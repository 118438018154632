import { FOLDER_TYPES } from '~/routes/private/files/constants';

export const MAX_SIZE = 5242880;
export const MAX_SIZE_ERROR_MESSAGE = ['Sorry, that file’s too large.', 'Maximum file size is 5 megabytes.'];
export const RESTRICTIONS_BY_FOLDER_TYPE = {
  [FOLDER_TYPES.IMAGES]: 'image/*',
  [FOLDER_TYPES.ICONS]: '.svg',
  [FOLDER_TYPES.FONTS]: '.woff, .woff2, .ttf, .otf'
};

export const DIMENSIONS_WHITE_LIST = [FOLDER_TYPES.IMAGES, FOLDER_TYPES.ICONS];
