import React, { useCallback, useMemo } from 'react';
import { TableAction } from 'react-table';
import { useHistory } from 'react-router-dom';
import { CoachPopoverComponent } from '~/components/_layout/popovers/import-export/coach-popover';
import { useSlotController } from '~/components/_layout/popovers/import-export/use-coach-slot-controller';
import useDocumentTitle from '~/hooks/use-document-title';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { Slot } from '~/components/slots';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';
import { Table } from '~/components/_table';
import { columns } from '~/pages/datasets/attributes/columns';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { calculatePageCount } from '~/components/_table/helpers/common';
import { useSelector } from '~/store/hooks';
import { useTheme } from '~/components/theme';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { TableStorageService } from '~/services/table-service';
import { Button, LinkButton } from '~/components/button';
import { WELCOME_MESSAGE } from '~/pages/datasets/attributes/constants';
import { DeleteAction } from '~/components/_layout/page-actions/delete-action';
import { useTableExport } from '~/components/_table/hooks/use-table-export';
import { ExportAction } from '~/components/_layout/page-actions/export-action';
import { buildExportParamsFromTable } from '~/components/_table/hooks/use-table-export/helpers';
import { useTableImport } from '~/components/_table/hooks/use-table-import';
import { ImportAction } from '~/components/_layout/page-actions/import-action';
import { Attribute } from '~/types/gists/attribute';
import { WrapAction } from '~/components/_layout/page-actions/wrap-action';
import PageHeading from '~/components/_layout/page-heading';

const defaultSortBy = [
  {
    id: 'updatedAt',
    desc: true
  }
];

const gist = 'attributes';

const Attributes = (): React.JSX.Element => {
  useDocumentTitle('Attributes');
  const { affiliate, env } = useTheme();
  const { push } = useHistory();

  const { get, editCell, autoResetProps, items, isEmpty, count, remove, removeSelected, cloneSingle } =
    useTableFetch<Attribute>({
      gist
    });
  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { CoachPopover: WelcomePopover, ...welcomeProps } = useCoachPopover({
    disabled: isMainMenuOpened || !isEmpty || TableStorageService.isEmptyFilters({ affiliate, env, gist })
  });

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  const editAttribute = useCallback<TableAction<Attribute>['handler']>(
    ({ row: { original } }) =>
      push(`${DATASETS_ROUTES.attributes.table}/${original.id}?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, push]
  );

  const actions = useMemo<TableAction<Attribute>[]>(() => {
    return [
      {
        id: 'edit',
        icon: 'edit',
        handler: editAttribute,
        tooltip: 'Edit Attribute'
      },
      {
        id: 'duplicate',
        icon: 'duplicate',
        handler: cloneSingle,
        tooltip: 'Duplicate Attribute'
      },
      {
        id: 'delete',
        icon: 'bin',
        confirm: { origin: 'displayName' },
        handler: remove,
        tooltip: 'Delete'
      }
    ];
  }, [cloneSingle, editAttribute, remove]);

  const {
    Popover: ExportPopover,
    onOpenExport,
    onSubmitExport,
    ...exportProps
  } = useTableExport<Attribute>({
    gist,
    message: 'Related Tags are included in this export.'
  });

  const { Popover: ImportPopover, onOpenImport, onImportSubmit, ...importProps } = useTableImport<Attribute>({ gist });

  const {
    onOpenNewCoachContent,
    onCloseCoachContent,
    CoachPopover: CoachImportPopover,
    ...coachImportProps
  } = useSlotController({ unsubscribePrevious: welcomeProps.unsubscribe, unsubscribeCurrent: importProps.toggle });

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Datasets' to={DATASETS_ROUTES.root} />
          <Breadcrumb.Item label={'Attributes'} truncated />
        </Breadcrumb>
      </Slot>
      <main>
        <PageHeading
          icon='datasets'
          title='Attributes'
          subtitle='Personal data, mostly answers to questions we’ve asked users in tools'
        />
        <Table<Attribute>
          key={`${affiliate}_${env}_attributes`}
          gist={gist}
          columns={columns}
          data={items}
          actions={actions}
          onEditCell={editCell}
          onUpdate={get}
          manualFilters
          manualPagination
          manualSortBy
          defaultSortBy={defaultSortBy}
          actionsColumnWidth={134}
          pageCount={pageCount}
          preventGet={isMainMenuOpened}
          count={count}
          {...autoResetProps}
        >
          {instance => {
            const refetch = async () => await instance.refetchTable();
            const openExportOptions = buildExportParamsFromTable(instance);
            const tooltipText = openExportOptions.isExportAll ? 'Export all' : 'Export selected';

            return (
              <>
                <Slot name='page-actions'>
                  <WrapAction gist={gist} onChange={instance.setWrappingMode} />
                  <ImportAction toggleImportPopover={onOpenImport} />
                  <ImportPopover onSubmit={onImportSubmit} afterSubmit={refetch} {...importProps} />
                  <ExportAction toggleExportPopover={onOpenExport(openExportOptions)} tooltipText={tooltipText} />
                  <ExportPopover onSubmit={onSubmitExport} {...exportProps} />
                  <DeleteAction items={instance.selectedFlatRows} onConfirm={removeSelected(instance)} gist={gist} />
                  <LinkButton to={DATASETS_ROUTES.attributes.create} icon='plus' fluid>
                    New Attribute
                  </LinkButton>
                </Slot>
                <CoachImportPopover {...coachImportProps}>
                  <CoachPopoverComponent
                    {...importProps}
                    {...coachImportProps}
                    toggle={onCloseCoachContent}
                    onSubmit={onImportSubmit}
                    afterSubmit={instance.refetchTable}
                  />
                </CoachImportPopover>
              </>
            );
          }}
        </Table>
        <WelcomePopover message={WELCOME_MESSAGE} {...welcomeProps}>
          <LinkButton to={DATASETS_ROUTES.attributes.create} icon='plus' fluid>
            Add new Attribute
          </LinkButton>
          <Button onClick={onOpenNewCoachContent} is='minor' icon='import' fluid>
            Import Attribute
          </Button>
        </WelcomePopover>
      </main>
    </>
  );
};

export default Attributes;
