import * as yup from 'yup';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const codeValidationSchema = yup.object().shape({
  code: yup.string().min(6, 'Code must be longer than or equal to 6 characters')
});

export const phoneNumberValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('isPossiblePhoneNumber', 'Phone number is not possible', value => isPossiblePhoneNumber(value || ''))
    .test('isValidPhoneNumber', 'Phone number is not valid', value => isValidPhoneNumber(value || ''))
});
