const DATASETS_ROUTE = '/datasets';

export const DATASETS_ROUTES = {
  root: DATASETS_ROUTE,
  attributes: {
    table: `${DATASETS_ROUTE}/attributes`,
    create: `${DATASETS_ROUTE}/attributes/new`,
    edit: `${DATASETS_ROUTE}/attributes/:id`
  }
};
