import type { Column } from 'react-table';
import dayjs from 'dayjs';

import { PrimaryCell } from '~/components/_table/cells/primary';
import { LinkCell } from '~/components/_table/cells/link';
import { ImageCell } from '~/components/_table/cells/image';
import { formatDate } from '~/helpers/formatters';

import type { PushNotification } from '~/types/gists/push-notification';
import RelatedItemCell from '~/components/_table/cells/related';
import { PUSH_NOTIFICATIONS_ROUTES } from '~/routes/private/push-notifications/constants';
import TagsCell from '~/components/_table/cells/tags';
import { TagsFilter } from '~/components/_table/filters/tags';

export const columns: Column<PushNotification>[] = [
  {
    title: 'Push name',
    Cell: PrimaryCell,
    accessor: 'name',
    to: PUSH_NOTIFICATIONS_ROUTES.root,
    actionTooltip: 'Edit Push',
    primary: true
  },
  {
    title: 'Cohort name',
    id: 'cohort.name',
    Cell: RelatedItemCell,
    accessor: push => push.cohort?.name ?? '',
    to: push => `/cohorts/${push.cohort?.id}`,
    tooltip: push => push.cohort?.description ?? '',
    actionTooltip: 'Edit Cohort',
    disableEdit: true
  },
  {
    title: '#',
    id: 'title-length',
    accessor: value => value.title?.length.toString() ?? '-',
    minWidth: 48,
    width: 48,
    disableSortBy: true,
    disableFilters: true,
    disableEdit: true
  },
  {
    title: 'Title',
    accessor: 'title'
  },
  {
    id: 'body-length',
    title: '#',
    accessor: value => value.message?.length ?? '-',
    minWidth: 48,
    width: 48,
    disableSortBy: true,
    disableFilters: true,
    disableEdit: true
  },
  {
    title: 'Body',
    accessor: 'message'
  },
  {
    title: 'Image',
    accessor: 'image',
    disableFilters: true,
    Cell: ImageCell,
    minWidth: 54,
    width: 88
  },
  {
    title: 'Link',
    accessor: 'link',
    Cell: LinkCell,
    to: '/',
    actionTooltip: 'Open Link in new tab'
  },
  {
    title: 'Tags',
    accessor: 'tags',
    Cell: TagsCell,
    disableSortBy: true,
    Filter: TagsFilter
  },
  {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableEdit: true,
    width: 160
  },
  {
    title: 'Last send',
    accessor: push => push.timedMessageHistories[0]?.createdAt ?? '-',
    id: 'timedMessageHistories.createdAt',
    format: formatDate,
    disableEdit: true,
    disableFilters: true,
    width: 160
  },
  {
    id: 'triggeredAt',
    title: 'Next send',
    accessor: value => (dayjs(value.triggeredAt).isBefore(new Date()) ? '-' : value.triggeredAt),
    format: formatDate,
    disableEdit: true,
    disableFilters: true,
    width: 160
  },
  {
    id: 'isRepeatable',
    title: 'Repeat',
    accessor: value => (value.isRepeatable ? 'Yes' : 'No'),
    disableEdit: true,
    disableFilters: true,
    width: 40
  }
];
