import React, { useMemo } from 'react';
import { Table } from '~/components/_table';

import type { CohortHistoryEntry } from '~/types/gists/cohort';

import { columns } from './columns';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { calculatePageCount } from '~/components/_table/helpers/common';

type Props = {
  id: string;
};

const identifier = 'cohort-history';

const defaultSortBy = [
  {
    id: 'createdAt',
    desc: true
  }
];

export const CohortHistory = ({ id }: Props): React.JSX.Element => {
  const { getHistory, loading, items, count, error } = useTableFetch<CohortHistoryEntry>({
    id,
    gist: 'cohorts'
  });

  const showBody = {
    error,
    loading
  };
  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  return (
    <Table<CohortHistoryEntry>
      gist='cohortStats'
      name={identifier}
      columns={columns}
      data={items}
      disableFilters
      disableEditing
      disableSelection
      manualSortBy
      defaultSortBy={defaultSortBy}
      manualPagination
      manualFilters
      pageCount={pageCount}
      onUpdate={getHistory}
      loading={loading}
      showBody={showBody}
      disableResizing
      count={count}
    />
  );
};
