import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  pageName: yup.string().required('You must enter a page name before you can save or update.'),
  slug: yup
    .string()
    .required('Slug must be at least 1 character')
    .matches(/^[a-zA-Z0-9].*[a-zA-Z0-9]$/, 'Slug should either start and end with a letter or number')
    .matches(/^[a-zA-Z0-9-_]+$/g, 'Slug may contain only letters, numbers, hyphens, and underscores')
    .matches(
      /^(?!.*[-_]{2,})[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+)*$/,
      'Slug may not contain more than one hyphen or underscore in a row, nor may it contain a sequence of hyphens and underscores'
    )
});
