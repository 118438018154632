import { pages as pagesApi } from '~/api/pages';
import { CustomError } from '~/helpers/common/custom-error';
import { setError, setIsEnabled, setUpdatedMetaFields } from '~/pages/pages/edit/context/store/actions';
import { getPayloadForPageSaving } from '~/pages/pages/edit/context/store/selectors';
import { BESavePagePayload, PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { Page } from '~/types/gists/page';

export const togglePublishPage = (): PageEditorThunk => async (dispatch, getState) => {
  const payload = getPayloadForPageSaving(getState());

  try {
    const { updatedBy, updatedAt } = await pagesApi.edit<BESavePagePayload, Page>({
      ...payload,
      isEnabled: !payload.isEnabled
    });
    dispatch(setIsEnabled(!payload.isEnabled));
    dispatch(setUpdatedMetaFields({ updatedBy, updatedAt }));
  } catch (unknownError) {
    const error = new CustomError(unknownError ?? 'Failed to update page data');
    dispatch(setError(error.message));
  }
};
