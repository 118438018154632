import React, { useEffect, useState } from 'react';
import { ArrayShim } from '~/helpers/shims/array-shim';
import { encodeQueryParams } from '~/api/utils';
import { FormLabel } from '~/components/_form/components/label';
import { Select, SelectCallback, SelectItem } from '~/components/_inputs/select';
import { TableApi } from '~/components/_table/types';
import { useTheme } from '~/components/theme';
import { pluralToSingular } from '~/helpers/formatters';
import { useSidebarPanel } from '../index';
import { api } from '~/api';
import { gistToIdentifier } from '~/components/_table/helpers/gist-to-identifier';
import styles from '~/components/_layout/panels/sidebar-panel.module.scss';

export type OnChange<T> = (value: T) => void;

export type Props<T extends HasId> = {
  id: T['id'];
  onChange: HandleChange;
  title: string;
  label: string;
  placeholder: string;
  children?: React.ReactNode;
  gist: TableApi;
  name: string;
  className?: string;
  multiLine?: boolean;
};

export const SelectPanel = <T extends HasId & HasName>({
  id,
  onChange,
  title,
  label,
  placeholder,
  children,
  gist,
  name,
  className,
  multiLine
}: Props<T>): React.JSX.Element => {
  const [items, setItems] = useState<SelectItem<T>[]>([]);
  const [loading, setLoading] = useState(true);

  const handleChange: SelectCallback<T> = () => item => {
    onChange(name, item.id);
  };

  const mapValue = (id?: T['id']): SelectItem<T> | undefined => {
    const map = ArrayShim.normalize(items, 'id');

    const selected = id === void 0 ? id : map[String(id)];

    return (
      selected && {
        id: selected.id,
        value: selected,
        label: selected.label
      }
    );
  };

  useEffect(() => {
    const asyncGet = async () => {
      const identifier = gistToIdentifier[gist];

      const { items } = await api[gist].get<T>({
        limit: 500,
        orderBy: 'name',
        orderDirection: 'ASC',
        filters: JSON.stringify({ [`${identifier}.isEnabled`]: 1 })
      });

      const data: SelectItem<T>[] = items.map(value => {
        const { id, name } = value;

        return {
          id,
          value,
          label: name
        };
      });

      setItems(data);

      setLoading(false);
    };

    asyncGet();
  }, [gist]);

  const { Panel, List, ListItem } = useSidebarPanel();

  const { env, affiliate } = useTheme();
  const pureEntityName = pluralToSingular(gist);
  const entityHref = encodeQueryParams(`/${gist}/${id}`, { affiliate, env });

  return (
    <Panel title={title}>
      <List>
        <ListItem className={styles.relative}>
          <FormLabel label={label}>
            {id && (
              <a href={entityHref} className={styles.link} target='_blank' rel='noreferrer'>
                Edit {pureEntityName}
              </a>
            )}
            <Select<T>
              name={`select-${title}`}
              items={items}
              placeholder={placeholder}
              loading={loading}
              value={mapValue(id)}
              onChange={handleChange}
              multiLine={multiLine}
              containerClassName={className}
            />
          </FormLabel>
        </ListItem>
        <ListItem withoutBorder>{children}</ListItem>
      </List>
    </Panel>
  );
};
