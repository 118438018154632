import { RefCallback, useCallback } from 'react';

export const useAutofocus = <T extends HTMLElement>(
  toEnd?: boolean
): {
  ref: RefCallback<T>;
} => {
  const ref = useCallback<RefCallback<T>>(
    node => {
      if (node) {
        node.focus();

        if (toEnd) {
          if (node instanceof HTMLInputElement || node instanceof HTMLTextAreaElement) {
            const length = node.value.length;

            node.setSelectionRange(length, length);
          }
        }
      }
    },
    [toEnd]
  );

  return {
    ref
  };
};
