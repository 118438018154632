import React, { useCallback } from 'react';
import classnames from 'classnames';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { AffiliateLogo } from '~/components/_layout/affiliate-logo';
import { Label } from '~/components/_layout/typography/label';
import { ImageFileURL, ImageInput } from '~/components/_inputs/image';
import { useTheme } from '~/components/theme';

import { Indicator, Toggle } from './indicator';
import styles from './preview.module.scss';
import { Frame } from './frame';
import { PushNotification, PushNotificationHistory } from '~/types/gists/push-notification';
import { Mode } from '../top-bar/mode-action';
import { ObjectShim } from '~/helpers/shims/object-shim';

type Props = {
  editPreview: Pick<PushNotification, 'title' | 'message' | 'image'>;
  statsPreview: PushNotificationHistory['messageDetails'] | Record<string, any>;
  mode: Mode;
  messageStructure: {
    showLink: boolean;
    showImage: boolean;
    showTitle: boolean;
  };
  onChange: HandleChange;
};

const DEFAULT_AFFILIATE_NAME = 'Sandbox';

const MAX_LENGTH = {
  title: {
    alert: 30,
    absolute: 80
  },
  message: {
    alert: 40,
    absolute: 500
  }
};

export const Preview = React.memo(
  ({ messageStructure: editMessageStructure, mode, editPreview, statsPreview, onChange }: Props): React.JSX.Element => {
    const item = mode === 'edit' ? editPreview : statsPreview;
    const { title, message, image } = item;

    const { affiliate: affiliateName = DEFAULT_AFFILIATE_NAME } = useTheme();

    const messageStructure =
      mode === 'edit'
        ? editMessageStructure
        : {
            showTitle: Boolean(title),
            showImage: Boolean(image)
          };

    const { showImage, showTitle } = messageStructure;

    const { theme } = useTheme();

    const handleFocus = (toggle: Toggle) => () => toggle(true);
    const handleBlur = (toggle: Toggle) => () => toggle(false);

    const handleChange = useCallback<HandleChange>(
      (name, value) => {
        if (mode === 'stats') {
          return;
        }

        if (value.length <= MAX_LENGTH[name].absolute) {
          onChange(name, value);
        }
      },
      [mode, onChange]
    );

    const handleImage = (fileUrl: ImageFileURL) => {
      if (mode === 'stats') {
        return;
      }

      onChange('image', fileUrl);
    };

    return (
      <div className={classnames(styles.container, styles[theme])}>
        <Frame>
          {item && !ObjectShim.isEmpty(item) && (
            <div className={styles.bubble}>
              <header className={styles.header}>
                <AffiliateLogo className={styles.logo} />
                <Label size='s'>{affiliateName}</Label>
                <Label size='s' className={styles.now}>
                  now
                </Label>
              </header>
              {showTitle && (
                <Indicator value={title?.length} constraint={MAX_LENGTH.title.alert}>
                  {toggle => (
                    <TextareaAutosize
                      name='title'
                      placeholder='Add a title...'
                      onChange={handleChange}
                      value={title}
                      className={styles.title}
                      onFocus={handleFocus(toggle)}
                      onBlur={handleBlur(toggle)}
                    />
                  )}
                </Indicator>
              )}
              <div className={styles.body}>
                <Indicator value={message.length} constraint={MAX_LENGTH.message.alert} className={styles.message}>
                  {toggle => (
                    <TextareaAutosize
                      name='message'
                      placeholder='Add some body copy...'
                      onChange={handleChange}
                      value={message}
                      onFocus={handleFocus(toggle)}
                      onBlur={handleBlur(toggle)}
                    />
                  )}
                </Indicator>
                {showImage && (
                  <div className={styles.image}>
                    <ImageInput disabled={!onChange} id='preview-image' hideLabel cb={handleImage} src={image} />
                  </div>
                )}
              </div>
            </div>
          )}
        </Frame>
      </div>
    );
  }
);
