import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import EditableContentLayer from '~/pages/pages/edit/components/preview-zone/components/block/components/layers/components/layer/components/content-layer';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setSelectedLayerId } from '~/pages/pages/edit/context/store/actions';
import { getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { extractTagFromHTML } from '~/pages/pages/edit/context/store/helpers';
import { BlockLayer, LAYER_TYPES, PublicBlock } from '~/types/gists/page';

import styles from './styles.module.scss';
import ImageStaticLayer from '~/pages/pages/edit/components/preview-zone/components/block/components/layers/components/layer/components/image-static-layer';

type Props = {
  layers: Array<BlockLayer>;
  layer: BlockLayer;
  block: PublicBlock;
};

const Layer = ({ layers, layer, block }: Props): React.JSX.Element | null => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const onClickLayer = useCallback(
    e => {
      e.stopPropagation();
      if (selectedLayerId !== layer.id) {
        dispatch(setSelectedLayerId(layer.id));
      }
    },
    [dispatch, layer.id, selectedLayerId]
  );

  const layerClassName = classnames(styles.layer, { [styles.selected]: selectedLayerId === layer.id });

  const children = useMemo(
    () => layer.children.map(childId => layers.find(layer => layer.id === childId) as BlockLayer),
    [layer.children, layers]
  );

  if (layer.type === LAYER_TYPES.CONTENT) {
    if (layer.id === selectedLayerId) {
      return <EditableContentLayer block={block} layer={layer} />;
    }

    return (
      <span
        id={layer.id}
        role='generic'
        tabIndex={0}
        onClick={onClickLayer}
        onKeyDown={onClickLayer}
        className={layerClassName}
        dangerouslySetInnerHTML={{ __html: layer.content }}
      />
    );
  } else if (layer.type === LAYER_TYPES.STATIC && block.type === COMPONENT_TYPES.image) {
    return (
      <ImageStaticLayer
        layer={layer}
        block={block}
        onClickLayer={onClickLayer}
        layerClassName={layerClassName}
        fileKey={block.data.fileKey}
      />
    );
  }

  const tag = extractTagFromHTML(layer.content);

  if (tag) {
    return React.createElement(
      tag,
      {
        key: layer.id,
        id: layer.id,
        onClick: onClickLayer,
        className: layerClassName
      },
      <>
        {children.map(childLayer => (
          <Layer key={childLayer.id} layers={layers} layer={childLayer} block={block} />
        ))}
      </>
    );
  }

  return null;
};

export default Layer;
