import React, { useCallback } from 'react';
import { DeleteCurrentAction } from '~/components/_layout/page-actions/delete-current-action';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { deleteCurrent } from '~/pages/pages/edit/components/delete-page-action/thunks';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getPageName } from '~/pages/pages/edit/context/store/selectors';

const DeletePageAction = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const pageName = usePageEditorSelector(getPageName);

  const onDeleteCurrent = useCallback(() => dispatch(deleteCurrent()), [dispatch]);

  return <DeleteCurrentAction name={pageName} rootRoute={PAGES_ROUTES.root} onConfirm={onDeleteCurrent} />;
};

export default DeletePageAction;
