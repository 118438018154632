import React, { PropsWithChildren, useCallback } from 'react';
import { Input } from '~/components/_inputs/input';

import styles from './styles.module.scss';

type Props = {
  value: string;
  onChange: (newValue: string) => void;
};

const Filter = ({ children, value, onChange }: PropsWithChildren<Props>): React.JSX.Element => {
  const onChangeFilter = useCallback((_, newValue: string) => onChange(newValue), [onChange]);

  return (
    <div className={styles.container}>
      <Input value={value} onChange={onChangeFilter} placeholder={'Search'} />
      {children}
    </div>
  );
};

export default Filter;
