import { Route } from '~/routes/types';
import { BLOCKS_ROUTES } from '~/routes/private/blocks/constants';
import Blocks from '~/pages/blocks';
import EditBlock from '~/pages/blocks/edit';

export const blocks: Route[] = [
  {
    to: BLOCKS_ROUTES.root,
    exact: true,
    component: Blocks
  },
  {
    to: BLOCKS_ROUTES.edit,
    exact: true,
    component: EditBlock
  }
];
