import React from 'react';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedLayer } from '~/pages/pages/edit/context/store/selectors';
import PageSidebar from '~/pages/pages/edit/components/right-sidebar/modes/page';
import BlockSidebar from '~/pages/pages/edit/components/right-sidebar/modes/block/modes';
import { LAYER_TYPES } from '~/types/gists/page';

import styles from './styles.module.scss';

const RightSidebar = (): React.JSX.Element => {
  const selectedLayer = usePageEditorSelector(getSelectedLayer);

  if (!selectedLayer) {
    return (
      <div className={styles.container}>
        <PageSidebar />
      </div>
    );
  }

  if (selectedLayer.type === LAYER_TYPES.PRESENTATION) {
    return <div className={styles.container} />;
  }
  return (
    <div className={styles.container}>
      <BlockSidebar />
    </div>
  );
};

export default RightSidebar;
