import { ONBOARDING_ROUTES } from '~/routes/constants';

export enum MFA_CHALLENGES {
  PHONE_NUMBER_SETUP = 'PHONE_NUMBER_SETUP',
  PHONE_NUMBER_VERIFIER = 'PHONE_NUMBER_VERIFIER',
  SMS_MFA = 'SMS_MFA'
}

export const MFA_CHALLENGE_TO_ROUTE = {
  [MFA_CHALLENGES.SMS_MFA]: ONBOARDING_ROUTES.smsMfa,
  [MFA_CHALLENGES.PHONE_NUMBER_SETUP]: ONBOARDING_ROUTES.phoneSetup,
  [MFA_CHALLENGES.PHONE_NUMBER_VERIFIER]: ONBOARDING_ROUTES.phoneVerify
};
