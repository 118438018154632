import React from 'react';
import { TagName } from '~/templates/tags-interaction/components/TagsTree/components/TagName';
import useTagsSelector from '~/templates/tags-interaction/context/hooks/useTagsSelector';
import { getSelectedTags } from '~/templates/tags-interaction/context/store/selectors';
import { deselectTag } from '~/templates/tags-interaction/context/store/actions';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';

import styles from './styles.module.scss';
import { Icon } from '~/components/icon';

const SelectedTags = (): React.JSX.Element | null => {
  const { dispatch } = useTagsInteractionContext();

  const selectedTags = useTagsSelector(getSelectedTags);

  if (!selectedTags.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {selectedTags.map(selectedTag => (
        <div
          key={selectedTag.id}
          onClick={() => setTimeout(() => dispatch(deselectTag(selectedTag.id)), 0)}
          className={styles.item}
        >
          <Icon name='tags' />
          <TagName name={selectedTag.name} />
        </div>
      ))}
    </div>
  );
};

export default SelectedTags;
