import React, { forwardRef, HTMLAttributes, PropsWithChildren, Ref, useState } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import type { Options } from '@popperjs/core';
import { useMergeRefs } from '~/hooks/use-merge-refs';
import { useUniqId } from '~/hooks/use-uniq-id';
import { useClickOutside } from '~/hooks/use-click-outside';
import { usePopper, UsePopper } from '~/hooks/use-popper';

import styles from './popover.module.scss';

type Props = PropsWithChildren<{
  onClickOutside?: CallableFunction;
}> &
  HTMLAttributes<HTMLDivElement>;

export const PopoverComponent = (
  { children, className, onClickOutside, ...props }: Props,
  ref: Ref<HTMLDivElement>
): React.JSX.Element => {
  const [clickTrackerRef] = useClickOutside(onClickOutside, true);

  return (
    <div ref={useMergeRefs(ref, clickTrackerRef)} className={classnames(styles.container, className)} {...props}>
      {children}
    </div>
  );
};

export const Popover = forwardRef<HTMLDivElement, Props>(PopoverComponent);
export const MotionPopover = motion(Popover);

const POPPER: Partial<Options> = {
  strategy: 'absolute',
  placement: 'bottom'
};

export type UsePopover = {
  id: string;
  show: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  MotionPopover: typeof MotionPopover;
  Popover: typeof Popover;
} & ReturnType<UsePopper>;

export type PopoverProps = Pick<UsePopover, 'show' | 'onPopoverRef' | 'onTriggerRef' | 'toggle'>;

export const usePopover = (defaultShow = false, options: Partial<Options> = POPPER): UsePopover => {
  const [show, toggle] = useState(defaultShow);
  const id = useUniqId();

  return {
    id,
    show,
    toggle,
    Popover,
    MotionPopover,
    ...usePopper(options, show)
  };
};
