import React from 'react';

import { QUOTE_DEFAULT_VARIANT, QuoteVariants } from '@life-moments/lifehub-components';

import { getStructureItemLayers, StructureItemLayer } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import { Block } from '~/types/gists/Block';

import Variant from '~/pages/blocks/edit/components/structure-sidebar/components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: QuoteVariants[];
  blockName: Block['name'];
};

const content: StructureItemLayer[] = [
  {
    name: 'Inner',
    childrenLayers: [{ name: 'Text' }, { name: 'Caption' }]
  }
];

const QuoteBlockVariants = ({ variants, blockName }: Props): React.JSX.Element => (
  <div className={styles.container}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        name={variant.displayName}
        isDefault={variant.name === QUOTE_DEFAULT_VARIANT.name}
        content={getStructureItemLayers(blockName, content)}
      />
    ))}
  </div>
);

export default QuoteBlockVariants;
