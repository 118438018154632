import React, { useCallback, useMemo } from 'react';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getLayerToParentsMap } from '~/pages/pages/edit/context/store/helpers';
import { getBlockById } from '~/pages/pages/edit/context/store/selectors';
import Layer from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure/components/structure-item/components/layer';
import { LAYER_TYPES } from '~/types/gists/page';

type Props = {
  id: string;
};

const StructureItem = ({ id }: Props): React.JSX.Element => {
  const getBlockByIdSelector = useCallback(state => getBlockById(state, { id }), [id]);

  const block = usePageEditorSelector(getBlockByIdSelector);

  const rootLayers = block.data.layers.filter(layer => layer.type === LAYER_TYPES.CONTAINER);

  const layerToParentsMap = useMemo(() => getLayerToParentsMap(block.data.layers), [block.data.layers]);

  return (
    <>
      {rootLayers.map(rootLayer => (
        <Layer key={rootLayer.id} layers={block.data.layers} layer={rootLayer} layerToParentsMap={layerToParentsMap} />
      ))}
    </>
  );
};

export default StructureItem;
