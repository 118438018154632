import React from 'react';
import classnames from 'classnames';

import { useTheme } from '~/components/theme';
import styles from './environment-indicator.module.scss';
import { Icon } from '~/components/icon';
import useOpenProductInNewTab from '~/hooks/use-open-product-in-new-tab';

export const ENVIRONMENT_LABELS: Record<Environment, string> = {
  dev: 'Dev',
  test: 'Test',
  stage: 'Stage',
  prod: 'Live',
  none: ''
};

export const EnvironmentIndicator = (): React.JSX.Element => {
  const { env, theme } = useTheme();

  const openProductInNewTab = useOpenProductInNewTab();

  return (
    <div className={classnames(styles.container, styles[theme])} onClick={openProductInNewTab}>
      {ENVIRONMENT_LABELS[env]}
      <Icon className={styles.icon} name={'arrow-right'} />
    </div>
  );
};
