import React, { useCallback, useState } from 'react';
import { Options } from '@popperjs/core';

import { offset } from '~/helpers/ui-kit/popper/offset';
import { sameWidth } from '~/helpers/ui-kit/popper/same-width';
import { maxHeight } from '~/helpers/ui-kit/popper/max-size';

import { Portal } from '~/components/portal';
import { Popover, PopoverProps, usePopover } from '~/components/popover';
import TagsInteractionProvider from '~/templates/tags-interaction/context';
import { OriginalTag } from '~/types/gists/tag';
import { isEqual } from '~/helpers/common';

import InteractionPopoverFlow from './interaction';

import styles from './styles.module.scss';

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => [0, -reference.height]
  }
};

const POPOVER: Partial<Options> = {
  placement: 'bottom',
  modifiers: [topOffset, sameWidth, ...maxHeight]
};

type Props = {
  children: (toggle: PopoverProps['toggle']) => React.ReactNode;
  saveTags: (tags: OriginalTag[]) => void;
  initialAssignedTags: OriginalTag[];
  disabledTagCreation?: boolean;
  onClose?: () => void;
};

const TagsInteractionPopover = ({
  children,
  saveTags,
  initialAssignedTags,
  disabledTagCreation,
  onClose
}: Props): React.JSX.Element => {
  const { onPopoverRef, onTriggerRef, toggle, show } = usePopover(false, POPOVER);

  const [assignedTags, setAssignedTags] = useState<OriginalTag[]>([]);

  const closePopover = useCallback(() => {
    toggle(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, toggle]);

  const onCreateNewTag = useCallback(
    (newTag: OriginalTag) => {
      saveTags([...assignedTags, newTag]);
      closePopover();
    },
    [assignedTags, closePopover, saveTags]
  );

  const onAssignTags = useCallback(
    (newAssignedTags: OriginalTag[]) => {
      if (!isEqual(assignedTags, newAssignedTags)) {
        setAssignedTags(newAssignedTags);
        saveTags(newAssignedTags);
      }
    },
    [assignedTags, saveTags]
  );

  return (
    <>
      <div ref={onTriggerRef} className={styles.container}>
        {children(toggle)}
      </div>
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
          {show && (
            <Popover className={styles.popover} onClickOutside={closePopover}>
              <TagsInteractionProvider>
                <InteractionPopoverFlow
                  initialAssignedTags={initialAssignedTags}
                  onAssignTags={onAssignTags}
                  onCreateNewTag={disabledTagCreation ? undefined : onCreateNewTag}
                />
              </TagsInteractionProvider>
            </Popover>
          )}
        </div>
      </Portal>
    </>
  );
};

export default TagsInteractionPopover;
