import React, { memo, useCallback } from 'react';
import { parseInteractionState } from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/components/interaction/utils';
import { BlockLayer, PublicBlock } from '~/types/gists/page';
import { getAvailableImageByKey } from '~/pages/pages/edit/context/store/selectors';
import { FileObject } from '~/types/gists/file';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import ImageBlockDefault from '~/assets/images/image_block_default.svg';

import styles from './styles.module.scss';

type Props = {
  layer: BlockLayer;
  onClickLayer: (e: any) => void;
  layerClassName: string;
  block: PublicBlock;
  fileKey?: FileObject['key'];
};

type ImageProps = Pick<Props, 'onClickLayer' | 'layerClassName' | 'fileKey'>;

const FileImage = ({ fileKey, onClickLayer, layerClassName }: ImageProps): React.JSX.Element => {
  const getAvailableImageSelector = useCallback(
    state => {
      if (fileKey) {
        return getAvailableImageByKey(state, { key: fileKey });
      }
      return null;
    },
    [fileKey]
  );

  const availableImage = usePageEditorSelector(getAvailableImageSelector);

  return (
    <img
      onClick={onClickLayer}
      className={layerClassName}
      src={availableImage?.s3ObjectUrl ?? ImageBlockDefault}
      alt={availableImage?.altText ?? 'default alt text'}
    />
  );
};

const ImageStaticLayer = ({ layer, onClickLayer, layerClassName, fileKey, block }: Props): React.JSX.Element => {
  const interactionState = parseInteractionState(block.data.content);

  if (interactionState) {
    return (
      <a
        className={styles.anchor}
        href={interactionState.url}
        onClickCapture={e => e.preventDefault()}
        target={interactionState.target}
        aria-label={interactionState.ariaLabel}
        rel={interactionState.noFollow ? 'nofollow' : undefined}
      >
        <FileImage key={layer.id} fileKey={fileKey} layerClassName={layerClassName} onClickLayer={onClickLayer} />
      </a>
    );
  }

  return <FileImage key={layer.id} fileKey={fileKey} layerClassName={layerClassName} onClickLayer={onClickLayer} />;
};

export default memo(ImageStaticLayer);
