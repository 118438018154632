import React from 'react';
import { ContentEditableAsInput } from '~/components/contenteditable/input';
import { ContentEditableAsTextarea } from '~/components/contenteditable/textarea';
import { FeatureIcon, FeatureIcons } from '~/components/icon';
import { Heading } from '~/components/_layout/typography/heading';
import { Label } from '~/components/_layout/typography/label';

import styles from './edit-template.module.scss';

export type Props = {
  title?: string;
  onTitleChange?: (event: TitleChangeEvent) => void;
  description?: string;
  onDescriptionChange?: (event: DescriptionChangeEvent) => void;
  icon?: keyof FeatureIcons;
  children?: React.ReactNode;
  showDescription?: boolean;
};

export type TitleChangeEvent = React.ChangeEvent<HTMLInputElement>;
export type DescriptionChangeEvent = React.ChangeEvent<HTMLTextAreaElement>;
export type ChangeEvent = TitleChangeEvent | DescriptionChangeEvent;

const Title = ({ children }: React.PropsWithChildren<void>) => <Heading size='l'>{children}</Heading>;

const SubTitle = ({ children }: React.PropsWithChildren<void>) => <Label size='s'>{children}</Label>;

export const EditTemplate = React.memo(
  ({
    icon,
    children,
    title,
    onTitleChange,
    description,
    onDescriptionChange,
    showDescription = true
  }: Props): React.JSX.Element => {
    return (
      <section className={styles.container}>
        <header className={styles.header}>
          {icon && <FeatureIcon className={styles.icon} name={icon} />}
          <div className={styles.intro}>
            <ContentEditableAsInput as={Title} className={styles.title} value={title} onChange={onTitleChange} />
            {showDescription && (
              <ContentEditableAsTextarea
                as={SubTitle}
                className={styles.description}
                value={description}
                onChange={onDescriptionChange}
                placeholder='Add a description...'
              />
            )}
          </div>
        </header>
        {children}
      </section>
    );
  }
);
