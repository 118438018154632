import React, { RefCallback, useCallback, useEffect, useRef } from 'react';

type ClickOutsideHandler<T> = (event: React.MouseEvent<T>) => void;

export const useClickOutside = <T extends HTMLElement>(
  callback?: CallableFunction,
  auto?: boolean
): [RefCallback<T>, ClickOutsideHandler<T>] => {
  const anchor = useRef<T | null>(null);

  const onRef = useCallback<RefCallback<T>>(node => {
    anchor.current = node ?? null;
  }, []);

  const onClick = useCallback<(event: MouseEvent | TouchEvent) => void>(
    ({ target }) => {
      const reference = anchor.current;

      if (reference && callback && !reference.contains(target as Node)) {
        callback();
      }
    },
    [callback]
  );

  const handleClick = useCallback<ClickOutsideHandler<T>>(
    event => {
      onClick(event.nativeEvent);
    },
    [onClick]
  );

  useEffect(() => {
    if (auto && callback) {
      document.addEventListener('click', onClick);
      document.addEventListener('touchstart', onClick);

      return () => {
        document.removeEventListener('click', onClick);
        document.removeEventListener('touchstart', onClick);
      };
    }

    return;
  }, [auto, callback, onClick]);

  return [onRef, handleClick];
};
