import { Filters, FilterValue, IdType, SortingRule, TableWrapMode } from 'react-table';
import { LocalStorageService } from '~/helpers/services/local-storage';
import { WrappingMode } from '~/components/_table/plugins/wrapping';
import { TableApi } from '~/components/_table/types';
import { parse } from 'query-string';

const TABLE_STORAGE_KEY = 'table-storage';

type TableFilters<D> = Filters<{ id: IdType<D>; value: FilterValue }>;

type TableSortingRules<D> = Array<SortingRule<D>>;

type Table = {
  wrapMode: TableWrapMode;
  resizeData: ResizeData;
  filters: TableFilters<unknown>;
  sortBy: TableSortingRules<unknown>;
};

const initialState: Table = {
  wrapMode: 'nowrap',
  resizeData: {},
  filters: [],
  sortBy: []
};

type ResizeData = {
  [columnId: string]: number;
};

export type TableIdentifiers = {
  affiliate: AffiliateKey;
  env: Environment;
  gist: TableApi;
};

type TableStorageWriteArguments = TableIdentifiers & { value: Partial<Table> };

type TableStage = Record<TableApi, Table>;

type EnvironmentListSettings = Partial<Record<Environment, TableStage>>;

type AffiliateListSettings = Record<AffiliateKey, EnvironmentListSettings>;

export class TableStorageService {
  // This could be a problem in the future with the storage memory limit.
  // Maybe, it is a good idea to create an additional async abstraction for future purposes.
  private static store = new LocalStorageService<AffiliateListSettings>(TABLE_STORAGE_KEY);

  static getWrapMode({ affiliate, env, gist }: TableIdentifiers): WrappingMode {
    return TableStorageService.get({ affiliate, env, gist }).wrapMode || initialState.wrapMode;
  }

  static setWrapMode({ affiliate, env, gist, mode }: TableIdentifiers & { mode: TableWrapMode }): boolean {
    return TableStorageService.set({
      affiliate,
      env,
      gist,
      value: {
        wrapMode: mode
      }
    });
  }

  static getResizeData({ affiliate, env, gist }: TableIdentifiers): ResizeData {
    return TableStorageService.get({ affiliate, env, gist }).resizeData || initialState.resizeData;
  }

  static setResizeData({ affiliate, env, gist, resizeData }: TableIdentifiers & { resizeData: ResizeData }): boolean {
    return TableStorageService.set({
      affiliate,
      env,
      gist,
      value: {
        resizeData
      }
    });
  }

  static getSortBy<D>({ affiliate, env, gist }: TableIdentifiers): TableSortingRules<D> {
    return TableStorageService.get({ affiliate, env, gist }).sortBy || initialState.sortBy;
  }

  static setSortBy<D>({ affiliate, env, gist, sortBy }: TableIdentifiers & { sortBy: TableSortingRules<D> }): boolean {
    return TableStorageService.set({
      affiliate,
      env,
      gist,
      value: {
        sortBy
      }
    });
  }

  static encodeFilters<D>(filters: TableFilters<D>): string {
    return filters.length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '';
  }

  static decodeFilters<D>(): TableFilters<D> {
    const { filters } = parse(window.location.search);

    return filters ? JSON.parse(filters.toString()) : [];
  }

  static addQueryParams(decodedParams: string, omitParams?: string[]): void {
    const { search, host, pathname, protocol } = window.location;

    const existedParams = parse(search);

    const { affiliate, env, ...others } = existedParams;

    const strictParams = `affiliate=${affiliate}&env=${env}`;

    const othersParams = Object.keys(others)
      .filter(param => !omitParams?.includes(param))
      .join('&');

    const newUrl = `${protocol}//${host}${pathname}?${strictParams}${othersParams}${decodedParams}`;

    window.history.replaceState({ path: newUrl }, document.title, newUrl);
  }

  static getFilters<D>({ affiliate, env, gist }: TableIdentifiers): TableFilters<D> {
    return TableStorageService.get({ affiliate, env, gist }).filters || initialState.filters;
  }

  static setFilters<D>({ affiliate, env, gist, filters }: TableIdentifiers & { filters: TableFilters<D> }): boolean {
    return TableStorageService.set({
      affiliate,
      env,
      gist,
      value: {
        filters
      }
    });
  }

  static getMergedFilters<D>({ affiliate, env, gist }: TableIdentifiers): TableFilters<D> {
    const paramsFilters = TableStorageService.decodeFilters();
    const persistedFilters = TableStorageService.getFilters({ affiliate, env, gist });

    return persistedFilters.reduce((filters, currentFilter) => {
      const isFilterAlreadyExists = filters.find(filter => filter.id === currentFilter.id);

      if (!isFilterAlreadyExists) {
        filters.push(currentFilter);
      }

      return filters;
    }, paramsFilters);
  }

  static getTableStorageState<D>(tableIdentifiers: TableIdentifiers): Table {
    const wrapMode = TableStorageService.getWrapMode(tableIdentifiers);
    const resizeData = TableStorageService.getResizeData(tableIdentifiers);
    const filters = TableStorageService.getMergedFilters<D>(tableIdentifiers);
    const sortBy = TableStorageService.getSortBy(tableIdentifiers);

    return {
      wrapMode,
      resizeData,
      filters,
      sortBy
    };
  }

  static isEmptyFilters({ affiliate, env, gist }: TableIdentifiers): boolean {
    return Boolean(TableStorageService.getMergedFilters({ affiliate, env, gist }).length);
  }

  private static write({ affiliate, env, gist, value }: TableStorageWriteArguments) {
    return TableStorageService.store.set(store => {
      const affiliateState = store[affiliate] || {};
      const envState = affiliateState[env] || {};

      return {
        ...store,
        [affiliate]: {
          ...affiliateState,
          [env]: {
            ...envState,
            [gist]: value
          }
        }
      };
    });
  }

  private static get({ affiliate, env, gist }: TableIdentifiers): Table {
    const tables = TableStorageService.store.get();

    const isEmptyTableState = !tables[affiliate] || !tables[affiliate][env] || !tables[affiliate][env][gist];

    if (isEmptyTableState) {
      TableStorageService.write({ affiliate, env, gist, value: initialState });
      return initialState;
    }

    return tables[affiliate][env][gist];
  }

  private static set({ affiliate, env, gist, value }: TableStorageWriteArguments): boolean {
    const mergedValue = Object.assign(TableStorageService.get({ affiliate, env, gist }), value);

    return TableStorageService.write({ affiliate, env, gist, value: mergedValue });
  }
}
