import React, { useCallback, useMemo } from 'react';
import { COMPONENT_TYPES } from '@life-moments/lifehub-components';
import { DEFAULT_BLOCK_TAG } from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor/utils';

import { BlockLayer, PublicBlock } from '~/types/gists/page';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { onPopulateNewBlock } from '~/pages/pages/edit/context/store/thunks';
import RichEditor from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor';
import { setBlockLayerContent } from '~/pages/pages/edit/context/store/actions';

type Props = {
  layer: BlockLayer;
  block: PublicBlock;
};

function wrapInOrderedList(content: string): string {
  return `<ol class="number-list">${content}</ol>`;
}

function removeOuterListTags(input: string): string {
  const pattern = /(<(ul|ol)>|<\/(ul|ol)>)/g;
  let openListCount = 0;

  return input.replace(pattern, (match, p1) => {
    if (p1 === '<ul>' || p1 === '<ol>') {
      openListCount++;
      return openListCount > 1 ? match : '';
    } else if (p1 === '</ul>' || p1 === '</ol>') {
      openListCount--;
      return openListCount > 0 ? match : '';
    }
    return match;
  });
}

const EditableContentLayer = ({ block, layer }: Props): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const handleEnterKeyPressMap = useMemo(
    () =>
      new Map<COMPONENT_TYPES, undefined | (() => void)>([
        [
          COMPONENT_TYPES.text,
          () => {
            dispatch(onPopulateNewBlock(COMPONENT_TYPES.text, block.id));
          }
        ],
        [
          COMPONENT_TYPES.heading,
          () => {
            dispatch(onPopulateNewBlock(COMPONENT_TYPES.text, block.id));
          }
        ],
        [COMPONENT_TYPES.bulletList, undefined],
        [COMPONENT_TYPES.numberList, undefined],
        [
          COMPONENT_TYPES.quote,
          () => {
            dispatch(onPopulateNewBlock(COMPONENT_TYPES.text, block.id));
          }
        ]
      ]),
    [block, dispatch]
  );

  const onContentChange = useCallback(
    (content: string) => {
      if (block.type === COMPONENT_TYPES.bulletList || block.type === COMPONENT_TYPES.numberList) {
        dispatch(setBlockLayerContent(layer.id, removeOuterListTags(content)));
        return;
      }

      dispatch(setBlockLayerContent(layer.id, content));
    },
    [block.type, dispatch, layer.id]
  );

  return (
    <div onClick={e => e.stopPropagation()}>
      <RichEditor
        content={block.type === COMPONENT_TYPES.numberList ? wrapInOrderedList(layer.content) : layer.content}
        handleEnterKeyPress={handleEnterKeyPressMap.get(block.type)}
        onContentChange={onContentChange}
        defaultBlockTag={DEFAULT_BLOCK_TAG[block.type] ?? DEFAULT_BLOCK_TAG.default}
      />
    </div>
  );
};

export default EditableContentLayer;
