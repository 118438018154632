import dayjs from 'dayjs';
import React from 'react';
import { ControlledFormLabel } from '~/components/_form/components/label';
import { Switch } from '~/components/_inputs/switch';
import { CustomError } from '~/helpers/common/custom-error';
import { formatDate } from '~/helpers/formatters';
import { useSidebarPanel } from '../';
import { Confirm, ConfirmCallback } from '../../popovers/confirm';
import { DateComponent } from '../../typography/date';
import { Label } from '../../typography/label';

import styles from './publish-sidebar-panel.module.scss';

type PublishPanelProps = {
  name: string;
  unsaved?: boolean;
  published?: boolean;
  onSave?: (isEnabled: boolean) => Promise<void>;
  onPublish?: () => Promise<void>;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  placeholder?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const PublishPanel = React.memo(
  ({
    name,
    createdAt,
    updatedAt,
    createdBy,
    updatedBy,
    unsaved,
    published,
    onSave,
    onPublish,
    placeholder = '---',
    disabled,
    children
  }: PublishPanelProps): React.JSX.Element => {
    const { Panel, List, ListItem } = useSidebarPanel();

    const handleConfirm: ConfirmCallback = async (setLoading, setReady) => {
      if (onPublish && onSave) {
        setLoading();

        try {
          if (unsaved) {
            await onSave(true);
          } else {
            await onPublish();
          }
        } catch (unknownError) {
          const error = new CustomError(unknownError);
          console.log(error);
        } finally {
          await setReady();
        }
      }
    };

    const showUpdatedDetails = Boolean(updatedBy) && Boolean(dayjs(createdAt).diff(dayjs(updatedAt)));

    return (
      <Panel title='Publishing'>
        <List>
          {showUpdatedDetails && (
            <ListItem>
              <Label className={styles.label} size='xs'>
                Updated by {updatedBy}
              </Label>
              <DateComponent size='2xs'>{unsaved ? placeholder : formatDate(updatedAt)}</DateComponent>
            </ListItem>
          )}
          {createdBy && (
            <ListItem>
              <Label className={styles.label} size='xs'>
                Created by {createdBy}
              </Label>
              <DateComponent size='2xs'>{unsaved ? placeholder : formatDate(createdAt)}</DateComponent>
            </ListItem>
          )}
          {children}
          {(onPublish && onSave) || disabled ? (
            <ListItem className={styles.toggle}>
              <Confirm
                message={
                  <span>
                    Are you sure you want to {published ? 'unpublish' : 'publish'} <strong>{name}</strong>
                  </span>
                }
                onConfirm={handleConfirm}
              >
                {toggle => (
                  <ControlledFormLabel id='publish-switch' placement='right' label='Published'>
                    <Switch id='publish-switch' checked={published} onClick={() => toggle(true)} readOnly />
                  </ControlledFormLabel>
                )}
              </Confirm>
            </ListItem>
          ) : null}
        </List>
      </Panel>
    );
  }
);
