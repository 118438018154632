import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';
import { OwnProps as TextProps, TextNode } from '../text';

import styles from './date.module.scss';

type Sizes = 'l' | 'm' | 's' | 'xs' | '2xs' | '3xs';

export type Props = {
  size?: Sizes;
} & TextProps;

export const Component = (
  { size = 'l', children, className, ...props }: Props,
  ref?: Ref<HTMLSpanElement>
): React.JSX.Element => {
  return (
    <TextNode ref={ref} className={classnames(styles.container, styles[`size-${size}`], className)} {...props}>
      {children}
    </TextNode>
  );
};

export const DateComponent = forwardRef<HTMLSpanElement, Props>(Component);
