import { v4 as uuid } from 'uuid';

import { BUILDER_CONDITIONS, BUILDER_OPERATORS, RULE_VALUE } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { RULE, RULE_SET } from '~/pages/cohorts/edit/ConditionBuilder/context/reducer';
import { Dataset } from '~/types/gists/conditions';

export const generateRule = (): RULE => ({
  id: uuid(),
  condition: BUILDER_CONDITIONS.OR,
  operator: '' as BUILDER_OPERATORS,
  object: '',
  value: '' as RULE_VALUE,
  type: null,
  options: [] as Dataset['options']
});

export const generateDefaultRule = (): RULE => ({
  ...generateRule(),
  condition: BUILDER_CONDITIONS.DEFAULT
});

export const generateRuleset = (): RULE_SET => ({
  id: uuid(),
  condition: BUILDER_CONDITIONS.AND,
  ruleIds: []
});

export const generateDefaultRuleset = (): RULE_SET => ({
  ...generateRuleset(),
  condition: BUILDER_CONDITIONS.DEFAULT
});
