import React, { useCallback } from 'react';

import useDocumentTitle from '~/hooks/use-document-title';

import Structure from '~/pages/tags/components/Sidebar';
import useFetchTags from '~/pages/tags/hooks/useFetchTags';
import { withTagsModuleContext } from '~/pages/tags/hocs/withTagsModuleContext';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import Workspace from './components/Workspace';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';

import styles from './styles.module.scss';
import { AsyncButton, Button, useAsyncButton } from '~/components/button';
import { LOADING_MESSAGE, WELCOME_MESSAGE } from './constants';
import DeleteTags from '~/pages/tags/components/DeleteTags';
import AddTag from '~/pages/tags/components/AddTag';
import SaveTag from '~/pages/tags/components/SaveTag';
import { generateNewVirtualTag, resetTagsToOriginal, saveSelectedTag } from '~/pages/tags/context/store/thunks';
import { DiscardType } from '~/pages/tags/context/store/types';
import {
  setHasUnsavedChanges,
  setNextTagsIdsToSelect,
  setSelectedTagsById,
  setShouldShowDiscardChanges
} from '~/pages/tags/context/store/actions';
import { useSelector } from '~/store/hooks';

const Tags = (): React.JSX.Element => {
  useDocumentTitle('Tags');

  useFetchTags();

  const { state, dispatch } = useTagsModuleContext();

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { CoachPopover: LoadingPopover, ...loadingProps } = useCoachPopover({
    timeout: 1000,
    loading: state.isLoading
  });
  const { CoachPopover: WelcomePopover, ...welcomeProps } = useCoachPopover({
    disabled: isMainMenuOpened || state.isLoading || !!state.originalTags.length || !!state.errorMessage.length
  });
  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !state.errorMessage.length });
  const { CoachPopover: DiscardNewTagChanges, ...discardNewTagChangesProps } = useCoachPopover({
    disabled: !(state.shouldShowDiscardChanges && state.hasUnsavedChanges) || state.discardType !== DiscardType.NewTag
  });
  const { CoachPopover: DiscardTagChanges, ...discardTagChangesProps } = useCoachPopover({
    disabled:
      !(state.shouldShowDiscardChanges && state.hasUnsavedChanges) || state.discardType !== DiscardType.CreatedTag
  });
  const { CoachPopover: DiscardInvalidTagChanges, ...discardInvalidTagChangesProps } = useCoachPopover({
    disabled:
      !(state.shouldShowDiscardChanges && state.hasUnsavedChanges) ||
      state.discardType !== DiscardType.CreatedInvalidTag
  });

  const onWelcomeClick = useCallback(() => {
    welcomeProps.unsubscribe();
    dispatch(generateNewVirtualTag());
  }, [dispatch, welcomeProps]);

  const { loading, ready, setLoading, setReady } = useAsyncButton();

  const discardChanges = useCallback(() => {
    dispatch(resetTagsToOriginal());
    dispatch(setShouldShowDiscardChanges(false));
    dispatch(setHasUnsavedChanges(false));
    dispatch(setSelectedTagsById(state.nextTagsIdsToSelect));
    dispatch(setNextTagsIdsToSelect([]));
  }, [dispatch, state.nextTagsIdsToSelect]);

  const saveChanges = useCallback(async () => {
    setLoading();

    try {
      await dispatch(saveSelectedTag());
    } finally {
      dispatch(setSelectedTagsById(state.nextTagsIdsToSelect));
      dispatch(setNextTagsIdsToSelect([]));
      await setReady();
    }
  }, [dispatch, setLoading, setReady, state.nextTagsIdsToSelect]);

  const onDiscardNewTagChanges = useCallback(() => {
    discardChanges();
    discardNewTagChangesProps.unsubscribe();
  }, [discardChanges, discardNewTagChangesProps]);
  const onDiscardTagChanges = useCallback(() => {
    discardChanges();
    discardTagChangesProps.unsubscribe();
  }, [discardChanges, discardTagChangesProps]);
  const onDiscardInvalidTagChanges = useCallback(() => {
    discardChanges();
    discardInvalidTagChangesProps.unsubscribe();
  }, [discardChanges, discardInvalidTagChangesProps]);

  const onSaveNewTagChanges = useCallback(async () => {
    await saveChanges();
    discardNewTagChangesProps.unsubscribe();
  }, [discardNewTagChangesProps, saveChanges]);
  const onSaveTagChanges = useCallback(async () => {
    await saveChanges();
    discardTagChangesProps.unsubscribe();
  }, [discardTagChangesProps, saveChanges]);

  const onContinueEditingInvalidTag = useCallback(async () => {
    dispatch(setShouldShowDiscardChanges(false));
    discardInvalidTagChangesProps.unsubscribe();
  }, [discardInvalidTagChangesProps, dispatch]);

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Tags' />
        </Breadcrumb>
      </Slot>
      <Slot name='page-actions'>
        {state.virtualTags.length === state.originalTags.length ? <AddTag /> : null}
        <DeleteTags />
        {state.virtualTags.length && state.selectedTagsIds.length < 2 ? <SaveTag /> : null}
      </Slot>
      <section className={styles.container}>
        <Structure />
        <Workspace />
      </section>

      <LoadingPopover key='loading' message={LOADING_MESSAGE} {...loadingProps} />
      <WelcomePopover key='welcome' message={WELCOME_MESSAGE} {...welcomeProps}>
        <Button icon='plus' fluid is='major' onClick={onWelcomeClick}>
          Add a Tag
        </Button>
      </WelcomePopover>
      <ErrorPopover key='error' message={state.errorMessage} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
      <DiscardNewTagChanges
        key='discardNewTagChanges'
        message={['You haven’t saved your new Tag.', 'What would you like to do?']}
        {...discardNewTagChangesProps}
      >
        <div className={styles.buttons}>
          <Button onClick={onDiscardNewTagChanges} is='minor'>
            Discard it
          </Button>
          <AsyncButton is='major' onClick={onSaveNewTagChanges} ready={ready} loading={loading}>
            Save it
          </AsyncButton>
        </div>
      </DiscardNewTagChanges>
      <DiscardTagChanges
        key='discardTagChanges'
        message={['You’ve made changes to this Tag.', 'What would you like to do?']}
        {...discardTagChangesProps}
      >
        <div className={styles.buttons}>
          <Button onClick={onDiscardTagChanges} is='minor'>
            Discard changes
          </Button>
          <AsyncButton is='major' onClick={onSaveTagChanges} ready={ready} loading={loading}>
            Update the Tag
          </AsyncButton>
        </div>
      </DiscardTagChanges>
      <DiscardInvalidTagChanges
        key='discardInvalidTagChanges'
        message={[
          'You’ve made changes to this Tag.',
          'If you want to save those changes, please make sure you’ve given it a valid Name',
          'What would you like to do?'
        ]}
        {...discardInvalidTagChangesProps}
      >
        <div className={styles.buttons}>
          <Button onClick={onDiscardInvalidTagChanges} is='minor'>
            Discard changes
          </Button>
          <Button onClick={onContinueEditingInvalidTag} is='major'>
            Continue editing
          </Button>
        </div>
      </DiscardInvalidTagChanges>
    </>
  );
};

export default withTagsModuleContext(Tags);
