import { useCallback } from 'react';
import { useTheme } from '~/components/theme';
import { openInNewTab } from '~/helpers/common';

const useOpenProductInNewTab = (): (() => void) => {
  const { affiliateDetails } = useTheme();

  return useCallback(
    () => (affiliateDetails?.baseUrl ? openInNewTab(affiliateDetails?.baseUrl) : undefined),
    [affiliateDetails?.baseUrl]
  );
};

export default useOpenProductInNewTab;
