import React from 'react';

import { MetadataPanel } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/metadata-panel';
import PublishingPanel from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel';
import Tags from '~/pages/pages/edit/components/right-sidebar/modes/page/components/tags';
import SeoPanel from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo';
import DisplayConditions from '~/pages/pages/edit/components/right-sidebar/modes/page/components/display-conditions';

const PageSidebar = (): React.JSX.Element => {
  return (
    <>
      <PublishingPanel />
      <MetadataPanel />
      <DisplayConditions />
      <Tags />
      <SeoPanel />
    </>
  );
};

export default PageSidebar;
