import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CustomError } from '~/helpers/common/custom-error';

import useDocumentTitle from '~/hooks/use-document-title';

import { TextField } from '~/components/_form/fields/text';
import { ButtonField } from '~/components/_form/fields/button';
import { OnboardingTemplate } from '~/templates/onboarding';
import { emailSchema } from '~/helpers/validations/onboarding';
import { onboarding } from '~/api/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const initialValues = {
  email: ''
};

const MESSAGE = ['If you’ve forgotten your password, you’ll need to reset it.', 'Enter your email address to begin'];

export const ForgotPasswordEmail = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onSubmit = async ({ email }: { email: string }, { setFieldError }) => {
    try {
      await onboarding.forgotPasswordRequest({ email });

      localStorage.setItem('email', email);
      history.push(ONBOARDING_ROUTES.forgotPasswordUpPassword);
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      setFieldError('email', error.message);
    }
  };

  return (
    <OnboardingTemplate message={MESSAGE} backButton>
      <Formik validationSchema={emailSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <TextField name='email' label='Email' />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};
