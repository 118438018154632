import { useContext } from 'react';
import { ContextReturnType, FilePrimaryCellContext } from '~/components/_table/cells/file-primary/context/index';

const useFilePrimaryCellContext = (): ContextReturnType => {
  const context = useContext(FilePrimaryCellContext);
  if (context === undefined) {
    throw new Error('useFilePrimaryCellContext must be used within a FilePrimaryCellProvider');
  }

  return context;
};

export default useFilePrimaryCellContext;
