import { matchPath } from 'react-router';
import { publicRoutes } from '~/routes/public';
import { RouteProps, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ONBOARDING_ROUTES } from '~/routes/constants';

export const NO_MATCH_ROUTE = '';

const activeRouteToBGColorMap = {
  [ONBOARDING_ROUTES.greeting]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.signInEmail]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.signInPassword]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)',
  [ONBOARDING_ROUTES.signInLoggingIn]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)',
  [ONBOARDING_ROUTES.setupPermissions]: 'linear-gradient(135deg, #0EB004 0%, #C4E40A 100%)',
  [ONBOARDING_ROUTES.forgotPasswordUpEmail]: 'linear-gradient(135deg, #F54E25 0%, #F5C413 100%)',
  [ONBOARDING_ROUTES.forgotPasswordUpPassword]: 'linear-gradient(135deg, #F54E25 0%, #F5C413 100%)',
  [ONBOARDING_ROUTES.forgotPasswordUpConfirm]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)',
  [ONBOARDING_ROUTES.newCodeSent]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.signUpEmail]: 'linear-gradient(135deg, #0EB004 0%, #C4E40A 100%)',
  [ONBOARDING_ROUTES.signUpPassword]: 'linear-gradient(135deg, #0EB004 0%, #C4E40A 100%)',
  [ONBOARDING_ROUTES.signUpConfirm]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)',
  [ONBOARDING_ROUTES.smsMfa]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.contactTeam]: 'linear-gradient(135deg, #0EB004 0%, #C4E40A 100%)',
  [ONBOARDING_ROUTES.error]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)',
  [ONBOARDING_ROUTES.phoneSetup]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.phoneVerify]: 'linear-gradient(135deg, #D900CD 0%, #F63D29 100%)',
  [ONBOARDING_ROUTES.mfaSetupComplete]: 'linear-gradient(135deg, #0783F4 0%, #2CBA85 100%)'
};

const useBackgroundColorByRoute = (): string => {
  const { pathname } = useLocation();
  const [activeRoute, setActiveRoute] = useState<string>(NO_MATCH_ROUTE);
  useEffect(() => {
    const route = publicRoutes.find(route => matchPath(pathname, { path: route.to, exact: true } as RouteProps));
    if (route) {
      setActiveRoute(route.to as string);
    }
  }, [pathname]);

  return activeRouteToBGColorMap[activeRoute] ?? NO_MATCH_ROUTE;
};

export default useBackgroundColorByRoute;
