import React, { useCallback, useEffect } from 'react';

import useMountEffect from '~/hooks/use-mount-effect';

import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';
import useTagsSelector from '~/templates/tags-interaction/context/hooks/useTagsSelector';
import {
  getError,
  getInteractionMode,
  getIsLoading,
  getSelectedTags
} from '~/templates/tags-interaction/context/store/selectors';
import Loader from '~/components/loader';
import { INTERACTION_MODES } from '~/templates/tags-interaction/context/constants';
import TagsAssignFlow from '~/templates/tags-interaction/shapes/popover/components/AssignFlow';
import CreateNewTagForm from '~/templates/tags-interaction/components/CreateNewTagForm';
import useTagsFetch from '~/templates/tags-interaction/context/hooks/useTagsFetch';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import { enableAssignMode, initSelectedTags, setNewTagName } from '~/templates/tags-interaction/context/store/actions';
import { OriginalTag } from '~/types/gists/tag';

type Props = {
  onAssignTags: (tags: OriginalTag[]) => void;
  initialAssignedTags: OriginalTag[];
  onCreateNewTag?: (newTag: OriginalTag) => void;
};

const InteractionPopoverFlow = ({
  onAssignTags,
  onCreateNewTag,
  initialAssignedTags = []
}: Props): React.JSX.Element => {
  const { dispatch } = useTagsInteractionContext();

  useTagsFetch();
  useMountEffect(() => dispatch(initSelectedTags(initialAssignedTags)));

  const error = useTagsSelector(getError);
  const isLoading = useTagsSelector(getIsLoading);
  const interactionMode = useTagsSelector(getInteractionMode);
  const selectedTags = useTagsSelector(getSelectedTags);

  useEffect(() => onAssignTags(selectedTags), [onAssignTags, selectedTags]);
  const { CoachPopover: Error, ...errorProps } = useCoachPopover({ disabled: !error.length });

  const onBackFromCreationFlow = useCallback(() => {
    setTimeout(() => {
      dispatch(enableAssignMode());
      dispatch(setNewTagName(''));
    }, 0);
  }, [dispatch]);

  const ActiveFlow = useCallback(() => {
    if (!onCreateNewTag) {
      return <TagsAssignFlow disabledCreationMode={true} />;
    }

    return interactionMode === INTERACTION_MODES.ASSIGN ? (
      <TagsAssignFlow />
    ) : (
      <CreateNewTagForm onCreateNewTag={onCreateNewTag} onBack={onBackFromCreationFlow} />
    );
  }, [interactionMode, onBackFromCreationFlow, onCreateNewTag]);

  return (
    <>
      {isLoading ? <Loader /> : <ActiveFlow />}
      <Error message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </Error>
    </>
  );
};

export default InteractionPopoverFlow;
