import React, { createContext, useMemo } from 'react';
import { useTheme } from '~/components/theme';
import { PersistingService } from '~/pages/pages/edit/context/store/persisting-service';
import { initialState, reducer } from '~/pages/pages/edit/context/store/reducer';
import { ContextReturnType } from '~/pages/pages/edit/context/store/types';
import { useThunkReducer } from '~/hooks/use-thunk-reducer';

export const Context: React.Context<ContextReturnType> = createContext<ContextReturnType>({
  state: initialState,
  dispatch: () => void 0
} as ContextReturnType);

type PageStoreProps = {
  children: React.ReactNode;
};

export const PageStore = ({ children }: PageStoreProps): React.JSX.Element => {
  const { env, affiliate } = useTheme();

  const [state, dispatch] = useThunkReducer(reducer, {
    ...initialState,
    preview: {
      ...initialState.preview,
      ...PersistingService.getPreview({ env, affiliate, section: 'preview' })
    },
    isNew: window.location.pathname.includes('new')
  });

  const contextValue = useMemo<ContextReturnType>(
    () => ({
      state,
      dispatch
    }),
    [dispatch, state]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
