import React, { useCallback, useEffect, useRef } from 'react';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getAvailableImageByKey, getSelectedBlock, getSelectedLayer } from '~/pages/pages/edit/context/store/selectors';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { ControlledFormLabel } from '~/components/_form/components/label';
import { FilePicker } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { FileObject } from '~/types/gists/file';
import {
  setError,
  setLoading,
  updateAvailableImages,
  updateBlockContent,
  updateBlockData
} from '~/pages/pages/edit/context/store/actions';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import styles from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/styles.module.scss';
import { useSidebarPanel } from '~/components/_layout/panels';
import { replaceAttributes } from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/components/image-metadata/utils';
import { BlockLayer, PublicBlock } from '~/types/gists/page';

import ImageBlockDefault from '~/assets/images/image_block_default.svg';

const ImageMetadata = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const { Panel, List, ListItem } = useSidebarPanel();

  const block = usePageEditorSelector(getSelectedBlock) as PublicBlock;
  const layer = usePageEditorSelector(getSelectedLayer) as BlockLayer;

  const onChangeImage = useCallback(
    (file?: FileObject) => () => {
      if (file) {
        const newSrcValue = '/config/' + file?.key;
        const newAltValue = file.altText || 'default alt text';
        const newBlockContent = replaceAttributes(block.data.content, newSrcValue, newAltValue);
        dispatch(updateBlockContent(block.id, newBlockContent));

        dispatch(updateBlockData(block.id, { fileKey: file.key }));
        dispatch(updateAvailableImages(file));
      } else {
        const newBlockContent = replaceAttributes(block.data.content, ImageBlockDefault, 'default alt text');
        dispatch(updateBlockContent(block.id, newBlockContent));
        dispatch(updateBlockData(block.id, { fileKey: null }));
      }
    },
    [block.data.content, block.id, dispatch]
  );

  const setPageError = useCallback(
    (error: string | null) => {
      dispatch(setError(error));
    },
    [dispatch]
  );

  const setPageLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setLoading(isLoading));
    },
    [dispatch]
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (layer.meta?.shouldHandleMountBehaviour) {
      ref.current?.click();
    }
  }, [layer.meta?.shouldHandleMountBehaviour]);

  const getImageByKey = useCallback(
    state => getAvailableImageByKey(state, { key: block.data?.fileKey }),
    [block.data?.fileKey]
  );

  const image = usePageEditorSelector(getImageByKey);

  return (
    <Panel className={styles.container} title={'Image Metadata'}>
      <List>
        <ListItem withoutBorder>
          <ControlledFormLabel id='Image' label='Image file'>
            <FilePicker
              ref={ref}
              inputId='Image'
              activeFolder={FOLDER_TYPES.IMAGES}
              file={image}
              handleFileInteraction={onChangeImage}
              setPageError={setPageError}
              setPageLoading={setPageLoading}
            />
          </ControlledFormLabel>
        </ListItem>
      </List>
    </Panel>
  );
};

export default ImageMetadata;
