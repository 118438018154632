import React, { ChangeEvent, useCallback, useState } from 'react';
import { ActiveFolder, OuterUploaderHandlers } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { UsePopover } from '~/components/popover';
import { ObjectShim } from '~/helpers/shims/object-shim';
import ErrorPopover from '~/pages/files/components/files-table/components/add-files/components/uploader/components/ErrorPopover';
import UploadButton from '~/pages/files/components/files-table/components/add-files/components/uploader/components/UploadButton';
import {
  MAX_SIZE,
  MAX_SIZE_ERROR_MESSAGE,
  RESTRICTIONS_BY_FOLDER_TYPE
} from '~/pages/files/components/files-table/components/add-files/components/uploader/constants';
import { prepareFilesToUpload } from '~/pages/files/components/files-table/components/add-files/components/uploader/helpers';
import useUniquenessPopover from '~/pages/files/components/files-table/components/add-files/components/uploader/hooks/use-uniqueness-popover';
import { UploadFilesResponse } from '~/pages/files/components/files-table/components/add-files/components/uploader/types';
import { uploadFiles } from '~/pages/files/components/files-table/components/add-files/components/uploader/utils';
import { FileObject } from '~/types/gists/file';

type Props = ActiveFolder &
  OuterUploaderHandlers & {
    toggle: UsePopover['toggle'];
  };

export const FileUploader = React.forwardRef<HTMLInputElement, Props>(
  ({ activeFolder, toggle, handleFileInteraction, setPageLoading, setPageError }, ref) => {
    const [errorMessage, setErrorMessage] = useState<string[]>([]);

    const handleErrorPopoverClose = () => setErrorMessage([]);

    const startPageLoading = useCallback(() => {
      toggle(false);
      setPageLoading(true);
    }, [setPageLoading, toggle]);

    const onUploadError = useCallback(() => {
      setErrorMessage(['Something went wrong with uploading.', 'Check your files and try again, please.']);
      setPageError('Something went wrong with uploading.');
    }, [setPageError]);

    const finishLoading = useCallback(async () => {
      setPageLoading(false);
    }, [setPageLoading]);

    const handleUploadFilesResponse = useCallback(
      (result: UploadFilesResponse) => {
        if (!result) return;

        const uploadedImage = typeof result[0] === 'object' ? result[0][0] : result[0];

        handleFileInteraction(uploadedImage as FileObject)();
      },
      [handleFileInteraction]
    );

    const { showPopover: showUniquenessPopover, Popover: UniquenessPopover } = useUniquenessPopover({
      onError: onUploadError,
      onUploadStart: startPageLoading,
      onUploadFinish: finishLoading,
      activeFolder,
      handleUploadFilesResponse
    });

    const handleSingleFileToUpload = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (!files?.length || files?.length > 1) return;

        const filesArray = Array.from(files);

        const isMaxFileSizeExceed = filesArray.some(file => file.size > MAX_SIZE);
        if (isMaxFileSizeExceed) {
          setErrorMessage(MAX_SIZE_ERROR_MESSAGE);
          return;
        }

        setPageError(null);
        setErrorMessage([]);

        try {
          startPageLoading();

          const { uniquenessFilesMap, filesToUpload } = await prepareFilesToUpload({ filesArray, activeFolder });

          if (!ObjectShim.isEmpty(uniquenessFilesMap)) {
            showUniquenessPopover(filesToUpload);
          } else {
            const result: UploadFilesResponse = await uploadFiles(filesToUpload, activeFolder, onUploadError);

            handleUploadFilesResponse(result);
          }
        } catch (error) {
          console.error(error);
          onUploadError();
        } finally {
          await finishLoading();
        }
      },
      [
        setPageError,
        startPageLoading,
        activeFolder,
        showUniquenessPopover,
        onUploadError,
        handleUploadFilesResponse,
        finishLoading
      ]
    );

    return (
      <>
        <UploadButton
          ref={ref}
          multiple={false}
          onChange={handleSingleFileToUpload}
          accept={RESTRICTIONS_BY_FOLDER_TYPE[activeFolder]}
        />
        <ErrorPopover errorMessage={errorMessage} onClose={handleErrorPopoverClose} />
        <UniquenessPopover />
      </>
    );
  }
);
