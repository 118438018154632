import React, { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react';

import { reducer, RULE, RULE_SET, State } from '~/pages/cohorts/edit/ConditionBuilder/context/reducer';
import * as conditionQueryActions from '~/pages/cohorts/edit/ConditionBuilder/context/actions';
import {
  BUILDER_CONDITIONS,
  BUILDER_OPERATORS,
  BUILDER_TYPES,
  DEFAULT_VIEW_MODE,
  RULE_VALUE,
  VIEW_MODES
} from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { conditions } from '~/api/conditions';
import { generateDefaultRule, generateDefaultRuleset } from '~/pages/cohorts/edit/ConditionBuilder/context/helpers';
import {
  parseConditionQueryToVisualViewModeStructure,
  parseVisualModeStructureToConditionQuery
} from '~/pages/cohorts/edit/ConditionBuilder/helpers';
import { Dataset } from '~/types/gists/conditions';

type SetConditionQuery = (conditionQuery: string) => void;
type SetViewMode = (viewMode: VIEW_MODES) => void;
type SetRuleObjectById = (ruleId: string, object: string) => void;
type SetRuleTypeById = (ruleId: string, type: BUILDER_TYPES | null) => void;
type SetRuleOperatorById = (ruleId: string, operator: BUILDER_OPERATORS) => void;
type SetRuleValueById = (ruleId: string, value: RULE_VALUE) => void;
type SetRuleConditionById = (ruleId: string, condition: BUILDER_CONDITIONS) => void;
type SetRuleComputableById = (ruleId: string, computable: boolean) => void;
type SetIsLoading = (isLoading: boolean) => void;
type SetRuleSetConditionById = (ruleId: string, condition: BUILDER_CONDITIONS) => void;
type DuplicateRuleById = (ruleId: string) => void;
type DeleteRuleById = (ruleId: string, rulesetId: string) => void;
type IsLastRuleInRuleset = (rulesetId: string) => boolean;
type IsLastRuleset = () => boolean;
type DeleteRulesetById = (rulesetId: string) => void;
type AddRule = (rulesetId: string) => void;
type AddRuleset = () => void;
type SetRules = (rules: Record<string, RULE>) => void;
type SetRulesets = (rules: Record<string, RULE_SET>) => void;
type ConvertCodeViewToVisualView = () => void;
type ConvertVisualViewToCodeView = () => void;
type SetIsError = (isError: boolean) => void;
type ResetVisualView = () => void;
type SetupInitialVisualViewData = () => void;
type SetRuleOptionsById = (ruleId: string, options: Dataset['options']) => void;

export type ContextReturnType = State & {
  setConditionQuery: SetConditionQuery;
  setViewMode: SetViewMode;
  setRuleObjectById: SetRuleObjectById;
  setRuleTypeById: SetRuleTypeById;
  setRuleOperatorById: SetRuleOperatorById;
  setRuleValueById: SetRuleValueById;
  setRuleConditionById: SetRuleConditionById;
  setRuleSetConditionById: SetRuleSetConditionById;
  setIsLoading: SetIsLoading;
  duplicateRuleById: DuplicateRuleById;
  deleteRuleById: DeleteRuleById;
  isLastRuleInRuleset: IsLastRuleInRuleset;
  isLastRuleset: IsLastRuleset;
  deleteRulesetById: DeleteRulesetById;
  addRule: AddRule;
  addRuleset: AddRuleset;
  setRules: SetRules;
  setRulesets: SetRulesets;
  convertCodeViewToVisualView: ConvertCodeViewToVisualView;
  convertVisualViewToCodeView: ConvertVisualViewToCodeView;
  setIsError: SetIsError;
  resetVisualView: ResetVisualView;
  setupInitialVisualViewData: SetupInitialVisualViewData;
  setRuleComputableById: SetRuleComputableById;
  setRuleOptionsById: SetRuleOptionsById;
};

export const initialState: ContextReturnType = {
  conditionQuery: '',
  viewMode: DEFAULT_VIEW_MODE,
  datasets: [],
  ruleSets: {},
  rules: {},
  isLoading: false,
  isError: false,
  setConditionQuery: () => void 0,
  setViewMode: () => void 0,
  setRuleObjectById: () => void 0,
  setRuleTypeById: () => void 0,
  setRuleOperatorById: () => void 0,
  setRuleValueById: () => void 0,
  setRuleConditionById: () => void 0,
  setIsLoading: () => void 0,
  setRuleSetConditionById: () => void 0,
  duplicateRuleById: () => void 0,
  deleteRuleById: () => void 0,
  isLastRuleInRuleset: () => true,
  isLastRuleset: () => true,
  deleteRulesetById: () => void 0,
  addRule: () => void 0,
  addRuleset: () => void 0,
  setRules: () => void 0,
  setRulesets: () => void 0,
  convertCodeViewToVisualView: () => void 0,
  convertVisualViewToCodeView: () => void 0,
  setIsError: () => void 0,
  resetVisualView: () => void 0,
  setupInitialVisualViewData: () => void 0,
  setRuleComputableById: () => void 0,
  setRuleOptionsById: () => void 0
};

const ConditionBuilderContext = createContext<ContextReturnType>(initialState);

const ConditionBuilderProvider = ({ children }: { children: ReactNode }): React.JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setIsLoading = useCallback((isLoading: boolean) => dispatch(conditionQueryActions.setIsLoading(isLoading)), []);

  // useEffect is always called after the render phase of the component.
  // In order to make data load before render phase and avoid unnecessary re-renders, we use useMemo, which do not wait until component will be rendered.
  useMemo(() => {
    setIsLoading(true);

    conditions
      .fetchDataSets()
      .then(datasets => dispatch(conditionQueryActions.setDataSets(datasets)))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setConditionQuery = useCallback<SetConditionQuery>(
    (conditionQuery: string) => dispatch(conditionQueryActions.setConditionQuery(conditionQuery)),
    []
  );

  const setViewMode = useCallback<SetViewMode>(
    (viewMode: VIEW_MODES) => dispatch(conditionQueryActions.setViewMode(viewMode)),
    []
  );

  const setRuleObjectById = useCallback<SetRuleObjectById>(
    (ruleId: string, object: string) => dispatch(conditionQueryActions.updateRuleById(ruleId, { object })),
    []
  );

  const setRuleTypeById = useCallback<SetRuleTypeById>((ruleId, type) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { type }));
  }, []);

  const setRuleOperatorById = useCallback<SetRuleOperatorById>((ruleId: string, operator: BUILDER_OPERATORS) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { operator }));
  }, []);

  const setRuleValueById = useCallback<SetRuleValueById>((ruleId: string, value: RULE_VALUE) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { value }));
  }, []);

  const setRuleConditionById = useCallback<SetRuleConditionById>((ruleId: string, condition: BUILDER_CONDITIONS) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { condition }));
  }, []);

  const setRuleComputableById = useCallback<SetRuleComputableById>((ruleId, computable) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { computable }));
  }, []);

  const setRuleSetConditionById = useCallback<SetRuleSetConditionById>(
    (ruleSetId: string, condition: BUILDER_CONDITIONS) => {
      dispatch(conditionQueryActions.updateRuleSetById(ruleSetId, { condition }));
    },
    []
  );

  const duplicateRuleById = useCallback<DuplicateRuleById>(ruleId => {
    dispatch(conditionQueryActions.duplicateRuleById(ruleId));
  }, []);

  const isLastRuleset = useCallback<IsLastRuleset>(() => Object.keys(state.ruleSets).length === 1, [state.ruleSets]);
  const isLastRuleInRuleset = useCallback<IsLastRuleInRuleset>(
    rulesetId => {
      const ruleset = state.ruleSets[rulesetId];

      return ruleset.ruleIds.length === 1;
    },
    [state.ruleSets]
  );

  const deleteRulesetById = useCallback<DeleteRulesetById>(
    rulesetId => {
      if (state.ruleSets[rulesetId].condition === BUILDER_CONDITIONS.DEFAULT) {
        const rulesetsIds = Object.keys(state.ruleSets);
        const nextRulesetId = rulesetsIds[rulesetsIds.indexOf(rulesetId) + 1];

        setRuleSetConditionById(nextRulesetId, BUILDER_CONDITIONS.DEFAULT);
      }

      dispatch(conditionQueryActions.deleteRulesetById(rulesetId));
    },
    [setRuleSetConditionById, state.ruleSets]
  );

  const deleteRuleById = useCallback<DeleteRuleById>(
    (ruleId, rulesetId) => {
      if (state.rules[ruleId].condition === BUILDER_CONDITIONS.DEFAULT) {
        const nextRuleIndex = state.ruleSets[rulesetId].ruleIds.indexOf(ruleId) + 1;
        const nextRuleId = state.ruleSets[rulesetId].ruleIds[nextRuleIndex];
        setRuleConditionById(nextRuleId, BUILDER_CONDITIONS.DEFAULT);
      }

      dispatch(conditionQueryActions.deleteRuleById(ruleId, rulesetId));
    },
    [setRuleConditionById, state.ruleSets, state.rules]
  );

  const addRule = useCallback<AddRule>(rulesetId => {
    dispatch(conditionQueryActions.addRule(rulesetId));
  }, []);

  const addRuleset = useCallback<AddRuleset>(() => {
    dispatch(conditionQueryActions.addRuleset());
  }, []);

  const setRules = useCallback<SetRules>(rules => {
    dispatch(conditionQueryActions.setRules(rules));
  }, []);

  const setRulesets = useCallback<SetRulesets>((rulesets: Record<string, RULE_SET>) => {
    dispatch(conditionQueryActions.setRulesets(rulesets));
  }, []);

  const setIsError = useCallback<SetIsError>(isError => {
    dispatch(conditionQueryActions.setIsError(isError));
  }, []);

  const setupInitialVisualViewData = useCallback<SetupInitialVisualViewData>(() => {
    const DEFAULT_RULE = generateDefaultRule();
    const DEFAULT_RULESET = generateDefaultRuleset();

    dispatch(
      conditionQueryActions.update({
        ruleSets: {
          [DEFAULT_RULESET.id]: {
            ...DEFAULT_RULESET,
            ruleIds: [DEFAULT_RULE.id]
          }
        },
        rules: {
          [DEFAULT_RULE.id]: DEFAULT_RULE
        }
      })
    );
  }, []);

  const convertCodeViewToVisualView = useCallback<ConvertCodeViewToVisualView>(
    () =>
      parseConditionQueryToVisualViewModeStructure(state.conditionQuery)
        .then(parsedCodeView => {
          const { rules, ruleSets } = parsedCodeView;
          dispatch(
            conditionQueryActions.update({
              ruleSets,
              rules
            })
          );
        })
        .catch(() => {
          setIsError(true);
        }),
    [setIsError, state.conditionQuery]
  );

  const convertVisualViewToCodeView = useCallback<ConvertVisualViewToCodeView>(() => {
    setConditionQuery(parseVisualModeStructureToConditionQuery(state.ruleSets, state.rules));
  }, [setConditionQuery, state.ruleSets, state.rules]);

  const resetVisualView = useCallback<ResetVisualView>(() => {
    dispatch(
      conditionQueryActions.update({
        ruleSets: {},
        rules: {},
        isError: false,
        isLoading: false
      })
    );
  }, []);

  const setRuleOptionsById = useCallback<SetRuleOptionsById>((ruleId, options) => {
    dispatch(conditionQueryActions.updateRuleById(ruleId, { options }));
  }, []);

  return (
    <ConditionBuilderContext.Provider
      value={{
        conditionQuery: state.conditionQuery,
        viewMode: state.viewMode,
        rules: state.rules,
        ruleSets: state.ruleSets,
        datasets: state.datasets,
        isLoading: state.isLoading,
        isError: state.isError,
        setConditionQuery,
        setViewMode,
        setRuleObjectById,
        setRuleTypeById,
        setRuleOperatorById,
        setRuleValueById,
        setRuleConditionById,
        setIsLoading,
        setRuleSetConditionById,
        duplicateRuleById,
        deleteRuleById,
        isLastRuleInRuleset,
        isLastRuleset,
        deleteRulesetById,
        addRule,
        addRuleset,
        setRules,
        setRulesets,
        convertCodeViewToVisualView,
        convertVisualViewToCodeView,
        setIsError,
        resetVisualView,
        setupInitialVisualViewData,
        setRuleComputableById,
        setRuleOptionsById
      }}
    >
      {children}
    </ConditionBuilderContext.Provider>
  );
};

export default ConditionBuilderProvider;
export { ConditionBuilderContext };
