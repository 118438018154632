import React from 'react';

import { COMPONENT_TYPES, BulletListVariants, NumberListVariants } from '@life-moments/lifehub-components';

import Variant from '~/pages/blocks/edit/components/preview/components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: BulletListVariants[] | NumberListVariants[];
  content: string;
};

const BulletListBlockVariants = ({ variants, content }: Props): React.JSX.Element => {
  return (
    <div className={styles.list}>
      {variants.map(variant => (
        <Variant key={variant.name} variant={variant} type={COMPONENT_TYPES.bulletList} content={content} />
      ))}
    </div>
  );
};

export default BulletListBlockVariants;
