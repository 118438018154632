import { createAction, createReducer } from '@reduxjs/toolkit';

const PUSH_COACH_ENTRY = 'coach/push';
const POP_COACH_ENTRY = 'coach/pop';

export const push = createAction(PUSH_COACH_ENTRY, (id: string) => {
  return {
    payload: { id }
  };
});

export const pop = createAction(POP_COACH_ENTRY, (id: string) => {
  return {
    payload: { id }
  };
});

type State = {
  key: string | null;
  active: boolean;
  queue: string[];
};

export const initialState: State = {
  key: null,
  active: false,
  queue: []
};

export const coach = createReducer<State>(initialState, builder => {
  builder.addCase(push, (state, action) => {
    const queue = [...state.queue, action.payload.id];

    state.active = Boolean(queue.length);
    state.key = state.active ? queue[0] : null;
    state.queue = queue;
  });
  builder.addCase(pop, (state, action) => {
    const queue = state.queue.filter(id => id !== action.payload.id);

    state.active = Boolean(queue.length);
    state.key = state.active ? queue[0] : null;
    state.queue = queue;
  });
});
