import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableAction } from 'react-table';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { DeleteAction } from '~/components/_layout/page-actions/delete-action';
import { CoachPopoverComponent } from '~/components/_layout/popovers/import-export/coach-popover';
import { useSlotController } from '~/components/_layout/popovers/import-export/use-coach-slot-controller';
import { Table } from '~/components/_table';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { useTableImport } from '~/components/_table/hooks/use-table-import';
import { TableApi } from '~/components/_table/types';
import { Button, LinkButton } from '~/components/button';
import { Slot } from '~/components/slots';
import { useTheme } from '~/components/theme';
import useDocumentTitle from '~/hooks/use-document-title';
import useLifehubComponentsStyles from '~/pages/pages/edit/context/hooks/use-lifehub-components-styles';
import { columns } from '~/pages/pages/columns';
import { WELCOME_MESSAGE } from '~/pages/pages/messages';
import { NEW_PAGE_ROUTE, PAGES_ROUTES } from '~/routes/private/pages/constants';
import { TableStorageService } from '~/services/table-service';
import { Page } from '~/types/gists/page';
import { WrapAction } from '~/components/_layout/page-actions/wrap-action';
import { useTableExport } from '~/components/_table/hooks/use-table-export';
import { ExportAction } from '~/components/_layout/page-actions/export-action';
import { buildExportParamsFromTable } from '~/components/_table/hooks/use-table-export/helpers';
import { ImportAction } from '~/components/_layout/page-actions/import-action';
import { useSelector } from '~/store/hooks';

const gist: TableApi = 'pages';

const defaultSortBy = [
  {
    id: 'createdAt',
    desc: true
  }
];

const Pages = (): React.JSX.Element => {
  useDocumentTitle('Pages');
  useLifehubComponentsStyles();

  const { push } = useHistory();
  const { affiliate, env } = useTheme();
  const { search } = useLocation();

  const { get, remove, removeSelected, items, isEmpty, count, cloneSingle, editCell, publish } = useTableFetch<Page>({
    gist
  });

  const goToPage = useCallback<TableAction<Page>['handler']>(
    ({ row: { original } }) => push(`${PAGES_ROUTES.root}/${original.id}${search}`),
    [push, search]
  );

  const actions = useMemo<TableAction<Page>[]>(() => {
    return [
      {
        id: 'edit',
        icon: 'edit',
        handler: goToPage,
        tooltip: 'Edit Page'
      },
      {
        id: 'duplicate',
        icon: 'duplicate',
        handler: cloneSingle,
        tooltip: 'Duplicate Page'
      },
      {
        id: 'delete',
        icon: 'bin',
        confirm: { origin: 'name' },
        handler: remove,
        tooltip: 'Delete'
      }
    ];
  }, [cloneSingle, goToPage, remove]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { CoachPopover: WelcomePopover, ...welcomeProps } = useCoachPopover({
    disabled: isMainMenuOpened || !isEmpty || TableStorageService.isEmptyFilters({ affiliate, env, gist })
  });

  const { Popover: ExportPopover, onOpenExport, onSubmitExport, ...exportProps } = useTableExport<Page>({ gist });
  const { Popover: ImportPopover, onOpenImport, onImportSubmit, ...importProps } = useTableImport<Page>({ gist });

  const {
    onOpenNewCoachContent,
    onCloseCoachContent,
    CoachPopover: CoachImportPopover,
    ...coachImportProps
  } = useSlotController({ unsubscribePrevious: welcomeProps.unsubscribe, unsubscribeCurrent: importProps.toggle });

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Pages' />
        </Breadcrumb>
      </Slot>
      <Table<Page>
        key={`${affiliate}_${env}_${gist}`}
        gist={gist}
        columns={columns}
        onEditCell={editCell}
        data={items}
        actions={actions}
        manualSortBy
        defaultSortBy={defaultSortBy}
        manualFilters
        manualPagination
        actionsColumnWidth={134}
        onUpdate={get}
        count={count}
        onPublish={publish}
      >
        {instance => {
          const refetch = async () => await instance.refetchTable();
          const openExportOptions = buildExportParamsFromTable(instance);
          const tooltipText = openExportOptions.isExportAll ? 'Export all' : 'Export selected';

          return (
            <>
              <Slot name='page-actions'>
                <WrapAction gist={gist} onChange={instance.setWrappingMode} />
                <ImportAction toggleImportPopover={onOpenImport} />
                <ImportPopover onSubmit={onImportSubmit} afterSubmit={refetch} {...importProps} />
                <ExportAction toggleExportPopover={onOpenExport(openExportOptions)} tooltipText={tooltipText} />
                <ExportPopover onSubmit={onSubmitExport} {...exportProps} />
                <DeleteAction items={instance.selectedFlatRows} onConfirm={removeSelected(instance)} gist={gist} />
                <LinkButton to={NEW_PAGE_ROUTE} icon='plus' fluid>
                  New Page
                </LinkButton>
              </Slot>
              <CoachImportPopover {...coachImportProps}>
                <CoachPopoverComponent
                  {...importProps}
                  {...coachImportProps}
                  toggle={onCloseCoachContent}
                  onSubmit={onImportSubmit}
                  afterSubmit={refetch}
                />
              </CoachImportPopover>
            </>
          );
        }}
      </Table>
      <WelcomePopover message={WELCOME_MESSAGE} {...welcomeProps}>
        <LinkButton to={NEW_PAGE_ROUTE} icon='plus' fluid>
          Add new Page
        </LinkButton>
        <Button onClick={onOpenNewCoachContent} is='minor' icon='import' fluid>
          Import Pages
        </Button>
      </WelcomePopover>
    </>
  );
};

export default Pages;
