import { Greeting } from '~/pages/onboarding/general/greeting';
import { NewCodeSent } from '~/pages/onboarding/general/new-code-sent';

import { SignInEmail } from '~/pages/onboarding/sign-in/email';
import { SignInPassword } from '~/pages/onboarding/sign-in/password';
import { LoggingIn } from '~/pages/onboarding/general/logging-in';

import { ForgotPasswordEmail } from '~/pages/onboarding/forgot-password/email';
import { ForgotPasswordPassword } from '~/pages/onboarding/forgot-password/password';
import { ForgotPasswordConfirm } from '~/pages/onboarding/forgot-password/confirm';

import { SignUpEmail } from '~/pages/onboarding/sign-up/email';
import { SignUpPassword } from '~/pages/onboarding/sign-up/password';
import { SignUpConfirm } from '~/pages/onboarding/sign-up/confirm';
import { SetupPermissions } from '~/pages/onboarding/setup-permissions';

import { ONBOARDING_ROUTES } from '~/routes/constants';

import { Route } from '~/routes/types';
import SmsMfa from '~/pages/onboarding/mfa/sms-mfa';
import Error from '~/pages/onboarding/general/error';
import ContactTeam from '~/pages/onboarding/general/contact-team';
import PhoneSetup from '~/pages/onboarding/mfa/phone-setup';
import PhoneVerify from '~/pages/onboarding/mfa/phone-verify';
import MfaSetupComplete from '~/pages/onboarding/mfa/mfa-setup-complete';

export const publicRoutes: Route[] = [
  {
    to: ONBOARDING_ROUTES.greeting,
    exact: true,
    component: Greeting
  },
  {
    to: ONBOARDING_ROUTES.signInEmail,
    exact: true,
    component: SignInEmail
  },
  {
    to: ONBOARDING_ROUTES.signInPassword,
    exact: true,
    component: SignInPassword
  },
  {
    to: ONBOARDING_ROUTES.signInLoggingIn,
    exact: true,
    component: LoggingIn
  },
  {
    to: ONBOARDING_ROUTES.forgotPasswordUpEmail,
    exact: true,
    component: ForgotPasswordEmail
  },
  {
    to: ONBOARDING_ROUTES.forgotPasswordUpPassword,
    exact: true,
    component: ForgotPasswordPassword
  },
  {
    to: ONBOARDING_ROUTES.forgotPasswordUpConfirm,
    exact: true,
    component: ForgotPasswordConfirm
  },
  {
    to: ONBOARDING_ROUTES.signUpEmail,
    exact: true,
    component: SignUpEmail
  },
  {
    to: ONBOARDING_ROUTES.signUpPassword,
    exact: true,
    component: SignUpPassword
  },
  {
    to: ONBOARDING_ROUTES.signUpConfirm,
    exact: true,
    component: SignUpConfirm
  },
  {
    to: ONBOARDING_ROUTES.newCodeSent,
    exact: true,
    component: NewCodeSent
  },
  {
    to: ONBOARDING_ROUTES.setupPermissions,
    exact: true,
    component: SetupPermissions
  },
  {
    to: ONBOARDING_ROUTES.smsMfa,
    exact: true,
    component: SmsMfa
  },
  {
    to: ONBOARDING_ROUTES.contactTeam,
    exact: true,
    component: ContactTeam
  },
  {
    to: ONBOARDING_ROUTES.error,
    exact: true,
    component: Error
  },
  {
    to: ONBOARDING_ROUTES.phoneSetup,
    exact: true,
    component: PhoneSetup
  },
  {
    to: ONBOARDING_ROUTES.phoneVerify,
    exact: true,
    component: PhoneVerify
  },
  {
    to: ONBOARDING_ROUTES.mfaSetupComplete,
    exact: true,
    component: MfaSetupComplete
  }
];
