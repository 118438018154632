import React, { useCallback, useState } from 'react';
import { saveAs, saveAsZip } from '~/helpers/common/file';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';

const useDownload = () => {
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const { CoachPopover: Error, ...errorProps } = useCoachPopover({ disabled: !errorMessage.length });

  const saveAsZipHandler = useCallback(async (urls: string[], zipName: string) => {
    try {
      await saveAsZip(urls, zipName);
      setErrorMessage([]);
    } catch (e) {
      console.error(e);
      setErrorMessage(['Something went wrong with downloading files.', 'Try again later.']);
    }
  }, []);

  const saveAsHandler = useCallback(async (urls: string[]) => {
    try {
      await saveAs(urls);
      setErrorMessage([]);
    } catch (e) {
      console.error(e);
      setErrorMessage(['Something went wrong with downloading file.', 'Try again later.']);
    }
  }, []);

  const ErrorMessage = useCallback(
    () => (
      <Error key='error' message={errorMessage} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </Error>
    ),
    [Error, errorMessage, errorProps]
  );

  return {
    saveAsZip: saveAsZipHandler,
    saveAs: saveAsHandler,
    ErrorMessage
  };
};

export default useDownload;
