import React from 'react';
import useDocumentTitle from '~/hooks/use-document-title';
import { OnboardingTemplate } from '~/templates/onboarding';

const ContactTeam = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  return (
    <OnboardingTemplate
      message='Please contact the Life Moments customer success team for help with log in issues.'
      backButton
    />
  );
};

export default ContactTeam;
