import React, { useCallback } from 'react';

import { FormLabel } from '~/components/_form/components/label';
import { useSegmentedButton } from '~/components/segmented-button';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedBlock } from '~/pages/pages/edit/context/store/selectors';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { updateBlockContent } from '~/pages/pages/edit/context/store/actions';
import {
  getCurrentHeadingTag,
  replaceHeadingTag
} from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-level/helpers';

import { Level } from './types';
import { PublicBlock } from '~/types/gists/page';

const levels: Level[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const BlockLevel = (): React.JSX.Element => {
  const selectedBlock = usePageEditorSelector(getSelectedBlock) as PublicBlock;

  const { Container, Segment, active, setActive } = useSegmentedButton<Level>(
    getCurrentHeadingTag(selectedBlock.data.content) || 'h2'
  );

  const dispatch = usePageEditorDispatch();

  const onClick = useCallback(
    (level: Level) => () => {
      setActive(level);

      const modifiedBlockContent = replaceHeadingTag(selectedBlock.data.content, level);
      dispatch(updateBlockContent(selectedBlock.id, modifiedBlockContent));
    },
    [dispatch, selectedBlock, setActive]
  );

  return (
    <FormLabel label='Level' placement='top'>
      <Container>
        {levels.map((level, index) => (
          <Segment
            key={level}
            active={level === active}
            onClick={onClick(level)}
            first={index === 0}
            last={index === levels.length - 1}
          >
            {level.toUpperCase()}
          </Segment>
        ))}
      </Container>
    </FormLabel>
  );
};

export default BlockLevel;
