import React from 'react';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { useAsyncButton } from '~/components/button';

type Props = {
  unsaved: boolean;
  onSave: () => Promise<void>;
  onUpdate: () => Promise<void>;
};

export const SaveAction = ({ unsaved, onSave, onUpdate }: Props): React.JSX.Element => {
  const { loading, ready, setLoading, setReady, Button: AsyncButton } = useAsyncButton();

  const handleSave = async () => {
    setLoading();

    try {
      unsaved ? await onSave() : await onUpdate();
    } catch (error) {
      console.log(error);
    }

    await setReady();
  };

  return (
    <TopBarTool>
      <AsyncButton is='minor' loading={loading} ready={ready} onClick={handleSave}>
        {unsaved ? 'Save' : 'Update'}
      </AsyncButton>
    </TopBarTool>
  );
};
