import React from 'react';
import { ErrorMessage } from 'formik';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';
import { Icon } from '~/components/icon';
import { SVGGlowFilter } from '~/components/icon/filters/glow';
import { Label } from '~/components/_layout/typography/label';

import styles from './form-error.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const Error = ({ children }: Props): React.JSX.Element | null => {
  const { theme } = useTheme();

  if (!children) {
    return null;
  }

  return (
    <div className={classnames(styles.container, styles[theme])} role='alert' aria-live='assertive'>
      <Icon className={styles.icon} name='alert' filter='url(#glow)'>
        <SVGGlowFilter id='glow' />
      </Icon>
      <div className={styles.text}>
        <Label size='xs'>{children}</Label>
      </div>
    </div>
  );
};

export const FormError = ({ name }: { name: string }): React.JSX.Element => (
  <ErrorMessage name={name}>{error => <Error>{error}</Error>}</ErrorMessage>
);
