import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PublishPanel } from '~/components/_layout/panels/publish';
import { PublishingForm } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel/components/publishing-form';
import { togglePublishPage } from '~/pages/pages/edit/components/right-sidebar/modes/page/thunks';
import { preSubmitValidation, savePage } from '~/pages/pages/edit/components/save-publish-buttons/thunks';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import {
  getIsNewPage,
  getIsPagePublished,
  getPageMetaData,
  getPageName
} from '~/pages/pages/edit/context/store/selectors';
import { commitPageChangesToStore } from '~/pages/pages/edit/context/store/thunks';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import OverrideField from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel/components/override-field';

const PublishingPanel = (): React.JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();

  const dispatch = usePageEditorDispatch();

  const isNew = usePageEditorSelector(getIsNewPage);
  const isEnabled = usePageEditorSelector(getIsPagePublished);
  const name = usePageEditorSelector(getPageName);
  const metaFields = usePageEditorSelector(getPageMetaData);
  const { createdAt, createdBy, updatedAt, updatedBy } = metaFields;

  const handleSave = useCallback(async () => {
    await dispatch(commitPageChangesToStore());
    const isValid = dispatch(preSubmitValidation());

    if (isValid) {
      const response = await dispatch(savePage(!isEnabled));
      if (response) {
        history.replace(`${PAGES_ROUTES.root}/${response.id}${search}`);
      }
    }
  }, [dispatch, history, isEnabled, search]);

  const handlePublish = useCallback(async () => {
    await dispatch(commitPageChangesToStore());
    const isValid = dispatch(preSubmitValidation());

    if (isValid) {
      dispatch(togglePublishPage());
    }
  }, [dispatch]);

  return (
    <PublishPanel
      name={name}
      createdAt={createdAt}
      updatedAt={updatedAt ?? undefined}
      createdBy={createdBy}
      updatedBy={updatedBy ?? undefined}
      unsaved={isNew}
      published={isEnabled}
      onSave={handleSave}
      onPublish={handlePublish}
    >
      <PublishingForm />
      <OverrideField />
    </PublishPanel>
  );
};

export default PublishingPanel;
