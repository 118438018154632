import { files as filesApi } from '~/api/files';
import { ActiveFolder } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { FilesToUploadMap } from '~/pages/files/components/files-table/components/add-files/components/uploader/types';

type PrepareFilesToUploadParams = ActiveFolder & {
  filesArray: File[];
};

export const prepareFilesToUpload = async ({
  activeFolder,
  filesArray
}: PrepareFilesToUploadParams): Promise<{
  filesToUpload: FilesToUploadMap;
  uniquenessFilesMap: Record<PropertyKey, unknown>;
}> => {
  const uniquenessFiles = await filesApi.validateNames({
    folder: activeFolder,
    names: filesArray.map(file => file.name)
  });

  const uniquenessFilesMap = uniquenessFiles.reduce((result, uniquenessFile) => {
    result[uniquenessFile.name] = uniquenessFile;
    return result;
  }, {});

  const filesToUpload = filesArray.reduce<FilesToUploadMap>((result, file) => {
    const uniquenessFile = uniquenessFilesMap[file.name];

    result[file.name] = {
      isUniqueness: !!uniquenessFile,
      target: uniquenessFile?.target,
      altName: uniquenessFile?.altName,
      file
    };

    return result;
  }, {});

  return { uniquenessFilesMap, filesToUpload };
};
