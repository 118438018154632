import React, { FunctionComponent, PropsWithChildren, SVGAttributes, useMemo } from 'react';
import classnames from 'classnames';

import styles from './icon.module.scss';

export type DefaultIcons = {
  alert: 'alert';
  angle: 'angle';
  'arrow-down': 'arrow-down';
  'arrow-left': 'arrow-left';
  'arrow-right': 'arrow-right';
  'arrow-up': 'arrow-up';
  bin: 'bin';
  block: 'block';
  blocks: 'blocks';
  pages: 'pages';
  chart: 'chart';
  code: 'code';
  container: 'container';
  cross: 'cross';
  duplicate: 'duplicate';
  edit: 'edit';
  export: 'export';
  eye: 'eye';
  filter: 'filter';
  'flex-align-baseline': 'flex-align-baseline';
  'flex-align-center': 'flex-align-center';
  'flex-align-end': 'flex-align-end';
  'flex-align-start': 'flex-align-start';
  'flex-align-stretch': 'flex-align-stretch';
  'flex-justify-around': 'flex-justify-around';
  'flex-justify-between': 'flex-justify-between';
  'flex-justify-center': 'flex-justify-center';
  'flex-justify-end': 'flex-justify-end';
  'flex-justify-evenly': 'flex-justify-evenly';
  'flex-justify-start': 'flex-justify-start';
  'grid-view': 'grid-view';
  home: 'home';
  image: 'image';
  import: 'import';
  laptop: 'laptop';
  'list-view': 'list-view';
  menu: 'menu';
  'mod-down': 'mod-down';
  'mod-left': 'mod-left';
  'mod-right': 'mod-right';
  'mod-up': 'mod-up';
  photo: 'photo';
  plus: 'plus';
  redo: 'redo';
  refresh: 'refresh';
  sort: 'sort';
  text: 'text';
  tick: 'tick';
  'tile-h': 'tile-h';
  'tile-h+v': 'tile-h+v';
  'tile-v': 'tile-v';
  token: 'token';
  undo: 'undo';
  'text-wrap': 'text-wrap';
  'text-nowrap': 'text-nowrap';
  blank: 'blank';
  tags: 'tags';
  'arrow-triangle-right': 'arrow-triangle-right';
  'double-triangle-down': 'double-triangle-down';
  checkmark: 'checkmark';
  grips: 'grips';
  asterisk: 'asterisk';
  font: 'font';
  save: 'save';
  files: 'files';
};

export type FeatureIcons = {
  activities: 'activities';
  'block-templates': 'block-templates';
  blocks: 'blocks';
  cohorts: 'cohorts';
  'conditional-messages': 'conditional-messages';
  conditions: 'conditions';
  'data-privacy': 'data-privacy';
  'email-templates': 'email-templates';
  emails: 'emails';
  fields: 'fields';
  files: 'files';
  flows: 'flows';
  marketplace: 'marketplace';
  'page-templates': 'page-templates';
  pages: 'pages';
  points: 'points';
  releases: 'releases';
  'scheduled-messages': 'scheduled-messages';
  styles: 'styles';
  tags: 'tags';
  tokens: 'tokens';
  insights: 'insights';
  datasets: 'datasets';
  settings: 'settings';
  google: 'google';
  facebook: 'facebook';
  twitter: 'twitter';
};

type ComponentProps = PropsWithChildren<{
  pathname: string;
  className?: string;
  withLabel?: boolean;
}> &
  SVGAttributes<SVGElement>;

const IconComponent = ({ pathname, className, children, withLabel, ...props }: ComponentProps): React.JSX.Element => {
  const Icon = useMemo<FunctionComponent<SVGAttributes<SVGElement>>>(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`~/assets/icons/${pathname}.icon.svg`).default ?? null;
  }, [pathname]);

  return (
    <div className={classnames(styles.container, className, { [styles.iconMargin]: withLabel })}>
      <svg>
        <defs>{children}</defs>
        <Icon data-icon={pathname} width='100%' height='100%' {...props} />
      </svg>
    </div>
  );
};

type Props<T> = {
  name: keyof T;
} & Omit<ComponentProps, 'pathname'>;

export const Icon = ({ name, ...props }: Props<DefaultIcons>): React.JSX.Element => {
  return <IconComponent pathname={`default/${name}`} {...props} />;
};

export const FeatureIcon = ({ name, ...props }: Props<FeatureIcons>): React.JSX.Element => {
  return <IconComponent pathname={`feature/${name}`} {...props} />;
};
