import React, { useCallback, useMemo } from 'react';
import { TableAction } from 'react-table';

import useDocumentTitle from '~/hooks/use-document-title';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { Table } from '~/components/_table';
import { columns } from '~/pages/datasets/columns';
import { Dataset } from '~/pages/datasets/types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '~/components/theme';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';

const STATIC_DATA: Dataset[] = [
  {
    name: 'Attributes',
    description: 'Personal data, mostly answers to questions we’ve asked users in tools',
    blank: '',
    id: 'attributes'
  }
];

const autoResetProps = {
  autoResetHiddenColumns: true,
  autoResetExpanded: true,
  autoResetFilters: true,
  autoResetGlobalFilter: true,
  autoResetGroupBy: true,
  autoResetPage: true,
  autoResetSelectedRows: true,
  autoResetRowState: true,
  autoResetSortBy: true
};

const NOOP = () => void 0;

const Datasets = (): React.JSX.Element => {
  useDocumentTitle('Datasets');

  const { push } = useHistory();
  const { affiliate, env } = useTheme();

  const goToDataset = useCallback<TableAction<Dataset>['handler']>(
    ({ row: { original } }) => push(`${DATASETS_ROUTES.root}/${original.id}?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, push]
  );

  const actions = useMemo<TableAction<Dataset>[]>(
    () => [
      {
        id: 'edit',
        icon: 'edit',
        handler: goToDataset,
        tooltip: 'Edit Dataset'
      }
    ],
    [goToDataset]
  );

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Datasets' />
        </Breadcrumb>
      </Slot>
      <Table<Dataset>
        gist={'datasets'}
        columns={columns}
        data={STATIC_DATA}
        count={STATIC_DATA.length}
        onUpdate={NOOP}
        actionsColumnWidth={134}
        disableFilters={true}
        disableSelection={true}
        disableSortBy={true}
        actions={actions}
        {...autoResetProps}
      />
    </>
  );
};

export default Datasets;
