import React, { useCallback, useMemo, useState } from 'react';
import { ActiveFolder } from '~/components/_layout/popovers/upload/components/inputs/picker';
import UniquenessPopover from '~/pages/files/components/files-table/components/add-files/components/uploader/components/UniquenessPopover';
import {
  FilesToUploadMap,
  UploadFilesResponse
} from '~/pages/files/components/files-table/components/add-files/components/uploader/types';
import {
  renameFile,
  uploadFiles
} from '~/pages/files/components/files-table/components/add-files/components/uploader/utils';
import useActiveFolderType from '~/pages/files/hooks/useActiveFolderType';

type UseUniquenessPopoverProps = Partial<ActiveFolder> & {
  onError: () => void;
  onUploadStart: () => void;
  onUploadFinish: () => Promise<void>;
  handleUploadFilesResponse?: (uploadResponse: UploadFilesResponse) => void;
};

type UseUniquenessModal = {
  showPopover: (files: FilesToUploadMap) => void;
  Popover: () => React.JSX.Element;
};

const useUniquenessPopover = ({
  onError,
  onUploadStart,
  onUploadFinish,
  activeFolder: activeFolderType,
  handleUploadFilesResponse
}: UseUniquenessPopoverProps): UseUniquenessModal => {
  const activeFolderByURL = useActiveFolderType();
  const activeFolder = activeFolderType ?? activeFolderByURL;

  const [show, setShow] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<FilesToUploadMap>({});

  const showPopover = useCallback((files: FilesToUploadMap) => {
    setFilesToUpload(files);
    setShow(true);
  }, []);

  const reset = useCallback(() => {
    setShow(false);
    setFilesToUpload({});
  }, []);

  const onReplaceFiles = useCallback(async () => {
    setShow(false);
    onUploadStart();

    const result = await uploadFiles(filesToUpload, activeFolder, onError);
    if (handleUploadFilesResponse) {
      handleUploadFilesResponse(result);
    }

    setFilesToUpload({});
    onUploadFinish();
  }, [activeFolder, filesToUpload, handleUploadFilesResponse, onError, onUploadFinish, onUploadStart]);

  const onMakeCopies = useCallback(async () => {
    const updatedFiles = Object.values(filesToUpload).reduce((result, fileToUpload) => {
      const updatedFile = {
        ...fileToUpload,
        isUniqueness: false,
        file: fileToUpload.isUniqueness
          ? renameFile(fileToUpload.file, fileToUpload.altName as string)
          : fileToUpload.file
      };
      result[updatedFile.isUniqueness ? (updatedFile.altName as string) : updatedFile.file.name] = updatedFile;
      return result;
    }, {});

    setShow(false);
    onUploadStart();

    const result = await uploadFiles(updatedFiles, activeFolder, onError);
    if (handleUploadFilesResponse) {
      handleUploadFilesResponse(result);
    }

    setFilesToUpload({});
    onUploadFinish();
  }, [activeFolder, filesToUpload, handleUploadFilesResponse, onError, onUploadFinish, onUploadStart]);

  const uniquenessFilesLength = useMemo(
    () => Object.values(filesToUpload).filter(file => file.isUniqueness).length,
    [filesToUpload]
  );

  const Popover = useCallback(
    () => (
      <UniquenessPopover
        onReplaceFiles={onReplaceFiles}
        onMakeCopies={onMakeCopies}
        onCancelUpload={reset}
        uniquenessFilesLength={uniquenessFilesLength}
        show={show}
      />
    ),
    [onMakeCopies, onReplaceFiles, reset, show, uniquenessFilesLength]
  );

  return {
    showPopover,
    Popover
  };
};

export default useUniquenessPopover;
