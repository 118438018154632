import React from 'react';
import { useSubscribe } from '~/components/slots';
import TopBar from './top-bar';
import { Sidebar } from './sidebar';

import styles from './layout.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props): React.JSX.Element => {
  const left = useSubscribe('left-sidebar');
  const right = useSubscribe('right-sidebar');

  return (
    <div className={styles.container}>
      <header className={styles.top}>
        <TopBar />
      </header>
      <div className={styles.content}>
        <aside className={styles.left}>
          <Sidebar ref={left} />
        </aside>
        <main className={styles.center}>{children}</main>
        <aside className={styles.right}>
          <Sidebar ref={right} />
        </aside>
      </div>
    </div>
  );
};
