import React from 'react';
import { useSidebarPanel } from '~/components/_layout/panels';
import { TagsInteractionBlock } from '~/templates/tags-interaction';
import { offset } from '~/helpers/ui-kit/popper/offset';
import { Options } from '@popperjs/core';
import { sameWidth } from '~/helpers/ui-kit/popper/same-width';
import { maxHeight } from '~/helpers/ui-kit/popper/max-size';
import { OriginalTag } from '~/types/gists/tag';

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => [-95, -reference.height + 45]
  }
};

const POPOVER: Partial<Options> = {
  placement: 'bottom',
  modifiers: [topOffset, sameWidth, ...maxHeight]
};

type Props = {
  tags: OriginalTag[];
  onChange: (name: string, value: OriginalTag[]) => void;
};

const TagsPanel = ({ tags = [], onChange }: Props): React.JSX.Element => {
  const { Panel } = useSidebarPanel();

  return (
    <Panel title='Tags'>
      <TagsInteractionBlock initialAssignedTags={tags} onChange={onChange} popoverOptions={POPOVER} />
    </Panel>
  );
};

export default TagsPanel;
