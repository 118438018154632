import React from 'react';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';

type Props = {
  errorMessage: string[];
  onClose: () => void;
};

const ErrorPopover = ({ errorMessage, onClose }: Props) => {
  const { CoachPopover, ...popoverProps } = useCoachPopover({ disabled: !errorMessage.length });

  return (
    <CoachPopover message={errorMessage} {...popoverProps}>
      <Button onClick={onClose} is='major' fluid>
        Ok
      </Button>
    </CoachPopover>
  );
};

export default ErrorPopover;
