import { CustomError } from '~/helpers/common/custom-error';
import { PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { setError } from '~/pages/pages/edit/context/store/actions';
import { pages as pagesApi } from '~/api/pages';
import { Page } from '~/types/gists/page';
import { getPageId } from '~/pages/pages/edit/context/store/selectors';

export const cloneCurrent = (): PageEditorThunk<Promise<Page | void>> => async (dispatch, getState) => {
  const id = getPageId(getState());

  try {
    return await pagesApi.cloneSingle<Page>({ id, isEnabled: false } as Page);
  } catch (unknownError) {
    const error = new CustomError(unknownError ?? 'Failed to clone page');
    dispatch(setError(error.message));

    return void 0;
  }
};
