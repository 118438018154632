import { AnimatePresence } from 'framer-motion';
import React, { memo } from 'react';
import { VARIANTS } from '~/components/_layout/coach/coach-popover';
import { ButtonProps, PopoverProps } from '~/components/_layout/popovers/confirm';
import { INITIAL_CODE_VALUE } from '~/components/_layout/popovers/import-export/constants';
import { ImportExportPopoverContent } from '~/components/_layout/popovers/import-export/content-component';
import { PopoverComponentOwnProps } from '~/components/_layout/popovers/import-export/types';
import { useImportExportPopoverHandlers } from '~/components/_layout/popovers/import-export/use-content-handlers';
import { Button } from '~/components/button';
import { MotionPopover } from '~/components/popover';
import { Slot } from '~/components/slots';
import { useClickOutside } from '~/hooks/use-click-outside';
import styles from './index.module.scss';

export type Props = PopoverComponentOwnProps & PopoverProps & ButtonProps;

export const PageActionsPopoverComponent = memo(
  ({
    operationOptions,
    onSubmit,
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    loading,
    ready,
    afterSubmit,
    hasError,
    setDefaultCode
  }: Props): React.JSX.Element => {
    const {
      defaultCode = INITIAL_CODE_VALUE,
      editable,
      title,
      message = '',
      submitText,
      successTitle,
      operationType
    } = operationOptions;

    const { handleSubmit, handleChange, code, onClose, closePopover, responseMessage } = useImportExportPopoverHandlers(
      {
        defaultCode,
        toggle,
        setDefaultCode,
        afterSubmit,
        onSubmit
      }
    );

    const [clickTrackerRef] = useClickOutside(onClose, true);

    return (
      <Slot name='page-actions'>
        <div className={styles.popover}>
          <div ref={onTriggerRef} />
          <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
            {show && (
              <AnimatePresence>
                <MotionPopover
                  ref={clickTrackerRef}
                  className={styles.container}
                  initial='initial'
                  animate='in'
                  exit='out'
                  variants={VARIANTS}
                >
                  <Button is='transparent' onClick={closePopover} icon='cross' className={styles.cross} />
                  <ImportExportPopoverContent
                    operationType={operationType}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    ready={ready}
                    hasError={hasError}
                    code={code}
                    editable={!!editable}
                    handleChange={handleChange}
                    title={title}
                    message={message}
                    submitText={submitText}
                    successTitle={successTitle}
                    onClose={onClose}
                    responseMessage={responseMessage}
                  />
                </MotionPopover>
              </AnimatePresence>
            )}
          </div>
        </div>
      </Slot>
    );
  }
);

PageActionsPopoverComponent.displayName = 'PageActionsPopoverComponent';
