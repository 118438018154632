import React, { useCallback, useRef } from 'react';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { FileFilters } from '~/components/_layout/popovers/upload/components/inputs/filters';
import { ActiveFolder, OuterUploaderHandlers } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { FileUploader } from '~/components/_layout/popovers/upload/components/inputs/uploader';
import { FileList } from '~/components/_layout/popovers/upload/components/list';
import { POPPER_OPTIONS } from '~/components/_layout/popovers/upload/constants';
import { useFetchFiles } from '~/components/_layout/popovers/upload/hooks/use-fetch-files';
import { useHandleFiles } from '~/components/_layout/popovers/upload/hooks/use-handle-files';
import { Button, useAsyncButton } from '~/components/button';
import { Popover, UsePopover, usePopover } from '~/components/popover';
import { Portal } from '~/components/portal';

import styles from './styles.module.scss';

type PopoverProps = Pick<UsePopover, 'show' | 'onPopoverRef' | 'onTriggerRef' | 'toggle'>;

type OwnProps = ActiveFolder &
  OuterUploaderHandlers & {
    message: React.ReactNode;
    children: (toggle: UsePopover['toggle'], show: boolean) => React.ReactNode;
    isFileSelected?: boolean;
  };

type Props = OwnProps & PopoverProps;

export const Upload = ({
  message,
  isFileSelected,
  activeFolder,
  handleFileInteraction,
  setPageError,
  setPageLoading,
  show,
  onPopoverRef,
  onTriggerRef,
  toggle,
  children
}: Props) => {
  const uploaderRef = useRef<HTMLInputElement>(null);

  const close = useCallback(() => toggle(false), [toggle]);

  const {
    state: { filter, selectedSorting },
    methods: { onChangeFilter, onChangeOrder, handleRemove, handleUpload, handleSelect }
  } = useHandleFiles({ toggle, uploaderRef, handleFileInteraction });

  const { items, count, loadMore, loading } = useFetchFiles({
    show,
    toggle,
    filter,
    orderSettings: selectedSorting.value,
    activeFolder,
    setPageError
  });

  return (
    <div>
      <div ref={onTriggerRef}>{children(toggle, show)}</div>
      <FileUploader
        ref={uploaderRef}
        activeFolder={activeFolder}
        toggle={toggle}
        handleFileInteraction={handleFileInteraction}
        setPageError={setPageError}
        setPageLoading={setPageLoading}
      />
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
          {show && (
            <Popover className={styles.popover} onClickOutside={close}>
              <CoachMessage>{message}</CoachMessage>
              <FileFilters
                filter={filter}
                onChangeFilter={onChangeFilter}
                selectedSorting={selectedSorting}
                onChangeOrder={onChangeOrder}
              />
              <FileList items={items} count={count} loading={loading} handleSelect={handleSelect} loadMore={loadMore} />
              <div className={styles.buttons}>
                <Button className={styles.action} disabled={loading} onClick={handleUpload} fluid>
                  Upload
                </Button>
                <Button className={styles.action} disabled={loading || !isFileSelected} onClick={handleRemove} fluid>
                  Remove
                </Button>
              </div>
            </Popover>
          )}
        </div>
      </Portal>
    </div>
  );
};

export const useUpload = () => {
  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, POPPER_OPTIONS);
  const { loading, ready, setLoading, setReady } = useAsyncButton();

  return { show, onPopoverRef, onTriggerRef, toggle, loading, ready, setLoading, setReady, Upload: Upload };
};
