import { SelectItem } from '~/components/_inputs/select';

export const WELCOME_MESSAGE = [
  'Attributes are a personal data, mostly answers to questions we’ve asked users in tools\n',
  'Get started by adding a new attributes, or importing attributes you’ve made in another environment.'
];

export const DATA_TYPE_SELECT_ITEMS: Array<SelectItem<string>> = [
  {
    value: 'int',
    label: 'Integer',
    id: 'int'
  },
  {
    value: 'text',
    label: 'Text',
    id: 'text'
  },
  {
    value: 'json',
    label: 'JSON',
    id: 'json'
  },
  {
    value: 'date',
    label: 'Date',
    id: 'date'
  },
  {
    value: 'bool',
    label: 'Boolean',
    id: 'bool'
  },
  {
    value: 'float',
    label: 'Float',
    id: 'float'
  }
];
