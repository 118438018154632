import React, { memo, useCallback } from 'react';
import { FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { updateVirtualTag } from '~/pages/tags/context/store/thunks';
import { FlatTag } from '~/types/gists/tag';

type Props = FlatTag;

const Subheading = ({ id, subHeading }: Props): React.JSX.Element => {
  const { dispatch } = useTagsModuleContext();

  const onSubHeadingChange = useCallback(
    (_, value: string) => dispatch(updateVirtualTag(id, { subHeading: value })),
    [dispatch, id]
  );

  return (
    <FormLabel label='Sub heading' placement='top'>
      <Input value={subHeading ?? ''} onChange={onSubHeadingChange} />
    </FormLabel>
  );
};

export default memo(Subheading);
