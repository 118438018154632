import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { AccordionsService } from '~/services/accordion-service';
import { UserService } from '~/services/user-service';
import { useTheme } from '~/components/theme';
import { api } from '~/api';

import { Accordion } from 'react-accessible-accordion';
import EnvironmentAccordion from './affiliate-environments';
import { AffiliateInfo } from '~/types/gists/affiliates';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';

export const IDENTIFIER = 'client-config';

export const ClientEnvironmentSection = React.memo((): React.JSX.Element => {
  const location = useLocation();
  const { setAffiliate, affiliate, env, setAffiliateDetails } = useTheme();
  const [affiliates, setAffiliates] = useState<AffiliateInfo[]>(UserService.getAffiliatesFullInfo());
  const [error, setError] = useState<string>();

  const fetchToApplyAffiliateDetails = useCallback(
    async (affiliate, env) => {
      setAffiliate(affiliate as AffiliateKey, env as Environment);

      await Promise.all([api.affiliates.getCurrent(), api.user.getUserFunctionalPermissions()])
        .then(([current, functionalPermissions]) => {
          UserService.setFunctionalPermissions(functionalPermissions);
          setAffiliateDetails(current);
          setAffiliates(currentList =>
            currentList.map(affiliateItem => {
              if (affiliateItem.key === affiliate) {
                return current;
              }

              return affiliateItem;
            })
          );
          UserService.setAffiliateByKey(affiliate, current);
        })
        .catch(() => {
          setError('Failed to fetch info about this affiliate. Try later or another one.');
          UserService.setAffiliates({});
          UserService.resetFunctionalPermissions();
          setAffiliateDetails({} as AffiliateInfo);
        });
    },
    [setAffiliate, setAffiliateDetails]
  );

  const onChangeAffiliate = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      // Applies radio button value from the main menu form.
      const [affiliate, env] = event.target.value.split('/');
      fetchToApplyAffiliateDetails(affiliate, env);
    },
    [fetchToApplyAffiliateDetails]
  );

  useEffect(() => {
    // Observes browsers navigate back/forward actions.
    const searchParams = new URLSearchParams(location.search);
    const searchParamAffiliate = searchParams.get('affiliate') as AffiliateKey;
    const searchParamEnv = searchParams.get('env') as Environment;
    const isNewParams = searchParamAffiliate !== affiliate || searchParamEnv !== env;

    if (isNewParams) {
      fetchToApplyAffiliateDetails(searchParamAffiliate, searchParamEnv);
    }
  }, [fetchToApplyAffiliateDetails, location.search, affiliate, env]);

  const preExpanded = useMemo(
    () => Array.from(new Set([affiliate, ...AccordionsService.getAccordionsSection('sidebar_affiliates')])),
    [affiliate]
  );

  const [accordionListsState, setAccordionListsState] = useState<string[]>(preExpanded);

  const onChangeAccordionsState = (keys: string[]) => {
    setAccordionListsState(keys);
    AccordionsService.saveAccordionsSection('sidebar_affiliates', keys);
  };

  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error });

  return (
    <section>
      <Formik initialValues={{}} onSubmit={() => void 0}>
        {() => (
          <Form translate='yes'>
            {/*Affiliate's Accordion*/}
            <Accordion
              onChange={onChangeAccordionsState}
              preExpanded={accordionListsState}
              allowMultipleExpanded
              allowZeroExpanded
            >
              {/*Environment Accordion*/}
              {affiliates.map(affiliateItem => (
                <EnvironmentAccordion
                  key={affiliateItem.key}
                  toggle={onChangeAffiliate}
                  affiliate={affiliateItem}
                  affiliateEnvironments={UserService.affiliates[affiliateItem.key]?.environments}
                  currentAffiliateEnvironment={`${affiliate}/${env}`}
                />
              ))}
            </Accordion>
          </Form>
        )}
      </Formik>
      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
    </section>
  );
});

ClientEnvironmentSection.displayName = 'ClientEnvironmentSection';
