import { CustomError } from '~/helpers/common/custom-error';
import { PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { Page } from '~/types/gists/page';
import { setError } from '~/pages/pages/edit/context/store/actions';
import { getPageId } from '~/pages/pages/edit/context/store/selectors';
import { pages as pagesApi } from '~/api/pages';

export const deleteCurrent = (): PageEditorThunk<Promise<Page | void>> => async (dispatch, getState) => {
  try {
    return await pagesApi.removeSingle<Page>({ id: getPageId(getState()) } as Page);
  } catch (unknownError) {
    const error = new CustomError(unknownError ?? 'Failed to delete page');
    dispatch(setError(error.message));

    return void 0;
  }
};
