import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';
import { DeleteCurrentAction } from '~/components/_layout/page-actions/delete-current-action';
import { DuplicateCurrentAction } from '~/components/_layout/page-actions/duplicate-current-action';
import { ChangeEvent, EditTemplate } from '~/templates/edit';
import { Slot } from '~/components/slots';
import { PublishPanel } from '~/components/_layout/panels/publish';
import { PublishAction } from '~/components/_layout/page-actions/publish-action';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { EditTemplateState } from '~/templates/edit/use-editable-template';
import { withEditTemplate } from '~/templates/edit/with-edit-template';
import { Button } from '~/components/button';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { COHORTS_ROUTES } from '~/routes/private/cohorts/constants';

import { Cohort } from '~/types/gists/cohort';
import { LOADING_MESSAGE } from './messages';
import { CohortHistory } from './history';

import styles from './styles.module.scss';
import { useOverlay } from '~/components/_layout/overlay';
import ConditionBuilder from '~/pages/cohorts/edit/ConditionBuilder';
import ConditionPanel from '~/pages/cohorts/edit/ConditionPanel';
import TagsPanel from '~/components/_layout/panels/tags';

const newCohort: Partial<Cohort> = {
  name: 'New Cohort',
  description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
};

const overlayProps = {
  initial: { x: '100%' }
};

export const Component = (props: EditTemplateState<Cohort>): React.JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();

  const { item, touch, change, add, edit, publish, removeCurrent, cloneCurrent, isNew, error, isTouched } = props;

  useDocumentTitle(`${item.name} - Cohorts`);

  const { id, name, description, isEnabled, createdAt, updatedAt, createdBy, updatedBy, conditionQuery, tags } = item;

  const {
    Overlay: ConditionBuilderOverlay,
    open: openConditionBuilderOverlay,
    close: closeConditionBuilderOverlay
  } = useOverlay();

  const openConditionBuilder = useCallback(() => openConditionBuilderOverlay(), [openConditionBuilderOverlay]);

  const onConditionBuilderSave = useCallback(
    (newConditionQuery: string) => {
      change('conditionQuery', newConditionQuery);
    },
    [change]
  );

  const handleChange = (name: keyof Cohort) => (event: ChangeEvent) => {
    change(name, event.target.value);
  };

  const onSaveTags = useCallback(
    (name: string, value: Cohort['tags']) => {
      change(name, value);
      // Assume that it is an on-mount fetch & save operation, not an update made by a user.
      touch(false);
    },
    [change, touch]
  );

  const handleSave = async (isEnabled = false) => {
    const { affiliateId, name, description, conditionQuery } = item;

    const response = await add({
      affiliateId,
      conditionQuery,
      name,
      description,
      isEnabled
    });

    if (response) {
      history.replace(`/cohorts/${response.id}${search}`);
    }
  };

  const handleUpdate = async () => {
    await edit();
  };

  const handlePublish = async () => {
    await publish();
  };

  const handleRemove = async () => {
    return await removeCurrent();
  };

  const handleClone = useCallback(async () => {
    return await cloneCurrent({ isEnabled: false });
  }, [cloneCurrent]);

  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error });

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Cohorts' to={COHORTS_ROUTES.root} />
          <Breadcrumb.Item label={name} truncated />
        </Breadcrumb>
      </Slot>
      <ConditionBuilderOverlay animateProps={overlayProps} className={styles.overlay}>
        <ConditionBuilder
          close={closeConditionBuilderOverlay}
          initialConditionQuery={conditionQuery ?? ''}
          onSave={onConditionBuilderSave}
        />
      </ConditionBuilderOverlay>
      <EditTemplate
        icon='cohorts'
        title={name}
        description={description}
        onTitleChange={handleChange('name')}
        onDescriptionChange={handleChange('description')}
      >
        <Slot name='page-actions'>
          {!!item.id && (
            <>
              <DuplicateCurrentAction
                rootRoute={COHORTS_ROUTES.root}
                isUnsavedChanges={isTouched}
                onClone={handleClone}
              />
              <DeleteCurrentAction name={name} rootRoute={COHORTS_ROUTES.root} onConfirm={handleRemove} />
            </>
          )}
          <PublishAction
            name={name}
            unsaved={isNew}
            published={isEnabled}
            onSave={handleSave}
            onUpdate={handleUpdate}
            onPublish={handlePublish}
          />
        </Slot>
        <Slot name='right-sidebar'>
          <ConditionPanel openConditionBuilder={openConditionBuilder} conditionQuery={item.conditionQuery} />
          <TagsPanel tags={tags} onChange={onSaveTags} />
          <PublishPanel
            name={name}
            createdAt={createdAt}
            updatedAt={updatedAt}
            createdBy={createdBy}
            updatedBy={updatedBy}
            unsaved={isNew}
            published={isEnabled}
            onSave={handleSave}
            onPublish={handlePublish}
          />
        </Slot>
        {id !== void 0 && <CohortHistory id={id} />}
      </EditTemplate>
      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
    </>
  );
};

export const EditCohort = withEditTemplate({
  gist: 'cohorts',
  defaultItem: newCohort,
  loadingMessage: LOADING_MESSAGE
})(Component);
