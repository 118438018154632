import classnames from 'classnames';
import React from 'react';
import { Label } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';
import styles from './styles.module.scss';

export enum MODE {
  page = 'page',
  blocks = 'blocks'
}

type Props = {
  mode: MODE;
  switchMode: (mode: MODE) => () => void;
};

const ModeSwitcher = React.memo(
  ({ mode, switchMode }: Props): React.JSX.Element => (
    <div className={styles.container}>
      <button
        className={classnames(styles.tab, { [styles['selected-tab']]: mode === MODE.page })}
        onClick={switchMode(MODE.page)}
      >
        <Icon name='pages' />
        <Label size='s'>Page</Label>
      </button>
      <button
        className={classnames(styles.tab, { [styles['selected-tab']]: mode === MODE.blocks })}
        onClick={switchMode(MODE.blocks)}
      >
        <Icon name='blocks' />
        <Label size='s'>Blocks</Label>
      </button>
    </div>
  )
);

export default ModeSwitcher;
