export class CustomError extends Error {
  constructor(error: unknown, options?: { cause?: unknown }) {
    let message: string | undefined;

    if (error) {
      if (typeof error === 'object' && 'message' in error && typeof error?.message === 'string') {
        message = error.message;
      }

      if (typeof error === 'string') {
        message = error;
      }
    }

    super(message, options);
  }
}

export const DEFAULT_ERROR_MESSAGE = 'Error';
