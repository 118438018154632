import React from 'react';
import { useTheme } from '~/components/theme';

type Props = {
  id: string;
};

const COLOR_MATRIX_VALUES = {
  dark: '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0',
  light: '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
};

const OFFSETS = {
  dark: -1,
  light: 1
};

export const SVGShadowFilter = ({ id }: Props): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <filter id={id} width='400%' height='400%' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
      <feFlood floodOpacity='0' result='BackgroundImageFix' />
      <feColorMatrix
        in='SourceAlpha'
        type='matrix'
        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        result='hardAlpha'
      />
      <feOffset dy={OFFSETS[theme]} />
      <feColorMatrix type='matrix' values={COLOR_MATRIX_VALUES[theme]} />
      <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
      <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
    </filter>
  );
};
