import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { privateRoutes as routes } from '~/routes/private';

export const Home = (): React.JSX.Element => {
  const location = useLocation();

  return (
    <Switch location={location}>
      {routes.map(({ to, component: Component, ...props }) => (
        <Route
          key={to.toString()}
          path={to}
          render={routeProps => <Component key={to.toString()} {...routeProps} />}
          {...props}
        />
      ))}
    </Switch>
  );
};
