import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '~/components/_inputs/input';
import { Select } from '~/components/_inputs/select';
import { useSidebarPanel } from '~/components/_layout/panels';
import { Label } from '~/components/_layout/typography/label';
import { useSegmentedButton } from '~/components/segmented-button';
import { useTheme } from '~/components/theme';
import { onKeyDown } from '~/helpers/common/event';
import {
  BREAKPOINT_ITEMS,
  breakpointSegments,
  ZOOM_ITEMS,
  zoomOptions
} from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/preview-controls/constants';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setBreakpoint, setWidth, setZoom } from '~/pages/pages/edit/context/store/actions';
import { BREAKPOINT_LABELS, WIDTH_LIMITS, ZOOM_VALUES } from '~/pages/pages/edit/context/store/enums';
import { PersistingService } from '~/pages/pages/edit/context/store/persisting-service';
import { getPreviewBreakpoint, getPreviewWidth, getPreviewZoom } from '~/pages/pages/edit/context/store/selectors';

import styles from './styles.module.scss';

const PreviewControls = () => {
  const { env, affiliate } = useTheme();

  const persistPreviewSettings = useCallback(
    value => PersistingService.setPreview({ affiliate, env, value }),
    [env, affiliate]
  );

  const dispatch = usePageEditorDispatch();
  const zoom = usePageEditorSelector(getPreviewZoom);
  const width = usePageEditorSelector(getPreviewWidth);
  const breakpoint = usePageEditorSelector(getPreviewBreakpoint);

  const handleChangeZoom = useCallback(
    () =>
      ({ value }) => {
        dispatch(setZoom(value));
        persistPreviewSettings({ zoom: value });
      },
    [dispatch, persistPreviewSettings]
  );

  const [unvalidatedWidth, setUnvalidatedWidth] = useState<number>(width);

  useEffect(() => {
    setUnvalidatedWidth(width);
  }, [width]);

  const handleChangeWidth = useCallback((_, value: string) => {
    setUnvalidatedWidth(parseInt(value));
  }, []);

  const handleValidateAndUpdateWidth = useCallback(() => {
    let widthValue = unvalidatedWidth;
    if (unvalidatedWidth < WIDTH_LIMITS.min) {
      widthValue = WIDTH_LIMITS.min;
    }

    if (unvalidatedWidth > WIDTH_LIMITS.max) {
      widthValue = WIDTH_LIMITS.max;
    }

    dispatch(setWidth(widthValue));
    persistPreviewSettings({ width: widthValue });
  }, [dispatch, persistPreviewSettings, unvalidatedWidth]);

  const { active, setActive, Container, Segment } = useSegmentedButton<{ label: BREAKPOINT_LABELS; size: number }>(
    BREAKPOINT_ITEMS[breakpoint]
  );

  const handleChangeBreakpoint = useCallback(
    (breakpoint: { label: BREAKPOINT_LABELS; size: number }) => () => {
      setActive(breakpoint);
      dispatch(setBreakpoint(breakpoint.label));
      persistPreviewSettings({ breakpoint: breakpoint.label });
      dispatch(setWidth(breakpoint.size));
      persistPreviewSettings({ width: breakpoint.size });
    },
    [dispatch, persistPreviewSettings, setActive]
  );

  const { Panel, List } = useSidebarPanel();

  return (
    <Panel title='Preview'>
      <List>
        <div className={styles.inputs}>
          <div>
            <Label size='xs'>Breakpoint</Label>
            <Container>
              {breakpointSegments.map(({ size, label }, index) => (
                <Segment
                  key={size}
                  onClick={handleChangeBreakpoint({ size, label })}
                  first={index === 0}
                  active={label === active.label}
                  last={index === breakpointSegments.length - 1}
                >
                  {label}
                </Segment>
              ))}
            </Container>
          </div>
          <div>
            <Label size='xs'>Width</Label>
            <Input
              className={styles['width-input']}
              name='width'
              value={unvalidatedWidth}
              onChange={handleChangeWidth}
              onBlur={handleValidateAndUpdateWidth}
              onKeyDown={onKeyDown('enter', handleValidateAndUpdateWidth)}
              type='number'
              min={WIDTH_LIMITS.min}
              max={WIDTH_LIMITS.max}
            />
          </div>
          <div>
            <Label size='xs'>Zoom</Label>
            <Select<ZOOM_VALUES> name='zoom' items={zoomOptions} value={ZOOM_ITEMS[zoom]} onChange={handleChangeZoom} />
          </div>
        </div>
      </List>
    </Panel>
  );
};

export default PreviewControls;
