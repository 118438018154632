import React, { memo, useCallback } from 'react';
import useMountEffect from '~/hooks/use-mount-effect';

import TopBar from '~/pages/cohorts/edit/ConditionBuilder/components/TopBar';
import { VIEW_MODES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Heading } from '~/components/_layout/typography/heading';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { withConditionBuilderContext } from '~/pages/cohorts/edit/ConditionBuilder/hocs/withConditionBuilderContext';
import Body from '~/pages/cohorts/edit/ConditionBuilder/components/Body';
import { parseVisualModeStructureToConditionQuery } from '~/pages/cohorts/edit/ConditionBuilder/helpers';
import Loader from '~/components/loader';

import styles from './styles.module.scss';

type Props = {
  close: () => void;
  initialConditionQuery: string;
  onSave: (conditionQuery: string) => void;
};

const ConditionBuilder = ({ initialConditionQuery, close, onSave }: Props): React.JSX.Element => {
  const { setConditionQuery, viewMode, conditionQuery, ruleSets, rules, isLoading } = useConditionBuilderContext();

  useMountEffect(() => {
    setConditionQuery(initialConditionQuery);
  });

  const onConditionSave = useCallback(() => {
    if (viewMode === VIEW_MODES.VISUAL_VIEW_MODE) {
      const parsedConditionQuery = parseVisualModeStructureToConditionQuery(ruleSets, rules);

      return onSave(parsedConditionQuery);
    }

    return onSave(conditionQuery);
  }, [conditionQuery, onSave, ruleSets, rules, viewMode]);

  return (
    <div className={styles.container}>
      <TopBar close={close} onSave={onConditionSave} />
      <div className={styles.main}>
        <div className={styles.heading}>
          <Heading size='s'>This cohort will include only people who match these rules</Heading>
        </div>
        {isLoading ? <Loader /> : <Body />}
      </div>
    </div>
  );
};

export default memo(withConditionBuilderContext(ConditionBuilder));
