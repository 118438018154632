import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './top-bar-tool.module.scss';

type Props = PropsWithChildren<{
  className?: string;
}>;

const Component = ({ children }: Props, ref?: React.Ref<HTMLDivElement>): React.JSX.Element => {
  return (
    <div className={styles.container} ref={ref}>
      {children}
    </div>
  );
};

export const TopBarTool = forwardRef<HTMLDivElement, Props>(Component);
