import { ArrayShim } from '~/helpers/shims/array-shim';
import { StringShim } from '~/helpers/shims/string-shim';
import {
  Frequency,
  FREQUENCY_LABELS,
  FREQUENCY_TYPES,
  MonthDay,
  SEGMENTS,
  SUFFIXES,
  WeekDay,
  WEEKDAYS_LABELS
} from '~/pages/push-notifications/edit/panel/scheduler/types';

export const segments: SEGMENTS[] = [SEGMENTS.never, SEGMENTS.on, SEGMENTS.after];

export const frequency: Frequency[] = [
  {
    id: FREQUENCY_TYPES.day,
    label: FREQUENCY_LABELS.days,
    value: FREQUENCY_TYPES.day
  },
  {
    id: FREQUENCY_TYPES.week,
    label: FREQUENCY_LABELS.weeks,
    value: FREQUENCY_TYPES.week
  },
  {
    id: FREQUENCY_TYPES.month,
    label: FREQUENCY_LABELS.months,
    value: FREQUENCY_TYPES.month
  },
  {
    id: FREQUENCY_TYPES.year,
    label: FREQUENCY_LABELS.years,
    value: FREQUENCY_TYPES.year
  }
];

export const weekDays: WeekDay[] = [
  {
    id: '1',
    label: WEEKDAYS_LABELS.Mondays,
    value: 1
  },
  {
    id: '2',
    label: WEEKDAYS_LABELS.Tuesdays,
    value: 2
  },
  {
    id: '3',
    label: WEEKDAYS_LABELS.Wednesdays,
    value: 3
  },
  {
    id: '4',
    label: WEEKDAYS_LABELS.Thursdays,
    value: 4
  },
  {
    id: '5',
    label: WEEKDAYS_LABELS.Fridays,
    value: 5
  },
  {
    id: '6',
    label: WEEKDAYS_LABELS.Saturdays,
    value: 6
  },
  {
    id: '7',
    label: WEEKDAYS_LABELS.Sundays,
    value: 7
  }
];

const suffixes: { [key: number]: SUFFIXES } = {
  1: SUFFIXES.st,
  2: SUFFIXES.nd,
  3: SUFFIXES.rd
};

export const monthDays: MonthDay[] = [...Array(31)].map((_, i) => {
  const index = i + 1;

  const lastChar = StringShim.getLastChar(String(index));
  const condition = index > 20 || index < 4;

  const suffix = suffixes[lastChar] && condition ? suffixes[lastChar] : SUFFIXES.th;

  return {
    id: String(index),
    label: index + suffix,
    value: index
  };
});

export const normalizedFrequency = ArrayShim.normalize(frequency, 'id');
export const normalizedWeekDays = ArrayShim.normalize(weekDays, 'id');
export const normalizedMonthDays = ArrayShim.normalize(monthDays, 'id');
