import React, { useCallback } from 'react';

import TagsPanel from '~/components/_layout/panels/tags';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getTags } from '~/pages/pages/edit/context/store/selectors';
import { Page } from '~/types/gists/page';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { setTags } from '~/pages/pages/edit/context/store/actions';
import { isEqual } from '~/helpers/common';

const Tags = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const tags = usePageEditorSelector(getTags);
  const onSaveTags = useCallback(
    (name: string, value: Page['tags']) => {
      if (!isEqual(tags, value)) {
        dispatch(setTags(value));
      }
    },
    [dispatch, tags]
  );

  return <TagsPanel tags={tags} onChange={onSaveTags} />;
};

export default Tags;
