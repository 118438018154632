import React, { useMemo } from 'react';

import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import Name from '~/pages/tags/components/Workspace/components/TagForm/components/Name';
import Description from '~/pages/tags/components/Workspace/components/TagForm/components/Description';
import Subheading from '~/pages/tags/components/Workspace/components/TagForm/components/Subheading';
import Parent from '~/pages/tags/components/Workspace/components/TagForm/components/Parent';

import styles from './styles.module.scss';
import { FlatTag } from '~/types/gists/tag';

const TagForm = (): React.JSX.Element => {
  const { state } = useTagsModuleContext();

  const tag = useMemo(
    () => state.virtualTags.find(virtualTag => virtualTag.id === state.selectedTagsIds[0]) as FlatTag,
    [state.selectedTagsIds, state.virtualTags]
  );

  return (
    <div className={styles.container}>
      <Name {...tag} />
      <Description {...tag} />
      <div className={styles['inputs-container']}>
        <Parent {...tag} />
        <Subheading {...tag} />
      </div>
    </div>
  );
};

export default TagForm;
