import React from 'react';
import { useTheme } from '~/components/theme';

type Props = {
  id: string;
};

const COLOR_MATRIX_VALUES = {
  dev: '0 0 0 0 0.239216 0 0 0 0 0.811765 0 0 0 0 0.207843 0 0 0 1 0',
  test: '0 0 0 0 0.960784 0 0 0 0 0.0705882 0 0 0 0 0.176471 0 0 0 1 0',
  stage: '0 0 0 0 0.0745098 0 0 0 0 0.698039 0 0 0 0 0.996078 0 0 0 1 0',
  prod: '0 0 0 0 0.878431 0 0 0 0 0 0 0 0 0 0.443137 0 0 0 1 0',
  none: '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
};

export const SVGGlowFilter = ({ id }: Props): React.JSX.Element => {
  const { theme, env } = useTheme();

  return (
    <filter id={id} width='400%' height='400%' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
      {theme === 'dark' && (
        <>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix type='matrix' values={COLOR_MATRIX_VALUES[env]} />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
        </>
      )}
      <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
    </filter>
  );
};
