import React from 'react';
import { TableWrapMode, WrapCallback } from 'react-table';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { TableApi } from '~/components/_table/types';
import { useSegmentedButton } from '~/components/segmented-button';
import { useTheme } from '~/components/theme';
import { TableStorageService } from '~/services/table-service';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';

const ACTIONS: TableWrapMode[] = ['nowrap', 'wrap'];

const WRAP_MODE_LOCALES = {
  nowrap: 'Truncate',
  wrap: 'Wrap'
};

type Props = {
  gist: TableApi;
  onChange?: WrapCallback;
};

export const WrapAction = ({ gist, onChange }: Props): React.JSX.Element => {
  const { affiliate, env } = useTheme();

  const { active, setActive, Container, Segment } = useSegmentedButton<string>(() =>
    TableStorageService.getWrapMode({ affiliate, env, gist })
  );

  const handleClick = (mode: TableWrapMode) => () => {
    setActive(mode);

    if (onChange) {
      onChange(mode);
    }

    TableStorageService.setWrapMode({ affiliate, env, gist, mode });
  };

  return (
    <TopBarTool>
      <Container>
        {ACTIONS.map((mode, index) => (
          <TooltipOnHover key={mode} title={WRAP_MODE_LOCALES[mode]} delay={100}>
            <Segment
              active={mode === active}
              icon={`text-${mode}`}
              onClick={handleClick(mode)}
              first={index === 0}
              last={index === ACTIONS.length - 1}
            />
          </TooltipOnHover>
        ))}
      </Container>
    </TopBarTool>
  );
};
