import React, { ChangeEvent, forwardRef, Ref, TextareaHTMLAttributes } from 'react';
import classnames from 'classnames';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import TextareaAutosizeBase from 'react-textarea-autosize';
import { useTheme } from '../../theme';

import styles from './textarea.module.scss';

export type Props = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & { onChange: HandleChange };
export type Event = ChangeEvent<HTMLTextAreaElement>;

export const TextareaComponent = (
  { className, onChange, ...props }: Props,
  ref?: Ref<HTMLTextAreaElement>
): React.JSX.Element => {
  const { theme } = useTheme();

  const handleChange = (event: Event) => {
    const {
      target: { name, value }
    } = event;

    onChange(name, value, event);
  };

  return (
    <textarea
      onChange={handleChange}
      ref={ref}
      {...props}
      className={classnames(styles.container, styles[theme], className)}
    />
  );
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(TextareaComponent);

type AutosizeProps = Omit<TextareaAutosizeProps, 'onChange'> & { onChange: HandleChange };

export const TextareaAutosizeComponent = (
  { className, onChange, ...props }: AutosizeProps,
  ref?: Ref<HTMLTextAreaElement>
): React.JSX.Element => {
  const { theme } = useTheme();

  const handleChange = (event: Event) => {
    const {
      target: { name, value }
    } = event;

    onChange(name, value, event);
  };

  return (
    <TextareaAutosizeBase
      ref={ref}
      onChange={handleChange}
      {...props}
      className={classnames(styles.container, styles[theme], className)}
    />
  );
};

export const TextareaAutosize = forwardRef<HTMLTextAreaElement, AutosizeProps>(TextareaAutosizeComponent);
