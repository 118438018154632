import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CustomError } from '~/helpers/common/custom-error';

import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getError, getIsLoading, getIsNewPage, getIsReady } from '~/pages/pages/edit/context/store/selectors';
import { loadAvailableBlocks, loadAvailableImages, loadPageDataById } from '~/pages/pages/edit/context/store/thunks';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { useSelector } from '~/store/hooks';
import { Button } from '~/components/button';
import { initContainers, setError, setIsReady, setLoading } from '~/pages/pages/edit/context/store/actions';
import { LOADING_MESSAGE } from '~/pages/pages/edit/messages';

export function withLoadPageEditorData(Component: React.FC): React.FC {
  const displayName = (Component.displayName ?? Component.name) || 'Component';

  const EnhancedComponent = (): React.JSX.Element | null => {
    const dispatch = usePageEditorDispatch();
    const isLoading = usePageEditorSelector(getIsLoading);
    const error = usePageEditorSelector(getError);
    const isReady = usePageEditorSelector(getIsReady);
    const isNew = usePageEditorSelector(getIsNewPage);
    const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

    const { id } = useParams<{ id?: string }>();

    useEffect(() => {
      (async () => {
        try {
          dispatch(setLoading(true));
          await dispatch(loadAvailableBlocks());
          await dispatch(loadAvailableImages());

          if (!isNew && id) {
            await dispatch(loadPageDataById(id));
          } else {
            dispatch(initContainers());
          }
        } catch (unknownError) {
          const error = new CustomError(unknownError ?? 'Failed to load data to init page');
          dispatch(setError(error.message));
        } finally {
          dispatch(setLoading(false));
          dispatch(setIsReady(true));
        }
      })();
    }, [dispatch, id, isNew]);

    const { CoachPopover: LoadingPopover, ...loadingProps } = useCoachPopover({
      timeout: 1000,
      loading: isLoading
    });

    const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({
      disabled: !error || isMainMenuOpened
    });

    const resetError = useCallback(() => {
      dispatch(setError(null));
      errorProps.unsubscribe();
    }, [dispatch, errorProps]);

    return (
      <>
        <LoadingPopover {...loadingProps} message={LOADING_MESSAGE} />
        <ErrorPopover message={error ?? undefined} {...errorProps}>
          <Button onClick={resetError} is='major' fluid>
            Ok
          </Button>
        </ErrorPopover>
        {isReady ? <Component /> : null}
      </>
    );
  };

  EnhancedComponent.displayName = `withLoadPageEditorData(${displayName})`;

  return EnhancedComponent;
}
