import React, { useCallback } from 'react';
import { PublishAction } from '~/components/_layout/page-actions/publish-action';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getIsNewPage, getIsPagePublished, getPageName } from '~/pages/pages/edit/context/store/selectors';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { useHistory, useLocation } from 'react-router-dom';
import { commitPageChangesToStore } from '~/pages/pages/edit/context/store/thunks';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import {
  preSubmitValidation,
  publishPage,
  savePage,
  updatePage
} from '~/pages/pages/edit/components/save-publish-buttons/thunks';

const SavePublishButtons = () => {
  const history = useHistory();
  const { search } = useLocation();

  const dispatch = usePageEditorDispatch();

  const pageName = usePageEditorSelector(getPageName);
  const isNew = usePageEditorSelector(getIsNewPage);
  const isPublished = usePageEditorSelector(getIsPagePublished);

  const handleSave = useCallback(
    async (withPublish: boolean) => {
      await dispatch(commitPageChangesToStore());
      const isValid = dispatch(preSubmitValidation());

      if (isValid) {
        const response = await dispatch(savePage(withPublish));
        if (response) {
          history.replace(`${PAGES_ROUTES.root}/${response.id}${search}`);
        }
      }
    },
    [dispatch, history, search]
  );

  const handleUpdate = useCallback(async () => {
    await dispatch(commitPageChangesToStore());
    const isValid = dispatch(preSubmitValidation());

    if (isValid) {
      await dispatch(updatePage());
    }
  }, [dispatch]);

  const handlePublish = useCallback(async () => {
    await dispatch(commitPageChangesToStore());
    const isValid = dispatch(preSubmitValidation());

    if (isValid) {
      await dispatch(publishPage());
    }
  }, [dispatch]);

  return (
    <PublishAction
      name={pageName}
      unsaved={isNew}
      published={isPublished}
      onSave={handleSave}
      onUpdate={handleUpdate}
      onPublish={handlePublish}
    />
  );
};

export default SavePublishButtons;
