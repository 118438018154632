import React, { KeyboardEvent, TextareaHTMLAttributes } from 'react';
import classnames from 'classnames';
import { Key } from '~/helpers/common/event';
import { Snippet } from '~/components/snippet';

import styles from './contenteditable-as-code.module.scss';
import { UseContentAllowedExternalProps } from '~/components/contenteditable/types';
import { useContentEditable } from '~/components/contenteditable';

const isTabKey = Key.isSpecificKey('tab');

const insertTabSpace = (event: KeyboardEvent<HTMLTextAreaElement>) => {
  if (isTabKey(event.nativeEvent)) {
    event.preventDefault();

    const target = event.currentTarget;

    if (target) {
      const { value, selectionStart, selectionEnd } = target;

      target.value = value.substring(0, selectionStart) + '\t' + value.substring(selectionEnd);
      target.selectionStart = target.selectionEnd = selectionStart + 1;
    }
  }
};

type Props = UseContentAllowedExternalProps<HTMLTextAreaElement> & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const ContentEditableAsCode = ({
  className,
  keyResetters = [],
  keySubmitters = [],
  ...props
}: Props): React.JSX.Element => {
  const {
    className: container,
    isEditing,
    ref,
    handleBlur,
    handleClick,
    handleKeyDown
  } = useContentEditable<HTMLTextAreaElement>({
    keyGuards: [
      {
        key: 'enter',
        serviceKey: 'shiftKey'
      }
    ],
    keyCallbacks: [insertTabSpace],
    keyResetters,
    keySubmitters: [
      {
        key: 'enter',
        serviceKey: 'shiftKey'
      },
      ...keySubmitters
    ]
  });

  const { value, placeholder } = props;

  const valueAsString = value !== undefined ? String(value) : value;

  return (
    <div className={classnames(container, className)} onBlur={handleBlur}>
      {isEditing ? (
        <Snippet code={valueAsString} placeholder={placeholder}>
          <textarea
            ref={ref}
            className={styles.input}
            autoCapitalize='off'
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            onKeyDown={handleKeyDown}
            {...props}
          />
        </Snippet>
      ) : (
        <div role='textbox' tabIndex={0} className={styles.button} onClick={handleClick}>
          <Snippet code={valueAsString} placeholder={placeholder} />
        </div>
      )}
    </div>
  );
};
