import React, { useCallback, useMemo } from 'react';

import { Switch } from '~/components/_inputs/switch';
import { Label } from '~/components/_layout/typography/label';
import useMaintenanceMode from '~/components/_layout/main-menu/current-environment/useMaintenanceMode';
import { Confirm } from '~/components/_layout/popovers/confirm';
import { MaintenanceStatusEnum } from '~/types/gists/affiliates';
import { LifeHubLogo } from '~/components/_layout/logo';
import { Icon } from '~/components/icon';

import styles from './styles.module.scss';

const MaintenanceSwitcher = (): React.JSX.Element => {
  const { maintenance, maintenanceStatus, toggleMaintenance, isTogglingAvailable, isTransition } = useMaintenanceMode();

  const getMaintenanceStatusLocale = useCallback(() => {
    if (!maintenanceStatus) {
      return <span>Maintenance mode is {maintenance ? 'on' : 'off'}</span>;
    }

    switch (maintenanceStatus) {
      case MaintenanceStatusEnum.TurningOn:
        return <span>Switching maintenance mode on</span>;

      case MaintenanceStatusEnum.TurningOff:
        return <span>Switching maintenance mode off</span>;

      case MaintenanceStatusEnum.Completed:
        return <span>Maintenance mode is {maintenance ? 'on' : 'off'}</span>;

      case MaintenanceStatusEnum.Failed:
        return (
          <span className={styles['failed-status']}>
            <Icon name={'alert'} />
            Failed to switch {!maintenance ? 'on' : 'off'} maintenance mode
          </span>
        );

      default:
        return null;
    }
  }, [maintenance, maintenanceStatus]);

  const confirmMessage = useMemo(
    () => [
      `Are you sure you want to switch ${maintenance ? 'off' : 'on'} maintenance mode?`,
      `This means the entire product will be ${maintenance ? 'visible to' : 'hidden from'} all users.`
    ],
    [maintenance]
  );

  return (
    <div className={styles.container}>
      {isTransition ? (
        <LifeHubLogo animate={true} className={styles.loader} />
      ) : (
        <Confirm message={confirmMessage} onConfirm={toggleMaintenance}>
          {toggle => (
            <Switch
              id='maintenance'
              checked={maintenance}
              onChange={() =>
                maintenanceStatus === MaintenanceStatusEnum.Completed || maintenanceStatus === null
                  ? toggle(true)
                  : toggleMaintenance()
              }
              disabled={!isTogglingAvailable}
            />
          )}
        </Confirm>
      )}
      <Label size='s'>{getMaintenanceStatusLocale()}</Label>
    </div>
  );
};

export default MaintenanceSwitcher;
