import React, { useCallback, useState } from 'react';
import { Dimmer } from '~/helpers/ui-kit/dimmer';
import { ScrollContainer } from '~/helpers/ui-kit/scroll-container';
import { useClickOutside } from '~/hooks/use-click-outside';
import { AnimatePresence, motion } from 'framer-motion';
import { Portal } from '~/components/portal';

import styles from './overlaty.module.scss';
import classnames from 'classnames';

type Coordinate = number | string;

type ANIMATE_PROPS = Partial<{
  initial: Partial<{ x: Coordinate; y: Coordinate }>;
  animate: Partial<{ x: Coordinate; y: Coordinate }>;
  exit: Partial<{ x: Coordinate; y: Coordinate }>;
  transition?: Partial<{ type: string; bounce: number; duration: number }>;
}>;
type Props<T> = {
  children:
    | ((props: { animationCompleted: boolean; state?: T }) => React.JSX.Element)
    | React.JSX.Element
    | React.JSX.Element[];
  animateProps?: ANIMATE_PROPS;
  className?: string;
};

const defaultAnimateProps: ANIMATE_PROPS = {
  initial: { x: -1000 },
  animate: { x: 0 },
  exit: { x: -1000 },
  transition: { type: 'spring', bounce: 0, duration: 0.5 }
};

type UseOverlay<T> = {
  show: boolean;
  open: (data?: any) => void;
  close: () => void;
  toggle: () => void;
  Overlay: ({ children }: Props<T>) => React.JSX.Element;
};

const NOOP = () => void 0;

export const useOverlay = <T extends Record<string, any>>(
  defaultValue = false,
  onClickOutside: () => void = NOOP
): UseOverlay<T> => {
  const [show, setShow] = useState(defaultValue);
  const [state, setState] = useState<T>({} as T);

  const open = (data?: any) => {
    if (data) {
      setState(data);
    }

    setShow(true);
  };

  const close = useCallback(() => setShow(false), []);
  const toggle = useCallback(() => setShow(!show), [show]);

  const onClickOutsideHandler = useCallback(() => {
    close();

    if (onClickOutside) {
      onClickOutside();
    }
  }, [close, onClickOutside]);

  const [anchor, handleClickOutside] = useClickOutside(onClickOutsideHandler);

  const [animationCompleted, completeAnimation] = useState(false);

  const Overlay = useCallback(
    ({ children, animateProps = {}, className }: Props<T>): React.JSX.Element => {
      const onAnimationComplete = () => {
        completeAnimation(true);
      };

      return (
        <Portal id='overlay'>
          <Dimmer
            on={show}
            display='flex'
            className={classnames(styles.container, className)}
            onClick={handleClickOutside}
            onEscClick={close}
          >
            <AnimatePresence>
              {show && (
                <ScrollContainer
                  at='y'
                  as={motion.aside}
                  onAnimationComplete={onAnimationComplete}
                  {...defaultAnimateProps}
                  {...animateProps}
                  className={styles.body}
                  ref={anchor}
                  /* animationCompleted initiate re-render, but we do not need animate after it has already completed */
                  {...(animationCompleted
                    ? {
                        initial: { x: 0 }
                      }
                    : {})}
                >
                  {typeof children === 'function' ? children({ animationCompleted, state }) : children}
                </ScrollContainer>
              )}
            </AnimatePresence>
          </Dimmer>
        </Portal>
      );
    },
    [anchor, animationCompleted, close, handleClickOutside, show, state]
  );

  return {
    show,
    close,
    open,
    toggle,
    Overlay
  };
};
