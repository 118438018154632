import { Level } from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-level/types';

export const getCurrentHeadingTag = (htmlString: string): Level | null => {
  const regex = /<(h[1-6])>(.*?)<\/\1>/i;
  const match = htmlString.match(regex);
  return match ? (match[1].toLowerCase() as Level) : null;
};

export const replaceHeadingTag = (originalContent: string, newTagName: Level): string => {
  const regex = /<(h[1-6])>(.*?)<\/\1>/g;
  return originalContent.replace(regex, `<${newTagName}>$2</${newTagName}>`);
};
