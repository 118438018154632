import { useCallback } from 'react';
import { ActionType, Hooks, TableDataLike, TableInstance, TableState } from 'react-table';
// React Table public utils is a JSX file
// By the time when this comment was written the current tsconfig
// is not allowing to parse JSX files.
// import { actions } from 'react-table/~/publicUtils';

export type UseErrorInstanceProps = {
  setTableError: (state: string | null) => void;
};

export type UseErrorColumnProps = {
  setTableError: (state: string | null) => void;
};

export type UseErrorState = {
  error: string | null;
};

export type UseErrorActions = {
  setTableError: { type: 'setTableError'; error: string | null };
};

const ACTIONS = {
  // TODO: Find a way to export utils actions to preserve consistence
  // init: actions.init,
  init: 'init',
  setTableError: 'setTableError'
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { columns, dispatch } = instance;

  const setTableError = useCallback(
    (state: string | null) => dispatch({ type: ACTIONS.setTableError, error: state }),
    [dispatch]
  );

  columns.forEach(column => {
    Object.assign(column, {
      setTableError
    });
  });

  Object.assign(instance, {
    setTableError
  });
};

const useReducer = <Data extends TableDataLike>(state: TableState<Data>, action: ActionType): TableState<Data> => {
  if (action.type === ACTIONS.init) {
    return {
      ...state,
      error: null
    };
  }

  if (action.type === ACTIONS.setTableError) {
    const { error } = action as UseErrorActions['setTableError'];

    return {
      ...state,
      error
    };
  }

  return state;
};

export const useError = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.stateReducers.push(useReducer);
  hooks.useInstance.push(useInstance);
};

useError.pluginName = 'useError';
