import { CustomError } from '~/helpers/common/custom-error';

const MAX_WIDTH = 2000;
const MIN_WIDTH = 300;

export const validateImage = async (files: FileList): Promise<void> => {
  const file = files[0];

  try {
    const width = await checkImageWidth(file);

    if (width <= MIN_WIDTH || width >= MAX_WIDTH) {
      throw new CustomError(`Image width should be between ${MIN_WIDTH} - ${MAX_WIDTH}`);
    }
  } catch (unknownError) {
    const error = new CustomError(unknownError);
    throw new Error(error.message);
  }
};

export const checkImageWidth = async (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        resolve(image.width);
        return image.width;
      };
      reader.onerror = err => reject(err);
    };
  });
};
