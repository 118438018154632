import React, { ChangeEvent, forwardRef, InputHTMLAttributes, Ref, useRef } from 'react';
import classnames from 'classnames';
import { Value } from '~/components/_layout/typography/value';
import { useTheme } from '~/components/theme';

import styles from './input.module.scss';
import { Label } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';
import { useMergeRefs } from '~/hooks/use-merge-refs';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: HandleChange;
  error?: string;
};
export type Event = ChangeEvent<HTMLInputElement>;

export const InputComponent = (
  { type = 'text', name, className, onChange, error, value, ...props }: Props,
  ref?: Ref<HTMLInputElement>
): React.JSX.Element => {
  const { theme } = useTheme();

  const inputRef = useRef<HTMLInputElement>();

  const handleChange = (event: Event) => {
    const {
      target: { name, value }
    } = event;

    onChange(name, value);
  };

  const mergedRef = useMergeRefs(ref ?? null, inputRef);

  return (
    <Value>
      <input
        name={name}
        ref={mergedRef}
        type={type}
        className={classnames(styles.container, styles[theme], { [styles.error]: error }, className)}
        onChange={handleChange}
        value={value ?? ''}
        {...props}
      />
      {error ? (
        <div className={styles['error-wrapper']}>
          <Icon name='alert' className={styles['error-icon']} />
          <Label size='s' className={styles['error-message']}>
            {error}
          </Label>
        </div>
      ) : null}
    </Value>
  );
};

export const Input = forwardRef<HTMLInputElement, Props>(InputComponent);
