import { useMemo } from 'react';

import usePageEditorContext from '~/pages/pages/edit/context/hooks/use-page-editor-context';
import { State } from '~/pages/pages/edit/context/store/types';

const usePageEditorSelector = <R>(cb: (state: State) => R): R => {
  const { state } = usePageEditorContext();

  return useMemo(() => cb(state), [cb, state]);
};

export default usePageEditorSelector;
