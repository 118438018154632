import { LocalStorageService } from '~/helpers/services/local-storage';
import { initialState as initialContextState } from '~/pages/pages/edit/context/store/reducer';
import { Preview } from '~/pages/pages/edit/context/store/types';

const PERSISTING_STORAGE_KEY = 'page-editor-storage';

type PersistingStorage = {
  preview: Preview;
};

const initialState: PersistingStorage = {
  preview: initialContextState.preview
};

export type PersistingIdentifiers = {
  affiliate: AffiliateKey;
  env: Environment;
  section: keyof PersistingStorage;
};

export type PersistingStorageWriteArguments = PersistingIdentifiers & { value: any };

export class PersistingService {
  private static store = new LocalStorageService<PersistingStorage>(PERSISTING_STORAGE_KEY);

  private static write({ affiliate, env, section, value }: PersistingStorageWriteArguments) {
    return PersistingService.store.set(store => {
      const affiliateState = store[affiliate] || {};
      const envState = affiliateState[env] || {};

      return {
        ...store,
        [affiliate]: {
          ...affiliateState,
          [env]: {
            ...envState,
            [section]: value
          }
        }
      };
    });
  }

  private static get({ affiliate, env, section }: PersistingIdentifiers) {
    const editor = PersistingService.store.get();

    const isEmptyEditorState = !editor[affiliate] || !editor[affiliate][env] || !editor[affiliate][env][section];

    if (isEmptyEditorState) {
      PersistingService.write({ affiliate, env, section, value: initialState[section] });
      return initialState;
    }

    return editor[affiliate][env][section];
  }

  private static set({
    affiliate,
    env,
    section,
    value
  }: PersistingStorageWriteArguments): ReturnType<typeof PersistingService.write> {
    const mergedValue = Object.assign(PersistingService.get({ affiliate, env, section }), value);

    return PersistingService.write({ affiliate, env, section, value: mergedValue });
  }

  public static setPreview({
    affiliate,
    env,
    value
  }: Omit<PersistingStorageWriteArguments, 'section'>): ReturnType<typeof PersistingService.set> {
    return PersistingService.set({
      affiliate,
      env,
      section: 'preview',
      value
    });
  }

  public static getPreview({ affiliate, env, section }: PersistingIdentifiers): Partial<Preview> {
    return PersistingService.get({ affiliate, env, section });
  }
}
