import React, { memo } from 'react';
import { Heading } from '~/components/_layout/typography/heading';
import { Panel } from '~/components/panel';

import Title from '../Title';

import styles from './proTip.module.scss';

const ProTip = (): React.JSX.Element => (
  <>
    <Title>Pro Tip</Title>
    <Panel className={styles.panel}>
      <Heading special size='l' className={styles.text}>
        Navigate from anywhere, to anywhere in the app, using the menu button in the top bar.
      </Heading>
    </Panel>
  </>
);

export default memo(ProTip);
