import { CellProps, Column, ColumnInstance, Hooks, IdType, Meta, Row, TableDataLike } from 'react-table';
import { DefaultIcons } from '~/components/icon';
import { ConfirmCallback } from '~/components/_layout/popovers/confirm';
import { ActionsCell } from '~/components/_table/cells/actions';

export const ACTIONS_COLUMN = '_actions';

type ConfirmActionType<Data extends TableDataLike> = {
  origin: IdType<Data> | ((cell: CellProps<Data>) => string);
};

type ForbidActionType<Data extends TableDataLike> = {
  validator: (row: Row<Data>) => boolean;
  infoMessage: string;
};

export type ActionTypes<Data extends TableDataLike> = {
  confirm: ConfirmActionType<Data>;
  forbid: ForbidActionType<Data>;
};

export type ActionHandlerTypes = {
  confirm: ConfirmCallback;
};

export type ActionHandler<Data extends TableDataLike, HandlerParams extends (...args: any) => any = never> = {
  (cell: CellProps<Data>, ...rest: Parameters<HandlerParams>): void | Promise<void>;
};

export type ActionOptions<Data extends TableDataLike, HandlerParams extends (...args: any) => any = never> = {
  id: string;
  label?: string;
  icon?: keyof DefaultIcons;
  confirm?: boolean | ConfirmActionType<Data>;
  handler: ActionHandler<Data, HandlerParams>;
  loading?: (cell: CellProps<Data>) => boolean;
  disabled?: (cell: CellProps<Data>) => boolean;
  message?: string;
  tooltip?: string;
  forbid?: ForbidActionType<Data>;
};

export type UseActionsOptions<Data extends TableDataLike> = Partial<{
  actions: ActionOptions<Data>[];
}>;

const useAllColumns = <Data extends TableDataLike>(
  allColumns: ColumnInstance<Data>[],
  { instance: { actions, actionsColumnWidth = 90 } }: Meta<Data>
): Column<Data>[] => {
  return [
    ...allColumns,
    ...(actions
      ? [
          {
            id: ACTIONS_COLUMN,
            disableResizing: true,
            disableGroupBy: true,
            disableSortBy: true,
            disableEdit: true,
            disableFilters: true,
            disableGlobalFilter: true,
            title: 'Actions',
            width: actionsColumnWidth,
            minWidth: 90,
            Cell: ActionsCell,
            sticky: 'right'
          }
        ]
      : [])
  ];
};

export const useActions = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.allColumns.push(useAllColumns);
};

useActions.pluginName = 'useActions';
