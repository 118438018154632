import React, { PropsWithChildren } from 'react';
import { ContentState } from 'draft-js';

type Props = PropsWithChildren<{
  contentState: ContentState;
  entityKey: string;
}>;

const Link = ({ contentState, entityKey, children }: Props): React.JSX.Element => {
  const { url, ariaLabel, target, noFollow } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} rel={noFollow ? 'nofollow' : undefined} target={target} aria-label={ariaLabel}>
      {children}
    </a>
  );
};

export default Link;
