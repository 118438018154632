import React, { useCallback } from 'react';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import {
  getBlockById,
  getIsImageBlockCaptionEnabled,
  getSelectedLayerId
} from '~/pages/pages/edit/context/store/selectors';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';
import { ControlledFormLabel } from '~/components/_form/components/label';
import { Switch } from '~/components/_inputs/switch';
import { toggleImageBlockCaption } from '~/pages/pages/edit/context/store/actions';

const ToggleCaption = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const getBlockByIdSelector = useCallback(
    state => {
      const { blockId } = parseLayerId(selectedLayerId);
      return getBlockById(state, { id: blockId });
    },
    [selectedLayerId]
  );

  const block = usePageEditorSelector(getBlockByIdSelector);

  const isImageBlockCaptionEnabled = usePageEditorSelector(getIsImageBlockCaptionEnabled);

  const onChange = useCallback(() => {
    dispatch(toggleImageBlockCaption(block.id));
  }, [block.id, dispatch]);

  return (
    <div>
      <ControlledFormLabel label={'Caption'} placement={'right'} id={'toggle-caption'}>
        <Switch id={'toggle-caption'} checked={isImageBlockCaptionEnabled} onChange={onChange} />
      </ControlledFormLabel>
    </div>
  );
};

export default ToggleCaption;
