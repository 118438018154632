import { useMemo } from 'react';

import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import { State } from '~/templates/tags-interaction/context/store/types';

const useTagsSelector = <R>(cb: (state: State) => R): R => {
  const { state } = useTagsInteractionContext();

  return useMemo(() => cb(state), [cb, state]);
};

export default useTagsSelector;
