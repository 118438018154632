import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';
import { ScrollContainer } from '~/helpers/ui-kit/scroll-container';
import { useTheme } from '~/components/theme';

import styles from './sidebar.module.scss';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const SidebarComponent = ({ className, children }: Props, ref?: Ref<HTMLDivElement>): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <ScrollContainer ref={ref} at='y' className={classnames(styles.container, styles[theme], className)}>
      {children}
    </ScrollContainer>
  );
};

export const Sidebar = forwardRef<HTMLDivElement, Props>(SidebarComponent);
