import React from 'react';

import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { Sidebar } from '~/components/_layout/sidebar';
import { useSubscribe } from '~/components/slots';
import { Block } from '~/types/gists/Block';

import TextBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/text';
import HeadingBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/heading';
import SpacerBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/spacer';
import ImageBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/image';
import ListBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/list';
import QuoteBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/quote';
import MetaboxBlockVariants from '~/pages/blocks/edit/components/structure-sidebar/block-types/metabox';

import styles from './styles.module.scss';

type Props = {
  block: Block;
};

const ComponentTypeToVariants = {
  [COMPONENT_TYPES.text]: TextBlockVariants,
  [COMPONENT_TYPES.heading]: HeadingBlockVariants,
  [COMPONENT_TYPES.spacer]: SpacerBlockVariants,
  [COMPONENT_TYPES.image]: ImageBlockVariants,
  [COMPONENT_TYPES.bulletList]: ListBlockVariants,
  [COMPONENT_TYPES.numberList]: ListBlockVariants,
  [COMPONENT_TYPES.quote]: QuoteBlockVariants,
  [COMPONENT_TYPES.metabox]: MetaboxBlockVariants
};

const StructureSidebar = ({ block }: Props): React.JSX.Element => {
  const left = useSubscribe('left-sidebar');

  const Variant = ComponentTypeToVariants[block.type];

  return (
    <div className={styles.container}>
      <Sidebar ref={left} className={styles.sidebar}>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        {Variant && <Variant variants={block.data.variants} blockName={block.name} />}
      </Sidebar>
    </div>
  );
};

export default StructureSidebar;
