import React, { InputHTMLAttributes, useLayoutEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';
import { Icon } from './icon';

import styles from './checkbox.module.scss';

export type Props = { indeterminate?: boolean } & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const Checkbox = ({
  id,
  checked = false,
  indeterminate = false,
  className,
  ...props
}: Props): React.JSX.Element => {
  const { theme } = useTheme();

  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={classnames(styles.container, { [styles.checked]: checked }, styles[theme], className)}>
      <input ref={ref} id={id} type='checkbox' checked={checked} className={styles.input} {...props} />
      <label htmlFor={id} className={styles.label}>
        <Icon className={styles.icon} />
      </label>
    </div>
  );
};
