import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { useSidebarPanel } from '~/components/_layout/panels';
import BlockItem from '~/pages/pages/edit/components/left-sidebar/components/blocks-panel/components/blocks-list/components/block-item';
import { AvailableBlocks } from '~/pages/pages/edit/context/store/types';

import styles from './styles.module.scss';

type Props = {
  blocks: AvailableBlocks;
};

const BlocksList = ({ blocks }: Props): React.JSX.Element => {
  const { List, Panel } = useSidebarPanel();

  return (
    <Panel className={styles.container}>
      <List>
        <Droppable droppableId='blocks_list' type={'block'}>
          {provided => (
            <>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {Array.from(blocks.values()).map((block, index) => {
                  if (COMPONENT_TYPES[block.type]) {
                    return <BlockItem key={block.id} block={block} index={index} />;
                  }
                  return null;
                })}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </List>
    </Panel>
  );
};

export default BlocksList;
