import { get } from '~/api/utils';
import { FunctionalModulePermission, FunctionalPermissions } from '~/types/gists/user';

const getUserFunctionalPermissions = async (): Promise<FunctionalPermissions> =>
  await get<FunctionalModulePermission[], never>('/user/permissions').then(functionalPermissions =>
    functionalPermissions.reduce(
      (result, modulePermission) => ({
        ...result,
        [modulePermission.name]: modulePermission
      }),
      {} as FunctionalPermissions
    )
  );

export const user = {
  getUserFunctionalPermissions
};
