import React from 'react';

export const withDefaultProps =
  <Props extends Record<string, any>>(defaultProps: Partial<Props>) =>
  (Component: React.ComponentType<Props>) => {
    // eslint-disable-next-line react/display-name
    return (props: Props): React.JSX.Element => {
      return <Component {...(defaultProps as Props)} {...(props as Props)} />;
    };
  };
