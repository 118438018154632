import React from 'react';
import ConditionBuilderProvider from '~/pages/cohorts/edit/ConditionBuilder/context';

export function withConditionBuilderContext<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
  const displayName = Component.displayName || Component.name || 'Component';

  const EnhancedComponent = (props: React.PropsWithChildren<T>) => (
    <ConditionBuilderProvider>
      <Component {...props} />
    </ConditionBuilderProvider>
  );

  EnhancedComponent.displayName = `withConditionBuilder(${displayName})`;

  return EnhancedComponent;
}
