import React, { useCallback } from 'react';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { ControlledFormLabel, FormLabel } from '~/components/_form/components/label';
import { useSidebarPanel } from '~/components/_layout/panels';
import { FilePicker } from '~/components/_layout/popovers/upload/components/inputs/picker';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setError, setLoading, setTwitterSEO } from '~/pages/pages/edit/context/store/actions';
import { getAvailableImageByKey, getTwitterSEO } from '~/pages/pages/edit/context/store/selectors';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { FileObject } from '~/types/gists/file';

export const TwitterTab = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const seo = usePageEditorSelector(getTwitterSEO);

  const onChange = useCallback(
    (name, value) => {
      dispatch(setTwitterSEO({ name, value }));
    },
    [dispatch]
  );

  const onChangeImage = useCallback(
    (file?: FileObject) => () => {
      onChange('twitter_image', file?.key);
    },
    [onChange]
  );

  const setPageError = useCallback(
    (error: string | null) => {
      dispatch(setError(error));
    },
    [dispatch]
  );

  const setPageLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setLoading(isLoading));
    },
    [dispatch]
  );

  const { List, ListItem } = useSidebarPanel();

  const getImageFileByKey = useCallback(
    state => getAvailableImageByKey(state, { key: seo?.twitter_image }),
    [seo?.twitter_image]
  );

  const imageFile = usePageEditorSelector(getImageFileByKey);

  return (
    <List>
      <ListItem withoutBorder>
        <FormLabel label='X title'>
          <TextareaAutosize name='twitter_title' value={seo?.twitter_title} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <FormLabel label='X description'>
          <TextareaAutosize name='twitter_description' value={seo?.twitter_description} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <ControlledFormLabel id='X image' label='X image'>
          <FilePicker
            inputId='X image'
            activeFolder={FOLDER_TYPES.IMAGES}
            file={imageFile}
            handleFileInteraction={onChangeImage}
            setPageError={setPageError}
            setPageLoading={setPageLoading}
          />
        </ControlledFormLabel>
      </ListItem>
    </List>
  );
};
