import React, { SyntheticEvent } from 'react';
import classnames from 'classnames';
import { usePopper } from '~/hooks/use-popper';
import { ChangeCallback, Item, useMenuList } from '~/helpers/ui-kit/menu-list';
import { maxHeight } from '~/helpers/ui-kit/popper/max-size';
import { offset } from '~/helpers/ui-kit/popper/offset';
import { sameWidth } from '~/helpers/ui-kit/popper/same-width';
import { Options } from '@popperjs/core';
import { Label } from '~/components/_layout/typography/label';
import { TextNode } from '~/components/_layout/typography/text';
import { Value } from '~/components/_layout/typography/value';
import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { Popover } from '~/components/popover';

import { useTheme } from '../../theme';
import styles from './select.module.scss';

export type SelectItem<V> = Item<V>;
export type SelectCallback<V> = (name: string, close?: () => void) => ChangeCallback<V>;
export type SelectLabelMapper<V> = (item: Item<V>) => string | React.JSX.Element;

export type Props<V> = {
  id?: string;
  name: string;
  value?: SelectItem<V>;
  items: SelectItem<V>[];
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  mapLabel?: SelectLabelMapper<V>;
  onChange?: SelectCallback<V>;
  popperOptions?: Partial<Options>;
  multiLine?: boolean;
  className?: string;
  isInOverlay?: boolean;
  headerElement?: React.JSX.Element;
  closeButton?: boolean;
  containerClassName?: string;
  closeOnSelect?: boolean;
};

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => {
      return [0, -reference.height];
    }
  }
};

const defaultPopperOptions: Partial<Options> = {
  placement: 'bottom',
  modifiers: [topOffset, sameWidth, ...maxHeight]
};

//TODO: Rewrite select, it is mess to pass whole item as value
export const Select = <V extends unknown>({
  id,
  name,
  items = [],
  value,
  placeholder,
  disabled,
  loading,
  mapLabel,
  onChange,
  popperOptions = defaultPopperOptions,
  multiLine,
  className,
  isInOverlay,
  closeButton,
  headerElement,
  containerClassName,
  closeOnSelect = true
}: Props<V>): React.JSX.Element => {
  const { visible, open, close, change, isFocused, isSelected, MenuList, MenuListItem } = useMenuList({
    items,
    value
  });

  const handleOnChange = React.useCallback(
    (item: SelectItem<V>) => (e: SyntheticEvent) => {
      if (item.disabled) {
        return void 0;
      }

      if (onChange) {
        onChange(name, () => close(e))(item);
      }

      if (closeOnSelect) {
        return close(e);
      }
    },
    [close, closeOnSelect, name, onChange]
  );

  const { onPopoverRef, onTriggerRef } = usePopper(popperOptions, visible);

  const { theme } = useTheme();

  return (
    <div className={containerClassName}>
      <Value ref={onTriggerRef} className={classnames(styles['button-container'], styles[theme], className)}>
        <button
          id={id ?? name}
          className={styles.button}
          disabled={loading || disabled}
          onClick={open}
          onKeyDown={change}
        >
          <TextNode inherit truncated={!multiLine}>
            {value?.label || placeholder}
          </TextNode>
          <Icon name={loading ? 'refresh' : 'mod-down'} className={styles.icon} />
        </button>
      </Value>
      <div
        ref={onPopoverRef}
        className={classnames(styles['popover-container'], styles[theme], { [styles['in-overlay']]: isInOverlay })}
      >
        {visible && (
          <Popover className={classnames(styles.popover)} onClickOutside={close}>
            <MenuList
              className={styles.list}
              headerElement={headerElement}
              closeButtonElement={
                closeButton ? (
                  <Button is='transparent' onClick={close} icon='cross' className={styles.cross} />
                ) : undefined
              }
            >
              {items.map((item, index) => {
                const { id, label, disabled } = item;

                const selected = isSelected(item);

                return (
                  <MenuListItem
                    key={id}
                    index={index}
                    className={styles.item}
                    selected={selected}
                    focused={isFocused(index)}
                    disabled={disabled}
                    onClick={handleOnChange(item)}
                  >
                    <Label size='s' truncated={!multiLine}>
                      {mapLabel ? mapLabel(item) : label}
                    </Label>
                    {selected && <Icon name='tick' className={classnames(styles.icon, styles.selected)} />}
                  </MenuListItem>
                );
              })}
            </MenuList>
          </Popover>
        )}
      </div>
    </div>
  );
};
