import { FocusEvent, KeyboardEvent, MouseEvent, RefCallback, SyntheticEvent, useState } from 'react';
import { Key } from '~/helpers/common/event';
import { useAutofocus } from '~/hooks/use-autofocus';
import { ContentEditableAsCode } from '~/components/contenteditable/code';

import styles from './contenteditable.module.scss';
import { UseContentEditable } from '~/components/contenteditable/types';

export const isResetOnBlurEvent = <T extends SyntheticEvent>({ nativeEvent }: T): boolean => {
  if (nativeEvent instanceof CustomEvent) {
    if (nativeEvent.detail.reset) {
      return true;
    }
  }

  return false;
};

export const useContentEditable = <E extends HTMLElement>({
  keyGuards = [],
  keyResetters = [],
  keySubmitters = [],
  keyCallbacks = [],
  onClick,
  onBlur,
  editingInitialState = false
}: UseContentEditable<E> = {}): {
  className: string;
  isEditing: boolean;
  ref: RefCallback<E>;
  handleBlur: (e: FocusEvent<HTMLElement>) => void;
  handleClick: (e: MouseEvent<HTMLElement>) => void;
  handleKeyDown: (event: KeyboardEvent<E>) => void;
} => {
  const [isEditing, setEditing] = useState(editingInitialState);

  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }

    setEditing(false);
  };

  const handleClick = e => {
    if (onClick) {
      onClick(e);
    }

    setEditing(true);
  };

  const reset = ({ currentTarget }: KeyboardEvent<E>): boolean => {
    return currentTarget.dispatchEvent(
      new CustomEvent('blur', {
        bubbles: true,
        detail: {
          reset: true
        }
      })
    );
  };

  const handleKeyDown = (event: KeyboardEvent<E>) => {
    if (keyGuards.some(({ key, serviceKey }) => Key.isSpecificKey(key, serviceKey)(event.nativeEvent))) {
      event.preventDefault();
    }

    if (keyCallbacks.length) {
      keyCallbacks.forEach(callback => callback(event));
    }

    if (keyResetters.some(({ key, serviceKey }) => Key.isSpecificKey(key, serviceKey)(event.nativeEvent))) {
      reset(event);
    } else if (keySubmitters.some(({ key, serviceKey }) => Key.isSpecificKey(key, serviceKey)(event.nativeEvent))) {
      event.currentTarget.blur();
    }
  };

  const { ref } = useAutofocus<E>(true);

  return {
    className: styles.container,
    isEditing,
    ref,
    handleBlur,
    handleClick,
    handleKeyDown
  };
};

export { ContentEditableAsCode };
