import { Reducer, useCallback, useEffect, useReducer } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CustomError } from '~/helpers/common/custom-error';
import { ObjectShim } from '~/helpers/shims/object-shim';
import { TableApi } from '~/components/_table/types';

import { api } from '~/api';

import { Action, reducer, State } from './reducer';
import { replaceEmptyStringWithNull } from '~/helpers/formatters';

export type Callback<T> = {
  beforeGet?: EditTemplateCallback<T>;
  beforeEdit?: EditTemplateCallback<T>;
};

type Props<T> = {
  defaultItem: Partial<T>;
  gist: TableApi;
  callback?: Callback<T>;
};

type Extension = HasId & { isActive?: boolean; isEnabled?: boolean };

export const useEditTemplate = <T extends Extension>({
  defaultItem,
  gist,
  callback
}: Props<T>): EditTemplateState<T> => {
  const location = useLocation<{ item?: T }>();
  const { id } = useParams<{ id?: string }>();

  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, {
    loading: true,
    isTouched: false,
    item: {},
    error: undefined
  });

  const isNew = location.pathname.includes('new');

  const method = api[gist];

  const getSingle = useCallback(
    async (id: string) => {
      dispatch({ type: 'request' });

      try {
        const response = await method.getSingle<T>({ id });

        const payload = callback?.beforeGet ? callback.beforeGet(response) : response;

        dispatch({ type: 'get', payload });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
      }
    },
    [callback, method]
  );

  const touch = useCallback(value => {
    dispatch({ type: 'touch', value });
  }, []);

  const change = useCallback(
    (name, value) => {
      dispatch({ type: 'change', name, value });
      touch(true);
    },
    [touch]
  );

  const add = useCallback(
    async (params: Partial<T>): Promise<T | void> => {
      dispatch({ type: 'clear' });

      try {
        const response = await method.add<T>({ ...state.item, ...params } as T);
        touch(false);
        return response;
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
      }
    },
    [method, state.item, touch]
  );

  const edit = useCallback(
    async (params?: Partial<T>) => {
      dispatch({ type: 'clear' });

      try {
        const item = { ...state.item, ...params } as T;
        replaceEmptyStringWithNull(item);

        const data = callback?.beforeEdit ? callback.beforeEdit(item) : item;

        const payload = await method.edit<T>(data);

        dispatch({ type: 'edit', payload });
        touch(false);
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
      }
    },
    [callback, method, state.item, touch]
  );

  const publish = useCallback(async () => {
    dispatch({ type: 'clear' });

    try {
      await edit({
        ...state.item,
        isEnabled: !state.item.isEnabled,
        isActive: !state.item.isActive
      } as T);

      change('isEnabled', !state.item.isEnabled);
      change('isActive', !state.item.isActive);
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      dispatch({ type: 'failure', error });
    }
  }, [change, edit, state.item]);

  const cloneCurrent = useCallback(
    async (params?: Partial<T>): Promise<T | void> => {
      dispatch({ type: 'clear' });

      try {
        return await method.cloneSingle<T>({ id: state.item.id, ...params } as T);
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
      }
    },
    [method, state.item.id]
  );

  const removeCurrent = useCallback(async (): Promise<void | T> => {
    dispatch({ type: 'clear' });

    try {
      return await method.removeSingle({ id: state.item.id } as T);
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      dispatch({ type: 'failure', error });
    }
  }, [method, state.item.id]);

  useEffect(() => {
    if (ObjectShim.isEmpty(state.item) && id) {
      getSingle(id);
    }
  }, [getSingle, id, state.item]);

  useEffect(() => {
    if (isNew) {
      dispatch({ type: 'init', payload: defaultItem });
    }
  }, [defaultItem, isNew]);

  const { item, loading, error, isTouched } = state;

  return {
    item,
    error,
    isNew,
    loading,
    isTouched,
    add,
    edit,
    touch,
    change,
    publish,
    cloneCurrent,
    removeCurrent
  };
};

export type EditTemplateCallback<T> = {
  (data: T): T;
};

export type EditTemplateState<T> = {
  item: T | Record<string, any>;
  isNew: boolean;
  error?: string;
  loading: boolean;
  isTouched: boolean;
  add: (params: Partial<T>) => Promise<T | void>;
  edit: (params?: Partial<T>) => Promise<void>;
  touch(value: boolean): void;
  change: HandleChange;
  publish: () => Promise<void>;
  removeCurrent: () => Promise<T | void>;
  cloneCurrent: (params?: Partial<T>) => Promise<T | void>;
};
