import { SetState } from '~/templates/tags-interaction/context/store/types';
import { INTERACTION_MODES } from '~/templates/tags-interaction/context/constants';
import { OriginalTag } from '~/types/gists/tag';

export enum ActionTypes {
  SetTags = 'SetTags',
  SetError = 'SetError',
  SelectTag = 'SelectTag',
  DeselectTag = 'DeselectTag',
  SetIsLoading = 'SetIsLoading',
  SetInteractionMode = 'SetInteractionMode',
  InitSelectedTags = 'InitSelectedTags',
  SetNewTagName = 'SetNewTagName',
  SetFilter = 'SetFilter'
}

export const setTags = (tags: OriginalTag[]): SetState => ({
  type: ActionTypes.SetTags,
  payload: tags
});

export const setError = (error: string): SetState => ({
  type: ActionTypes.SetError,
  payload: error
});

export const initSelectedTags = (selectedTags: OriginalTag[]): SetState => ({
  type: ActionTypes.InitSelectedTags,
  payload: selectedTags
});

export const selectTag = (tag: OriginalTag): SetState => ({
  type: ActionTypes.SelectTag,
  payload: tag
});

export const deselectTag = (id: number): SetState => ({
  type: ActionTypes.DeselectTag,
  payload: id
});

export const enableLoading = (): SetState => ({
  type: ActionTypes.SetIsLoading,
  payload: true
});

export const disableLoading = (): SetState => ({
  type: ActionTypes.SetIsLoading,
  payload: false
});

export const enableCreationMode = (): SetState => ({
  type: ActionTypes.SetInteractionMode,
  payload: INTERACTION_MODES.CREATION
});

export const enableAssignMode = (): SetState => ({
  type: ActionTypes.SetInteractionMode,
  payload: INTERACTION_MODES.ASSIGN
});

export const setNewTagName = (newTagName: string): SetState => ({
  type: ActionTypes.SetNewTagName,
  payload: newTagName
});

export const setFilter = (newFilter: string): SetState => ({
  type: ActionTypes.SetFilter,
  payload: newFilter
});
