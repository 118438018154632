import React from 'react';
import classnames from 'classnames';
import { DefaultIcons, Icon } from '~/components/icon';
import { useTheme } from '~/components/theme';
import { SVGShadowFilter } from '~/components/icon/filters/shadow';
import styles from './image.module.scss';

type Props = {
  src: string;
  alt?: string;
  className?: string;
  icon?: keyof DefaultIcons;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const Image = React.memo(
  ({ src, alt = 'image', className, onClick }: Props): React.JSX.Element => (
    <div className={classnames(styles.container, className)} onClick={onClick}>
      <img src={src} alt={alt} className={styles.image} />
    </div>
  )
);

export const ImageWithOverlay = React.memo(
  ({ src, alt = 'image-with-overlay', icon = 'bin', className }: Props): React.JSX.Element => {
    const { theme } = useTheme();

    return (
      <div className={classnames(styles.container, styles[theme], className)}>
        <img src={src} alt={alt} className={styles.image} />
        <div className={styles.overlay}>
          <Icon name={icon} filter='url(#shadow)' className={styles.icon}>
            <SVGShadowFilter id='shadow' />
          </Icon>
        </div>
      </div>
    );
  }
);
