import React from 'react';

import styles from './styles.module.scss';
import { Button } from '~/components/button';
import { VIEW_MODES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { useSegmentedButton } from '~/components/segmented-button';
import { DefaultIcons } from '~/components/icon';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

const MODES: Array<{ mode: VIEW_MODES; icon: keyof DefaultIcons }> = [
  {
    mode: VIEW_MODES.VISUAL_VIEW_MODE,
    icon: 'eye'
  },
  {
    mode: VIEW_MODES.CODE_VIEW_MODE,
    icon: 'code'
  }
];

const TopBar = ({ close, onSave }: { close: () => void; onSave: () => void }): React.JSX.Element => {
  const { viewMode, setViewMode } = useConditionBuilderContext();
  const { Container, Segment } = useSegmentedButton<VIEW_MODES>(viewMode || VIEW_MODES.CODE_VIEW_MODE);

  const onSegmentClick = (mode: VIEW_MODES) => setViewMode(mode);

  const submitChanges = () => {
    onSave();
    close();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Container>
          {MODES.map(({ mode, icon }, index) => (
            <Segment
              key={mode}
              active={mode === viewMode}
              onClick={() => onSegmentClick(mode)}
              first={index === 0}
              last={index === MODES.length - 1}
              icon={icon}
              applyFilter={false}
            />
          ))}
        </Container>
        <Button is='minor' fluid onClick={close}>
          Cancel
        </Button>
        <Button is='major' fluid onClick={submitChanges}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default TopBar;
