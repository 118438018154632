import { createAction, createReducer } from '@reduxjs/toolkit';

type State = { isMainMenuOpened: boolean };

export const initialState = {
  isMainMenuOpened: false
};

const PREFIX = 'mainMenu';

export const setIsMainMenuOpened = createAction(`${PREFIX}/setIsMainMenuOpened`, (isMainMenuOpened: boolean) => ({
  payload: {
    isMainMenuOpened
  }
}));

export const mainMenu = createReducer<State>(initialState, builder =>
  builder.addCase(setIsMainMenuOpened, (state, action) => ({
    ...state,
    isMainMenuOpened: action.payload.isMainMenuOpened
  }))
);
