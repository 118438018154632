import React from 'react';
import styles from '~/components/_table/cells/actions/actions-cell.module.scss';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';
import { AsyncButton, ButtonProps } from '~/components/button';
import classnames from 'classnames';

type OwnProps = {
  icon?: ButtonProps['icon'];
  onClick: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  tooltip?: string;
};

const BaseAction = ({ tooltip, disabled, loading, icon, children, onClick }: OwnProps): React.JSX.Element => (
  <TooltipOnCondition title={tooltip} condition={!disabled} delay={100}>
    <AsyncButton
      ready={true}
      loading={loading}
      is='minor'
      icon={icon}
      className={classnames(styles.item, { [styles['icon-only']]: icon && !children, [styles.loading]: loading })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </AsyncButton>
  </TooltipOnCondition>
);

export default BaseAction;
