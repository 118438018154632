import React, { useCallback } from 'react';
import { useOverlay } from '~/components/_layout/overlay';

import styles from './file-details-overlay.module.scss';
import FileDetails from '~/components/_table/cells/file-primary/components/FileDetails';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';
import { isEqual } from '~/helpers/common';
import { setIsFileDetailsOpened } from '~/components/_table/cells/file-primary/context/actions';

const animateProps = {
  initial: { x: '100%' }
};

type UseFileDetailsOverlay = {
  openFileDetailsOverlay: (data?: any) => void;
  FileDetailsOverlay: React.FunctionComponent;
};

const useFileDetailsOverlay = (): UseFileDetailsOverlay => {
  const { dispatch, cellProps, altText, description } = useFilePrimaryCellContext();

  const {
    row: {
      original: { altText: originalAltText, description: originalDescription }
    },
    column: { edit },
    cell
  } = cellProps;

  const onClickOutside = useCallback(() => {
    dispatch(setIsFileDetailsOpened(false));

    if (!isEqual({ altText, description }, { altText: originalAltText, description: originalDescription })) {
      edit(cell, { altText, description });
    }
  }, [altText, cell, description, dispatch, edit, originalAltText, originalDescription]);

  const { Overlay, open } = useOverlay(false, onClickOutside);

  const openFileDetailsOverlay = useCallback(
    (data: any) => {
      dispatch(setIsFileDetailsOpened(true));
      open(data);
    },
    [dispatch, open]
  );

  const FileDetailsOverlay = useCallback(
    () => (
      <Overlay animateProps={animateProps} className={styles.container}>
        {() => <FileDetails />}
      </Overlay>
    ),
    [Overlay]
  );

  return {
    openFileDetailsOverlay,
    FileDetailsOverlay
  };
};

export default useFileDetailsOverlay;
