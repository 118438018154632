/**
 * @description The default validation subset for the JSON cell has been based on an Admin v1 example.
 */
export const DEFAULT_VALIDATION_SET = {
  max: 100,
  min: 1,
  errorMessages: {
    base: '',
    max: '',
    min: ''
  }
};
