import React, { useMemo } from 'react';
import { TableAction } from 'react-table';
import { Table } from '~/components/_table';
import { FileObject } from '~/types/gists/file';
import { useTheme } from '~/components/theme';
import useActiveFolderType from '~/pages/files/hooks/useActiveFolderType';
import { COLUMNS_BY_FOLDER_TYPE } from '~/pages/files/components/files-table/columns';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { calculatePageCount } from '~/components/_table/helpers/common';
import { useSelector } from '~/store/hooks';
import { Slot } from '~/components/slots';
import AddFiles from '~/pages/files/components/files-table/components/add-files';
import DownloadFiles from '~/pages/files/components/files-table/components/download-files';
import useDownload from '~/hooks/use-download';
import useReplaceFile from '~/pages/files/components/files-table/hooks/use-replace-file';
import { DeleteAction } from '~/components/_layout/page-actions/delete-action';
import { ExportAction } from '~/components/_layout/page-actions/export-action';
import { buildExportParamsFromTable } from '~/components/_table/hooks/use-table-export/helpers';
import { useTableExport } from '~/components/_table/hooks/use-table-export';
import { useTableImport } from '~/components/_table/hooks/use-table-import';
import { ImportAction } from '~/components/_layout/page-actions/import-action';
import { WrapAction } from '~/components/_layout/page-actions/wrap-action';

const defaultSortBy = [
  {
    id: 'updatedAt',
    desc: true
  }
];

const FilesTable = (): React.JSX.Element => {
  const { affiliate, env } = useTheme();
  const activeFolderType = useActiveFolderType();

  const { get, editCell, edit, autoResetProps, items, count, remove, removeSelected } = useTableFetch<FileObject>({
    gist: activeFolderType
  });

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { saveAs, ErrorMessage: DownloadErrorMessage } = useDownload();
  const { replaceFile } = useReplaceFile();

  const actions = useMemo<TableAction<FileObject>[]>(
    () => [
      {
        id: 'replace',
        icon: 'refresh',
        handler: async ({ row: { original }, toggleTableLoading, setTableError, refetchTable }) => {
          await replaceFile({
            startLoading: () => toggleTableLoading(true),
            finishLoading: () => toggleTableLoading(false),
            target: original,
            refetchTable: async () => await refetchTable(),
            onError: setTableError
          });
        },
        tooltip: 'Replace file'
      },
      {
        id: 'download',
        icon: 'save',
        handler: ({
          row: {
            original: { fullUrl }
          }
        }) => saveAs([fullUrl]),
        tooltip: 'Download'
      },
      {
        id: 'delete',
        icon: 'bin',
        confirm: { origin: 'name' },
        handler: remove,
        tooltip: 'Delete'
      }
    ],
    [remove, replaceFile, saveAs]
  );

  const {
    Popover: ExportPopover,
    onOpenExport,
    onSubmitExport,
    ...exportProps
  } = useTableExport<FileObject>({
    gist: activeFolderType,
    message: 'Related Tags are included in this export.'
  });

  const {
    Popover: ImportPopover,
    onOpenImport,
    onImportSubmit,
    ...importProps
  } = useTableImport<FileObject>({ gist: activeFolderType });

  return (
    <>
      <DownloadErrorMessage />
      <Table<FileObject>
        gist={activeFolderType}
        key={`${affiliate}_${env}_${activeFolderType}`}
        columns={COLUMNS_BY_FOLDER_TYPE[activeFolderType]}
        data={items}
        actions={actions}
        actionsColumnWidth={154}
        onUpdate={get}
        onRemove={remove}
        onEditCell={editCell}
        onEdit={edit}
        manualSortBy
        defaultSortBy={defaultSortBy}
        manualFilters
        manualPagination
        pageCount={pageCount}
        preventGet={isMainMenuOpened}
        count={count}
        {...autoResetProps}
      >
        {instance => {
          const refetch = async () => await instance.refetchTable();
          const openExportOptions = buildExportParamsFromTable(instance);
          const tooltipText = openExportOptions.isExportAll ? 'Export all' : 'Export selected';

          return (
            <Slot name='page-actions'>
              <WrapAction gist={activeFolderType} onChange={instance.setWrappingMode} />
              <ImportAction toggleImportPopover={onOpenImport} />
              <ImportPopover onSubmit={onImportSubmit} afterSubmit={refetch} {...importProps} />
              <ExportAction toggleExportPopover={onOpenExport(openExportOptions)} tooltipText={tooltipText} />
              <ExportPopover onSubmit={onSubmitExport} {...exportProps} />
              <DownloadFiles />
              <DeleteAction
                items={instance.selectedFlatRows}
                onConfirm={removeSelected(instance)}
                gist={activeFolderType}
              />
              <AddFiles />
            </Slot>
          );
        }}
      </Table>
    </>
  );
};

export default FilesTable;
