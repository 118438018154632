import React from 'react';

import MetaItems from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/MetaItems';
import Actions from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/Actions';

import styles from './sidebar.module.scss';

const Sidebar = () => (
  <div className={styles.container}>
    <Actions />
    <MetaItems />
  </div>
);

export default Sidebar;
