import React, { useCallback, useState } from 'react';
import { useSidebarPanel } from '~/components/_layout/panels';
import {
  SEO_SOCIALS,
  SeoTabSwitcher
} from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tab-switcher';
import { SeoContent } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tabs';

import styles from './styles.module.scss';

const SeoPanel = (): React.JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<SEO_SOCIALS>(SEO_SOCIALS.GOOGLE);

  const onChangeTab = useCallback(
    (tab: SEO_SOCIALS) => () => {
      setSelectedTab(tab);
    },
    []
  );

  const { Panel } = useSidebarPanel();

  return (
    <Panel title='SEO' className={styles.container}>
      <SeoTabSwitcher selectedTab={selectedTab} onChangeTab={onChangeTab} />
      <SeoContent selectedTab={selectedTab} />
    </Panel>
  );
};

export default SeoPanel;
