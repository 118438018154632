import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useDocumentTitle from '~/hooks/use-document-title';
import { OnboardingTemplate } from '~/templates/onboarding';
import { ButtonField } from '~/components/_form/fields/button';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const MfaSetupComplete = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onLogin = useCallback(() => history.push(ONBOARDING_ROUTES.signInEmail), [history]);

  return (
    <OnboardingTemplate
      message={[
        'Great! That’s Multi Factor Authentication all set up for you.',
        'Next time you log in you’ll need to use your phone to enter a security code.'
      ]}
    >
      <ButtonField onClick={onLogin}>Ok, let’s log in now</ButtonField>
    </OnboardingTemplate>
  );
};

export default MfaSetupComplete;
