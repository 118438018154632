export enum SEGMENTS {
  never = 'never',
  on = 'on',
  after = 'after'
}

export enum SUFFIXES {
  st = 'st',
  nd = 'nd',
  rd = 'rd',
  th = 'th'
}

export enum FREQUENCY_TYPES {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export enum FREQUENCY_LABELS {
  days = 'Days',
  weeks = 'Weeks',
  months = 'Months',
  years = 'Years'
}

export enum WEEKDAYS_LABELS {
  Mondays = 'Mondays',
  Tuesdays = 'Tuesdays',
  Wednesdays = 'Wednesdays',
  Thursdays = 'Thursdays',
  Fridays = 'Fridays',
  Saturdays = 'Saturdays',
  Sundays = 'Sundays'
}

export type Frequency = {
  id: FREQUENCY_TYPES;
  label: FREQUENCY_LABELS;
  value: FREQUENCY_TYPES;
};

export type BasicDay = {
  id: string;
  value: number;
};

export type WeekDay = BasicDay & {
  label: WEEKDAYS_LABELS;
};

export type MonthDay = BasicDay & {
  label: string;
};
