import { CoachPopoverComponent } from '~/components/_layout/popovers/import-export/coach-popover';
import { useSlotController } from '~/components/_layout/popovers/import-export/use-coach-slot-controller';
import useDocumentTitle from '~/hooks/use-document-title';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { TableAction, TableInstance } from 'react-table';
import { Row } from 'react-table';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { useOverlay } from '~/components/_layout/overlay';
import { DeleteAction } from '~/components/_layout/page-actions/delete-action';
import { ExportAction } from '~/components/_layout/page-actions/export-action';
import { ImportAction } from '~/components/_layout/page-actions/import-action';
import { WrapAction } from '~/components/_layout/page-actions/wrap-action';
import { Confirm } from '~/components/_layout/popovers/confirm';
import { Table } from '~/components/_table';
import { calculatePageCount } from '~/components/_table/helpers/common';
import { useTableExport } from '~/components/_table/hooks/use-table-export';
import { buildExportParamsFromTable } from '~/components/_table/hooks/use-table-export/helpers';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { useTableImport } from '~/components/_table/hooks/use-table-import';
import { TableApi } from '~/components/_table/types';
import { Button, LinkButton } from '~/components/button';
import { Slot } from '~/components/slots';
import { useTheme } from '~/components/theme';
import { PUSH_NOTIFICATIONS_ROUTES } from '~/routes/private/push-notifications/constants';
import { TableStorageService } from '~/services/table-service';
import type { PushNotification } from '~/types/gists/push-notification';
import { columns } from './columns';
import { WELCOME_MESSAGE } from './messages';
import { StatsInOverlay } from './stats';
import { useSelector } from '~/store/hooks';

const defaultSortBy = [
  {
    id: 'updatedAt',
    desc: true
  }
];

const gist: TableApi = 'pushNotifications';

export const PushNotifications = (): React.JSX.Element => {
  useDocumentTitle('Push');

  const { push } = useHistory();

  const { get, editCell, remove, removeSelected, publish, autoResetProps, items, isEmpty, count } =
    useTableFetch<PushNotification>({
      gist
    });

  const { Overlay, open } = useOverlay<{ id: string }>();

  const handleStatsOpen = useCallback<TableAction<PushNotification>['handler']>(
    ({ row }) => {
      open({ id: row.original.id });
    },
    [open]
  );

  const { affiliate, env } = useTheme();

  const goToPush = useCallback<TableAction<PushNotification>['handler']>(
    ({ row: { original } }) =>
      push(`${PUSH_NOTIFICATIONS_ROUTES.root}/${original.id}?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, push]
  );

  const actions = useMemo<TableAction<PushNotification>[]>(() => {
    return [
      {
        id: 'edit',
        icon: 'edit',
        handler: goToPush,
        tooltip: 'Edit Push'
      },
      {
        id: 'stats',
        icon: 'chart',
        label: 'Stats',
        handler: handleStatsOpen
      },
      {
        id: 'delete',
        icon: 'bin',
        confirm: { origin: 'name' },
        handler: remove,
        tooltip: 'Delete',
        forbid: {
          validator: (row: Row<PushNotification>) => env === 'prod' && !!row.original.timedMessageHistories.length,
          infoMessage:
            'This push notification has previously been sent and cannot be deleted as regulations state it should be kept.'
        }
      }
    ];
  }, [env, goToPush, handleStatsOpen, remove]);

  const publishColumn = useMemo<TableAction<PushNotification>>(() => {
    return {
      id: 'isActive',
      title: 'Publish',
      handler: publish,
      width: 120
    };
  }, [publish]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { CoachPopover: WelcomePopover, ...welcomeProps } = useCoachPopover({
    disabled: isMainMenuOpened || !isEmpty || TableStorageService.isEmptyFilters({ affiliate, env, gist })
  });

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  const {
    Popover: ExportPopover,
    onOpenExport,
    onSubmitExport,
    ...exportProps
  } = useTableExport<PushNotification>({
    gist,
    message: 'Related Push Notifications are included in this export.'
  });
  const {
    Popover: ImportPopover,
    onOpenImport,
    onImportSubmit,
    ...importProps
  } = useTableImport<PushNotification>({
    gist
  });

  const {
    onOpenNewCoachContent,
    onCloseCoachContent,
    CoachPopover: CoachImportPopover,
    ...coachImportProps
  } = useSlotController({ unsubscribePrevious: welcomeProps.unsubscribe, unsubscribeCurrent: importProps.toggle });

  const isPushesFromSelectedWereSent = useCallback(
    (instance: TableInstance<PushNotification>) => {
      const { state, rows } = instance;

      return (
        env === 'prod' &&
        rows
          .filter(({ id }) => state.selectedRowIds[id])
          .some(({ original }) => !!original.timedMessageHistories.length)
      );
    },
    [env]
  );

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Push' />
        </Breadcrumb>
      </Slot>
      <Overlay>
        {({ state, animationCompleted }) => (
          <StatsInOverlay animationCompleted={animationCompleted} id={state?.id} title='Stats' />
        )}
      </Overlay>
      <Table<PushNotification>
        gist={gist}
        key={`${affiliate}_${env}`}
        columns={columns}
        data={items}
        actions={actions}
        publishColumn={publishColumn}
        actionsColumnWidth={154}
        onUpdate={get}
        onPublish={publish}
        onEditCell={editCell}
        manualSortBy
        defaultSortBy={defaultSortBy}
        manualFilters
        manualPagination
        pageCount={pageCount}
        preventGet={isMainMenuOpened}
        count={count}
        {...autoResetProps}
      >
        {instance => {
          const refetch = async () => await instance.refetchTable();
          const openExportOptions = buildExportParamsFromTable(instance);
          const tooltipText = openExportOptions.isExportAll ? 'Export all' : 'Export selected';

          return (
            <>
              <Slot name='page-actions'>
                <WrapAction gist={gist} onChange={instance.setWrappingMode} />
                <ImportAction toggleImportPopover={onOpenImport} />
                <ImportPopover onSubmit={onImportSubmit} afterSubmit={refetch} {...importProps} />
                <ExportAction toggleExportPopover={onOpenExport(openExportOptions)} tooltipText={tooltipText} />
                <ExportPopover onSubmit={onSubmitExport} {...exportProps} />

                <Confirm
                  message={[
                    'Push notifications that have previously been sent cannot be deleted as regulations state they should be kept.',
                    'Are you sure you want to delete the rest?'
                  ]}
                  onConfirm={removeSelected(instance)}
                >
                  {toggle => (
                    <DeleteAction
                      items={instance.selectedFlatRows}
                      gist={gist}
                      onConfirm={(setLoading, setReady) =>
                        isPushesFromSelectedWereSent(instance)
                          ? toggle(true)
                          : removeSelected(instance)(setLoading, setReady)
                      }
                    />
                  )}
                </Confirm>
                <LinkButton to='/push-notifications/new' icon='plus'>
                  New Push
                </LinkButton>
              </Slot>
              <CoachImportPopover {...coachImportProps}>
                <CoachPopoverComponent
                  {...importProps}
                  {...coachImportProps}
                  toggle={onCloseCoachContent}
                  onSubmit={onImportSubmit}
                  afterSubmit={refetch}
                />
              </CoachImportPopover>
            </>
          );
        }}
      </Table>
      <WelcomePopover message={WELCOME_MESSAGE} {...welcomeProps}>
        <LinkButton to='/push-notifications/new' icon='plus' fluid>
          Add new Push
        </LinkButton>
        <Button onClick={onOpenNewCoachContent} is='minor' icon='import' fluid>
          Import Push
        </Button>
      </WelcomePopover>
    </>
  );
};
