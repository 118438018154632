type AccordionSectionKey = 'sidebar_affiliates';

type AccordionSectionExpandedKeys = string[];

type Accordions = {
  [key in AccordionSectionKey]?: AccordionSectionExpandedKeys;
};

export class AccordionsService {
  static identifier = 'accordions';
  static defaultValue: string[] = [];
  static accordions: Accordions = {};

  static getAllAccordionsSections(): AccordionSectionExpandedKeys {
    const stringedAccordionSections = localStorage.getItem(this.identifier);

    if (stringedAccordionSections === null) {
      return this.defaultValue;
    }

    return JSON.parse(stringedAccordionSections);
  }

  static getAccordionsSection(sectionKey: AccordionSectionKey): AccordionSectionExpandedKeys {
    return this.getAllAccordionsSections()[sectionKey] || [];
  }

  static saveAccordionsSection(sectionKey: AccordionSectionKey, expandedKeys: AccordionSectionExpandedKeys): void {
    const existedState = this.getAllAccordionsSections();
    const newState = JSON.stringify({ ...existedState, [sectionKey]: expandedKeys });

    return localStorage.setItem(this.identifier, newState);
  }

  static removeAccordionsSection(sectionKey: AccordionSectionKey): void {
    const existedState = this.getAllAccordionsSections();
    const newState = JSON.stringify({ ...existedState, [sectionKey]: undefined });

    localStorage.setItem(this.identifier, newState);
  }

  static clear(): void {
    localStorage.removeItem(this.identifier);
  }
}
