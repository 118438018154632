import { CustomError } from '~/helpers/common/custom-error';
import { withDefaultProps } from '~/hocs/with-default-props';
import { ConfirmBase, ConfirmProps } from '~/pages/onboarding/general/confirm';
import { onboarding } from '~/api/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const returnBackPath = ONBOARDING_ROUTES.forgotPasswordUpEmail;

const sendNewCodeMethod = 'forgotPasswordRequest';

const onSubmit =
  history =>
  async ({ code }: { code: string }, { setFieldError }) => {
    try {
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');

      if (email && password) {
        await onboarding.confirmForgotPassword({ email, code, password });
        localStorage.removeItem('email');
        localStorage.removeItem('password');

        history.push(ONBOARDING_ROUTES.signInEmail);
      } else {
        history.push(returnBackPath);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      setFieldError('code', error.message);
    }
  };

export const ForgotPasswordConfirm = withDefaultProps<ConfirmProps>({ onSubmit, returnBackPath, sendNewCodeMethod })(
  ConfirmBase
);
