import React from 'react';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode[] | React.ReactNode;
};

const Tabs = ({ children }: Props): React.JSX.Element => {
  return <div className={styles.container}>{children}</div>;
};

export default Tabs;
