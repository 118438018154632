import React, { useCallback } from 'react';
import type { Options } from '@popperjs/core';
import { Popover, UsePopover, usePopover } from '~/components/popover';
import { Portal } from '~/components/portal';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { AsyncButton, UseAsyncButton, useAsyncButton } from '~/components/button';

import styles from './styles.module.scss';

const POPPER: Partial<Options> = {
  placement: 'bottom',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top']
      }
    }
  ]
};

type PopoverProps = Pick<UsePopover, 'show' | 'onPopoverRef' | 'onTriggerRef' | 'toggle'>;
type ButtonProps = Pick<UseAsyncButton, 'loading' | 'ready' | 'setLoading' | 'setReady'>;
type OwnProps = {
  children: (toggle: PopoverProps['toggle'], show: boolean) => React.ReactNode;
  onClick?: (setLoading: () => void, setReady: () => Promise<void>) => void | Promise<void>;
  message: React.ReactNode;
};
type Props = OwnProps & ButtonProps & PopoverProps;

const InfoComponent = ({
  message,
  show,
  onPopoverRef,
  onTriggerRef,
  toggle,
  onClick,
  loading,
  ready,
  setLoading,
  setReady,
  children
}: Props) => {
  const close = useCallback(() => {
    toggle(false);
  }, [toggle]);

  const handleClick = async () => {
    if (onClick) {
      await onClick(setLoading, setReady);
    }

    toggle(false);
  };

  return (
    <div className={styles.container}>
      <div ref={onTriggerRef}>{children(toggle, show)}</div>
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
          {show && (
            <Popover className={styles.popover} onClickOutside={close}>
              <CoachMessage>{message}</CoachMessage>
              <AsyncButton className={styles.action} onClick={handleClick} loading={loading} ready={ready} fluid>
                Ok
              </AsyncButton>
            </Popover>
          )}
        </div>
      </Portal>
    </div>
  );
};

type UseInfo = PopoverProps & ButtonProps & { Info: typeof InfoComponent };

export const Info = ({ children, ...props }: OwnProps): React.JSX.Element => {
  const { Info, ...rest } = useInfo();

  return (
    <Info {...props} {...rest}>
      {children}
    </Info>
  );
};

export const useInfo = (): UseInfo => {
  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, POPPER);
  const { loading, ready, setLoading, setReady } = useAsyncButton();

  return {
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    loading,
    ready,
    setLoading,
    setReady,
    Info: InfoComponent
  };
};
