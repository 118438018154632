import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { get, post } from '~/api/utils';
import { FileObject } from '~/types/gists/file';

export { images } from './images';
export { icons } from './icons';
export { fonts } from './fonts';

export type Fields = {
  Policy: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  'X-Amz-Signature': string;
  bucket: string;
  success_action_status: string;
};

type GetPresignedURLResponse = {
  url: string;
  folderPath: string;
  fields: Fields;
};

type GetPresignedURLParams = {
  folder: FOLDER_TYPES;
};

const getPresignedURL = async (params: GetPresignedURLParams): Promise<GetPresignedURLResponse> =>
  await get<GetPresignedURLResponse, GetPresignedURLParams>(`/files/presigned-post`, params);

const uploadFileToBucketByPresignedURL = async (
  url: string,
  type: string,
  size: number,
  formData: FormData
): Promise<unknown> =>
  await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'content-length': `${size}`
    }
  });

export type UploadFileParams = Array<{
  key: string;
  size: number;
  dimensions?: string;
}>;

const uploadFile = async (params: UploadFileParams): Promise<FileObject[]> =>
  await post<FileObject[], UploadFileParams>('/files', params);

type ValidateNamesParams = {
  folder: FOLDER_TYPES;
  names: Array<string>;
};
export type UniquenessFile = { name: string; altName: string; target: FileObject };
type ValidateNamesResponse = Array<UniquenessFile>;
const validateNames = async (params: ValidateNamesParams): Promise<ValidateNamesResponse> =>
  await post<ValidateNamesResponse, ValidateNamesParams>('/files/validate-names', params);

export const files = {
  getPresignedURL,
  uploadFileToBucketByPresignedURL,
  uploadFile,
  validateNames
};
