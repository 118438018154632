import { Reducer } from 'react';

import { ActionType } from '~/pages/tags/context/store/actions';
import { FlatTag } from '~/types/gists/tag';
import { DispatchThunk } from '~/hooks/use-thunk-reducer';

export enum DiscardType {
  NewTag = 'NewTag',
  CreatedTag = 'CreatedTag',
  CreatedInvalidTag = 'CreatedInvalidTag',
  None = 'None'
}

export type State = {
  originalTags: Array<FlatTag>;
  virtualTags: Array<FlatTag>;
  isLoading: boolean;
  errorMessage: Array<string>;
  selectedTagsIds: Array<number>;
  expandedTagsIds: Array<number>;
  validationErrors: Record<string, string>;
  shouldValidate: boolean;
  discardType: DiscardType;
  hasUnsavedChanges: boolean;
  shouldShowDiscardChanges: boolean;
  nextTagsIdsToSelect: number[];
};

export type SetState = {
  type: ActionType;
  payload?: any;
};

export type TagsThunk = DispatchThunk<Reducer<State, SetState>>;
