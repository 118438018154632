import { OriginalTag } from '~/types/gists/tag';

export function filterTags(tags: OriginalTag[], name: string, withPerfectMatch?: boolean): OriginalTag[] {
  const getChildren = (result: OriginalTag[], tag: OriginalTag) => {
    if (withPerfectMatch) {
      if (tag.name === name) {
        result.push(tag);
        return result;
      }
    } else if (tag.name.toLowerCase().includes(name.toLowerCase())) {
      result.push(tag);
      return result;
    }

    if (Array.isArray(tag.children)) {
      const children = tag.children.reduce(getChildren, []);
      if (children.length) result.push({ ...tag, children });
    }

    return result;
  };

  return name ? tags.reduce(getChildren, []) : tags;
}
