import React, { ElementType, FocusEvent, InputHTMLAttributes, MouseEvent, useEffect, useRef } from 'react';

import { useMergeRefs } from '~/hooks/use-merge-refs';

import classnames from 'classnames';
import { TextNode } from '~/components/_layout/typography/text';
import { Label } from '~/components/_layout/typography/label';

import { UseContentAllowedExternalProps } from '../types';
import { useContentEditable } from '~/components/contenteditable';

import styles from './contenteditable-as-input.module.scss';
import { Icon } from '~/components/icon';

type Props = {
  as: ElementType;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  error?: string;
  isRequired?: boolean;
  editingInitialState?: boolean;
} & UseContentAllowedExternalProps<HTMLInputElement> &
  InputHTMLAttributes<HTMLInputElement>;

export const ContentEditableAsInput = ({
  as: Component = Label,
  className,
  keyResetters = [],
  keySubmitters = [],
  onClick,
  onBlur,
  isRequired,
  editingInitialState = false,
  ...props
}: Props): React.JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const {
    className: container,
    isEditing,
    ref,
    handleBlur,
    handleClick,
    handleKeyDown
  } = useContentEditable<HTMLInputElement>({
    keyResetters,
    keySubmitters: [{ key: 'enter' }, ...keySubmitters],
    onClick,
    onBlur,
    editingInitialState
  });

  const { value, title, placeholder, error } = props;

  const mergedRef = useMergeRefs(ref, inputRef);

  useEffect(() => {
    if (editingInitialState || isEditing) {
      inputRef.current?.select();
    }
  }, [editingInitialState, isEditing]);

  return (
    <>
      <div
        className={classnames(container, styles.container, { [styles.error]: error }, className)}
        onBlur={handleBlur}
        onClick={isEditing ? undefined : handleClick}
      >
        {isEditing ? (
          <Component title={title}>
            <div className={styles.label}>
              <input
                ref={mergedRef}
                size={1}
                className={styles.input}
                autoCapitalize='off'
                autoComplete='off'
                autoCorrect='off'
                spellCheck='false'
                onKeyDown={handleKeyDown}
                {...props}
              />
              <span className={styles.value}>{value}</span>
            </div>
          </Component>
        ) : (
          <div role='textbox' tabIndex={0} className={styles.button}>
            <Component title={title}>
              <TextNode truncated>
                <span className={classnames(styles.text, { [styles.empty]: !value && !placeholder })}>
                  {value || placeholder}
                </span>
              </TextNode>
            </Component>
            {!error && isRequired && <Icon name='asterisk' className={styles.asterisk} />}
          </div>
        )}
      </div>
      {error ? (
        <div className={styles['error-wrapper']}>
          <Icon name='alert' className={styles['error-icon']} />
          <Label size='s' className={styles['error-message']}>
            {error}
          </Label>
        </div>
      ) : null}
    </>
  );
};
