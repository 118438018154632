import React, { useState } from 'react';
import { Label } from '~/components/_layout/typography/label';
import { TextareaAutosize } from '~/components/_inputs/textarea';

import styles from './meta-item.module.scss';

type Props = {
  label: string;
  value: string;
  onChange: HandleChange;
};

const MetaItem = ({ label, value, onChange }: Props) => {
  const [metaValue, setMetaValue] = useState(value);

  return (
    <div className={styles.container}>
      <Label size='xs' className={styles.label}>
        {label}
      </Label>

      <TextareaAutosize
        value={metaValue}
        onChange={(_, newValue) => setMetaValue(newValue)}
        minRows={3}
        onBlur={() => onChange('', metaValue)}
      />
    </div>
  );
};

export default MetaItem;
