import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import TagsTree from '~/templates/tags-interaction/components/TagsTree';
import { TagName } from '~/templates/tags-interaction/components/TagsTree/components/TagName';
import { Icon } from '~/components/icon';

import styles from './styles.module.scss';
import { OriginalTag } from '~/types/gists/tag';

type Props = {
  node: OriginalTag;
  onSelectTag: (selectedTag: OriginalTag) => void;
  filterValue: string;
  isSelected: (node: OriginalTag) => boolean;
};

const TagsNode = ({ node, onSelectTag, filterValue, isSelected }: Props): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = !!node.children?.length;

  const disabled = useMemo(
    () => isSelected(node) || (filterValue.length && !node.name.toLowerCase().includes(filterValue.toLowerCase())),
    [filterValue, isSelected, node]
  );

  return (
    <li className={styles.container}>
      <div
        className={classnames(styles.item, { [styles.disabled]: disabled })}
        onClick={() => !disabled && onSelectTag(node)}
      >
        {hasChildren && (
          <div
            onClick={e => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            className={styles.caret}
          >
            <Icon name='arrow-triangle-right' className={classnames(styles.caret, { [styles.expanded]: isExpanded })} />
          </div>
        )}

        <Icon name='tags' />
        <TagName name={node.name} />
      </div>

      {hasChildren && isExpanded && (
        <div className={styles.children}>
          <TagsTree
            className={styles['children-tree']}
            data={node.children as OriginalTag[]}
            onSelectTag={onSelectTag}
            filterValue={filterValue}
            isTagSelected={isSelected}
          />
        </div>
      )}
    </li>
  );
};

export default TagsNode;
