import React, { memo } from 'react';
import { ButtonProps, PopoverProps } from '~/components/_layout/popovers/confirm';
import { ImportExportPopoverContent } from '~/components/_layout/popovers/import-export/content-component';
import { PopoverComponentOwnProps } from '~/components/_layout/popovers/import-export/types';
import { useImportExportPopoverHandlers } from '~/components/_layout/popovers/import-export/use-content-handlers';
import { INITIAL_CODE_VALUE } from '~/components/_layout/popovers/import-export/constants';
import { Button } from '~/components/button';
import styles from './index.module.scss';

export type Props = PopoverComponentOwnProps & Pick<PopoverProps, 'toggle'> & ButtonProps;

export const CoachPopoverComponent = memo(
  ({
    operationOptions,
    onSubmit,
    toggle,
    loading,
    ready,
    afterSubmit,
    hasError,
    setDefaultCode
  }: Props): React.JSX.Element => {
    const {
      defaultCode = INITIAL_CODE_VALUE,
      editable,
      title,
      message = '',
      submitText,
      successTitle,
      operationType
    } = operationOptions;

    const { handleSubmit, handleChange, code, onClose, closePopover, responseMessage } = useImportExportPopoverHandlers(
      {
        defaultCode,
        toggle,
        setDefaultCode,
        afterSubmit,
        onSubmit
      }
    );

    return (
      <div className={styles.popover}>
        <Button is='transparent' onClick={closePopover} icon='cross' className={styles.cross} />
        <ImportExportPopoverContent
          operationType={operationType}
          handleSubmit={handleSubmit}
          loading={loading}
          ready={ready}
          hasError={hasError}
          code={code}
          editable={!!editable}
          handleChange={handleChange}
          title={title}
          message={message}
          submitText={submitText}
          successTitle={successTitle}
          onClose={onClose}
          responseMessage={responseMessage}
        />
      </div>
    );
  }
);

CoachPopoverComponent.displayName = 'CoachPopoverComponent';
