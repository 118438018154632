import { compose } from 'redux';

type RoundMethod = 'round' | 'floor' | 'ceil';
type RoundProp = boolean | RoundMethod;
type RoundFunction = { (value: number): number };

const ALLOWED_ROUND_METHODS: RoundMethod[] = ['round', 'floor', 'ceil'];

const roundBy = (round: RoundProp = true): RoundFunction => {
  return (value: number) => {
    let method: RoundMethod | null;

    switch (typeof round) {
      case 'boolean':
        method = round ? 'round' : null;
        break;
      case 'string':
        method = ALLOWED_ROUND_METHODS.includes(round) ? round : null;
        break;
      default:
        method = null;
    }

    return method ? Math[method](value) : value;
  };
};

const transformBy = <T>(transform?: T) => {
  return (value: number) => {
    return typeof transform === 'function' ? transform(value) : value;
  };
};

const percentOf = <Value = number>(
  value: number,
  target: number,
  round?: RoundProp,
  transform?: (value: number) => Value
): Value => {
  const percent = target !== 0 ? (value / target) * 100 : 0;

  return compose(transformBy(transform), roundBy(round))(percent);
};
const percentIs = <Value = number>(
  value: number,
  target: number,
  round?: RoundProp,
  transform?: (value: number) => Value
): Value => {
  const sum = (value * target) / 100;

  return compose(transformBy(transform), roundBy(round))(sum);
};
const decreaseBy = <Value = number>(
  target: number,
  percent: number,
  round?: RoundProp,
  transform?: (value: number) => Value
): Value => {
  const result = target * (1 - percent / 100);

  return compose(transformBy(transform), roundBy(round))(result);
};
const increaseBy = <Value = number>(
  target: number,
  percent: number,
  round?: RoundProp,
  transform?: (value: number) => Value
): Value => {
  const result = target * ((100 + percent) / 100);

  return compose(transformBy(transform), roundBy(round))(result);
};

type InRangeMethod = 'min' | 'max';
type InRangeOptions = Partial<Record<InRangeMethod, number>>;

const inRange = (value: number, options: InRangeOptions = {}): number => {
  const { min = -Infinity, max = Infinity } = options;

  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

type LoopInIterator = {
  (): number;
  getIteration: { (): number };
  isIterationDone: { (): boolean };
};

const loopIn = (min = 0, max = 0, step = 1): LoopInIterator => {
  let current = min;
  let iteration = 0;
  let isIterationDone = false;

  const loop: LoopInIterator = () => {
    const value = current;

    if (current < max) {
      current += step;
      isIterationDone = false;
    } else {
      current = min;
      iteration = ++iteration;
      isIterationDone = true;
    }

    return value;
  };

  loop.getIteration = () => {
    return iteration;
  };
  loop.isIterationDone = () => {
    return isIterationDone;
  };

  return loop;
};

const random = (min = 0, max = 100): number => {
  if (max <= min) {
    throw new Error(`'max' should be greater then 'min'`);
  }

  return Math.round(min - 0.5 + Math.random() * (max - min + 1));
};

const takePrecision = (value: number): number => {
  if (isNaN(value) || Number.isInteger(value)) {
    return 0;
  }

  return Number(Math.abs(value).toString().split('.')[1].length);
};

const toNearestOf = (value: number, increment: number): number => {
  const precision = takePrecision(increment);

  if (precision && Number(increment.toString().split('').pop()) % 5 > 0) {
    const nextValue = value + increment;

    return Number(nextValue.toFixed(precision));
  }

  const nextValue = value % increment === 0 ? value + increment : roundBy('ceil')(value / increment) * increment;

  return Number(nextValue.toFixed(precision));
};

type LinearlyDistributedOptions = {
  parts?: number;
  round?: RoundProp;
  reverse?: boolean;
  format?: (value: number) => unknown;
};

const toLinearlyDistributedRange = <Value = number>(
  value: number,
  options: LinearlyDistributedOptions = {}
): Value[] => {
  const { parts = 4, format, round = 'round', reverse = false } = options;

  const periods = [];
  const delta = value / parts;

  const put = (value: number) => (reverse ? Array.prototype.push : Array.prototype.unshift).call(periods, value);

  let cursor = value;

  do {
    compose(put, transformBy(format), roundBy(round))(cursor);
  } while ((cursor -= delta) >= 0);

  return periods;
};

const difference = (a: number, b: number, transform?: string): number => {
  const difference = a - b;

  if (transform === '%') {
    return (difference / b) * 100;
  }

  return difference;
};

export class NumberShim {
  static percentOf = percentOf;
  static percentIs = percentIs;
  static decreaseBy = decreaseBy;
  static increaseBy = increaseBy;
  static inRange = inRange;
  static loopIn = loopIn;
  static random = random;
  static takePrecision = takePrecision;
  static toNearestOf = toNearestOf;
  static toLinearlyDistributedRange = toLinearlyDistributedRange;
  static difference = difference;
}
