import React, { forwardRef } from 'react';
import { Column as UIKitColumn, Grid as UIKitGrid, GridCSSProperties } from '~/helpers/ui-kit/grid';
import { mapProps } from './helpers';
import * as TYPES from './types';

export type OwnProps = {
  renderAs?: React.ElementType;
  className?: string;
  children?: React.ReactNode;
  style?: GridCSSProperties;
};

export type GridProps = {
  span?: TYPES.SpanHelper;
  edge?: TYPES.EdgeHelper;
  flex?: TYPES.FlexDirectionHelper;
  wrap?: TYPES.FlexWrapHelper;
  align?: TYPES.FlexAlignHelper;
  justify?: TYPES.FlexJustifyHelper;
};

export type ColumnProps = OwnProps & GridProps;

const GridComponent = ({ renderAs, ...props }: OwnProps, ref?: React.Ref<unknown>) => (
  <UIKitGrid as={renderAs} ref={ref} {...props} />
);
const ColumnComponent = ({ renderAs, ...props }: ColumnProps, ref?: React.Ref<unknown>) => (
  <UIKitColumn as={renderAs} ref={ref} {...mapProps(props)} />
);

export const Grid = forwardRef(GridComponent);
export const Column = forwardRef(ColumnComponent);
