import { useContext } from 'react';
import { TableInstanceContext } from '~/components/_table/context';

const useTableInstanceContext = () => {
  const context = useContext(TableInstanceContext);
  if (context === undefined) {
    throw new Error('useTableInstanceContext must be used within a TableInstanceContext');
  }

  return context;
};

export default useTableInstanceContext;
