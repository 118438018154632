import { Route } from '~/routes/types';
import { PushNotifications } from '~/pages/push-notifications';
import { EditPushNotification } from '~/pages/push-notifications/edit';

import { PUSH_NOTIFICATIONS_ROUTES } from './constants';

export const pushNotifications: Route[] = [
  {
    to: PUSH_NOTIFICATIONS_ROUTES.root,
    exact: true,
    component: PushNotifications
  },
  {
    to: PUSH_NOTIFICATIONS_ROUTES.edit,
    exact: true,
    component: EditPushNotification
  }
];
