import { Route } from '~/routes/types';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import Pages from '~/pages/pages';
import EditPage from '~/pages/pages/edit';

export const pages: Route[] = [
  {
    to: PAGES_ROUTES.root,
    exact: true,
    component: Pages
  },
  {
    to: PAGES_ROUTES.edit,
    exact: true,
    component: EditPage
  },
  {
    to: PAGES_ROUTES.new,
    exact: true,
    component: EditPage
  }
];
