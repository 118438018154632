import React from 'react';
import styles from './coach-message.module.scss';

type Props = {
  children?: React.ReactNode;
};

export const CoachMessage = ({ children }: Props): React.JSX.Element => {
  return (
    <>
      {React.Children.map(children, (child, index) => (
        <div key={index} className={styles.container}>
          {child}
        </div>
      ))}
    </>
  );
};
