import { produce } from 'immer';
import { v4 as uuid } from 'uuid';
import { EditorState } from 'draft-js';

import { BREAKPOINT_LABELS, CONTAINERS, ZOOM_VALUES } from '~/pages/pages/edit/context/store/enums';
import { generateLayerId, layersMapperByBlockType, parseLayerId } from '~/pages/pages/edit/context/store/layers';
import { getFirstLayerToSelect } from '~/pages/pages/edit/context/store/thunks';
import { SetState, State } from '~/pages/pages/edit/context/store/types';
import { ActionTypes } from '~/pages/pages/edit/context/store/actions';
import {
  collectContentFromLayers,
  duplicateElement,
  moveElementBackward,
  moveElementForward,
  removeElementFromArray,
  removeLayerById,
  removeObjectById
} from '~/pages/pages/edit/context/store/helpers';
import { StringShim } from '~/helpers/shims/string-shim';
import { mapPageFromAPI } from '~/pages/pages/edit/context/store/mappers';
import { LAYER_TYPES, PublicBlock } from '~/types/gists/page';

export const initialState: State = {
  richEditorState: EditorState.createEmpty(),
  affiliateId: 0,
  seo: {
    google: {
      title: undefined,
      description: undefined,
      canonical: undefined,
      noindex: false
    },
    facebook: {
      og_title: undefined,
      og_description: undefined,
      og_image: undefined
    },
    twitter: {
      twitter_title: undefined,
      twitter_description: undefined,
      twitter_image: undefined
    }
  },
  url: '',
  id: 0,
  createdAt: '',
  createdBy: '',
  updatedAt: null,
  updatedBy: null,
  readTimeMinutes: 0,
  name: 'New Page',
  slug: 'new-page',
  isEnabled: false,
  overrodeAt: undefined,
  isNew: false,
  tags: [],
  isReady: false,
  isLoading: false,
  isTouched: false,
  error: null,
  validationErrors: {
    pageName: null,
    slug: null
  },
  selectedLayerId: '',
  editingLayerId: '',
  blocks: {},
  structure: {},
  containers: {},
  preview: {
    breakpoint: BREAKPOINT_LABELS.Small,
    width: 375,
    zoom: ZOOM_VALUES.full
  },
  availableBlocks: new Map(),
  title: null,
  excerpt: null,
  label: null,
  image: null,
  cohortId: null,
  displayConditionRule: null,
  availableImages: new Map()
};

export const reducer = (state: State, action: SetState): State => {
  switch (action.type) {
    case ActionTypes.SavePageName: {
      return produce(state, draft => {
        draft.name = action.payload;
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetLoading: {
      return produce(state, draft => {
        draft.isLoading = action.payload;
      });
    }
    case ActionTypes.SetError: {
      return produce(state, draft => {
        draft.error = action.payload;
      });
    }
    case ActionTypes.SetValidationError: {
      return produce(state, draft => {
        draft.validationErrors[action.payload.fieldName] = action.payload.error;
      });
    }
    case ActionTypes.SetInitialPageData: {
      const { structure, blocks, containers, readTimeMinutes } = mapPageFromAPI(action.payload);

      delete action.payload.data;

      return {
        ...state,
        ...action.payload,
        structure,
        blocks,
        containers,
        readTimeMinutes
      };
    }
    case ActionTypes.SetIsReady: {
      return produce(state, draft => {
        draft.isReady = action.payload;
      });
    }
    case ActionTypes.SetIsNew: {
      return produce(state, draft => {
        draft.isNew = action.payload;
      });
    }
    case ActionTypes.SetIsEnabled: {
      return produce(state, draft => {
        draft.isEnabled = action.payload;
      });
    }
    case ActionTypes.SetBreakpoint: {
      return produce(state, draft => {
        draft.preview.breakpoint = action.payload;
      });
    }
    case ActionTypes.SetWidth: {
      return produce(state, draft => {
        draft.preview.width = action.payload;
      });
    }
    case ActionTypes.SetZoom: {
      return produce(state, draft => {
        draft.preview.zoom = action.payload;
      });
    }
    case ActionTypes.SetSlug: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.slug = action.payload;
      });
    }
    case ActionTypes.SetOverrodeAt: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.overrodeAt = action.payload;
      });
    }
    case ActionTypes.SetGoogleSEO: {
      return produce(state, draft => {
        if (draft.seo.google) {
          draft.isTouched = true;
          draft.seo.google[action.payload.name] = action.payload.value;
        }
      });
    }
    case ActionTypes.SetFacebookSEO: {
      return produce(state, draft => {
        if (draft.seo.facebook) {
          draft.isTouched = true;
          draft.seo.facebook[action.payload.name] = action.payload.value;
        }
      });
    }
    case ActionTypes.SetTwitterSEO: {
      return produce(state, draft => {
        if (draft.seo.twitter) {
          draft.isTouched = true;
          draft.seo.twitter[action.payload.name] = action.payload.value;
        }
      });
    }
    case ActionTypes.SetAvailableBlocks: {
      return produce(state, draft => {
        draft.availableBlocks = action.payload;
      });
    }
    case ActionTypes.AddBlockByIndex: {
      const bodyOrder = [...state.structure[action.payload.containerId]];
      bodyOrder.splice(action.payload.index, 0, action.payload.block.id);

      return produce(state, draft => {
        draft.isTouched = true;
        draft.structure[action.payload.containerId] = bodyOrder;
        draft.blocks[action.payload.block.id] = action.payload.block;
      });
    }
    case ActionTypes.SetSelectedLayerId: {
      return produce(state, draft => {
        draft.selectedLayerId = action.payload;
      });
    }
    case ActionTypes.MoveBlockUp: {
      return produce(state, draft => {
        const containerId = Object.keys(draft.structure).find(containerKey =>
          draft.structure[containerKey].includes(action.payload)
        );

        if (containerId) {
          draft.isTouched = true;
          draft.structure[containerId] = moveElementBackward(draft.structure[containerId], action.payload);
        }
      });
    }
    case ActionTypes.MoveBlockDown: {
      return produce(state, draft => {
        const containerId = Object.keys(draft.structure).find(containerKey =>
          draft.structure[containerKey].includes(action.payload)
        );

        if (containerId) {
          draft.isTouched = true;
          draft.structure[containerId] = moveElementForward(draft.structure[containerId], action.payload);
        }
      });
    }
    case ActionTypes.DeleteBlock: {
      return produce(state, draft => {
        const containerId = Object.keys(draft.structure).find(containerKey =>
          draft.structure[containerKey].includes(action.payload)
        );

        if (containerId) {
          draft.isTouched = true;
          draft.structure[containerId] = removeElementFromArray(draft.structure[containerId], action.payload);
          draft.blocks = removeObjectById(draft.blocks, action.payload);
          draft.selectedLayerId = '';
        }
      });
    }
    case ActionTypes.DuplicateBlock: {
      return produce(state, draft => {
        const containerId = Object.keys(draft.structure).find(containerKey =>
          draft.structure[containerKey].includes(action.payload.id)
        );

        if (containerId) {
          draft.isTouched = true;
          draft.structure[containerId] = duplicateElement(
            draft.structure[containerId],
            action.payload.id,
            action.payload.newId
          );

          const layersMapper = layersMapperByBlockType[draft.blocks[action.payload.id].type];

          const blockToCopy = draft.blocks[action.payload.id];
          const newBlockId = action.payload.newId;

          const newBlock: PublicBlock = {
            ...blockToCopy,
            id: newBlockId,
            data: {
              ...blockToCopy.data,
              // Re-mapping block's layers based on the brand-new id.
              layers: layersMapper({ ...blockToCopy, id: newBlockId })
            }
          };

          draft.blocks[action.payload.newId] = newBlock;

          const firstLayerToSelect = getFirstLayerToSelect(newBlock);

          if (firstLayerToSelect) {
            draft.selectedLayerId = firstLayerToSelect.id;
          }
        }
      });
    }
    case ActionTypes.SetUpdatedMetaFields: {
      return {
        ...state,
        updatedAt: action.payload.updatedAt,
        updatedBy: action.payload.updatedBy
      };
    }
    case ActionTypes.UpdateBlockContent: {
      return produce(state, draft => {
        const block = draft.blocks[action.payload.id];
        const layersMapper = layersMapperByBlockType[block.type];

        block.data.content = action.payload.content;
        const blockToMap = { ...block, data: { ...block.data, content: action.payload.content } };
        block.data.layers = layersMapper(blockToMap);
        draft.isTouched = true;
      });
    }
    case ActionTypes.InitContainers: {
      return produce(state, draft => {
        CONTAINERS.forEach(containerType => {
          const containerId = uuid();

          draft.containers[containerId] = {
            id: containerId,
            type: containerType,
            name: StringShim.capitalize(containerType)
          };
          draft.structure[containerId] = [];
        });
      });
    }
    case ActionTypes.SetRichEditorState: {
      return produce(state, draft => {
        draft.richEditorState = action.payload;
      });
    }
    case ActionTypes.SetTags: {
      return produce(state, draft => {
        draft.tags = action.payload;
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetBlockVariantById: {
      return produce(state, draft => {
        draft.blocks[action.payload.id].data.variant = action.payload.variant;
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetIsTouched: {
      return produce(state, draft => {
        draft.isTouched = action.payload;
      });
    }
    case ActionTypes.SetBlockType: {
      const templateBlock = Array.from(state.availableBlocks.values()).find(
        block => block.type === action.payload.newType
      ) as PublicBlock;

      const layersMapper = layersMapperByBlockType[templateBlock.type];

      return produce(state, draft => {
        const { data, name, type } = templateBlock;
        const id = draft.blocks[action.payload.id].id;
        draft.blocks[action.payload.id] = {
          data: { ...data, layers: layersMapper({ ...templateBlock, id }) },
          name,
          type,
          id
        };
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetBlockLayerContent: {
      if (state.selectedLayerId) {
        const { layerIndex, blockId } = parseLayerId(state.selectedLayerId);

        return produce(state, draft => {
          draft.blocks[blockId].data.layers[layerIndex].content = action.payload.newContent;
          draft.isTouched = true;
        });
      }

      return state;
    }
    case ActionTypes.SetCohortId: {
      return produce(state, draft => {
        draft.cohortId = action.payload;
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetDisplayConditionRule: {
      return produce(state, draft => {
        draft.displayConditionRule = action.payload;
        draft.isTouched = true;
      });
    }
    case ActionTypes.SetPageImage: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.image = action.payload;
      });
    }
    case ActionTypes.SetPageTitle: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.title = action.payload;
      });
    }
    case ActionTypes.SetPageExcerpt: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.excerpt = action.payload;
      });
    }
    case ActionTypes.SetPageLabel: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.label = action.payload;
      });
    }
    case ActionTypes.ToggleImageBlockCaption: {
      const block = state.blocks[action.payload];
      const captionLayer = block.data.layers.find(layer => layer.name === 'Caption');

      return produce(state, draft => {
        draft.isTouched = true;

        if (captionLayer) {
          draft.blocks[action.payload].data.layers = removeLayerById(
            draft.blocks[action.payload].data.layers,
            captionLayer.id
          );
        } else {
          const captionLayer = {
            type: LAYER_TYPES.CONTENT,
            id: generateLayerId(block.id, block.type, draft.blocks[action.payload].data.layers.length),
            name: 'Caption',
            content: '<figcaption>{imageCaption}</figcaption>',
            children: []
          };
          draft.blocks[action.payload].data.layers.push(captionLayer);
          draft.blocks[action.payload].data.layers[1].children.push(captionLayer.id);
        }

        draft.blocks[action.payload].data.content = collectContentFromLayers(draft.blocks[action.payload].data.layers);
      });
    }
    case ActionTypes.UpdateBlockData: {
      return produce(state, draft => {
        draft.isTouched = true;
        draft.blocks[action.payload.id].data = {
          ...draft.blocks[action.payload.id].data,
          ...action.payload.data
        };
      });
    }
    case ActionTypes.SetAvailableImages: {
      return produce(state, draft => {
        draft.availableImages = action.payload;
      });
    }
    case ActionTypes.UpdateAvailableImage: {
      return produce(state, draft => {
        draft.availableImages.set(action.payload.key, action.payload);
      });
    }
    default:
      return state;
  }
};
