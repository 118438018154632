import { post } from './utils';
import { MFA_CHALLENGES } from '~/pages/onboarding/mfa/constants';

type Email = { email: string };
type Password = { password: string };
type Code = { code: string };
type Session = { session: string };
type NextChallenge = { nextChallenge: MFA_CHALLENGES };
type Success = { success: boolean };
type PhoneNumber = { phoneNumber: string };
type IsEmailAvailable = { isAvailable: boolean };

type EmailPassword = Email & Password;
type EmailCode = Email & Code;
type EmailCodePassword = Email & Code & Password;

export type UserParams = {
  accessToken: string;
  refreshToken: string;
  token: string;
} & Email &
  Partial<NextChallenge> &
  Partial<Session>;

const login = async ({ email, password }: EmailPassword): Promise<UserParams> =>
  await post<UserParams, EmailPassword>('/login', { email, password });

const isEmailAvailable = async ({ email }: Email): Promise<IsEmailAvailable> =>
  await post<IsEmailAvailable, Email>('/is-email-available', { email });

const signUp = async ({ email, password }: EmailPassword): Promise<[]> =>
  await post<[], EmailPassword>('/sign-up', { email, password });

type ConfirmSignUpResponse = Success & NextChallenge;
const confirmSignUp = async ({ email, code }: EmailCode): Promise<ConfirmSignUpResponse> =>
  await post<ConfirmSignUpResponse, EmailCode>('/confirm-sign-up', { email, code });

const forgotPasswordRequest = async ({ email }: Email): Promise<[]> =>
  await post<[], Email>('/forgot-password-request', { email });

const confirmForgotPassword = async ({ email, code, password }: EmailCodePassword): Promise<[]> =>
  await post<[], EmailCodePassword>('/confirm-forgot-password', { email, code, password });

const resendCode = async ({ email }: Email): Promise<[]> => await post<[], Email>('/resend-sign-up', { email });

type VerifyMFAParams = Email & Code & Session;
const verifyMFA = async (verifyMFAParams: VerifyMFAParams): Promise<UserParams> =>
  await post<UserParams, VerifyMFAParams>('/verify-mfa', verifyMFAParams);

type ResendMFAParams = Email & Password;
type ResendMFAResponse = Email & NextChallenge & Session & Success;
const resendMFA = async (resendParams: ResendMFAParams): Promise<ResendMFAResponse> =>
  await post<ResendMFAResponse, ResendMFAParams>('/resend-mfa', resendParams);

type SavePhoneNumberParams = Email & Password & PhoneNumber;
type SavePhoneNumberResponse = Success & NextChallenge;
const savePhoneNumber = async (savePhoneNumberParams: SavePhoneNumberParams): Promise<SavePhoneNumberResponse> =>
  await post<SavePhoneNumberResponse, SavePhoneNumberParams>('/phone-number', savePhoneNumberParams);

const resendPhoneNumberVerify = async (resendParams: ResendMFAParams): Promise<ResendMFAResponse> =>
  await post<ResendMFAResponse, ResendMFAParams>('/resend-phone-number', resendParams);

type VerifyPhoneParams = Email & Password & Code;
type VerifyPhoneResponse = Success;
const verifyPhoneNumber = async (verifyParams: VerifyPhoneParams): Promise<VerifyPhoneResponse> =>
  post<VerifyPhoneResponse, VerifyPhoneParams>('/verify-phone-number', verifyParams);

export const onboarding = {
  login,
  signUp,
  forgotPasswordRequest,
  isEmailAvailable,
  confirmSignUp,
  resendCode,
  confirmForgotPassword,
  verifyMFA,
  resendMFA,
  savePhoneNumber,
  resendPhoneNumberVerify,
  verifyPhoneNumber
};
