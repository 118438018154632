import { endOfDay } from '~/helpers/shims/date-shim';
import { SchedulerConfig } from '~/pages/push-notifications/edit/panel/scheduler/config';
import { RepeatableConfig } from '~/types/gists/push-notification';
import { FREQUENCY_TYPES } from './panel/scheduler/types';

const frequencyWithEntityValue = [FREQUENCY_TYPES.week, FREQUENCY_TYPES.month];

export const buildRepeatableConfig = (config: SchedulerConfig): RepeatableConfig => {
  const { time, everyEntity, entityValue, everyNumber, ends, end, occurrences } = config;

  return {
    time,
    end: end && endOfDay(end),
    ends,
    entityValue: frequencyWithEntityValue.includes(everyEntity) ? entityValue : undefined,
    everyEntity,
    everyNumber,
    occurrences
  };
};
