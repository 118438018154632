import { CustomError } from '~/helpers/common/custom-error';
import { FileObject } from '~/types/gists/file';
import { files as filesApi, UploadFileParams } from '~/api/files';
import { api } from '~/api';
import { DIMENSIONS_WHITE_LIST } from '~/pages/files/components/files-table/components/add-files/components/uploader/constants';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import {
  FilesToUploadMap,
  UploadFilesResponse
} from '~/pages/files/components/files-table/components/add-files/components/uploader/types';

export const getDimensions = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const img = new Image();

      img.onload = () => {
        resolve(img.width + ' x ' + img.height);
      };

      img.onerror = reject;
      img.src = e.target?.result as string;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const renameFile = (originalFile: File, newName: string): File =>
  new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  });

export const uploadFiles = async (
  filesToUpload: FilesToUploadMap,
  activeFolder: FOLDER_TYPES,
  onError: (error: Error) => void
): Promise<UploadFilesResponse> => {
  try {
    const { url, fields, folderPath } = await filesApi.getPresignedURL({ folder: activeFolder });

    const fd = new FormData();
    Object.keys(fields).forEach(fieldKey => fd.set(fieldKey, fields[fieldKey]));

    const uploadPromises = Object.values(filesToUpload).map(async fileToUpload => {
      const key = `${folderPath}/${fileToUpload.file.name}`;
      fd.set('key', key);
      fd.set('file', fileToUpload.file);

      await filesApi.uploadFileToBucketByPresignedURL(url, fileToUpload.file.type, fileToUpload.file.size, fd);

      if (fileToUpload.isUniqueness) {
        return await api[activeFolder].edit<FileObject>({
          ...(fileToUpload.target as FileObject),
          ...(DIMENSIONS_WHITE_LIST.includes(activeFolder)
            ? { dimensions: await getDimensions(fileToUpload.file) }
            : {}),
          name: fileToUpload.file.name,
          size: String(fileToUpload.file.size)
        });
      } else {
        const uploadFileParams: UploadFileParams = [{ key, size: fileToUpload.file.size }];
        if (DIMENSIONS_WHITE_LIST.includes(activeFolder)) {
          uploadFileParams[0].dimensions = await getDimensions(fileToUpload.file);
        }

        return await filesApi.uploadFile(uploadFileParams);
      }
    });

    return await Promise.all(uploadPromises);
  } catch (unknownError) {
    const error = new CustomError(unknownError);
    console.error(error);
    onError(error);
    return void 0;
  }
};
