import { useParams } from 'react-router-dom';
import { FOLDER_TYPES } from '~/routes/private/files/constants';

const useActiveFolderType = (): FOLDER_TYPES => {
  const { type } = useParams<{ type: FOLDER_TYPES }>();

  return type;
};

export default useActiveFolderType;
