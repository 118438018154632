import React from 'react';
import { ContentBlock, ContentState, DraftStyleMap, EditorBlock, SelectionState } from 'draft-js';

import styles from './styles.module.scss';

type Props = {
  contentState: ContentState;
  block: ContentBlock;
  blockProps: Record<string, unknown>;
  customStyleMap: DraftStyleMap;
  decorator: unknown;
  direction: 'LTR' | 'RTL' | 'NEUTRAL';
  forceSelection: boolean;
  selection: SelectionState;
  customStyleFn?: Function;
};

const Figcaption = (props: Props): React.JSX.Element => (
  <figcaption className={styles.container}>
    <EditorBlock {...props} />
  </figcaption>
);

export default Figcaption;
