import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Heading } from '~/components/_layout/typography/heading';
import { Image } from '~/components/image';
import { BLOCK_TYPES_TO_THUMBNAILS_PATH } from '~/pages/pages/edit/components/left-sidebar/components/blocks-panel/components/blocks-list/utils';
import { useSidebarPanel } from '~/components/_layout/panels';
import { useTheme } from '~/components/theme';
import { PublicBlock } from '~/types/gists/page';

import styles from './styles.module.scss';

type Props = {
  block: PublicBlock;
  index: number;
};

const BlockItem = ({ block, index }: Props): React.JSX.Element => {
  const { ListItem } = useSidebarPanel();
  const { theme } = useTheme();

  const thumbnailSrc = BLOCK_TYPES_TO_THUMBNAILS_PATH[block.type]?.[theme];

  return (
    <Draggable draggableId={block.id.toString()} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <ListItem withoutBorder className={styles.item}>
            <Heading size='2xs'>{block.name}</Heading>
            {thumbnailSrc && (
              <Image src={thumbnailSrc} alt={`${block.type}_block_thumbnail`} className={styles.thumbnail} />
            )}
          </ListItem>
        </div>
      )}
    </Draggable>
  );
};

export default BlockItem;
