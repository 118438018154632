import React, { useMemo } from 'react';
import MetaItem from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/MetaItem';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';

import styles from './meta-items.module.scss';
import { isEqual } from '~/helpers/common';
import { setAltText, setDescription } from '~/components/_table/cells/file-primary/context/actions';

const MetaItems = () => {
  const { dispatch, altText, description } = useFilePrimaryCellContext();

  const items = useMemo(
    () => [
      {
        label: 'Alt text',
        value: altText ?? '',
        onChange: (name, value) => !isEqual(value, altText) && dispatch(setAltText(value))
      },
      {
        label: 'Description',
        value: description ?? '',
        onChange: (name, value) => !isEqual(value, description) && dispatch(setDescription(value))
      }
    ],
    [altText, description, dispatch]
  );

  return (
    <div className={styles.container}>
      {items.map(item => (
        <MetaItem key={item.label} label={item.label} value={item.value} onChange={item.onChange} />
      ))}
    </div>
  );
};

export default MetaItems;
