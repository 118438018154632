import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import classnames from 'classnames';

import styles from './value.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
}>;

export const ValueComponent = ({ children, className }: Props, ref?: Ref<HTMLDivElement>): React.JSX.Element => {
  return (
    <div ref={ref} className={classnames(styles.container, styles.size, className)}>
      {children}
    </div>
  );
};

export const Value = forwardRef<HTMLDivElement, Props>(ValueComponent);
