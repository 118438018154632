import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';

import styles from '../styles.module.scss';

type Props = {
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({ children, onClick, isActive }: PropsWithChildren<Props>): React.JSX.Element => (
  <div className={classnames(styles.tab, { [styles.active]: isActive })} onClick={onClick}>
    {children}
  </div>
);

export default Tab;
