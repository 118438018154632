import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { isNaN } from 'formik';

dayjs.extend(calendar);

export const formatDate = (value: string | undefined): string => {
  if (!dayjs(value).isValid() && value) {
    return value;
  }

  return dayjs(value).calendar(void 0, {
    sameDay: '[Today at] H:mm',
    nextDay: '[Tomorrow] H:mm',
    nextWeek: 'dddd H:mm',
    lastDay: '[Yesterday] H:mm',
    lastWeek: '[Last] dddd H:mm',
    sameElse: 'DD MMM YYYY'
  });
};

export const toFixed =
  (precision = 0) =>
  (value: string): string => {
    if (isNaN(Number(value))) {
      return '0';
    }

    return Number(value).toFixed(precision);
  };

export const formatPercent =
  (precision = 1) =>
  (value: string | undefined): string => {
    if (isNaN(Number(value))) {
      return '-';
    }

    return Number(value).toFixed(precision).replace(/\.0+$/, '') + '%';
  };

export type AvailableEntityBackendKeys = 'TimedMessage' | 'Cohort' | 'UserAttributeSettings' | 'File' | 'Page';

export const SINGULAR_ENTITY_TITLES: Record<AvailableEntityBackendKeys, string> = {
  TimedMessage: 'Push',
  Cohort: 'Cohort',
  UserAttributeSettings: 'Attribute',
  File: 'File',
  Page: 'Page'
};

export const PLURAL_ENTITY_TITLES: Record<AvailableEntityBackendKeys, string> = {
  TimedMessage: 'Pushes',
  Cohort: 'Cohorts',
  UserAttributeSettings: 'Attributes',
  File: 'Files',
  Page: 'Pages'
};

// Has mistakes. Upgrade with own key's map.
export const pluralToSingular = (str: string): string => str.substring(0, str.length - 1);

export const copyToClipboard = async (value: string): Promise<void> => await navigator.clipboard.writeText(value);

export const replaceEmptyStringWithNull = <T extends object>(item: T): void => {
  Object.keys(item).forEach(key => {
    if (item[key] === '') {
      item[key] = null;
    }
  });
};
