import React, { createContext, Dispatch, RefCallback, useCallback, useContext, useReducer } from 'react';
import { createPortal } from 'react-dom';
import { Actions, ActionType, initialState, reducer, SlotNames, State } from './reducer';

type ContextProps = {
  state: State;
  dispatch: Dispatch<Actions>;
};

const Context = createContext<ContextProps>({
  state: initialState,
  dispatch: () => void 0
});

type SlotsProps = {
  children?: React.ReactNode;
};

export const Slots = ({ children }: SlotsProps): React.JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const useSubscribe = (name: SlotNames): RefCallback<HTMLElement> => {
  const { dispatch } = useContext(Context);

  const subscribe = useCallback(
    (ref: HTMLElement | null) => {
      if (ref) {
        dispatch({ type: ActionType.AddSlot, payload: { ref, name } });
      }
    },
    [dispatch, name]
  );

  return subscribe;
};

export const useSlot = (name: string): HTMLElement | undefined => {
  const { state } = useContext(Context);

  return state[name];
};

type SlotProps = {
  name: SlotNames;
  children?: React.ReactNode;
};

export const Slot = ({ name, children }: SlotProps): React.JSX.Element | null => {
  const slot = useSlot(name);

  return slot ? createPortal(children, slot) : null;
};
