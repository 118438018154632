import { Route } from '~/routes/types';

import Home from '~/pages/home';

import { cohorts } from './cohorts';
import { pushNotifications } from './push-notifications';
import { tags } from './tags';
import { insights } from '~/routes/private/insights';
import { datasets } from './datasets';
import { files } from '~/routes/private/files';
import { settings } from '~/routes/private/settings';
import { blocks } from '~/routes/private/blocks';
import { pages } from '~/routes/private/pages';

export const privateRoutes: Route[] = [
  {
    to: '/',
    exact: true,
    component: Home
  },
  tags,
  insights,
  ...cohorts,
  ...pushNotifications,
  ...datasets,
  files,
  settings,
  ...blocks,
  ...pages
];
