import React, { useEffect, useRef, useState } from 'react';
import { FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';

type Props = {
  onSaveAriaLabel: (ariaLabel: string) => void;
  ariaLabel: string;
};

const AriaLabel = ({ onSaveAriaLabel, ariaLabel }: Props): React.JSX.Element => {
  const [innerAriaLabel, setInnerAriaLabel] = useState(ariaLabel);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInnerAriaLabel(ariaLabel);
  }, [ariaLabel]);

  return (
    <FormLabel label='ARIA label' placement='top'>
      <Input
        value={innerAriaLabel}
        ref={ref}
        onBlur={() => onSaveAriaLabel(innerAriaLabel)}
        onKeyDown={e => {
          if (e.key === 'Enter') ref.current?.blur();
        }}
        onChange={(_, value) => setInnerAriaLabel(value)}
      />
    </FormLabel>
  );
};

export default AriaLabel;
