import React from 'react';
import classnames from 'classnames';
import { Label, Sizes } from '~/components/_layout/typography/label';

import styles from './form-label.module.scss';
import { Icon } from '~/components/icon';

export type ExtraProps = {
  size?: Sizes;
  control?: boolean;
  placement?: LabelPlacement;
};

type UncontrolledProps = {
  label: string;
  isRequired?: boolean;
  children: React.ReactNode;
} & ExtraProps;

export type LabelPlacement = 'left' | 'right' | 'top' | 'bottom';

export const FormLabel = ({
  label = '',
  size = 'xs',
  placement = 'top',
  isRequired,
  children
}: UncontrolledProps): React.JSX.Element => {
  return (
    <label className={styles.container}>
      <div className={classnames(styles.provider, styles[placement])}>
        <div className={styles['label-container']}>
          <div className={styles.wrapper}>
            <Label size={size}>{label}</Label>
          </div>
          {isRequired && <Icon name='asterisk' className={styles.asterisk} />}
        </div>
        {children}
      </div>
    </label>
  );
};

type ControlledProps = {
  id: string;
} & UncontrolledProps;

export const ControlledFormLabel = ({
  id,
  label = '',
  size = 'xs',
  placement = 'top',
  children
}: ControlledProps): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={classnames(styles.provider, styles[placement])}>
        <div className={styles['label-container']}>
          <label htmlFor={id} className={styles.wrapper}>
            <Label size={size}>{label}</Label>
          </label>
        </div>
        {children}
      </div>
    </div>
  );
};
