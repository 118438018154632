import React, { useCallback } from 'react';

import { Button } from '~/components/button';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { deleteBlock } from '~/pages/pages/edit/context/store/actions';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

const Delete = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const onDelete = useCallback(() => {
    const { blockId } = parseLayerId(selectedLayerId);
    dispatch(deleteBlock(blockId));
  }, [dispatch, selectedLayerId]);

  return <Button is={'minor'} icon={'bin'} onClick={onDelete} />;
};

export default Delete;
