import React from 'react';
import { Icon } from '~/components/icon';

import styles from '../styles.module.scss';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';

type Props = {
  ruleId: string;
};

const DuplicateRule = ({ ruleId }: Props): React.JSX.Element => {
  const { duplicateRuleById } = useConditionBuilderContext();

  return (
    <TooltipOnHover title='Duplicate rule'>
      <div className={styles['rule-action']} onClick={() => duplicateRuleById(ruleId)}>
        <Icon name='duplicate' />
      </div>
    </TooltipOnHover>
  );
};

export default DuplicateRule;
