import type { Column } from 'react-table';
import type { CohortHistoryEntry } from '~/types/gists/cohort';

import { formatDate } from '~/helpers/formatters';
import { getHistoryEntryDuration, getHistoryEntryPushEnabled } from '../../helpers';

export const columns: Column<CohortHistoryEntry>[] = [
  {
    title: 'Date',
    accessor: 'createdAt',
    format: formatDate
  },
  {
    title: 'Size',
    accessor: 'total',
    width: 88
  },
  {
    title: 'Push enabled',
    accessor: getHistoryEntryPushEnabled,
    id: 'withFirebaseTokens',
    width: 128
  },
  {
    title: 'Duration',
    accessor: getHistoryEntryDuration,
    id: 'duration',
    width: 128,
    disableSortBy: true
  }
];
