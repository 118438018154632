import classnames from 'classnames';
import React from 'react';
import TagsNode from '~/templates/tags-interaction/components/TagsTree/components/TagsNode';
import { OriginalTag } from '~/types/gists/tag';

import styles from './styles.module.scss';

type Props = {
  data: OriginalTag[];
  onSelectTag: (selectedTag: OriginalTag) => void;
  filterValue: string;
  isTagSelected: (tag: OriginalTag) => boolean;
  className?: string;
};

const TagsTree = ({ data = [], onSelectTag, filterValue, isTagSelected, className }: Props): React.JSX.Element => (
  <ul className={classnames(styles.container, className)}>
    {data.map(node => (
      <TagsNode
        key={node.id}
        node={node}
        onSelectTag={onSelectTag}
        filterValue={filterValue}
        isSelected={isTagSelected}
      />
    ))}
  </ul>
);

export default TagsTree;
