import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

import useDocumentTitle from '~/hooks/use-document-title';

import { PasswordField } from '~/components/_form/fields/password';
import { ButtonField } from '~/components/_form/fields/button';
import { OnboardingTemplate } from '~/templates/onboarding';
import { passwordSchema } from '~/helpers/validations/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { PasswordValidation } from '../components/password-validatation';

const MESSAGE = ['Ok, now create a new password.', 'Make sure it ticks all the security requirements below.'];

const initialValues = {
  password: ''
};

export const ForgotPasswordPassword = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onSubmit = async ({ password }: { password: string }) => {
    const email = localStorage.getItem('email');

    if (email) {
      localStorage.setItem('password', password);

      history.push(ONBOARDING_ROUTES.forgotPasswordUpConfirm);
    } else {
      history.push(ONBOARDING_ROUTES.forgotPasswordUpEmail);
    }
  };

  return (
    <OnboardingTemplate message={MESSAGE} backButton>
      <Formik validationSchema={passwordSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values: { password }, isValid, dirty }) => (
          <Form>
            <PasswordField name='password' label='Password' />
            <PasswordValidation password={password} />
            <ButtonField disabled={!(isValid && dirty)} type='submit'>
              Continue
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};
