import React, { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react';
import { FakeButton, FakeProps } from '~/components/button';
import { Label } from '~/components/_layout/typography/label';

import styles from './file.module.scss';

export type OnFile = (files: FileList, fileName: string) => Promise<void> | void;
type OnRemoveFile = () => Promise<void>;

export type Props = {
  buttonLabel?: React.ReactNode;
  buttonIcon?: FakeProps['icon'];
  hideLabel?: boolean;
  defaultLabel?: string;
  onFile?: OnFile;
  onRemoveFile?: OnRemoveFile;
  isImageExists?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export type Event = ChangeEvent<HTMLInputElement>;

export const File = ({
  id,
  defaultLabel = 'No file chosen',
  hideLabel = false,
  buttonLabel = 'Upload',
  buttonIcon,
  onFile,
  onRemoveFile,
  isImageExists,
  children,
  ...props
}: Props): React.JSX.Element => {
  const [label, setLabel] = useState(defaultLabel);

  const ref = useRef<HTMLInputElement>(null);

  const reset = () => {
    if (ref && ref.current) {
      ref.current.value = '';
    }
  };

  const handleChange = async (event: Event) => {
    const files = event.target.files;

    if (files && files.length) {
      const fileName = [...files].map(({ name }) => name).join(',');
      setLabel(fileName);

      if (onFile) {
        await onFile(files, fileName);
      }
    }

    reset();
  };

  return (
    <>
      {isImageExists ? (
        <button id={id} className={styles.input} onClick={onRemoveFile} />
      ) : (
        <input ref={ref} id={id} type='file' className={styles.input} onChange={handleChange} {...props} />
      )}
      <label htmlFor={id} className={styles.label}>
        {children ?? (
          <FakeButton is='minor' className={styles.button} icon={buttonIcon}>
            {buttonLabel}
          </FakeButton>
        )}
        {!hideLabel && (
          <div className={styles.text}>
            <Label size='xs'>{label}</Label>
          </div>
        )}
      </label>
    </>
  );
};
