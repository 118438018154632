import React from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';
import { useTheme } from '~/components/theme';
import { DARK_THEME, LIGHT_THEME } from '~/components/json-editor/themes';

type ChangeHandler = (data: InteractionProps) => void;

type Props = {
  src: Record<string, unknown>;
  onEdit: ChangeHandler;
  onAdd: ChangeHandler;
  onDelete: ChangeHandler;
};

const JsonEditor = ({ src, onEdit, onAdd, onDelete }: Props): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <ReactJson
      src={src}
      onEdit={onEdit}
      onAdd={onAdd}
      onDelete={onDelete}
      theme={theme === 'dark' ? DARK_THEME : LIGHT_THEME}
    />
  );
};

export default JsonEditor;
