import React, { memo } from 'react';
import Title from '~/pages/home/components/Title';
import { Panel } from '~/components/panel';
import { Label } from '~/components/_layout/typography/label';

import styles from './messages.module.scss';
import { Image } from '~/components/image';
import { Bubble } from '~/components/bubble';

import BenLeonardAvatar from '~/assets/images/avatar_ben_leonard.svg';
import RobWinterAvatar from '~/assets/images/avatar_rob_winter.svg';

const MESSAGES = [
  {
    avatarSrc: BenLeonardAvatar,
    name: 'Ben Leonard - CEO',
    text: ['Welcome to LifeHub! We’re thrilled to open our doors to you and power up your products.']
  },
  {
    avatarSrc: RobWinterAvatar,
    name: 'Rob Winter - Head of Design',
    text: [
      'If you have any questions or suggestions about how the LifeHub app works, please email me here:',
      'rob@life-moments.co.uk'
    ]
  }
];

const Messages = (): React.JSX.Element => (
  <>
    <Title>Life Moments messages</Title>
    <Panel className={styles.container}>
      {MESSAGES.map((message, messageIndex) => (
        <div className={styles.item} key={messageIndex}>
          <div className={styles.label}>
            <Image src={message.avatarSrc} alt={message.name} />
            <Label size='xs' className={styles.name}>
              {message.name}
            </Label>
          </div>
          <div className={styles.messages}>
            {message.text.map((text, textIndex) => (
              <Bubble key={textIndex} className={styles.bubble}>
                {text}
              </Bubble>
            ))}
          </div>
        </div>
      ))}
    </Panel>
  </>
);

export default memo(Messages);
