import React from 'react';
import classnames from 'classnames';
import { CellProps, TableDataLike } from 'react-table';
import { LinkButton } from '~/components/button';
import { TextCell } from '../text';

import styles from './primary-cell.module.scss';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';

export const PrimaryCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => {
  const {
    column: { to, canEdit, isEditing, actionTooltip },
    row: { id, original }
  } = props.cell;

  const { id: itemId } = original;

  const path = {
    pathname: typeof to === 'function' ? to(original) : to + `/${itemId}`,
    state: { item: original },
    search: window.location.search
  };

  return (
    <div className={classnames(styles.container, canEdit ? styles.caret : styles.arrow)}>
      <TextCell {...props} />
      {to && !isEditing(id) && (
        <div className={styles.button}>
          <TooltipOnCondition title={actionTooltip} condition={!!actionTooltip} delay={100}>
            <LinkButton to={path} icon='edit' />
          </TooltipOnCondition>
        </div>
      )}
    </div>
  );
};
