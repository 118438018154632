import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useTheme } from '../../theme';

import styles from './switch.module.scss';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> & {
  onChange?: HandleChange;
  squared?: boolean;
  labels?: {
    on: string;
    off: string;
  };
};
export type Event = ChangeEvent<HTMLInputElement>;

export const Switch = ({
  id,
  checked = false,
  name,
  onChange,
  squared,
  labels,
  ...props
}: Props): React.JSX.Element => {
  const { theme } = useTheme();

  const handleChange = (event: Event) => {
    const {
      target: { name, checked }
    } = event;

    if (onChange) {
      onChange(name, checked);
    }
  };

  return (
    <div className={styles.container}>
      <input
        id={id}
        name={name}
        type='checkbox'
        onChange={handleChange}
        checked={checked}
        className={styles.input}
        {...props}
      />
      <label
        htmlFor={id}
        className={classnames(
          styles.label,
          { [styles.checked]: checked, [styles.squared]: squared, [styles.indicator]: !labels },
          styles[theme]
        )}
      >
        {name ?? 'toggle'}
      </label>
      {labels && (
        <span
          className={classnames(styles.knobs, { [styles['knobs-checked']]: checked })}
          data-off={labels.off}
          data-on={labels.on}
        />
      )}
    </div>
  );
};
