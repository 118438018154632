import React, { ElementType, PropsWithChildren, useState } from 'react';
import type { PolymorphicComponentProps } from 'react-polymorphic-box';
import { Box } from 'react-polymorphic-box';
import classnames from 'classnames';
import { Button } from '../button';
import { useTheme } from '../theme';
import { Icon } from '../icon';

import { SVGGlowFilter } from '../icon/filters/glow';
import styles from './segmented-button.module.scss';

type OwnProps = React.HTMLAttributes<HTMLButtonElement> & {
  active: boolean;
  first: boolean;
  last: boolean;
  applyFilter?: boolean;
};

type Props = PolymorphicComponentProps<ElementType, OwnProps>;

const defaultElement = Button;

//TODO: Rewrite segmented button to prevent passing new function on each render
export const Segment = ({
  active,
  children,
  icon,
  first,
  last,
  applyFilter = true,
  ...props
}: Props): React.JSX.Element => {
  if (icon && active) {
    return (
      <Icon
        name={icon}
        filter={applyFilter ? 'url(#glow)' : undefined}
        className={classnames(styles.item, styles.active, {
          [styles.first]: first && !last,
          [styles.last]: last && !first,
          [styles.only]: last && first
        })}
      >
        <SVGGlowFilter id='glow' />
      </Icon>
    );
  }

  return (
    <Box
      as={defaultElement}
      is='minor'
      className={classnames(styles.item, {
        [styles.active]: active,
        [styles.first]: first && !last,
        [styles.last]: last && !first,
        [styles.only]: last && first
      })}
      icon={icon}
      {...props}
    >
      {children}
    </Box>
  );
};

type SegmentedButtonProps = PropsWithChildren<{
  className?: string;
}>;

export const SegmentedButton = ({ children, className }: SegmentedButtonProps): React.JSX.Element => {
  const { theme } = useTheme();

  return <div className={classnames(styles.container, styles[theme], className)}>{children}</div>;
};

type UseSegmentedButton<T> = {
  active: T;
  setActive: React.Dispatch<T>;
  Container: typeof SegmentedButton;
  Segment: typeof Segment;
};

export const useSegmentedButton = <T,>(init: T | (() => T)): UseSegmentedButton<T> => {
  const [active, setActive] = useState<T>(init);

  return {
    active,
    setActive,
    Container: SegmentedButton,
    Segment
  };
};
