import React from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { SelectCell } from '~/components/_table/cells/select';
import { DATA_TYPE_SELECT_ITEMS } from '~/pages/datasets/attributes/constants';

const DataTypeSelectCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => (
  <SelectCell {...props} selectItems={DATA_TYPE_SELECT_ITEMS} headingLabel='Data type' />
);

export default DataTypeSelectCell;
