import * as yup from 'yup';
import { digitCheck, lowerCaseCheck, upperCaseCheck } from '~/helpers/regex';

export const emailSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

export const passwordSchemaSimplified = yup.object().shape({
  password: yup.string().min(8)
});

export const passwordSchema = yup.object().shape({
  password: yup.string().min(8).matches(upperCaseCheck).matches(lowerCaseCheck).matches(digitCheck)
});
