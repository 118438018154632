import { Column } from 'react-table';
import { PrimaryCell } from '~/components/_table/cells/primary';
import { Dataset } from '~/pages/datasets/types';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';

export const columns: Column<Dataset>[] = [
  {
    title: 'Name',
    Cell: PrimaryCell,
    accessor: 'name',
    to: DATASETS_ROUTES.root,
    actionTooltip: 'Edit Attributes',
    primary: true
  },
  {
    title: 'Description',
    accessor: 'description',
    width: Math.round((window.innerWidth - 334) * 0.5)
  },
  {
    title: '',
    accessor: 'blank',
    width: Math.round((window.innerWidth - 334) * 0.5)
  }
];
