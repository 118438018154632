import React from 'react';
import { ComponentsMap } from '@life-moments/lifehub-components';

import { LAYER_TYPES, PublicBlock } from '~/types/gists/page';
import Layer from '~/pages/pages/edit/components/preview-zone/components/block/components/layers/components/layer';

import styles from './styles.module.scss';

type Props = {
  block: PublicBlock;
  additionalProps: Record<string, unknown>;
};

const Layers = ({ block, additionalProps }: Props): React.JSX.Element => {
  const containerLayers = block.data.layers.filter(layer => layer.type === LAYER_TYPES.CONTAINER);
  const Component = ComponentsMap[block.type];

  return (
    <>
      {containerLayers.map(containerLayer => (
        <Component
          key={block.id}
          content={block.data.content}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          variant={block.data.variant}
          renderChildren={true}
          className={styles.image}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          additionalProps={additionalProps}
        >
          <Layer key={containerLayer.id} layers={block.data.layers} layer={containerLayer} block={block} />
        </Component>
      ))}
    </>
  );
};

export default Layers;
