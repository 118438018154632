import React, { PropsWithChildren } from 'react';
import Highlight from 'prism-react-renderer';
import Prism from 'prism-react-renderer/prism';
import { mapClassNameFromCSSModule } from '~/helpers/styles';

import styles from './snippet.module.scss';

type Props = PropsWithChildren<{
  code?: string;
  placeholder?: string;
}>;

export const Snippet = ({ code, placeholder, children }: Props): React.JSX.Element => {
  return (
    <Highlight Prism={Prism} code={(code || placeholder) ?? ''} language='javascript'>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        const offset = tokens.length.toString().length;

        return (
          <div className={styles.container}>
            <div className={styles.edit} style={{ paddingLeft: `${offset}em` }}>
              {children}
            </div>
            <pre className={mapClassNameFromCSSModule(className, styles)} style={style}>
              {tokens.map((line, i) => {
                const { key, className, style, ...lineProps } = getLineProps({ line, key: i });

                return (
                  <div
                    key={key}
                    data-number={i + 1}
                    style={{
                      ...style,
                      paddingLeft: `${offset}em`
                    }}
                    className={mapClassNameFromCSSModule(className, styles)}
                    {...lineProps}
                  >
                    <div>
                      {line.map((token, i) => {
                        const { key, className, ...tokenProps } = getTokenProps({ token, key: i });

                        return (
                          <span key={key} className={mapClassNameFromCSSModule(className, styles)} {...tokenProps} />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </pre>
          </div>
        );
      }}
    </Highlight>
  );
};
