import React from 'react';
import { CellProps, TableAction, TableActionTypes, TableDataLike } from 'react-table';
import { Confirm } from '~/components/_layout/popovers/confirm';

import styles from './actions-cell.module.scss';
import BaseAction from '~/components/_table/cells/actions/components/BaseAction';
import { Info } from '~/components/_layout/popovers/info';

const DEFAULT_MESSAGE = 'Are you sure you want to delete';

export const ActionsCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => {
  const { actions = [], cell } = props;

  const items = actions as TableAction<Data>[];

  const getItemName = (confirm: boolean | TableActionTypes<Data>['confirm']) => {
    const defaultValue = 'this row';

    if (typeof confirm === 'boolean') {
      return defaultValue;
    }

    if (confirm.origin) {
      return typeof confirm.origin === 'function' ? confirm.origin(props) : `"${cell.row.original[confirm.origin]}"`;
    }

    return defaultValue;
  };

  return (
    <div className={styles.container}>
      {items.map(
        ({
          id,
          icon,
          label,
          confirm = false,
          handler,
          loading,
          disabled,
          message = DEFAULT_MESSAGE,
          tooltip,
          forbid
        }) => {
          const isDisabled = disabled ? disabled(props) : false;
          const isLoading = loading ? loading(props) : false;

          if (!forbid && !confirm) {
            return (
              <div key={id} className={styles.action}>
                <BaseAction
                  tooltip={tooltip}
                  disabled={isDisabled}
                  loading={isLoading}
                  icon={icon}
                  onClick={() => handler(props)}
                >
                  {label}
                </BaseAction>
              </div>
            );
          }
          if (!forbid && confirm) {
            return (
              <div key={id} className={styles.action}>
                <Confirm
                  message={[
                    <span key='permanently-delete'>
                      {message} <strong>{getItemName(confirm)}</strong> ?
                    </span>,
                    'This cannot be undone...'
                  ]}
                  onConfirm={(setLoading, setReady) => handler(props, setLoading, setReady)}
                  cancelText={'Keep it'}
                  confirmText={'Delete it'}
                >
                  {toggle => (
                    <BaseAction
                      tooltip={tooltip}
                      disabled={isDisabled}
                      loading={isLoading}
                      icon={icon}
                      onClick={() => toggle(true)}
                    >
                      {label}
                    </BaseAction>
                  )}
                </Confirm>
              </div>
            );
          }

          if (forbid && confirm) {
            return (
              <div key={id} className={styles.action}>
                <Info message={forbid.infoMessage}>
                  {toggleForbid => (
                    <Confirm
                      message={[
                        <span key='permanently-delete'>
                          {message} <strong>{getItemName(confirm)}</strong> ?
                        </span>,
                        'This cannot be undone...'
                      ]}
                      onConfirm={(setLoading, setReady) =>
                        forbid.validator(props.row) ? toggleForbid(true) : handler(props, setLoading, setReady)
                      }
                      cancelText={'Keep it'}
                      confirmText={'Delete it'}
                    >
                      {toggleConfirm => (
                        <BaseAction
                          tooltip={tooltip}
                          disabled={isDisabled}
                          loading={isLoading}
                          icon={icon}
                          onClick={() => toggleConfirm(true)}
                        >
                          {label}
                        </BaseAction>
                      )}
                    </Confirm>
                  )}
                </Info>
              </div>
            );
          }

          return null;
        }
      )}
    </div>
  );
};
