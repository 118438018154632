import React from 'react';
import { Heading } from '~/components/_layout/typography/heading';
import { Label } from '~/components/_layout/typography/label';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { Button } from '~/components/button';
import { copyToClipboard } from '~/helpers/formatters';

import styles from './styles.module.scss';

const NOOP = () => void 0;

type Props = {
  conditionQuery: string;
  openConditionBuilder: () => void;
};

const ConditionPanel = ({ conditionQuery, openConditionBuilder }: Props) => {
  const onCopyToClipboard = e => {
    e.preventDefault();

    copyToClipboard(conditionQuery);
  };
  return (
    <div className={styles.container}>
      <Heading size='xs' className={styles.heading}>
        Define your Cohort
      </Heading>
      <div className={styles.body}>
        <Label size='xs' className={styles.label}>
          Condition
        </Label>
        {conditionQuery && (
          <a role='button' className={styles.link} onClick={onCopyToClipboard}>
            Copy code
          </a>
        )}
        <TextareaAutosize onClick={openConditionBuilder} onChange={NOOP} readOnly={true} value={conditionQuery} />
        <Button is='minor' fluid onClick={openConditionBuilder}>
          Edit condition
        </Button>
      </div>
    </div>
  );
};

export default ConditionPanel;
