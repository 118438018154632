import { Hooks, TableDataLike, TableInstance, UpdateCallback } from 'react-table';
import { useCallback } from 'react';

export type UseRefetchProps<Data extends TableDataLike> = {
  handleUpdate: UpdateCallback<Data>;
  refetchTable: () => Promise<void>;
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { handleUpdate, rows, state } = instance;

  const refetchTable = useCallback(async () => {
    if (handleUpdate) {
      await handleUpdate(state);
    } else {
      console.warn('[Table View] Table Re-fetching plugin is enabled but "handleUpdate" prop was not provided');
    }
  }, [handleUpdate, state]);

  Object.assign(instance, {
    refetchTable
  });

  rows.forEach(row => {
    Object.assign(row, {
      refetchTable
    });
  });
};

export const useTableRefetch = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.useInstance.push(useInstance);
};

useTableRefetch.pluginName = 'useTableRefetch';
