import { BlockVariant, COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { BlockLayer, LAYER_TYPES, PublicBlock } from '~/types/gists/page';

type Block = Omit<PublicBlock, 'data'> & {
  data: {
    content: string; // dangerousHTML
    variants: Array<BlockVariant>;
    variant?: BlockVariant;
    [key: string]: unknown;
  };
};

type LayersMapper = (block: Block) => Array<BlockLayer>;

export const generateLayerId = (blockId: string, blockName: string, layerIndex: number | string): string =>
  `${blockId}_${blockName}_${layerIndex}`;
export const parseLayerId = (layerId: string): { blockId: string; blockName: string; layerIndex: number | string } => {
  const results = layerId.split('_');

  return {
    blockId: results[0],
    blockName: results[1],
    layerIndex: results[2]
  };
};

export const changeLayerBlockId = (layerId: string, newBlockId: string, blockType: COMPONENT_TYPES): string => {
  const { layerIndex } = parseLayerId(layerId);

  return generateLayerId(newBlockId, blockType, layerIndex);
};

const getChildNodes = (content: string): NodeListOf<ChildNode> => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  return doc.body.childNodes;
};

const getChildLayers = (block: Block, childNodes: NodeListOf<ChildNode>): BlockLayer[] => {
  const childLayers: BlockLayer[] = [];

  const serializer = new XMLSerializer();

  childNodes.forEach((node, index) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.childNodes.length > 0) {
        Array.from(node.childNodes).forEach((child, childIndex) => {
          childLayers.push({
            type: LAYER_TYPES.CONTENT,
            id: generateLayerId(block.id, block.type, index + childIndex + 1),
            // Default name for inner layers
            name: `${block.name} inner`,
            content: serializer.serializeToString(child),
            children: []
          });
        });
      } else {
        childLayers.push({
          type: LAYER_TYPES.CONTENT,
          id: generateLayerId(block.id, block.type, index + 1),
          // Default name for inner layers
          name: `${block.name} inner`,
          content: serializer.serializeToString(node),
          children: []
        });
      }
    }
  });

  return childLayers;
};

export const mapTextLayers: LayersMapper = block => {
  const layers: BlockLayer[] = [];

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: '<p></p>',
    children: []
  });

  const childLayers = getChildLayers(block, getChildNodes(block.data.content));

  childLayers.forEach(childLayer => {
    layers[0].children.push(childLayer.id);
    childLayer.name = `${block.name} inner`;
  });

  layers.push(...childLayers);

  return layers;
};

export const mapHeadingLayers: LayersMapper = block => {
  const layers: BlockLayer[] = [];

  const childNodes = getChildNodes(block.data.content);

  const containerTag = childNodes.length ? childNodes[0].nodeName.toLowerCase() : 'h2';

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: `<${containerTag}></${containerTag}>`,
    children: []
  });

  const childLayers = getChildLayers(block, childNodes);

  childLayers.forEach(childLayer => {
    layers[0].children.push(childLayer.id);
    childLayer.name = `${block.name} inner`;
  });

  layers.push(...childLayers);

  return layers;
};

export const mapSpacerLayers: LayersMapper = block => {
  const layers: BlockLayer[] = [];

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: '<div></div>',
    children: []
  });

  return layers;
};

export const mapListLayers = (
  block: Omit<PublicBlock, 'data'> & {
    data: {
      content: string; // dangerousHTML
      variants: Array<BlockVariant>;
      variant?: BlockVariant;
      [key: string]: unknown;
    };
  }
): Array<BlockLayer> => {
  const layers: BlockLayer[] = [];

  const parser = new DOMParser();
  const doc = parser.parseFromString(block.data.content, 'text/html');

  const childNodes = doc.body.childNodes;
  const childLayers: BlockLayer[] = [];
  const serializer = new XMLSerializer();

  const containerTag = childNodes[0].nodeName.toLowerCase();

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: `<${containerTag}></${containerTag}>`,
    children: []
  });

  const contentId = generateLayerId(block.id, block.type, 1);

  layers[layers.length - 1].children.push(contentId);

  let content = '';
  childNodes[0].childNodes.forEach(child => {
    content += serializer.serializeToString(child);
  });

  layers.push({
    type: LAYER_TYPES.CONTENT,
    id: generateLayerId(block.id, block.type, 1),
    name: `${block.name} Inner`,
    content,
    children: []
  });

  layers.push(...childLayers);

  return layers;
};

export const mapImageLayers: LayersMapper = block => {
  const layers: BlockLayer[] = [];

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: '<figure></figure>',
    children: []
  });

  const childNodes = getChildNodes(block.data.content);

  const innerNode = childNodes[0].childNodes[0];
  const innerId = generateLayerId(block.id, block.type, 1);
  const innerTag = childNodes.length ? innerNode.nodeName.toLowerCase() : 'div';

  layers[0].children.push(innerId);
  layers.push({
    type: LAYER_TYPES.PRESENTATION,
    id: innerId,
    name: `${block.name} inner`,
    content: `<${innerTag}></${innerTag}>`,
    children: []
  });

  const serializer = new XMLSerializer();

  innerNode.childNodes.forEach(node => {
    const id = generateLayerId(block.id, block.type, layers.length);
    layers[1].children.push(id);
    if (node.nodeType === Node.ELEMENT_NODE) {
      layers.push({
        type: node.nodeName === 'IMG' || node.nodeName === 'A' ? LAYER_TYPES.STATIC : LAYER_TYPES.CONTENT,
        id,
        name: node.nodeName === 'FIGCAPTION' ? 'Caption' : 'File',
        content: serializer.serializeToString(node),
        children: []
      });
    }
  });

  return layers.map(layer => ({ ...layer, meta: { shouldHandleMountBehaviour: true } }));
};

export const mapQuoteLayers: LayersMapper = block => {
  const serializer = new XMLSerializer();

  const layers: BlockLayer[] = [];

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: generateLayerId(block.id, block.type, 0),
    name: block.name,
    content: '<figure></figure>',
    children: []
  });

  const childNodes = getChildNodes(block.data.content);

  const innerNode = childNodes[0].childNodes[0];
  const innerId = generateLayerId(block.id, block.type, 1);
  const innerTag = childNodes.length ? innerNode.nodeName.toLowerCase() : 'div';
  layers[0].children.push(innerId);
  layers.push({
    type: LAYER_TYPES.PRESENTATION,
    id: innerId,
    name: `${block.name} inner`,
    content: `<${innerTag}></${innerTag}>`,
    children: []
  });

  const containersQuantity = layers.length;
  innerNode.childNodes.forEach((node, index) => {
    const id = generateLayerId(block.id, block.type, index + containersQuantity);
    layers[1].children.push(id);

    if (node.nodeType === Node.ELEMENT_NODE) {
      layers.push({
        type: LAYER_TYPES.CONTENT,
        id,
        name: node.nodeName === 'FIGCAPTION' ? 'Caption' : 'Text',
        content: serializer.serializeToString(node),
        children: []
      });
    }
  });

  return layers;
};

export const mapMetaboxLayers: LayersMapper = block => {
  const layers: BlockLayer[] = [];
  const metaboxId = generateLayerId(block.id, block.type, 0);
  const innerId = generateLayerId(block.id, block.type, 1);
  const labelId = generateLayerId(block.id, block.type, 2);
  const dateId = generateLayerId(block.id, block.type, 3);

  layers.push({
    type: LAYER_TYPES.CONTAINER,
    id: metaboxId,
    name: block.name,
    content: '<div class="metabox"></div>',
    children: [innerId]
  });

  layers.push({
    type: LAYER_TYPES.PRESENTATION,
    id: innerId,
    name: `${block.name} inner`,
    content: '<div class="metabox-inner"></div>',
    children: [labelId, dateId]
  });

  layers.push({
    type: LAYER_TYPES.PRESENTATION,
    id: labelId,
    name: 'Label',
    content: '<span class="metabox-label"></span>',
    children: []
  });

  layers.push({
    type: LAYER_TYPES.PRESENTATION,
    id: dateId,
    name: 'Date',
    content: '<span class="metabox-date"></span>',
    children: []
  });

  return layers;
};

export const layersMapperByBlockType: Record<COMPONENT_TYPES, LayersMapper> = {
  [COMPONENT_TYPES.text]: mapTextLayers,
  [COMPONENT_TYPES.heading]: mapHeadingLayers,
  [COMPONENT_TYPES.spacer]: mapSpacerLayers,
  [COMPONENT_TYPES.image]: mapImageLayers,
  [COMPONENT_TYPES.bulletList]: mapListLayers,
  [COMPONENT_TYPES.numberList]: mapListLayers,
  [COMPONENT_TYPES.quote]: mapQuoteLayers,
  [COMPONENT_TYPES.metabox]: mapMetaboxLayers
};
