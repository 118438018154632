import React from 'react';
import FilePrimaryCellProvider from '~/components/_table/cells/file-primary/context';
import { FileObject } from '~/types/gists/file';
import { CellProps } from 'react-table';

export function withFilePrimaryCellContext(
  Component: React.ComponentType<Record<never, never>>
): React.ComponentType<CellProps<FileObject>> {
  const displayName = Component.displayName || Component.name || 'Component';

  const EnhancedComponent = (props: CellProps<FileObject>) => (
    <FilePrimaryCellProvider {...props}>
      <Component />
    </FilePrimaryCellProvider>
  );

  EnhancedComponent.displayName = `withFilePrimaryCellContext(${displayName})`;

  return EnhancedComponent;
}
