import { DiscardType, SetState } from '~/pages/tags/context/store/types';
import { FlatTag } from '~/types/gists/tag';

export enum ActionType {
  SetVirtualTags = 'SetVirtualTags',
  SetIsLoading = 'SetIsLoading',
  SetErrorMessage = 'SetErrorMessage',
  SetSelectedTagsById = 'SetSelectedTagsById',
  SetExpandedTagsByIds = 'SetExpandedTagsByIds',
  ToggleTagById = 'ToggleTagById',
  CreateVirtualTag = 'CreateVirtualTag',
  UpdateVirtualTagById = 'UpdateVirtualTagById',
  UpdateValidationErrors = 'UpdateValidationErrors',
  SetShouldValidate = 'SetShouldValidate',
  SaveTags = 'SaveTags',
  ReorderTag = 'ReorderTag',
  Reset = 'Reset',
  SetDiscardType = 'SetDiscardType',
  SetShouldShowDiscardChanges = 'SetShouldShowDiscardChanges',
  SetHasUnsavedChanges = 'SetHasUnsavedChanges',
  SetNextTagsIdsToSelect = 'SetNextTagsIdsToSelect'
}

export const setVirtualTags = (tags: Array<FlatTag>): SetState => ({
  type: ActionType.SetVirtualTags,
  payload: tags
});

export const setIsLoading = (isLoading: boolean): SetState => ({
  type: ActionType.SetIsLoading,
  payload: isLoading
});

export const setErrorMessage = (errorMessage: Array<string>): SetState => ({
  type: ActionType.SetErrorMessage,
  payload: errorMessage
});

export const setSelectedTagsById = (tagsIds: Array<number>): SetState => ({
  type: ActionType.SetSelectedTagsById,
  payload: tagsIds
});

export const toggleTagById = (tagId: number): SetState => ({
  type: ActionType.ToggleTagById,
  payload: { id: tagId }
});

export const setExpandedTagsByIds = (tagsIds: Array<number>): SetState => ({
  type: ActionType.SetExpandedTagsByIds,
  payload: {
    ids: tagsIds
  }
});

export const createVirtualTag = (newVirtualTag: FlatTag): SetState => ({
  type: ActionType.CreateVirtualTag,
  payload: newVirtualTag
});

export const updateVirtualTagById = (id: number, payload: Partial<FlatTag>): SetState => ({
  type: ActionType.UpdateVirtualTagById,
  payload: {
    id,
    data: payload
  }
});

export const updateValidationErrors = (validationErrors: Record<string, string>): SetState => ({
  type: ActionType.UpdateValidationErrors,
  payload: validationErrors
});

export const setShouldValidate = (shouldValidate: boolean): SetState => ({
  type: ActionType.SetShouldValidate,
  payload: shouldValidate
});

export const saveTags = (tags: FlatTag[]): SetState => ({
  type: ActionType.SaveTags,
  payload: { tags }
});

export const reorderTag = (draggableTagId: number, from: number, to: number): SetState => ({
  type: ActionType.ReorderTag,
  payload: {
    draggableTagId,
    from,
    to
  }
});

export const resetTags = (): SetState => ({
  type: ActionType.Reset
});

export const setDiscardType = (discardType: DiscardType): SetState => ({
  type: ActionType.SetDiscardType,
  payload: discardType
});

export const setShouldShowDiscardChanges = (shouldShowDiscardChanges: boolean): SetState => ({
  type: ActionType.SetShouldShowDiscardChanges,
  payload: shouldShowDiscardChanges
});

export const setHasUnsavedChanges = (hasUnsavedChanges: boolean): SetState => ({
  type: ActionType.SetHasUnsavedChanges,
  payload: hasUnsavedChanges
});

export const setNextTagsIdsToSelect = (nextTagsIdsToSelect: number[]): SetState => ({
  type: ActionType.SetNextTagsIdsToSelect,
  payload: nextTagsIdsToSelect
});
