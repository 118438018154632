import React, { ElementType, forwardRef } from 'react';
import type { PolymorphicComponentProps } from 'react-polymorphic-box';
import { Box } from 'react-polymorphic-box';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';

import styles from './bubble.module.scss';

type OwnProps = React.HTMLAttributes<HTMLDivElement>;

type Props = PolymorphicComponentProps<ElementType, OwnProps>;

const defaultElement = 'div';

export const BubbleComponent = (
  { children, className, ...props }: Props,
  ref?: typeof props.ref
): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <Box ref={ref} as={defaultElement} className={classnames(styles.container, styles[theme], className)} {...props}>
      {children}
    </Box>
  );
};

export const Bubble = forwardRef(BubbleComponent);
