import React from 'react';
import { useField } from 'formik';
import { useUniqId } from '~/hooks/use-uniq-id';
import { FormField } from '~/components/_form/components/field';
import { ControlledFormLabel as FormLabel, ExtraProps as LabelProps } from '~/components/_form/components/label';
import { Props as RadioProps, Radio } from '~/components/_inputs/radio';

import styles from './radio-field.module.scss';

type Props = RadioProps & {
  name: string;
  label: string;
  labelProps?: LabelProps;
};

export const RadioField = React.memo(({ label, labelProps, ...props }: Props): React.JSX.Element => {
  const id = useUniqId(props.name);
  const [field] = useField<string>(props.name);

  return (
    <FormField className={styles.container}>
      <FormLabel id={id} placement='right' size='s' {...labelProps} label={label}>
        <Radio id={id} {...field} {...props} />
      </FormLabel>
    </FormField>
  );
});
