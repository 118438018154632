import React, { memo } from 'react';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { VIEW_MODES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import CodeView from '~/pages/cohorts/edit/ConditionBuilder/components/CodeView';
import VisualView from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView';

const Body = (): React.JSX.Element => {
  const { viewMode } = useConditionBuilderContext();

  return viewMode === VIEW_MODES.CODE_VIEW_MODE ? <CodeView /> : <VisualView />;
};

export default memo(Body);
