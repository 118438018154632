import React from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { Confirm } from '~/components/_layout/popovers/confirm';
import { Switch } from '~/components/_inputs/switch';

import styles from './publish-cell.module.scss';

export const PublishCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => {
  const { row, column, handlePublish: handler } = props;

  const published = row.values[column.id] ?? false;
  const name = column.name ? (row.original[column.name] as string) : '';

  return (
    <div className={styles.container}>
      <Confirm
        message={
          <span>
            Are you sure you want to {published ? 'unpublish' : 'publish'} <strong>{name}</strong>
          </span>
        }
        onConfirm={(setLoading, setReady) => handler && handler(props, setLoading, setReady)}
      >
        {toggle => <Switch id={`publish-item-${row.id}`} checked={published} onClick={() => toggle(true)} readOnly />}
      </Confirm>
    </div>
  );
};
