import { SetState } from '~/components/_table/cells/file-primary/context/types';
import { FileObject } from '~/types/gists/file';

export enum ActionTypes {
  SetIsFileDetailsOpened = 'SetIsFileDetailsOpened',
  SetAltText = 'SetAltText',
  SetDescription = 'SetDescription'
}

export const setIsFileDetailsOpened = (isFileDetailsOpened: boolean): SetState => ({
  type: ActionTypes.SetIsFileDetailsOpened,
  payload: isFileDetailsOpened
});

export const setAltText = (altText: FileObject['altText']): SetState => ({
  type: ActionTypes.SetAltText,
  payload: altText
});

export const setDescription = (description: FileObject['description']): SetState => ({
  type: ActionTypes.SetDescription,
  payload: description
});
