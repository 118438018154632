import React, { useCallback } from 'react';
import { CustomError } from '~/helpers/common/custom-error';
import useDocumentTitle from '~/hooks/use-document-title';
import { useHistory } from 'react-router-dom';
import { OnboardingTemplate } from '~/templates/onboarding';
import { Form, Formik } from 'formik';
import { codeValidationSchema } from '~/pages/onboarding/mfa/schemas';
import { TextField } from '~/components/_form/fields/text';
import { ButtonField } from '~/components/_form/fields/button';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { api } from '~/api';

type FormikValues = {
  code: string;
};

const initialValues: FormikValues = {
  code: ''
};

const PhoneVerify = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  const history = useHistory();

  const onNewCode = useCallback(
    (
        setFieldError: (field: string, message: string) => void,
        setFieldTouched: (field: string, isTouched: boolean) => void
      ) =>
      async () => {
        setFieldTouched('code', true);

        try {
          const email = localStorage.getItem('email') as string;
          const password = localStorage.getItem('password') as string;

          if (email && password) {
            await api.onboarding.resendPhoneNumberVerify({
              email,
              password
            });

            history.push(ONBOARDING_ROUTES.newCodeSent);
          } else {
            history.push(ONBOARDING_ROUTES.signInEmail);
          }
        } catch (unknownError) {
          const error = new CustomError(unknownError);
          setFieldError('code', error.message);
        }
      },
    [history]
  );

  const onSubmit = useCallback(
    async ({ code }: FormikValues) => {
      try {
        const email = localStorage.getItem('email') as string;
        const password = localStorage.getItem('password') as string;

        if (email && password) {
          await api.onboarding.verifyPhoneNumber({ email, password, code });

          localStorage.removeItem('email');
          localStorage.removeItem('password');
          history.push(ONBOARDING_ROUTES.mfaSetupComplete);
        } else {
          history.push(ONBOARDING_ROUTES.signInEmail);
        }
      } catch {
        history.push(ONBOARDING_ROUTES.error);
      }
    },
    [history]
  );

  return (
    <OnboardingTemplate
      message={[
        'I’ve sent a security code to your phone number.',
        'Enter it here to complete your Multi-Factor Authentication setup.'
      ]}
      backButton
    >
      <Formik validationSchema={codeValidationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, dirty, isSubmitting, setFieldError, setFieldTouched }) => (
          <Form>
            <TextField name='code' label='Security code' />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
            <ButtonField is='minor' onClick={onNewCode(setFieldError, setFieldTouched)} type='button'>
              Send me another code
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};

export default PhoneVerify;
