export const pageNameToSlug = (pageName: string): string => {
  return (
    pageName
      // removes whitespace from both ends of a string
      .trim()
      // removes all not unauthorised characters
      .replace(/[^a-zA-Z0-9/ /]/g, '')
      // splits string to several pieces by whitespace
      .split(' ')
      // joins pieces with hyphen
      .join('-')
      // removes first, last and consecutive hyphens
      .replace(/(?<!-)(-)\1+(?!-)/g, '-')
  );
};
