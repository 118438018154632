import React, { useCallback } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { Switch } from '~/components/_inputs/switch';

import styles from './styles.module.scss';

export const SwitchCell = <Data extends TableDataLike>({ cell, row, column }: CellProps<Data>): React.JSX.Element => {
  const checked = row.values[column.id];
  const { editCell, disableEdit } = column;

  const onChange = useCallback(
    (_, newChecked) => (disableEdit ? void 0 : editCell(cell, newChecked)),
    [cell, disableEdit, editCell]
  );

  return (
    <div className={styles.container}>
      <Switch id={`publish-item-${row.id}`} checked={checked} onChange={onChange} readOnly />
    </div>
  );
};
