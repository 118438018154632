import React from 'react';
import TagsInteractionProvider from '../../context';
import InteractionBlockFlow from '~/templates/tags-interaction/shapes/block/interaction';
import { OriginalTag } from '~/types/gists/tag';
import { Options } from '@popperjs/core';

type Props = {
  initialAssignedTags: OriginalTag[];
  onChange: HandleChange;
  popoverOptions?: Partial<Options>;
};

const TagsInteractionBlock = ({
  initialAssignedTags = [],
  onChange,
  popoverOptions = {}
}: Props): React.JSX.Element => (
  <TagsInteractionProvider>
    <InteractionBlockFlow
      initialAssignedTags={initialAssignedTags}
      onChange={onChange}
      popoverOptions={popoverOptions}
    />
  </TagsInteractionProvider>
);

export default TagsInteractionBlock;
