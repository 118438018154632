import React, { memo, useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';

import { Sidebar as SidebarLayout } from '~/components/_layout/sidebar';
import { useSubscribe } from '~/components/slots';
import { useSidebarPanel } from '~/components/_layout/panels';
import { CustomError } from '~/helpers/common/custom-error';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import CollapseButtons from '~/pages/tags/components/Sidebar/components/CollapseButtons';
import { reorderTag, setErrorMessage, setSelectedTagsById, toggleTagById } from '~/pages/tags/context/store/actions';
import { selectTagsByIds } from '~/pages/tags/context/store/thunks';
import { TagsTreeDescendants, TagsTreeItem, TagsTreeLayout } from '~/pages/tags/components/TagsTree';

import { api } from '~/api';

import styles from './styles.module.scss';
import { FlatTag } from '~/types/gists/tag';

const Sidebar = (): React.JSX.Element => {
  const left = useSubscribe('left-sidebar');
  const { state, dispatch } = useTagsModuleContext();

  const { Panel } = useSidebarPanel();

  const onSelectTag = useCallback((ids: number[]) => dispatch(selectTagsByIds(ids)), [dispatch]);

  const onExpandTag = useCallback((id: number) => dispatch(toggleTagById(id)), [dispatch]);

  const rootTags = state.virtualTags.filter(tag => tag.parentId === null);

  const isDescendantSelected = useCallback(tagId => state.selectedTagsIds.includes(tagId), [state.selectedTagsIds]);
  const isDescendantExpanded = useCallback(tagId => state.expandedTagsIds.includes(tagId), [state.expandedTagsIds]);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const draggableTagId = Number(result.draggableId);
      // dropped outside or on the same position
      if (!result.destination || result.source.index === result.destination.index) {
        return;
      }

      // Getting the original tag to avoid saving any changes other than position.
      const originalDraggedTag = state.originalTags.find(originalTag => originalTag.id === draggableTagId) as FlatTag;

      dispatch(reorderTag(draggableTagId, result.source.index, result.destination.index));

      // Do not select draggable tag in case of selected tag has unsaved changes in order to ensure the proper behaviour of saving/discard unsaved changes logic.
      if (!state.hasUnsavedChanges) {
        dispatch(setSelectedTagsById([draggableTagId]));
      }

      try {
        await api.tags.updateTagById({
          ...originalDraggedTag,
          // positions on BE starts from 1
          position: result.destination.index + 1,
          // BE do not expect 'descendants' as a property
          descendants: undefined
        });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch(setErrorMessage([error.message]));
      }
    },
    [dispatch, state.hasUnsavedChanges, state.originalTags]
  );

  return (
    <aside className={styles.container}>
      <SidebarLayout ref={left}>
        <Panel title='Tag structure' className={styles.heading}>
          {state.originalTags.length ? <CollapseButtons /> : null}
          <TagsTreeLayout.RootWrapper>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'droppable'} type='root'>
                {(providedDroppable: DroppableProvided) => (
                  <div ref={providedDroppable.innerRef} {...providedDroppable.droppableProps}>
                    {rootTags.map((tag, index) => (
                      <Draggable
                        draggableId={tag.id.toString()}
                        index={index}
                        key={tag.id}
                        isDragDisabled={!state.originalTags.some(originalTag => originalTag.id === tag.id)}
                      >
                        {(providedDraggable: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            <TagsTreeLayout.RootItem>
                              <TagsTreeItem
                                tagId={tag.id}
                                onSelect={onSelectTag}
                                onExpand={onExpandTag}
                                isSelected={state.selectedTagsIds.some(selectedTagId => selectedTagId === tag.id)}
                                isExpanded={state.expandedTagsIds.includes(tag.id)}
                                multiSelect={true}
                                shouldRenderDNDIcon={true}
                                isDragging={draggableSnapshot.isDragging}
                              >
                                <TagsTreeDescendants
                                  onSelect={onSelectTag}
                                  onExpand={onExpandTag}
                                  parentId={tag.id}
                                  isSelected={isDescendantSelected}
                                  isExpanded={isDescendantExpanded}
                                  multiSelect={true}
                                  enableDND={true}
                                />
                              </TagsTreeItem>
                            </TagsTreeLayout.RootItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TagsTreeLayout.RootWrapper>
        </Panel>
      </SidebarLayout>
    </aside>
  );
};

export default memo(Sidebar);
