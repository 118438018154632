import React, { useCallback, useEffect, useState } from 'react';
import { useSidebarPanel } from '~/components/_layout/panels';

import styles from './styles.module.scss';
import { useSegmentedButton } from '~/components/segmented-button';
import { DISPLAY_CONDITION_RULES } from '~/pages/pages/edit/context/store/enums';
import { Page } from '~/types/gists/page';
import { FormLabel } from '~/components/_form/components/label';
import { Select, SelectCallback, SelectItem } from '~/components/_inputs/select';
import { Cohort } from '~/types/gists/cohort';
import { api } from '~/api';
import { ArrayShim } from '~/helpers/shims/array-shim';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getCohortId, getDisplayConditionRule } from '~/pages/pages/edit/context/store/selectors';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { setCohortId, setDisplayConditionRule } from '~/pages/pages/edit/context/store/actions';

type SegmentOption = { label: string; value: Page['displayConditionRule'] };

const conditionRules: SegmentOption[] = [
  { label: 'None', value: null },
  { label: 'Show to', value: DISPLAY_CONDITION_RULES.SHOW },
  { label: 'Hide from', value: DISPLAY_CONDITION_RULES.HIDE }
];

const mapValue = (id: Page['cohortId'], items: SelectItem<Cohort>[]): SelectItem<Cohort> | undefined => {
  const map = ArrayShim.normalize(items, 'id');

  const selected = id ? map[String(id)] : id;

  return (
    selected && {
      id: selected.id,
      value: selected,
      label: selected.label
    }
  );
};

const DisplayConditions = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const cohortId = usePageEditorSelector(getCohortId);
  const displayConditionRule = usePageEditorSelector(getDisplayConditionRule);

  const { active, setActive, Container, Segment } = useSegmentedButton<SegmentOption>(
    conditionRules.find(rule => rule.value === displayConditionRule) ?? conditionRules[0]
  );

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<SelectItem<Cohort>[]>([]);

  useEffect(() => {
    const fetchCohorts = async () => {
      const { items } = await api.cohorts.get<Cohort>({
        limit: 500,
        orderBy: 'name',
        orderDirection: 'ASC',
        filters: JSON.stringify({ [`cohort.isEnabled`]: 1 })
      });

      const data: SelectItem<Cohort>[] = items.map(value => {
        const { id, name } = value;

        return {
          id,
          value,
          label: name
        };
      });

      setItems(data);

      setLoading(false);
    };

    fetchCohorts();
  }, []);

  const onConditionRuleChange = useCallback(
    (conditionRule: SegmentOption) => () => {
      setActive(conditionRule);
      dispatch(setDisplayConditionRule(conditionRule.value));
    },
    [dispatch, setActive]
  );

  const onCohortChange = useCallback<SelectCallback<Cohort>>(
    () => item => dispatch(setCohortId(item.value.id)),
    [dispatch]
  );

  const { Panel, List, ListItem } = useSidebarPanel();

  return (
    <Panel title='Display conditions'>
      <List className={styles.container}>
        <ListItem withoutBorder>
          <FormLabel label='Rule' placement='top'>
            <Container>
              {conditionRules.map((rule, index) => (
                <Segment
                  key={rule.label}
                  onClick={onConditionRuleChange(rule)}
                  first={index === 0}
                  active={rule.label === active.label}
                  last={index === conditionRules.length - 1}
                >
                  {rule.label}
                </Segment>
              ))}
            </Container>
          </FormLabel>
        </ListItem>
        {(active.value === DISPLAY_CONDITION_RULES.SHOW || active.value === DISPLAY_CONDITION_RULES.HIDE) && (
          <ListItem withoutBorder>
            <FormLabel label='Cohort'>
              <Select<Cohort>
                name='select-cohort'
                items={items}
                placeholder={'Select a cohort...'}
                loading={loading}
                value={mapValue(cohortId, items)}
                onChange={onCohortChange}
                multiLine
              />
            </FormLabel>
          </ListItem>
        )}
      </List>
    </Panel>
  );
};

export default DisplayConditions;
