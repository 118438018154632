import React from 'react';

import { COMPONENT_TYPES, ImageVariants } from '@life-moments/lifehub-components';

import ImageBlockDefault from '~/assets/images/image_block_default.svg';

import Variant from '../../components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: ImageVariants[];
  content: string;
};

const ImageBlockVariants = ({ variants }: Props): React.JSX.Element => (
  <div className={styles.list}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        variant={variant}
        type={COMPONENT_TYPES.image}
        // Static content because BE block content do not have figcaption by default because on page editor we do not need figcaption by default.
        content={`<figure> <img src="${ImageBlockDefault}" alt="default alt text"> <figcaption>{imageCaption}</figcaption> </figure>`}
      />
    ))}
  </div>
);

export default ImageBlockVariants;
