import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useDocumentTitle from '~/hooks/use-document-title';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { Button } from '~/components/button';
import { useTheme } from '~/components/theme';

import styles from './styles.module.scss';
import { api } from '~/api';
import { ObjectShim } from '~/helpers/shims/object-shim';
import { useSelector } from '~/store/hooks';

const Insights = (): React.JSX.Element => {
  useDocumentTitle('Insights');

  const { affiliateDetails, env, affiliate } = useTheme();
  const containerRef = useRef(null);
  const history = useHistory();

  const isAppReady = useSelector(state => state.meta.isAppReady);
  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const redirectToHomePage = useCallback(
    () => history.push('/' + `?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, history]
  );

  useEffect(() => {
    const initDashboard = async () => {
      try {
        const { url } = await api.analytics.getAffiliateEmbedDashboardUrl();

        return embedDashboard({
          url,
          container: containerRef.current
        });
      } catch {
        return redirectToHomePage();
      }
    };

    if (isAppReady && !isMainMenuOpened) {
      if (!affiliateDetails || ObjectShim.isEmpty(affiliateDetails.embedDashboardParams)) {
        return redirectToHomePage();
      }

      initDashboard();
    }
  }, [affiliateDetails, history, isAppReady, isMainMenuOpened, redirectToHomePage]);

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Insights' />
        </Breadcrumb>
      </Slot>
      <Slot name='page-actions'>
        {affiliateDetails?.fullDashboardUrl ? (
          <Button icon='arrow-right' fluid onClick={() => window.open(affiliateDetails.fullDashboardUrl)}>
            View all insights
          </Button>
        ) : null}
      </Slot>
      {!isMainMenuOpened && <div className={styles.dashboard} ref={containerRef} />}
    </>
  );
};

export default Insights;
