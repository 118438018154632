import { BlockVariant, COMPONENT_TYPES, UTIL_COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { AffiliateInfo } from '~/types/gists/affiliates';
import { FileObject } from '~/types/gists/file';
import { EntityMetaData } from '~/types/gists/misc';
import { OriginalTag } from '~/types/gists/tag';
import { DISPLAY_CONDITION_RULES } from '~/pages/pages/edit/context/store/enums';
import { Cohort } from '~/types/gists/cohort';

export type ServiceBlock = {
  id: string;
  name: string;
  type: UTIL_COMPONENT_TYPES;
  data?: {
    [key: string]: unknown;
  };
};

export enum LAYER_TYPES {
  CONTAINER = 'container',
  CONTENT = 'content',
  STATIC = 'static',
  PRESENTATION = 'presentation'
}

export type LayerMeta = {
  shouldHandleMountBehaviour?: boolean;
};

export type BlockLayer = {
  type: LAYER_TYPES;
  id: string;
  name: string;
  content: string;
  children: Array<string>;
  meta?: LayerMeta;
};

export type PublicBlock = {
  id: string;
  name: string;
  type: COMPONENT_TYPES;
  data: {
    content: string; // dangerousHTML
    layers: Array<BlockLayer>;
    variants: Array<BlockVariant>;
    variant?: BlockVariant;
    fileKey?: FileObject['key'];
    [key: string]: unknown;
  };
};

export type BlockType = ServiceBlock | PublicBlock;
export type StructureFromAPI = Array<{
  id: string;
  name: string;
  type: UTIL_COMPONENT_TYPES;
  children: PublicBlock[];
  data?: {
    [key: string]: unknown;
  };
}>;

export type OverrodeAt = Date | string | undefined;

export type GoogleSEO = {
  title?: string;
  description?: string;
  canonical?: string;
  noindex?: boolean;
};

export type FacebookSEO = {
  og_title?: string;
  og_description?: string;
  og_image?: FileObject['key'];
};

export type TwitterSEO = {
  twitter_title?: string;
  twitter_description?: string;
  twitter_image?: FileObject['key'];
};

export type PageMetaData = {
  blocks: BlockType[];
  files: FileObject[];
  readTimeMinutes: number;
};

export type Page = EntityMetaData & {
  id: number;
  name: string;
  affiliateId: AffiliateInfo['id'];
  url: string;
  slug: string;
  data: {
    blocks: string[]; // names of blocks from structure except containers
    structure: StructureFromAPI;
  };
  isEnabled: boolean;
  tags: Array<OriginalTag>;
  seo: {
    google: GoogleSEO;
    facebook: FacebookSEO;
    twitter: TwitterSEO;
  };
  overrodeAt: OverrodeAt;
  title: string | null;
  excerpt: string | null;
  label: string | null;
  image: FileObject['key'] | null;
  cohortId: Cohort['id'] | null;
  displayConditionRule: DISPLAY_CONDITION_RULES | null;
  metadata: PageMetaData;
};
