export const LOADING_MESSAGE = ['Loading your Tags...'];
export const WELCOME_MESSAGE = [
  'Tags enable you to organise your content and assets. This makes things easy to find in LifeHub and structures how users see them on the product',
  'Get started by adding your first Tag'
];
export const ERROR_MESSAGE = ['Something went wrong.', 'Please, try again.'];

export const TAG_FORM_VALIDATION_KEYS = {
  NAME: 'name'
};
