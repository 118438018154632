import React, { useEffect } from 'react';
import useDocumentTitle from '~/hooks/use-document-title';
import { OnboardingTemplate } from '~/templates/onboarding';
import { useHistory } from 'react-router-dom';

const Error = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.goBack();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history]);

  return <OnboardingTemplate message='Sorry, that code doesn’t seem to work' />;
};

export default Error;
