import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setSelectedLayerId } from '~/pages/pages/edit/context/store/actions';
import { getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { BlockLayer } from '~/types/gists/page';
import Caret from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure/components/caret';
import { Paragraph } from '~/components/_layout/typography/paragraph';

import styles from './styles.module.scss';

type Props = {
  layers: Array<BlockLayer>;
  layer: BlockLayer;
  layerToParentsMap: Record<string, string[]>;
};

const Layer = ({ layer, layers, layerToParentsMap }: Props): React.JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(false);

  const children = layer.children.map(childId => layers.find(layer => layer.id === childId) as BlockLayer);

  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const isChildLayerSelected = useMemo(
    () => Boolean(layerToParentsMap[selectedLayerId]?.find(parentId => parentId === layer.id)),
    [layer.id, layerToParentsMap, selectedLayerId]
  );

  useEffect(() => {
    if (isChildLayerSelected) {
      setIsExpanded(true);
    }
  }, [isChildLayerSelected]);

  const dispatch = usePageEditorDispatch();
  const onClick = useCallback(() => {
    dispatch(setSelectedLayerId(layer.id));
  }, [dispatch, layer]);

  return (
    <div className={styles.container}>
      <div className={classnames(styles.item, { [styles.selected]: selectedLayerId === layer.id })} onClick={onClick}>
        {children.length ? <Caret onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} /> : null}
        <Paragraph size='s'>{layer.name}</Paragraph>
      </div>

      {isExpanded && (
        <div className={styles.child}>
          {children.map(child => (
            <Layer key={child.id} layers={layers} layer={child} layerToParentsMap={layerToParentsMap} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Layer;
