import React, { useMemo } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import classnames from 'classnames';
import { urlRegex } from '~/helpers/regex';
import { TextCell } from '~/components/_table/cells/text';
import { Button } from '~/components/button';

import styles from './link-cell.module.scss';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';

export const LinkCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => {
  const {
    column: { to, canEdit, isEditing, actionTooltip },
    row: { id: rowId },
    value
  } = props.cell;

  const isValid = useMemo(() => urlRegex.test(value), [value]);

  const onClick = () => window.open(value);

  return (
    <div className={classnames(styles.container, canEdit ? styles.caret : styles.arrow)}>
      <TextCell {...props} />
      {isValid && to && !isEditing(rowId) && (
        <div className={styles.button}>
          <TooltipOnCondition title={actionTooltip} condition={!!actionTooltip} delay={100}>
            <Button onClick={onClick} icon='arrow-right' />
          </TooltipOnCondition>
        </div>
      )}
    </div>
  );
};
