import { useCallback, useEffect, useState } from 'react';
import { EditCallback, PublishCallback, TableAutoResetProps, TableDataLike } from 'react-table';

const getState = (state = false) => {
  return {
    autoResetHiddenColumns: state,
    autoResetExpanded: state,
    autoResetFilters: state,
    autoResetGlobalFilter: state,
    autoResetGroupBy: state,
    autoResetPage: state,
    autoResetSelectedRows: state,
    autoResetRowState: state,
    autoResetSortBy: state
  };
};

export const useEditableData = <Data extends TableDataLike>(
  data: Data[],
  onEdit: EditCallback<Data>,
  onPublish: PublishCallback<Data>
): [EditCallback<Data>, PublishCallback<Data>, TableAutoResetProps] => {
  const [autoReset, setAutoReset] = useState(getState(true));

  const handleEdit = useCallback<EditCallback<Data>>(
    async (...args) => {
      if (onEdit) {
        setAutoReset(getState(false));
        await onEdit(...args);
      }
    },
    [onEdit]
  );

  const handlePublish = useCallback<PublishCallback<Data>>(
    async (...args) => {
      if (onPublish) {
        setAutoReset(getState(false));
        await onPublish(...args);
      }
    },
    [onPublish]
  );

  useEffect(() => {
    setAutoReset(getState(true));
  }, [data]);

  return [handleEdit, handlePublish, autoReset];
};
