import React from 'react';
import { SEO_SOCIALS } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tab-switcher';
import { FacebookTab } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tabs/facebook-tab';
import { GoogleTab } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tabs/google-tab';
import { TwitterTab } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/seo/components/tabs/twitter-tab';

type Props = {
  selectedTab: SEO_SOCIALS;
};

export const SeoContent = React.memo(({ selectedTab }: Pick<Props, 'selectedTab'>): React.JSX.Element => {
  switch (selectedTab) {
    case SEO_SOCIALS.FACEBOOK:
      return <FacebookTab />;
    case SEO_SOCIALS.TWITTER:
      return <TwitterTab />;
    case SEO_SOCIALS.GOOGLE:
    default:
      return <GoogleTab />;
  }
});
