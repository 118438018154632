import { get, post, put, remove } from '~/api/utils';
import { FlatTag, OriginalTag } from '~/types/gists/tag';

type DeleteTagParams = {
  ids: string;
};

const flatTagsTree = (flatResult: FlatTag[], originalTag: OriginalTag) => {
  const tag = {
    name: originalTag.name,
    parentId: originalTag.parentId,
    description: originalTag.description,
    subHeading: originalTag.subHeading,
    id: originalTag.id,
    descendants: originalTag.children?.map(descendant => descendant.id) ?? [],
    position: originalTag.position
  };

  if (originalTag.children?.length) {
    return [...flatResult, tag, ...originalTag.children.reduce(flatTagsTree, [])];
  }

  return [...flatResult, tag];
};

const getTagsFlatTree = async (): Promise<Array<FlatTag>> => {
  const tagsResponse = await get<Array<OriginalTag>, never>('/tags/tree');
  return tagsResponse.reduce<Array<FlatTag>>(flatTagsTree, []);
};
const getOriginalTagsTree = async (): Promise<Array<OriginalTag>> => await get('/tags/tree');
const updateTagById = async <T extends HasId>(params: T): Promise<T> => await put<T, T>(`/tags/${params.id}`, params);
const createTag = async <T>(params: T): Promise<T> => await post<T, T>('/tags', params);
const deleteTagsByIds = async <T extends unknown>(params: DeleteTagParams): Promise<T> =>
  await remove<T, DeleteTagParams>('/tags', params);

export const tags = {
  getTagsFlatTree,
  updateTagById,
  createTag,
  deleteTagsByIds,
  getOriginalTagsTree
};
