import React from 'react';

import { Button } from '~/components/button';
import { Icon } from '~/components/icon';

import styles from '../styles.module.scss';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

const AddRuleset = (): React.JSX.Element => {
  const { addRuleset } = useConditionBuilderContext();

  return (
    <Button is='minor' className={styles['add-ruleset']} onClick={() => addRuleset()}>
      Add a ruleset
      <Icon name='plus' />
    </Button>
  );
};

export default AddRuleset;
