import React from 'react';
import classnames from 'classnames';
import { Icon } from '~/components/icon';

import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
  isExpanded: boolean;
};

const Caret = ({ onClick, isExpanded }: Props): React.JSX.Element => (
  <div onClick={onClick} className={classnames(styles['caret-wrapper'], { [styles.expanded]: isExpanded })}>
    <Icon name='arrow-triangle-right' className={styles.caret} />
  </div>
);

export default Caret;
