import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';
import { OwnProps as TextProps, TextNode } from '../text';

import styles from './label.module.scss';

export type Sizes = 'l' | 'm' | 's' | 'xs';

export type Props = {
  size?: Sizes;
} & TextProps;

export const LabelComponent = (
  { size, children, className, ...props }: Props,
  ref?: Ref<HTMLSpanElement>
): React.JSX.Element => {
  return (
    <TextNode
      ref={ref}
      className={classnames(styles.container, { [styles[`size-${size}`]]: size }, className)}
      {...props}
    >
      {children}
    </TextNode>
  );
};

export const Label = forwardRef<HTMLSpanElement, Props>(LabelComponent);
