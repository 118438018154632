import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { withEditTemplate } from '~/templates/edit/with-edit-template';
import { EditTemplateState } from '~/templates/edit/use-editable-template';
import useDocumentTitle from '~/hooks/use-document-title';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';
import { EditTemplate } from '~/templates/edit';
import InputField from '~/pages/datasets/attributes/edit/components/InputField';
import HistoricalData from '~/pages/datasets/attributes/edit/components/HistoricalData';
import DataType from '~/pages/datasets/attributes/edit/components/DataType';
import { SaveAction } from '~/components/_layout/page-actions/save-action';

import styles from './styles.module.scss';
import { PublishPanel } from '~/components/_layout/panels/publish';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';
import { DeleteCurrentAction } from '~/components/_layout/page-actions/delete-current-action';
import { DuplicateCurrentAction } from '~/components/_layout/page-actions/duplicate-current-action';
import ValidationData from '~/pages/datasets/attributes/edit/components/ValidationData';
import { TagsInteractionBlock } from '~/templates/tags-interaction';
import { Attribute } from '~/types/gists/attribute';

const newAttribute: Partial<Attribute> = {
  displayName: 'New Object'
};

const EditAttribute = (props: EditTemplateState<Attribute>): React.JSX.Element => {
  const { item, change, isNew, add, edit, error, removeCurrent, isTouched, cloneCurrent } = props;

  const history = useHistory();
  const { search } = useLocation();

  useDocumentTitle(`${item.displayName} - Attribute`);

  const handleChange = useCallback<HandleChange>((name, value) => change(name, value), [change]);

  const handleSave = useCallback(async () => {
    const { name, prefix, type, formula, isSaveHistoricalData } = item;

    const response = await add({
      name,
      prefix,
      type,
      formula,
      isSaveHistoricalData
    });

    if (response) {
      history.replace(`/datasets/attributes/${response.id}${search}`);
    }
  }, [add, history, item, search]);

  const { CoachPopover: Error, ...errorProps } = useCoachPopover({ disabled: !error });

  const handleUpdate = useCallback(async () => {
    await edit();
  }, [edit]);

  const handleRemove = useCallback(async () => {
    return await removeCurrent();
  }, [removeCurrent]);

  const handleClone = useCallback(async () => await cloneCurrent(), [cloneCurrent]);

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Datasets' to={DATASETS_ROUTES.root} />
          <Breadcrumb.Item label='Attributes' to={DATASETS_ROUTES.attributes.table} />
          <Breadcrumb.Item label={item.displayName} truncated />
        </Breadcrumb>
      </Slot>
      <EditTemplate
        icon='datasets'
        title={item.displayName}
        description={item.description}
        onTitleChange={event => handleChange('displayName', event.target.value)}
        onDescriptionChange={event => handleChange('description', event.target.value)}
      >
        <Slot name='page-actions'>
          {!!item.id && (
            <>
              <DuplicateCurrentAction
                rootRoute={DATASETS_ROUTES.attributes.table}
                isUnsavedChanges={isTouched}
                onClone={handleClone}
              />
              <DeleteCurrentAction
                name={item.displayName}
                rootRoute={DATASETS_ROUTES.attributes.table}
                onConfirm={handleRemove}
              />
            </>
          )}
          <SaveAction unsaved={isNew} onSave={handleSave} onUpdate={handleUpdate} />
        </Slot>
        <div className={styles.container}>
          <InputField onChange={handleChange} value={item.name} name='name' label='System Name' />
          <InputField onChange={handleChange} value={item.prefix} name='prefix' label='Group' />
          <DataType onChange={handleChange} value={item.type} />
          <InputField onChange={handleChange} value={item.formula} name='formula' label='Formula' />
          <ValidationData onChange={handleChange} value={item.validationData} />
          <HistoricalData onChange={handleChange} checked={item.isSaveHistoricalData} />
          <TagsInteractionBlock initialAssignedTags={item.tags} onChange={handleChange} />
        </div>
        <Slot name='right-sidebar'>
          {!isNew ? (
            <PublishPanel
              name={item.displayName}
              createdAt={item.createdAt}
              updatedAt={item.updatedAt}
              createdBy={item.createdBy}
              updatedBy={item.updatedBy}
            />
          ) : null}
        </Slot>
      </EditTemplate>
      <Error message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </Error>
    </>
  );
};

export default withEditTemplate({
  gist: 'attributes',
  defaultItem: newAttribute,
  loadingMessage: 'Loading your attribute...'
})(EditAttribute);
