import React, { useCallback, useEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getPreviewWidth, getPreviewZoom, getStructure } from '~/pages/pages/edit/context/store/selectors';
import Container from '~/pages/pages/edit/components/preview-zone/components/container';

import styles from './styles.module.scss';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { CANVAS_DROPPABLE_ID } from '~/pages/pages/edit/components/preview-zone/constants';
import { onCloseRichEditor } from '~/pages/pages/edit/components/preview-zone/thunks';

const PreviewZone = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const canvasRef = useRef<HTMLDivElement>(null);
  const outerCanvasRef = useRef<HTMLDivElement>(null);

  const width = usePageEditorSelector(getPreviewWidth);
  const zoom = usePageEditorSelector(getPreviewZoom);
  const containers = usePageEditorSelector(getStructure);

  const handleClickOutside = useCallback<(event: MouseEvent | TouchEvent) => void>(
    ({ target }) => {
      if (outerCanvasRef.current?.contains(target as Node) && !canvasRef.current?.contains(target as Node)) {
        dispatch(onCloseRichEditor());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={styles.container} ref={outerCanvasRef}>
      <Droppable droppableId={CANVAS_DROPPABLE_ID} type={'block'}>
        {provided => (
          <div
            className={styles.canvas}
            style={{
              width: `${width}px`,
              zoom: `${zoom}%`
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div ref={canvasRef}>
              {Object.keys(containers).map(containerId => (
                <Container containerId={containerId} key={containerId} />
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default PreviewZone;
