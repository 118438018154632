import { CellProps, Column, ColumnInstance, Hooks, Meta, TableDataLike } from 'react-table';
import { PublishCell } from '~/components/_table/cells/publish';

export type PublishHandler<Data extends TableDataLike, HandlerParams extends (...args: any) => any = never> = {
  (cell: CellProps<Data>, ...rest: Parameters<HandlerParams>): void | Promise<void>;
};

export type PublishOptions<Data extends TableDataLike, HandlerParams extends (...args: any) => any = never> = {
  id: string;
  handler: PublishHandler<Data, HandlerParams>;
  disabled?: (cell: CellProps<Data>) => boolean;
};

export type UsePublishOptions<Data extends TableDataLike> = Partial<{
  publishColumn: PublishOptions<Data>;
}>;

const useAllColumns = <Data extends TableDataLike>(
  allColumns: ColumnInstance<Data>[],
  { instance: { publishColumn } }: Meta<Data>
): Column<Data>[] => {
  return [
    ...allColumns,
    ...(publishColumn
      ? [
          {
            id: publishColumn.id,
            accessor: publishColumn.id,
            disableResizing: publishColumn.disableResizing ?? true,
            disableGroupBy: publishColumn.disableGroupBy ?? true,
            disableSortBy: publishColumn.disableSortBy ?? false,
            disableEdit: publishColumn.disableEdit ?? true,
            disableFilters: publishColumn.disableFilters ?? true,
            disableGlobalFilter: publishColumn.disableGlobalFilter ?? true,
            title: publishColumn.title ?? 'Publish',
            width: publishColumn.width ?? 120,
            Cell: PublishCell,
            sticky: 'right'
          }
        ]
      : [])
  ];
};

export const usePublish = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.allColumns.push(useAllColumns);
};

usePublish.pluginName = 'usePublish';
