import React from 'react';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import useDownload from '~/hooks/use-download';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';

const Download = () => {
  const { cellProps } = useFilePrimaryCellContext();
  const {
    row: {
      original: { fullUrl }
    }
  } = cellProps;
  const { ErrorMessage, saveAs } = useDownload();

  return (
    <>
      <ErrorMessage />
      <TooltipOnHover title='Download' delay={100}>
        <Button icon='save' is={'minor'} onClick={() => saveAs([fullUrl])} />
      </TooltipOnHover>
    </>
  );
};

export default Download;
