import React, { useCallback } from 'react';

import { Portal } from '~/components/portal';
import { Popover, PopoverProps, usePopover } from '~/components/popover';
import CreateNewTagForm from '~/templates/tags-interaction/components/CreateNewTagForm';
import { Options } from '@popperjs/core';

import { offset } from '~/helpers/ui-kit/popper/offset';
import { sameWidth } from '~/helpers/ui-kit/popper/same-width';
import { maxHeight } from '~/helpers/ui-kit/popper/max-size';

import styles from './styles.module.scss';
import { OriginalTag } from '~/types/gists/tag';

type Props = {
  onCreateNewTag: (newTag: OriginalTag) => Promise<void>;
  children: (toggle: PopoverProps['toggle']) => React.ReactNode;
  popoverOptions?: Partial<Options>;
};

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => [14, -reference.height + 45]
  }
};

const POPOVER: Partial<Options> = {
  placement: 'bottom',
  modifiers: [topOffset, sameWidth, ...maxHeight]
};

const CreationFlow = ({ onCreateNewTag, children, popoverOptions = {} }: Props): React.JSX.Element => {
  const { onPopoverRef, onTriggerRef, toggle, show } = usePopover(false, { ...POPOVER, ...popoverOptions });

  const createNewTag = useCallback(
    async (newTag: OriginalTag) => {
      await onCreateNewTag(newTag);
      toggle(false);
    },
    [onCreateNewTag, toggle]
  );

  const onClickOutside = useCallback(() => toggle(false), [toggle]);

  return (
    <>
      <div ref={onTriggerRef} className={styles.container}>
        {children(toggle)}
      </div>
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
          {show && (
            <Popover className={styles.popover} onClickOutside={onClickOutside}>
              <CreateNewTagForm onCreateNewTag={createNewTag} />
            </Popover>
          )}
        </div>
      </Portal>
    </>
  );
};

export default CreationFlow;
