import { produce } from 'immer';

import { GetTableItemsResponse, TableReducerState } from '~/components/_table/types';
import { DEFAULT_ERROR_MESSAGE } from '~/helpers/common/custom-error';
import { ArrayShim } from '~/helpers/shims/array-shim';
import { RemovePayload, SetIsProcessingByIdPayload } from '~/components/_table/hooks/use-table-fetch';

export type Action<T> =
  | { type: 'request' }
  | { type: 'clear' }
  | { type: 'get'; payload: GetTableItemsResponse<T> }
  | { type: 'edit'; payload: T }
  | { type: 'failure'; error: Error }
  | { type: 'setLoading'; payload: boolean }
  | { type: 'setIsProcessingById'; payload: SetIsProcessingByIdPayload }
  | { type: 'remove'; payload: RemovePayload }
  | { type: 'clone'; payload: T };

export const reducer = <T extends HasId>(state: TableReducerState<T>, action: Action<T>): TableReducerState<T> => {
  switch (action.type) {
    case 'request': {
      return { ...state, loading: true, error: undefined };
    }
    case 'clear': {
      return { ...state, error: undefined };
    }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload
      };
    }
    case 'get': {
      return {
        ...state,
        count: action.payload.count,
        items: action.payload.items,
        normalizedItems: ArrayShim.normalize(action.payload.items, 'id'),
        isEmpty: action.payload.count < 1,
        loading: false
      };
    }
    case 'edit': {
      const newItems = produce(state.items, draft => {
        const index = draft.findIndex(item => item.id === action.payload.id);

        if (index !== -1) {
          draft[index] = { ...draft[index], ...action.payload };
        }
      });

      return {
        ...state,
        items: newItems,
        normalizedItems: ArrayShim.normalize(newItems, 'id')
      };
    }
    case 'failure': {
      return { ...state, loading: false, error: action.error.message ?? DEFAULT_ERROR_MESSAGE };
    }
    case 'setIsProcessingById': {
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id
            ? {
                ...item,
                isProcessing: action.payload.isProcessing
              }
            : item
        )
      };
    }
    case 'remove': {
      const newItems = state.items.filter(item => !action.payload.ids.includes(item.id));

      return {
        ...state,
        items: newItems,
        count: state.count - (state.items.length - newItems.length)
      };
    }
    case 'clone': {
      return {
        ...state,
        items: [action.payload, ...state.items],
        count: ++state.count
      };
    }
    default: {
      return state;
    }
  }
};
