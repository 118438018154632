import React from 'react';

import 'react-phone-number-input/style.css';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import { Value } from '~/components/_layout/typography/value';
import { Input } from '~/components/_inputs/input';

type Props = {
  name: string;
  value: string;
  onChange: (value: string) => void;
};

const PhoneInput = ({ onChange, value, name }: Props): React.JSX.Element => {
  return (
    <Value>
      <ReactPhoneNumberInput
        withCountryCallingCode
        placeholder='+44 0000 000000'
        value={value}
        onChange={onChange}
        inputComponent={Input as any}
        name={name}
      />
    </Value>
  );
};

export default PhoneInput;
