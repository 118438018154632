import React, { useCallback } from 'react';

import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { duplicateBlock } from '~/pages/pages/edit/context/store/actions';
import { Button } from '~/components/button';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

const Duplicate = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const onDuplicate = useCallback(() => {
    const { blockId } = parseLayerId(selectedLayerId);
    dispatch(duplicateBlock(blockId));
  }, [dispatch, selectedLayerId]);

  return <Button is={'minor'} icon={'duplicate'} onClick={onDuplicate} />;
};

export default Duplicate;
