import React from 'react';

import { IMAGE_DEFAULT_VARIANT, ImageVariants } from '@life-moments/lifehub-components';

import { getStructureItemLayers, StructureItemLayer } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import { Block } from '~/types/gists/Block';

import Variant from '../../components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: ImageVariants[];
  blockName: Block['name'];
};

const content: StructureItemLayer[] = [
  {
    name: 'Inner',
    childrenLayers: [{ name: 'File' }, { name: 'Caption' }]
  }
];

const ImageBlockVariants = ({ variants, blockName }: Props): React.JSX.Element => (
  <div className={styles.container}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        name={variant.displayName}
        isDefault={variant.name === IMAGE_DEFAULT_VARIANT.name}
        content={getStructureItemLayers(blockName, content)}
      />
    ))}
  </div>
);

export default ImageBlockVariants;
