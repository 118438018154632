import React, { useCallback } from 'react';

import { Heading } from '~/components/_layout/typography/heading';
import Filter from '~/templates/tags-interaction/components/Filter';
import SelectedTags from '~/templates/tags-interaction/components/SelectedTags';
import TagsTree from '~/templates/tags-interaction/components/TagsTree';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import useTagsSelector from '~/templates/tags-interaction/context/hooks/useTagsSelector';
import {
  getFilter,
  getFilteredTags,
  getSelectedTags,
  isPerfectMatchExist as isPerfectMatchExistSelector
} from '~/templates/tags-interaction/context/store/selectors';
import {
  enableCreationMode,
  selectTag,
  setFilter,
  setNewTagName
} from '~/templates/tags-interaction/context/store/actions';
import { Icon } from '~/components/icon';
import { Label } from '~/components/_layout/typography/label';

import styles from './styles.module.scss';
import { OriginalTag } from '~/types/gists/tag';

type Props = {
  disabledCreationMode?: boolean;
};

const TagsAssignFlow = ({ disabledCreationMode }: Props): React.JSX.Element => {
  const { dispatch } = useTagsInteractionContext();

  const selectedTags = useTagsSelector(getSelectedTags);
  const filter = useTagsSelector(getFilter);
  const filteredTags = useTagsSelector(getFilteredTags);
  const isPerfectMatchExist = useTagsSelector(isPerfectMatchExistSelector);

  const goToCreationMode = () => {
    dispatch(enableCreationMode());
    dispatch(setNewTagName(filter));
  };

  const onSelectTag = useCallback((selectedTag: OriginalTag) => dispatch(selectTag(selectedTag)), [dispatch]);
  const onChangeFilter = useCallback((newFilter: string) => dispatch(setFilter(newFilter)), [dispatch]);
  const isNodeSelected = useCallback(
    (node: OriginalTag) => selectedTags.some(selectedTag => selectedTag.id === node.id),
    [selectedTags]
  );

  return (
    <>
      <Heading size='xs' className={styles.heading}>
        Tags
      </Heading>
      <Filter onChange={onChangeFilter} value={filter}>
        {!isPerfectMatchExist && !disabledCreationMode && (
          <div className={styles.label} onClick={() => setTimeout(goToCreationMode, 0)}>
            <Icon name='plus' /> <Label size='s'>{filter}</Label>
          </div>
        )}
      </Filter>
      <SelectedTags />
      <TagsTree data={filteredTags} onSelectTag={onSelectTag} filterValue={filter} isTagSelected={isNodeSelected} />
    </>
  );
};

export default TagsAssignFlow;
