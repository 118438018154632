import React, { useCallback } from 'react';

import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import useDownload from '~/hooks/use-download';
import { useTheme } from '~/components/theme';
import { getCurrentDateTime } from '~/helpers/common/date';
import useTableInstanceContext from '~/components/_table/context/use-table-instance-context';

const DownloadFiles = (): React.JSX.Element | null => {
  const { affiliate } = useTheme();

  const { saveAsZip, ErrorMessage } = useDownload();
  const instance = useTableInstanceContext();

  const onClick = useCallback(async () => {
    await saveAsZip(
      instance.selectedFlatRows.map(({ original: { fullUrl } }) => fullUrl),
      `${affiliate}_files_${getCurrentDateTime()}`
    );
  }, [affiliate, instance.selectedFlatRows, saveAsZip]);

  if (!instance.selectedFlatRows.length) {
    return null;
  }

  return (
    <>
      <ErrorMessage />
      <TopBarTool>
        <TooltipOnHover title='Download' delay={100}>
          <Button icon='save' is='minor' onClick={onClick} />
        </TooltipOnHover>
      </TopBarTool>
    </>
  );
};

export default DownloadFiles;
