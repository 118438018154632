import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ThemeService } from '~/services/theme-service';
import { AffiliateInfo } from '~/types/gists/affiliates';

import { ActionType, initialState, reducer, State } from './reducer';

type SetTheme = (theme: Theme) => void;
type SetAffiliate = (affiliate: AffiliateKey, env: Environment) => void;
type SetAffiliateDetails = (affiliate: AffiliateInfo) => void;

type ContextProps = State & {
  setTheme: SetTheme;
  setAffiliate: SetAffiliate;
  setAffiliateDetails: SetAffiliateDetails;
};

const Context = createContext<ContextProps>({
  ...initialState(),
  setTheme: () => void 0,
  setAffiliate: () => void 0,
  setAffiliateDetails: () => void 0
});

type Props = PropsWithChildren<Record<never, never>>;

export const Theme = ({ children }: Props): React.JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState());

  const setTheme = useCallback<SetTheme>(theme => {
    dispatch({ type: ActionType.SetState, payload: { theme } });
  }, []);

  const history = useHistory();
  const location = useLocation();

  const setAffiliate = useCallback<SetAffiliate>(
    (affiliate, env) => {
      const searchParams = new URLSearchParams(location.search);

      searchParams.set('affiliate', affiliate);
      searchParams.set('env', env);

      const omitParams = ['affiliate', 'env', 'filters'];

      Object.keys(searchParams).forEach(param => {
        const value = searchParams[param];

        if (!omitParams.includes(param) && typeof value === 'string') {
          searchParams.set(param, value);
        }
      });

      history.push({ search: searchParams.toString() });

      dispatch({ type: ActionType.SetState, payload: { affiliate, env } });
    },
    [history, location.search]
  );

  const setAffiliateDetails = useCallback<SetAffiliateDetails>(affiliateDetails => {
    dispatch({ type: ActionType.SetState, payload: { affiliateDetails } });
  }, []);

  useEffect(() => {
    ['theme', 'affiliate', 'env'].forEach(key => {
      document.documentElement.dataset[key] = state[key] ?? '';
    });
  }, [state]);

  useEffect(() => {
    const unsubscribe = ThemeService.subscribe(setTheme);

    return () => {
      unsubscribe();
    };
  }, [setTheme]);

  const sharedValue = useMemo(
    () => ({
      theme: state.theme,
      affiliate: state.affiliate,
      env: state.env,
      affiliateDetails: state.affiliateDetails,
      setTheme,
      setAffiliate,
      setAffiliateDetails
    }),
    [setAffiliate, setAffiliateDetails, setTheme, state.affiliate, state.affiliateDetails, state.env, state.theme]
  );

  return <Context.Provider value={sharedValue}>{children}</Context.Provider>;
};

export const useTheme = (): ContextProps => useContext(Context);
