import React, { useLayoutEffect } from 'react';
import { usePopper } from '~/hooks/use-popper';
import { useMergeRefs } from '~/hooks/use-merge-refs';
import { useSelector } from '~/store/hooks';
import { LifeHubLogo } from '~/components/_layout/logo';
import { useSubscribe } from '~/components/slots';

import styles from './coach.module.scss';

export const Coach = (): React.JSX.Element => {
  const active = useSelector(state => state.coach.active);
  const key = useSelector(state => state.coach.key);

  const subscribe = useSubscribe('coach');

  const { onPopoverRef, onTriggerRef, instance } = usePopper({ strategy: 'absolute', placement: 'bottom' }, active);

  useLayoutEffect(() => {
    if (instance) {
      instance.update();
    }
  }, [key, instance]);

  return (
    <div className={styles.container}>
      <div ref={onTriggerRef}>
        <LifeHubLogo animate={active} multiColoured />
      </div>
      <div ref={useMergeRefs(subscribe, onPopoverRef)} />
    </div>
  );
};
