import React from 'react';
import { Switch } from '~/components/_inputs/switch';
import { FormLabel } from '~/components/_form/components/label';

export type TARGET_OPTIONS = '_blank' | '_self';

type Props = {
  target: TARGET_OPTIONS;
  onChangeTarget: (target: TARGET_OPTIONS) => void;
};

const Target = ({ target, onChangeTarget }: Props): React.JSX.Element => {
  return (
    <FormLabel label='Opens in new tab' placement='right' size='xs'>
      <Switch
        id='target-switch'
        checked={target === '_blank'}
        onChange={() => {
          onChangeTarget(target === '_self' ? '_blank' : '_self');
        }}
      />
    </FormLabel>
  );
};

export default Target;
