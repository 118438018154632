import { useContext } from 'react';
import { ContextReturnType, TagsInteractionContext } from '~/templates/tags-interaction/context';

const useTagsInteractionContext = (): ContextReturnType => {
  const context = useContext(TagsInteractionContext);
  if (context === undefined) {
    throw new Error('useTagsInteractionContext must be used within a TagsInteractionProvider');
  }

  return context;
};

export default useTagsInteractionContext;
