import React, { memo, useCallback } from 'react';
import { BUILDER_CONDITIONS } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Label } from '~/components/_layout/typography/label';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { Switch } from '~/components/_inputs/switch';

import styles from '../styles.module.scss';

type Props = {
  ruleId: string;
};

const RuleCondition = ({ ruleId }: Props): React.JSX.Element => {
  const { rules, setRuleConditionById } = useConditionBuilderContext();

  const { condition } = rules[ruleId];

  const toggleCondition = useCallback(() => {
    setRuleConditionById(ruleId, condition === BUILDER_CONDITIONS.AND ? BUILDER_CONDITIONS.OR : BUILDER_CONDITIONS.AND);
  }, [condition, ruleId, setRuleConditionById]);

  if (condition === BUILDER_CONDITIONS.DEFAULT) {
    return (
      <div className={styles['default-condition']}>
        <Label size='s'>Where</Label>
      </div>
    );
  }

  return (
    <div className={styles.condition}>
      <Switch
        id={ruleId}
        checked={condition === BUILDER_CONDITIONS.AND}
        onClick={toggleCondition}
        squared
        labels={{
          off: 'and',
          on: 'or'
        }}
      />
    </div>
  );
};

export default memo(RuleCondition);
