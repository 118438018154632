import React from 'react';
import { Button, ButtonProps, LinkButton, LinkProps } from '~/components/button';
import { FormField } from '../../components/field';

import styles from './button-field.module.scss';

type Props = Partial<ButtonProps> & Partial<LinkProps>;

export const ButtonField = ({ to, ...props }: Props): React.JSX.Element => (
  <FormField className={styles.container}>
    {to ? <LinkButton to={to} fluid {...props} /> : <Button fluid {...props} />}
  </FormField>
);
