import React from 'react';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';

type OwnProps = {
  tooltipText?: string;
  toggleExportPopover(): void;
};

export const ExportAction = ({ toggleExportPopover, tooltipText = 'Export' }: OwnProps): React.JSX.Element => (
  <TopBarTool>
    <TooltipOnHover title={tooltipText} delay={100}>
      <Button is='minor' icon='export' onClick={toggleExportPopover} />
    </TooltipOnHover>
  </TopBarTool>
);
