import React, { useCallback } from 'react';

import styles from './styles.module.scss';
import { FeatureIcon } from '~/components/icon';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import TagForm from '~/pages/tags/components/Workspace/components/TagForm';

const MultipleTagsSelected = (): React.JSX.Element => (
  <div className={styles['error-state']}>
    <CoachMessage>
      <p>Multiple tags selected. </p>
    </CoachMessage>
  </div>
);

const Workspace = (): React.JSX.Element => {
  const { state } = useTagsModuleContext();

  const Content = useCallback(() => {
    if (state.selectedTagsIds.length === 1) {
      return <TagForm />;
    }

    if (state.selectedTagsIds.length > 1) {
      return <MultipleTagsSelected />;
    }

    return null;
  }, [state.selectedTagsIds]);

  return (
    <main className={styles.container}>
      <FeatureIcon className={styles.icon} name='tags' />
      <Content />
    </main>
  );
};

export default Workspace;
