import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { Slot } from '~/components/slots';
import Tabs from '~/components/_layout/tabs';
import Tab from '~/components/_layout/tabs/components/Tab';
import { FILES_ROUTES, FOLDER_TYPES } from '~/routes/private/files/constants';
import useActiveFolderType from '~/pages/files/hooks/useActiveFolderType';
import FilesTable from '~/pages/files/components/files-table';

const Files = (): React.JSX.Element => {
  useDocumentTitle('Files');
  const history = useHistory();
  const location = useLocation();
  const activeFolderType = useActiveFolderType();

  const redirectToFolderByType = useCallback(
    (folderType: FOLDER_TYPES) =>
      history.push({
        pathname: FILES_ROUTES[folderType.toUpperCase()],
        search: location.search
      }),
    [history, location.search]
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Images',
        onClick: () => redirectToFolderByType(FOLDER_TYPES.IMAGES),
        isActive: activeFolderType === FOLDER_TYPES.IMAGES
      },
      {
        label: 'Icons',
        onClick: () => redirectToFolderByType(FOLDER_TYPES.ICONS),
        isActive: activeFolderType === FOLDER_TYPES.ICONS
      },
      {
        label: 'Fonts',
        onClick: () => redirectToFolderByType(FOLDER_TYPES.FONTS),
        isActive: activeFolderType === FOLDER_TYPES.FONTS
      }
    ],
    [redirectToFolderByType, activeFolderType]
  );

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Files' active={true} />
          <Breadcrumb.Item label='' />
        </Breadcrumb>
      </Slot>
      <Slot name='tabs'>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab key={index} onClick={tab.onClick} isActive={tab.isActive}>
              {tab.label}
            </Tab>
          ))}
        </Tabs>
      </Slot>
      <FilesTable />
    </>
  );
};

export default Files;
