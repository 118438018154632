import React from 'react';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Button, useAsyncButton } from '~/components/button';
import { ConfirmCallback, useConfirm } from '../../popovers/confirm';

type Props = {
  name: string;
  unsaved: boolean;
  published: boolean;
  onSave: (isEnabled: boolean) => Promise<void>;
  onUpdate: () => Promise<void>;
  onPublish: () => Promise<void>;
};

export const PublishAction = ({
  name,
  unsaved,
  published = !unsaved,
  onSave,
  onUpdate,
  onPublish
}: Props): React.JSX.Element => {
  const { loading, ready, setLoading, setReady, Button: AsyncButton } = useAsyncButton();

  const { Confirm, ...confirmProps } = useConfirm();

  const handleSave = async () => {
    setLoading();

    try {
      unsaved ? await onSave(false) : await onUpdate();
    } catch (error) {
      console.log(error);
    }

    await setReady();
  };

  const handleConfirm: ConfirmCallback = async (setLoading, setReady) => {
    setLoading();

    try {
      if (unsaved) {
        return await onSave(true);
      }

      published ? await onUpdate() : await onPublish();
    } catch (error) {
      console.log(error);
    } finally {
      await setReady();
    }
  };

  return (
    <>
      <TopBarTool>
        {!published && (
          <AsyncButton is='minor' loading={loading} ready={ready} disabled={confirmProps.loading} onClick={handleSave}>
            Save
          </AsyncButton>
        )}
      </TopBarTool>
      <TopBarTool>
        <Confirm
          message={[
            <>
              Are you sure you want to {published ? 'update' : 'publish'} <strong>{name}</strong>
            </>,
            'This can’t be undone.'
          ]}
          onConfirm={handleConfirm}
          {...confirmProps}
        >
          {toggle => <Button onClick={() => toggle(true)}>{published ? 'Update' : 'Publish'}</Button>}
        </Confirm>
      </TopBarTool>
    </>
  );
};
