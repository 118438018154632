import React, { useCallback, useMemo } from 'react';

import { Button } from '~/components/button';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getIsBlockFirst, getIsBlockLast, getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { moveBlockDown, moveBlockUp } from '~/pages/pages/edit/context/store/actions';
import { State } from '~/pages/pages/edit/context/store/types';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

const Ordering = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);
  const selectedBlockId = useMemo(() => {
    const { blockId } = parseLayerId(selectedLayerId);

    return blockId;
  }, [selectedLayerId]);

  const getIsBlockFirstInBodySelector = useCallback(
    (state: State) => getIsBlockFirst(state, { id: selectedBlockId }),
    [selectedBlockId]
  );
  const isBlockFirst = usePageEditorSelector(getIsBlockFirstInBodySelector);

  const getIsBlockLastInBodySelector = useCallback(
    (state: State) => getIsBlockLast(state, { id: selectedBlockId }),
    [selectedBlockId]
  );
  const isBlockLast = usePageEditorSelector(getIsBlockLastInBodySelector);

  const onMoveUp = useCallback(() => dispatch(moveBlockUp(selectedBlockId)), [selectedBlockId, dispatch]);

  const onMoveDown = useCallback(() => dispatch(moveBlockDown(selectedBlockId)), [selectedBlockId, dispatch]);

  return (
    <>
      <Button key={'move-up'} is={'minor'} icon={'arrow-up'} onClick={onMoveUp} disabled={isBlockFirst} />
      <Button key={'move-down'} is={'minor'} icon={'arrow-down'} onClick={onMoveDown} disabled={isBlockLast} />
    </>
  );
};

export default Ordering;
