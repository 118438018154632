import { get } from './utils';

type GetSignedUrlParams = {
  name: string;
};

type GetSignedUrlResponse = {
  fileFullPath: string;
  fileUrl: string;
  signedUrl: string;
};

const getSignedUrl = async (params: GetSignedUrlParams): Promise<GetSignedUrlResponse> =>
  await get<GetSignedUrlResponse, GetSignedUrlParams>(`/upload-image/signed-url`, { name: params.name });

type UploadImageParams = {
  signedUrl: string;
  file: File;
};

const uploadImage = async ({ signedUrl, file }: UploadImageParams): Promise<Response> =>
  await fetch(signedUrl, {
    method: 'PUT',
    body: file
  });

export const system = {
  getSignedUrl,
  uploadImage
};
