import { Block } from '~/types/gists/Block';

export type StructureItemLayer = {
  name: string;
  childrenLayers?: StructureItemLayer[];
};

export const getStructureItemLayers = (
  blockName: Block['name'],
  childrenLayers?: StructureItemLayer[]
): StructureItemLayer[] => {
  return [
    {
      name: blockName,
      childrenLayers
    }
  ];
};
