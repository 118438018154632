import React from 'react';
import { useSidebarPanel } from '~/components/_layout/panels';

import styles from './styles.module.scss';

export const RootWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const { List } = useSidebarPanel();

  return <List>{children}</List>;
};
RootWrapper.displayName = 'TagsTree.RootWrapperLayout';

export const DescendantsWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const { List } = useSidebarPanel();

  return <List className={styles.descendants}>{children}</List>;
};
DescendantsWrapper.displayName = 'TagsTree.DescendantsWrapperLayout';

export const DescendantItem = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const { ListItem } = useSidebarPanel();

  return (
    <ListItem withoutBorder className={styles.descendant}>
      {children}
    </ListItem>
  );
};
DescendantItem.displayName = 'TagsTree.ItemLayout';

export const RootItem = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const { ListItem } = useSidebarPanel();

  return (
    <ListItem withoutBorder className={styles.item}>
      {children}
    </ListItem>
  );
};
RootItem.displayName = 'TagsTree.RootItemLayout';
