import React from 'react';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';

type OwnProps = {
  toggleImportPopover?(): void;
};

export const ImportAction = ({ toggleImportPopover }: OwnProps): React.JSX.Element => {
  return (
    <TopBarTool>
      <TooltipOnHover title='Import' delay={100}>
        <Button is='minor' icon='import' onClick={toggleImportPopover} />
      </TooltipOnHover>
    </TopBarTool>
  );
};
