import React, { useCallback, useEffect, useState } from 'react';
import Interaction, {
  InteractionState
} from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getBlockByLayerId, getSelectedLayer } from '~/pages/pages/edit/context/store/selectors';
import { updateBlockContent } from '~/pages/pages/edit/context/store/actions';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { BlockLayer } from '~/types/gists/page';
import styles from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/styles.module.scss';
import { useSidebarPanel } from '~/components/_layout/panels';
import {
  addLink,
  parseInteractionState
} from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/components/interaction/utils';

const initialInteractionState: InteractionState = {
  url: '',
  ariaLabel: '',
  noFollow: false,
  target: '_self'
};

const StaticInteraction = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const [interactionState, setInteractionState] = useState<InteractionState>(initialInteractionState);

  const layer = usePageEditorSelector(getSelectedLayer) as BlockLayer;

  const getBlockByLayerIdSelected = useCallback(state => getBlockByLayerId(state, { layerId: layer.id }), [layer.id]);
  const block = usePageEditorSelector(getBlockByLayerIdSelected);

  useEffect(() => {
    const parsedInteractionState = parseInteractionState(block.data.content);
    setInteractionState(parsedInteractionState || initialInteractionState);
  }, [block.data.content]);

  const onInteractionChange = useCallback(
    (newInteractionState: InteractionState) => {
      const newBlockContent = addLink(block.data.content, newInteractionState);
      dispatch(updateBlockContent(block.id, newBlockContent));
    },
    [block.data.content, block.id, dispatch]
  );

  const { Panel, List, ListItem } = useSidebarPanel();

  return (
    <Panel className={styles.container} title={'Interaction'}>
      <List>
        <ListItem withoutBorder>
          <Interaction onInteractionChange={onInteractionChange} interactionState={interactionState} />
        </ListItem>
      </List>
    </Panel>
  );
};

export default StaticInteraction;
