const getThemeSchema = (isLight: boolean): Theme => (isLight ? 'light' : 'dark');

const query = window.matchMedia('(prefers-color-scheme: light)');
const systemDefault = getThemeSchema(query.matches);

export class ThemeService {
  private static query = query;
  private static systemDefault = systemDefault;

  static get = (): Theme => {
    return (localStorage.getItem('theme') as Theme) ?? ThemeService.systemDefault;
  };

  static set = (theme: Theme): void => {
    localStorage.setItem('theme', theme);
  };

  static has = (): boolean => {
    return !!localStorage.getItem('theme');
  };

  static remove = (): void => {
    localStorage.removeItem('theme');
  };

  static getDefault = (): Theme => {
    return ThemeService.systemDefault;
  };

  static setDefault = (theme: Theme): void => {
    ThemeService.systemDefault = theme;
  };

  static subscribe = (callback: (theme: Theme) => void): (() => void) => {
    const onChange = ({ matches }: MediaQueryListEvent) => {
      callback(getThemeSchema(matches));
    };

    ThemeService.query.addEventListener('change', onChange);

    return () => ThemeService.query.removeEventListener('change', onChange);
  };
}
