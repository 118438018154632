import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';

import { Button } from '~/components/button';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import IconThumb from '~/components/_table/cells/file-primary/components/IconThumb';
import ImageThumb from '~/components/_table/cells/file-primary/components/ImageThumb';
import FontThumb from '~/components/_table/cells/file-primary/components/FontThumb';
import { withFilePrimaryCellContext } from '~/components/_table/cells/file-primary/hocs/withFilePrimaryCellContext';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';
import useReplaceFile from '~/pages/files/components/files-table/hooks/use-replace-file';
import { setAltText, setDescription } from '~/components/_table/cells/file-primary/context/actions';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Label } from '~/components/_layout/typography/label';

import styles from './file-primary.module.scss';

const FilePrimaryCell = () => {
  const { isFileDetailsOpened, cellProps, dispatch } = useFilePrimaryCellContext();
  const {
    row: { original },
    toggleTableLoading,
    refetchTable,
    setTableError,
    wrappingMode
  } = cellProps;

  const { folder, name, altText, description } = original;

  useEffect(() => {
    dispatch(setAltText(altText));
    dispatch(setDescription(description));
  }, [altText, description, dispatch]);

  const { replaceFile } = useReplaceFile();

  const onReplaceFile = useCallback(async () => {
    await replaceFile({
      startLoading: () => toggleTableLoading(true),
      finishLoading: () => toggleTableLoading(false),
      target: original,
      refetchTable,
      onError: setTableError
    });
  }, [original, refetchTable, replaceFile, setTableError, toggleTableLoading]);

  const isWrapMode = wrappingMode === 'wrap';

  return (
    <div
      className={classnames(styles.container, {
        [styles.selected]: isFileDetailsOpened
      })}
    >
      <div className={styles['thumb-container']}>
        {folder === FOLDER_TYPES.IMAGES && <ImageThumb />}
        {folder === FOLDER_TYPES.ICONS && <IconThumb />}
        {folder === FOLDER_TYPES.FONTS && <FontThumb />}
      </div>
      <div className={classnames(styles['label-container'], { [styles.wrap]: isWrapMode })}>
        <TooltipOnHover title={name}>
          <Label size='s' truncated={!isWrapMode}>
            {name}
          </Label>
        </TooltipOnHover>
      </div>

      <div className={styles['replace-button']}>
        <Button icon='refresh' onClick={onReplaceFile} />
      </div>
    </div>
  );
};

export default withFilePrimaryCellContext(FilePrimaryCell);
