import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { RowClickCallback } from 'react-table';

import { isNil } from '~/helpers/common';
import { ScrollContainer } from '~/helpers/ui-kit/scroll-container';
import useDocumentTitle from '~/hooks/use-document-title';

import { FormLabel } from '~/components/_form/components/label';
import { Switch } from '~/components/_inputs/switch';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { DeleteCurrentAction } from '~/components/_layout/page-actions/delete-current-action';
import { DuplicateCurrentAction } from '~/components/_layout/page-actions/duplicate-current-action';
import { PublishAction } from '~/components/_layout/page-actions/publish-action';
import { PublishPanel } from '~/components/_layout/panels/publish';
import { SelectPanel } from '~/components/_layout/panels/select';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { Button } from '~/components/button';

import { Slot } from '~/components/slots';
import { useTheme } from '~/components/theme';
import { buildRepeatableConfig } from '~/pages/push-notifications/edit/helpers';

import { ContentStructurePanel } from '~/pages/push-notifications/edit/panel/content-structure';
import { SchedulerPanel } from '~/pages/push-notifications/edit/panel/scheduler';
import { defaultSchedulerConfig } from '~/pages/push-notifications/edit/panel/scheduler/config';

import { PUSH_NOTIFICATIONS_ROUTES } from '~/routes/private/push-notifications/constants';
import { ChangeEvent as InputChangeEvent, EditTemplate } from '~/templates/edit';
import { EditTemplateState } from '~/templates/edit/use-editable-template';
import { withEditTemplate } from '~/templates/edit/with-edit-template';
import { Cohort } from '~/types/gists/cohort';

import { PushNotification, PushNotificationHistory } from '~/types/gists/push-notification';

import { StatsTable } from '../stats';

import styles from './edit.module.scss';
import { LOADING_MESSAGE } from './messages';
import { Preview } from './preview';
import { Mode, ModeAction } from './top-bar/mode-action';
import TagsPanel from '~/components/_layout/panels/tags';

const newPushNotification: Partial<PushNotification> = {
  name: 'New Push'
};

const Component = (props: EditTemplateState<PushNotification>): React.JSX.Element => {
  const [mode, setMode] = useState<Mode>('edit');
  const [selectedRow, selectRow] = useState<PushNotificationHistory>();
  const { item, isNew, touch, change, add, edit, publish, removeCurrent, cloneCurrent, error, isTouched } = props;

  useDocumentTitle(`${item.name} - Push`);

  const history = useHistory();
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();

  const {
    cohortId,
    name,
    image,
    message = '',
    link,
    title = '',
    isActive,
    createdAt,
    updatedAt,
    createdBy,
    updatedBy,
    isRepeatable = false,
    sendOnceToUser = true,
    repeatableConfig = defaultSchedulerConfig,
    triggeredAt,
    description,
    tags
  } = item;

  const [messageStructure, setMessageStructure] = useState({
    showLink: isNew ? true : !isNil(link),
    showImage: isNew ? false : !isNil(image),
    showTitle: isNew ? true : !isNil(title)
  });

  const [initialScheduleTime, setInitialScheduleTime] = useState(repeatableConfig.time);

  const isNotificationScheduleTimeChangedButNotSaved = useMemo(
    () => dayjs(initialScheduleTime).diff(repeatableConfig.time, 'm') !== 0,
    [repeatableConfig.time, initialScheduleTime]
  );

  const handleChange = (name: keyof PushNotification) => (event: InputChangeEvent) => {
    change(name, event.target.value);
  };

  const handleSave = async (isEnabled = false) => {
    const response = await add({
      isActive: isEnabled,
      message,
      title,
      isRepeatable,
      sendOnceToUser,
      cohortId,
      repeatableConfig: buildRepeatableConfig(repeatableConfig),
      ...(!isRepeatable && { triggeredAt: repeatableConfig.time })
    });

    if (response) {
      history.replace(`/push-notifications/${response.id}${search}`);
    }
  };

  const handleUpdate = async () => {
    await edit({
      image: messageStructure.showImage ? image : null,
      link: messageStructure.showLink ? link : null,
      title: messageStructure.showTitle ? title : null,
      repeatableConfig: buildRepeatableConfig(repeatableConfig),
      ...(!isRepeatable && { triggeredAt: repeatableConfig.time })
    });

    setInitialScheduleTime(repeatableConfig.time);
  };

  const handlePublish = async () => {
    await publish();
  };

  const handleRemove = async () => {
    return await removeCurrent();
  };

  const onSaveTags = useCallback(
    (name: string, value: PushNotification['tags']) => {
      change(name, value);
      // Assume that it is an on-mount fetch & save operation, not an update made by a user.
      touch(false);
    },
    [change, touch]
  );

  const handleClone = useCallback(async () => {
    return await cloneCurrent({ isActive: false });
  }, [cloneCurrent]);

  const stats = useTableFetch<PushNotificationHistory>({
    gist: 'pushNotifications',
    id
  });

  const handleRowClick: RowClickCallback<PushNotificationHistory> = row => {
    selectRow(row);
  };

  useEffect(() => {
    if (!stats.loading && stats.items.length > 0 && !selectedRow) {
      selectRow(stats.items[0]);
    }
  }, [stats.loading, stats.items, selectedRow]);

  const { affiliateDetails } = useTheme();

  const editPreview = {
    title,
    image,
    message,
    affiliateName: affiliateDetails?.name
  };

  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error });

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Push' to={PUSH_NOTIFICATIONS_ROUTES.root} />
          <Breadcrumb.Item label={name} truncated />
        </Breadcrumb>
      </Slot>
      <EditTemplate
        description={description}
        icon='scheduled-messages'
        title={name}
        onTitleChange={handleChange('name')}
        onDescriptionChange={handleChange('description')}
      >
        <Slot name='page-actions'>
          <ModeAction isNew={isNew} defaultValue={mode} onChange={setMode} />
          {!!item.id && (
            <>
              <DuplicateCurrentAction
                rootRoute={PUSH_NOTIFICATIONS_ROUTES.root}
                isUnsavedChanges={isTouched}
                onClone={handleClone}
              />
              <DeleteCurrentAction name={name} rootRoute={PUSH_NOTIFICATIONS_ROUTES.root} onConfirm={handleRemove} />
            </>
          )}
          <PublishAction
            name={name}
            unsaved={isNew}
            published={isActive}
            onSave={handleSave}
            onUpdate={handleUpdate}
            onPublish={handlePublish}
          />
        </Slot>
        {mode !== 'stats' && (
          <Slot name='right-sidebar'>
            <PublishPanel
              name={name}
              createdAt={createdAt}
              updatedAt={updatedAt}
              createdBy={createdBy}
              updatedBy={updatedBy}
              unsaved={isNew}
              published={isActive}
              onSave={handleSave}
              onPublish={handlePublish}
            />
            <ContentStructurePanel
              messageStructure={messageStructure}
              onStructureChange={setMessageStructure}
              link={link}
              onLinkChange={change}
            />
            <SelectPanel<Cohort>
              title='Recipients'
              label='Send to this Cohort'
              placeholder='Select a cohort...'
              id={cohortId}
              onChange={change}
              gist='cohorts'
              name='cohortId'
              className={styles.selector}
              multiLine
            >
              <FormLabel label='Users can only receive the same notification once' placement='right'>
                <Switch name='sendOnceToUser' checked={sendOnceToUser} onChange={change} id='anti-spam' />
              </FormLabel>
            </SelectPanel>
            <SchedulerPanel
              isRepeatable={isRepeatable}
              repeatableConfig={repeatableConfig}
              onChange={change}
              triggeredAt={triggeredAt}
              isTimeChangedButNotSaved={isNotificationScheduleTimeChangedButNotSaved}
              isPublished={isActive}
            />
            <TagsPanel tags={tags} onChange={onSaveTags} />
          </Slot>
        )}
        <div className={styles.container}>
          <Preview
            mode={mode}
            messageStructure={messageStructure}
            editPreview={editPreview}
            statsPreview={selectedRow?.messageDetails ?? {}}
            onChange={change}
          />
          {mode === 'stats' && (
            <ScrollContainer at='y'>
              <StatsTable state={stats} onRowClick={handleRowClick} selectedRow={selectedRow} />
            </ScrollContainer>
          )}
        </div>
      </EditTemplate>
      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
    </>
  );
};

export const EditPushNotification = withEditTemplate<PushNotification>({
  gist: 'pushNotifications',
  loadingMessage: LOADING_MESSAGE,
  defaultItem: newPushNotification
})(Component);
