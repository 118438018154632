import React from 'react';
import classnames from 'classnames';
import type { Options } from '@popperjs/core';
import { Popover, UsePopover, usePopover } from '~/components/popover';
import { Portal } from '~/components/portal';

import { Label } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';
import { SVGGlowFilter } from '~/components/icon/filters/glow';

import styles from './indicator.module.scss';

const POPPER: Partial<Options> = {
  placement: 'left-start',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [-4, 8]
      }
    }
  ]
};

export type Toggle = Pick<UsePopover, 'toggle'>['toggle'];

type Props = {
  children: (toggle: Toggle) => React.ReactNode;
  value: number | undefined;
  className?: string;
  constraint: number;
};

export const Indicator = React.memo(({ children, value = 0, className, constraint }: Props): React.JSX.Element => {
  const { onPopoverRef, onTriggerRef, show, toggle } = usePopover(false, POPPER);

  return (
    <div className={classnames(styles.container, className)}>
      <div ref={onTriggerRef}>{children(toggle)}</div>
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
          {show && (
            <Popover className={styles.popover}>
              <Label size='xs'>{value}</Label>
              {value > constraint && (
                <Icon name='alert' className={styles.icon} filter='url(#glow)'>
                  <SVGGlowFilter id='glow' />
                </Icon>
              )}
            </Popover>
          )}
        </div>
      </Portal>
    </div>
  );
});
