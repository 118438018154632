import { ActionTypes } from '~/templates/tags-interaction/context/store/actions';
import { SetState, State } from '~/templates/tags-interaction/context/store/types';

const TagsInteractionReducer = (state: State, action: SetState): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SetTags: {
      return {
        ...state,
        tags: payload
      };
    }
    case ActionTypes.SetError: {
      return {
        ...state,
        error: payload
      };
    }
    case ActionTypes.InitSelectedTags: {
      return {
        ...state,
        selectedTags: payload
      };
    }
    case ActionTypes.SelectTag: {
      return {
        ...state,
        selectedTags: [...state.selectedTags, payload]
      };
    }
    case ActionTypes.DeselectTag: {
      return {
        ...state,
        selectedTags: state.selectedTags.filter(selectedTag => selectedTag.id !== payload)
      };
    }
    case ActionTypes.SetIsLoading: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case ActionTypes.SetInteractionMode: {
      return {
        ...state,
        interactionMode: payload
      };
    }
    case ActionTypes.SetNewTagName: {
      return {
        ...state,
        newTagName: payload
      };
    }
    case ActionTypes.SetFilter: {
      return {
        ...state,
        filter: payload
      };
    }

    default: {
      return state;
    }
  }
};

export default TagsInteractionReducer;
