import React from 'react';
import { Field } from 'formik';
import { useUniqId } from '~/hooks/use-uniq-id';
import { FormField } from '~/components/_form/components/field';
import { ControlledFormLabel as FormLabel, ExtraProps as LabelProps } from '~/components/_form/components/label';
import { Props as TickboxProps, Tickbox } from '~/pages/onboarding/components/tickbox';

import styles from './tickbox-field.module.scss';

type Props = {
  name: string;
  value: boolean;
  label: string;
  labelProps?: LabelProps;
  inputProps?: TickboxProps;
};

export const TickboxField = ({ name, value, label, labelProps, inputProps }: Props): React.JSX.Element => {
  const id = useUniqId(name);

  return (
    <FormField className={styles.container}>
      <FormLabel id={id} placement='right' size='s' {...labelProps} label={label}>
        <Field as={Tickbox} {...inputProps} id={id} name={name} checked={value} />
      </FormLabel>
    </FormField>
  );
};
