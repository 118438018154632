import queryString from 'query-string';
import { UserParams } from '~/api/onboarding';
import { UserService } from '~/services/user-service';

const UNAUTHORIZED_ERROR = 'UnauthorizedError';
const UNAUTHORIZED_MESSAGE = 'Token is invalid or has expired';

export const DEFAULT_AFFILIATE: AffiliateKey = 'fhc';

const getDefaultHeaders = () => {
  return new Headers({ 'Content-Type': 'application/json' });
};

const createHeaders = (affiliate: string) => {
  const token = localStorage.getItem('token');

  const headers = getDefaultHeaders();

  if (token) {
    headers.set('authorization', token);
  }

  if (affiliate) {
    headers.set('x-affiliate', affiliate);
  }

  return headers;
};

type Query = Record<string, string | number>;

export const baseApiUrls = {
  dev: process.env.REACT_APP_ADMIN_API_ENDPOINT_DEV,
  test: process.env.REACT_APP_ADMIN_API_ENDPOINT_TEST,
  stage: process.env.REACT_APP_ADMIN_API_ENDPOINT_STAGE,
  prod: process.env.REACT_APP_ADMIN_API_ENDPOINT_PROD
};

const hostnameToEnv: Record<string, Environment> = {
  'console.lifehubplatform.com': 'prod',
  'console.stage.lifehubplatform.com': 'stage',
  'console.test.lifehubplatform.com': 'test',
  'console.dev.lifehubplatform.com': 'dev',
  localhost: 'dev'
};

export const getEnvFromUrl = (): Environment => {
  const { env } = queryString.parse(window.location.search);
  if (env && env !== 'undefined' && env !== 'none') {
    return env as Environment;
  }

  return hostnameToEnv[window.location.hostname];
};

/**
 * If the affiliate is not specified in the URL, we will use the FHC affiliate as a default one.
 * It will be enough to fetch the very first affiliate's permissions and avoid the app's crush.
 */
export const getAffiliateFromUrl = (): AffiliateKey => {
  const { affiliate } = queryString.parse(window.location.search);
  if (affiliate && affiliate !== 'undefined') {
    return affiliate as AffiliateKey;
  }

  return DEFAULT_AFFILIATE;
};

export const requestByUrl = async <R, B = Body>(
  method: string,
  url: string,
  affiliate: string | (string | null)[] | null,
  params?: B
): Promise<R> => {
  const headers = createHeaders(affiliate as string);

  const body = params && JSON.stringify(params);

  const response = await fetch(url, { headers, method, body });

  const result = await response.json();

  if (!response.ok && (result.name === UNAUTHORIZED_ERROR || result.message === UNAUTHORIZED_MESSAGE)) {
    try {
      await refreshSession();

      return await requestByUrl<R, B>(method, url, affiliate, params);
    } catch (error) {
      UserService.removeUserInfo();
      window.location.reload();
    }
  }

  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw result as Error;
  }

  return result as R;
};

//R stands for Result
//B stands for Body
export const request = async <R, B = Body>(method: string, url: string, params?: B): Promise<R> => {
  const env = getEnvFromUrl();
  const baseUrl = baseApiUrls[env];
  const affiliate = getAffiliateFromUrl();

  return await requestByUrl<R, B>(method, baseUrl + url, affiliate, params);
};

export const encodeQueryParams = <Q extends Query>(url: string, params?: Q): string => {
  if (params) {
    const query = Object.keys(params)
      .filter(name => Boolean(params[name]))
      .map(name => {
        return encodeURIComponent(name) + '=' + encodeURIComponent(params[name]);
      });

    if (query.length) {
      return `${url}?${query.join('&')}`;
    }
  }

  return url;
};

export const get = async <R, Q extends Query>(url: string, query?: Q): Promise<R> =>
  request<R>('GET', encodeQueryParams<Q>(url, query));

export const post = async <R, B>(url: string, params?: B): Promise<R> => request<R, B>('POST', url, params);

export const patch = async <R, B>(url: string, params: B): Promise<R> => request<R, B>('PATCH', url, params);

export const put = async <R, B>(url: string, params: B): Promise<R> => request<R, B>('PUT', url, params);

export const remove = async <R, Q extends Query>(url: string, query?: Q): Promise<R> =>
  request<R>('DELETE', encodeQueryParams<Q>(url, query));

const refreshSession = async () => {
  const token = localStorage.getItem('token') as string;
  const refreshToken = localStorage.getItem('refreshToken') as string;
  const email = localStorage.getItem('email') as string;

  const response = await post<UserParams, Partial<UserParams>>('/session', { token, refreshToken, email });

  localStorage.setItem('token', response.token);
  localStorage.setItem('accessToken', response.accessToken);
  localStorage.setItem('refreshToken', response.refreshToken);
  localStorage.setItem('email', response.email);
};
