import React, { createContext, ReactNode } from 'react';
import { TableDataLike, TableInstance } from 'react-table';

type ContextReturnType = TableInstance<any>;

const TableInstanceContext = createContext<ContextReturnType>({} as TableInstance<any>);

type Props<T extends TableDataLike> = {
  children: ReactNode;
  instance: TableInstance<T>;
};

const TableContextProvider = <T extends TableDataLike>({ children, instance }: Props<T>): React.JSX.Element => (
  <TableInstanceContext.Provider value={instance}>{children}</TableInstanceContext.Provider>
);

export default TableContextProvider;
export { TableInstanceContext };
