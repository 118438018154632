import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isUndefined } from '~/helpers/common';
import { isHtmlElement } from '~/helpers/common/element';
import { SCOptions, ScrollControl } from '~/helpers/common/scroll-control';

export const useScrollControl = <RefType = React.ElementType>(
  on: boolean,
  options?: SCOptions
): {
  anchor: React.RefCallback<RefType>;
  enableScroll: () => void;
  disableScroll: (ref?: RefType) => void;
} => {
  const scrollController = useRef(new ScrollControl(options));

  const [target, setTarget] = useState();

  const onRef = useCallback(node => {
    setTarget(node);
  }, []);

  const enable = useCallback(() => {
    const { current: SC } = scrollController;

    SC.enableScroll();
  }, []);

  const disable = useCallback(ref => {
    const { current: SC } = scrollController;

    SC.disableScroll(ref);
  }, []);

  useEffect(() => {
    const { current: SC } = scrollController;

    if (isHtmlElement(target) && !isUndefined(on)) {
      on ? SC.disableScroll(target) : SC.enableScroll();
    }

    return () => {
      SC.enableScroll();
    };
  }, [on, target]);

  return {
    anchor: onRef,
    enableScroll: enable,
    disableScroll: disable
  };
};
