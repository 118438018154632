import React from 'react';
import { ThemeService } from '~/services/theme-service';
import { Select, SelectCallback, SelectItem } from '~/components/_inputs/select';
import { useTheme } from '~/components/theme';
import { FormLabel } from '~/components/_form/components/label';

const GET_DEFAULT_VALUE = (): SelectItem<Theme> => ({
  id: 'system',
  label: 'System default',
  value: ThemeService.getDefault()
});

const VALUES: Record<Theme, SelectItem<Theme>> = {
  dark: {
    id: 2,
    label: 'Dark',
    value: 'dark'
  },
  light: {
    id: 3,
    label: 'Light',
    value: 'light'
  }
};

const ITEMS: SelectItem<Theme>[] = [GET_DEFAULT_VALUE(), VALUES.dark, VALUES.light];

export const ThemeSelect = (): React.JSX.Element => {
  const { theme, setTheme } = useTheme();

  const handleChange: SelectCallback<Theme> =
    () =>
    ({ id, value }) => {
      if (id === 'system') {
        ThemeService.remove();
      } else {
        ThemeService.set(value);
      }

      setTheme(value);
    };

  return (
    <FormLabel label='Colour mode'>
      <Select
        name='theme-select'
        items={ITEMS}
        value={ThemeService.has() ? VALUES[theme] : GET_DEFAULT_VALUE()}
        onChange={handleChange}
      />
    </FormLabel>
  );
};
