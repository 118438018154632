import { ContentBlock, ContentState } from 'draft-js';

export const DEFAULT_VALUE_TO_REPLACE_NEW_LINE_SYMBOLS = '<span><br></span>';

export const DEFAULT_BLOCK_TAG = {
  list: 'li',
  image: 'figcaption',
  quote: 'figcaption',
  default: 'span'
};

export const REPLACE_NEW_LINE_VALUE_MAP: { [key in keyof typeof DEFAULT_BLOCK_TAG]: string } = {
  list: '',
  image: '',
  quote: DEFAULT_VALUE_TO_REPLACE_NEW_LINE_SYMBOLS,
  default: DEFAULT_VALUE_TO_REPLACE_NEW_LINE_SYMBOLS
};

export const findLinkEntitiesStrategy = (
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  return block.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

export const replaceWhitespacesBetweenTags = (html: string): string => {
  // Removes any whitespace characters between HTML tags.
  return html.replace(/>\s+</g, '><');
};

export const replaceNewLineSymbols = (
  html: string,
  replaceValue: string | undefined = DEFAULT_VALUE_TO_REPLACE_NEW_LINE_SYMBOLS
): string => {
  return (
    html
      // Handles splitting Editor's content into several container tags.
      .replaceAll(/<\/blockquote>\n<blockquote>/g, replaceValue)
      .replaceAll(/<\/figcaption>\n<figcaption>/g, replaceValue)
      // Handles two soft new lines /n in a row or the very first new line before the whole content.
      .replaceAll(/<span><br><\/span>\n/g, replaceValue)
      // Handles a single soft new line /n.
      .replace(/(?<=>)\n(?=<)/g, replaceValue)
  );
};
