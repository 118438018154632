import { useCallback, useState } from 'react';
import {
  DEFAULT_POPPER_OPTIONS,
  ERROR_RESPONSE_MESSAGE,
  OPERATION_TYPES
} from '~/components/_layout/popovers/import-export/constants';
import { OperationOptions } from '~/components/_layout/popovers/import-export/types';
import { isUndefined } from '~/helpers/common';
import { api } from '~/api';
import { CoachMessage } from '~/components/_layout/coach/coach-popover';
import { PageActionsPopoverComponent as Popover } from '~/components/_layout/popovers/import-export/page-actions-popover';
import { Extend } from '~/components/_table/hooks/use-table-fetch';
import { useAsyncButton } from '~/components/button';
import { usePopover } from '~/components/popover';
import {
  getFirstEntityFromImportList,
  getImportSuccessMessage
} from '~/components/_table/hooks/use-table-import/helpers';
import { UseTableImportParams, UseTableImportReturnType } from '~/components/_table/hooks/use-table-import/types';
import { gistToIdentifier } from '~/components/_table/helpers/gist-to-identifier';
import { StringShim } from '~/helpers/shims/string-shim';

const operationOptions: OperationOptions = {
  defaultCode: '',
  editable: true,
  title: 'Import',
  submitText: 'Begin Import',
  successTitle: 'Successful Import!',
  operationType: OPERATION_TYPES.IMPORT
};

export const useTableImport = <T extends Extend>({ gist }: UseTableImportParams): UseTableImportReturnType => {
  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, DEFAULT_POPPER_OPTIONS);
  const { loading, ready, setLoading, setReady } = useAsyncButton();
  const [hasError, setHasError] = useState<boolean>(false);

  const onOpenImport = useCallback(() => {
    toggle(true);
  }, [toggle]);

  const onImportSubmit = useCallback(
    async (code: string): Promise<CoachMessage> => {
      setLoading();

      try {
        const itemsForImport = JSON.parse(code);
        const firstEntity = getFirstEntityFromImportList<T>(itemsForImport);
        const identifier = gistToIdentifier[gist];

        if (!isUndefined(firstEntity) && firstEntity !== StringShim.capitalize(identifier)) {
          setHasError(true);
          return `You are trying to import '${firstEntity}' instead of '${StringShim.capitalize(identifier)}'.`;
        }

        const response = await api[gist].importItems<T>({ data: itemsForImport });
        setHasError(false);

        return getImportSuccessMessage(response.result);
      } catch (e) {
        setHasError(true);

        return ERROR_RESPONSE_MESSAGE;
      } finally {
        await setReady();
      }
    },
    [gist, setLoading, setReady]
  );

  return {
    onOpenImport,
    onImportSubmit,
    operationOptions,
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    loading,
    ready,
    setLoading,
    setReady,
    Popover,
    hasError
  };
};
