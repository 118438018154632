import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactSVG } from 'react-svg';
import { FilesLoader } from '~/components/_layout/popovers/upload/components/loader';
import { HandleFileInteraction } from '~/components/_layout/popovers/upload/types';
import { Label } from '~/components/_layout/typography/label';
import { Image } from '~/components/image';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { FileObject } from '~/types/gists/file';

import styles from './styles.module.scss';

type ListProps = {
  loading: boolean;
  count: number;
  items: FileObject[];
  handleSelect: HandleFileInteraction;
  loadMore: () => Promise<void>;
};

export const FileList = React.memo(
  ({ items, count, loading, handleSelect, loadMore }: ListProps): React.JSX.Element => {
    return (
      <div id='files' className={styles.files}>
        <InfiniteScroll
          scrollableTarget='files'
          dataLength={items.length}
          hasMore={count > items.length}
          next={loadMore}
          loader={<FilesLoader message='Loading more files...' />}
        >
          <>
            {loading ? <FilesLoader message='Loading files...' /> : null}
            {items.map(fileObject => (
              <FileListItem key={fileObject.id} fileObject={fileObject} onClick={handleSelect(fileObject)} />
            ))}
          </>
        </InfiniteScroll>
      </div>
    );
  }
);

type ListItemProps = {
  fileObject: FileObject;
  onClick: () => void;
};

const FileListItem = React.memo((props: ListItemProps): React.JSX.Element => {
  const { fileObject, onClick } = props;
  const { name, altText, updatedAt, folder, s3ObjectUrl } = fileObject;

  return (
    <div className={styles['list-item']} onClick={onClick}>
      {folder === FOLDER_TYPES.IMAGES && (
        <div className={styles['thumb-container']}>
          <Image src={`${s3ObjectUrl}?updatedAt=${updatedAt}`} alt={altText ?? undefined} className={styles.thumb} />
        </div>
      )}
      {folder === FOLDER_TYPES.ICONS && (
        <div className={styles['icon-container']}>
          <ReactSVG src={`${s3ObjectUrl}?updatedAt=${updatedAt}`} />
        </div>
      )}
      <div className={styles['label-container']}>
        <Label size='s'>{name}</Label>
      </div>
    </div>
  );
});
