import React, { useCallback, useRef } from 'react';
import { Button } from '~/components/button';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import Uploader from '~/pages/files/components/files-table/components/add-files/components/uploader';

const AddFiles = (): React.JSX.Element => {
  const uploaderRef = useRef<HTMLInputElement>(null);
  const openUploader = useCallback(() => uploaderRef.current?.click(), []);

  return (
    <>
      <TopBarTool>
        <Uploader ref={uploaderRef} />
        <Button icon='plus' is='minor' onClick={openUploader}>
          Add Files
        </Button>
      </TopBarTool>
    </>
  );
};

export default AddFiles;
