import React, { useCallback } from 'react';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import useReplaceFile from '~/pages/files/components/files-table/hooks/use-replace-file';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';

const Replace = () => {
  const { cellProps } = useFilePrimaryCellContext();

  const {
    toggleTableLoading,
    row: { original },
    refetchTable,
    setTableError
  } = cellProps;

  const { replaceFile } = useReplaceFile();

  const onReplace = useCallback(
    async () =>
      replaceFile({
        startLoading: () => toggleTableLoading(true),
        finishLoading: () => toggleTableLoading(false),
        target: original,
        refetchTable,
        onError: setTableError
      }),
    [original, refetchTable, replaceFile, setTableError, toggleTableLoading]
  );

  return (
    <TooltipOnHover title='Replace file' delay={100}>
      <Button icon='refresh' is={'minor'} onClick={onReplace} />
    </TooltipOnHover>
  );
};

export default Replace;
