import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { NumberShim } from '~/helpers/shims/number-shim';

import type { CohortHistoryEntry } from '~/types/gists/cohort';

dayjs.extend(duration);

export const getHistoryEntryPushEnabled = ({ total, withFirebaseTokens }: CohortHistoryEntry): string => {
  return `${withFirebaseTokens} (${NumberShim.percentOf(withFirebaseTokens, total, 'ceil')}%)`;
};

export const getHistoryEntryDuration = ({ createdAt, updatedAt }: CohortHistoryEntry): string => {
  const hours = dayjs(updatedAt).diff(dayjs(createdAt), 'h', true);

  return dayjs.duration(hours, 'hours').format('HH:mm:ss');
};
