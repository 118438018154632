import classnames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { FeatureIcon } from '~/components/icon';

import styles from './styles.module.scss';

export enum SEO_SOCIALS {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter'
}

type Props = {
  selectedTab: SEO_SOCIALS;
  onChangeTab: (tab: SEO_SOCIALS) => MouseEventHandler<HTMLButtonElement>;
};

export const SeoTabSwitcher = ({ selectedTab, onChangeTab }: Props): React.JSX.Element => (
  <div className={styles.container}>
    <button
      onClick={onChangeTab(SEO_SOCIALS.GOOGLE)}
      className={classnames(styles.tab, { [styles['selected-tab']]: selectedTab === SEO_SOCIALS.GOOGLE })}
    >
      <FeatureIcon name='google' />
    </button>
    <button
      onClick={onChangeTab(SEO_SOCIALS.FACEBOOK)}
      className={classnames(styles.tab, { [styles['selected-tab']]: selectedTab === SEO_SOCIALS.FACEBOOK })}
    >
      <FeatureIcon name='facebook' />
    </button>
    <button
      onClick={onChangeTab(SEO_SOCIALS.TWITTER)}
      className={classnames(styles.tab, { [styles['selected-tab']]: selectedTab === SEO_SOCIALS.TWITTER })}
    >
      <FeatureIcon name='twitter' />
    </button>
  </div>
);
