import { InteractionState } from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction';
import { makeValidUrl } from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/utils';

export const parseInteractionState = (input: string): InteractionState | null => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  const figureElement = doc.querySelector('figure');
  if (!figureElement) {
    return null;
  }

  const anchorElement = figureElement.querySelector('a');
  const imgElement = figureElement.querySelector('img');
  const figcaptionElement = figureElement.querySelector('figcaption');

  if (!anchorElement) {
    return null;
  }

  const url = anchorElement.getAttribute('href') || '';
  const ariaLabel = imgElement?.getAttribute('alt') || figcaptionElement?.textContent || '';
  const noFollow = anchorElement.getAttribute('rel')?.includes('nofollow') || false;
  const target = (anchorElement.getAttribute('target') as '_self' | '_blank') || '_self';

  return {
    url,
    ariaLabel,
    noFollow,
    target
  };
};

export const addLink = (content: string, interactionState: InteractionState): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  const existingAnchor = doc.querySelector('a') as HTMLAnchorElement | null;

  if (existingAnchor) {
    if (!interactionState.url) {
      const anchorContent = existingAnchor.innerHTML;
      const anchorParent = existingAnchor.parentElement;
      if (anchorParent) {
        anchorParent.replaceChild(doc.createRange().createContextualFragment(anchorContent), existingAnchor);
      }
    } else {
      existingAnchor.setAttribute('href', makeValidUrl(interactionState.url));
      existingAnchor.setAttribute('aria-label', interactionState.ariaLabel || '');
      existingAnchor.setAttribute('rel', interactionState.noFollow ? 'nofollow' : '');

      if (interactionState.target) {
        existingAnchor.setAttribute('target', interactionState.target);
      } else {
        existingAnchor.removeAttribute('target');
      }

      if (!interactionState.ariaLabel) {
        existingAnchor.removeAttribute('aria-label');
      }
    }
  } else if (interactionState.url) {
    const newAnchor = doc.createElement('a');
    newAnchor.setAttribute('href', makeValidUrl(interactionState.url));
    if (interactionState.ariaLabel) {
      newAnchor.setAttribute('aria-label', interactionState.ariaLabel);
    }
    if (interactionState.noFollow) {
      newAnchor.setAttribute('rel', 'nofollow');
    }
    if (interactionState.target) {
      newAnchor.setAttribute('target', interactionState.target);
    }

    const imgElement = doc.querySelector('img');
    if (imgElement) {
      const imgParent = imgElement.parentElement;
      if (imgParent) {
        imgParent.replaceChild(newAnchor, imgElement);
        newAnchor.appendChild(imgElement);
      }
    }
  }

  return doc.body ? doc.body.innerHTML : content;
};
