import { PromiseShim } from '../shims/promise-shim';
import { EventOptions, IS_PASSIVE_SUPPORTED, listenIt, prevent, stop, Touch, unListenItForEach } from './event';
import { hasScroll, isScrollAtBottom, isScrollAtTop } from './scroll';
import { DEVICE } from './user-agent';

type SCState = {
  disabled: boolean;
  locked: boolean;
  tracked: boolean;
};

export type SCOptions = {
  target?: HTMLElement;
  className?: string;
};

type SCLock = (() => void | null) | null;

type SCLocks = {
  [K in keyof DocumentEventMap as string]: SCLock;
};

type SCEvent = {
  callback: (event: TouchEvent) => unknown;
  options?: EventOptions;
};

type SCEvents = {
  [K in keyof DocumentEventMap as string]: SCEvent;
};

export class ScrollControl {
  private touch: TouchList | null = null;
  private state: SCState = { disabled: false, locked: false, tracked: false };
  private options: SCOptions = { target: undefined, className: 'frozen' };

  private body: SCLocks = {};
  private target: SCLocks = {};
  private targetIOS: SCLocks = {};

  private locks: SCEvents = {};
  private trackers: SCEvents = {};

  constructor(options?: SCOptions) {
    this.options = Object.assign(this.options, options);

    this.locks = {
      touchmove: {
        callback: this.handleTouchMove,
        options: IS_PASSIVE_SUPPORTED ? { passive: false } : false
      }
    };

    this.trackers = {
      touchstart: {
        callback: this.handleTouch
      },
      touchmove: {
        callback: this.handleScroll
      }
    };
  }

  handleTouch = (event: TouchEvent): void => {
    const target = event.currentTarget;

    if (target && Touch.isSingle(event)) {
      this.touch = event.targetTouches;

      const unlisten = listenIt(target, 'touchend', this.handleTouchEnd);

      if (unlisten) {
        this.targetIOS.touchend = unlisten;
      }
    }

    return void 0;
  };

  handleTouchEnd = (): void => {
    this.targetIOS = unListenItForEach(this.targetIOS);

    this.touch = null;
  };

  handleTouchMove = (event: TouchEvent): void | boolean => {
    if (!Touch.isSingle(event)) {
      return true;
    }

    if (event.cancelable) {
      return prevent(event);
    }

    return void 0;
  };

  handleScroll = (event: TouchEvent): void | boolean => {
    if (this.touch && Touch.isSingle(event)) {
      const { currentTarget: target } = event;

      const [prevTouch] = this.touch;
      const [nextTouch] = event.targetTouches;

      const delta = nextTouch.clientY - prevTouch.clientY;

      if (isScrollAtTop(target) && delta > 0) {
        return prevent(event);
      }

      if (isScrollAtBottom(target) && delta < 0) {
        return prevent(event);
      }

      return stop(event);
    }

    return void 0;
  };

  enableScroll(): void {
    const { disabled, locked, tracked } = this.state;
    const { className } = this.options;

    if (!disabled) {
      return void 0;
    }

    if (locked) {
      this.body = unListenItForEach(this.body);

      this.state.locked = false;
    }

    if (tracked) {
      this.target = unListenItForEach(this.target);

      this.state.tracked = false;
    }

    if (className) {
      document.body.classList.remove(className);
    }

    this.state.disabled = false;

    return void 0;
  }

  disableScrollOnIOS(ref: EventTarget): void {
    const locks = this.locks;

    Object.keys(locks).forEach(key => {
      const { callback, options } = locks[key];

      const unlisten = listenIt(window, key as keyof DocumentEventMap, callback as any, options);

      if (unlisten) {
        this.body[key] = unlisten;
      }
    });

    this.state.locked = true;

    if (hasScroll(ref)) {
      const trackers = this.trackers;

      Object.keys(this.trackers).forEach(key => {
        const { callback, options } = trackers[key];

        const unlisten = listenIt(ref, key as keyof DocumentEventMap, callback as any, options);

        if (unlisten) {
          this.target[key] = unlisten;
        }
      });

      this.state.tracked = true;
    }

    return void 0;
  }

  disableScroll(ref?: EventTarget): void {
    const { disabled } = this.state;

    if (disabled) {
      return void 0;
    }

    const element = ref ?? this.options.target;

    if (DEVICE.IOS() && element) {
      PromiseShim.queueMicrotask(() => {
        this.disableScrollOnIOS(element);
      });
    } else {
      const { className } = this.options;

      if (className) {
        document.body.classList.add(className);
      }
    }

    this.state.disabled = true;

    return void 0;
  }
}
