import React from 'react';
import { useAsyncButton } from '~/components/button';
import { Icon } from '~/components/icon';
import { SVGShadowFilter } from '~/components/icon/filters/shadow';
import { File, Props as FileProps } from '~/components/_inputs/file';
import { ImageWithOverlay } from '~/components/image';
import { system } from '~/api/system';
import { CustomError } from '~/helpers/common/custom-error';
import { validateImage } from './helpers/validate-image';

import styles from './image.module.scss';

export type ImageFileURL = string | null;

type OwnProps = {
  cb: (fileUrl: ImageFileURL) => void;
  src?: string;
};

const IMAGE_FORMATS = '.png, .jpg, .jpeg';

export const ImageInput = React.memo(({ cb, src, ...props }: OwnProps & FileProps): React.JSX.Element => {
  const { loading, setLoading, setReady, ready, Button: FakeAsyncButton } = useAsyncButton();

  const handleFile = async (files: FileList, fileName: string): Promise<void> => {
    setLoading();

    try {
      await validateImage(files);

      const { signedUrl, fileUrl } = await system.getSignedUrl({ name: fileName });
      await system.uploadImage({ signedUrl, file: files[0] });

      if (cb) {
        cb(fileUrl);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      console.error(error.message);
    } finally {
      await setReady();
    }
  };

  const removeFile = async (): Promise<void> => {
    setLoading();

    try {
      if (cb) {
        cb(null);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      console.error(error.message);
    } finally {
      await setReady();
    }
  };

  return (
    <File {...props} accept={IMAGE_FORMATS} onFile={handleFile} onRemoveFile={removeFile} isImageExists={!!src}>
      {ready && src ? (
        <ImageWithOverlay src={src} alt='image' />
      ) : (
        <FakeAsyncButton as='div' is='minor' loading={loading} ready={ready} className={styles.button}>
          <Icon name='image' filter='url(#shadow)' className={styles.icon}>
            <SVGShadowFilter id='shadow' />
          </Icon>
        </FakeAsyncButton>
      )}
    </File>
  );
});
