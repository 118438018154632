import React, { memo, ReactNode } from 'react';
import { Heading } from '~/components/_layout/typography/heading';
import styles from './title.module.scss';

const Title = ({ children }: { children: ReactNode }): React.JSX.Element => (
  <Heading size='m' className={styles.title}>
    {children}
  </Heading>
);

export default memo(Title);
