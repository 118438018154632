import React from 'react';
import classnames from 'classnames';
import { HeaderProps, TableDataLike } from 'react-table';
import { Checkbox } from '~/components/_inputs/checkbox';
import { ControlledFormLabel as CheckboxLabel } from '~/components/_form/components/label';
import { Label } from '~/components/_layout/typography/label';

import styles from './checkbox-heading.module.scss';

export const CheckboxHeading = <Data extends TableDataLike>({
  getToggleAllRowsSelectedProps
}: HeaderProps<Data>): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Label>Select</Label>
      </div>
      <div className={classnames(styles.row, styles.input)}>
        <CheckboxLabel id='_selector' label='Check all' size='xs' placement='right'>
          <Checkbox id='_selector' {...getToggleAllRowsSelectedProps()} />
        </CheckboxLabel>
      </div>
    </div>
  );
};
