import { createAction, createReducer } from '@reduxjs/toolkit';

type State = { isAppReady: boolean };

export const initialState = {
  isAppReady: false
};

const PREFIX = 'meta';

export const setIsAppReady = createAction(`${PREFIX}/setIsAppReady`, (isAppReady: boolean) => ({
  payload: {
    isAppReady
  }
}));

export const meta = createReducer<State>(initialState, builder =>
  builder.addCase(setIsAppReady, (state, action) => ({
    ...state,
    isAppReady: action.payload.isAppReady
  }))
);
