import React from 'react';
import classnames from 'classnames';
import { Panel as GeneralPanel } from '~/components/panel';
import { Heading } from '../typography/heading';

import styles from './sidebar-panel.module.scss';

type SidebarPanelProps = React.PropsWithChildren<{
  title?: string;
  className?: string;
}>;

const Panel = ({ title, className, children }: SidebarPanelProps): React.JSX.Element => {
  return (
    <GeneralPanel className={classnames(styles.container, className)}>
      {title && (
        <Heading className={styles.title} size='xs'>
          {title}
        </Heading>
      )}
      {children}
    </GeneralPanel>
  );
};

type SidebarPanelListProps = React.PropsWithChildren<{
  className?: string;
}>;

const SidebarPanelList = ({ className, ...props }: SidebarPanelListProps): React.JSX.Element => {
  return <ul className={classnames(styles.list, className)} {...props} />;
};

type SidebarPanelListItemProps = React.PropsWithChildren<{
  className?: string;
  withoutBorder?: boolean;
  row?: boolean;
}>;

const SidebarPanelListItem = ({
  className,
  withoutBorder,
  row = false,
  ...props
}: SidebarPanelListItemProps): React.JSX.Element => {
  return (
    <li
      className={classnames(styles.item, { [styles['without-border']]: withoutBorder, [styles.row]: row }, className)}
      {...props}
    />
  );
};

type UseSidebarPanel = {
  Panel: typeof Panel;
  List: typeof SidebarPanelList;
  ListItem: typeof SidebarPanelListItem;
};

export const useSidebarPanel = (): UseSidebarPanel => {
  return {
    Panel,
    List: SidebarPanelList,
    ListItem: SidebarPanelListItem
  };
};
