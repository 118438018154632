import { useEffect, useRef } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const useBugsnagInitialization = (): typeof Bugsnag => {
  const bugsnagClientRef = useRef<typeof Bugsnag>(Bugsnag);

  useEffect(() => {
    if (!process.env.REACT_APP_BUGSNAG_API_KEY || process.env.REACT_APP_BUGSNAG_API_KEY === 'false') {
      console.error('BugSnag API key is missing. Please provide a valid API key.');
      return;
    }

    if (!bugsnagClientRef.current.isStarted()) {
      bugsnagClientRef.current.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        releaseStage: process.env.REACT_APP_RELEASE_STAGE,
        plugins: [new BugsnagPluginReact()],
        onError: event => {
          event.setUser(localStorage.getItem('token') ? 'Registered User' : 'Shadow User');
        }
      });
    }
  }, []);

  return bugsnagClientRef.current;
};

const useBugsnag = (): ((error: string) => void) => {
  const bugsnagClient = useBugsnagInitialization();

  return (error: string): void => {
    if (bugsnagClient.isStarted()) {
      bugsnagClient.notify(new Error(error));
    }
  };
};

export default useBugsnag;
