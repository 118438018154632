import React from 'react';
import { Switch } from '~/components/_inputs/switch';
import { FormLabel } from '~/components/_form/components/label';

type Props = {
  nofollow: boolean;
  onChangeNoFollow: (nofollow: boolean) => void;
};

const NoFollow = ({ nofollow, onChangeNoFollow }: Props): React.JSX.Element => (
  <FormLabel label='No search engine follow' placement='right' size='xs'>
    <Switch
      id='nofollow-switch'
      checked={nofollow}
      onChange={() => {
        onChangeNoFollow(!nofollow);
      }}
    />
  </FormLabel>
);

export default NoFollow;
