import { useCallback, useMemo } from 'react';

import { useTheme } from '~/components/theme';
import { AffiliateInfo, MaintenanceStatus, MaintenanceStatusEnum } from '~/types/gists/affiliates';
import { api } from '~/api';

type ReturnType = {
  maintenance: AffiliateInfo['maintenance'];
  maintenanceStatus: AffiliateInfo['maintenanceStatus'];
  toggleMaintenance: () => Promise<void>;
  isTogglingAvailable: boolean;
  isTransition: boolean;
};

const availableMaintenanceStatusesToToggle: MaintenanceStatus[] = [
  null,
  MaintenanceStatusEnum.Failed,
  MaintenanceStatusEnum.Completed
];
const transitionMaintenanceStatuses: MaintenanceStatus[] = [
  MaintenanceStatusEnum.TurningOn,
  MaintenanceStatusEnum.TurningOff
];

const useMaintenanceMode = (): ReturnType => {
  const { affiliateDetails, setAffiliateDetails } = useTheme();

  const toggleMaintenance = useCallback(async () => {
    if (affiliateDetails) {
      const updatedAffiliate = await api.affiliates.putCurrent({
        ...affiliateDetails,
        maintenance: !affiliateDetails.maintenance
      });

      setAffiliateDetails(updatedAffiliate);
    }
  }, [affiliateDetails, setAffiliateDetails]);

  const isTogglingAvailable = useMemo(
    () =>
      affiliateDetails ? availableMaintenanceStatusesToToggle.includes(affiliateDetails.maintenanceStatus) : false,
    [affiliateDetails]
  );

  const isTransition = useMemo(
    () => (affiliateDetails ? transitionMaintenanceStatuses.includes(affiliateDetails.maintenanceStatus) : false),
    [affiliateDetails]
  );

  return {
    maintenance: affiliateDetails?.maintenance ?? false,
    maintenanceStatus: affiliateDetails?.maintenanceStatus ?? null,
    toggleMaintenance,
    isTogglingAvailable,
    isTransition
  };
};

export default useMaintenanceMode;
