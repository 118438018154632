import { useCallback, useMemo, useState } from 'react';

import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { Dataset } from '~/types/gists/conditions';
import useMountEffect from '~/hooks/use-mount-effect';
import { BUILDER_OPERATORS } from '~/pages/cohorts/edit/ConditionBuilder/constants';

type UseDatasetsController = {
  currentDatasetsList: Dataset[];
  goToNextDataset: (nextValue: string, closeSelect: () => void) => void;
  goToPrevDataset: (() => void) | undefined;
  resultString: string;
  selectedDataset?: Dataset;
  currentDatasetsListTitle: string;
};

const getDatasetByCustomField = (datasets, field: string, value: string): Dataset => {
  const result = datasets.find(dataset => dataset[field] === value);

  if (result) {
    return result;
  }

  return datasets
    .map(dataset => {
      if (dataset.children) {
        return getDatasetByCustomField(dataset.children.data, field, value);
      }

      return undefined;
    })
    .filter(Boolean)
    .shift();
};

const DEFAULT_TITLE = 'Datasets';

const appendResultString = (resultString: string, newResult: string): string => [resultString, newResult].join('.');
const getPrevResultString = (resultString: string): string => resultString.split('.').slice(0, -1).join('.');

const useDatasetsController = (id: string): UseDatasetsController => {
  const {
    datasets,
    setRuleObjectById,
    setRuleTypeById,
    rules,
    setIsError,
    setRuleOperatorById,
    setRuleComputableById,
    setRuleOptionsById,
    setRuleValueById
  } = useConditionBuilderContext();

  useMountEffect(() => {
    const ruleObject = rules[id].object;

    if (ruleObject) {
      const lastObjectValue = ruleObject.split('.').slice(-1).pop();
      if (lastObjectValue) {
        const lastSelectedDataset = getDatasetByCustomField(datasets, 'value', lastObjectValue);

        if (lastSelectedDataset && lastSelectedDataset.parentId) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { data: datasetsList, title: datasetsListTitle } = getDatasetByCustomField(
            datasets,
            'id',
            lastSelectedDataset.parentId
          ).children!;

          setSelectedDataset(lastSelectedDataset);
          setCurrentDatasetsList(datasetsList);
          setCurrentDatasetsListTitle(datasetsListTitle);
          setResultString(ruleObject);
          setRuleTypeById(id, lastSelectedDataset.type);
          setRuleComputableById(id, lastSelectedDataset.computable);
          setRuleOperatorById(id, rules[id].operator);
          if (lastSelectedDataset.options) {
            setRuleOptionsById(id, lastSelectedDataset.options);
          }
        } else {
          setIsError(true);
        }
      }
    }
  });

  const [currentDatasetsList, setCurrentDatasetsList] = useState<Dataset[]>(datasets);
  const [currentDatasetsListTitle, setCurrentDatasetsListTitle] = useState<string>(DEFAULT_TITLE);

  const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>();
  const [resultString, setResultString] = useState<string>('');

  const goToNextDataset = useCallback(
    (nextValue: string, closeSelect: () => void) => {
      const nextDataset = getDatasetByCustomField(currentDatasetsList, 'value', nextValue);
      setSelectedDataset(nextDataset);

      if (nextDataset.children) {
        const { data: nextDatasetList, title } = nextDataset.children;

        setTimeout(() => {
          setCurrentDatasetsList(() => nextDatasetList);
          setCurrentDatasetsListTitle(() => title);
          setResultString(resultString ? appendResultString(resultString, nextValue) : nextValue);
        }, 0);
      } else {
        const newResultString = appendResultString(
          rules[id].object ? getPrevResultString(resultString) : resultString,
          nextValue
        );
        setRuleObjectById(id, newResultString);
        setResultString(newResultString);
        setRuleTypeById(id, nextDataset.type);
        setRuleOperatorById(id, BUILDER_OPERATORS.EQ);
        setRuleComputableById(id, nextDataset.computable);
        setRuleValueById(id, '');
        setRuleOptionsById(id, nextDataset.options);
        closeSelect();
      }
    },
    [
      currentDatasetsList,
      resultString,
      rules,
      id,
      setRuleObjectById,
      setRuleTypeById,
      setRuleOperatorById,
      setRuleComputableById,
      setRuleValueById,
      setRuleOptionsById
    ]
  );

  const resetDatasetController = useCallback(() => {
    setRuleObjectById(id, '');
    setRuleTypeById(id, null);
    setRuleOptionsById(id, []);
    setResultString('');
  }, [id, setRuleOptionsById, setRuleObjectById, setRuleTypeById]);

  const goToRootDataset = useCallback(() => {
    setCurrentDatasetsList(datasets);
    setSelectedDataset(undefined);
    setCurrentDatasetsListTitle(DEFAULT_TITLE);
    resetDatasetController();
  }, [datasets, resetDatasetController]);

  const goToPrevDataset = useMemo(() => {
    if (selectedDataset) {
      return () => {
        if (selectedDataset.parentId) {
          const parentOfSelectedDataset = getDatasetByCustomField(datasets, 'id', selectedDataset.parentId);

          if (selectedDataset.children) {
            const { data, title } = parentOfSelectedDataset.children as { data: Array<Dataset>; title: string };
            setSelectedDataset(parentOfSelectedDataset);
            setCurrentDatasetsList(data);
            setCurrentDatasetsListTitle(title);
            setResultString(getPrevResultString(resultString));
            resetDatasetController();
          } else {
            if (parentOfSelectedDataset.parentId) {
              const parentOfParentDataset = getDatasetByCustomField(datasets, 'id', parentOfSelectedDataset.parentId);
              const { data, title } = parentOfParentDataset.children as { data: Array<Dataset>; title: string };
              setSelectedDataset(parentOfParentDataset);
              setCurrentDatasetsList(data);
              setCurrentDatasetsListTitle(title);
              setResultString(getPrevResultString(resultString));
              resetDatasetController();
            } else {
              goToRootDataset();
            }
          }
        } else {
          goToRootDataset();
        }
      };
    }

    return undefined;
  }, [selectedDataset, datasets, resultString, resetDatasetController, goToRootDataset]);

  return {
    currentDatasetsList,
    goToNextDataset,
    goToPrevDataset,
    resultString,
    currentDatasetsListTitle,
    selectedDataset
  };
};

export default useDatasetsController;
