import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';
import { Icon } from './icon';

import styles from './tickbox.module.scss';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'readOnly' | 'onChange'>;

export const Tickbox = ({ id, checked = false }: Props): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <div className={classnames(styles.container, { [styles.checked]: checked }, styles[theme])}>
      <input id={id} type='checkbox' checked={checked} className={styles.input} readOnly />
      <label htmlFor={id} className={styles.label}>
        <Icon className={styles.icon} />
      </label>
    </div>
  );
};
