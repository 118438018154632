import queryString from 'query-string';
import React, { ComponentType, memo, useEffect, useState } from 'react';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { UserService } from '~/services/user-service';
import { affiliates as affiliatesApi } from '~/api/affiliates';
import { user as userApi } from '~/api/user';
import { baseApiUrls, getEnvFromUrl } from '~/api/utils';
import { useTheme } from '~/components/theme';
import { useCoachPopover } from '../_layout/coach/coach-popover';
import { Button } from '~/components/button';
import { groupAffiliatesByEnvironment } from '~/components/private-route/helpers';
import { arePropsEqual } from '~/helpers/common/are-props-equal';
import { AffiliateInfo } from '~/types/gists/affiliates';
import { setIsAppReady } from '~/store/reducers/meta';
import { useDispatch, useSelector } from '~/store/hooks';

const LOADING_MESSAGE = ['Optimising your environments…'];

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

export const PrivateRoute = memo((props: Props): React.JSX.Element => {
  const { setAffiliate, setAffiliateDetails } = useTheme();
  const dispatch = useDispatch();

  const { component: Component, ...rest } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const isAppReady = useSelector(state => state.meta.isAppReady);

  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error });
  const { CoachPopover: LoadingPopover, ...loadingProps } = useCoachPopover({
    loading: isLoading,
    timeout: 1000,
    disabled: !UserService.isAuthenticated() || UserService.isAffiliatesFetched()
  });

  useEffect(() => {
    (async () => {
      if (UserService.isAuthenticated() && !UserService.isAffiliatesFetched()) {
        try {
          setError('');
          setIsLoading(true);

          UserService.decodeToken();

          const affiliates = await Promise.all(
            Object.keys(baseApiUrls).map(env => affiliatesApi.fetchAllByEnv(env as Environment))
          );

          const groupedAffiliatesByEnv = groupAffiliatesByEnvironment(affiliates);
          UserService.setAffiliates(groupedAffiliatesByEnv);
          const firstAffiliate = Object.values(groupedAffiliatesByEnv)[0];

          const { affiliate: affiliateFromURL } = queryString.parse(window.location.search);
          const envFromUrl = getEnvFromUrl();
          const isExistedAffiliateFromURL = Object.values(groupedAffiliatesByEnv).find(
            ({ affiliateInfo, environments }) =>
              affiliateInfo.key === affiliateFromURL && environments.includes(envFromUrl)
          );

          setAffiliate(
            isExistedAffiliateFromURL ? (affiliateFromURL as AffiliateKey) : firstAffiliate?.affiliateInfo.key,
            isExistedAffiliateFromURL ? envFromUrl : firstAffiliate?.environments[0]
          );

          const [currentAffiliate, functionalPermissions] = await Promise.all([
            affiliatesApi.getCurrent(),
            userApi.getUserFunctionalPermissions()
          ]);
          UserService.setFunctionalPermissions(functionalPermissions);
          setAffiliateDetails(currentAffiliate);
          dispatch(setIsAppReady(true));
        } catch {
          setError('Something went wrong with fetching affiliates.');
          UserService.setAffiliates({});
          UserService.resetFunctionalPermissions();
          setAffiliateDetails({} as AffiliateInfo);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [dispatch, setAffiliate, setAffiliateDetails]);

  if (!UserService.isAuthenticated()) {
    return (
      <Redirect
        to={{
          pathname: ONBOARDING_ROUTES.greeting
        }}
      />
    );
  }

  return (
    <>
      <LoadingPopover message={LOADING_MESSAGE} {...loadingProps} />
      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
      {isAppReady && <Route {...rest} render={routeProps => <Component {...routeProps} />} />}
    </>
  );
}, arePropsEqual);

PrivateRoute.displayName = 'PrivateRoute';
