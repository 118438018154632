import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CustomError } from '~/helpers/common/custom-error';

import useDocumentTitle from '~/hooks/use-document-title';
import { OnboardingTemplate } from '~/templates/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { api } from '~/api';
import { setUser } from '~/store/reducers/account';
import { UserService } from '~/services/user-service';
import { useDispatch } from '~/store/hooks';
import { TextField } from '~/components/_form/fields/text';
import { ButtonField } from '~/components/_form/fields/button';
import { codeValidationSchema } from '~/pages/onboarding/mfa/schemas';

type FormikValues = {
  code: string;
};

const initialValues: FormikValues = {
  code: ''
};

const SmsMfa = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ code }: FormikValues) => {
      try {
        const email = localStorage.getItem('email') as string;
        const session = localStorage.getItem('session') as string;

        if (email && session) {
          const response = await api.onboarding.verifyMFA({
            email,
            session,
            code
          });

          dispatch(setUser(response));

          localStorage.removeItem('email');
          localStorage.removeItem('password');

          localStorage.setItem('token', response.token);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('email', response.email);
          localStorage.setItem('accessToken', response.accessToken);

          UserService.decodeToken(response.token);

          history.push(ONBOARDING_ROUTES.signInLoggingIn);
        } else {
          history.push(ONBOARDING_ROUTES.signInEmail);
        }
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        if (error.message === 'User is not in admin group' || error.message === 'User has no permissions') {
          history.push(ONBOARDING_ROUTES.setupPermissions);
          return;
        }

        history.push(ONBOARDING_ROUTES.error);
      }
    },
    [dispatch, history]
  );

  const onNewCode = useCallback(
    (
        setFieldError: (field: string, message: string) => void,
        setFieldTouched: (field: string, isTouched: boolean) => void
      ) =>
      async () => {
        setFieldTouched('code', true);

        try {
          const email = localStorage.getItem('email') as string;
          const password = localStorage.getItem('password') as string;

          if (email && password) {
            const response = await api.onboarding.resendMFA({
              email,
              password
            });

            localStorage.removeItem('session');
            localStorage.setItem('session', response.session);

            history.push(ONBOARDING_ROUTES.newCodeSent);
          } else {
            history.push(ONBOARDING_ROUTES.signInEmail);
          }
        } catch (unknownError) {
          const error = new CustomError(unknownError);
          setFieldError('code', error.message);
        }
      },
    [history]
  );

  return (
    <OnboardingTemplate message={['I’ve just sent a security code to your phone.', 'Enter it here to log in.']}>
      <Formik validationSchema={codeValidationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, dirty, isSubmitting, setFieldError, setFieldTouched }) => (
          <Form>
            <TextField name='code' label='Security code' />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
            <ButtonField is='minor' onClick={onNewCode(setFieldError, setFieldTouched)} type='button'>
              Send me another code
            </ButtonField>
            <ButtonField is='minor' to={ONBOARDING_ROUTES.contactTeam}>
              I can’t access my phone anymore
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};

export default SmsMfa;
