import React, { ElementType, forwardRef, PropsWithRef, HTMLAttributes } from 'react';
import classnames from 'classnames';
import type { PolymorphicComponentProps } from 'react-polymorphic-box';
import { Box } from 'react-polymorphic-box';

import styles from './text.module.scss';

export type OwnProps = HTMLAttributes<HTMLSpanElement | HTMLParagraphElement | HTMLHeadingElement> &
  PropsWithRef<{
    inherit?: boolean;
    truncated?: boolean;
    className?: string;
    children?: React.ReactNode;
  }>;

type Props = PolymorphicComponentProps<ElementType, OwnProps>;

const defaultElement = 'span';

const TextNodeComponent = (
  { truncated, inherit, children, className, as, ...props }: Props,
  ref?: typeof props.ref
): React.JSX.Element => {
  return (
    <Box
      as={as ?? defaultElement}
      ref={ref}
      className={classnames(styles.container, { [styles.inherit]: inherit, [styles.truncated]: truncated }, className)}
      {...props}
    >
      {children}
    </Box>
  );
};

export const TextNode = forwardRef(TextNodeComponent);
