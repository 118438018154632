import React from 'react';
import { Switch } from '~/components/_inputs/switch';
import { FormLabel } from '~/components/_form/components/label';

type Props = {
  checked: boolean;
  onChange: HandleChange;
};

const HistoricalData = ({ checked, onChange }: Props): React.JSX.Element => (
  <FormLabel label='Historical data' placement='right' size='xs'>
    <Switch id='historical-data' checked={checked} onChange={onChange} name='isSaveHistoricalData' />
  </FormLabel>
);

export default HistoricalData;
