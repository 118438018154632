import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import type { Options } from '@popperjs/core';
import { Popover, usePopover, UsePopover } from '~/components/popover';
import { Portal } from '~/components/portal';
import { useTheme } from '~/components/theme';
import { Label } from '~/components/_layout/typography/label';

import styles from './tooltip-popover.module.scss';

export type Tooltip = React.ReactNode;

type PopoverProps = Pick<UsePopover, 'id' | 'show' | 'toggle' | 'onPopoverRef' | 'onTriggerRef'>;

const POPPER: Partial<Options> = {
  placement: 'bottom-start',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start']
      }
    }
  ]
};

const Title = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
  return <Label size='xs'>{children}</Label>;
};

type Props = {
  id: string;
  show: boolean;
  onPopoverRef: React.RefCallback<HTMLElement>;
  onTriggerRef: React.RefCallback<HTMLElement>;
  title: Tooltip;
  titleAs?: React.ElementType;
  onClickOutside?: CallableFunction;
  children: (ref: React.RefCallback<HTMLElement>) => React.JSX.Element;
};

const TooltipComponent = ({
  id,
  show,
  title,
  titleAs: Component = Title,
  children,
  onPopoverRef,
  onTriggerRef,
  onClickOutside
}: Props): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <>
      {children(onTriggerRef)}
      <Portal id='popover'>
        <div ref={onPopoverRef} style={{ zIndex: 2001 }}>
          <Popover
            role='tooltip'
            id={id}
            className={classnames(styles.container, styles[theme])}
            hidden={!show}
            onClickOutside={onClickOutside}
          >
            <Component>{title}</Component>
          </Popover>
        </div>
      </Portal>
    </>
  );
};

type UseTooltip = PopoverProps & {
  Tooltip: typeof TooltipComponent;
};

export const useTooltip = (): UseTooltip => {
  const { id, show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, POPPER);

  return {
    id,
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    Tooltip: TooltipComponent
  };
};
