export type DecodedTokenType = {
  aud: string;
  auth_time: number;
  'cognito:groups': Array<string>;
  'cognito:username': string;
  'custom:devEnvAccessConf': string;
  'custom:prodEnvAccessConf': string;
  'custom:testEnvAccessConf': string;
  email: string;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  sub: string;
  token_use: string;
};

export enum MODULE_ACCESS {
  View = 'view',
  Edit = 'edit',
  None = 'none'
}

export enum FUNCTIONAL_PERMISSIONS_KEY {
  Cohorts = 'cohorts',
  Datasets = 'datasets',
  Files = 'files',
  Push = 'push',
  Tags = 'tags',
  Settings = 'settings',
  Blocks = 'blocks',
  Pages = 'pages'
}

export type FunctionalModulePermission = {
  name: FUNCTIONAL_PERMISSIONS_KEY;
  access: MODULE_ACCESS;
  action: string[] | '*';
};

export type FunctionalPermissions = Record<FUNCTIONAL_PERMISSIONS_KEY, FunctionalModulePermission>;
