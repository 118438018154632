import React from 'react';
import { ReactSVG } from 'react-svg';

import styles from './icon-thumb.module.scss';
import useFileDetailsOverlay from '~/components/_table/cells/file-primary/hooks/useFileDetailsOverlay';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';

const IconThumb = () => {
  const { FileDetailsOverlay, openFileDetailsOverlay } = useFileDetailsOverlay();

  const { cellProps } = useFilePrimaryCellContext();

  const {
    row: {
      original: { s3ObjectUrl, updatedAt }
    }
  } = cellProps;

  return (
    <>
      <FileDetailsOverlay />
      <ReactSVG
        src={`${s3ObjectUrl}?updatedAt=${updatedAt}`}
        className={styles.container}
        onClick={openFileDetailsOverlay}
      />
    </>
  );
};

export default IconThumb;
