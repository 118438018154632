import classnames from 'classnames';
import React, { useCallback } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { encodeQueryParams } from '~/api/utils';
import { Heading } from '~/components/_layout/typography/heading';
import { Label } from '~/components/_layout/typography/label';
import { TableApi } from '~/components/_table/types';
import { Column, Grid } from '~/components/grid';
import { FeatureIcon, FeatureIcons } from '~/components/icon';
import { ObjectShim } from '~/helpers/shims/object-shim';
import { SVGGlowFilter } from '~/components/icon/filters/glow';
import { useTheme } from '~/components/theme';
import { TableStorageService } from '~/services/table-service';
import styles from './main-menu-categories.module.scss';
import { TAGS_ROUTE } from '~/routes/private/tags/constants';
import { INSIGHTS_ROUTE } from '~/routes/private/insights/constants';
import { AffiliateInfo } from '~/types/gists/affiliates';
import { PUSH_NOTIFICATIONS_ROUTES } from '~/routes/private/push-notifications/constants';
import { COHORTS_ROUTES } from '~/routes/private/cohorts/constants';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';
import { FILES_ROUTES } from '~/routes/private/files/constants';
import { UserService } from '~/services/user-service';
import { FUNCTIONAL_PERMISSIONS_KEY } from '~/types/gists/user';
import { SETTINGS_ROUTE } from '~/routes/private/settings/constants';
import { BLOCKS_ROUTES } from '~/routes/private/blocks/constants';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';

const COLS: Section[] = [
  {
    name: 'Content',
    key: 'content'
  },
  {
    name: 'Appearance',
    key: 'appearance'
  },
  {
    name: 'Marketing',
    key: 'marketing'
  },
  {
    name: 'Advanced',
    key: 'advanced'
  }
];

const ROWS: FeatureItem[] = [
  {
    content: {
      name: 'Files',
      icon: 'files',
      gist: 'images',
      to: FILES_ROUTES.IMAGES,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Files)
    },
    appearance: {
      name: 'Blocks',
      icon: 'blocks',
      to: BLOCKS_ROUTES.root,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Blocks)
    },
    marketing: {
      name: 'Cohorts',
      icon: 'cohorts',
      gist: 'cohorts',
      to: COHORTS_ROUTES.root,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Cohorts)
    },
    advanced: {
      name: 'Insights',
      icon: 'insights',
      to: INSIGHTS_ROUTE,
      renderCondition: currentAffiliate =>
        Boolean(currentAffiliate && !ObjectShim.isEmpty(currentAffiliate.embedDashboardParams))
    }
  },
  {
    content: {
      name: 'Pages',
      icon: 'pages',
      to: PAGES_ROUTES.root,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Pages)
    },
    marketing: {
      name: 'Push',
      icon: 'scheduled-messages',
      gist: 'pushNotifications',
      to: PUSH_NOTIFICATIONS_ROUTES.root,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Push)
    },
    advanced: {
      name: 'Tags',
      icon: 'tags',
      to: TAGS_ROUTE,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Tags)
    }
  },
  {
    advanced: {
      name: 'Datasets',
      icon: 'datasets',
      to: DATASETS_ROUTES.root,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Datasets)
    }
  },
  {
    advanced: {
      name: 'Settings',
      icon: 'settings',
      to: SETTINGS_ROUTE,
      renderCondition: () => UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Settings)
    }
  }
];

type FeatureItem = Partial<Record<Section['key'], FeatureProps>>;

type FeatureProps = {
  name: string;
  icon: keyof FeatureIcons;
  gist?: TableApi;
  to: LinkProps['to'];
  renderCondition?: (currentAffiliate: AffiliateInfo) => boolean;
};

export const Feature = ({ to, icon, name, renderCondition, gist }: FeatureProps): React.JSX.Element | null => {
  const { theme, env, affiliate, affiliateDetails } = useTheme();

  const filters = JSON.stringify(gist && TableStorageService.getFilters({ affiliate, env, gist }));

  const path = encodeQueryParams(to as string, { affiliate, env, filters });

  const closeMenu = useCallback(() => {
    const closeMenuEvent = new Event('closeMenu');
    document.dispatchEvent(closeMenuEvent);
  }, []);

  if (renderCondition && !renderCondition(affiliateDetails as AffiliateInfo)) {
    return null;
  }

  return (
    <Link to={path} className={classnames(styles.link, styles[theme])} onClick={closeMenu}>
      <FeatureIcon name={icon} className={styles.icon}>
        <SVGGlowFilter id='glow' />
      </FeatureIcon>
      <Label size='m'>{name}</Label>
    </Link>
  );
};

const GRID_STYLES = {
  '--app-custom-grid-cols': COLS.length
};

type Section = { name: string; key: 'content' | 'appearance' | 'marketing' | 'advanced' };

export const CategoriesSection = (): React.JSX.Element => {
  return (
    <section className={styles.container}>
      <Grid style={GRID_STYLES}>
        {COLS.map(({ name, key }, index) => {
          return (
            <Column key={key} span={index > 0 ? `${index + 1}+1` : '1'} className={styles.section}>
              <div className={styles.heading}>
                <Heading size='s'>{name}</Heading>
              </div>
            </Column>
          );
        })}
      </Grid>
      <Grid style={GRID_STYLES} className={styles.features}>
        {ROWS.map((row, rIndex) => {
          return (
            <React.Fragment key={rIndex}>
              {COLS.map(({ key }, cIndex) => {
                const feature = row[key];

                return (
                  <Column key={key + rIndex} span={cIndex > 0 ? `${cIndex + 1}+1` : '1'} className={styles.feature}>
                    {feature && <Feature {...feature} />}
                  </Column>
                );
              })}
            </React.Fragment>
          );
        })}
      </Grid>
    </section>
  );
};
