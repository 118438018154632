import React from 'react';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import styles from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/styles.module.scss';
import { Icon } from '~/components/icon';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

type Props = {
  ruleId: string;
  rulesetId: string;
};

const DeleteRule = ({ ruleId, rulesetId }: Props): React.JSX.Element => {
  const { deleteRuleById, isLastRuleInRuleset, deleteRulesetById } = useConditionBuilderContext();

  const onClick = () =>
    isLastRuleInRuleset(rulesetId) ? deleteRulesetById(rulesetId) : deleteRuleById(ruleId, rulesetId);

  return (
    <TooltipOnHover title='Delete rule'>
      <div className={styles['rule-action']} onClick={onClick}>
        <Icon name='bin' />
      </div>
    </TooltipOnHover>
  );
};

export default DeleteRule;
