import type { Column } from 'react-table';
import { PushNotificationHistory } from '~/types/gists/push-notification';
import { formatDate, formatPercent, toFixed } from '~/helpers/formatters';

export const columns: Column<PushNotificationHistory>[] = [
  {
    title: 'Send time',
    accessor: 'createdAt',
    format: formatDate
  },
  {
    title: 'Cohort Size',
    id: 'cohortSize',
    accessor: value => value.cohortSize ?? 0
  },
  {
    id: 'enabledPercent',
    accessor: 'enabledPercent',
    format: formatPercent(1),
    title: '>',
    minWidth: 54,
    width: 54
  },
  {
    title: 'Push enabled',
    id: 'usersInCohortWithTokens',
    accessor: value => value.usersInCohortWithTokens ?? '-'
  },
  {
    title: '>',
    accessor: 'sentPercent',
    format: formatPercent(1),
    minWidth: 54,
    width: 54
  },
  {
    title: 'Sent',
    id: 'sent',
    accessor: 'sent',
    format: toFixed(0)
  }
  /* Has been disabled due to postponed BE tickets. */
  // {
  //   id: 'eligiblePercent',
  //   accessor: 'eligiblePercent',
  //   title: '>',
  //   format: formatPercent(1),
  //   minWidth: 54,
  //   width: 54
  // },
  // {
  //   title: 'Eligible',
  //   accessor: 'total'
  // },
  // {
  //   title: '>',
  //   accessor: 'receivedPercent',
  //   format: formatPercent(1),
  //   minWidth: 54,
  //   width: 54
  // },
  // {
  //   title: 'Received',
  //   id: 'received',
  //   accessor: 'received',
  //   format: toFixed(0)
  // },
  // {
  //   title: '>',
  //   accessor: 'impressionsPercent',
  //   format: formatPercent(1),
  //   minWidth: 54,
  //   width: 54
  // },
  // {
  //   title: 'Impressions',
  //   id: 'impressions',
  //   accessor: 'impressions',
  //   format: toFixed(0)
  // },
  // {
  //   title: '>',
  //   accessor: 'openCountPercent',
  //   format: formatPercent(1),
  //   minWidth: 54,
  //   width: 54
  // },
  // {
  //   title: 'Open count',
  //   id: 'openCount',
  //   accessor: 'openCount',
  //   format: toFixed(0)
  // }
];
