import React, { useCallback, useMemo, useState } from 'react';
import { Heading } from '~/components/_layout/typography/heading';
import { CustomError } from '~/helpers/common/custom-error';
import useTagsSelector from '~/templates/tags-interaction/context/hooks/useTagsSelector';
import { getNewTagName, getTags } from '~/templates/tags-interaction/context/store/selectors';
import Filter from '~/templates/tags-interaction/components/Filter';
import TagsTree from '~/templates/tags-interaction/components/TagsTree';
import { filterTags } from '~/templates/tags-interaction/utils';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import { generateNewTag } from '~/templates/tags-interaction/components/CreateNewTagForm/utils';
import { tags as apiTags } from '~/api/tags';
import { setError } from '~/templates/tags-interaction/context/store/actions';
import { Label } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';

import styles from './styles.module.scss';
import { OriginalTag } from '~/types/gists/tag';

type Props = {
  onCreateNewTag: (newTag: OriginalTag) => void;
  onBack?: CallableFunction;
};

const CreateNewTagForm = ({ onCreateNewTag, onBack }: Props): React.JSX.Element => {
  const { dispatch } = useTagsInteractionContext();

  const newTagName = useTagsSelector(getNewTagName);
  const tags = useTagsSelector(getTags);

  const [filter, setFilter] = useState('');

  const filteredTags = useMemo(() => filterTags(tags, filter), [filter, tags]);

  const onSelectParent = useCallback(
    async (selectedParent: OriginalTag) => {
      try {
        const newTag = await apiTags.createTag(generateNewTag(newTagName, selectedParent.id));
        onCreateNewTag(newTag as OriginalTag);
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        console.error(error);
        dispatch(setError(error.message));
      }
    },
    [dispatch, newTagName, onCreateNewTag]
  );

  const isNodeSelected = useCallback(() => false, []);

  return (
    <>
      <div className={styles['heading-container']}>
        {onBack && (
          <div onClick={() => onBack()}>
            <Icon name='arrow-left' className={styles['back-icon']} />
          </div>
        )}

        <Heading size='xs' className={styles.heading}>
          Select a parent for {newTagName}
        </Heading>
      </div>

      <Filter value={filter} onChange={setFilter} />
      <div className={styles['no-parent']} onClick={() => onSelectParent({ id: null } as unknown as OriginalTag)}>
        <Label size='s'>No parent</Label>
      </div>
      <TagsTree data={filteredTags} onSelectTag={onSelectParent} filterValue={filter} isTagSelected={isNodeSelected} />
    </>
  );
};

export default CreateNewTagForm;
