import React, { useCallback, useMemo } from 'react';
import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { Select, SelectCallback, SelectItem } from '~/components/_inputs/select';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getAvailableBlocks, getBlockById, getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { FormLabel } from '~/components/_form/components/label';
import { setBlockType } from '~/pages/pages/edit/context/store/actions';
import { StringShim } from '~/helpers/shims/string-shim';

import styles from './styles.module.scss';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

const BlockType = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const getBlockByIdSelector = useCallback(
    state => {
      const { blockId } = parseLayerId(selectedLayerId);
      return getBlockById(state, { id: blockId });
    },
    [selectedLayerId]
  );

  const block = usePageEditorSelector(getBlockByIdSelector);
  const availableBlocks = usePageEditorSelector(getAvailableBlocks);

  const onTypeChange: SelectCallback<COMPONENT_TYPES> = useCallback(
    () => item => {
      if (item.value !== block.type) {
        dispatch(setBlockType(block.id, item.value));
      }
    },
    [block.id, block.type, dispatch]
  );

  const blockTypesOptions = useMemo<SelectItem<COMPONENT_TYPES>[]>(
    () =>
      Array.from(availableBlocks.values()).map(availableBlock => ({
        id: availableBlock.type,
        label: StringShim.capitalize(availableBlock.type),
        value: availableBlock.type,
        disabled: false
      })),
    [availableBlocks]
  );

  const selected = useMemo(
    () => blockTypesOptions.find(({ id }) => id === block?.type),
    [block?.type, blockTypesOptions]
  );

  return (
    <div className={styles.container}>
      <FormLabel label='Block' placement='top'>
        <Select<COMPONENT_TYPES> name='block-type' items={blockTypesOptions} value={selected} onChange={onTypeChange} />
      </FormLabel>
    </div>
  );
};

export default BlockType;
