import { BlockVariant, COMPONENT_TYPES } from '@life-moments/lifehub-components';
import { v4 as uuid } from 'uuid';
import { produce } from 'immer';
import { CustomError } from '~/helpers/common/custom-error';
import { PromiseShim } from '~/helpers/shims/promise-shim';

import { AvailableBlocks, AvailableImages, PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { pages as pagesApi } from '~/api/pages';
import {
  addBlockByIndex,
  setAvailableBlocks,
  setAvailableImages,
  setInitialPageData,
  setLoading,
  setSelectedLayerId
} from '~/pages/pages/edit/context/store/actions';
import { BlockLayer, LAYER_TYPES, Page, PublicBlock } from '~/types/gists/page';
import {
  getAvailableBlockById,
  getAvailableBlockByType,
  getBlockContainerById,
  getFlatBlocksByOrder
} from '~/pages/pages/edit/context/store/selectors';
import { onCloseRichEditor } from '~/pages/pages/edit/components/preview-zone/thunks';
import { blocks as blocksApi } from '~/api/blocks';
import { GetTableItemsResponse, SortDirections } from '~/components/_table/types';
import { changeLayerBlockId, layersMapperByBlockType } from '~/pages/pages/edit/context/store/layers';
import { api } from '~/api';
import { FileObject } from '~/types/gists/file';
import { FOLDER_TYPES } from '~/routes/private/files/constants';

export const loadPageDataById =
  (id: string): PageEditorThunk<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(setLoading(true));
      const pageData = await pagesApi.getSingle<Page>({ id });
      dispatch(setInitialPageData(pageData));
    } catch (unknownError) {
      throw new CustomError(unknownError ?? 'Failed to fetch page');
    }
  };

export const loadAvailableImages = (): PageEditorThunk<Promise<void>> => async dispatch => {
  try {
    const images: GetTableItemsResponse<FileObject> = await api[FOLDER_TYPES.IMAGES].get({
      orderDirection: SortDirections.DESC
    });

    dispatch(
      setAvailableImages(
        images.items.reduce<AvailableImages>((map, image) => {
          map.set(image.key, image);

          return map;
        }, new Map<string, FileObject>())
      )
    );
  } catch (unknownError) {
    throw new CustomError(unknownError ?? 'Failed to load available images');
  }
};

export const loadAvailableBlocks = (): PageEditorThunk<Promise<void>> => async dispatch => {
  try {
    const blocksFromApi = await blocksApi.get<
      Omit<PublicBlock, 'data'> & {
        data: {
          content: string; // dangerousHTML
          variants: Array<BlockVariant>;
          variant?: BlockVariant;
          [key: string]: unknown;
        };
      }
    >({
      orderBy: 'name',
      orderDirection: SortDirections.ASC
    });

    dispatch(
      setAvailableBlocks(
        blocksFromApi.items.reduce<AvailableBlocks>((map, block) => {
          const id = block.id.toString();

          const layersMapper = layersMapperByBlockType[block.type];
          const layers = layersMapper ? layersMapper(block) : [];
          map.set(id, { ...block, id, data: { ...block.data, layers } });

          return map;
        }, new Map<string, PublicBlock>())
      )
    );
  } catch (unknownError) {
    throw new CustomError(unknownError ?? 'Failed to load blocks');
  }
};

const generateNewBlockToAdd = (blockTemplate: PublicBlock): PublicBlock => {
  const id = uuid();

  return produce(blockTemplate, draft => {
    draft.id = id;
    draft.data.layers = draft.data.layers.map(layer => ({
      ...layer,
      id: changeLayerBlockId(layer.id, id, blockTemplate.type),
      children: layer.children.map(childLayerId => changeLayerBlockId(childLayerId, id, blockTemplate.type))
    }));
  });
};

export const getFirstLayerToSelect = (block: PublicBlock): BlockLayer | undefined => {
  const PRIORITIZE_LAYER_TYPES_BY_BLOCK: Partial<Record<COMPONENT_TYPES, LAYER_TYPES>> = {
    [COMPONENT_TYPES.image]: LAYER_TYPES.STATIC
  };
  return block.data.layers.find(
    layer => layer.type === (PRIORITIZE_LAYER_TYPES_BY_BLOCK[block.type] ?? LAYER_TYPES.CONTENT)
  );
};

export const onDragEnd =
  (blockTemplateId: string, dropPosition: number): PageEditorThunk =>
  (dispatch, getState) => {
    const blockToAdd = generateNewBlockToAdd(getAvailableBlockById(getState(), { id: blockTemplateId }));
    const flatBlocksOrder = getFlatBlocksByOrder(getState());
    const previousBlock = flatBlocksOrder[dropPosition - 1];

    const previousBlockContainer = getBlockContainerById(getState(), { blockId: previousBlock?.id });

    dispatch(addBlockByIndex(blockToAdd, dropPosition, previousBlockContainer.id));

    const firstLayerToSelect = getFirstLayerToSelect(blockToAdd);

    if (firstLayerToSelect) {
      dispatch(setSelectedLayerId(firstLayerToSelect.id));
    }
  };

export const onPopulateNewBlock =
  (type: COMPONENT_TYPES, blockId: string): PageEditorThunk =>
  (dispatch, getState) => {
    const blockTemplate = getAvailableBlockByType(getState(), { type });
    if (!blockTemplate) return;

    const blockToAdd = generateNewBlockToAdd({
      ...getAvailableBlockById(getState(), { id: blockTemplate.id }),
      id: uuid()
    });
    const flatBlocksOrder = getFlatBlocksByOrder(getState());

    const previousBlock = flatBlocksOrder.find(block => block.id === blockId);
    const newBlockPosition = flatBlocksOrder.findIndex(block => block.id === blockId) + 1;
    const previousBlockContainer = getBlockContainerById(getState(), { blockId: previousBlock?.id });

    dispatch(addBlockByIndex(blockToAdd, newBlockPosition, previousBlockContainer.id));

    const firstLayerToSelect = getFirstLayerToSelect(blockToAdd);
    if (firstLayerToSelect) {
      dispatch(setSelectedLayerId(firstLayerToSelect.id));
    }
  };

export const commitPageChangesToStore = (): PageEditorThunk<Promise<void>> => async dispatch => {
  // We need to simulate a microtask queue to make sure that all changes are committed to the store before the next step.
  // Meanwhile, some dispatches below are treated as synchronous but, in reality, have some asynchronous behavior under the hood.
  return PromiseShim.queueMicrotask(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore @ts-expect-error
    // Committing Canvas Area's changes to the store.
    dispatch(onCloseRichEditor());
  });
};
