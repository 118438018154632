import { useContext } from 'react';
import { ContextReturnType } from '~/pages/pages/edit/context/store/types';
import { Context } from '~/pages/pages/edit/context';

const usePageEditorContext = (): ContextReturnType => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('usePageEditorContext must be used within a PageStore provider');
  }

  return context;
};

export default usePageEditorContext;
