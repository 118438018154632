import React, { useCallback, useEffect, useState } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import classnames from 'classnames';

import { Icon } from '~/components/icon';
import { TagsInteractionPopover } from '~/templates/tags-interaction';
import { TagName } from '~/templates/tags-interaction/components/TagsTree/components/TagName';
import { OriginalTag } from '~/types/gists/tag';
import { isEqual } from '~/helpers/common';

import styles from './styles.module.scss';

const TagsCell = <Data extends TableDataLike>({ cell, column, wrappingMode }: CellProps<Data>): React.JSX.Element => {
  const { editCell } = column;
  const { value } = cell;

  const [assignedTags, setAssignedTags] = useState<OriginalTag[]>(value ?? []);

  const handleEditCell = useCallback(
    (tags: OriginalTag[]) => {
      if (!isEqual(tags, assignedTags)) {
        setAssignedTags(tags);
      }
    },
    [assignedTags]
  );

  const onClose = useCallback(() => {
    editCell(cell, assignedTags);
  }, [assignedTags, cell, editCell]);

  useEffect(() => {
    setAssignedTags(value ?? []);
  }, [value]);

  const isWrapMode = wrappingMode === 'wrap';

  return (
    <div className={styles.container}>
      <TagsInteractionPopover initialAssignedTags={assignedTags} saveTags={handleEditCell} onClose={onClose}>
        {toggle => (
          <div onClick={() => toggle(true)} className={classnames(styles.tags, { [styles.truncate]: !isWrapMode })}>
            {assignedTags.map(tag => (
              <div key={tag.name} className={styles.tag}>
                <Icon name={'tags'} />
                <TagName name={tag.name} />
              </div>
            ))}
          </div>
        )}
      </TagsInteractionPopover>
    </div>
  );
};

export default TagsCell;
