import React from 'react';

import { COMPONENT_TYPES, MetaboxVariants } from '@life-moments/lifehub-components';

import Variant from '~/pages/blocks/edit/components/preview/components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: MetaboxVariants[];
  content: string;
};

const MetaboxBlockVariants = ({ variants, content }: Props): React.JSX.Element => {
  // Additional data required by Metabox Block beyond its internal state.
  const mockedAdditionalProps = { readTimeMinutes: 1, updatedAt: '2024-01-01T00:00:01.278Z' };

  return (
    <div className={styles.list}>
      {variants.map(variant => (
        <Variant
          key={variant.name}
          variant={variant}
          type={COMPONENT_TYPES.metabox}
          content={content}
          additionalProps={mockedAdditionalProps}
        />
      ))}
    </div>
  );
};

export default MetaboxBlockVariants;
