import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '~/components/icon';
import { CoachMessage } from '~/components/_layout/coach/coach-message';

import styles from './onboarding-template.module.scss';
import classnames from 'classnames';

type Props = {
  backButton?: boolean;
  heading?: React.ReactNode;
  message?: React.ReactNode;
  children?: React.ReactNode;
};

export const OnboardingTemplate = ({ backButton, message, children }: Props): React.JSX.Element => {
  const history = useHistory();

  return (
    <section>
      <header className={styles.header}>
        {backButton && (
          <button className={styles.back} onClick={history.goBack}>
            <Icon name='arrow-left' />
          </button>
        )}
        <div className={classnames(styles.description, { [styles['with-back-button']]: backButton })}>
          {message && <CoachMessage>{message}</CoachMessage>}
        </div>
      </header>
      {children}
    </section>
  );
};
