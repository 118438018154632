import { useContext, useEffect } from 'react';

import { TagsModuleContext } from '~/pages/tags/context';
import { api } from '~/api';
import {
  resetTags,
  saveTags,
  setErrorMessage,
  setIsLoading,
  setSelectedTagsById
} from '~/pages/tags/context/store/actions';
import { ERROR_MESSAGE } from '~/pages/tags/constants';
import { useTheme } from '~/components/theme';
import { useSelector } from 'react-redux';
import { RootState } from '~/store';

const useFetchTags = (): void => {
  const { dispatch } = useContext(TagsModuleContext);

  const { env, affiliate } = useTheme();

  const isMainMenuOpened = useSelector((state: RootState) => state.mainMenu.isMainMenuOpened);

  useEffect(() => {
    (async () => {
      if (!isMainMenuOpened) {
        try {
          dispatch(setIsLoading(true));
          dispatch(resetTags());
          const tags = await api.tags.getTagsFlatTree();

          if (tags.length) {
            dispatch(saveTags(tags));
            dispatch(setSelectedTagsById([tags[0].id]));
          }
        } catch (e) {
          console.error(e);
          dispatch(setErrorMessage(ERROR_MESSAGE));
        } finally {
          dispatch(setIsLoading(false));
        }
      }
    })();
  }, [env, affiliate, dispatch, isMainMenuOpened]);
};

export default useFetchTags;
