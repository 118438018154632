import jwtDecode from 'jwt-decode';
import { getAffiliateFromUrl, getEnvFromUrl } from '~/api/utils';
import { AffiliateInfo, Affiliates } from '~/types/gists/affiliates';
import {
  DecodedTokenType,
  FUNCTIONAL_PERMISSIONS_KEY,
  FunctionalModulePermission,
  FunctionalPermissions,
  MODULE_ACCESS
} from '~/types/gists/user';

export enum COGNITO_GROUPS {
  GLOBAL_ADMIN = 'GlobalAdmin',
  ADMIN = 'Admin'
}

export class UserService {
  static decodedToken: Partial<DecodedTokenType>;
  static affiliates: Affiliates = {};
  static functionalPermissions: FunctionalPermissions = Object.values(FUNCTIONAL_PERMISSIONS_KEY).reduce(
    (result, functionalModuleKey) => ({
      ...result,
      [functionalModuleKey]: {
        name: functionalModuleKey,
        access: MODULE_ACCESS.None,
        action: '*'
      }
    }),
    {} as FunctionalPermissions
  );

  static isAuthenticated(): boolean {
    return Boolean(localStorage.getItem('token'));
  }

  static removeUserInfo(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('env');
    localStorage.removeItem('affiliate');

    UserService.affiliates = {};
    UserService.decodedToken = {};
  }

  static decodeToken(responseToken?: string): void {
    const token = responseToken ?? localStorage.getItem('token');

    if (!token) {
      return;
    }

    UserService.decodedToken = jwtDecode(token);
  }

  static setAffiliates = (affiliates: Affiliates): void => {
    UserService.affiliates = affiliates;
  };

  static setAffiliateByKey = (key: AffiliateKey, affiliateInfo: AffiliateInfo): void => {
    UserService.affiliates[key] = {
      ...UserService.affiliates[key],
      affiliateInfo
    };
  };

  static getAffiliatesFullInfo = (): AffiliateInfo[] =>
    Object.keys(UserService.affiliates)
      .map(affKey => UserService.affiliates[affKey].affiliateInfo)
      .sort((a, b) => {
        // move affiliate without 'client' field to the end of the list
        if (!a.client) {
          return 1;
        }

        // move affiliate without 'client' field to the end of the list
        if (!b.client) {
          return -1;
        }

        return a.client.localeCompare(b.client);
      });

  static getAffiliateFullInfoByKey = (key: AffiliateKey): AffiliateInfo => {
    return UserService.affiliates[key].affiliateInfo;
  };

  static getAffiliate = (): [AffiliateKey, Environment] => {
    const env = getEnvFromUrl();
    const affiliate = getAffiliateFromUrl();

    if (affiliate) {
      return [affiliate, env];
    }

    const devAffiliate = Object.values(UserService.affiliates)[0];

    if (devAffiliate) {
      return [devAffiliate.affiliateInfo.key, devAffiliate.environments[0]];
    }

    return ['', 'none'];
  };

  static checkAffiliate = (affiliateToCheck: AffiliateKey): boolean => {
    const [affiliate] = UserService.getAffiliate();

    return affiliate === affiliateToCheck;
  };

  static isAffiliatesFetched = (): boolean => {
    return !!Object.keys(UserService.affiliates).length;
  };

  static resetFunctionalPermissions = (): void => {
    UserService.functionalPermissions = Object.keys(UserService.functionalPermissions).reduce<FunctionalPermissions>(
      (result, functionalModuleKey) => {
        return {
          ...result,
          [functionalModuleKey]: {
            name: functionalModuleKey,
            access: MODULE_ACCESS.None,
            action: '*'
          }
        };
      },
      {} as FunctionalPermissions
    );
  };

  static setFunctionalPermissions = (functionalPermissions: FunctionalPermissions): void => {
    UserService.functionalPermissions = Object.keys(UserService.functionalPermissions).reduce<FunctionalPermissions>(
      (result, functionalModuleKey) => ({
        ...result,
        [functionalModuleKey]: result[functionalModuleKey]
          ? result[functionalModuleKey]
          : {
              name: functionalModuleKey,
              access: MODULE_ACCESS.None,
              action: '*'
            }
      }),
      functionalPermissions
    );
  };

  static getFunctionalPermissionsByModuleKey = (moduleKey: FUNCTIONAL_PERMISSIONS_KEY): FunctionalModulePermission =>
    UserService.functionalPermissions[moduleKey];

  static isUserHaveWildcardPermission = (): boolean => {
    const wildcardAccess = UserService.functionalPermissions['*']?.access;

    return wildcardAccess && wildcardAccess !== MODULE_ACCESS.None;
  };

  static isFunctionalModuleVisible = (moduleKey: FUNCTIONAL_PERMISSIONS_KEY): boolean => {
    if (UserService.isUserHaveWildcardPermission()) {
      return true;
    }

    const modulePermission = UserService.getFunctionalPermissionsByModuleKey(moduleKey);
    if (!modulePermission) {
      return false;
    }

    // temporarily MODULE_ACCESS.Edit === MODULE_ACCESS.View by permissions
    return modulePermission.access === MODULE_ACCESS.Edit || modulePermission.access === MODULE_ACCESS.View;
  };
}
