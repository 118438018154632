import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { useCallback } from 'react';
import { FormError } from '~/components/_form/components/error';
import { FormLabel } from '~/components/_form/components/label';
import { Event, Input } from '~/components/_inputs/input';
import { useSidebarPanel } from '~/components/_layout/panels';
import {
  PAGE_MAME_FIELD_NAME,
  SLUG_FIELD_NAME
} from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel/components/publishing-form/constants';
import { validationSchema } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel/components/publishing-form/schema';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { savePageName, setSlug, setValidationError } from '~/pages/pages/edit/context/store/actions';
import { getPageName, getPageSlug } from '~/pages/pages/edit/context/store/selectors';
import { FieldForValidation } from '~/pages/pages/edit/context/store/types';

type FormProps = FormikProps<{ pageName: string; slug: string }>;

export const PublishingForm = () => {
  const dispatch = usePageEditorDispatch();

  const slug = usePageEditorSelector(getPageSlug);
  const pageName = usePageEditorSelector(getPageName);

  const handleSubmit = useCallback(
    ({ pageName, slug }: FormikValues) => {
      dispatch(savePageName(pageName));
      dispatch(setValidationError(PAGE_MAME_FIELD_NAME, null));
      dispatch(setSlug(slug));
      dispatch(setValidationError(SLUG_FIELD_NAME, null));
    },
    [dispatch]
  );
  const onChangeSubmit = useCallback(
    ({ setFieldValue, submitForm }: FormProps) =>
      async (fieldName: string, value: string): Promise<void> => {
        await setFieldValue(fieldName, value, true);
        await submitForm();
      },
    []
  );

  const onBlurValidation = useCallback(
    ({ isValid, dirty, errors }: FormProps) =>
      (field: Event) => {
        if (dirty && !isValid) {
          const fieldName = field.target.name as FieldForValidation;
          dispatch(setValidationError(fieldName, errors[fieldName] || null));
        }
      },
    [dispatch]
  );

  const { ListItem } = useSidebarPanel();

  return (
    <Formik
      initialValues={{ slug, pageName }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
    >
      {formik => (
        <Form>
          <ListItem>
            <FormLabel isRequired label='Page name'>
              <Field
                as={Input}
                type='text'
                name={PAGE_MAME_FIELD_NAME}
                onChange={onChangeSubmit(formik)}
                onBlur={onBlurValidation(formik)}
              />
            </FormLabel>
            <FormError name={PAGE_MAME_FIELD_NAME} />
          </ListItem>
          <ListItem>
            <FormLabel isRequired label='Slug'>
              <Field
                as={Input}
                type='text'
                name={SLUG_FIELD_NAME}
                onChange={onChangeSubmit(formik)}
                onBlur={onBlurValidation(formik)}
              />
            </FormLabel>
            <FormError name={SLUG_FIELD_NAME} />
          </ListItem>
        </Form>
      )}
    </Formik>
  );
};
