import React, {
  ButtonHTMLAttributes,
  ElementType,
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useState
} from 'react';
import type { PolymorphicComponentProps } from 'react-polymorphic-box';
import { Box } from 'react-polymorphic-box';
import classnames from 'classnames';
import { PromiseShim } from '~/helpers/shims/promise-shim';
import { LinkWithQuery, Props as OwnLinkProps } from '~/components/link';
import { useTheme } from '~/components/theme';
import { DefaultIcons, Icon } from '~/components/icon';
import { SVGShadowFilter } from '~/components/icon/filters/shadow';
import { SVGGlowFilter } from '~/components/icon/filters/glow';
import { LifeHubLogo } from '~/components/_layout/logo';

import styles from './button.module.scss';

type OwnProps = React.HTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    is?: 'major' | 'minor' | 'transparent';
    icon?: keyof DefaultIcons;
    iconClassname?: string;
    fluid?: boolean;
    elastic?: boolean;
  }>;

type PolymorphicProps = {
  label?: React.ReactNode;
};

type Props = PolymorphicComponentProps<ElementType, PolymorphicProps & OwnProps>;

const defaultElement = 'button';

const PolymorphicButton = ({
  is = 'major',
  label,
  icon,
  fluid = false,
  elastic = false,
  children,
  className,
  iconClassname,
  ...props
}: Props): React.JSX.Element => {
  const { theme } = useTheme();

  return (
    <Box
      as={defaultElement}
      className={classnames(
        styles.container,
        styles[is],
        styles[theme],
        { [styles.fluid]: fluid, [styles.elastic]: elastic },
        className
      )}
      {...props}
    >
      <div className={styles.body}>
        <div style={{ display: children ? 'inherit' : 'none' }}>{children}</div>
        <div style={{ display: !children ? 'inherit' : 'none' }}>
          {label && <span className={styles.label}>{label}</span>}
          {icon && (
            <Icon
              name={icon}
              filter='url(#shadow)'
              className={classnames(styles.icon, iconClassname)}
              withLabel={Boolean(label)}
            >
              <SVGShadowFilter id='shadow' />
            </Icon>
          )}
        </div>
      </div>
    </Box>
  );
};

export type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ children, ...props }: ButtonProps): React.JSX.Element => {
  return <PolymorphicButton label={children} as={defaultElement} {...props} />;
};

export type LinkProps = Props & OwnLinkProps & HTMLAttributes<HTMLLinkElement>;

export const LinkButton = ({ children, ...props }: LinkProps): React.JSX.Element => {
  return <PolymorphicButton label={children} {...props} as={LinkWithQuery} />;
};

export type FakeProps = Props & HTMLAttributes<HTMLDivElement>;

export const FakeButton = ({ children, ...props }: FakeProps): React.JSX.Element => {
  return <PolymorphicButton label={children} {...props} as='div' />;
};

export type AsyncProps = { loading?: boolean; ready?: boolean; as?: ElementType } & ButtonProps;

export const AsyncButton = ({
  loading,
  ready,
  disabled,
  children,
  className,
  as = 'button',
  ...props
}: AsyncProps): React.JSX.Element => {
  const notReady = loading || !ready;

  return (
    <PolymorphicButton
      label={children}
      className={classnames({ [styles.loading]: notReady }, className)}
      {...(as !== 'button' ? { ...props } : { disabled: disabled || notReady, ...props })}
      as={as}
    >
      {loading ? (
        <LifeHubLogo className={classnames(styles.icon, styles.loading)} animate filter='url(#glow)'>
          <SVGGlowFilter id='glow' />
        </LifeHubLogo>
      ) : !ready ? (
        <Icon name='tick' className={classnames(styles.icon, styles.loading)} />
      ) : null}
    </PolymorphicButton>
  );
};

export type UseAsyncButton = {
  loading: boolean;
  ready: boolean;
  setLoading: () => void;
  setReady: () => Promise<void>;
  Button: typeof AsyncButton;
};

export const useAsyncButton = (): UseAsyncButton => {
  const [{ loading, ready }, setState] = useState({
    loading: false,
    ready: true
  });

  const setLoading = useCallback(() => {
    setState({ loading: true, ready: false });
  }, []);

  const setReady = useCallback(async () => {
    setState({ loading: false, ready: false });
    await PromiseShim.delay(1000);
    setState({ loading: false, ready: true });
  }, []);

  return {
    loading,
    ready,
    setLoading,
    setReady,
    Button: AsyncButton
  };
};
