import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Key } from '~/helpers/common/event';
import { useEventListener } from '~/hooks/use-event-listener';

import styles from './dimmer.module.scss';

type Props = {
  on: boolean;
  soft?: boolean;
  display?: string;
  children: React.ReactNode;
  className?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onEscClick: CallableFunction;
};

export const Dimmer = React.memo(
  ({
    on = true,
    soft = false,
    display = 'block',
    children,
    className,
    onClick,
    onEscClick
  }: Props): React.JSX.Element => {
    const handleEscKey = useCallback(
      event => {
        if (on && Key.isSpecificKey('escape')(event) && onEscClick) {
          onEscClick();
        }
      },
      [on, onEscClick]
    );

    useEventListener('keyup', handleEscKey);

    return (
      <div
        style={{
          ...(soft ? void 0 : { display: on ? display : 'none' })
        }}
        className={classnames(styles.container, { [styles.soft]: soft }, className)}
        data-visible={on}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);
