import React, { useCallback } from 'react';
import { ControlledFormLabel, FormLabel } from '~/components/_form/components/label';
import { Switch } from '~/components/_inputs/switch';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { useSidebarPanel } from '~/components/_layout/panels';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setGoogleSEO } from '~/pages/pages/edit/context/store/actions';
import { getGoogleSEO } from '~/pages/pages/edit/context/store/selectors';

export const GoogleTab = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const seo = usePageEditorSelector(getGoogleSEO);

  const onChange = useCallback(
    (name, value) => {
      dispatch(setGoogleSEO({ name, value }));
    },
    [dispatch]
  );

  const toggleSearchEngine = useCallback(
    e => {
      const { name, checked } = e.target;
      onChange(name, !checked);
    },
    [onChange]
  );

  const { List, ListItem } = useSidebarPanel();

  return (
    <List>
      <ListItem withoutBorder>
        <FormLabel label='Meta title'>
          <TextareaAutosize name='title' value={seo?.title} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <FormLabel label='Meta description'>
          <TextareaAutosize name='description' value={seo?.description} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <FormLabel label='Canonical URL'>
          <TextareaAutosize name='canonical' value={seo?.canonical} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <ControlledFormLabel id='noindex' placement='right' label='Let search engines index'>
          <Switch id='noindex' name='noindex' checked={!seo?.noindex} onClick={toggleSearchEngine} />
        </ControlledFormLabel>
      </ListItem>
    </List>
  );
};
