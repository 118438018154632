import { v4 as uuid } from 'uuid';
import { ArrayShim } from '~/helpers/shims/array-shim';

import { RULE, RULE_SET } from '~/pages/cohorts/edit/ConditionBuilder/context/reducer';
import { BUILDER_CONDITIONS, BUILDER_OPERATORS } from '~/pages/cohorts/edit/ConditionBuilder/constants';

const parseRuleQuery = (ruleQuery: string): string[] => {
  const ruleChunks = ruleQuery.split(new RegExp(Object.values(BUILDER_OPERATORS).join('|'), 'g'));

  return [...ruleChunks, ruleQuery.substring(ruleChunks[0].length, ruleChunks[0].length + 2)];
};

const getRuleFromQuery = ([condition, ruleQuery]: string[]) => {
  const [object, value, operator] = parseRuleQuery(ruleQuery);

  return {
    condition: condition.trim() as BUILDER_CONDITIONS,
    object: object.replace(/[\s'%]/g, ''),
    value: value.replace(/'/g, '').trimStart().trimEnd(),
    operator: operator.trim() as BUILDER_OPERATORS,
    id: uuid(),
    options: [],
    type: null
  };
};

const getRulesQueriesWithConditions = (query: string): string[][] => {
  let offset = 0;

  return query.split(/\|\||&&/g).map((match, index) => {
    const tmp = offset;
    offset += match.length + 2;

    if (index === 0) {
      return ['', match];
    }

    return [query.substring(tmp - 2, tmp + 1), match];
  });
};

const toLookupById = (lookup, item) => ({
  ...lookup,
  [item.id]: item
});

const parseRulesetQueryToRules = (rulesetQuery: string): Record<string, RULE> =>
  getRulesQueriesWithConditions(rulesetQuery).map(getRuleFromQuery).reduce(toLookupById, {});

const getRulesetsQueriesWithConditions = (conditionQuery: string): string[][] => {
  const rulesetQueries = conditionQuery.match(/[^()]+/g);
  if (rulesetQueries) {
    return ArrayShim.chunk(['', ...rulesetQueries], 2);
  }

  return [];
};

const parseConditionQueryToVisualViewModeStructure = (
  conditionQuery: string
): Promise<{
  ruleSets: Record<string, RULE_SET>;
  rules: Record<string, RULE>;
}> =>
  new Promise((resolve, reject) => {
    const rulesetsQueries = getRulesetsQueriesWithConditions(conditionQuery);

    if (!rulesetsQueries.length) {
      reject();
    }

    resolve(
      rulesetsQueries.reduce(
        (parsedResult, [condition, rulesetQuery]) => {
          const rules = parseRulesetQueryToRules(rulesetQuery);
          const rulesetId = uuid();

          return {
            ruleSets: {
              ...parsedResult.ruleSets,
              [rulesetId]: {
                id: rulesetId,
                condition: condition.trim() as BUILDER_CONDITIONS,
                ruleIds: Object.keys(rules)
              }
            },
            rules: {
              ...parsedResult.rules,
              ...rules
            }
          };
        },
        {
          ruleSets: {} as Record<string, RULE_SET>,
          rules: {} as Record<string, RULE>
        }
      )
    );
  });

export default parseConditionQueryToVisualViewModeStructure;
