import {
  FREQUENCY_TYPES,
  MonthDay,
  WeekDay,
  WEEKDAYS_LABELS
} from '~/pages/push-notifications/edit/panel/scheduler/types';
import { RepeatableConfig } from '~/types/gists/push-notification';

export type SchedulerConfig = RepeatableConfig & {
  weekDay: WeekDay;
  monthDay: MonthDay;
  onYear?: Date;
};

export const defaultSchedulerConfig: SchedulerConfig = {
  end: new Date(),
  time: new Date(),
  everyEntity: FREQUENCY_TYPES.day,
  entityValue: 1,
  everyNumber: 1,
  weekDay: {
    id: WEEKDAYS_LABELS.Mondays,
    label: WEEKDAYS_LABELS.Mondays,
    value: 1
  },
  monthDay: {
    id: '1st',
    label: '1st',
    value: 1
  },
  onYear: new Date(),
  occurrences: 1,
  ends: 'never'
};
