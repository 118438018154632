import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Paragraph } from '~/components/_layout/typography/paragraph';
import StructureItem from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure/components/structure-item';
import { useSidebarPanel } from '~/components/_layout/panels';
import Caret from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure/components/caret';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

import styles from './styles.module.scss';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getContainerBlocks, getContainerById, getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';

type Props = {
  containerId: string;
};

const StructureContainerItem = ({ containerId }: Props): React.JSX.Element => {
  const { List, ListItem } = useSidebarPanel();
  const [isExpanded, setIsExpanded] = useState(true);

  const getContainerByIdSelector = useCallback(state => getContainerById(state, { containerId }), [containerId]);
  const container = usePageEditorSelector(getContainerByIdSelector);

  const getContainerBlocksSelector = useCallback(state => getContainerBlocks(state, { containerId }), [containerId]);
  const containerBlocks = usePageEditorSelector(getContainerBlocksSelector);
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const isChildLayerSelected = useMemo(() => {
    const { blockId: selectedBLockId } = parseLayerId(selectedLayerId);

    return !!containerBlocks.find(blockId => blockId === selectedBLockId);
  }, [containerBlocks, selectedLayerId]);

  useEffect(() => {
    if (isChildLayerSelected) {
      setIsExpanded(true);
    }
  }, [isChildLayerSelected]);

  return (
    <ListItem withoutBorder className={styles['structure-container-item-wrapper']}>
      <div className={styles['structure-container-item']}>
        <Caret onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} />
        <Paragraph size='s'>{container.name}</Paragraph>
      </div>
      {isExpanded && (
        <List className={styles['structure-items']}>
          {containerBlocks.map(itemId => (
            <StructureItem key={itemId} id={itemId} />
          ))}
        </List>
      )}
    </ListItem>
  );
};

export default StructureContainerItem;
