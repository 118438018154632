import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';
import { OwnProps as TextProps, TextNode } from '../text';

import styles from './heading.module.scss';

type As = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Sizes = 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs';

export type Props = {
  as?: As;
  size?: Sizes;
  special?: boolean;
} & TextProps;

export const HeadingComponent = (
  { as = 'h2', size = 'l', special = false, children, className, ...props }: Props,
  ref?: Ref<HTMLHeadingElement>
): React.JSX.Element => {
  return (
    <TextNode
      as={as}
      ref={ref}
      className={classnames(styles.container, styles[`size-${size}`], { [styles.special]: special }, className)}
      {...props}
    >
      {children}
    </TextNode>
  );
};

export const Heading = forwardRef<HTMLHeadingElement, Props>(HeadingComponent);
