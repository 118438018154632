import classnames from 'classnames';
import React from 'react';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { CoachMessage as CoachMessageType } from '~/components/_layout/coach/coach-popover';
import styles from '~/components/_layout/popovers/import-export/index.module.scss';
import { OPERATION_TYPES } from '~/components/_layout/popovers/import-export/constants';
import { Heading } from '~/components/_layout/typography/heading';
import { Label } from '~/components/_layout/typography/label';
import { Paragraph } from '~/components/_layout/typography/paragraph';
import { AsyncButton, Button } from '~/components/button';

type Props = {
  responseMessage: CoachMessageType;
  hasError: boolean;
  loading: boolean;
  ready: boolean;
  submitText: string;
  successTitle: string;
  onClose(): void;
  title: string;
  message: string;
  editable: boolean;
  handleChange: HandleChange;
  code: string;
  operationType: OPERATION_TYPES;
  handleSubmit: () => Promise<void>;
};

export const ImportExportPopoverContent = (props: Props) => {
  const {
    responseMessage,
    hasError,
    loading,
    ready,
    submitText,
    successTitle,
    onClose,
    title,
    message,
    editable,
    handleChange,
    code,
    operationType,
    handleSubmit
  } = props;
  return (
    <>
      {responseMessage ? (
        <>
          <CoachMessage>
            <div className={styles.heading}>
              <Heading size='xs'>{hasError ? 'Error' : successTitle}</Heading>
            </div>
            {responseMessage}
          </CoachMessage>
          <Button onClick={onClose} is='major' fluid>
            {hasError ? 'Ok' : 'Ok, thanks'}
          </Button>
        </>
      ) : (
        <>
          <CoachMessage>
            <div className={styles.heading}>
              <Heading size='xs'>{title}</Heading>
            </div>
          </CoachMessage>
          {!!message && (
            <Paragraph size='m' className={styles.message}>
              {message}
            </Paragraph>
          )}
          <Label className={styles.label}>Code</Label>
          <TextareaAutosize
            minRows={1}
            maxRows={7}
            disabled={!editable}
            onChange={handleChange}
            defaultValue={code}
            className={styles.code}
            placeholder={operationType === OPERATION_TYPES.IMPORT ? 'Paste code here' : undefined}
          />
          <AsyncButton
            className={classnames(styles.button, { [styles.loading]: loading })}
            onClick={handleSubmit}
            loading={loading}
            ready={ready}
            disabled={!code}
            fluid
          >
            {submitText}
          </AsyncButton>
        </>
      )}
    </>
  );
};
