import React from 'react';

import { useSidebarPanel } from '~/components/_layout/panels';
import Typography from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/content/components/typography';

import styles from './styles.module.scss';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getIsSomethingSelectedInRichEditor } from '~/pages/pages/edit/context/store/selectors';
import Interaction from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/content/components/interaction';

const ContentLevelSidebar = (): React.JSX.Element => {
  const { Panel, List, ListItem } = useSidebarPanel();
  const isSomethingSelected = usePageEditorSelector(getIsSomethingSelectedInRichEditor);

  return (
    <>
      <Panel className={styles.container}>
        <List>
          <ListItem withoutBorder>
            <Typography />
          </ListItem>
        </List>
      </Panel>
      {isSomethingSelected && (
        <Panel title={'Interaction'}>
          <List>
            <ListItem withoutBorder>
              <Interaction />
            </ListItem>
          </List>
        </Panel>
      )}
    </>
  );
};

export default ContentLevelSidebar;
