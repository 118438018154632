import React from 'react';

import { Paragraph } from '~/components/_layout/typography/paragraph';
import { Icon } from '~/components/icon';
import { useSidebarPanel } from '~/components/_layout/panels';

import { StructureItemLayer } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import styles from './styles.module.scss';

type Props = {
  name: string;
  isDefault: boolean;
  content?: StructureItemLayer[];
};

const VariantContent = ({ content }: { content?: StructureItemLayer }): React.JSX.Element | null => {
  if (!content) {
    return null;
  }

  if (content?.childrenLayers?.length) {
    return (
      <div className={styles['layer-container']}>
        <div className={styles['block-name-container']}>
          <Icon name='arrow-triangle-right' className={styles.caret} />
          <Paragraph size='s' className={styles['block-name']}>
            {content.name}
          </Paragraph>
        </div>
        {content.childrenLayers.map(layer => (
          <React.Fragment key={layer.name}>
            <VariantContent content={layer} />
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <Paragraph size='s' className={styles.content}>
      {content.name}
    </Paragraph>
  );
};

export const Variant = ({ name, isDefault, content }: Props): React.JSX.Element => {
  const { Panel } = useSidebarPanel();

  return (
    <Panel title={`${name}${isDefault ? ' (default)' : ''}`} className={styles.container}>
      {content?.map(layer => (
        <VariantContent key={layer.name} content={layer} />
      ))}
    </Panel>
  );
};

export default Variant;
