import { Options } from '@popperjs/core';

export const INITIAL_CODE_VALUE = '';
export const INITIAL_RESPONSE_MESSAGE = '';
export const ERROR_RESPONSE_MESSAGE = ['Oh... it appears an error has occurred.', 'We’re doomed!'];

export enum OPERATION_TYPES {
  IMPORT,
  EXPORT
}

export const DEFAULT_POPPER_OPTIONS: Partial<Options> = {
  strategy: 'absolute',
  placement: 'bottom'
};
