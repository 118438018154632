import React from 'react';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

const TRANSITION: Transition = {
  repeat: Infinity,
  repeatType: 'reverse',
  duration: 10,
  ease: 'easeInOut'
};

export const Background = (): React.JSX.Element => {
  return (
    <svg viewBox='0 0 100 100' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient id='MyGradient' gradientTransform='rotate(45)'>
          <AnimatePresence>
            <motion.stop
              key='from_stop'
              initial={{ stopColor: '#c31cb6' }}
              animate={{ stopColor: '#f5d20d' }}
              transition={TRANSITION}
              offset={0}
            />
            <motion.stop
              key='to_stop'
              initial={{ stopColor: '#f48c1d' }}
              animate={{ stopColor: '#3dcf35' }}
              transition={TRANSITION}
              offset={0.5}
            />
          </AnimatePresence>
        </linearGradient>
      </defs>

      <rect fill='url(#MyGradient)' x={0} y={0} width={100} height={100} />
    </svg>
  );
};
