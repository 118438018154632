import React, { useCallback, useMemo } from 'react';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { AsyncButton, useAsyncButton } from '~/components/button';

import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { saveSelectedTag } from '~/pages/tags/context/store/thunks';

import styles from './styles.module.scss';
import { TAG_FORM_VALIDATION_KEYS } from '~/pages/tags/constants';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';

const SaveTag = (): React.JSX.Element => {
  const { state, dispatch } = useTagsModuleContext();

  const isEditMode = state.originalTags.length === state.virtualTags.length;

  const isDisabled = useMemo(() => {
    const tag = state.virtualTags.find(tag => tag.id === state.selectedTagsIds[0]);
    if (tag) {
      return Object.values(TAG_FORM_VALIDATION_KEYS).some(key => !!state.validationErrors[key]);
    }

    return false;
  }, [state.selectedTagsIds, state.validationErrors, state.virtualTags]);

  const { loading, ready, setLoading, setReady } = useAsyncButton();

  const onClick = useCallback(async () => {
    setLoading();
    try {
      await dispatch(saveSelectedTag());
    } finally {
      await setReady();
    }
  }, [dispatch, setLoading, setReady]);

  return (
    <TopBarTool>
      <TooltipOnCondition
        title={`Please add a name before ${!isEditMode ? 'saving' : 'updating'}`}
        condition={Boolean(state.validationErrors[TAG_FORM_VALIDATION_KEYS.NAME])}
        delay={0}
      >
        <AsyncButton
          is='major'
          onClick={onClick}
          className={styles.button}
          disabled={isDisabled}
          ready={ready}
          loading={loading}
        >
          {isEditMode ? 'Update' : 'Save'}
        </AsyncButton>
      </TooltipOnCondition>
    </TopBarTool>
  );
};

export default SaveTag;
