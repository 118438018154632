import React, { useCallback, useEffect } from 'react';
import { CustomError } from '~/helpers/common/custom-error';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import useTagsFetch from '~/templates/tags-interaction/context/hooks/useTagsFetch';
import useMountEffect from '~/hooks/use-mount-effect';
import {
  enableAssignMode,
  enableCreationMode,
  initSelectedTags,
  selectTag,
  setError,
  setFilter,
  setNewTagName,
  setTags
} from '~/templates/tags-interaction/context/store/actions';
import { tags as apiTags } from '~/api/tags';
import useTagsSelector from '~/templates/tags-interaction/context/hooks/useTagsSelector';
import {
  getError,
  getFilter,
  getFilteredTags,
  getIsLoading,
  getSelectedTags,
  isPerfectMatchExist as isPerfectMatchExistSelector
} from '~/templates/tags-interaction/context/store/selectors';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import Loader from '~/components/loader';
import { Button } from '~/components/button';
import Filter from '~/templates/tags-interaction/components/Filter';
import SelectedTags from '~/templates/tags-interaction/components/SelectedTags';
import TagsTree from '~/templates/tags-interaction/components/TagsTree';
import { Label } from '~/components/_layout/typography/label';

import styles from './styles.module.scss';

import { Icon } from '~/components/icon';
import CreationFlow from '~/templates/tags-interaction/shapes/block/components/CreationFlow';
import { OriginalTag } from '~/types/gists/tag';
import { Options } from '@popperjs/core';

type Props = {
  initialAssignedTags: OriginalTag[];
  onChange: HandleChange;
  popoverOptions?: Partial<Options>;
};

const InteractionBlockFlow = ({ initialAssignedTags, onChange, popoverOptions = {} }: Props): React.JSX.Element => {
  const { dispatch } = useTagsInteractionContext();
  useTagsFetch();
  useMountEffect(() => dispatch(initSelectedTags(initialAssignedTags)));

  const error = useTagsSelector(getError);
  const isLoading = useTagsSelector(getIsLoading);
  const selectedTags = useTagsSelector(getSelectedTags);
  const filteredTags = useTagsSelector(getFilteredTags);
  const filter = useTagsSelector(getFilter);
  const isPerfectMatchExist = useTagsSelector(isPerfectMatchExistSelector);

  useEffect(() => onChange('tags', selectedTags), [onChange, selectedTags]);
  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error.length });

  const onSelectTag = useCallback((selectedTag: OriginalTag) => dispatch(selectTag(selectedTag)), [dispatch]);
  const onChangeFilter = useCallback((newFilter: string) => dispatch(setFilter(newFilter)), [dispatch]);
  const isTagSelected = useCallback(
    (node: OriginalTag) => selectedTags.some(selectedTag => selectedTag.id === node.id),
    [selectedTags]
  );

  const goToCreationMode = toggle => {
    dispatch(enableCreationMode());
    dispatch(setNewTagName(filter));
    toggle(true);
  };

  const onCreateNewTag = useCallback(
    async (newTag: OriginalTag) => {
      try {
        const newTags = await apiTags.getOriginalTagsTree();
        dispatch(setTags(newTags));
        dispatch(setFilter(''));
        onSelectTag(newTag);
        dispatch(enableAssignMode());
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        console.error(error);
        dispatch(setError(error.message));
      }
    },
    [dispatch, onSelectTag]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Label size='xs' className={styles.label}>
            Tags
          </Label>
          <CreationFlow onCreateNewTag={onCreateNewTag} popoverOptions={popoverOptions}>
            {toggle => (
              <Filter onChange={onChangeFilter} value={filter}>
                {!isPerfectMatchExist && (
                  <div className={styles['add-new-tag']} onClick={() => setTimeout(() => goToCreationMode(toggle), 0)}>
                    <Icon name='plus' /> <Label size='s'>{filter}</Label>
                  </div>
                )}
              </Filter>
            )}
          </CreationFlow>
          <SelectedTags />
          <TagsTree data={filteredTags} onSelectTag={onSelectTag} filterValue={filter} isTagSelected={isTagSelected} />
        </>
      )}

      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
    </div>
  );
};

export default InteractionBlockFlow;
