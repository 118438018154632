import React from 'react';
import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { useSidebarPanel } from '~/components/_layout/panels';
import BlockOptions from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-options';
import BlockType from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-type';
import BlockVariantSelect from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-variant';
import BlockLevel from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-level';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedBlock } from '~/pages/pages/edit/context/store/selectors';
import ToggleCaption from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/toggle-caption';

import styles from './styles.module.scss';

const ContainerLevelSidebar = (): React.JSX.Element => {
  const { Panel, List, ListItem } = useSidebarPanel();

  const selectedBlock = usePageEditorSelector(getSelectedBlock);

  return (
    <>
      <Panel title={'Block options'} className={styles.container}>
        <List>
          <ListItem withoutBorder>
            <BlockOptions />
          </ListItem>
          <ListItem withoutBorder>
            <BlockType />
          </ListItem>
          <ListItem withoutBorder>
            <BlockVariantSelect />
          </ListItem>
          {selectedBlock?.type === COMPONENT_TYPES.heading && (
            <ListItem withoutBorder>
              <BlockLevel />
            </ListItem>
          )}
          {selectedBlock?.type === COMPONENT_TYPES.image && (
            <ListItem withoutBorder>
              <ToggleCaption />
            </ListItem>
          )}
        </List>
      </Panel>
    </>
  );
};

export default ContainerLevelSidebar;
