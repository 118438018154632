import React from 'react';

import { TEXT_DEFAULT_VARIANT, TextVariants } from '@life-moments/lifehub-components';

import { getStructureItemLayers, StructureItemLayer } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import Variant from '../../components/variant';
import { Block } from '~/types/gists/Block';

import styles from './styles.module.scss';

type Props = {
  variants: TextVariants[];
  blockName: Block['name'];
};

const content: StructureItemLayer[] = [{ name: 'Text inner' }];

const TextBlockVariants = ({ variants, blockName }: Props): React.JSX.Element => (
  <div className={styles.container}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        name={variant.displayName}
        isDefault={variant.name === TEXT_DEFAULT_VARIANT.name}
        content={getStructureItemLayers(blockName, content)}
      />
    ))}
  </div>
);

export default TextBlockVariants;
