import React, { memo } from 'react';

import useDocumentTitle from '~/hooks/use-document-title';

import { Column, Grid } from '~/components/grid';
import Hero from '~/pages/home/components/Hero';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import ProTip from '~/pages/home/components/ProTip';
import Messages from '~/pages/home/components/Messages';

import styles from './styles.module.scss';

const Home = (): React.JSX.Element => {
  useDocumentTitle('Home');

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Home' />
        </Breadcrumb>
      </Slot>
      <section className={styles.container}>
        <Grid className={styles.hero}>
          <Column>
            <Hero />
          </Column>
        </Grid>
        <Grid className={styles.main}>
          <Column span='1'>
            <ProTip />
          </Column>
          <Column span='2+1'>
            <Messages />
          </Column>
        </Grid>
      </section>
    </>
  );
};

export default memo(Home);
