import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Tooltip, useTooltip } from '../index';

type Props = PropsWithChildren<{
  title: Tooltip;
  delay?: number;
}>;

export const TooltipOnHover = ({ title, children, delay = 1500 }: Props): React.JSX.Element => {
  const { Tooltip, toggle, ...props } = useTooltip();

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleEnter = () => {
    timeout.current = setTimeout(() => {
      toggle(true);

      timeout.current = null;
    }, delay);
  };

  const handleLeave = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    toggle(false);
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  });

  return (
    <Tooltip title={title} {...props}>
      {ref => (
        <div ref={ref} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
          {children}
        </div>
      )}
    </Tooltip>
  );
};

type PropsWithCondition = Props & { condition: boolean };

export const TooltipOnCondition = ({ condition, title, delay, children }: PropsWithCondition): React.JSX.Element =>
  condition && title ? (
    <TooltipOnHover title={title} delay={delay}>
      {children}
    </TooltipOnHover>
  ) : (
    <>{children}</>
  );
