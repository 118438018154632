import React, { useCallback } from 'react';

import { DuplicateCurrentAction } from '~/components/_layout/page-actions/duplicate-current-action';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getIsTouched } from '~/pages/pages/edit/context/store/selectors';
import { cloneCurrent } from '~/pages/pages/edit/components/duplicate-page-action/thunks';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';

const DuplicatePageAction = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const isTouched = usePageEditorSelector(getIsTouched);

  const handleClone = useCallback(() => dispatch(cloneCurrent()), [dispatch]);

  return <DuplicateCurrentAction rootRoute={PAGES_ROUTES.root} isUnsavedChanges={isTouched} onClone={handleClone} />;
};

export default DuplicatePageAction;
