import { CustomError } from '~/helpers/common/custom-error';
import { DiscardType, TagsThunk } from '~/pages/tags/context/store/types';
import {
  createVirtualTag,
  saveTags,
  setDiscardType,
  setErrorMessage,
  setHasUnsavedChanges,
  setNextTagsIdsToSelect,
  setSelectedTagsById,
  setShouldShowDiscardChanges,
  setShouldValidate,
  setVirtualTags,
  updateVirtualTagById
} from '~/pages/tags/context/store/actions';
import { api } from '~/api';
import { isEqual } from '~/helpers/common';
import { FlatTag } from '~/types/gists/tag';

export const resetTagsToOriginal = (): TagsThunk => (dispatch, getState) => {
  const state = getState();
  dispatch(
    setSelectedTagsById(
      state.selectedTagsIds.filter(selectedId => state.originalTags.some(tag => tag.id === selectedId))
    )
  );
  dispatch(setShouldValidate(false));
  dispatch(setVirtualTags(state.originalTags));
};

export const generateNewVirtualTag = (): TagsThunk => (dispatch, getState) => {
  const state = getState();
  const newVirtualTagId =
    Math.max.apply(null, state.virtualTags.length ? state.virtualTags.map(tag => tag.id) : [0]) + 1;

  const theFirstTagInTheFlatList = state.virtualTags.find(virtualTag => state.selectedTagsIds.includes(virtualTag.id));

  const newVirtualTag = {
    id: newVirtualTagId,
    name: 'New Tag',
    description: null,
    parentId: theFirstTagInTheFlatList?.parentId ?? null,
    subHeading: null,
    descendants: [],
    position: theFirstTagInTheFlatList ? theFirstTagInTheFlatList.position + 1 : 1
  } as FlatTag;

  dispatch(resetTagsToOriginal());
  dispatch(createVirtualTag(newVirtualTag));
  dispatch(setSelectedTagsById([newVirtualTagId]));
};

export const saveSelectedTag = (): TagsThunk => async (dispatch, getState) => {
  const state = getState();
  const isEditMode = state.originalTags.length === state.virtualTags.length;

  // BE do not expect 'descendants' as a property
  const tag = {
    ...(state.virtualTags.find(tag => tag.id === state.selectedTagsIds[0]) as FlatTag),
    descendants: undefined
  };

  try {
    const response = isEditMode ? await api.tags.updateTagById(tag) : await api.tags.createTag(tag);

    const tags = await api.tags.getTagsFlatTree();

    dispatch(saveTags(tags));
    dispatch(setDiscardType(DiscardType.None));
    dispatch(setShouldShowDiscardChanges(false));
    dispatch(setHasUnsavedChanges(false));
    dispatch(setSelectedTagsById([response.id]));
  } catch (unknownError) {
    const error = new CustomError(unknownError);
    console.error(error);
    dispatch(setErrorMessage([error.message]));
  }
};

export const syncSaveChanges = (): TagsThunk => async (dispatch, getState) => {
  const state = getState();

  const selectedTag = state.virtualTags.find(virtual => virtual.id === state.selectedTagsIds[0]) as FlatTag;
  const originalTag = state.originalTags.find(original => original.id === selectedTag.id) as FlatTag;

  dispatch(setHasUnsavedChanges(!isEqual(selectedTag, originalTag)));

  let discardType;

  if (Object.values(state.validationErrors).filter(Boolean).length) {
    discardType = DiscardType.CreatedInvalidTag;
  } else if (state.virtualTags.length === state.originalTags.length) {
    discardType = DiscardType.CreatedTag;
  } else {
    discardType = DiscardType.NewTag;
  }

  dispatch(setDiscardType(discardType));
};

export const updateVirtualTag =
  (id: number, payload: Partial<FlatTag>): TagsThunk =>
  dispatch => {
    dispatch(updateVirtualTagById(id, payload));
    dispatch(syncSaveChanges());
  };

export const selectTagsByIds =
  (idsToSelect: number[]): TagsThunk =>
  (dispatch, getState) => {
    const state = getState();

    if (state.hasUnsavedChanges) {
      dispatch(setNextTagsIdsToSelect(idsToSelect));
      dispatch(setShouldShowDiscardChanges(true));
    } else {
      dispatch(resetTagsToOriginal());
      dispatch(setSelectedTagsById(idsToSelect));
    }
  };
