import { AvailableEntityBackendKeys } from '~/helpers/formatters';
import { FilterValue } from 'react-table';

export type TableApi =
  | 'pushNotifications'
  | 'cohorts'
  | 'attributes'
  | 'images'
  | 'icons'
  | 'fonts'
  | 'blocks'
  | 'datasets'
  | 'pages';
export type TableIdentifier =
  | 'cohort'
  | 'timedMessage'
  | 'userAttributeSettings'
  | 'images'
  | 'icons'
  | 'fonts'
  | 'file'
  | 'blocks'
  | 'datasets'
  | 'page';

export type TableEntitiesPlural =
  | 'Cohorts'
  | 'Pushes'
  | 'Attributes'
  | 'Images'
  | 'Icons'
  | 'Fonts'
  | 'Blocks'
  | 'Datasets'
  | 'Pages';

export type SortBy = {
  orderBy?: string;
  orderDirection?: string;
};

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type GetTableItemsParams = {
  page?: number;
  limit?: number;
  orderBy?: string;
  orderDirection?: string;
  filters?: string;
};

export type GetTableItemsResponse<T> = {
  count: number;
  items: T[];
};

export type DeleteTableItemParams = {
  ids: string;
};

export type TableReducerState<T extends HasId> = {
  count: number;
  items: T[];
  normalizedItems: Record<T['id'], T> | Record<string, any>;
  isEmpty: boolean | null;
  loading: boolean;
  error?: string;
};

export type GetHistoryParams = GetTableItemsParams & { id: string };

export type GetSingleParams = { id: string };

export type ExportParams = Pick<GetTableItemsParams, 'filters' | 'limit'>;

export type ExportResponse<T> = T[];

export type ImportParams<T> = {
  data: T[];
};

export type ImportResponse = {
  result: ImportResult[];
};

export type ImportResult = {
  entity: AvailableEntityBackendKeys;
  inserted?: number;
  updated?: number;
  skipped?: number;
};

export type FilterWithCustomQuery = {
  id: string;
  value: {
    value: FilterValue;
    customQuery: string;
  };
};
