import React, { useCallback } from 'react';
import { compose } from 'redux';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';

import LeftSidebar from '~/pages/pages/edit/components/left-sidebar';
import RightSidebar from '~/pages/pages/edit/components/right-sidebar';
import PageNamePopover from '~/pages/pages/edit/components/page-name-popover';
import { withPageEditorContext } from '~/pages/pages/edit/context/hocs/with-page-editor-context';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import useLifehubComponentsStyles from '~/pages/pages/edit/context/hooks/use-lifehub-components-styles';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getIsNewPage, getPageName } from '~/pages/pages/edit/context/store/selectors';
import useDocumentTitle from '~/hooks/use-document-title';
import { withLoadPageEditorData } from '~/pages/pages/edit/context/hocs/with-load-page-editor-data';
import SavePublishButtons from '~/pages/pages/edit/components/save-publish-buttons';
import DuplicatePageAction from '~/pages/pages/edit/components/duplicate-page-action';
import DeletePageAction from '~/pages/pages/edit/components/delete-page-action';
import PreviewZone from '~/pages/pages/edit/components/preview-zone';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { CANVAS_DROPPABLE_ID } from '~/pages/pages/edit/components/preview-zone/constants';
import { onDragEnd } from '~/pages/pages/edit/context/store/thunks';

const Component = (): React.JSX.Element => {
  const pageName = usePageEditorSelector(getPageName);
  const isNew = usePageEditorSelector(getIsNewPage);

  const dispatch = usePageEditorDispatch();

  useDocumentTitle(`${pageName} - Pages`);
  useLifehubComponentsStyles();

  const onDragBlockToCanvasEnd = useCallback<OnDragEndResponder>(
    ({ destination, draggableId }) => {
      if (destination && destination.droppableId === CANVAS_DROPPABLE_ID) {
        dispatch(onDragEnd(draggableId, destination.index));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Pages' to={PAGES_ROUTES.root} />
          <Breadcrumb.Item label={pageName} truncated />
        </Breadcrumb>
      </Slot>
      <DragDropContext onDragEnd={onDragBlockToCanvasEnd}>
        <Slot name='left-sidebar'>
          <LeftSidebar />
        </Slot>
        <Slot name='right-sidebar'>
          <RightSidebar />
        </Slot>
        {isNew && <PageNamePopover />}
        <Slot name='page-actions'>
          <DuplicatePageAction />
          <DeletePageAction />
          <SavePublishButtons />
        </Slot>
        <PreviewZone />
      </DragDropContext>
    </>
  );
};

export default compose(withPageEditorContext, withLoadPageEditorData)(Component);
