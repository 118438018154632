import React, { PropsWithChildren, SVGAttributes, useEffect } from 'react';
import classnames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { NumberShim } from '~/helpers/shims/number-shim';

import styles from './logo.module.scss';

type Props = PropsWithChildren<{
  animate?: boolean;
  multiColoured?: boolean;
}> &
  SVGAttributes<SVGElement>;

const ITEMS = [
  { r: 87, strokeWidth: 15 },
  { r: 120, strokeWidth: 22 },
  { r: 177, strokeWidth: 30 },
  { r: 236, strokeWidth: 39 },
  { r: 301, strokeWidth: 50 },
  { r: 380, strokeWidth: 63 },
  { r: 472, strokeWidth: 78 }
];

export const LifeHubLogo = ({
  animate = false,
  multiColoured = false,
  className,
  children,
  ...props
}: Props): React.JSX.Element => {
  const animation = useAnimation();

  useEffect(() => {
    if (animate) {
      animation.start(({ index, rotate }) => {
        return {
          rotate: rotate + 360,
          transition: { from: rotate, repeat: Infinity, repeatType: 'mirror', duration: 2, delay: index * 0.1 }
        };
      });
    } else {
      animation.set(({ rotate }) => {
        return {
          rotate
        };
      });
      animation.stop();
    }
  }, [animate, animation]);

  return (
    <svg
      className={classnames(
        styles.container,
        { [styles.animate]: animate, [styles['multi-coloured']]: multiColoured },
        className
      )}
      viewBox='0 0 1024 1024'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <defs>{children}</defs>
      {ITEMS.map(({ r, strokeWidth }, index, { length }) => {
        const order = length - index;
        const rotate = order * -25;

        return (
          <motion.circle
            key={r}
            animate={animation}
            custom={{
              index: order - 1,
              rotate
            }}
            className={styles.circle}
            cx={512}
            cy={512}
            r={r}
            strokeWidth={strokeWidth}
            strokeDasharray={NumberShim.decreaseBy(2 * Math.PI * r, 25)}
          />
        );
      })}
    </svg>
  );
};
