import type { Column } from 'react-table';
import type { Cohort } from '~/types/gists/cohort';

import { PrimaryCell } from '~/components/_table/cells/primary';
import { formatDate } from '~/helpers/formatters';
import { COHORTS_ROUTES } from '~/routes/private/cohorts/constants';
import TagsCell from '~/components/_table/cells/tags';
import { TagsFilter } from '~/components/_table/filters/tags';

export const columns: Column<Cohort>[] = [
  {
    title: 'Cohort name',
    Cell: PrimaryCell,
    accessor: 'name',
    to: COHORTS_ROUTES.root,
    actionTooltip: 'Edit Cohort',
    primary: true
  },
  {
    title: 'Description',
    accessor: 'description'
  },
  {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableEdit: true,
    width: 160
  },
  {
    title: 'Condition',
    accessor: cohort => cohort.conditionQuery ?? '',
    id: 'conditionQuery',
    tooltip: cohort => cohort.conditionQuery ?? ''
  },
  {
    title: 'Tags',
    accessor: 'tags',
    Cell: TagsCell,
    disableSortBy: true,
    Filter: TagsFilter
  },
  {
    title: 'Size',
    accessor: 'size',
    disableFilters: true,
    disableEdit: true,
    width: 88
  },
  {
    title: 'Push enabled',
    accessor: 'sizeWithPushEnabled',
    disableFilters: true,
    disableEdit: true,
    width: 128
  }
];
