import { State } from '~/templates/tags-interaction/context/store/types';
import { INTERACTION_MODES } from '~/templates/tags-interaction/context/constants';
import { filterTags } from '~/templates/tags-interaction/utils';
import { OriginalTag } from '~/types/gists/tag';

export const getError = (state: State): string => state.error;
export const getTags = (state: State): OriginalTag[] => state.tags;
export const getSelectedTags = (state: State): OriginalTag[] => state.selectedTags;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getInteractionMode = (state: State): INTERACTION_MODES => state.interactionMode;
export const getNewTagName = (state: State): string => state.newTagName;
export const getFilter = (state: State): string => state.filter;
export const isPerfectMatchExist = (state: State): boolean => {
  const perfectMatchTags = filterTags(state.tags, getFilter(state), true);

  return !!perfectMatchTags.length;
};
export const getFilteredTags = (state: State): OriginalTag[] => filterTags(getTags(state), getFilter(state));
