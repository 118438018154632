import React, { useCallback, useState } from 'react';
import BlocksContent from '~/pages/pages/edit/components/left-sidebar/components/blocks-panel';
import ModeSwitcher, { MODE } from '~/pages/pages/edit/components/left-sidebar/components/mode-switcher';
import PagePanel from '~/pages/pages/edit/components/left-sidebar/components/page-panel';

const LeftSidebar = (): React.JSX.Element => {
  const [mode, setMode] = useState<MODE>(MODE.page);

  const switchMode = useCallback(
    (mode: MODE) => () => {
      setMode(mode);
    },
    []
  );

  return (
    <>
      <ModeSwitcher mode={mode} switchMode={switchMode} />
      {mode === MODE.page && <PagePanel />}
      {mode === MODE.blocks && <BlocksContent />}
    </>
  );
};

export default LeftSidebar;
