import React, { ChangeEvent, memo } from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { RadioField } from '~/components/_form/fields/radio';
import { ENVIRONMENT_LABELS } from '~/components/_layout/environment-indicator';
import { IDENTIFIER } from '~/components/_layout/main-menu/client-environment/index';
import { Heading } from '~/components/_layout/typography/heading';
import { Label } from '~/components/_layout/typography/label';
import { AffiliateInfo } from '~/types/gists/affiliates';
import { Image } from '~/components/image';

import styles from './client-environment-section.module.scss';

type OwnProps = {
  toggle(event: ChangeEvent<HTMLInputElement>): void;
  affiliateEnvironments: Environment[];
  currentAffiliateEnvironment: string;
  affiliate: AffiliateInfo;
};

const EnvironmentAccordion = ({
  affiliate,
  toggle,
  affiliateEnvironments,
  currentAffiliateEnvironment
}: OwnProps): React.JSX.Element => (
  <AccordionItem uuid={affiliate.key} className={styles.container}>
    <AccordionItemHeading>
      <AccordionItemButton className={styles.headline}>
        {affiliate.iconUrl && <Image src={affiliate.iconUrl} alt='product-env-logo' className={styles.logo} />}
        <div>
          <Heading size='xs'>{affiliate.name}</Heading>
          <Label size='xs' className={styles.client}>
            {affiliate.client ?? '-'}
          </Label>
        </div>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel onChange={toggle} className={styles.options}>
      {affiliateEnvironments.map(env => {
        const value = `${affiliate.key}/${env}`;
        const isChecked = currentAffiliateEnvironment === value;

        return (
          <RadioField key={value} name={IDENTIFIER} label={ENVIRONMENT_LABELS[env]} value={value} checked={isChecked} />
        );
      })}
    </AccordionItemPanel>
  </AccordionItem>
);

export default memo(EnvironmentAccordion);
