import { CustomError, DEFAULT_ERROR_MESSAGE } from '~/helpers/common/custom-error';
import { ScrollContainer } from '~/helpers/ui-kit/scroll-container';
import useMountEffect from '~/hooks/use-mount-effect';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import {
  EditCallback,
  Filters,
  PublishCallback,
  RemoveCallback,
  Row,
  RowClickCallback,
  SortingRule,
  TableDataLike,
  TableInstance,
  TableOptions,
  UpdateCallback,
  useAsyncDebounce,
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { DEFAULT_DEBOUNCE_DELAY } from '~/components/_table/hooks/use-table-fetch';
import { usePublish } from '~/components/_table/plugins/publish';
import { useTheme } from '~/components/theme';
import { TableStorageService } from '~/services/table-service';
import { TextCell } from './cells/text';
import { TableBody } from './components/body';
import { TextFilter } from './filters/text';
import { TextHeading } from './headings/text';
import { calculateOffset } from './helpers/calculate-column-offset';
import { useActions } from './plugins/actions';
import { useEditing } from './plugins/editing';
import { useLoading } from './plugins/loading';
import { useSelection } from './plugins/selection';
import { useWrapping } from './plugins/wrapping';
import { useEmptyColumn } from './plugins/empty-column';

import styles from './table.module.scss';
import { DEFAULT_PAGE_SIZE } from '~/components/_table/helpers/build-table-request';
import Loader from '~/components/loader';
import { mapGistToPluralEntity } from '~/components/_table/helpers/common';
import { Label } from '~/components/_layout/typography/label';
import TableContextProvider from '~/components/_table/context';
import { useRemoving } from '~/components/_table/plugins/removing';
import { useTableRefetch } from '~/components/_table/plugins/refetching';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Button } from '~/components/button';
import { useError } from '~/components/_table/plugins/error';

const DEFAULT_COLUMN = {
  title: '',
  Header: TextHeading,
  Cell: TextCell,
  Filter: TextFilter,
  minWidth: 90,
  width: 200,
  filter: 'text'
};

// Defaults to 'text'.
// Other built-in options include 'exactText', 'exactTextCase', 'includes', 'includesAll', 'includesSome', 'includesValue', 'exact', 'equals', and 'between'.
// See (https://github.com/tannerlinsley/react-table/blob/master/src/filterTypes.js) for implementation details
const FILTER_TYPES = {
  // Use this object to override existing
  // text: TextFilterType
  // Or to add custom filters
  // someCustomFilterType: MyCustomFilterTypeFn
};

export interface TableProps<Data extends TableDataLike> extends TableOptions<Data> {
  defaultSortBy?: SortingRule<Data>[]; // Warning!!! Must be memoized
  defaultFilterBy?: Filters<Data>; // Warning!!! Must be memoized
  onEditCell?: EditCallback<Data>; // Warning!!! Must be memoized
  onEdit?: EditCallback<Data>; // Warning!!! Must be memoized
  onUpdate: UpdateCallback<Data>; // Warning!!! Must be memoized
  onRemove?: RemoveCallback<Data>; // Warning!!! Must be memoized
  onPublish?: PublishCallback<Data>; // Warning!!! Must be memoized
  onRowClick?: RowClickCallback<Data>; // Warning!!! Must be memoized
  children?: (instance: TableInstance<Data>) => React.ReactNode;
  selectedRow?: Row<Data>['original'];
  showBody?: {
    loading: boolean;
    error: string | undefined;
  };
  preventGet?: boolean;
  disableResizing?: boolean;
  count: number;
}

const EMPTY_OBJECT = {};

export const Table = <Data extends TableDataLike>({
  columns,
  data,
  defaultSelected,
  defaultSortBy,
  defaultFilterBy,
  onEditCell,
  onEdit,
  onUpdate,
  onRemove,
  onPublish,
  onRowClick,
  children,
  selectedRow,
  showBody,
  preventGet,
  disableResizing,
  disableFilters,
  disableSortBy,
  count,
  gist,
  ...props
}: TableProps<Data>): React.JSX.Element => {
  const { affiliate, env } = useTheme();

  const tableIdentifiers = useMemo(() => ({ affiliate, env, gist }), [affiliate, env, gist]);

  const instance = useTable<Data>(
    {
      columns,
      data,
      defaultColumn: DEFAULT_COLUMN,
      filterTypes: FILTER_TYPES,
      initialState: {
        wrappingMode: TableStorageService.getWrapMode(tableIdentifiers),
        pageSize: DEFAULT_PAGE_SIZE,
        ...(defaultSelected && { selectedRowIds: defaultSelected }),
        ...(defaultSortBy && { sortBy: defaultSortBy }),
        ...(defaultFilterBy && { filters: { ...defaultFilterBy } }),
        ...(!disableFilters && { filters: TableStorageService.getMergedFilters(tableIdentifiers) })
      },
      handleCellEdit: onEditCell,
      handleEdit: onEdit,
      handlePublish: onPublish,
      handleUpdate: onUpdate,
      handleRemove: onRemove,
      disableResizing,
      disableFilters,
      ...props
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    usePagination,
    useLoading,
    useError,
    useEditing,
    useActions,
    useRowSelect,
    useSelection,
    usePublish,
    useWrapping,
    useEmptyColumn,
    useRemoving,
    useTableRefetch
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    state: { loading, sortBy, filters, pageIndex, pageSize, wrappingMode, columnResizing, error },
    allColumns
  } = instance;

  useMountEffect(() => {
    if (!disableResizing) {
      columnResizing.columnWidths = TableStorageService.getResizeData(tableIdentifiers) || EMPTY_OBJECT;
    }

    if (!disableSortBy) {
      const tableSortBy = TableStorageService.getSortBy(tableIdentifiers);

      if (tableSortBy?.length) {
        sortBy.splice(0, sortBy.length, ...tableSortBy);
      }
    }
  });

  useEffect(() => {
    if (!disableResizing && columnResizing.isResizingColumn === null) {
      TableStorageService.setResizeData({ ...tableIdentifiers, resizeData: columnResizing.columnWidths });
    }
  }, [columnResizing.columnWidths, columnResizing.isResizingColumn, gist, disableResizing, tableIdentifiers]);

  const offsets = useMemo(() => calculateOffset(allColumns), [allColumns]);

  useEffect(() => {
    if (!disableFilters) {
      TableStorageService.addQueryParams(TableStorageService.encodeFilters(filters), ['filters']);
      TableStorageService.setFilters({ ...tableIdentifiers, filters });
    }
  }, [filters, disableFilters, gist, tableIdentifiers]);

  useEffect(() => {
    if (!disableSortBy) {
      TableStorageService.setSortBy({ ...tableIdentifiers, sortBy });
    }
  }, [disableSortBy, sortBy, tableIdentifiers]);

  const debouncedOnUpdate = useAsyncDebounce(onUpdate, DEFAULT_DEBOUNCE_DELAY);

  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      if (!preventGet) {
        try {
          instance.setTableError(null);
          instance.toggleTableLoading(true);

          await debouncedOnUpdate({ sortBy, filters, pageIndex, pageSize });
        } catch (unknownError) {
          const error = new CustomError(unknownError ?? DEFAULT_ERROR_MESSAGE);
          instance.setTableError(error.message);
        } finally {
          instance.toggleTableLoading(false);
        }
      }
    })();
  }, [debouncedOnUpdate, preventGet, sortBy, filters, pageIndex, pageSize, instance, search]);

  const { theme } = useTheme();

  const loadMore = useCallback(
    async () =>
      await debouncedOnUpdate({
        sortBy,
        filters,
        pageIndex,
        pageSize: rows.length + 50
      }),
    [debouncedOnUpdate, filters, pageIndex, rows.length, sortBy]
  );

  const { CoachPopover: LoadingPopover, ...loadingProps } = useCoachPopover({
    timeout: 1000,
    loading,
    disabled: !!showBody?.loading
  });
  const { CoachPopover: ErrorPopover, ...errorProps } = useCoachPopover({ disabled: !error || !!showBody?.error });

  return (
    <>
      <LoadingPopover message={[`Loading your ${mapGistToPluralEntity[gist]}...`]} {...loadingProps} />
      <ErrorPopover message={error} {...errorProps}>
        <Button onClick={errorProps.unsubscribe} is='major' fluid>
          Ok
        </Button>
      </ErrorPopover>
      <TableContextProvider instance={instance}>
        <div className={styles.container} data-loading={loading} {...getTableProps()}>
          <InfiniteScroll
            dataLength={rows.length}
            next={loadMore}
            hasMore={count > rows.length}
            loader={
              <Loader>
                <Label size='s'>Loading more {mapGistToPluralEntity[gist]}</Label>
              </Loader>
            }
          >
            <ScrollContainer at='x'>
              <div className={styles.wrapper}>
                {headerGroups.map(headerGroup => {
                  const { key, style, ...props } = headerGroup.getHeaderGroupProps();

                  return (
                    <div key={key} className={classnames(styles.row, styles['sticky-header'])} style={style} {...props}>
                      {headerGroup.headers.map(
                        ({ isSorted, className, sticky, getHeaderProps, render, empty }, index) => {
                          const { key, ...props } = getHeaderProps({ className });

                          return (
                            <div
                              key={key}
                              className={classnames(styles.heading, styles[theme], {
                                [styles.sorted]: isSorted,
                                [styles.sticky]: sticky,
                                [styles.empty]: empty
                              })}
                              {...props}
                              style={{ ...props.style, ...(sticky && { [sticky]: offsets[sticky][index + 1] }) }}
                            >
                              {render('Header')}
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                })}

                <TableBody {...getTableBodyProps()} showBody={showBody}>
                  {page.map(row => {
                    // Prepare the row for display
                    prepareRow(row);

                    const { key, style, ...props } = row.getRowProps();

                    return (
                      <div
                        key={key}
                        className={classnames(styles.row, {
                          [styles.highlighted]: selectedRow && selectedRow.id === row.original.id
                        })}
                        onClick={onRowClick ? () => onRowClick(row.original) : undefined}
                        style={style}
                        {...props}
                      >
                        {row.cells.map(
                          ({ column: { isSorted, className, sticky, empty }, getCellProps, render }, index) => {
                            const { key, ...props } = getCellProps({ className });

                            return (
                              <div
                                key={key}
                                className={classnames(styles.cell, styles[theme], {
                                  [styles.sorted]: isSorted,
                                  [styles.sticky]: sticky,
                                  [styles.empty]: empty
                                })}
                                {...props}
                                style={{ ...props.style, ...(sticky && { [sticky]: offsets[sticky][index + 1] }) }}
                              >
                                {render('Cell', { wrappingMode })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </TableBody>
              </div>
            </ScrollContainer>
          </InfiniteScroll>
        </div>
        {/* Instance provider for external slots */}
        {children && children(instance)}
      </TableContextProvider>
    </>
  );
};
