import React from 'react';
import { Icon } from '~/components/icon';

import styles from './font-thumb.module.scss';

const FontThumb = () => {
  return (
    <div className={styles.container}>
      <Icon name='font' />
    </div>
  );
};

export default FontThumb;
