import React, { useCallback, useMemo, useState } from 'react';
import { SelectItem } from '~/components/_inputs/select';
import { OuterUploaderHandlers } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { ORDER_ITEMS } from '~/components/_layout/popovers/upload/constants';
import { HandleFileInteraction, OrderItem } from '~/components/_layout/popovers/upload/types';
import { useDebounce } from '~/hooks/use-debounce';
import { UsePopover } from '~/components/popover';
import { FileObject } from '~/types/gists/file';

type UseHandleFilesProps = Pick<OuterUploaderHandlers, 'handleFileInteraction'> & {
  uploaderRef: React.RefObject<HTMLInputElement>;
  toggle: UsePopover['toggle'];
};

type UseHandleFilesReturn = {
  state: {
    filter: string;
    selectedSorting: SelectItem<OrderItem>;
  };
  methods: {
    onChangeFilter: (name: string, value: string) => void;
    onChangeOrder: () => (option: SelectItem<OrderItem>) => void;
    handleSelect: HandleFileInteraction;
    handleRemove: () => void;
    handleUpload: () => void;
  };
};

export const useHandleFiles = ({
  toggle,
  uploaderRef,
  handleFileInteraction
}: UseHandleFilesProps): UseHandleFilesReturn => {
  const handleUpload = useCallback(() => {
    uploaderRef.current?.click();
    toggle(true);
  }, [uploaderRef, toggle]);

  const handleRemove = useCallback(async () => {
    handleFileInteraction()();
    toggle(false);
  }, [handleFileInteraction, toggle]);

  const handleSelect = useCallback(
    (file?: FileObject) => () => {
      handleFileInteraction(file)();
      toggle(false);
    },
    [handleFileInteraction, toggle]
  );

  const [filter, setFilter] = useState<string>('');

  const onChangeFilter = useCallback((_, value: string) => {
    setFilter(value);
  }, []);

  const delayedFilter = useDebounce<string>(filter);

  const [orderBy, setOrderBy] = useState<SelectItem<OrderItem>>(ORDER_ITEMS[0]);

  const selectedSorting = useMemo(
    () => ORDER_ITEMS.find(({ id }) => id === orderBy.id) as SelectItem<OrderItem>,
    [orderBy]
  );

  const onChangeOrder = useCallback(
    () => (option: SelectItem<OrderItem>) => {
      setOrderBy(option);
    },
    []
  );

  return useMemo(() => {
    return {
      state: {
        filter: delayedFilter,
        selectedSorting
      },
      methods: {
        onChangeFilter,
        onChangeOrder,
        handleRemove,
        handleSelect,
        handleUpload
      }
    };
  }, [delayedFilter, handleRemove, handleSelect, onChangeFilter, onChangeOrder, handleUpload, selectedSorting]);
};
