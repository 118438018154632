import React, { ElementType, PropsWithChildren, useEffect, useState } from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { TableBodyProps } from 'react-table';

import { LifeHubLogo } from '~/components/_layout/logo';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { Icon } from '~/components/icon';

import styles from './body.module.scss';

type OwnProps = PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> &
    TableBodyProps & {
      showBody:
        | {
            loading: boolean;
            error: string | undefined;
          }
        | undefined;
    }
>;

type Props = PolymorphicComponentProps<ElementType, OwnProps>;

const defaultElement = 'div';

export const TableBody = ({ children, showBody, ...rest }: Props): React.JSX.Element => {
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setDelay(false), 1250);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (showBody && (showBody.loading || delay)) {
    return (
      <div className={styles.container}>
        <LifeHubLogo className={styles.icon} animate />
        <CoachMessage>Loading</CoachMessage>
      </div>
    );
  }

  if (showBody?.error) {
    return (
      <div className={styles.container}>
        <Icon name='alert' className={styles.icon} />
        <CoachMessage>{showBody.error}</CoachMessage>
      </div>
    );
  }

  return (
    <Box as={defaultElement} {...rest}>
      {children}
    </Box>
  );
};
