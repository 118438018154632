import React from 'react';

import { Button } from '~/components/button';
import { Icon } from '~/components/icon';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';

import styles from '../styles.module.scss';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

type Props = {
  rulesetId: string;
};

const AddRule = ({ rulesetId }: Props): React.JSX.Element => {
  const { addRule } = useConditionBuilderContext();

  return (
    <TooltipOnHover title='Add rule'>
      <Button is='minor' className={styles['add-rule']} onClick={() => addRule(rulesetId)}>
        <Icon name='plus' />
      </Button>
    </TooltipOnHover>
  );
};

export default AddRule;
