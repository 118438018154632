import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import { useMemo } from 'react';

const useQuery = (): ParsedQuery<string> => {
  const { search } = useLocation();

  return useMemo(() => queryString.parse(search), [search]);
};

export default useQuery;
