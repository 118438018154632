import React, { useCallback } from 'react';

import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Button } from '~/components/button';
import { Confirm, ConfirmCallback } from '~/components/_layout/popovers/confirm';

const Delete = () => {
  const { cellProps } = useFilePrimaryCellContext();

  const {
    row: { original, handleRemove },
    toggleTableLoading
  } = cellProps;

  const onConfirm = useCallback<ConfirmCallback>(
    async (setLoading, setReady) => {
      toggleTableLoading(true);
      await handleRemove(cellProps, setLoading, setReady);
      toggleTableLoading(false);
    },
    [cellProps, handleRemove, toggleTableLoading]
  );

  return (
    <Confirm
      message={[
        <span key='permanently-delete'>
          Are you sure you want to permanently delete <strong>{original.name}</strong>
        </span>,
        'This can’t be undone.'
      ]}
      onConfirm={onConfirm}
    >
      {toggle => (
        <TooltipOnHover title='Delete' delay={100}>
          <Button is='minor' icon='bin' onClick={() => toggle(true)} />
        </TooltipOnHover>
      )}
    </Confirm>
  );
};

export default Delete;
