import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { enableMapSet } from 'immer';

import { useBugsnagInitialization } from '~/hooks/use-bugsnag';

import App from './App';
import { store } from './store';
import { Theme } from './components/theme';
import { Slots } from './components/slots';
import './styles/index.scss';

enableMapSet();

const BugsnagBoundary = ({ children }: PropsWithChildren<Record<any, any>>): React.JSX.Element => {
  useBugsnagInitialization();
  return <>{children}</>;
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <BugsnagBoundary>
        <Theme>
          <Slots>
            <App />
          </Slots>
        </Theme>
      </BugsnagBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
