import { Column } from 'react-table';

import { PrimaryCell } from '~/components/_table/cells/primary';
import { SwitchCell } from '~/components/_table/cells/switch';
import TagsCell from '~/components/_table/cells/tags';
import { formatDate } from '~/helpers/formatters';
import { JSONCell } from '~/components/_table/cells/json';
import { TagsFilter } from '~/components/_table/filters/tags';
import { Attribute } from '~/types/gists/attribute';
import DataTypeSelectCell from '~/pages/datasets/attributes/components/data-type-select-cell';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';

export const columns: Column<Attribute>[] = [
  {
    title: 'Group',
    accessor: 'prefix',
    width: 128
  },
  {
    title: 'Display name',
    Cell: PrimaryCell,
    accessor: 'displayName',
    to: DATASETS_ROUTES.attributes.table,
    actionTooltip: 'Edit Attribute',
    primary: true
  },
  {
    title: 'System name',
    accessor: 'name'
  },
  {
    title: 'Description',
    accessor: 'description'
  },
  {
    title: 'Data type',
    accessor: 'type',
    width: 128,
    Cell: DataTypeSelectCell
  },
  {
    title: 'Formula',
    accessor: 'formula'
  },
  {
    title: 'Validation',
    accessor: 'validationData',
    Cell: JSONCell,
    actionTooltip: 'Reset',
    disableFilters: true,
    disableSortBy: true
  },
  {
    title: 'Historical data',
    accessor: 'isSaveHistoricalData',
    disableFilters: true,
    Cell: SwitchCell
  },
  {
    title: 'Tags',
    accessor: 'tags',
    Cell: TagsCell,
    disableSortBy: true,
    Filter: TagsFilter
  },
  {
    title: 'Data created',
    accessor: 'createdAt',
    format: formatDate,
    disableEdit: true
  },
  {
    title: 'Created by',
    accessor: 'createdBy',
    disableEdit: true
  },
  {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableEdit: true
  },
  {
    title: 'Updated by',
    accessor: 'updatedBy',
    disableEdit: true
  }
];
