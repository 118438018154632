import React from 'react';
import { Heading } from '~/components/_layout/typography/heading';
import styles from './current-environment-section.module.scss';
import { useTheme } from '~/components/theme';
import { Image } from '~/components/image';
import { EnvironmentIndicator } from '~/components/_layout/environment-indicator';
import useOpenProductInNewTab from '~/hooks/use-open-product-in-new-tab';

export const CurrentEnvironmentSection = (): React.JSX.Element => {
  const { affiliateDetails } = useTheme();

  const openProductInNewTab = useOpenProductInNewTab();

  return (
    <section className={styles.container}>
      <div className={styles.logo} onClick={openProductInNewTab}>
        {affiliateDetails?.iconUrl && <Image src={affiliateDetails?.iconUrl} alt='Affiliate Logo' />}
      </div>
      <div className={styles['info-container']}>
        <Heading size='l'>{affiliateDetails?.name}</Heading>
        <div className={styles.info}>
          <div className={styles.env}>
            <EnvironmentIndicator />
          </div>
        </div>
      </div>
    </section>
  );
};
