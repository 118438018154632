import {
  Boundary,
  Context,
  Modifier,
  ModifierArguments,
  Padding,
  Placement,
  RootBoundary,
  State
} from '@popperjs/core';

import maxSizeModifier from 'popper-max-size-modifier';

type Options = {
  placement: Placement;
  boundary: Boundary;
  rootBoundary: RootBoundary;
  elementContext: Context;
  altBoundary: boolean;
  padding: Padding;
  state: State & {
    modifiersData: {
      maxSize: {
        width: number;
        height: number;
      };
    };
  };
};

type SizeModifier<Name> = Modifier<Name, Options>;

const applyMaxSize = function ({ state }: ModifierArguments<Options>): State | void {
  const { height, width } = state.modifiersData.maxSize;

  state.styles.popper.maxHeight = `${height}px`;
  state.styles.popper.maxWidth = `${width}px`;
};

const applyMaxWidth = function ({ state }: ModifierArguments<Options>): State | void {
  const { width } = state.modifiersData.maxSize;

  state.styles.popper.maxWidth = `${width}px`;
};

const applyMaxHeight = function ({ state }: ModifierArguments<Options>): State | void {
  const { height } = state.modifiersData.maxSize;

  state.styles.popper.maxHeight = `${height}px`;
};

export const maxSize: [typeof maxSizeModifier, SizeModifier<'applyMaxSize'>] = [
  maxSizeModifier,
  {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn: applyMaxSize
  }
];

export const maxWidth: [typeof maxSizeModifier, SizeModifier<'applyMaxWidth'>] = [
  maxSizeModifier,
  {
    name: 'applyMaxWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn: applyMaxWidth
  }
];

export const maxHeight: [typeof maxSizeModifier, SizeModifier<'applyMaxHeight'>] = [
  maxSizeModifier,
  {
    name: 'applyMaxHeight',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn: applyMaxHeight
  }
];
