import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, PropsWithChildren, useEffect, useState } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { isResetOnBlurEvent } from '~/components/contenteditable';
import { ContentEditableAsInput } from '~/components/contenteditable/input';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Label } from '~/components/_layout/typography/label';

import styles from './text-cell.module.scss';

type Props<Data extends TableDataLike> = CellProps<Data>;
type TextProps = PropsWithChildren<{ title?: string; truncated?: boolean }>;

const Text = ({ children, title, truncated = false }: TextProps): React.JSX.Element => (
  <div className={styles.text}>
    <TooltipOnHover title={title}>
      <Label size='s' truncated={truncated}>
        {children}
      </Label>
    </TooltipOnHover>
  </div>
);

export const TextCell = <Data extends TableDataLike>({ cell, wrappingMode }: Props<Data>): React.JSX.Element => {
  const {
    column: { id: colId, canEdit, editCell, setEditing, format, tooltip },
    row: { id: rowId, original },
    value: unformatted
  } = cell;

  const value = format ? format(unformatted) : unformatted;

  const [state, setState] = useState(value);

  const handleClick = () => {
    setEditing(colId, rowId, true);
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setState(target.value);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const nextValue = event.target.value;

    if (isResetOnBlurEvent(event)) {
      setState(value);
    } else if (nextValue && value !== nextValue) {
      editCell(cell, nextValue);
    }

    setEditing(colId, rowId, false);
  };

  useEffect(() => {
    setState(value);
  }, [value]);

  const tooltipTitle = tooltip ? tooltip(original) : value;
  const isWrapMode = wrappingMode === 'wrap';

  return (
    <div className={styles.container}>
      {canEdit ? (
        <ContentEditableAsInput
          as={Text}
          value={state}
          className={classnames(styles.input, { [styles.wrap]: isWrapMode })}
          title={tooltipTitle}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
          keyResetters={[{ key: 'escape' }]}
        />
      ) : (
        <div className={classnames(styles.label, { [styles.wrap]: isWrapMode })}>
          <Text truncated={!isWrapMode} title={tooltipTitle}>
            {value}
          </Text>
        </div>
      )}
    </div>
  );
};
