import {
  DeleteTableItemParams,
  ExportParams,
  ExportResponse,
  GetHistoryParams,
  GetSingleParams,
  GetTableItemsParams,
  GetTableItemsResponse,
  ImportParams,
  ImportResponse
} from '~/components/_table/types';
import { get, post, put, remove } from './';
import { mapEntityToSingular } from '~/api/utils/helpers';

export const createTableApi = (path: string): CreateTableApi => ({
  get: async <T>(params: GetTableItemsParams): Promise<GetTableItemsResponse<T>> =>
    await get<GetTableItemsResponse<T>, GetTableItemsParams>(path, params),
  getSingle: async <T>(params: GetSingleParams): Promise<T> => await get<T, GetSingleParams>(`${path}/${params.id}`),
  add: async <T, R = T>(params: T): Promise<R> => await post<R, T>(path, params),
  edit: async <T extends HasId, R = T>(params: T): Promise<R> => await put<R, T>(`${path}/${params.id}`, params),
  remove: async <T>(params: DeleteTableItemParams): Promise<T> => await remove<T, DeleteTableItemParams>(path, params),
  removeSingle: async <T extends HasId>(params: T): Promise<T> => await remove<T, T>(`${path}/${params.id}`),
  getHistory: async <T>({ id, ...params }: GetHistoryParams): Promise<GetTableItemsResponse<T>> =>
    get<GetTableItemsResponse<T>, Omit<GetHistoryParams, 'id'>>(`${path}/${id}/history`, params),
  cloneSingle: async <T extends HasId>(params: T): Promise<T> =>
    await post<T, T>(`/clone${mapEntityToSingular(path)}/${params.id}`, params),
  exportItems: async <T>(params: ExportParams): Promise<ExportResponse<T>> =>
    await get<ExportResponse<T>, ExportParams>(`${path}/export`, { ...params, limit: 2000 }),
  importItems: async <T>(params: ImportParams<T>): Promise<ImportResponse> =>
    await post<ImportResponse, ImportParams<T>>('/import', params)
});

type CreateTableApi = {
  get: <T>(params: GetTableItemsParams) => Promise<GetTableItemsResponse<T>>;
  getSingle: <T>(params: GetSingleParams) => Promise<T>;
  add: <T, R = T>(params: T) => Promise<R>;
  edit: <T extends HasId, R = T>(params: T) => Promise<R>;
  remove: <T>(params: DeleteTableItemParams) => Promise<T>;
  removeSingle: <T extends HasId>(params: T) => Promise<T>;
  getHistory: <T>(params: GetHistoryParams) => Promise<GetTableItemsResponse<T>>;
  cloneSingle: <T extends HasId>(params: T) => Promise<T>;
  exportItems: <T>(params: ExportParams) => Promise<ExportResponse<T>>;
  importItems: <T>(params: ImportParams<T>) => Promise<ImportResponse>;
};
