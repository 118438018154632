import React from 'react';

import styles from './file-details.module.scss';
import Sidebar from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar';
import Preview from '~/components/_table/cells/file-primary/components/FileDetails/components/Preview';

const FileDetails = () => (
  <div className={styles.container}>
    <Sidebar />
    <Preview />
  </div>
);

export default FileDetails;
