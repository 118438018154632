import React from 'react';
import classnames from 'classnames';
import { ColumnInstance, HeaderProps, TableDataLike } from 'react-table';
import { Label } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';

import styles from './text-heading.module.scss';

type SortByProps<Data extends TableDataLike> = {
  children?: React.ReactNode;
} & ColumnInstance<Data>;

const SortBy = <Data extends TableDataLike>({
  canSort,
  isSorted,
  getSortByToggleProps,
  children
}: SortByProps<Data>): React.JSX.Element => {
  return (
    <div className={styles.row} {...getSortByToggleProps()}>
      {canSort && <Icon name='sort' className={classnames(styles.sort, { [styles.sorted]: isSorted })} />}
      {children}
    </div>
  );
};

const Resize = <Data extends TableDataLike>({
  isResizing,
  getResizerProps,
  canFilter
}: ColumnInstance<Data>): React.JSX.Element => (
  <div
    {...getResizerProps()}
    className={classnames(styles.resizer, { [styles.resizing]: isResizing, [styles['full-height']]: !canFilter })}
    style={{ cursor: `ew-resize` }}
  />
);

type FilterByProps<Data extends TableDataLike> = {
  children?: React.ReactNode;
} & ColumnInstance<Data>;

const FilterBy = <Data extends TableDataLike>({ filterValue, render }: FilterByProps<Data>): React.JSX.Element => {
  return (
    <div className={styles.row}>
      <Icon name='filter' className={classnames(styles.filter, { [styles.filtered]: Boolean(filterValue) })} />
      {render('Filter')}
    </div>
  );
};

export const TextHeading = <Data extends TableDataLike>({ column }: HeaderProps<Data>): React.JSX.Element => {
  const { title, canFilter, canResize, primary } = column;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <SortBy {...column}>
          <Label className={classnames(styles.label, { [styles.primary]: primary })} truncated>
            {title}
          </Label>
        </SortBy>
        {canFilter && <FilterBy {...column} />}
        {canResize && <Resize {...column} />}
      </div>
    </div>
  );
};
