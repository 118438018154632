import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import PageHeading from '~/components/_layout/page-heading';
import { useTheme } from '~/components/theme';
import { useSelector } from '~/store/hooks';
import { UserService } from '~/services/user-service';
import { FUNCTIONAL_PERMISSIONS_KEY } from '~/types/gists/user';
import MaintenanceSwitcher from '~/pages/settings/components/maintenance-switcher';

import styles from './styles.module.scss';

const Settings = (): React.JSX.Element => {
  const { env, affiliate } = useTheme();
  const history = useHistory();

  const isAppReady = useSelector(state => state.meta.isAppReady);

  const redirectToHomePage = useCallback(
    () => history.push('/' + `?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, history]
  );
  useEffect(() => {
    if (isAppReady && !UserService.isFunctionalModuleVisible(FUNCTIONAL_PERMISSIONS_KEY.Settings)) {
      redirectToHomePage();
    }
  }, [isAppReady, redirectToHomePage]);

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Settings' />
        </Breadcrumb>
      </Slot>
      <header className={styles.container}>
        <PageHeading title={'Settings'} icon='settings' />
      </header>
      {isAppReady && (
        <div className={styles['item-container']}>
          <MaintenanceSwitcher />
        </div>
      )}
    </>
  );
};

export default Settings;
