import React from 'react';

import styles from './styles.module.scss';
import { Icon } from '~/components/icon';

const ErrorState = (): React.JSX.Element => (
  <div className={styles.container}>
    <Icon name={'alert'} />
    <p>Sorry, I can’t visualise your condition code as it’s either too complex or contains an error.</p>
    <p>Please update your code to edit it here.</p>
  </div>
);

export default ErrorState;
