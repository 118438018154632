import React, { InputHTMLAttributes } from 'react';
import styles from '~/pages/files/components/files-table/components/add-files/components/uploader/styles.module.scss';

const UploadButton = React.forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
  <input type='file' className={styles.input} ref={ref} multiple value='' {...props} />
));

UploadButton.displayName = 'UploadButton';

export default UploadButton;
