import { TableApi, TableEntitiesPlural } from '~/components/_table/types';

export const calculatePageCount = (count: number): number => Math.ceil(count / 50);

type GistToTableEntitiesPlural = {
  [key in TableApi]: TableEntitiesPlural;
};

export const mapGistToPluralEntity: GistToTableEntitiesPlural = {
  pushNotifications: 'Pushes',
  cohorts: 'Cohorts',
  attributes: 'Attributes',
  images: 'Images',
  icons: 'Icons',
  fonts: 'Fonts',
  blocks: 'Blocks',
  datasets: 'Datasets',
  pages: 'Pages'
};
