import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './form-field.module.scss';

type Props = PropsWithChildren<{
  className?: string;
}>;

export const FormField = ({ children, className }: Props): React.JSX.Element => {
  return <div className={classnames(styles.container, className)}>{children}</div>;
};
