import { CustomError } from '~/helpers/common/custom-error';
import { BESavePagePayload, PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { getPayloadForPageSaving, getFirstInvalidFieldName } from '~/pages/pages/edit/context/store/selectors';
import { pages as pagesApi } from '~/api/pages';
import { setError, setIsEnabled, setIsTouched, setUpdatedMetaFields } from '~/pages/pages/edit/context/store/actions';
import { Page } from '~/types/gists/page';

export const preSubmitValidation = (): PageEditorThunk<boolean> => (dispatch, getState) => {
  const firstValidationError = getFirstInvalidFieldName(getState());
  if (!firstValidationError) return true;

  dispatch(setError(firstValidationError));
  return false;
};

export const savePage =
  (withPublish = false): PageEditorThunk<Promise<Page | void>> =>
  async (dispatch, getState) => {
    const payload = getPayloadForPageSaving(getState());

    try {
      return await pagesApi.add<BESavePagePayload, Page>({ ...payload, isEnabled: withPublish });
    } catch (unknownError) {
      const error = new CustomError(unknownError ?? 'Failed to save page data');
      dispatch(setError(error.message));
      return void 0;
    }
  };

export const updatePage = (): PageEditorThunk<Promise<Page | void>> => async (dispatch, getState) => {
  const payload = getPayloadForPageSaving(getState());

  try {
    const { updatedBy, updatedAt } = await pagesApi.edit<BESavePagePayload, Page>(payload);
    dispatch(setUpdatedMetaFields({ updatedBy, updatedAt }));
    dispatch(setIsTouched(false));
  } catch (unknownError) {
    const error = new CustomError(unknownError ?? 'Failed to update page data');
    dispatch(setError(error?.message));
  }
};

export const publishPage = (): PageEditorThunk<Promise<Page | void>> => async (dispatch, getState) => {
  const payload = getPayloadForPageSaving(getState());

  try {
    const { updatedBy, updatedAt } = await pagesApi.edit<BESavePagePayload, Page>({ ...payload, isEnabled: true });
    dispatch(setIsEnabled(true));
    dispatch(setUpdatedMetaFields({ updatedBy, updatedAt }));
    dispatch(setIsTouched(false));
  } catch (unknownError) {
    const error = new CustomError(unknownError ?? 'Failed to update page data');
    dispatch(setError(error?.message));
  }
};
