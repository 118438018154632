import { COMPONENT_TYPES } from '@life-moments/lifehub-components';
import TEXT_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/text-block-thumbnail-light.svg';
import TEXT_BLOCK_THUMBNAIL_DARK from '~/assets/images/text-block-thumbnail-dark.svg';
import HEADING_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/heading-block-thumbnail-light.svg';
import HEADING_BLOCK_THUMBNAIL_DARK from '~/assets/images/heading-block-thumbnail-dark.svg';
import SPACER_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/spacer-block-thumbnail-light.svg';
import SPACER_BLOCK_THUMBNAIL_DARK from '~/assets/images/spacer-block-thumbnail-dark.svg';
import BULLET_LIST_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/bullet-list-block-thumbnail-light.svg';
import BULLET_LIST_BLOCK_THUMBNAIL_DARK from '~/assets/images/bullet-list-block-thumbnail-dark.svg';
import NUMBER_LIST_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/number-list-block-thumbnail-light.svg';
import NUMBER_LIST_BLOCK_THUMBNAIL_DARK from '~/assets/images/number-list-block-thumbnail-dark.svg';
import IMAGE_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/image-block-thumbnail-light.svg';
import IMAGE_BLOCK_THUMBNAIL_DARK from '~/assets/images/image-block-thumbnail-dark.svg';
import QUOTE_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/quote-block-thumbnail-light.svg';
import QUOTE_BLOCK_THUMBNAIL_DARK from '~/assets/images/quote-block-thumbnail-dark.svg';
import METABOX_BLOCK_THUMBNAIL_LIGHT from '~/assets/images/metabox-block-thumbnail-light.svg';
import METABOX_BLOCK_THUMBNAIL_DARK from '~/assets/images/metabox-block-thumbnail-dark.svg';

export const BLOCK_TYPES_TO_THUMBNAILS_PATH: Partial<Record<COMPONENT_TYPES, Record<Theme, string>>> = {
  [COMPONENT_TYPES.text]: {
    light: TEXT_BLOCK_THUMBNAIL_LIGHT,
    dark: TEXT_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.heading]: {
    light: HEADING_BLOCK_THUMBNAIL_LIGHT,
    dark: HEADING_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.spacer]: {
    light: SPACER_BLOCK_THUMBNAIL_LIGHT,
    dark: SPACER_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.bulletList]: {
    light: BULLET_LIST_BLOCK_THUMBNAIL_LIGHT,
    dark: BULLET_LIST_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.numberList]: {
    light: NUMBER_LIST_BLOCK_THUMBNAIL_LIGHT,
    dark: NUMBER_LIST_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.image]: {
    light: IMAGE_BLOCK_THUMBNAIL_LIGHT,
    dark: IMAGE_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.quote]: {
    light: QUOTE_BLOCK_THUMBNAIL_LIGHT,
    dark: QUOTE_BLOCK_THUMBNAIL_DARK
  },
  [COMPONENT_TYPES.metabox]: {
    light: METABOX_BLOCK_THUMBNAIL_LIGHT,
    dark: METABOX_BLOCK_THUMBNAIL_DARK
  }
};
