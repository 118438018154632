import { EntityMetaData } from '~/types/gists/misc';

export enum MaintenanceStatusEnum {
  TurningOn = 'turningOn',
  TurningOff = 'turningOff',
  Failed = 'failed',
  Completed = 'completed'
}

export type MaintenanceStatus = MaintenanceStatusEnum | null;

export type AffiliateInfo = EntityMetaData & {
  baseUrl: string;
  id: number;
  name: string;
  icon: string;
  client: string;
  database: string;
  awsRegion: string;
  key: AffiliateKey;
  dataRetentionPeriod: number;
  isGenerateSitemap: boolean;
  iconUrl: string;
  fullDashboardUrl: string;
  embedDashboardParams: {
    awsAccountId: string;
    dashboardId: string;
    namespace: string;
    region: string;
    username: string;
  };
  maintenance: boolean;
  readonly maintenanceStatus: MaintenanceStatus;
};

export type Affiliates = Record<
  AffiliateInfo['key'],
  {
    environments: Environment[];
    affiliateInfo: AffiliateInfo;
  }
>;
