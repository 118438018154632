import React from 'react';
import classnames from 'classnames';
import { Box } from 'react-polymorphic-box';
import { Label } from '~/components/_layout/typography/label';
import { LinkWithQuery } from '~/components/link';

import styles from './breadcrumb.module.scss';

type Container = {
  children: React.ReactNode[] | React.ReactNode;
};

export function Breadcrumb({ children }: Container): React.JSX.Element {
  return (
    <div className={styles.container}>
      {React.Children.map(children, (child, index) => {
        const childrenLength = React.Children.toArray(children).length;

        if (index + 1 === childrenLength && React.isValidElement(child)) {
          return React.cloneElement(child, {
            // TODO: Need to find work around for this /or replace.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            active: true
          });
        }

        return child;
      })}
    </div>
  );
}

type Item = {
  active?: boolean;
  label: string;
  to?: string;
  truncated?: boolean;
};

const defaultElement = 'span';

Breadcrumb.Item = React.memo(({ active, label, truncated, ...rest }: Item): React.JSX.Element => {
  const Component = active ? defaultElement : LinkWithQuery;

  return (
    <Label size='s' className={classnames(styles.item, { [styles.clipped]: truncated })} truncated={truncated}>
      <span>/</span>
      <Box as={Component} className={classnames(styles.link, { [styles.active]: active })} {...rest}>
        {label}
      </Box>
    </Label>
  );
});
