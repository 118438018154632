import React, { FC } from 'react';
import styles from './index.module.scss';

export const CoachButtons: FC = ({ children }): React.JSX.Element => (
  <div className={styles.buttons}>
    {React.Children.map(children, child => {
      return child;
    })}
  </div>
);
