import { SelectItem } from '~/components/_inputs/select';
import { BREAKPOINT_LABELS, ZOOM_VALUES } from '~/pages/pages/edit/context/store/enums';

/**
 * Describes subset of breakpoint options used by segmented buttons.
 */
export const BREAKPOINT_ITEMS: Record<BREAKPOINT_LABELS, { label: BREAKPOINT_LABELS; size: number }> = {
  [BREAKPOINT_LABELS.Small]: {
    label: BREAKPOINT_LABELS.Small,
    size: 375
  },
  [BREAKPOINT_LABELS.Medium]: {
    label: BREAKPOINT_LABELS.Medium,
    size: 768
  },
  [BREAKPOINT_LABELS.Large]: {
    label: BREAKPOINT_LABELS.Large,
    size: 1105
  }
};

export const breakpointSegments = Object.values(BREAKPOINT_ITEMS);

/**
 * Describes subset of zoom options used by dropdown selector.
 */
export const ZOOM_ITEMS: Record<ZOOM_VALUES, SelectItem<ZOOM_VALUES>> = {
  [ZOOM_VALUES.half]: {
    id: ZOOM_VALUES.half,
    label: `${ZOOM_VALUES.half}%`,
    value: ZOOM_VALUES.half,
    disabled: false
  },
  [ZOOM_VALUES.threeQuarters]: {
    id: ZOOM_VALUES.threeQuarters,
    label: `${ZOOM_VALUES.threeQuarters}%`,
    value: ZOOM_VALUES.threeQuarters,
    disabled: false
  },
  [ZOOM_VALUES.full]: {
    id: ZOOM_VALUES.full,
    label: `${ZOOM_VALUES.full}%`,
    value: ZOOM_VALUES.full,
    disabled: false
  },
  [ZOOM_VALUES.oneAndQuarter]: {
    id: ZOOM_VALUES.oneAndQuarter,
    label: `${ZOOM_VALUES.oneAndQuarter}%`,
    value: ZOOM_VALUES.oneAndQuarter,
    disabled: false
  },
  [ZOOM_VALUES.oneAndHalf]: {
    id: ZOOM_VALUES.oneAndHalf,
    label: `${ZOOM_VALUES.oneAndHalf}%`,
    value: ZOOM_VALUES.oneAndHalf,
    disabled: false
  },
  [ZOOM_VALUES.double]: {
    id: ZOOM_VALUES.double,
    label: `${ZOOM_VALUES.double}%`,
    value: ZOOM_VALUES.double,
    disabled: false
  }
};

export const zoomOptions: SelectItem<ZOOM_VALUES>[] = Object.values(ZOOM_ITEMS);
