import { useContext } from 'react';
import { ConditionBuilderContext, ContextReturnType } from '~/pages/cohorts/edit/ConditionBuilder/context';

const useConditionBuilderContext = (): ContextReturnType => {
  const context = useContext(ConditionBuilderContext);

  return context;
};

export default useConditionBuilderContext;
