import React, { useEffect } from 'react';
import { Input } from '~/components/_inputs/input';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { BUILDER_TYPES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Select, SelectItem } from '~/components/_inputs/select';
import { Heading } from '~/components/_layout/typography/heading';

import styles from '../styles.module.scss';

const BOOL_SELECT_ITEMS: Array<SelectItem<string>> = [
  {
    value: 'true',
    label: 'true',
    id: 'true'
  },
  {
    value: 'false',
    label: 'false',
    id: 'false'
  }
];

const valueToSelectItem = <T extends string>(value: T): SelectItem<T> => ({
  value,
  id: value,
  label: value
});

const mapItemsToSelect = <T extends string>(items: Array<T>): Array<SelectItem<T>> => items.map(valueToSelectItem);

type Props = {
  ruleId: string;
};

const RuleValue = ({ ruleId }: Props): React.JSX.Element => {
  const { rules, setRuleValueById, setIsError } = useConditionBuilderContext();

  const { type, value, options } = rules[ruleId];

  useEffect(() => {
    if (value && options?.length && !options.includes(value as string)) {
      setIsError(true);
    }
  }, [options, setIsError, value]);

  if (type === BUILDER_TYPES.BOOL) {
    return (
      <Select<string>
        name='select-rule-boolean-value'
        items={BOOL_SELECT_ITEMS}
        placeholder='True/False'
        isInOverlay={true}
        headerElement={<Heading size='xs'>Select a value</Heading>}
        closeButton
        value={value ? valueToSelectItem(value as string) : undefined}
        onChange={() =>
          ({ value }) =>
            setRuleValueById(ruleId, value)}
        multiLine
        containerClassName={styles['select-container']}
        disabled={!type}
      />
    );
  }

  if (options?.length) {
    return (
      <Select<string>
        name='select-rule-value-from-available-values'
        items={mapItemsToSelect(options)}
        placeholder='Select a value'
        isInOverlay={true}
        headerElement={<Heading size='xs'>Select a value</Heading>}
        closeButton
        value={value ? valueToSelectItem(value as string) : undefined}
        onChange={() =>
          ({ value }) =>
            setRuleValueById(ruleId, value)}
        multiLine
        containerClassName={styles['select-container']}
        disabled={!type}
      />
    );
  }

  return (
    <Input
      value={(value as number | string) || ''}
      onChange={(_, value) => setRuleValueById(ruleId, value)}
      type={type || 'text'}
      disabled={!type}
      className={styles['rule-value']}
    />
  );
};

export default RuleValue;
