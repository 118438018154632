import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';

import { OnboardingTemplate } from '~/templates/onboarding';

//Here we are going to call some user related API later
export const LoggingIn = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  useEffect(() => {
    const timeout = setTimeout(() => history.push('/'), 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history]);

  return <OnboardingTemplate message='Excellent, logging you in now...!' />;
};
