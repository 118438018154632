import React from 'react';
import { FeatureIcon, FeatureIcons } from '~/components/icon';
import { Heading } from '~/components/_layout/typography/heading';

import styles from './styles.module.scss';
import classnames from 'classnames';

type Props = {
  icon: keyof FeatureIcons;
  title: string;
  subtitle?: string;
};

const PageHeading = ({ icon, title, subtitle }: Props): React.JSX.Element => (
  <div className={classnames(styles.container, styles[subtitle ? 'flex-start' : 'flex-center'])}>
    <FeatureIcon className={styles.icon} name={icon} />
    <div className={styles['title-wrapper']}>
      {title && (
        <Heading as='h1' className={styles.title}>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading size='xs' as='h2' className={styles.subtitle}>
          {subtitle}
        </Heading>
      )}
    </div>
  </div>
);

export default PageHeading;
