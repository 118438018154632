import { useCallback } from 'react';
import { CustomError, DEFAULT_ERROR_MESSAGE } from '~/helpers/common/custom-error';
import { FileObject } from '~/types/gists/file';
import {
  renameFile,
  uploadFiles
} from '~/pages/files/components/files-table/components/add-files/components/uploader/utils';
import useActiveFolderType from '~/pages/files/hooks/useActiveFolderType';
import { RESTRICTIONS_BY_FOLDER_TYPE } from '~/pages/files/components/files-table/components/add-files/components/uploader/constants';

const useReplaceFile = () => {
  const activeFolder = useActiveFolderType();

  const replaceFile = useCallback(
    async ({
      startLoading,
      finishLoading,
      target,
      refetchTable,
      onError
    }: {
      startLoading: () => void;
      finishLoading: () => void;
      target: FileObject;
      refetchTable: () => Promise<void>;
      onError: (e: string | null) => void;
    }) =>
      new Promise<void>(resolve => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = RESTRICTIONS_BY_FOLDER_TYPE[activeFolder];

        fileInput.onchange = async event => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              onError(null);
              const renamedFile = renameFile(file, target.name);

              startLoading();
              await uploadFiles(
                {
                  [renamedFile.name]: {
                    file: renamedFile,
                    isUniqueness: true,
                    target
                  }
                },
                activeFolder,
                e => onError(e?.message ?? DEFAULT_ERROR_MESSAGE)
              );

              await refetchTable();

              resolve(void 0);
            } catch (unknownError) {
              const error = new CustomError(unknownError);
              onError(error.message);
            } finally {
              finishLoading();
            }
          }
        };

        fileInput.click();
      }),
    [activeFolder]
  );

  return {
    replaceFile
  };
};

export default useReplaceFile;
