import { useEffect } from 'react';
import { useTheme } from '~/components/theme';

const STYLES_LINK_ID = 'lifehub-components-styles';
const BASE_S3_CONFIG_URL = 'https://s3.eu-west-2.amazonaws.com/config.lifehubplatform.com/';
const LH_COMPONENTS_STYLES_FILE_NAME = '/styles2.css';

const useLifehubComponentsStyles = (): void => {
  const { affiliate, env } = useTheme();

  useEffect(() => {
    // All s3 folders contain the environment name in their names (e.g. dev / test / stage), except for the prod folder.
    const envValue = env !== 'prod' && env !== 'none' ? `.${env}` : '';
    const href = BASE_S3_CONFIG_URL + affiliate + envValue + LH_COMPONENTS_STYLES_FILE_NAME;

    const head: HTMLCollectionOf<HTMLHeadElement> = document.getElementsByTagName('head');
    const currentLink: HTMLElement | null = document.getElementById(STYLES_LINK_ID);

    if (currentLink) {
      head[0].removeChild(currentLink);
    }

    const newLink: HTMLLinkElement = document.createElement('link');
    newLink.id = STYLES_LINK_ID;
    newLink.rel = 'stylesheet';
    newLink.as = 'style';
    newLink.type = 'text/css';
    newLink.href = href;

    head[0].appendChild(newLink);

    return () => {
      head[0].removeChild(newLink);
    };
  }, [affiliate, env]);
};

export default useLifehubComponentsStyles;
