import React, { memo, PropsWithChildren } from 'react';

import { LifeHubLogo } from '~/components/_layout/logo';

import styles from './styles.module.scss';

const Loader = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => (
  <div className={styles.container}>
    <LifeHubLogo animate={true} className={styles['loading-icon']} />
    <div className={styles.text}>{children}</div>
  </div>
);

export default memo(Loader);
