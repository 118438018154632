import { useCallback } from 'react';
import { ActionType, Hooks, TableDataLike, TableInstance, TableState } from 'react-table';

export type WrappingMode = 'nowrap' | 'wrap';

export type WrappingCallback = (mode: WrappingMode) => void;

export type UseWrapInstanceProps = {
  setWrappingMode: WrappingCallback;
};

export type UseWrapOptions = Partial<{
  defaultWrappingMode: WrappingMode;
}>;

export type UseWrapState = {
  wrappingMode: WrappingMode;
};

export type UseWrapActions = {
  setWrappingMode: { type: 'setWrappingMode'; mode: WrappingMode };
};

const actions = {
  setWrappingMode: 'setWrappingMode'
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { dispatch } = instance;

  const setWrappingMode = useCallback(
    (mode: WrappingMode) => {
      dispatch({ type: actions.setWrappingMode, mode });
    },
    [dispatch]
  );

  Object.assign(instance, {
    setWrappingMode
  });
};

const useReducer = <Data extends TableDataLike>(state: TableState<Data>, action: ActionType): TableState<Data> => {
  if (action.type === actions.setWrappingMode) {
    const { mode } = action as UseWrapActions['setWrappingMode'];

    return {
      ...state,
      wrappingMode: mode
    };
  }

  return state;
};

export const useWrapping = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.stateReducers.push(useReducer);
  hooks.useInstance.push(useInstance);
};

useWrapping.pluginName = 'useWrapping';
