import React from 'react';

import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { Block } from '~/types/gists/Block';

import TextBlockVariants from '~/pages/blocks/edit/components/preview/block-types/text';
import HeadingBlockVariants from '~/pages/blocks/edit/components/preview/block-types/heading';
import SpacerBlockVariants from '~/pages/blocks/edit/components/preview/block-types/spacer';
import ImageBlockVariants from '~/pages/blocks/edit/components/preview/block-types/image';
import ListBlockVariants from '~/pages/blocks/edit/components/preview/block-types/list';
import QuoteBlockVariants from '~/pages/blocks/edit/components/preview/block-types/quote';
import MetaboxBlockVariants from '~/pages/blocks/edit/components/preview/block-types/metabox';

import styles from './styles.module.scss';

type Props = {
  block: Block;
};

const BlockTypeToVariants = {
  [COMPONENT_TYPES.text]: TextBlockVariants,
  [COMPONENT_TYPES.heading]: HeadingBlockVariants,
  [COMPONENT_TYPES.spacer]: SpacerBlockVariants,
  [COMPONENT_TYPES.image]: ImageBlockVariants,
  [COMPONENT_TYPES.bulletList]: ListBlockVariants,
  [COMPONENT_TYPES.numberList]: ListBlockVariants,
  [COMPONENT_TYPES.quote]: QuoteBlockVariants,
  [COMPONENT_TYPES.metabox]: MetaboxBlockVariants
};

const Preview = ({ block }: Props): React.JSX.Element => {
  const Block = BlockTypeToVariants[block.type];

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore*/}
      {Block && <Block variants={block.data.variants} content={block.data.content} />}
    </div>
  );
};

export default Preview;
