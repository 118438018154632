import React, { useCallback } from 'react';
import { ControlledFormLabel, FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { useSidebarPanel } from '~/components/_layout/panels';
import { FilePicker } from '~/components/_layout/popovers/upload/components/inputs/picker';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import {
  setError,
  setLoading,
  setPageExcerpt,
  setPageImage,
  setPageLabel,
  setPageTitle
} from '~/pages/pages/edit/context/store/actions';
import {
  getAvailableImageByKey,
  getPageExcerpt,
  getPageImage,
  getPageLabel,
  getPageTitle
} from '~/pages/pages/edit/context/store/selectors';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { FileObject } from '~/types/gists/file';

import styles from './styles.module.scss';

export const MetadataPanel = (): React.JSX.Element => {
  const image = usePageEditorSelector(getPageImage) ?? undefined;
  const title = usePageEditorSelector(getPageTitle) ?? undefined;
  const excerpt = usePageEditorSelector(getPageExcerpt) ?? undefined;
  const label = usePageEditorSelector(getPageLabel) ?? undefined;

  const dispatch = usePageEditorDispatch();

  const onChangeImage = useCallback(
    (file?: FileObject) => () => {
      dispatch(setPageImage(file?.key || null));
    },
    [dispatch]
  );

  const onChangeTitle = useCallback(
    (_, value) => {
      dispatch(setPageTitle(value || null));
    },
    [dispatch]
  );

  const onChangeExcerpt = useCallback(
    (_, value) => {
      dispatch(setPageExcerpt(value || null));
    },
    [dispatch]
  );

  const onChangeLabel = useCallback(
    (_, value) => {
      dispatch(setPageLabel(value || null));
    },
    [dispatch]
  );

  const setPageError = useCallback(
    (error: string | null) => {
      dispatch(setError(error));
    },
    [dispatch]
  );

  const setPageLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setLoading(isLoading));
    },
    [dispatch]
  );

  const { Panel, List, ListItem } = useSidebarPanel();

  const getImageFileByKey = useCallback(state => getAvailableImageByKey(state, { key: image }), [image]);

  const imageFile = usePageEditorSelector(getImageFileByKey);

  return (
    <Panel className={styles.container} title='Page Metadata'>
      <List>
        <ListItem withoutBorder>
          <ControlledFormLabel id='Thumbnail' label='Thumbnail'>
            <FilePicker
              inputId='Thumbnail'
              activeFolder={FOLDER_TYPES.IMAGES}
              file={imageFile}
              handleFileInteraction={onChangeImage}
              setPageError={setPageError}
              setPageLoading={setPageLoading}
            />
          </ControlledFormLabel>
        </ListItem>
        <ListItem withoutBorder>
          <FormLabel label='Heading'>
            <Input name='title' value={title} onChange={onChangeTitle} />
          </FormLabel>
        </ListItem>
        <ListItem withoutBorder>
          <FormLabel label='Description'>
            <TextareaAutosize name='excerpt' value={excerpt} onChange={onChangeExcerpt} />
          </FormLabel>
        </ListItem>
        <ListItem withoutBorder>
          <FormLabel label='Label'>
            <Input name='label' value={label} onChange={onChangeLabel} />
          </FormLabel>
        </ListItem>
      </List>
    </Panel>
  );
};
