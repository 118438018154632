import { AffiliateInfo } from '~/types/gists/affiliates';
import { baseApiUrls, DEFAULT_AFFILIATE, get, put, requestByUrl } from './utils';

const getCurrent = async (): Promise<AffiliateInfo> => await get('/affiliates/current');
const putCurrent = async (affiliate: AffiliateInfo): Promise<AffiliateInfo> =>
  await put('/affiliates/current', affiliate);

export type AllResponse = { count: number; items: AffiliateInfo[] };

const getAll = async (): Promise<AllResponse> => await get('/affiliates/all');
const fetchAllByEnv = async (env: Environment): Promise<AllResponse> => {
  return await requestByUrl('GET', `${baseApiUrls[env] ?? baseApiUrls.dev}/affiliates/all`, DEFAULT_AFFILIATE);
};

export const affiliates = {
  getCurrent,
  getAll,
  putCurrent,
  fetchAllByEnv
};
