import { BlockLayer } from '~/types/gists/page';
import { produce } from 'immer';

export const moveElementForward = <T>(arr: T[], element: T): T[] => {
  const newArr = [...arr];

  const index = newArr.indexOf(element);

  if (index !== -1 && index < newArr.length - 1) {
    const movedElement = newArr.splice(index, 1)[0];
    newArr.splice(index + 1, 0, movedElement);
  }

  return newArr;
};

export const moveElementBackward = <T>(arr: T[], element: T): T[] => {
  const newArr = [...arr];
  const index = newArr.indexOf(element);

  if (index > 0) {
    const movedElement = newArr.splice(index, 1)[0];
    newArr.splice(index - 1, 0, movedElement);
  }

  return newArr;
};

export const removeElementFromArray = <T>(arr: T[], element: T): T[] => {
  const newArr = [...arr];
  const index = newArr.indexOf(element);

  if (index !== -1) {
    newArr.splice(index, 1);
  }

  return newArr;
};

export const removeObjectById = <T>(obj: Record<string, T>, id: string): Record<string, T> => {
  if (id in obj) {
    const newObj = { ...obj };
    delete newObj[id];
    return newObj;
  }
  return obj;
};

export const duplicateElement = <T>(arr: T[], element: T, newId: T): T[] => {
  const newArr = [...arr];
  const index = newArr.indexOf(element);

  if (index !== -1) {
    newArr.splice(index + 1, 0, newId);
  }

  return newArr;
};

export const removeLayerById = (layers: BlockLayer[], layerId: string): BlockLayer[] => {
  return produce(layers, draftLayers => {
    const findAndRemove = (arr: string[]) => {
      const index = arr.indexOf(layerId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    };

    const findLayerAndRemove = (arr: BlockLayer[]) => {
      const index = arr.findIndex(layer => layer.id === layerId);
      if (index !== -1) {
        const removedLayer = arr.splice(index, 1)[0];
        findAndRemove(removedLayer.children);
      }
    };

    findLayerAndRemove(draftLayers);

    // Remove layer from children of other layers
    draftLayers.forEach(layer => {
      findAndRemove(layer.children);
    });
  });
};

export const extractTagFromHTML = (html: string): string | null => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const tagElement = doc.body.firstChild;

  return tagElement instanceof HTMLElement ? tagElement.tagName.toLowerCase() : null;
};

export const collectContentFromLayers = (layers: BlockLayer[]): string => {
  const result: Record<string, string> = {};

  function traverse(layer: BlockLayer): string {
    let content = layer.content;

    if (layer.children && layer.children.length > 0) {
      content = layer.children.reduce((acc, childId) => {
        const childLayer = layers.find(l => l.id === childId);
        if (childLayer) {
          return acc + traverse(childLayer);
        }
        return acc;
      }, '');

      const tag = extractTagFromHTML(layer.content) ?? 'div';
      content = `<${tag}>${content}</${tag}>`;
    }

    result[layer.id] = content;

    return content;
  }

  for (const layer of layers) {
    traverse(layer);
  }

  return result[layers[0].id]; // Assuming the first layer is the root
};

export const getLayerToParentsMap = (layers: BlockLayer[]): Record<string, string[]> => {
  return layers.reduce<Record<string, string[]>>((childrenToParentsMap, layer) => {
    layer.children.forEach(childId => {
      childrenToParentsMap[childId] = [
        // upper grandparents
        ...(childrenToParentsMap[layer.id] || []),
        // current parent
        layer.id
      ];
    });

    return childrenToParentsMap;
  }, {});
};
