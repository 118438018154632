import React, { useCallback } from 'react';
import { Button } from '~/components/button';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { generateNewVirtualTag } from '~/pages/tags/context/store/thunks';
import { TopBarTool } from '~/components/_layout/top-bar/tool';

const AddTag = (): React.JSX.Element => {
  const { dispatch } = useTagsModuleContext();

  const onClick = useCallback(() => dispatch(generateNewVirtualTag()), [dispatch]);

  return (
    <TopBarTool>
      <Button icon='plus' is='minor' onClick={onClick}>
        Add a Tag
      </Button>
    </TopBarTool>
  );
};

export default AddTag;
