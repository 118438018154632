import React, { memo } from 'react';
import Rule from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule';

import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { BUILDER_CONDITIONS } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Switch } from '~/components/_inputs/switch';

import styles from './styles.module.scss';
import AddRule from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Ruleset/components/AddRule';

type Props = {
  id: string;
};

const RuleSet = ({ id }: Props): React.JSX.Element | null => {
  const { ruleSets, setRuleSetConditionById } = useConditionBuilderContext();

  const { ruleIds, condition } = ruleSets[id];

  const toggleCondition = () =>
    setRuleSetConditionById(id, condition === BUILDER_CONDITIONS.AND ? BUILDER_CONDITIONS.OR : BUILDER_CONDITIONS.AND);

  return (
    <>
      {condition !== BUILDER_CONDITIONS.DEFAULT && (
        <div className={styles['ruleset-condition']}>
          <Switch
            id={id}
            checked={condition === BUILDER_CONDITIONS.AND}
            onClick={toggleCondition}
            squared
            labels={{
              off: 'and',
              on: 'or'
            }}
          />
        </div>
      )}
      <div className={styles.ruleset}>
        {ruleIds.map(ruleId => (
          <Rule key={ruleId} ruleId={ruleId} rulesetId={id} />
        ))}
        <AddRule rulesetId={id} />
      </div>
    </>
  );
};

export default memo(RuleSet);
