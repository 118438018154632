export const FILES_MAIN = '/files/:type';

export enum FOLDER_TYPES {
  IMAGES = 'images',
  ICONS = 'icons',
  FONTS = 'fonts'
}

export const FILES_ROUTES = {
  IMAGES: `/files/${FOLDER_TYPES.IMAGES}`,
  ICONS: `/files/${FOLDER_TYPES.ICONS}`,
  FONTS: `/files/${FOLDER_TYPES.FONTS}`
};
