import { TableInstance } from 'react-table';

import { ImportResult } from '~/components/_table/types';
import { CoachMessage } from '~/components/_layout/coach/coach-popover';
import { AvailableEntityBackendKeys, PLURAL_ENTITY_TITLES, SINGULAR_ENTITY_TITLES } from '~/helpers/formatters';
import { Extend } from '~/components/_table/hooks/use-table-fetch';
import { RefetchParams } from '~/components/_table/hooks/use-table-import/types';

export const getImportSuccessMessage = (results: ImportResult[]): CoachMessage => {
  const formattedResults = results.map(record => {
    const { entity, skipped = 0, inserted = 0, updated = 0 } = record;
    const isPlural = skipped > 1 || inserted > 1 || updated > 1;
    const entityTitle = isPlural ? `${PLURAL_ENTITY_TITLES[entity]}` : SINGULAR_ENTITY_TITLES[entity];
    return `${updated} ${entityTitle} updated, ${inserted} added, ${skipped} skipped`;
  });

  return ['Here’s what was imported:'].concat(formattedResults);
};

export const getFirstEntityFromImportList = <T>(importList: { data: T[] }): AvailableEntityBackendKeys => {
  return importList[0].entity;
};

export const buildRefetchParamsFromTable = <T extends Extend>(instance: TableInstance<T>): RefetchParams<T> => {
  const { filters, sortBy, pageSize, pageIndex } = instance.state;
  return { filters, sortBy, pageSize, pageIndex };
};
