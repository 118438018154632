import { useContext } from 'react';
import { ContextReturnType, TagsModuleContext } from '~/pages/tags/context';

const useTagsModuleContext = (): ContextReturnType => {
  const context = useContext(TagsModuleContext);
  if (context === undefined) {
    throw new Error('useTagsModuleContext must be used within a TagsModuleProvider');
  }

  return context;
};

export default useTagsModuleContext;
