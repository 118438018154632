import React, { useCallback } from 'react';
import { FormLabel } from '~/components/_form/components/label';
import { DatePicker } from '~/components/_inputs/date';
import { useSidebarPanel } from '~/components/_layout/panels';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setOverrodeAt } from '~/pages/pages/edit/context/store/actions';
import { getPageOverrodeAt } from '~/pages/pages/edit/context/store/selectors';

const OverrideField = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const overrodeAt = usePageEditorSelector(getPageOverrodeAt);

  const onChange = useCallback(
    (_: string, value: Date) => {
      dispatch(setOverrodeAt(value));
    },
    [dispatch]
  );

  const { ListItem } = useSidebarPanel();

  return (
    <ListItem>
      <FormLabel placement='top' label='Override'>
        <DatePicker name='overrodeAt' selected={overrodeAt} onChange={onChange} />
      </FormLabel>
    </ListItem>
  );
};

export default OverrideField;
