import React from 'react';
import { Image } from '~/components/image';
import useFileDetailsOverlay from '~/components/_table/cells/file-primary/hooks/useFileDetailsOverlay';

import styles from './image-thumb.module.scss';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';

const ImageThumb = () => {
  const { FileDetailsOverlay, openFileDetailsOverlay } = useFileDetailsOverlay();

  const { cellProps } = useFilePrimaryCellContext();

  const {
    row: {
      original: { s3ObjectUrl, altText, updatedAt }
    }
  } = cellProps;

  return (
    <>
      <FileDetailsOverlay />
      <Image
        src={`${s3ObjectUrl}?updatedAt=${updatedAt}`}
        alt={altText || undefined}
        onClick={openFileDetailsOverlay}
        className={styles.thumb}
      />
    </>
  );
};

export default ImageThumb;
