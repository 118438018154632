import React from 'react';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { Options } from '@popperjs/core';
import { curry } from '~/helpers/functional/curry';
import { StringShim } from '~/helpers/shims/string-shim';
import { Switch } from '~/components/_inputs/switch';
import { FormLabel } from '~/components/_form/components/label';
import { DatePicker, TimePicker } from '~/components/_inputs/date';
import { useSidebarPanel } from '~/components/_layout/panels';
import { useSegmentedButton } from '~/components/segmented-button';
import { Input } from '~/components/_inputs/input';
import { Select, SelectItem } from '~/components/_inputs/select';
import { defaultSchedulerConfig } from '~/pages/push-notifications/edit/panel/scheduler/config';
import { BasicDay, FREQUENCY_TYPES } from '~/pages/push-notifications/edit/panel/scheduler/types';
import { RepeatableConfig } from '~/types/gists/push-notification';
import {
  frequency,
  monthDays,
  normalizedFrequency,
  normalizedMonthDays,
  normalizedWeekDays,
  segments,
  weekDays
} from './constants';
import styles from './scheduler.module.scss';
import { DateComponent } from '~/components/_layout/typography/date';
import InfoText from '~/components/_layout/typography/infoText';
import { formatDate } from '~/helpers/formatters';

dayjs.extend(objectSupport);

const popperOptions: Partial<Options> = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [4, 16]
      }
    }
  ],
  placement: 'left-end'
};

type Props = {
  isRepeatable: boolean;
  repeatableConfig: RepeatableConfig;
  onChange: HandleChange;
  triggeredAt: string;
  isTimeChangedButNotSaved: boolean;
  isPublished: boolean;
};

const ENTITY_VALUE_OPERATION = 'entityValue';

export const SchedulerPanel = React.memo(
  ({
    isRepeatable,
    repeatableConfig: state,
    onChange,
    triggeredAt,
    isTimeChangedButNotSaved,
    isPublished
  }: Props): React.JSX.Element => {
    const curriedChange = curry(onChange)('repeatableConfig');

    const handleSelect = (name: string) => (value: SelectItem<FREQUENCY_TYPES | BasicDay['value']>) => {
      // Reset value on frequency type change.
      const entityValue = name === ENTITY_VALUE_OPERATION ? undefined : defaultSchedulerConfig.entityValue;

      curriedChange({ ...state, entityValue, [name]: value.id });
    };

    const handleDate = (name: string, value: Date) => {
      curriedChange({ ...state, [name]: value });
    };

    const handleChange = (name: string, value: string | number) => {
      curriedChange({ ...state, [name]: value });
    };

    const { ListItem, List, Panel } = useSidebarPanel();

    const { Container, Segment, setActive, active } = useSegmentedButton(state.ends);

    const handleSegmentedChange = (value: string) => () => {
      curriedChange({ ...state, ends: value });

      setActive(value);
    };

    return (
      <Panel title='Scheduling'>
        <List className={styles.container}>
          <ListItem withoutBorder className={styles['fit-content']}>
            <FormLabel label='Repeat' placement='right'>
              <Switch name='isRepeatable' onChange={onChange} checked={isRepeatable} id='is-repeatable' />
            </FormLabel>
          </ListItem>
          <ListItem withoutBorder row>
            <FormLabel label={`Send ${isRepeatable ? 'from' : 'on'}`} placement='top'>
              <DatePicker name='time' selected={state.time} onChange={handleDate} minDate={dayjs().toDate()} />
            </FormLabel>
            <FormLabel label='At' placement='top'>
              <TimePicker name='time' selected={state.time} onChange={handleDate} />
            </FormLabel>
          </ListItem>
          {isRepeatable && (
            <>
              <ListItem withoutBorder row>
                <FormLabel label='Every' placement='top'>
                  <Input name='everyNumber' value={state.everyNumber} onChange={handleChange} type='number' />
                </FormLabel>
                <FormLabel label=''>
                  <Select
                    popperOptions={popperOptions}
                    name='everyEntity'
                    value={normalizedFrequency[state.everyEntity]}
                    items={frequency}
                    placeholder='Choose'
                    onChange={handleSelect}
                  />
                </FormLabel>
              </ListItem>
              {state.everyEntity !== 'day' && (
                <ListItem withoutBorder>
                  {state.everyEntity === 'week' && (
                    <FormLabel label='On' placement='top'>
                      <Select
                        popperOptions={popperOptions}
                        name={ENTITY_VALUE_OPERATION}
                        value={normalizedWeekDays[state.entityValue as number]}
                        items={weekDays}
                        placeholder='Choose'
                        onChange={handleSelect}
                      />
                    </FormLabel>
                  )}
                  {state.everyEntity === 'month' && (
                    <FormLabel label='On' placement='top'>
                      <Select
                        popperOptions={popperOptions}
                        name={ENTITY_VALUE_OPERATION}
                        value={normalizedMonthDays[state.entityValue as number]}
                        items={monthDays}
                        placeholder='Choose'
                        onChange={handleSelect}
                      />
                    </FormLabel>
                  )}
                </ListItem>
              )}
              <ListItem withoutBorder>
                <FormLabel label='Ends' placement='top'>
                  <Container>
                    {segments.map((segment, index) => (
                      <Segment
                        onClick={handleSegmentedChange(segment)}
                        key={segment}
                        active={segment === active}
                        first={index === 0}
                        last={index === segments.length - 1}
                      >
                        {StringShim.capitalize(segment)}
                      </Segment>
                    ))}
                  </Container>
                </FormLabel>
              </ListItem>
              {active !== 'never' && (
                <ListItem withoutBorder>
                  {active === 'on' && (
                    <FormLabel label='End date' placement='top'>
                      <DatePicker name='end' onChange={handleDate} selected={state.end} />
                    </FormLabel>
                  )}
                  {active === 'after' && (
                    <FormLabel label='Occurrences' placement='top'>
                      <Input name='occurrences' type='number' value={state.occurrences} onChange={handleChange} />
                    </FormLabel>
                  )}
                </ListItem>
              )}
              {isPublished && (
                <ListItem withoutBorder>
                  <FormLabel label='Next send'>
                    <DateComponent size='2xs' className={styles['thin-date']}>
                      {formatDate(triggeredAt)}
                    </DateComponent>
                    {isTimeChangedButNotSaved && <InfoText label='Changes need updating' />}
                  </FormLabel>
                </ListItem>
              )}
            </>
          )}
        </List>
      </Panel>
    );
  }
);
