import { ThemeService } from '~/services/theme-service';
import { UserService } from '~/services/user-service';
import { AffiliateInfo } from '~/types/gists/affiliates';

export enum ActionType {
  SetState
}

interface SetState {
  type: ActionType.SetState;
  payload: Partial<State>;
}

export type State = {
  theme: Theme;
  affiliate: AffiliateKey;
  affiliateDetails?: AffiliateInfo;
  env: Environment;
};

export const initialState = (): State => {
  const [affiliate, env] = UserService.getAffiliate();

  return {
    theme: ThemeService.get(),
    affiliate,
    env
  };
};

export const reducer = (state: State, action: SetState): State => {
  switch (action.type) {
    case ActionType.SetState: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
