import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { ONBOARDING } from '~/routes/constants';

const useOnboardingRoute = (): boolean => {
  const { pathname } = useLocation();

  const isOnboardingRoute = useMemo(() => {
    return ONBOARDING.some(route => route === pathname);
  }, [pathname]);

  return isOnboardingRoute;
};

export default useOnboardingRoute;
