import React from 'react';

type Props = {
  className: string;
};

export const Icon = ({ className }: Props): React.JSX.Element => {
  return (
    <svg className={className} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <g data-background-filter>
        <path
          data-background
          d='M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z'
        />
        <g data-tick-filter>
          <path
            data-tick
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.75012 13.1274L4.62262 9.99988L3.55762 11.0574L7.75012 15.2499L16.7501 6.24988L15.6926 5.19238L7.75012 13.1274Z'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_light'
          x='0'
          y='0'
          width='20'
          height='21'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow' />
        </filter>

        <filter
          id='filter1_dark'
          x='-2'
          y='-2'
          width='24'
          height='24'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.239216 0 0 0 0 0.811765 0 0 0 0 0.207843 0 0 0 1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>

        <filter
          id='filter1_light'
          x='1'
          y='1'
          width='19'
          height='20'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dx='1' dy='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>

        <linearGradient
          id='paint_radial_gradient_light'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(10 10) rotate(90) scale(10)'
        >
          <stop offset='1' stopColor='var(--app-color-env)' />
          <stop stopColor='var(--app-color-lighter-env)' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_dark'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#262626' stopOpacity='100%' />
          <stop stopColor='#3B3B3B' stopOpacity='0%' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_light_hover'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#E4E4E4' stopOpacity='100%' />
          <stop stopColor='#F8F8F8' stopOpacity='0%' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_dark_hover'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#333333' stopOpacity='100%' />
          <stop stopColor='#474747' stopOpacity='0%' />
        </linearGradient>
      </defs>
    </svg>
  );
};
