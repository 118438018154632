import JSZip from 'jszip';

const getFileNameFromURL = url => {
  const path = url.split('/');
  return path[path.length - 1];
};

export const saveAs = async (urls: string[]) => {
  const loadedUrls = await Promise.all(
    urls.map(async url => {
      const response = await fetch(url);
      const blob = await response.blob();

      return URL.createObjectURL(blob);
    })
  );

  loadedUrls.forEach((loadedUrl, index) => {
    const element = document.createElement('a');
    element.setAttribute('href', loadedUrl);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', getFileNameFromURL(urls[index]));
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};

export const saveAsZip = async (urls: string[], archiveName: string) => {
  const zip = new JSZip();

  await Promise.all(
    urls.map(url =>
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = getFileNameFromURL(url);

          return zip.file(fileName, blob);
        })
    )
  );

  const content = await zip.generateAsync({ type: 'blob' });

  const element = document.createElement('a');
  element.setAttribute('href', URL.createObjectURL(content));
  element.setAttribute('target', '_blank');
  element.setAttribute('download', `${archiveName}.zip`);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
