import React, { memo, useCallback, useMemo } from 'react';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { TagName } from '~/templates/tags-interaction/components/TagsTree/components/TagName';

import styles from './styles.module.scss';
import { Icon } from '~/components/icon';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';
import { FlatTag } from '~/types/gists/tag';

type Props = {
  tagId: number;
  onSelect: (ids: number[]) => void;
  onExpand: (ids: number) => void;
  isSelected: boolean;
  isExpanded: boolean;
  multiSelect?: boolean;
  children?: React.ReactNode;
  shouldRenderDNDIcon?: boolean;
  isDragging?: boolean;
  selectedTagId?: number;
};

const TagsTreeItem = ({
  tagId,
  onSelect,
  isSelected,
  isExpanded,
  onExpand,
  multiSelect,
  shouldRenderDNDIcon,
  isDragging,
  selectedTagId,
  children
}: Props): React.JSX.Element => {
  const { state } = useTagsModuleContext();

  const { theme } = useTheme();
  const tag = useMemo(() => {
    const virtualTag = state.virtualTags.find(({ id }) => id === tagId);
    return virtualTag ?? { name: 'None', id: NaN };
  }, [state.virtualTags, tagId]) as { name: string; id: number } & Partial<FlatTag>;

  const onSelectTag = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      // If the user switches to another tag while editing/creating the current tag.
      if (
        state.originalTags.length !== state.virtualTags.length &&
        state.originalTags.some(originalTag => originalTag.id === tag.id)
      ) {
        return onSelect([tag.id]);
      }

      if (multiSelect) {
        // If the user selects a tag with ctrl or cmd, we should add it to the list of selections without deleting the current one.
        if (e.metaKey || e.ctrlKey) {
          if (state.selectedTagsIds.includes(tag.id)) {
            const tagsToSelect = state.selectedTagsIds.filter(selectedId => selectedId !== tag.id);
            if (tagsToSelect.length) {
              return onSelect(tagsToSelect);
            }
            return;
          }

          return onSelect([...state.selectedTagsIds, tag.id]);
        }

        // If the user selects a tag with shift, we should select all tags from current one to the new selected.
        if (e.shiftKey) {
          const selectedIndex = state.virtualTags.findIndex(virtualTag => virtualTag.id === state.selectedTagsIds[0]);
          const targetIndex = state.virtualTags.findIndex(virtualTag => virtualTag.id === tag.id);

          return onSelect(
            state.virtualTags
              .slice(Math.min(selectedIndex, targetIndex), Math.max(selectedIndex, targetIndex) + 1)
              .map(t => t.id)
          );
        }
      }

      return onSelect([tag.id]);
    },
    [multiSelect, onSelect, state.originalTags, state.selectedTagsIds, state.virtualTags, tag.id]
  );

  const onExpandTag = (id: number) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onExpand(id);
  };

  const shouldRenderCaret = useMemo(
    () => tag.descendants?.filter(t => t !== selectedTagId).length,
    [tag.descendants, selectedTagId]
  );

  return (
    <div className={classnames(styles.container, styles[theme])}>
      <div
        className={classnames(styles.tag, {
          [styles.selected]: isSelected,
          [styles.expanded]: isExpanded,
          [styles['is-dragging']]: isDragging
        })}
        onClick={onSelectTag}
      >
        {shouldRenderCaret ? (
          <div onClick={onExpandTag(tag.id)}>
            <Icon name='arrow-triangle-right' className={styles.caret} />
          </div>
        ) : null}
        {!isNaN(tagId) ? <Icon name='tags' /> : null}
        <TagName name={tag.name} />
        {shouldRenderDNDIcon && (
          <div className={styles['dnd-icon']}>
            <Icon name='grips' />
          </div>
        )}
      </div>
      {isExpanded ? children : null}
    </div>
  );
};

export default memo(TagsTreeItem);
