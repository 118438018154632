import React, { useCallback } from 'react';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { ControlledFormLabel, FormLabel } from '~/components/_form/components/label';
import { useSidebarPanel } from '~/components/_layout/panels';
import { FilePicker } from '~/components/_layout/popovers/upload/components/inputs/picker';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setError, setFacebookSEO, setLoading } from '~/pages/pages/edit/context/store/actions';
import { getAvailableImageByKey, getFacebookSEO } from '~/pages/pages/edit/context/store/selectors';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { FileObject } from '~/types/gists/file';

export const FacebookTab = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();
  const seo = usePageEditorSelector(getFacebookSEO);

  const onChange = useCallback(
    (name, value) => {
      dispatch(setFacebookSEO({ name, value }));
    },
    [dispatch]
  );

  const onChangeImage = useCallback(
    (file?: FileObject) => () => {
      onChange('og_image', file?.key);
    },
    [onChange]
  );

  const setPageError = useCallback(
    (error: string | null) => {
      dispatch(setError(error));
    },
    [dispatch]
  );

  const setPageLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(setLoading(isLoading));
    },
    [dispatch]
  );

  const { List, ListItem } = useSidebarPanel();

  const getImageFileByKey = useCallback(
    state => getAvailableImageByKey(state, { key: seo?.og_image }),
    [seo?.og_image]
  );

  const imageFile = usePageEditorSelector(getImageFileByKey);

  return (
    <List>
      <ListItem withoutBorder>
        <FormLabel label='Open graph title'>
          <TextareaAutosize name='og_title' value={seo?.og_title} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <FormLabel label='Open graph description'>
          <TextareaAutosize name='og_description' value={seo?.og_description} onChange={onChange} />
        </FormLabel>
      </ListItem>
      <ListItem withoutBorder>
        <ControlledFormLabel id='Open graph image' label='Open graph image'>
          <FilePicker
            inputId='Open graph image'
            activeFolder={FOLDER_TYPES.IMAGES}
            file={imageFile}
            handleFileInteraction={onChangeImage}
            setPageError={setPageError}
            setPageLoading={setPageLoading}
          />
        </ControlledFormLabel>
      </ListItem>
    </List>
  );
};
