import React, { useCallback } from 'react';
import { InteractionProps } from 'react-json-view';
import { Button } from '~/components/button';
import { DEFAULT_VALIDATION_SET } from '~/components/_table/cells/json/constants';

import styles from './styles.module.scss';
import { FormLabel } from '~/components/_form/components/label';
import JsonEditor from '~/components/json-editor';

type Props = {
  value: Record<string, unknown>;
  onChange: HandleChange;
};

const ValidationData = ({ value, onChange }: Props): React.JSX.Element => {
  const handleChange = useCallback(
    (data: InteractionProps) => onChange('validationData', data.updated_src),
    [onChange]
  );

  const handleReset = useCallback(() => onChange('validationData', DEFAULT_VALIDATION_SET), [onChange]);

  return (
    <div className={styles.container}>
      <FormLabel label='Validation' placement='top' size='xs'>
        <div className={styles['json-container']}>
          <JsonEditor src={value} onEdit={handleChange} onAdd={handleChange} onDelete={handleChange} />
        </div>
      </FormLabel>
      <Button is='transparent' className={styles.button} onClick={handleReset}>
        Reset
      </Button>
    </div>
  );
};

export default ValidationData;
