import React, { useCallback, useState } from 'react';
import { FilterProps, TableDataLike } from 'react-table';

import { tags as tagsApi } from '~/api/tags';

import useMountEffect from '~/hooks/use-mount-effect';
import { TagsInteractionPopover } from '~/templates/tags-interaction';
import { TagName } from '~/templates/tags-interaction/components/TagsTree/components/TagName';
import { Icon } from '~/components/icon';
import { OriginalTag } from '~/types/gists/tag';

import styles from './tags-filter.module.scss';
import { isEqual } from '~/helpers/common';

export const TagsFilter = <Data extends TableDataLike>({
  column: { filterValue, setFilter }
}: FilterProps<Data>): React.JSX.Element => {
  const [assignedTags, setAssignedTags] = useState<OriginalTag[]>([]);

  useMountEffect(() => {
    (async () => {
      try {
        const tags = await tagsApi.getTagsFlatTree();
        setAssignedTags(tags.filter(tag => filterValue?.value.includes(tag.name)));
      } catch (e) {
        setAssignedTags([]);
        console.log(e);
      }
    })();
  });

  const handleChange = useCallback(
    (tags: OriginalTag[]) => {
      if (!isEqual(tags, assignedTags)) {
        setAssignedTags(tags);

        setFilter(tags.length ? { value: tags.map(tag => tag.name), customQuery: 'tags.name' } : undefined);
      }
    },
    [assignedTags, setFilter]
  );

  return (
    <div className={styles.container}>
      <TagsInteractionPopover initialAssignedTags={assignedTags} saveTags={handleChange} disabledTagCreation={true}>
        {toggle => (
          <div onClick={() => toggle(true)} className={styles.tags}>
            {assignedTags.map(tag => (
              <div key={tag.name} className={styles.tag}>
                <Icon name={'tags'} />
                <TagName name={tag.name} />
              </div>
            ))}
          </div>
        )}
      </TagsInteractionPopover>
    </div>
  );
};
