import React, { useCallback } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { InteractionProps } from 'react-json-view';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';
import { DEFAULT_VALIDATION_SET } from '~/components/_table/cells/json/constants';
import { Button } from '~/components/button';

import JsonEditor from '~/components/json-editor';

import styles from './styles.module.scss';

export const JSONCell = <Data extends TableDataLike>({ cell }: CellProps<Data>): React.JSX.Element => {
  const {
    column: { editCell, actionTooltip },
    value
  } = cell;

  const handleChange = useCallback(
    (data: InteractionProps) => {
      console.log(data.updated_src);
      return editCell(cell, data.updated_src);
    },
    [cell, editCell]
  );

  const handleReset = useCallback(() => editCell(cell, DEFAULT_VALIDATION_SET), [cell, editCell]);

  return (
    <div className={styles.container}>
      <JsonEditor src={value} onEdit={handleChange} onAdd={handleChange} onDelete={handleChange} />
      <div className={styles.button}>
        <TooltipOnCondition title={actionTooltip} condition={!!actionTooltip} delay={100}>
          <Button onClick={handleReset} icon='refresh' />
        </TooltipOnCondition>
      </div>
    </div>
  );
};
