import type { AnyAction } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

import { coach, initialState as coachInitialState } from './coach';
import { account, initialState as accountInitialState, LOG_OUT } from './account';
import { initialState as mainMenuInitialState, mainMenu } from './mainMenu';
import { initialState as metaInitialState, meta } from './meta';

const initialState = {
  coach: coachInitialState,
  account: accountInitialState,
  mainMenu: mainMenuInitialState,
  meta: metaInitialState
};
const appReducers = combineReducers({ coach, account, mainMenu, meta });

type RootState = typeof initialState;
export type RootStateTableModules = RootState;
type CombinedState = ReturnType<typeof appReducers>;

export const reducers = (state: RootState | undefined, action: AnyAction): CombinedState => {
  if (action.type === LOG_OUT) {
    state = initialState;
  }

  return appReducers(state, action);
};
