import React from 'react';
import Ordering from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-options/components/ordering';

import styles from './styles.module.scss';
import Delete from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-options/components/delete';
import Duplicate from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-options/components/duplicate';

const BlockOptions = (): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <Ordering />
      <Duplicate />
      <Delete />
    </div>
  );
};

export default BlockOptions;
