import { useMemo } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { shallowEqual, useDispatch as dispatch, useSelector as selector } from 'react-redux';
import type { ActionCreatorsMapObject, ThunkAction } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from './index';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDispatch = () => dispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = selector;

/*
	https://github.com/piotrwitek/react-redux-typescript-guide#connect-with-react-redux
*/
export type MapDispatch<M extends ActionCreatorsMapObject<any>> = {
  [N in keyof M]: ReturnType<M[N]> extends ThunkAction<any, any, any, any>
    ? (...args: Parameters<M[N]>) => ReturnType<ReturnType<M[N]>>
    : M[N];
};

export const useActions = <A extends ActionCreatorsMapObject<any>>(actions: A | null | undefined): MapDispatch<A> => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators<A, MapDispatch<A>>(
        {
          ...actions
        } as A,
        dispatch
      ),
    [actions, dispatch]
  );
};

export type MapState<State extends unknown> = {
  (state: RootState): State;
};

export const useConnect = <S extends unknown, A extends ActionCreatorsMapObject<any>>(
  mapState: MapState<S> | null | undefined,
  mapDispatch: A | null | undefined
): [S, MapDispatch<A>] => {
  const state = useSelector(state => {
    if (typeof mapState === 'function') {
      return (mapState as MapState<S>)(state);
    }

    return mapState as S;
  }, shallowEqual);

  const actions = useActions<A>(mapDispatch);

  return [state, actions];
};
