import React from 'react';
import styles from '~/components/_form/fields/password/password-field.module.scss';
import { FormLabel } from '~/components/_form/components/label';
import { connect, Field, FormikProps } from 'formik';
import { FormError } from '~/components/_form/components/error';
import { FormField } from '~/components/_form/components/field';
import PhoneInput from '~/components/_inputs/phone';

type Props = {
  name: string;
  label: string;
  formik?: FormikProps<any>;
};

const PhoneField = ({ name, label, formik }: Props): React.JSX.Element => (
  <FormField className={styles.container}>
    <FormLabel label={label}>
      <Field as={PhoneInput} onChange={value => formik?.setFieldValue(name, value || '')} name={name} />
    </FormLabel>
    <FormError name={name} />
  </FormField>
);

export default connect(PhoneField);
