import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';

import { OnboardingTemplate } from '~/templates/onboarding';

export const NewCodeSent = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.goBack();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history]);

  return <OnboardingTemplate message='New code sent!' />;
};
