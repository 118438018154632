import { DEFAULT_ERROR_MESSAGE } from '~/helpers/common/custom-error';

export type Action<T> =
  | { type: 'request' }
  | { type: 'clear' }
  | { type: 'get'; payload: T }
  | { type: 'init'; payload: Partial<T> }
  | { type: 'edit'; payload: T }
  | { type: 'change'; name: keyof T; value: unknown }
  | { type: 'touch'; value: boolean }
  | { type: 'failure'; error: Error };

export type State<T> = {
  item: T | Record<string, any>;
  loading: boolean;
  isTouched: boolean;
  error: string | undefined;
};

export const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'request': {
      return { ...state, loading: true };
    }
    case 'clear': {
      return { ...state, error: undefined };
    }
    case 'init':
    case 'edit':
    case 'get': {
      return {
        ...state,
        item: action.payload as T,
        loading: false,
        error: undefined
      };
    }
    case 'change': {
      return {
        ...state,
        item: {
          ...state.item,
          [action.name]: action.value
        }
      };
    }
    case 'touch': {
      return {
        ...state,
        isTouched: action.value
      };
    }
    case 'failure': {
      return { ...state, loading: false, error: action.error.message ?? DEFAULT_ERROR_MESSAGE };
    }
    default: {
      return state;
    }
  }
};
