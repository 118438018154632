import React, { useCallback } from 'react';

import { COMPONENT_TYPES } from '@life-moments/lifehub-components';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getBlockById, getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';
import ImageMetadata from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/components/image-metadata';
import Interaction from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static/components/interaction';

const StaticLevelSidebar = (): React.JSX.Element | null => {
  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const getBlockByIdSelector = useCallback(
    state => {
      const { blockId } = parseLayerId(selectedLayerId);
      return getBlockById(state, { id: blockId });
    },
    [selectedLayerId]
  );

  const block = usePageEditorSelector(getBlockByIdSelector);

  if (block.type === COMPONENT_TYPES.image) {
    return (
      <>
        <ImageMetadata />
        {block.data.fileKey && <Interaction />}
      </>
    );
  }

  return null;
};

export default StaticLevelSidebar;
