import React, { useMemo } from 'react';
import { Table, TableProps } from '~/components/_table';

import { columns } from './columns';
import { UseTableFetch, useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { PushNotificationHistory } from '~/types/gists/push-notification';
import { Heading } from '~/components/_layout/typography/heading';

import styles from './stats.module.scss';
import { calculatePageCount } from '~/components/_table/helpers/common';

const defaultSortBy = [
  {
    id: 'createdAt',
    desc: true
  }
];

type StatsTableProps = {
  state: UseTableFetch<PushNotificationHistory>;
} & Partial<TableProps<PushNotificationHistory>>;

export const StatsTable = ({ state, ...rest }: StatsTableProps): React.JSX.Element => {
  const { items, getHistory, count, loading, error } = state;

  const showBody = {
    error,
    loading
  };

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  return (
    <Table<PushNotificationHistory>
      gist='pushNotificationStats'
      columns={columns}
      loading={loading}
      data={items}
      onUpdate={getHistory}
      manualSortBy
      manualFilters
      manualPagination
      disableFilters
      disableEditing
      disableSelection
      showBody={showBody}
      defaultSortBy={defaultSortBy}
      pageCount={pageCount}
      disableResizing
      count={count}
      {...rest}
    />
  );
};

type Props = {
  id?: string;
  animationCompleted?: boolean;
  title: string;
};

export const StatsInOverlay = React.memo(({ id, animationCompleted, title }: Props): React.JSX.Element => {
  const stats = useTableFetch<PushNotificationHistory>({
    gist: 'pushNotifications',
    id
  });

  return (
    <>
      {title && (
        <Heading className={styles.heading} size='l'>
          {title}
        </Heading>
      )}
      <StatsTable state={stats} preventGet={!animationCompleted} />
    </>
  );
});
