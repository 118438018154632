import React, { memo, useCallback } from 'react';

import { Select } from '~/components/_inputs/select';
import { Icon } from '~/components/icon';
import { Heading } from '~/components/_layout/typography/heading';

import useDatasetsController from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/hooks/useDatasetsController';

import styles from '../styles.module.scss';

type Props = {
  ruleId: string;
};

const GoBack = ({ onGoBack }): React.JSX.Element => (
  <div onClick={onGoBack} className={styles['back-icon']} style={{ display: onGoBack ? 'inline-flex' : 'none' }}>
    <Icon name='arrow-left' />
  </div>
);

const RuleObject = ({ ruleId }: Props): React.JSX.Element => {
  const { currentDatasetsList, goToPrevDataset, goToNextDataset, selectedDataset, currentDatasetsListTitle } =
    useDatasetsController(ruleId);

  const onChange = useCallback(
    (_, closeSelect) =>
      ({ value }) =>
        goToNextDataset(value, closeSelect),
    [goToNextDataset]
  );

  return (
    <Select
      name='select-rule-object'
      items={currentDatasetsList}
      placeholder='Object'
      isInOverlay={true}
      closeButton
      value={selectedDataset}
      onChange={onChange}
      multiLine
      containerClassName={styles['select-container']}
      headerElement={
        <>
          <GoBack onGoBack={goToPrevDataset} />
          <Heading size='xs'>{currentDatasetsListTitle}</Heading>
        </>
      }
      closeOnSelect={false}
    />
  );
};

export default memo(RuleObject);
