import React, { useCallback } from 'react';
import { EditorState, RichUtils } from 'draft-js';

import { Heading } from '~/components/_layout/typography/heading';
import { FormLabel } from '~/components/_form/components/label';
import { Switch } from '~/components/_inputs/switch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getRichEditorState } from '~/pages/pages/edit/context/store/selectors';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { setRichEditorState } from '~/pages/pages/edit/context/store/actions';

import styles from './styles.module.scss';

const Typography = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const richEditorState = usePageEditorSelector(getRichEditorState);

  const currentInlineStyles = richEditorState.getCurrentInlineStyle();

  const onToggleTypography = useCallback(
    (typographyMode: 'ITALIC' | 'BOLD') => () => {
      const editorStateFocused = EditorState.forceSelection(richEditorState, richEditorState.getSelection());
      const newEditorState = RichUtils.toggleInlineStyle(editorStateFocused, typographyMode);
      dispatch(setRichEditorState(newEditorState));
    },
    [dispatch, richEditorState]
  );

  return (
    <>
      <Heading size='xs' className={styles.heading}>
        Typography
      </Heading>
      <div className={styles.container}>
        <FormLabel label='Bold' placement='right' size='xs'>
          <Switch id='bold-switch' checked={currentInlineStyles.has('BOLD')} onChange={onToggleTypography('BOLD')} />
        </FormLabel>
        <FormLabel label='Italic' placement='right' size='xs'>
          <Switch
            id='italic-switch'
            checked={currentInlineStyles.has('ITALIC')}
            onChange={onToggleTypography('ITALIC')}
          />
        </FormLabel>
      </div>
    </>
  );
};

export default Typography;
