import React, { useCallback, useMemo } from 'react';
import { Button } from '~/components/button';

import styles from './styles.module.scss';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { setExpandedTagsByIds, setSelectedTagsById } from '~/pages/tags/context/store/actions';
import { selectTagsByIds } from '~/pages/tags/context/store/thunks';
import classnames from 'classnames';

const CollapseButton = (): React.JSX.Element => {
  const { dispatch, state } = useTagsModuleContext();

  const onCollapseButtonClick = useCallback(() => {
    if (state.expandedTagsIds.length) {
      return dispatch(setExpandedTagsByIds([]));
    }

    return dispatch(setExpandedTagsByIds(state.virtualTags.map(tag => tag.id)));
  }, [dispatch, state.expandedTagsIds.length, state.virtualTags]);

  return (
    <Button
      icon='double-triangle-down'
      is='minor'
      onClick={onCollapseButtonClick}
      className={classnames(styles.button, styles.icon)}
      iconClassname={classnames({
        [styles['collapsed-icon']]: state.expandedTagsIds.length,
        [styles['expanded-icon']]: !state.expandedTagsIds.length
      })}
    >
      {state.expandedTagsIds.length ? 'Collapse all' : 'Expand all'}
    </Button>
  );
};

const SelectButton = (): React.JSX.Element => {
  const { dispatch, state } = useTagsModuleContext();

  const isAllTagsSelected = useMemo(() => {
    return state.virtualTags.every(tag => state.selectedTagsIds.includes(tag.id));
  }, [state.selectedTagsIds, state.virtualTags]);

  const onSelectAllButtonClick = () => {
    if (isAllTagsSelected) {
      return dispatch(setSelectedTagsById([state.virtualTags[0].id]));
    }

    dispatch(selectTagsByIds(state.virtualTags.map(tag => tag.id)));
  };

  return (
    <Button icon='checkmark' is='minor' className={styles.button} onClick={onSelectAllButtonClick}>
      {isAllTagsSelected ? 'Unselect all' : 'Select all'}
    </Button>
  );
};

const CollapseButtons = (): React.JSX.Element => {
  const { state } = useTagsModuleContext();

  return (
    <div className={styles.container}>
      {state.virtualTags.some(tag => tag.descendants.length) ? <CollapseButton /> : null}
      {state.originalTags.length > 1 ? <SelectButton /> : null}
    </div>
  );
};

export default CollapseButtons;
