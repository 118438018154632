import React from 'react';
import StaticLevelSidebar from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/static';
import ContainerLevelSidebar from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container';
import ContentLevelSidebar from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/content';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getSelectedLayer } from '~/pages/pages/edit/context/store/selectors';
import { BlockLayer, LAYER_TYPES } from '~/types/gists/page';

const SIDEBARS_BY_LAYER_TYPE = {
  [LAYER_TYPES.CONTAINER]: ContainerLevelSidebar,
  [LAYER_TYPES.CONTENT]: ContentLevelSidebar,
  [LAYER_TYPES.STATIC]: StaticLevelSidebar
};

const BlockSidebar = (): React.JSX.Element => {
  const selectedLayer = usePageEditorSelector(getSelectedLayer) as BlockLayer;
  const SidebarComponent = SIDEBARS_BY_LAYER_TYPE[selectedLayer.type];

  return <SidebarComponent />;
};

export default BlockSidebar;
