import React from 'react';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getStructure } from '~/pages/pages/edit/context/store/selectors';
import { useSidebarPanel } from '~/components/_layout/panels';
import StructureContainerItem from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure/components/structure-container-item';

import styles from './styles.module.scss';

const Structure = (): React.JSX.Element => {
  const structure = usePageEditorSelector(getStructure);
  const { Panel, List } = useSidebarPanel();

  return (
    <Panel title='Structure' className={styles.container}>
      <List>
        {Object.keys(structure).map(containerId => (
          <StructureContainerItem key={containerId} containerId={containerId} />
        ))}
      </List>
    </Panel>
  );
};

export default Structure;
