import { Column, ColumnInstance, Hooks, Meta, TableDataLike, UseRowSelectState } from 'react-table';
import { CheckboxCell } from '../../cells/checkbox';
import { CheckboxHeading } from '../../headings/checkbox';

export type UseSelectionOptions<Data extends TableDataLike> = Partial<{
  defaultSelected?: UseRowSelectState<Data>['selectedRowIds'];
  disableSelection: boolean;
}>;

export type UseSelectionInstanceProps = {
  disableSelection?: boolean;
};

export const useAllColumns = <Data extends TableDataLike>(
  allColumns: ColumnInstance<Data>[],
  { instance }: Meta<Data>
): Column<Data>[] => {
  const { disableSelection } = instance;

  return disableSelection
    ? allColumns
    : [
        ...allColumns,
        {
          id: '_selector',
          disableResizing: true,
          disableGroupBy: true,
          disableSortBy: true,
          disableEdit: true,
          disableFilters: true,
          disableGlobalFilter: true,
          title: 'Select',
          width: 120,
          Header: CheckboxHeading,
          Cell: CheckboxCell,
          sticky: 'right'
        }
      ];
};

export const useSelection = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.allColumns.push(useAllColumns);
};

useSelection.pluginName = 'useSelection';
