import { digitCheck, lowerCaseCheck, upperCaseCheck } from '../regex';

type Validator = {
  value: boolean;
  text: string;
};

type IsValidated = {
  validators: Validator[];
  isValid: boolean;
};

export const isValidated = (password: string): IsValidated => {
  const validators = [
    { value: password.length >= 10, text: '10+ characters' },
    { value: lowerCaseCheck.test(password), text: '1+ lowercase' },
    { value: upperCaseCheck.test(password), text: '1+ uppercase' },
    { value: digitCheck.test(password), text: '1+ number' }
  ];

  return {
    validators,
    isValid: validators.every(({ value }) => value)
  };
};
