import React from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { ImageFileURL, ImageInput } from '~/components/_inputs/image';

import styles from './image-cell.module.scss';

export const ImageCell = <Data extends TableDataLike>({ cell }: CellProps<Data>): React.JSX.Element => {
  const {
    column: { editCell },
    row: { id: rowId },
    value
  } = cell;

  const handleFile = (fileUrl: ImageFileURL) => {
    editCell(cell, fileUrl);
  };

  return (
    <div className={styles.container}>
      <ImageInput hideLabel cb={handleFile} src={value} id={`image-${rowId}`} />
    </div>
  );
};
