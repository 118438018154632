import React from 'react';
import { FilterProps, TableDataLike } from 'react-table';
import { Input } from '~/components/_inputs/input';

import styles from './text-filter.module.scss';

// type Props = {
//   value?: string;
//   onChange?: (event: Event) => void;
// };

// export const Filter = ({ value = '', onChange }: Props): React.JSX.Element => {
//   return <Input className={styles.container} value={value} onChange={onChange} />;
// };

export const TextFilter = <Data extends TableDataLike>({
  column: { filterValue, setFilter }
}: FilterProps<Data>): React.JSX.Element => {
  const handleChange = (name, value) => {
    setFilter(value);
  };

  return <Input className={styles.container} value={filterValue ?? ''} onChange={handleChange} />;
};
