import { SetState, State } from '~/components/_table/cells/file-primary/context/types';
import { ActionTypes } from '~/components/_table/cells/file-primary/context/actions';

const FilePrimaryCellReducer = (state: State, action: SetState): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SetIsFileDetailsOpened: {
      return {
        ...state,
        isFileDetailsOpened: payload
      };
    }
    case ActionTypes.SetAltText: {
      return {
        ...state,
        altText: payload
      };
    }
    case ActionTypes.SetDescription: {
      return {
        ...state,
        description: payload
      };
    }

    default: {
      return state;
    }
  }
};

export default FilePrimaryCellReducer;
