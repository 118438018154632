import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga4';

import { FLOWS, ONBOARDING_ROUTES } from '~/routes/constants';
import { PrivateRoute } from '~/components/private-route';
import { Onboarding } from '~/pages/onboarding';
import { Layout } from '~/components/_layout';
import { Home } from '~/pages/home/private';
import usePageTracking from '~/hooks/use-page-tracking';

ReactGA.initialize('G-KZNMRM6YJM');

function App(): React.JSX.Element {
  const location = useLocation();
  const match = useRouteMatch(FLOWS);

  usePageTracking();

  return (
    <Layout>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={match?.path ?? location.pathname}>
          <Route component={Onboarding} path={ONBOARDING_ROUTES.entry} />
          <PrivateRoute component={Home} path='/' />
        </Switch>
      </AnimatePresence>
    </Layout>
  );
}

export default App;
