import { PropsWithChildren, useMemo } from 'react';
import { createPortal } from 'react-dom';

type Portals = 'popover' | 'overlay';

type Props = PropsWithChildren<{
  id: Portals;
}>;

export const Portal = ({ children, id }: Props): React.JSX.Element | null => {
  const root = useMemo(() => document.getElementById(id), [id]);

  return root ? createPortal(children, root) : null;
};
