import React, { memo } from 'react';
import { Heading } from '~/components/_layout/typography/heading';

import styles from './hero.module.scss';

const Hero = (): React.JSX.Element => (
  <div className={styles.hero}>
    <Heading size='xl' as='h1'>
      Welcome to LifeHub
    </Heading>
    <Heading special size='m' className={styles.subtitle}>
      ETHICAL Digital Experience Platform
    </Heading>
  </div>
);

export default memo(Hero);
