import React, { memo, useCallback, useMemo } from 'react';

import { ContentEditableAsInput } from '~/components/contenteditable/input';
import styles from '~/pages/tags/components/Workspace/components/TagForm/styles.module.scss';
import { TAG_FORM_VALIDATION_KEYS } from '~/pages/tags/constants';
import { setShouldValidate, updateValidationErrors } from '~/pages/tags/context/store/actions';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { Heading } from '~/components/_layout/typography/heading';
import useMountEffect from '~/hooks/use-mount-effect';
import { syncSaveChanges, updateVirtualTag } from '~/pages/tags/context/store/thunks';
import { FlatTag } from '~/types/gists/tag';

const Title = ({ children }: React.PropsWithChildren<void>) => <Heading size='l'>{children}</Heading>;

type Props = FlatTag;

const Name = ({ id, name }: Props): React.JSX.Element => {
  const { state, dispatch } = useTagsModuleContext();

  const validateName = useCallback(() => {
    if (!name) {
      dispatch(
        updateValidationErrors({
          [TAG_FORM_VALIDATION_KEYS.NAME]: 'Name field is required'
        })
      );

      return dispatch(syncSaveChanges());
    }

    const isNameUnique =
      state.originalTags.filter(originalTag => originalTag.name === name && originalTag.id !== id).length === 0;

    dispatch(
      updateValidationErrors({
        [TAG_FORM_VALIDATION_KEYS.NAME]: isNameUnique ? '' : 'Name field should be unique'
      })
    );
    return dispatch(syncSaveChanges());
  }, [dispatch, id, name, state.originalTags]);

  const onNameBlur = useCallback(() => {
    dispatch(setShouldValidate(true));

    validateName();
  }, [dispatch, validateName]);

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => dispatch(updateVirtualTag(id, { name: e.target.value })),
    [dispatch, id]
  );

  useMountEffect(() => {
    validateName();
  });

  const isCreationMode = useMemo(
    () => state.originalTags.length !== state.virtualTags.length,
    [state.originalTags.length, state.virtualTags.length]
  );

  return (
    <ContentEditableAsInput
      as={Title}
      className={styles.name}
      value={name}
      onChange={onNameChange}
      onBlur={onNameBlur}
      error={state.shouldValidate ? state.validationErrors[TAG_FORM_VALIDATION_KEYS.NAME] : undefined}
      isRequired
      editingInitialState={isCreationMode}
    />
  );
};

export default memo(Name);
