import React, { useCallback } from 'react';

import useDocumentTitle from '~/hooks/use-document-title';
import { OnboardingTemplate } from '~/templates/onboarding';
import { Form, Formik } from 'formik';
import { api } from '~/api';
import PhoneField from '~/components/_form/fields/phone';
import { ButtonField } from '~/components/_form/fields/button';
import { MFA_CHALLENGE_TO_ROUTE } from '~/pages/onboarding/mfa/constants';
import { useHistory } from 'react-router-dom';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { phoneNumberValidationSchema } from '~/pages/onboarding/mfa/schemas';

type FormikValues = {
  phoneNumber: string;
};

const initialValues: FormikValues = {
  phoneNumber: ''
};

const PhoneSetup = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  const history = useHistory();

  const onSubmit = useCallback(
    async ({ phoneNumber }: FormikValues) => {
      try {
        const email = localStorage.getItem('email') as string;
        const password = localStorage.getItem('password') as string;

        if (email && password) {
          const { success, nextChallenge } = await api.onboarding.savePhoneNumber({ email, password, phoneNumber });
          if (success) {
            const nextMfaRoute = MFA_CHALLENGE_TO_ROUTE[nextChallenge];
            history.push(nextMfaRoute);
          } else {
            history.push(ONBOARDING_ROUTES.signInEmail);
          }
        } else {
          history.push(ONBOARDING_ROUTES.signInEmail);
        }
      } catch (e) {
        history.push(ONBOARDING_ROUTES.signInEmail);
      }
    },
    [history]
  );

  return (
    <OnboardingTemplate
      message={[
        'To keep LifeHub secure, you need to set up Multi-Factor Authentication.',
        'This means that each time you log in, as well as a password, we’ll send a security code to your phone to make sure it’s really you.',
        'Please enter your phone number, including your country code'
      ]}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={phoneNumberValidationSchema}>
        {({ isValid, dirty, isSubmitting }) => {
          return (
            <Form>
              <PhoneField name={'phoneNumber'} label={'Phone number'} />
              <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
                Continue
              </ButtonField>
            </Form>
          );
        }}
      </Formik>
    </OnboardingTemplate>
  );
};

export default PhoneSetup;
