import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CustomError } from '~/helpers/common/custom-error';

import useDocumentTitle from '~/hooks/use-document-title';

import { PasswordField } from '~/components/_form/fields/password';
import { ButtonField } from '~/components/_form/fields/button';
import { OnboardingTemplate } from '~/templates/onboarding';
import { onboarding } from '~/api/onboarding';
import { passwordSchemaSimplified } from '~/helpers/validations/onboarding';
import { useDispatch } from '~/store/hooks';
import { setUser } from '~/store/reducers/account';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { UserService } from '~/services/user-service';
import { MFA_CHALLENGE_TO_ROUTE } from '~/pages/onboarding/mfa/constants';

const MESSAGE = 'Ok, now enter your password';

const initialValues = {
  password: ''
};

export const SignInPassword = (): React.JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onSubmit = async ({ password }: { password: string }, { setFieldError }) => {
    const email = localStorage.getItem('email');

    try {
      if (email) {
        const response = await onboarding.login({ email, password });

        const nextChallenge = response.nextChallenge;

        if (nextChallenge) {
          const nextMfaRoute = MFA_CHALLENGE_TO_ROUTE[nextChallenge];

          localStorage.removeItem('email');
          localStorage.removeItem('password');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');

          localStorage.setItem('email', response.email);
          localStorage.setItem('password', password);
          localStorage.setItem('session', response.session as string);

          history.push(nextMfaRoute);
        } else {
          dispatch(setUser(response));

          localStorage.removeItem('email');
          localStorage.removeItem('password');

          localStorage.setItem('token', response.token);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('email', response.email);
          localStorage.setItem('accessToken', response.accessToken);

          UserService.decodeToken(response.token);

          history.push(ONBOARDING_ROUTES.signInLoggingIn);
        }
      } else {
        history.push(ONBOARDING_ROUTES.signInEmail);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      if (error.message === 'User is not confirmed.' && email) {
        await onboarding.resendCode({ email });
        history.push(ONBOARDING_ROUTES.signUpConfirm);
        return;
      }

      if (error.message === 'User is not in admin group' || error.message === 'User has no permissions') {
        history.push(ONBOARDING_ROUTES.setupPermissions);
        return;
      }

      setFieldError('password', error.message);
    }
  };

  return (
    <OnboardingTemplate message={MESSAGE} backButton>
      <Formik validationSchema={passwordSchemaSimplified} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <PasswordField name='password' label='Password' />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
            <ButtonField is='minor' to={ONBOARDING_ROUTES.forgotPasswordUpEmail}>
              I forgot my password
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};
