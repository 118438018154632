import { OriginalTag } from '~/types/gists/tag';

export const generateNewTag = (name: OriginalTag['name'], parentId: OriginalTag['parentId']): Partial<OriginalTag> => {
  return {
    description: null,
    name,
    parentId,
    subHeading: null
  };
};
