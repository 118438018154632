import React from 'react';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Label } from '~/components/_layout/typography/label';

import styles from './styles.module.scss';

export const TagName = ({ name }: { name: string }): React.JSX.Element => {
  return (
    <Label size='s' className={styles['tag-name']}>
      <TooltipOnHover title={name} delay={100}>
        {name}
      </TooltipOnHover>
    </Label>
  );
};
