import { Options } from '@popperjs/core';
import { SelectItem } from '~/components/_inputs/select';
import { OrderItem } from '~/components/_layout/popovers/upload/types';
import { SortDirections } from '~/components/_table/types';

export const DEFAULT_ITEMS_LIMIT = 10;

export const NAME_OR_TAG_KEY = 'file.name || tags.name';

export const ERROR_MESSAGE = 'Something went wrong.';

export const POPPER_OPTIONS: Partial<Options> = {
  placement: 'bottom',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top']
      }
    },
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [10, 10]
      }
    }
  ]
};

export enum ORDER {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  name = 'name',
  size = 'size'
}

export const ORDER_ITEMS: SelectItem<OrderItem>[] = [
  {
    id: ORDER.createdAt + SortDirections.ASC,
    label: 'Recently created first',
    value: { field: ORDER.createdAt, direction: SortDirections.ASC },
    disabled: false
  },
  {
    id: ORDER.updatedAt + SortDirections.ASC,
    label: 'Recently modified first',
    value: { field: ORDER.updatedAt, direction: SortDirections.ASC },
    disabled: false
  },
  {
    id: ORDER.name + SortDirections.ASC,
    label: 'File name A-Z',
    value: { field: ORDER.name, direction: SortDirections.ASC },
    disabled: false
  },
  {
    id: ORDER.name + SortDirections.DESC,
    label: 'File name Z-A',
    value: { field: ORDER.name, direction: SortDirections.DESC },
    disabled: false
  },
  {
    id: ORDER.size + SortDirections.ASC,
    label: 'File size (Smallest first)',
    value: { field: ORDER.size, direction: SortDirections.ASC },
    disabled: false
  },
  {
    id: ORDER.size + SortDirections.DESC,
    label: 'File size (Largest first)',
    value: { field: ORDER.size, direction: SortDirections.DESC },
    disabled: false
  }
];
