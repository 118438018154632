import React from 'react';
import { useHistory } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';

import { OnboardingTemplate } from '~/templates/onboarding';
import { copyToClipboard } from '~/helpers/formatters';
import { Button } from '~/components/button';

import styles from './setup-permissions.module.scss';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const LHTeamEmail = 'team@life-moments.co.uk';

export const SetupPermissions = (): React.JSX.Element => {
  useDocumentTitle('Welcome to LifeHub', '%s');

  const history = useHistory();

  const onEmailTeam = e => {
    e.preventDefault();

    window.location.href = `mailto:${LHTeamEmail}?subject=${encodeURIComponent(
      'LifeHub permissions'
    )}&body=${encodeURIComponent(
      'Hi team,\n' +
        '\n' +
        'LifeHub is telling me that it needs to set up permissions for my account.\n' +
        '\n' +
        `The email address I’m using to access LifeHub is: ${localStorage.getItem('email')}\n` +
        '\n' +
        'Many thanks!'
    )}`;
  };

  const onCopyToClipboard = e => {
    e.preventDefault();

    copyToClipboard(LHTeamEmail);
  };

  const redirectToLogin = e => {
    e.preventDefault();

    history.push(ONBOARDING_ROUTES.signInEmail);
  };

  return (
    <OnboardingTemplate
      message={[
        'Welcome to LifeHub!',
        'We need to set up the permissions for your account.',
        'Please email us now to explain you’re at this point in your LifeHub journey.'
      ]}
    >
      <div className={styles.buttons}>
        <Button onClick={onEmailTeam} is='major' fluid>
          Email {LHTeamEmail}
        </Button>
        <Button onClick={onCopyToClipboard} is='minor' fluid>
          Copy email address to clipboard
        </Button>
        <Button onClick={redirectToLogin} is='minor' fluid>
          Try logging in again
        </Button>
      </div>
    </OnboardingTemplate>
  );
};
