import { SyntheticEvent } from 'react';

export const IS_ADD_EVENT_LISTENER_SUPPORTED = (target: unknown): boolean => {
  return target instanceof EventTarget && 'addEventListener' in Object.getPrototypeOf(target);
};

export type EventOptions = boolean | (AddEventListenerOptions & EventListenerOptions);

export const unListenIt = <K extends keyof DocumentEventMap>(
  element: EventTarget,
  type: K,
  callback: (this: Document, event: DocumentEventMap[K]) => unknown,
  options?: EventOptions
): void | null => {
  if (IS_ADD_EVENT_LISTENER_SUPPORTED(element)) {
    element.removeEventListener(type, callback as any, options); // TODO: Refine type

    return null;
  }

  return console.warn('addEventListener is not supported on this environment');
};

export const listenIt = <K extends keyof DocumentEventMap>(
  element: EventTarget,
  type: K,
  callback: (this: Document, event: DocumentEventMap[K]) => unknown,
  options?: EventOptions
): void | (() => void | null) => {
  if (IS_ADD_EVENT_LISTENER_SUPPORTED(element)) {
    element.addEventListener(type, callback as any, options); // TODO: Refine type

    return () => unListenIt(element, type, callback, options);
  }

  return console.warn('addEventListener is not supported on this environment');
};

export const unListenItForEach = (entities: Record<string, unknown> = {}): Record<string, null> => {
  return Object.keys(entities).reduce((listeners, key) => {
    const unlisten = entities[key];

    if (typeof unlisten === 'function') {
      return { ...listeners, [key]: unlisten() };
    }

    return listeners;
  }, {});
};

// this self-immediate function test whether passive options of
// addEventListener is supported by browser.

// 'passive' option restricts using of preventDefault() method of event;
// it means if addEventListener callback will try to prevent default action
// on passive listener browser will ignore that invokation and throw a warning
// to console

// passive listeners improve performance of consecutive
// events like 'scroll' or 'touchmove'

// but in some cases when you need to prevent a scroll or user interaction
// you should first be sure that listener is not a passive

// in legacy browsers, the 3rd arg of addEventListener it is a bool flag of
// a 'capture' option.

// so it means if addEventLsitener in legacy browser doesn't support options arg
// or 'passive' options we could unintentionally start to capture an event
// instead of making it active. It could cause bugs.

// https://developer.mozilla.org/ru/docs/Web/API/EventTarget/addEventListener
export const IS_PASSIVE_SUPPORTED = (() => {
  let supportsPassiveOption = false;

  try {
    // to handle a execution of 'passive' prop by addEventListener
    // we should somohow intercept an operation.
    // lets define get function for property 'passive'.
    // it will be executed when addEventListener get access to the prop.
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassiveOption = true;

        return true;
      }
    });

    // add fake listener to test 'passive' prop support.
    const listener = listenIt(window, 'click', () => void 0, opts);

    if (listener) {
      listener();
    }
  } catch (e) {
    console.warn('Passive listeners are not available on this environment');
  }

  return supportsPassiveOption;
})();

export const prevent = <T extends Event>(event: T, cb?: () => unknown): boolean => {
  if (cb) {
    cb();
  }

  event.preventDefault();

  return false;
};

export const stop = <T extends Event>(event: T, cb?: () => unknown): boolean => {
  if (cb) {
    cb();
  }

  event.stopPropagation();

  return true;
};

export const onKeyDown =
  <T extends SyntheticEvent>(key: KeyMap, cb?: () => unknown) =>
  (event: T): void => {
    const isSpecificKey = Key.isSpecificKey(key);

    if (isSpecificKey(event.nativeEvent) && cb) {
      cb();
    }
  };

export class Touch {
  static isSingle = (event: Event): boolean => {
    if (event instanceof TouchEvent) {
      return event.touches.length === 1;
    }

    return false;
  };
}

type KeyboardEventKeyConstraint = 'key' | 'keyCode';
export type KeyMap = 'escape' | 'enter' | 'tab' | 'shift';
export type ServiceKeyMap = 'altKey' | 'ctrlKey' | 'shiftKey';

const KEYS_CONSTRAINTS: Record<string, Array<[KeyboardEventKeyConstraint, string | number]>> = {
  escape: [
    ['key', 'Escape'],
    ['keyCode', 27]
  ],
  enter: [
    ['key', 'Enter'],
    ['keyCode', 13]
  ],
  tab: [
    ['key', 'Tab'],
    ['keyCode', 9]
  ],
  shift: [
    ['key', 'Shift'],
    ['keyCode', 16]
  ]
};

export class Key {
  static isSpecificKey =
    (key: KeyMap, serviceKey?: ServiceKeyMap) =>
    <T>(event: T): boolean => {
      if (event instanceof KeyboardEvent) {
        return (
          KEYS_CONSTRAINTS[key]?.every(([key, value]) => event[key] === value) &&
          (serviceKey ? event[serviceKey] : true)
        );
      }

      return false;
    };
}
