import React from 'react';
import { useHistory } from 'react-router-dom';
import { encodeQueryParams } from '~/api/utils';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { ConfirmCallback, useConfirm } from '~/components/_layout/popovers/confirm';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { useTheme } from '~/components/theme';

export const DELAY_BEFORE_REDIRECT = 500;

export type Props<T> = {
  name: string;
  rootRoute: string;
  onConfirm(): Promise<void | T>;
};

export const DeleteCurrentAction = <T extends unknown>({
  name,
  rootRoute,
  onConfirm
}: Props<T>): React.JSX.Element | null => {
  const { push } = useHistory();
  const { affiliate, env } = useTheme();
  const { Confirm, ...confirmProps } = useConfirm();

  const redirectToList = () => push(encodeQueryParams(rootRoute, { affiliate, env }));

  const handleConfirm: ConfirmCallback = async (setLoading, setReady) => {
    setLoading();

    try {
      const response = await onConfirm();

      if (response) {
        setTimeout(() => redirectToList(), DELAY_BEFORE_REDIRECT);
      }
    } catch (error) {
      console.log(error);
    } finally {
      await setReady();
    }
  };

  return name ? (
    <TopBarTool>
      <TooltipOnHover title='Delete' delay={100}>
        <Confirm
          message={[
            <span key='permanently-delete'>
              Are you sure want to delete <strong>{`"${name}"`}</strong>?
            </span>,
            'This cannot be undone...'
          ]}
          onConfirm={handleConfirm}
          {...confirmProps}
          cancelText={'Keep it'}
          confirmText={'Delete it'}
        >
          {toggle => <Button is='minor' icon='bin' onClick={() => toggle(true)} />}
        </Confirm>
      </TooltipOnHover>
    </TopBarTool>
  ) : null;
};
