import React, { ReactNode } from 'react';
import { useTheme } from '~/components/theme';

type Props = {
  children: ReactNode;
};

export const Frame = ({ children }: Props): React.JSX.Element => {
  const { theme, env } = useTheme();

  const currentTheme = themes[theme];
  const currentEnv = envs[env];

  return (
    <svg width='440' height='890' viewBox='0 0 440 890' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.1285 0C29.5437 0 3.12847 26.4152 3.12847 59V117.468H0V150.895H3.12847V181.436H0V246.201H3.12847V264.745H0V329.51H3.12847V831C3.12847 863.585 29.5437 890 62.1285 890H377.872C410.457 890 436.872 863.585 436.872 831V307.113H440V202.653H436.872V59C436.872 26.4152 410.457 0 377.872 0H62.1285ZM274.014 32.0975C274.014 34.9821 271.68 37.3205 268.801 37.3205C265.922 37.3205 263.588 34.9821 263.588 32.0975C263.588 29.2129 265.922 26.8745 268.801 26.8745C271.68 26.8745 274.014 29.2129 274.014 32.0975ZM193.095 32.0975C193.095 30.3668 194.498 28.9637 196.229 28.9637H243.771C245.502 28.9637 246.905 30.3668 246.905 32.0975C246.905 33.8283 245.502 35.2313 243.771 35.2313H196.229C194.498 35.2313 193.095 33.8283 193.095 32.0975Z'
        fill='url(#paint0_linear)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M110 25.9814C110 23.7723 108.21 21.9814 106 21.9814H62.5029C41.5161 21.9814 24.5029 38.9946 24.5029 59.9815V830.981C24.5029 851.968 41.5161 868.981 62.5029 868.981H376.503C397.49 868.981 414.503 851.968 414.503 830.981V59.9814C414.503 38.9946 397.49 21.9814 376.503 21.9814H333.068C330.859 21.9814 329.068 23.7723 329.068 25.9814V31.7504C329.068 43.9007 319.218 53.7504 307.068 53.7504H132C119.85 53.7504 110 43.9007 110 31.7504V25.9814Z'
        fill='url(#paint1_linear)'
      />

      <defs>
        <linearGradient id='paint0_linear' x1='220' y1='0.562012' x2='220' y2='890.562' gradientUnits='userSpaceOnUse'>
          <stop stopColor={currentTheme.from} />
          <stop offset='1' stopColor={currentTheme.to} />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='24.5029'
          y1='22.5435'
          x2='823.881'
          y2='524.042'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={currentEnv.from} />
          <stop offset='1' stopColor={currentEnv.to} />
        </linearGradient>
      </defs>
      <g>
        <foreignObject x='32' y='132' width='375' height='100%'>
          {children}
        </foreignObject>
      </g>
    </svg>
  );
};

const themes = {
  light: {
    from: '#F2F2F2',
    to: '#E6E6E6'
  },
  dark: {
    from: '#2D2D2D',
    to: '#222222'
  }
};

const envs = {
  dev: {
    from: '#0EB004',
    to: '#C4E40A'
  },
  test: {
    from: '#F54E25',
    to: '#F5C413'
  },
  stage: {
    from: '#0783F4',
    to: '#2CBA85'
  },
  prod: {
    from: '#C31DB7',
    to: '#F63D29'
  }
};
