import { StringShim } from '~/helpers/shims/string-shim';
import * as TYPES from '../types';

// span='6'
// span='7+6'

export const parseSpanValue = (entity: TYPES.SpanHelper | undefined): string | string[] | undefined => {
  if (entity) {
    if (entity?.includes('+')) {
      const PROPERTIES = ['start', 'span'];

      return entity.split('+').map((value, index) => StringShim.toKebabCase(PROPERTIES[index], value));
    }

    return StringShim.toKebabCase('span', entity);
  }

  return undefined;
};

// edge

export const parseEdgeValue = (entity: TYPES.EdgeHelper | undefined): string | undefined => {
  if (entity) {
    return 'edge';
  }

  return undefined;
};

type FlexHelperValue =
  | TYPES.FlexDirectionHelper
  | TYPES.FlexWrapHelper
  | TYPES.FlexAlignHelper
  | TYPES.FlexJustifyHelper
  | undefined;

type FlexHelperName = 'direction' | 'wrap' | 'align' | 'justify';

const parseHelperValue = (entity: FlexHelperValue, helperName: FlexHelperName): string => {
  if (typeof entity === 'string') {
    return StringShim.toKebabCase('flex', helperName, entity);
  }

  return 'flex';
};

// flex
// flex='column'

type FlexHelper = [FlexHelperValue, FlexHelperName];

export const parseFlexValue = (entity: FlexHelper[]): string[] => {
  return entity.reduce((acc: string[], [helper, helperName]: FlexHelper): string[] => {
    if (helper) {
      const parsed: string = parseHelperValue(helper, helperName);

      return [...acc, parsed];
    }

    return acc;
  }, []);
};
