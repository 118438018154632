import React from 'react';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { Button } from '~/components/button';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';

type Props = {
  onReplaceFiles: () => void;
  onMakeCopies: () => void;
  onCancelUpload: () => void;
  uniquenessFilesLength: number;
  show: boolean;
};

const UniquenessPopover = ({ onReplaceFiles, onMakeCopies, onCancelUpload, uniquenessFilesLength, show }: Props) => {
  const { CoachPopover, ...uniquenessProps } = useCoachPopover({
    disabled: !show
  });

  return (
    <>
      <CoachPopover {...uniquenessProps}>
        <CoachMessage>
          {uniquenessFilesLength > 1 ? (
            <>{uniquenessFilesLength} of those files have the same name as existing files in this folder.</>
          ) : (
            <>This file has the same name as an existing file in this folder.</>
          )}
          <>What do you want to do?</>
        </CoachMessage>
        <Button key='replace' onClick={onReplaceFiles} is='major' fluid>
          Replace {uniquenessFilesLength > 1 ? 'them all' : 'it'}
        </Button>
        <Button key='make_copies' onClick={onMakeCopies} is='major' fluid>
          Make {uniquenessFilesLength > 1 ? 'new copies' : 'a new copy'}
        </Button>
        <Button key='cancel' onClick={onCancelUpload} is='minor' fluid>
          Cancel upload
        </Button>
      </CoachPopover>
    </>
  );
};

export default UniquenessPopover;
