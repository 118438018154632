import React, { memo, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import QS from 'query-string';

import { ScrollContainer } from '~/helpers/ui-kit/scroll-container';
import { RefSetter } from '~/hooks/use-popper';

import { Grid } from '~/components/grid';
import { Button } from '~/components/button';
import { UsePopover } from '~/components/popover';

import { ClientEnvironmentSection } from './client-environment';
import { MyAccountSection } from './my-account';
import { CurrentEnvironmentSection } from './current-environment';
import { CategoriesSection } from './categories';

import styles from './main-menu.module.scss';
import { RootState } from '~/store';
import { useTheme } from '~/components/theme';
import { UserService } from '~/services/user-service';
import { affiliates as affiliatesApi } from '~/api/affiliates';
import { user as userApi } from '~/api/user';
import { AffiliateInfo } from '~/types/gists/affiliates';

type Props = Pick<UsePopover, 'show' | 'toggle'> & {
  anchor?: HTMLElement | null;
  onPopoverRef: RefSetter;
  onTriggerRef: RefSetter;
};

const MainMenu = ({ anchor, show, onPopoverRef, onTriggerRef, toggle }: Props): React.JSX.Element => {
  const location = useLocation();
  const initialSearch = useRef(QS.parse(location.search));

  const isMainMenuOpened = useSelector((state: RootState) => state.mainMenu.isMainMenuOpened);

  const { setAffiliate, setAffiliateDetails, affiliateDetails } = useTheme();

  const handleMenuClose = useCallback(async () => {
    const { affiliate, env } = initialSearch.current;
    if (affiliate && env) {
      setAffiliate(affiliate as AffiliateKey, env as Environment);
      try {
        const [currentAffiliate, functionalPermissions] = await Promise.all([
          affiliatesApi.getCurrent(),
          userApi.getUserFunctionalPermissions()
        ]);
        UserService.setFunctionalPermissions(functionalPermissions);
        setAffiliateDetails(currentAffiliate);
      } catch {
        UserService.setAffiliates({});
        UserService.resetFunctionalPermissions();
        setAffiliateDetails({} as AffiliateInfo);
      }
    }

    toggle(false);
  }, [setAffiliate, setAffiliateDetails, toggle]);

  const handleClick = useCallback(() => {
    if (isMainMenuOpened) {
      handleMenuClose();
    } else if (UserService.affiliates && affiliateDetails) {
      toggle(true);
    }
  }, [affiliateDetails, handleMenuClose, isMainMenuOpened, toggle]);

  useLayoutEffect(() => {
    if (anchor) {
      onTriggerRef(anchor);
    }
  }, [anchor, onTriggerRef]);

  useEffect(() => {
    // to save search at the moment when the main menu just opened
    if (show) {
      initialSearch.current = QS.parse(location.search);
    }
  }, [location.search, show]);

  useEffect(() => {
    const closeMenu = () => toggle(false);

    document.addEventListener('closeMenu', closeMenu);

    return () => {
      const closeMenuEvent = new Event('closeMenu');
      document.dispatchEvent(closeMenuEvent);
      document.removeEventListener('closeMenu', closeMenu);
    };
  }, [toggle]);

  return (
    <div>
      <Button is='minor' icon='menu' onClick={handleClick}>
        {show ? 'Close' : 'Menu'}
      </Button>
      <div ref={onPopoverRef} className={styles.popover}>
        {show && (
          <nav className={styles.container}>
            <Grid className={styles.sections}>
              <ScrollContainer as='div' at='y' className={styles.sidebar}>
                <ClientEnvironmentSection />
                {/*<ConsoleEnvironmentSection />*/}
                <MyAccountSection />
              </ScrollContainer>
              <div>
                <CurrentEnvironmentSection />
                <CategoriesSection />
              </div>
            </Grid>
          </nav>
        )}
      </div>
    </div>
  );
};

export default memo(MainMenu);
