import { createAction, createReducer } from '@reduxjs/toolkit';
import { UserParams } from '~/api/onboarding';

const SET_USER = 'account/set-user';
export const LOG_OUT = 'account/log-out';

export type User = UserParams | Record<string, any>;
type State = { user: User };

export const setUser = createAction(SET_USER, ({ accessToken, email, refreshToken, token }: UserParams) => ({
  payload: { accessToken, email, refreshToken, token }
}));

export const logout = createAction(LOG_OUT);

export const initialState = {
  user: {}
};

export const account = createReducer<State>(initialState, builder =>
  builder.addCase(setUser, (state, action) => {
    const { accessToken, email, refreshToken, token } = action.payload;

    state.user = { ...state.user, accessToken, email, refreshToken, token };
  })
);
