import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

export type OwnProps = {
  preventQuery?: boolean;
};

export type Props = OwnProps & LinkProps;

export const LinkWithQuery = ({ preventQuery, to, children, ...rest }: Props): React.JSX.Element => {
  const { search } = useLocation();

  let path;

  if (typeof to === 'string') {
    path = preventQuery ? to : to + search;
  }

  if (typeof to === 'object') {
    path = {
      state: to.state,
      pathname: to.pathname,
      ...(!preventQuery && { search: to.search })
    };
  }

  return (
    <Link to={path} {...rest}>
      {children}
    </Link>
  );
};
