import classnames from 'classnames';
import { compose } from 'redux';
import { ColumnProps, OwnProps } from '../index';
import { parseEdgeValue, parseFlexValue, parseSpanValue } from './parsers';

export const mapSpanHelpers = ({ className, span, ...props }: ColumnProps): OwnProps => {
  return {
    ...props,
    className: classnames(className, parseSpanValue(span))
  };
};
export const mapEdgeHelpers = ({ className, edge, ...props }: ColumnProps): OwnProps => {
  return {
    ...props,
    className: classnames(className, parseEdgeValue(edge))
  };
};
export const mapFlexHelpers = ({ className, flex, wrap, align, justify, ...props }: ColumnProps): OwnProps => {
  return {
    ...props,
    className: classnames(
      className,
      parseFlexValue([
        [flex, 'direction'],
        [wrap, 'wrap'],
        [align, 'align'],
        [justify, 'justify']
      ])
    )
  };
};

export const mapProps: (columnProps: ColumnProps) => Object = compose(mapSpanHelpers, mapEdgeHelpers, mapFlexHelpers);
