import React, { useCallback } from 'react';
import { FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';
import { Attribute } from '~/types/gists/attribute';

type Props = {
  value: string;
  onChange: CallableFunction;
  name: Attribute['name'];
  label: string;
};

const InputField = ({ value, onChange, name, label }: Props): React.JSX.Element => {
  const handleChange = useCallback<HandleChange>((name, value) => onChange(name, value), [onChange]);

  return (
    <FormLabel label={label} placement='top'>
      <Input value={value} onChange={handleChange} name={name} />
    </FormLabel>
  );
};

export default InputField;
