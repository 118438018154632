import React, { ElementType, forwardRef, PropsWithRef, ReactNode, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { useScrollControl } from '~/hooks/use-scroll-control';
import { useMergeRefs } from '~/hooks/use-merge-refs';

import styles from './scroll-container.module.scss';

type OwnProps = HTMLAttributes<HTMLDivElement> &
  PropsWithRef<{
    at?: 'x' | 'y' | 'both';
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
  }>;

type Props = PolymorphicComponentProps<ElementType, OwnProps>;

const defaultElement = 'div';

const ScrollContainerComponent = (
  { className, children, at = 'both', disabled = false, ...props }: Props,
  ref: typeof props.ref
): React.JSX.Element => {
  const { anchor } = useScrollControl<typeof props.as>(disabled);

  return (
    <Box
      ref={useMergeRefs(ref, anchor)}
      as={defaultElement}
      className={classnames(styles.container, styles[`at-${at}`], className)}
      {...props}
    >
      {children}
    </Box>
  );
};

export const ScrollContainer = forwardRef(ScrollContainerComponent);
