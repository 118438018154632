import React, { useCallback, useMemo } from 'react';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { TableApi } from '~/components/_table/types';
import { Block } from '~/types/gists/Block';
import { Table } from '~/components/_table';
import { useTheme } from '~/components/theme';
import { columns } from '~/pages/blocks/components/blocks-table/columns';
import { SortingRule, TableAction } from 'react-table';
import { useHistory } from 'react-router-dom';
import { BLOCKS_ROUTES } from '~/routes/private/blocks/constants';
import { calculatePageCount } from '~/components/_table/helpers/common';
import { useSelector } from '~/store/hooks';

const gist: TableApi = 'blocks';
const defaultSortBy: SortingRule<Block>[] = [
  {
    id: 'name',
    desc: false
  }
];

const BlocksTable = (): React.JSX.Element => {
  const { affiliate, env } = useTheme();
  const { push } = useHistory();

  const { get, editCell, autoResetProps, items, count, publish } = useTableFetch<Block>({
    gist
  });

  const goToBlock = useCallback<TableAction<Block>['handler']>(
    ({ row: { original } }) => push(`${BLOCKS_ROUTES.root}/${original.id}?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, push]
  );

  const actions = useMemo<TableAction<Block>[]>(() => {
    return [
      {
        id: 'edit',
        icon: 'edit',
        handler: goToBlock,
        tooltip: 'Edit Block'
      }
    ];
  }, [goToBlock]);

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  return (
    <Table<Block>
      gist={gist}
      key={`${affiliate}_${env}`}
      columns={columns}
      data={items}
      actions={actions}
      actionsColumnWidth={154}
      onUpdate={get}
      onPublish={publish}
      onEditCell={editCell}
      manualSortBy
      defaultSortBy={defaultSortBy}
      manualFilters
      manualPagination
      pageCount={pageCount}
      preventGet={isMainMenuOpened}
      count={count}
      disableSelection={true}
      {...autoResetProps}
    />
  );
};

export default BlocksTable;
