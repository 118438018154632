import React from 'react';
import { ReactSVG } from 'react-svg';

import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { Image } from '~/components/image';
import useFilePrimaryCellContext from '~/components/_table/cells/file-primary/context/use-file-primary-cell-context';
import { LifeHubLogo } from '~/components/_layout/logo';
import { CoachMessage } from '~/components/_layout/coach/coach-message';

import styles from './preview.module.scss';
import { Icon } from '~/components/icon';

const Preview = () => {
  const { cellProps } = useFilePrimaryCellContext();

  const {
    row: {
      original: { s3ObjectUrl, altText, folder, updatedAt }
    },
    state: { loading, error }
  } = cellProps;

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.loader}>
          <LifeHubLogo className={styles.icon} animate />
          <CoachMessage>Loading...</CoachMessage>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.loader}>
          <Icon name='alert' className={styles.icon} />
          <CoachMessage>{error}</CoachMessage>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {folder === FOLDER_TYPES.IMAGES && (
        <div className={styles['image-wrapper']}>
          <Image src={`${s3ObjectUrl}?updatedAt=${updatedAt}`} alt={altText || undefined} />
        </div>
      )}
      {folder === FOLDER_TYPES.ICONS && (
        <ReactSVG src={`${s3ObjectUrl}?updatedAt=${updatedAt}`} className={styles['icon-wrapper']} />
      )}
    </div>
  );
};

export default Preview;
