import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '~/store/hooks';
import { logout } from '~/store/reducers/account';
import { UserService } from '~/services/user-service';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { Heading } from '~/components/_layout/typography/heading';
import { Button } from '~/components/button';
import { FormField } from '~/components/_form/components/field';
import { ThemeSelect } from './theme-select';

import styles from './account-section.module.scss';
import { openInNewTab } from '~/helpers/common';

export const MyAccountSection = (): React.JSX.Element => {
  const dispatch = useDispatch();

  const history = useHistory();

  const onLogout = () => {
    dispatch(logout());

    UserService.removeUserInfo();

    history.push(ONBOARDING_ROUTES.signInEmail);
  };

  const onGoToHelpCentre = () =>
    openInNewTab('https://www.notion.so/lifemoments/LifeHub-Help-Centre-a0407f12cc35419fbecbd41830003a70?pvs=4');

  return (
    <section>
      <header>
        <Heading size='xs' className={styles.headline}>
          My account
        </Heading>
      </header>
      <form className={styles.form}>
        <FormField>
          <ThemeSelect />
        </FormField>
        <FormField>
          <Button onClick={onGoToHelpCentre} is='minor' fluid>
            Help Centre
          </Button>
        </FormField>
        <FormField>
          <Button onClick={onLogout} is='minor' fluid>
            Log out
          </Button>
        </FormField>
      </form>
    </section>
  );
};
