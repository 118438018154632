import React, { useEffect, useRef, useState } from 'react';
import { FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';

import styles from './styles.module.scss';

type Props = {
  onSaveUrl: (url: string) => void;
  url: string;
};

const Url = ({ onSaveUrl, url }: Props): React.JSX.Element => {
  const [innerUrl, setInnerUrl] = useState(url);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInnerUrl(url);
  }, [url]);

  return (
    <div className={styles.container}>
      <FormLabel label='Link' placement='top'>
        {url && (
          <a href={url} className={styles.link} target='_blank' rel='noreferrer'>
            Test Link
          </a>
        )}
        <Input
          value={innerUrl}
          ref={ref}
          placeholder='Type a URL'
          onBlur={() => onSaveUrl(innerUrl)}
          onKeyDown={e => {
            if (e.key === 'Enter') ref.current?.blur();
          }}
          onChange={(_, value) => setInnerUrl(value)}
        />
      </FormLabel>
    </div>
  );
};

export default Url;
