import React, { useCallback } from 'react';

import { useSidebarPanel } from '~/components/_layout/panels';
import { Label } from '~/components/_layout/typography/label';
import { TextareaAutosize } from '~/components/_inputs/textarea';
import { Block } from '~/types/gists/Block';

import styles from './styles.module.scss';

type Props = {
  description: Block['description'];
  onChange: (name: keyof Block) => (value: any) => void;
};

const About = ({ description, onChange }: Props): React.JSX.Element => {
  const { Panel } = useSidebarPanel();

  const onChangeDescription = useCallback<HandleChange>((_, value) => onChange('description')(value), [onChange]);

  return (
    <Panel title={'About this block'}>
      <Label size='xs' className={styles.label}>
        Description
      </Label>
      <TextareaAutosize onChange={onChangeDescription} value={description} className={styles.textarea} minRows={3} />
    </Panel>
  );
};

export default About;
