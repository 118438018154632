import React, { ElementType, TextareaHTMLAttributes, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { useMergeRefs } from '~/hooks/use-merge-refs';

import { Label } from '~/components/_layout/typography/label';

import styles from './contenteditable-as-textarea.module.scss';
import { useContentEditable } from '~/components/contenteditable';
import { UseContentAllowedExternalProps } from '~/components/contenteditable/types';

type Props = {
  as?: ElementType;
} & UseContentAllowedExternalProps<HTMLTextAreaElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

export const ContentEditableAsTextarea = ({
  as: Component = Label,
  className,
  keyResetters = [],
  keySubmitters = [],
  ...props
}: Props): React.JSX.Element => {
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const {
    className: container,
    isEditing,
    ref,
    handleBlur,
    handleClick,
    handleKeyDown
  } = useContentEditable<HTMLTextAreaElement>({
    keyGuards: [
      {
        key: 'enter',
        serviceKey: 'shiftKey'
      }
    ],
    keyResetters,
    keySubmitters: [
      {
        key: 'enter',
        serviceKey: 'shiftKey'
      },
      ...keySubmitters
    ]
  });

  const { value, placeholder } = props;

  const mergedRef = useMergeRefs(ref, textAreaRef);

  useEffect(() => {
    if (isEditing) {
      textAreaRef.current?.select();
    }
  }, [isEditing]);

  return (
    <div className={classnames(container, styles.container, className)} onBlur={handleBlur}>
      {isEditing ? (
        <Component>
          <div className={styles.label}>
            <pre className={classnames(styles.value, styles.hidden)}>{value}</pre>
            <textarea
              ref={mergedRef}
              className={styles.input}
              autoCapitalize='off'
              autoComplete='off'
              autoCorrect='off'
              spellCheck='false'
              onKeyDown={handleKeyDown}
              {...props}
            />
          </div>
        </Component>
      ) : (
        <div role='textbox' tabIndex={0} className={styles.button} onClick={handleClick}>
          <Component>
            <pre
              className={classnames(styles.value, {
                [styles.empty]: !value && !placeholder,
                [styles.placeholder]: !value && placeholder
              })}
            >
              {value || placeholder}
            </pre>
          </Component>
        </div>
      )}
    </div>
  );
};
