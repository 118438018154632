import React, { createContext, ReactNode, useMemo, useReducer } from 'react';
import { State } from '~/templates/tags-interaction/context/store/types';
import TagsInteractionReducer from '~/templates/tags-interaction/context/store/reducer';
import { INTERACTION_MODES } from '~/templates/tags-interaction/context/constants';

export type ContextReturnType = {
  state: State;
  dispatch: React.Dispatch<any>;
};

const initialState = {
  tags: [],
  error: '',
  selectedTags: [],
  isLoading: false,
  newTagName: '',
  interactionMode: INTERACTION_MODES.ASSIGN,
  filter: ''
};

const TagsInteractionContext = createContext<ContextReturnType>({
  state: initialState,
  dispatch: () => void 0
});

const TagsInteractionProvider = ({ children }: { children: ReactNode }): React.JSX.Element => {
  const [state, dispatch] = useReducer(TagsInteractionReducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state]
  );

  return <TagsInteractionContext.Provider value={value}>{children}</TagsInteractionContext.Provider>;
};

export default TagsInteractionProvider;
export { TagsInteractionContext };
