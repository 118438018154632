import React, { memo, useCallback, useState } from 'react';
import classnames from 'classnames';

import { Value } from '~/components/_layout/typography/value';
import { sameWidth } from '~/helpers/ui-kit/popper/same-width';
import { maxHeight } from '~/helpers/ui-kit/popper/max-size';
import { Options } from '@popperjs/core';
import { offset } from '~/helpers/ui-kit/popper/offset';
import { TextNode } from '~/components/_layout/typography/text';
import { Icon } from '~/components/icon';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { usePopover } from '~/components/popover';
import { TagsTreeDescendants, TagsTreeItem, TagsTreeLayout } from '~/pages/tags/components/TagsTree';
import { Portal } from '~/components/portal';
import { FormLabel } from '~/components/_form/components/label';
import { Heading } from '~/components/_layout/typography/heading';
import { updateVirtualTag } from '~/pages/tags/context/store/thunks';

import styles from './styles.module.scss';
import { FlatTag } from '~/types/gists/tag';

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => {
      return [0, -reference.height];
    }
  }
};

const POPOVER: Partial<Options> = {
  placement: 'bottom',
  modifiers: [topOffset, sameWidth, ...maxHeight]
};

type Props = FlatTag;

const Parent = ({ parentId, id }: Props): React.JSX.Element => {
  const { state, dispatch } = useTagsModuleContext();
  const [expandedTags, setExpandedTags] = useState<number[]>([]);
  const { onPopoverRef, onTriggerRef, Popover, toggle, show } = usePopover(false, POPOVER);

  const parentTag = state.virtualTags.find(tag => tag.id === parentId);

  const closeSelect = useCallback(() => {
    toggle(!show);
    setExpandedTags([]);
  }, [show, toggle]);

  const onSelectParent = useCallback(
    (ids: number[]) => {
      const newParentId = isNaN(ids[0]) ? null : ids[0];
      dispatch(updateVirtualTag(id, { parentId: newParentId }));

      if (newParentId) {
        dispatch(
          updateVirtualTag(newParentId, {
            descendants: [...(state.virtualTags.find(tag => tag.id === newParentId) as FlatTag).descendants, id]
          })
        );
      }

      if (parentId) {
        dispatch(
          updateVirtualTag(parentId, {
            descendants: (state.virtualTags.find(tag => tag.id === parentId) as FlatTag).descendants.filter(
              descendantId => descendantId !== id
            )
          })
        );
      }
      closeSelect();
    },
    [closeSelect, dispatch, id, parentId, state.virtualTags]
  );
  const onExpandTag = useCallback(
    (id: number) => {
      const toggledTag = state.virtualTags.find(tag => tag.id === id) as FlatTag;
      const descendantsIds = toggledTag.descendants.map(
        descendantId => (state.virtualTags.find(({ id }) => id === descendantId) as FlatTag).id
      );

      const newExpandedTags = (
        expandedTags.includes(id) ? expandedTags.filter(id => id !== id) : expandedTags.concat(id)
      ).filter(expandedTagId => !descendantsIds.includes(expandedTagId));

      setExpandedTags(newExpandedTags);
    },
    [expandedTags, state.virtualTags]
  );

  const rootTags = state.virtualTags.filter(tag => tag.parentId === null && tag.id !== id);

  const isDescendantSelected = useCallback(tagId => tagId === parentId, [parentId]);
  const isDescendantExpanded = useCallback(tagId => expandedTags.includes(tagId), [expandedTags]);

  return (
    <>
      <FormLabel label='Parent' placement='top'>
        <Value ref={onTriggerRef}>
          <button className={styles.button} onClick={() => toggle(!show)} disabled={rootTags.length === 0}>
            <TextNode inherit truncated={true}>
              {parentTag?.name || 'None'}
            </TextNode>
            <Icon name={'mod-down'} className={styles.icon} />
          </button>
        </Value>
      </FormLabel>
      <Portal id='popover'>
        <div ref={onPopoverRef} className={classnames(styles['popover-container'])}>
          {show && (
            <Popover onClickOutside={closeSelect}>
              <>
                <Heading size='xs' className={styles.heading}>
                  Select a Parent
                </Heading>
                <TagsTreeLayout.RootWrapper>
                  <>
                    <TagsTreeLayout.RootItem key={'none'}>
                      <TagsTreeItem
                        tagId={NaN}
                        onSelect={onSelectParent}
                        onExpand={onExpandTag}
                        isSelected={!parentId}
                        isExpanded={false}
                      />
                    </TagsTreeLayout.RootItem>
                    {rootTags.map(tag => (
                      <TagsTreeLayout.RootItem key={tag.id}>
                        <TagsTreeItem
                          tagId={tag.id}
                          onSelect={onSelectParent}
                          onExpand={onExpandTag}
                          isSelected={tag.id === parentId}
                          isExpanded={expandedTags.includes(tag.id)}
                          selectedTagId={id}
                        >
                          <TagsTreeDescendants
                            onExpand={onExpandTag}
                            onSelect={onSelectParent}
                            parentId={tag.id}
                            isExpanded={isDescendantExpanded}
                            isSelected={isDescendantSelected}
                            selectedTagId={id}
                          />
                        </TagsTreeItem>
                      </TagsTreeLayout.RootItem>
                    ))}
                  </>
                </TagsTreeLayout.RootWrapper>
              </>
            </Popover>
          )}
        </div>
      </Portal>
    </>
  );
};

export default memo(Parent);
