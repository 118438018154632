import React from 'react';
import { LinkButton } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { useTheme } from '~/components/theme';

export const HomeButton = (): React.JSX.Element => {
  const { env, affiliate } = useTheme();
  return (
    <TooltipOnHover title='Home' delay={100}>
      <LinkButton preventQuery is='minor' to={`/?affiliate=${affiliate}&env=${env}`} icon='home' />
    </TooltipOnHover>
  );
};
