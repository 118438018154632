import { useCallback } from 'react';
import { ActionType, Cell, CellValue, Hooks, IdType, TableDataLike, TableInstance, TableState } from 'react-table';
// React Table public utils is a JSX file
// By the time when this comment was written the current tsconfig
// is not allowing to parse JSX files.
// import { actions } from 'react-table/~/publicUtils';

export type UseEditOptions = Partial<{
  disableEditing: boolean;
}>;

export type UseEditInstanceProps<Data extends TableDataLike> = Partial<{
  disableEditing: boolean;
  handleCellEdit: UseEditCallback<Data>;
}>;

export type UseEditColumnProps<Data extends TableDataLike> = {
  canEdit: boolean;
  editCell: (cell: Cell<Data>, value: any) => void;
  edit: (cell: Cell<Data>, value: any) => void;
  isEditing: (rowId: string) => boolean;
  setEditing: (colId: IdType<Data>, rowId: string, state: boolean) => void;
};

export type UseEditState<Data extends TableDataLike> = {
  editingColumns: Record<IdType<Data>, string[]>;
};

export type UseEditActions<Data extends TableDataLike> = {
  setEditing: { type: 'setEditing'; colId: IdType<Data>; rowId: string; isEditing: boolean };
};

export type UseEditColumnOptions = {
  disableEdit?: boolean;
  defaultIsEdit?: boolean;
};

export type UseEditCallback<Data extends TableDataLike> = {
  (value: CellValue<Data>, cell: Cell<Data>, instance: TableInstance<Data>): void | Promise<void>;
};

const ACTIONS = {
  // TODO: Find a way to export utils actions to preserve consistence
  // init: actions.init,
  init: 'init',
  setEditing: 'setEditing'
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { columns, state, disableEditing = false, handleCellEdit, dispatch, handleEdit } = instance;

  const setEditing = useCallback(
    (colId: IdType<Data>, rowId: string, state: boolean) => {
      dispatch({ type: ACTIONS.setEditing, colId, rowId, isEditing: state });
    },
    [dispatch]
  );

  const editCell = useCallback(
    (cell: Cell<Data>, value: CellValue<Data>): void => {
      if (!cell.column.canEdit) {
        return;
      }

      if (handleCellEdit) {
        handleCellEdit(value, cell, instance);
      } else {
        console.warn('[Table View] Table Editing plugin is enabled but "onEditCell" prop was not provided');
      }
    },
    [handleCellEdit, instance]
  );

  const edit = useCallback(
    (cell: Cell<Data>, value: CellValue<Data>): void => {
      if (handleEdit) {
        handleEdit(value, cell, instance);
      } else {
        console.warn('[Table View] Table Editing plugin is enabled but "onEdit" prop was not provided');
      }
    },
    [handleEdit, instance]
  );

  const { editingColumns } = state;

  columns.forEach(column => {
    const { id: colId, disableEdit = false } = column;

    Object.assign(column, {
      canEdit: !disableEditing && !disableEdit,
      editCell,
      edit,
      isEditing: (rowId: string) => editingColumns[colId]?.includes(rowId) ?? false,
      setEditing
    });
  });
};

const useReducer = <Data extends TableDataLike>(state: TableState<Data>, action: ActionType): TableState<Data> => {
  if (action.type === ACTIONS.init) {
    return {
      ...state,
      editingColumns: {} as Record<IdType<Data>, string[]>
    };
  }

  if (action.type === ACTIONS.setEditing) {
    const { colId, rowId, isEditing } = action as UseEditActions<Data>['setEditing'];

    const list = state.editingColumns[colId] ?? [];

    const nextState = isEditing ? [...list, rowId] : list.filter(id => id !== rowId);

    return {
      ...state,
      editingColumns: {
        ...state.editingColumns,
        [colId]: nextState
      }
    };
  }

  return state;
};

export const useEditing = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.stateReducers.push(useReducer);
  hooks.useInstance.push(useInstance);
};

useEditing.pluginName = 'useEditing';
