import { Column } from 'react-table';

import { PrimaryCell } from '~/components/_table/cells/primary';
import { Block } from '~/types/gists/Block';
import { BLOCKS_ROUTES } from '~/routes/private/blocks/constants';
import { formatDate } from '~/helpers/formatters';

export const columns: Column<Block>[] = [
  {
    title: 'Block name',
    Cell: PrimaryCell,
    accessor: 'name',
    to: BLOCKS_ROUTES.root,
    actionTooltip: 'Edit Block',
    primary: true,
    disableEdit: true
  },
  {
    title: 'Description',
    accessor: 'description',
    width: 516
  },
  {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableEdit: true,
    width: 160
  },
  {
    title: 'Updated by',
    accessor: 'updatedBy',
    disableEdit: true
  }
];
