import { ActionType, RULE, RULE_SET, SetState, State } from '~/pages/cohorts/edit/ConditionBuilder/context/reducer';
import { VIEW_MODES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Dataset } from '~/types/gists/conditions';

export const setConditionQuery = (conditionQuery: string): SetState => ({
  type: ActionType.SetConditionQuery,
  payload: { conditionQuery }
});

export const setViewMode = (viewMode: VIEW_MODES): SetState => ({
  type: ActionType.SetViewMode,
  payload: { viewMode }
});

export const updateRuleById = (ruleId: string, data: Partial<RULE>): SetState => ({
  type: ActionType.UpdateRuleById,
  payload: {
    id: ruleId,
    ...data
  }
});

export const updateRuleSetById = (ruleSetId: string, data: Partial<RULE_SET>): SetState => ({
  type: ActionType.UpdateRuleSetById,
  payload: {
    id: ruleSetId,
    ...data
  }
});

export const setDataSets = (payload: Dataset[]): SetState => ({
  type: ActionType.SetDataSets,
  payload
});

export const setIsLoading = (isLoading: boolean): SetState => ({
  type: ActionType.SetIsLoading,
  payload: isLoading
});

export const duplicateRuleById = (ruleId: string): SetState => ({
  type: ActionType.DuplicateRuleById,
  payload: {
    id: ruleId
  }
});

export const deleteRuleById = (ruleId: string, rulesetId: string): SetState => ({
  type: ActionType.DeleteRuleById,
  payload: {
    id: ruleId,
    rulesetId: rulesetId
  }
});

export const deleteRulesetById = (rulesetId: string): SetState => ({
  type: ActionType.DeleteRulesetById,
  payload: {
    id: rulesetId
  }
});

export const addRule = (rulesetId: string): SetState => ({
  type: ActionType.AddRule,
  payload: {
    rulesetId
  }
});

export const addRuleset = (): SetState => ({
  type: ActionType.AddRuleset,
  payload: {}
});

export const setRules = (rules: Record<string, RULE>): SetState => ({
  type: ActionType.SetRules,
  payload: {
    rules
  }
});

export const setRulesets = (rulesets: Record<string, RULE_SET>): SetState => ({
  type: ActionType.SetRulesets,
  payload: {
    rulesets
  }
});

export const setIsError = (isError: boolean): SetState => ({
  type: ActionType.SetIsError,
  payload: isError
});

export const update = (payload: Partial<State>): SetState => ({
  type: ActionType.Update,
  payload
});
