import React from 'react';

type Props = {
  className: string;
  viewBox: string;
};

export const Icon = ({ className, viewBox }: Props): React.JSX.Element => {
  return (
    <svg className={className} viewBox={viewBox} xmlns='http://www.w3.org/2000/svg'>
      <g data-background-filter>
        <path
          data-background
          d='M0 10.376C0 4.85313 4.47715 0.375977 10 0.375977C15.5228 0.375977 20 4.85313 20 10.376C20 15.8988 15.5228 20.376 10 20.376C4.47715 20.376 0 15.8988 0 10.376Z'
        />
        <g data-tick-filter>
          <circle data-tick cx='10' cy='10.376' r='4' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_light'
          x='0'
          y='0.375977'
          width='20'
          height='22'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow' />
        </filter>

        <filter
          id='filter1_dark'
          x='3'
          y='3.37598'
          width='14'
          height='14'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.239216 0 0 0 0 0.811765 0 0 0 0 0.207843 0 0 0 1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>

        <filter
          id='filter1_light'
          x='6'
          y='6.37598'
          width='9'
          height='10'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dx='1' dy='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>

        <linearGradient
          id='paint_radial_gradient_light'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(10 10) rotate(90) scale(10)'
        >
          <stop offset='1' stopColor='var(--app-color-env)' stopOpacity='100%' />
          <stop stopColor='var(--app-color-lighter-env)' stopOpacity='0%' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_dark'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#262626' stopOpacity='100%' />
          <stop stopColor='#3B3B3B' stopOpacity='0%' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_light_hover'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#E4E4E4' stopOpacity='100%' />
          <stop stopColor='#F8F8F8' stopOpacity='0%' />
        </linearGradient>

        <linearGradient
          id='paint_radial_gradient_dark_hover'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(180)'
        >
          <stop offset='1' stopColor='#333333' stopOpacity='100%' />
          <stop stopColor='#474747' stopOpacity='0%' />
        </linearGradient>
      </defs>
    </svg>
  );
};
