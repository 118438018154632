import { CellProps, Hooks, RemoveCallback, TableDataLike, TableInstance } from 'react-table';
import { useCallback } from 'react';

export type UseRemoveRowProps<Data extends TableDataLike> = {
  handleRemove: RemoveCallback<Data>;
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { handleRemove, rows } = instance;

  const remove = useCallback(
    async (cellProps: CellProps<Data>, setLoading, setReady) => {
      if (handleRemove) {
        await handleRemove(cellProps, setLoading, setReady);
      } else {
        console.warn('[Table View] Table Cell Removing plugin is enabled but "handleRemove" prop was not provided');
      }
    },
    [handleRemove]
  );

  rows.forEach(row => {
    Object.assign(row, {
      handleRemove: remove
    });
  });
};

export const useRemoving = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.useInstance.push(useInstance);
};

useRemoving.pluginName = 'useRemoving';
