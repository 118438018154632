import React from 'react';
import Structure from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/structure';
import PreviewControls from '~/pages/pages/edit/components/left-sidebar/components/page-panel/components/preview-controls';

import styles from './styles.module.scss';

const PagePanel = (): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <PreviewControls />
      <Structure />
    </div>
  );
};

export default PagePanel;
