import React from 'react';

import styles from './actions.module.scss';
import Download from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/Actions/components/Download';
import Replace from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/Actions/components/Replace';
import Delete from '~/components/_table/cells/file-primary/components/FileDetails/components/Sidebar/components/Actions/components/Delete';

const Actions = () => (
  <div className={styles.container}>
    <Download />
    <Replace />
    <Delete />
  </div>
);

export default Actions;
