import React, { useCallback, useMemo } from 'react';
import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

import { BUILDER_OPERATORS, OPERATORS_BY_TYPE } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Select, SelectItem } from '~/components/_inputs/select';
import { Heading } from '~/components/_layout/typography/heading';

import styles from '../styles.module.scss';

const mapToSelectItem = (operator: BUILDER_OPERATORS): SelectItem<BUILDER_OPERATORS> => ({
  value: operator,
  id: operator,
  label: operator
});

const mapToSelectFormat = (list: Array<BUILDER_OPERATORS>) => list.map(mapToSelectItem);

const OPERATOR_LABELS_MAP: Record<BUILDER_OPERATORS, string> = {
  [BUILDER_OPERATORS.EQ]: 'Is',
  [BUILDER_OPERATORS.EQNOT]: 'Is not',
  [BUILDER_OPERATORS.LT]: 'Is less than',
  [BUILDER_OPERATORS.EQLT]: 'Is less than or equal to',
  [BUILDER_OPERATORS.GT]: 'Is greater than',
  [BUILDER_OPERATORS.EQGT]: 'Is greater than or equal to'
};

const mapLabel = (item: SelectItem<BUILDER_OPERATORS>): React.JSX.Element => (
  <div className={styles['label-container']}>
    <span>{OPERATOR_LABELS_MAP[item.value]}</span>
    <span>{item.label}</span>
  </div>
);

type Props = { ruleId: string };

const RuleOperator = ({ ruleId }: Props): React.JSX.Element => {
  const { rules, setRuleOperatorById, setIsError } = useConditionBuilderContext();

  const { type, operator } = rules[ruleId];

  const operatorsList = useMemo<Array<BUILDER_OPERATORS>>(() => {
    if (type) {
      return OPERATORS_BY_TYPE[type] ?? [];
    }

    return [];
  }, [type]);

  const operatorValue = useMemo<SelectItem<BUILDER_OPERATORS> | undefined>(() => {
    if (operator) {
      if (type && !operatorsList.includes(operator)) {
        setIsError(true);
        return;
      }

      return mapToSelectItem(operator);
    }

    return void 0;
  }, [operator, operatorsList, setIsError, type]);

  const onChange = useCallback(
    () =>
      ({ value }) =>
        setRuleOperatorById(ruleId, value),
    [ruleId, setRuleOperatorById]
  );

  return (
    <Select<BUILDER_OPERATORS>
      name='select-rule-operator'
      items={mapToSelectFormat(operatorsList)}
      mapLabel={mapLabel}
      placeholder='='
      isInOverlay={true}
      headerElement={<Heading size='xs'>Select an operator</Heading>}
      closeButton
      value={operatorValue}
      onChange={onChange}
      multiLine
      containerClassName={styles['select-container']}
      className={styles['rule-operator']}
      disabled={!type}
    />
  );
};

export default RuleOperator;
