import {
  BUILDER_CONDITIONS,
  BUILDER_OPERATORS,
  BUILDER_TYPES,
  DEFAULT_VIEW_MODE,
  RULE_VALUE,
  VIEW_MODES
} from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { Dataset } from '~/types/gists/conditions';
import { v4 as uuid } from 'uuid';
import {
  generateDefaultRule,
  generateRule,
  generateRuleset
} from '~/pages/cohorts/edit/ConditionBuilder/context/helpers';

export enum ActionType {
  SetConditionQuery = 'SetConditionQuery',
  SetViewMode = 'SetViewMode',
  UpdateRuleById = 'UpdateRuleById',
  SetDataSets = 'SetDataSets',
  SetIsLoading = 'SetIsLoading',
  UpdateRuleSetById = 'UpdateRuleSetById',
  DuplicateRuleById = 'DuplicateRuleById',
  DeleteRuleById = 'DeleteRuleById',
  DeleteRulesetById = 'DeleteRulesetById',
  AddRule = 'AddRule',
  AddRuleset = 'AddRuleset',
  SetRules = 'SetRules',
  SetRulesets = 'SetRulesets',
  SetIsError = 'SetIsError',
  Update = 'Update'
}

export type RULE = {
  id: string;
  type: BUILDER_TYPES | null;
  condition: BUILDER_CONDITIONS;
  object: string;
  operator: BUILDER_OPERATORS;
  value: RULE_VALUE;
  computable?: boolean;
  options: Dataset['options'];
};

export type RULE_SET = {
  id: string;
  condition: BUILDER_CONDITIONS;
  ruleIds: Array<string>;
};

export type State = {
  conditionQuery: string;
  viewMode: VIEW_MODES;
  datasets: Array<Dataset>;
  ruleSets: Record<string, RULE_SET>;
  rules: {
    [key: string]: RULE;
  };
  isLoading: boolean;
  isError: boolean;
};

export type SetState = {
  type: ActionType;
  payload: any;
};

export const reducer = (state: State, action: SetState): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetConditionQuery: {
      return {
        ...state,
        conditionQuery: payload.conditionQuery ?? ''
      };
    }
    case ActionType.SetViewMode: {
      return {
        ...state,
        viewMode: payload.viewMode ?? DEFAULT_VIEW_MODE
      };
    }
    case ActionType.UpdateRuleById: {
      return {
        ...state,
        rules: {
          ...state.rules,
          [payload.id]: {
            ...state.rules[payload.id],
            ...payload
          }
        }
      };
    }
    case ActionType.SetDataSets: {
      return {
        ...state,
        datasets: payload
      };
    }
    case ActionType.SetIsLoading: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case ActionType.UpdateRuleSetById: {
      return {
        ...state,
        ruleSets: {
          ...state.ruleSets,
          [payload.id]: {
            ...state.ruleSets[payload.id],
            ...payload
          }
        }
      };
    }
    case ActionType.DuplicateRuleById: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const parentRuleSet = Object.keys(state.ruleSets)
        .map(ruleSetId => state.ruleSets[ruleSetId])
        .find(ruleSet => ruleSet.ruleIds.includes(payload.id))!;

      const newRuleId = uuid();
      const newRule: RULE = {
        ...state.rules[payload.id],
        id: newRuleId,
        condition:
          state.rules[payload.id].condition === BUILDER_CONDITIONS.DEFAULT
            ? BUILDER_CONDITIONS.OR
            : state.rules[payload.id].condition
      };

      return {
        ...state,
        rules: {
          ...state.rules,
          [newRuleId]: newRule
        },
        ruleSets: {
          ...state.ruleSets,
          [parentRuleSet.id]: {
            ...parentRuleSet,
            ruleIds: [
              ...parentRuleSet.ruleIds.slice(0, parentRuleSet.ruleIds.indexOf(payload.id)),
              payload.id,
              newRule.id,
              ...parentRuleSet.ruleIds.slice(parentRuleSet.ruleIds.indexOf(payload.id) + 1)
            ]
          }
        }
      };
    }
    case ActionType.DeleteRuleById: {
      const parentRuleSet = state.ruleSets[payload.rulesetId];

      return {
        ...state,
        ruleSets: {
          ...state.ruleSets,
          [parentRuleSet.id]: {
            ...parentRuleSet,
            ruleIds: parentRuleSet.ruleIds.filter(id => id !== payload.id)
          }
        },
        rules: Object.keys(state.rules)
          .filter(id => id !== payload.id)
          .reduce(
            (result, id) => ({
              ...result,
              [id]: state.rules[id]
            }),
            {}
          )
      };
    }
    case ActionType.DeleteRulesetById: {
      return {
        ...state,
        ruleSets: Object.keys(state.ruleSets)
          .filter(id => id !== payload.id)
          .reduce(
            (result, id) => ({
              ...result,
              [id]: state.ruleSets[id]
            }),
            {}
          ),
        rules: Object.keys(state.rules)
          .filter(id => !state.ruleSets[payload.id].ruleIds.includes(id))
          .reduce(
            (result, id) => ({
              ...result,
              [id]: state.rules[id]
            }),
            {}
          )
      };
    }
    case ActionType.AddRule: {
      const newRule = generateRule();

      return {
        ...state,
        ruleSets: {
          ...state.ruleSets,
          [payload.rulesetId]: {
            ...state.ruleSets[payload.rulesetId],
            ruleIds: [...state.ruleSets[payload.rulesetId].ruleIds, newRule.id]
          }
        },
        rules: {
          ...state.rules,
          [newRule.id]: newRule
        }
      };
    }
    case ActionType.AddRuleset: {
      const newRule = generateDefaultRule();
      const newRuleset = {
        ...generateRuleset(),
        ruleIds: [newRule.id]
      };

      return {
        ...state,
        ruleSets: {
          ...state.ruleSets,
          [newRuleset.id]: newRuleset
        },
        rules: {
          ...state.rules,
          [newRule.id]: newRule
        }
      };
    }
    case ActionType.SetRules: {
      return {
        ...state,
        rules: payload.rules
      };
    }
    case ActionType.SetRulesets: {
      return {
        ...state,
        ruleSets: payload.rulesets
      };
    }
    case ActionType.SetIsError: {
      return {
        ...state,
        isError: payload
      };
    }
    case ActionType.Update: {
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};
