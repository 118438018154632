import React from 'react';
import { isValidated } from '~/helpers/validations/is-password-valid';
import { TickboxField } from '~/components/_form/fields/tickbox';

import styles from './styles.module.scss';

export const PasswordValidation = ({ password }: { password: string }): React.JSX.Element => {
  const validators = isValidated(password).validators;

  return (
    <div className={styles.container}>
      {validators.map(({ text, value }) => (
        <TickboxField key={text} name={text} label={text} value={value} />
      ))}
    </div>
  );
};
