import { DiscardType, SetState, State } from '~/pages/tags/context/store/types';
import { ActionType } from '~/pages/tags/context/store/actions';
import { FlatTag } from '~/types/gists/tag';

export const initialState: State = {
  originalTags: [],
  virtualTags: [],
  isLoading: true,
  errorMessage: [],
  selectedTagsIds: [],
  expandedTagsIds: [],
  validationErrors: {},
  shouldValidate: false,
  discardType: DiscardType.None,
  hasUnsavedChanges: false,
  shouldShowDiscardChanges: false,
  nextTagsIdsToSelect: []
};

const TagsModuleReducer = (state: State, action: SetState): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetVirtualTags: {
      return {
        ...state,
        virtualTags: payload
      };
    }
    case ActionType.SetIsLoading: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case ActionType.SetErrorMessage: {
      return {
        ...state,
        errorMessage: payload
      };
    }
    case ActionType.SetSelectedTagsById: {
      return {
        ...state,
        selectedTagsIds: payload
      };
    }
    case ActionType.ToggleTagById: {
      const toggleTag = state.virtualTags.find(tag => tag.id === payload.id) as FlatTag;
      const descendantsIds = toggleTag.descendants.map(
        descendantId => (state.virtualTags.find(({ id }) => id === descendantId) as FlatTag).id
      );

      const expandedTags = (
        state.expandedTagsIds.includes(payload.id)
          ? state.expandedTagsIds.filter(id => id !== payload.id)
          : state.expandedTagsIds.concat(payload.id)
      ).filter(expandedTagId => !descendantsIds.includes(expandedTagId));

      return {
        ...state,
        expandedTagsIds: expandedTags
      };
    }
    case ActionType.SetExpandedTagsByIds: {
      return {
        ...state,
        expandedTagsIds: payload.ids
      };
    }
    case ActionType.CreateVirtualTag: {
      return {
        ...state,
        virtualTags: [...state.virtualTags, payload]
      };
    }
    case ActionType.UpdateVirtualTagById: {
      return {
        ...state,
        virtualTags: state.virtualTags.map(tag => {
          if (tag.id === payload.id) {
            return {
              ...tag,
              ...payload.data
            };
          }

          return tag;
        })
      };
    }
    case ActionType.UpdateValidationErrors: {
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          ...payload
        }
      };
    }
    case ActionType.SetShouldValidate: {
      return {
        ...state,
        shouldValidate: payload
      };
    }
    case ActionType.SaveTags: {
      return {
        ...state,
        originalTags: payload.tags,
        virtualTags: payload.tags
      };
    }
    case ActionType.ReorderTag: {
      const { draggableTagId, from, to } = action.payload;

      // Getting a virtual tag so that changes that have not been saved are not lost.
      const draggableVirtualTag = state.virtualTags.find(virtualTag => virtualTag.id === draggableTagId) as FlatTag;

      // Getting the tags list which have to be reordered from original list.
      const siblingsTags = state.originalTags.filter(tag => tag.parentId === draggableVirtualTag.parentId);

      // Reorder tags.
      const [removed] = siblingsTags.splice(from, 1);
      siblingsTags.splice(to, 0, removed);

      // Mapping siblings tags list with position according to new order.
      const siblingsWithMappedPosition = siblingsTags.map((res, index) => ({
        ...res,
        position: index + 1
      })) as FlatTag[];

      const sortByPosition = (first: FlatTag, second: FlatTag) => first.position - second.position;

      // Getting ordered list of original tags based on origin and mapped siblings.
      const orderedOriginalTags = state.originalTags
        .map(
          originalTag => siblingsWithMappedPosition.find(siblingTag => siblingTag.id === originalTag.id) ?? originalTag
        )
        .sort(sortByPosition);

      // Getting ordered list of virtual tags based on origin and mapped siblings.
      const orderedVirtualTags = state.virtualTags
        .map(
          virtualTag =>
            ({
              ...virtualTag,
              position: (siblingsWithMappedPosition.find(siblingTag => siblingTag.id === virtualTag.id) ?? virtualTag)
                .position
            } as FlatTag)
        )
        .sort(sortByPosition);

      return {
        ...state,
        virtualTags: orderedVirtualTags,
        originalTags: orderedOriginalTags
      };
    }
    case ActionType.Reset: {
      return initialState;
    }
    case ActionType.SetDiscardType: {
      return {
        ...state,
        discardType: payload
      };
    }
    case ActionType.SetShouldShowDiscardChanges: {
      return {
        ...state,
        shouldShowDiscardChanges: payload
      };
    }
    case ActionType.SetHasUnsavedChanges: {
      return {
        ...state,
        hasUnsavedChanges: payload
      };
    }
    case ActionType.SetNextTagsIdsToSelect: {
      return {
        ...state,
        nextTagsIdsToSelect: payload
      };
    }
    default: {
      return state;
    }
  }
};

export default TagsModuleReducer;
