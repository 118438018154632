import React from 'react';
import Url from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/components/url';
import AriaLabel from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/components/aria-label';
import Target from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/components/target';
import NoFollow from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/components/no-follow';

import styles from './styles.module.scss';

export type InteractionState = {
  url: string;
  ariaLabel: string;
  noFollow: boolean;
  target: '_self' | '_blank';
};

type Props = {
  onInteractionChange: (newInteractionState: InteractionState) => void;
  interactionState: InteractionState;
};

const Interaction = ({ onInteractionChange, interactionState }: Props): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <Url url={interactionState.url} onSaveUrl={newUrl => onInteractionChange({ ...interactionState, url: newUrl })} />
      {interactionState.url && (
        <>
          <AriaLabel
            ariaLabel={interactionState.ariaLabel}
            onSaveAriaLabel={newAriaLabel =>
              onInteractionChange({
                ...interactionState,
                ariaLabel: newAriaLabel
              })
            }
          />
          <Target
            target={interactionState.target}
            onChangeTarget={newTarget => onInteractionChange({ ...interactionState, target: newTarget })}
          />
          <NoFollow
            nofollow={interactionState.noFollow}
            onChangeNoFollow={newNoFollow =>
              onInteractionChange({
                ...interactionState,
                noFollow: newNoFollow
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default Interaction;
