import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CustomError } from '~/helpers/common/custom-error';

import useDocumentTitle from '~/hooks/use-document-title';

import { PasswordField } from '~/components/_form/fields/password';
import { ButtonField } from '~/components/_form/fields/button';
import { OnboardingTemplate } from '~/templates/onboarding';
import { onboarding } from '~/api/onboarding';
import { passwordSchema } from '~/helpers/validations/onboarding';
import { PasswordValidation } from '~/pages/onboarding/components/password-validatation';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const MESSAGE = 'Ok, now choose a password. Make sure it ticks all the security requirements below.';

const initialValues = {
  password: ''
};

export const SignUpPassword = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onSubmit = async ({ password }: { password: string }, { setFieldError }) => {
    try {
      const email = localStorage.getItem('email');

      if (email) {
        await onboarding.signUp({ email, password });

        localStorage.setItem('password', password);

        history.push(ONBOARDING_ROUTES.signUpConfirm);
      } else {
        history.push(ONBOARDING_ROUTES.signUpEmail);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      setFieldError('password', error.message);
    }
  };

  return (
    <OnboardingTemplate message={MESSAGE} backButton>
      <Formik validationSchema={passwordSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, dirty, isSubmitting, values: { password } }) => (
          <Form>
            <PasswordField name='password' label='Password' />
            <PasswordValidation password={password} />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};
