import { useEffect } from 'react';
import useTagsInteractionContext from '~/templates/tags-interaction/context/hooks/useTagsInteractionContext';
import { api } from '~/api';
import { disableLoading, enableLoading, setError, setTags } from '~/templates/tags-interaction/context/store/actions';

const useTagsFetch = (): void => {
  const { dispatch } = useTagsInteractionContext();
  useEffect(() => {
    const fetchTags = async () => {
      try {
        dispatch(enableLoading());
        const tags = await api.tags.getOriginalTagsTree();
        dispatch(setTags(tags));
      } catch (e) {
        if (typeof e === 'string') {
          dispatch(setError(e));
        }
      } finally {
        dispatch(disableLoading());
      }
    };

    fetchTags();
  }, [dispatch]);
};

export default useTagsFetch;
