import React from 'react';
import { Input } from '~/components/_inputs/input';
import { Select, SelectItem } from '~/components/_inputs/select';
import { ORDER_ITEMS } from '~/components/_layout/popovers/upload/constants';
import { OrderItem } from '~/components/_layout/popovers/upload/types';
import { Label } from '~/components/_layout/typography/label';

import styles from './styles.module.scss';

type Props = {
  filter: string;
  onChangeFilter: (name: string, value: string) => void;
  onChangeOrder: () => (option: SelectItem<OrderItem>) => void;
  selectedSorting: SelectItem<OrderItem>;
};

export const FileFilters = ({ filter, selectedSorting, onChangeFilter, onChangeOrder }: Props): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <div>
        <Label size='xs'>Filter</Label>
        <Input name='filter' placeholder='Enter file/tag name...' value={filter} onChange={onChangeFilter} />
      </div>
      <div>
        <Label size='xs'>Sort</Label>
        <Select<OrderItem>
          name='orderBy'
          items={ORDER_ITEMS}
          value={selectedSorting}
          onChange={onChangeOrder}
          className={styles.sortBy}
        />
      </div>
    </div>
  );
};
