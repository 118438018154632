import React, { useCallback } from 'react';
import type { Options } from '@popperjs/core';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { Popover, usePopover, UsePopover } from '~/components/popover';
import { AsyncButton, Button, useAsyncButton, UseAsyncButton } from '~/components/button';
import { Portal } from '~/components/portal';

import styles from './confirm-popover.module.scss';

export type RejectCallback = () => void;
export type ConfirmCallback = (setLoading: () => void, setReady: () => Promise<void>) => void | Promise<void>;

const POPPER: Partial<Options> = {
  placement: 'top',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom']
      }
    }
  ]
};

export type PopoverProps = Pick<UsePopover, 'show' | 'onPopoverRef' | 'onTriggerRef' | 'toggle'>;
export type ButtonProps = Pick<UseAsyncButton, 'loading' | 'ready' | 'setLoading' | 'setReady'>;

export type Props = OwnProps & PopoverProps & ButtonProps;

const ConfirmComponent = ({
  message,
  show,
  loading,
  ready,
  onPopoverRef,
  onTriggerRef,
  toggle,
  setLoading,
  setReady,
  onConfirm,
  onReject,
  cancelText,
  confirmText,
  children
}: Props) => {
  const handleClickOutside = useCallback(() => {
    toggle(false);
  }, [toggle]);

  const handleReject = () => {
    if (onReject) {
      onReject();
    }

    toggle(false);
  };

  const handlerConfirm = async () => {
    if (onConfirm) {
      await onConfirm(setLoading, setReady);
    }

    toggle(false);
  };

  return (
    <div className={styles.container}>
      <div ref={onTriggerRef}>{children(toggle, show)}</div>
      <Portal id='popover'>
        <div ref={onPopoverRef} className={styles['popover-container']}>
          {show && (
            <Popover className={styles.popover} onClickOutside={handleClickOutside}>
              <CoachMessage>{message}</CoachMessage>
              <div className={styles.actions}>
                {ready && !loading && (
                  <Button is='minor' className={styles.action} onClick={handleReject} fluid>
                    {cancelText || 'Cancel'}
                  </Button>
                )}
                <AsyncButton className={styles.action} onClick={handlerConfirm} loading={loading} ready={ready} fluid>
                  {confirmText || 'Ok'}
                </AsyncButton>
              </div>
            </Popover>
          )}
        </div>
      </Portal>
    </div>
  );
};

export type OwnProps = {
  message?: React.ReactNode;
  onConfirm?: ConfirmCallback;
  onReject?: RejectCallback;
  cancelText?: string;
  confirmText?: string;
  children: (toggle: PopoverProps['toggle'], show: boolean) => React.ReactNode;
};

export const Confirm = (props: OwnProps): React.JSX.Element => {
  const { Confirm, ...rest } = useConfirm();

  return <Confirm {...Object.assign({}, props, rest)} />;
};

export type UseConfirm = PopoverProps & ButtonProps & { Confirm: typeof ConfirmComponent };

export const useConfirm = (): UseConfirm => {
  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, POPPER);
  const { loading, ready, setLoading, setReady } = useAsyncButton();

  return {
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    loading,
    ready,
    setLoading,
    setReady,
    Confirm: ConfirmComponent
  };
};
