import { useCallback, Reducer, ReducerState, ReducerAction, useReducer, useMemo, useRef } from 'react';

export type StateGetter<A extends Reducer<any, any>> = () => ReducerState<A>;

export type DispatchThunk<A extends Reducer<any, any>, R = void> = (
  dispatch: DispatcherThunk<A, R>,
  state: StateGetter<A>
) => R;

export type DispatcherThunk<A extends Reducer<any, any>, R = void> = (
  action: ReducerAction<A> | DispatchThunk<A, R>
) => ReturnType<ReducerAction<A>> | R;

export type ActionOrThunk<A extends Reducer<any, any>, R = void> = ReducerAction<A> | DispatchThunk<A, R>;

function isDispatchThunk<R extends Reducer<any, any>>(
  action: ReducerAction<R> | DispatchThunk<R>
): action is DispatchThunk<R> {
  return typeof action === 'function';
}

/**
 * Augments React's useReducer() hook so that the action
 * dispatcher supports thunks.
 */
export function useThunkReducer<R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>
): [ReducerState<R>, DispatcherThunk<R, any>] {
  const [state, dispatch] = useReducer(reducer, initialState);

  const stateRef = useRef(state);
  stateRef.current = state;

  const dispatchThunk = useCallback(
    (action: ActionOrThunk<R>): void | ReturnType<typeof action> =>
      isDispatchThunk(action) ? action(dispatchThunk, () => stateRef.current) : dispatch(action),
    []
  );

  return useMemo(() => [state, dispatchThunk], [dispatchThunk, state]);
}
