import { Route } from '~/routes/types';
import { DATASETS_ROUTES } from '~/routes/private/datasets/constants';
import Datasets from '~/pages/datasets';
import Attributes from '~/pages/datasets/attributes';
import EditAttribute from '~/pages/datasets/attributes/edit';

export const datasets: Route[] = [
  {
    to: DATASETS_ROUTES.root,
    exact: true,
    component: Datasets
  },
  {
    to: DATASETS_ROUTES.attributes.table,
    exact: true,
    component: Attributes
  },
  {
    to: [DATASETS_ROUTES.attributes.create, DATASETS_ROUTES.attributes.edit],
    exact: true,
    component: EditAttribute
  }
];
