import { FilterValue, TableState } from 'react-table';
import {
  DeleteTableItemParams,
  FilterWithCustomQuery,
  GetTableItemsParams,
  SortBy,
  SortDirections,
  TableIdentifier
} from '../types';
import { ObjectShim } from '~/helpers/shims/object-shim';

export const mapSortBy = (sortBy: TableState['sortBy'] = []): SortBy => {
  const defaultValue: SortBy = {
    orderBy: void 0,
    orderDirection: void 0
  };

  return sortBy.reduce(({ orderBy, orderDirection }, { id, desc }) => {
    return {
      orderBy: orderBy ? `${orderBy},${id}` : id,
      orderDirection: orderDirection ? `${orderDirection},${id}` : desc ? SortDirections.DESC : SortDirections.ASC
    };
  }, defaultValue);
};

const isFilterWithCustomQuery = (filter: { id: string; value: FilterValue }): filter is FilterWithCustomQuery =>
  ObjectShim.isObject(filter?.value);

export const mapFilterBy = (filterBy: TableState['filters'] = [], identifier: string): string | undefined => {
  if (filterBy.length < 1) {
    return void 0;
  }

  return JSON.stringify(
    filterBy.reduce((map, filter) => {
      if (isFilterWithCustomQuery(filter)) {
        return {
          ...map,
          [`${filter.value.customQuery}`]: filter.value.value
        };
      }

      return {
        ...map,
        [`${identifier}.${filter.id}`]: filter.value
      };
    }, {})
  );
};

type BuildTableRequest = Partial<
  {
    limit: number;
  } & Pick<TableState, 'filters' | 'sortBy' | 'pageIndex' | 'pageSize'>
>;

export const DEFAULT_PAGE_SIZE = 50;

export const buildTableRequest = (
  identifier: TableIdentifier,
  { filters: filterBy, sortBy, pageIndex: page = 0, pageSize: limit = DEFAULT_PAGE_SIZE }: BuildTableRequest
): GetTableItemsParams => {
  const filters = mapFilterBy(filterBy, identifier);

  const { orderBy, orderDirection } = mapSortBy(sortBy);

  return { page, limit, filters, orderBy, orderDirection };
};

export const buildDeleteRequest = (ids: number | number[]): DeleteTableItemParams => {
  return {
    ids: ([] as number[]).concat(ids).toString()
  };
};
