import React, { useReducer } from 'react';
import { useUpload } from '~/components/_layout/popovers/upload';
import { HandleFileInteraction } from '~/components/_layout/popovers/upload/types';
import { Label } from '~/components/_layout/typography/label';
import { Button } from '~/components/button';
import { Image } from '~/components/image';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import { FileObject } from '~/types/gists/file';

import styles from './styles.module.scss';

export type ActiveFolder = {
  activeFolder: FOLDER_TYPES;
};

export type OuterUploaderHandlers = {
  setPageError: (error: string | null) => void;
  setPageLoading: (isLoading: boolean) => void;
  handleFileInteraction: HandleFileInteraction;
};

type Props = ActiveFolder &
  OuterUploaderHandlers & {
    inputId: string;
    file?: FileObject;
  };

export const FilePicker = React.forwardRef<HTMLDivElement, Props>(
  ({ inputId, activeFolder, handleFileInteraction, setPageLoading, setPageError, file }, ref): React.JSX.Element => {
    const { Upload, ...rest } = useUpload();

    const [hovered, toggleHovered] = useReducer(previous => !previous, false);

    return (
      <Upload
        message={<strong>Files / Images</strong>}
        handleFileInteraction={handleFileInteraction}
        setPageError={setPageError}
        setPageLoading={setPageLoading}
        activeFolder={activeFolder}
        isFileSelected={!!file}
        {...rest}
      >
        {(toggle, show) => (
          <div
            id={inputId}
            ref={ref}
            onClick={() => toggle(!show)}
            onMouseEnter={toggleHovered}
            onMouseLeave={toggleHovered}
            className={styles.container}
          >
            {file && !hovered ? (
              <Image src={file.s3ObjectUrl} />
            ) : (
              <Button is='minor' icon='files' className={styles.button} />
            )}
            <Label size='xs'>{file?.name ?? 'Add an image'}</Label>
          </div>
        )}
      </Upload>
    );
  }
);
