import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useDocumentTitle from '~/hooks/use-document-title';

import { OnboardingTemplate } from '~/templates/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { getMessageBasedOnADayPeriod } from './helpers';

const GREETING_MESSAGE = getMessageBasedOnADayPeriod();

export const Greeting = (): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push(ONBOARDING_ROUTES.signInEmail);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history]);

  return <OnboardingTemplate message={GREETING_MESSAGE} />;
};
