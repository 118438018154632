export enum VIEW_MODES {
  VISUAL_VIEW_MODE = 'visual',
  CODE_VIEW_MODE = 'code'
}

export const DEFAULT_VIEW_MODE = VIEW_MODES.VISUAL_VIEW_MODE;

export enum BUILDER_CONDITIONS {
  DEFAULT = '',
  OR = '||',
  AND = '&&'
}

export enum BUILDER_TYPES {
  INTEGER = 'int',
  FLOAT = 'float',
  TEXT = 'text',
  BOOL = 'bool',
  DATE = 'date',
  JSON = 'json'
}

export enum BUILDER_OPERATORS {
  EQ = '==',
  EQNOT = '!=',
  EQLT = '<=',
  LT = '<',
  EQGT = '>=',
  GT = '>'
}

export const OPERATORS_BY_TYPE = {
  [BUILDER_TYPES.INTEGER]: [
    BUILDER_OPERATORS.EQ,
    BUILDER_OPERATORS.EQNOT,
    BUILDER_OPERATORS.LT,
    BUILDER_OPERATORS.EQLT,
    BUILDER_OPERATORS.GT,
    BUILDER_OPERATORS.EQGT
  ],
  [BUILDER_TYPES.FLOAT]: [
    BUILDER_OPERATORS.EQ,
    BUILDER_OPERATORS.EQNOT,
    BUILDER_OPERATORS.LT,
    BUILDER_OPERATORS.EQLT,
    BUILDER_OPERATORS.GT,
    BUILDER_OPERATORS.EQGT
  ],
  [BUILDER_TYPES.TEXT]: [BUILDER_OPERATORS.EQ, BUILDER_OPERATORS.EQNOT],
  [BUILDER_TYPES.BOOL]: [BUILDER_OPERATORS.EQ, BUILDER_OPERATORS.EQNOT],
  [BUILDER_TYPES.DATE]: [
    BUILDER_OPERATORS.EQ,
    BUILDER_OPERATORS.EQNOT,
    BUILDER_OPERATORS.LT,
    BUILDER_OPERATORS.EQLT,
    BUILDER_OPERATORS.GT,
    BUILDER_OPERATORS.EQGT
  ]
};

export type RULE_VALUE = string | number | boolean | Date;
