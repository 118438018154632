import React from 'react';

import { SPACER_DEFAULT_VARIANT, SpacerVariants } from '@life-moments/lifehub-components';

import { getStructureItemLayers } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import { Block } from '~/types/gists/Block';

import Variant from '../../components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: SpacerVariants[];
  blockName: Block['name'];
};

const SpacerBlockVariants = ({ variants, blockName }: Props): React.JSX.Element => (
  <div className={styles.container}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        name={variant.displayName}
        isDefault={variant.name === SPACER_DEFAULT_VARIANT.name}
        content={getStructureItemLayers(blockName)}
      />
    ))}
  </div>
);

export default SpacerBlockVariants;
