import React, { ChangeEvent, useCallback, useState } from 'react';
import useTableInstanceContext from '~/components/_table/context/use-table-instance-context';
import { ObjectShim } from '~/helpers/shims/object-shim';
import ErrorPopover from '~/pages/files/components/files-table/components/add-files/components/uploader/components/ErrorPopover';
import UploadButton from '~/pages/files/components/files-table/components/add-files/components/uploader/components/UploadButton';
import {
  MAX_SIZE,
  MAX_SIZE_ERROR_MESSAGE,
  RESTRICTIONS_BY_FOLDER_TYPE
} from '~/pages/files/components/files-table/components/add-files/components/uploader/constants';
import { prepareFilesToUpload } from '~/pages/files/components/files-table/components/add-files/components/uploader/helpers';
import useUniquenessPopover from '~/pages/files/components/files-table/components/add-files/components/uploader/hooks/use-uniqueness-popover';
import { uploadFiles } from '~/pages/files/components/files-table/components/add-files/components/uploader/utils';
import useActiveFolderType from '~/pages/files/hooks/useActiveFolderType';

const Uploader = React.forwardRef<HTMLInputElement, {}>((_, ref) => {
  const activeFolder = useActiveFolderType();
  const instance = useTableInstanceContext();

  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const handleErrorPopoverClose = useCallback(() => setErrorMessage([]), []);

  const startTableLoading = useCallback(() => instance.toggleTableLoading(true), [instance]);
  const setTableError = useCallback(error => instance.setTableError(error), [instance]);
  const onUploadError = useCallback(() => {
    setErrorMessage(['Something went wrong with uploading.', 'Check your files and try again, please.']);
    setTableError('Something went wrong with uploading.');
  }, [setTableError]);
  const finishLoading = useCallback(async () => {
    await instance.refetchTable();
    instance.toggleTableLoading(false);
  }, [instance]);

  const { showPopover: showUniquenessPopover, Popover: UniquenessPopover } = useUniquenessPopover({
    onError: onUploadError,
    onUploadStart: startTableLoading,
    onUploadFinish: finishLoading
  });

  const handleFilesToUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (!files?.length) return;

      const filesArray = Array.from(files);

      const isMaxFileSizeExceed = filesArray.some(file => file.size > MAX_SIZE);
      if (isMaxFileSizeExceed) {
        setErrorMessage(MAX_SIZE_ERROR_MESSAGE);
        return;
      }

      setTableError(null);
      setErrorMessage([]);

      try {
        startTableLoading();

        const { uniquenessFilesMap, filesToUpload } = await prepareFilesToUpload({ filesArray, activeFolder });

        if (!ObjectShim.isEmpty(uniquenessFilesMap)) {
          showUniquenessPopover(filesToUpload);
        } else {
          await uploadFiles(filesToUpload, activeFolder, onUploadError);
          await instance.refetchTable();
        }
      } catch (error) {
        console.error(error);
        onUploadError();
      } finally {
        finishLoading();
      }
    },
    [setTableError, startTableLoading, activeFolder, showUniquenessPopover, onUploadError, instance, finishLoading]
  );

  return (
    <>
      <UploadButton accept={RESTRICTIONS_BY_FOLDER_TYPE[activeFolder]} onChange={handleFilesToUpload} ref={ref} />
      <ErrorPopover errorMessage={errorMessage} onClose={handleErrorPopoverClose} />
      <UniquenessPopover />
    </>
  );
});

Uploader.displayName = 'Uploader';

export default Uploader;
