import React, { createContext, ReactNode, Reducer } from 'react';

import TagsModuleReducer, { initialState } from '~/pages/tags/context/store/reducer';
import { State, SetState } from '~/pages/tags/context/store/types';
import { ActionOrThunk, useThunkReducer } from '~/hooks/use-thunk-reducer';

export type ContextReturnType = {
  state: State;
  dispatch: (action: ActionOrThunk<Reducer<State, SetState>>) => void;
};

const TagsModuleContext = createContext<ContextReturnType>({
  state: initialState,
  dispatch: () => null
});

const TagsModuleProvider = ({ children }: { children: ReactNode }): React.JSX.Element => {
  const [state, dispatch] = useThunkReducer(TagsModuleReducer, initialState);

  return (
    <TagsModuleContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </TagsModuleContext.Provider>
  );
};

export default TagsModuleProvider;
export { TagsModuleContext };
