import React from 'react';
import { Label } from '~/components/_layout/typography/label';
import Loader from '~/components/loader';

type Props = {
  message: string;
};

export const FilesLoader = ({ message }: Props): React.JSX.Element => {
  return (
    <Loader>
      <Label size='s'>{message}</Label>
    </Loader>
  );
};
