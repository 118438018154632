import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { BLOCKS_ROUTES } from '~/routes/private/blocks/constants';
import useDocumentTitle from '~/hooks/use-document-title';
import { withEditTemplate } from '~/templates/edit/with-edit-template';
import { Block } from '~/types/gists/Block';
import { EditTemplateState } from '~/templates/edit/use-editable-template';
import { PublishPanel } from '~/components/_layout/panels/publish';
import Preview from '~/pages/blocks/edit/components/preview/index';
import StructureSidebar from '~/pages/blocks/edit/components/structure-sidebar';
import { PublishAction } from '~/components/_layout/page-actions/publish-action';
import About from '~/pages/blocks/edit/components/about';

import styles from '~/pages/blocks/edit/styles.module.scss';

export const LOADING_MESSAGE = 'Loading your Block...';

const newCohort: Partial<Block> = {
  name: 'New Block',
  description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
};

const EditBlock = ({ item, isNew, change, edit, publish, add }: EditTemplateState<Block>): React.JSX.Element => {
  useDocumentTitle(`${item.name} - Blocks`);
  const history = useHistory();
  const { search } = useLocation();

  const handleChange = useCallback((name: keyof Block) => value => change(name, value), [change]);

  const handleSave = useCallback(
    async (isEnabled = false) => {
      const response = await add({
        ...item,
        isEnabled
      });

      if (response) {
        history.replace(`/${BLOCKS_ROUTES.root}/${response.id}${search}`);
      }
    },
    [add, history, item, search]
  );

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Blocks' to={BLOCKS_ROUTES.root} />
          <Breadcrumb.Item label={item.name} truncated />
        </Breadcrumb>
      </Slot>
      <Slot name='page-actions'>
        <PublishAction
          name={item.name}
          unsaved={isNew}
          published={item.isEnabled}
          onSave={handleSave}
          onUpdate={edit}
          onPublish={publish}
        />
      </Slot>
      <div className={styles.content}>
        <StructureSidebar block={item as Block} />
        <Preview block={item as Block} />
      </div>
      <Slot name='right-sidebar'>
        {item.updatedBy && item.updatedAt ? (
          <PublishPanel
            name={item.name}
            createdAt={item.createdAt}
            updatedAt={item.updatedAt}
            createdBy={item.createdBy}
            updatedBy={item.updatedBy}
            unsaved={isNew}
            published={item.isEnabled}
          />
        ) : null}
        <About description={item.description} onChange={handleChange} />
      </Slot>
    </>
  );
};

export default withEditTemplate({
  gist: 'blocks',
  defaultItem: newCohort,
  loadingMessage: LOADING_MESSAGE
})(EditBlock);
