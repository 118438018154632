import React, { Dispatch, SetStateAction } from 'react';
import { Textarea } from '~/components/_inputs/textarea';
import { useSidebarPanel } from '~/components/_layout/panels';
import { Switch } from '~/components/_inputs/switch';
import { FormLabel } from '~/components/_form/components/label';
import { calcRowsByLineLimit } from '~/helpers/styles';

import styles from './content-structure.module.scss';

const SYMBOLS_LIMIT_PER_ROW = 34;

type MessageStructure = {
  showTitle: boolean;
  showImage: boolean;
  showLink: boolean;
};

type Props = {
  messageStructure: MessageStructure;
  link: string | null;
  onStructureChange: Dispatch<SetStateAction<MessageStructure>>;
  onLinkChange: HandleChange;
};

export const ContentStructurePanel = React.memo(
  ({ messageStructure, link, onStructureChange, onLinkChange }: Props): React.JSX.Element => {
    const { Panel, List, ListItem } = useSidebarPanel();

    const { showTitle, showImage, showLink } = messageStructure;

    const handleChange: HandleChange = (name, value) => {
      onStructureChange({ ...messageStructure, [name]: value });
    };

    const textAreaRowsQuantity = link ? calcRowsByLineLimit(link, SYMBOLS_LIMIT_PER_ROW) : 1;

    return (
      <Panel title='Content structure'>
        <List>
          <ListItem withoutBorder>
            <FormLabel label='Title' placement='right'>
              <Switch name='showTitle' checked={showTitle} onChange={handleChange} id='show-title' />
            </FormLabel>
          </ListItem>
          <ListItem withoutBorder>
            <FormLabel label='Image' placement='right'>
              <Switch name='showImage' checked={showImage} onChange={handleChange} id='show-image' />
            </FormLabel>
          </ListItem>
          <ListItem withoutBorder>
            <FormLabel label='Link' placement='right'>
              <Switch name='showLink' checked={showLink} onChange={handleChange} id='show-link' />
            </FormLabel>
          </ListItem>
          {showLink && (
            <ListItem withoutBorder className={styles.item}>
              {link && (
                <a href={link} className={styles.link} target='_blank' rel='noreferrer'>
                  Test Link
                </a>
              )}
              <FormLabel label='Link navigates to URL'>
                <Textarea name='link' rows={textAreaRowsQuantity} value={link ?? ''} onChange={onLinkChange} />
              </FormLabel>
            </ListItem>
          )}
        </List>
      </Panel>
    );
  }
);
