import React, { memo } from 'react';

import RuleCondition from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/RuleCondition';
import RuleObject from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/RuleObject';
import RuleOperator from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/RuleOperator';
import RuleValue from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/RuleValue';
import DeleteRule from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/DeleteRule';
import DuplicateRule from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Rule/components/DuplicateRule';

import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';

import styles from './styles.module.scss';

type Props = {
  ruleId: string;
  rulesetId: string;
};

const Rule = ({ ruleId, rulesetId }: Props): React.JSX.Element => {
  const { isLastRuleInRuleset, isLastRuleset } = useConditionBuilderContext();

  return (
    <div className={styles.rule}>
      <div className={styles['rule-body']}>
        <RuleCondition ruleId={ruleId} />
        <RuleObject ruleId={ruleId} />
        <RuleOperator ruleId={ruleId} />
        <RuleValue ruleId={ruleId} />
      </div>
      <div className={styles['rule-actions']}>
        <DuplicateRule ruleId={ruleId} />
        {(!isLastRuleset() || !isLastRuleInRuleset(rulesetId)) && <DeleteRule ruleId={ruleId} rulesetId={rulesetId} />}
      </div>
    </div>
  );
};

export default memo(Rule);
