import React from 'react';
import classnames from 'classnames';

import { Label, Sizes } from '~/components/_layout/typography/label';
import { Icon } from '~/components/icon';

import styles from './infoText.module.scss';

type Props = {
  size?: Sizes;
  label: string;
};

const InfoText = React.memo(
  ({ size = 'xs', label }: Props): React.JSX.Element => (
    <div className={styles.container}>
      <Icon name='alert' className={classnames(styles.icon, styles[`icon-sizes-${size}`])} />
      <div className={styles.text}>
        <Label size={size} className={styles.label}>
          {label}
        </Label>
      </div>
    </div>
  )
);

InfoText.displayName = 'InfoText';

export default InfoText;
