import React from 'react';
import DatePickerBase from 'react-datepicker';
import type { Modifier } from '@popperjs/core';

import { Value } from '~/components/_layout/typography/value';

import 'react-datepicker/dist/react-datepicker.css';
import './overrides.scss';

import inputStyles from '../input/input.module.scss';
import classnames from 'classnames';

type Modifiers = Array<Partial<Modifier<any, any>>>;

export const defaultDateModifiers: Modifiers = [
  {
    name: 'offset',
    options: {
      offset: [4, 8]
    }
  }
];

type Props = {
  name: string;
  modifiers?: Modifiers;
  selected?: Date | string;
  className?: string;
  calendarClassName?: string;
  title?: string;
  dateFormat?: string;
  dateFormatCalendar?: string;
  onChange: HandleChange;
  minDate?: Date;
};

//TODO: Use lighter alternative to react-datepicker if there is time for that
export const DatePicker = React.memo((props: Props): React.JSX.Element => {
  const {
    modifiers = defaultDateModifiers,
    selected = new Date(),
    onChange,
    className,
    calendarClassName,
    dateFormat = 'dd/MM/yyyy',
    dateFormatCalendar,
    name,
    minDate
  } = props;

  const handleChange = (value: Date) => {
    onChange(name, value);
  };

  const value = new Date(selected);

  return (
    <Value>
      <DatePickerBase
        wrapperClassName={inputStyles['react-datepicker-wrapper']}
        onChange={handleChange}
        popperPlacement='left-start'
        popperModifiers={modifiers}
        selected={value}
        className={classnames(inputStyles.container, className)}
        calendarClassName={classnames('react-datepicker-overrides calendar', calendarClassName)}
        dateFormat={dateFormat}
        dateFormatCalendar={dateFormatCalendar}
        minDate={minDate}
      />
    </Value>
  );
});

export const TimePicker = React.memo((props: Props): React.JSX.Element => {
  const {
    modifiers = defaultDateModifiers,
    selected = new Date(),
    onChange,
    className,
    calendarClassName,
    title,
    name
  } = props;

  const handleChange = (value: Date) => {
    onChange(name, value);
  };

  const value = new Date(selected);

  return (
    <Value>
      <DatePickerBase
        wrapperClassName={inputStyles['react-datepicker-wrapper']}
        onChange={handleChange}
        popperPlacement='left-start'
        popperModifiers={modifiers}
        selected={value}
        className={classnames(inputStyles.container, className)}
        calendarClassName={classnames('react-datepicker-overrides time', calendarClassName)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption={title}
        dateFormat='h:mm aa'
      />
    </Value>
  );
});
