import React, { memo, useCallback } from 'react';
import { ContentEditableAsTextarea } from '~/components/contenteditable/textarea';
import styles from '~/pages/tags/components/Workspace/components/TagForm/styles.module.scss';
import { Label } from '~/components/_layout/typography/label';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { updateVirtualTag } from '~/pages/tags/context/store/thunks';
import { FlatTag } from '~/types/gists/tag';

const SubTitle = ({ children }: React.PropsWithChildren<void>) => <Label size='s'>{children}</Label>;

type Props = FlatTag;

const Description = ({ id, description }: Props): React.JSX.Element => {
  const { dispatch } = useTagsModuleContext();

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => dispatch(updateVirtualTag(id, { description: e.target.value })),
    [dispatch, id]
  );

  return (
    <ContentEditableAsTextarea
      as={SubTitle}
      className={styles.description}
      value={description ?? ''}
      onChange={onDescriptionChange}
      placeholder='Add a description...'
    />
  );
};

export default memo(Description);
