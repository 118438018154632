import { useCallback, useEffect, useState } from 'react';
import { api } from '~/api';
import { ActiveFolder, OuterUploaderHandlers } from '~/components/_layout/popovers/upload/components/inputs/picker';
import { DEFAULT_ITEMS_LIMIT, ERROR_MESSAGE } from '~/components/_layout/popovers/upload/constants';
import { stringifyFilters } from '~/components/_layout/popovers/upload/helpers';
import { GetTableItemsResponse } from '~/components/_table/types';
import { OrderItem } from '~/components/_layout/popovers/upload/types';
import { UsePopover } from '~/components/popover';
import { FileObject } from '~/types/gists/file';

type GetFilesResponse = GetTableItemsResponse<FileObject>;

type UseFetchFilesProps = ActiveFolder &
  Pick<OuterUploaderHandlers, 'setPageError'> & {
    show: UsePopover['show'];
    toggle: UsePopover['toggle'];
    filter: string;
    orderSettings: OrderItem;
  };

type UseFetchFilesReturn = GetFilesResponse & {
  loading: boolean;
  loadMore: () => Promise<void>;
};

export const useFetchFiles = ({
  show,
  toggle,
  filter,
  orderSettings,
  activeFolder,
  setPageError
}: UseFetchFilesProps): UseFetchFilesReturn => {
  const [loading, setLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<GetFilesResponse>({ count: 0, items: [] });

  const { field: orderBy, direction: orderDirection } = orderSettings;
  const fetchImages = useCallback(
    async (limit = DEFAULT_ITEMS_LIMIT) => {
      try {
        setLoading(true);
        const images: GetFilesResponse = await api[activeFolder].get({
          limit,
          orderDirection,
          orderBy,
          filters: stringifyFilters(filter)
        });
        setFiles(images);
      } catch (e) {
        console.error(e);
        setPageError(ERROR_MESSAGE);
        toggle(false);
      } finally {
        setLoading(false);
      }
    },
    [activeFolder, orderBy, orderDirection, filter, setPageError, toggle]
  );

  useEffect(() => {
    if (show) {
      (async () => await fetchImages())();
    }
  }, [fetchImages, show]);

  const loadMore = useCallback(async () => {
    return await fetchImages(files.items.length + DEFAULT_ITEMS_LIMIT);
  }, [fetchImages, files.items.length]);

  return { ...files, loading, loadMore };
};
