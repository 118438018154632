import { createTableApi } from '~/api/utils/create-table-api';
import { GetTableItemsParams, GetTableItemsResponse } from '~/components/_table/types';
import { get } from '../utils';
import { addFolderFilter } from '~/api/files/utils';

const getFonts = async <T>({ filters, ...params }: GetTableItemsParams): Promise<GetTableItemsResponse<T>> =>
  await get<GetTableItemsResponse<T>, GetTableItemsParams>('/files', {
    ...params,
    filters: addFolderFilter('fonts', filters)
  });

export const fonts = {
  ...createTableApi('/files'),
  get: getFonts
};
