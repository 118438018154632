import React, { useCallback, useMemo } from 'react';
import type { TableAction } from 'react-table';
import { useHistory } from 'react-router-dom';
import { CoachPopoverComponent } from '~/components/_layout/popovers/import-export/coach-popover';
import { useSlotController } from '~/components/_layout/popovers/import-export/use-coach-slot-controller';
import { CustomError } from '~/helpers/common/custom-error';
import useDocumentTitle from '~/hooks/use-document-title';
import { cohorts } from '~/api/cohorts';
import { WrapAction } from '~/components/_layout/page-actions/wrap-action';
import { useTableExport } from '~/components/_table/hooks/use-table-export';
import { buildExportParamsFromTable } from '~/components/_table/hooks/use-table-export/helpers';
import { useTableImport } from '~/components/_table/hooks/use-table-import';
import { TableApi } from '~/components/_table/types';
import { Slot } from '~/components/slots';
import { Button, LinkButton } from '~/components/button';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { DeleteAction } from '~/components/_layout/page-actions/delete-action';
import { ExportAction } from '~/components/_layout/page-actions/export-action';
import { ImportAction } from '~/components/_layout/page-actions/import-action';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import { Table } from '~/components/_table';
import { useTableFetch } from '~/components/_table/hooks/use-table-fetch';
import { TableStorageService } from '~/services/table-service';
import type { Cohort } from '~/types/gists/cohort';
import { calculatePageCount } from '~/components/_table/helpers/common';
import useSocket from '~/hooks/use-socket';
import { UserService } from '~/services/user-service';

import { columns } from './columns';
import { WELCOME_MESSAGE } from './messages';
import { useTheme } from '~/components/theme';
import { COHORTS_ROUTES } from '~/routes/private/cohorts/constants';
import { useSelector } from '~/store/hooks';

const gist: TableApi = 'cohorts';

const defaultSortBy = [
  {
    id: 'updatedAt',
    desc: true
  }
];

const WS_EVENTS = {
  ASSIGNMENT_STOP: 'cohortReassignmentStop',
  ASSIGNMENT_START: 'cohortReassignmentStart'
};

type wsEventMessageType = { cohortId: string; affiliate: AffiliateKey };
type wsEventType = { type: string; message: wsEventMessageType };

export const Cohorts = (): React.JSX.Element => {
  useDocumentTitle('Cohorts');

  const { push } = useHistory();
  const { affiliate, env } = useTheme();

  const {
    get,
    editCell,
    remove,
    removeSelected,
    publish,
    autoResetProps,
    items,
    isEmpty,
    count,
    setError,
    clear,
    setIsProcessingById
  } = useTableFetch<Cohort>({
    gist
  });

  const stopRefresh = useCallback<TableAction<Cohort>['handler']>(
    async (instance, setLoading, setReady) => {
      const { cell } = instance;

      setLoading();

      await editCell(
        {
          isProcessing: false,
          size: 0,
          sizeWithPushEnabled: 0
        } as Cohort,
        cell,
        instance
      );

      setReady();
    },
    [editCell]
  );

  const tableIdentifiers = useMemo(() => ({ affiliate, env, gist }), [affiliate, env]);

  const handleRefresh = useCallback<TableAction<Cohort>['handler']>(
    async ({ row: { original } }) => {
      try {
        clear();
        setIsProcessingById({ id: original.id, isProcessing: true });

        await cohorts.refreshCohort(original.id);
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        setError(error);
      }
    },
    [clear, setError, setIsProcessingById]
  );

  const handleSocketMessage = useCallback(
    (event: MessageEvent): void => {
      const { type, message }: wsEventType = JSON.parse(event.data);

      const setCohortAssignmentProcessing = (
        { cohortId, affiliate }: wsEventMessageType,
        isProcessing: boolean
      ): void => {
        if (UserService.checkAffiliate(affiliate)) {
          setIsProcessingById({ id: +cohortId, isProcessing });
        }
      };

      switch (type) {
        case WS_EVENTS.ASSIGNMENT_STOP: {
          setCohortAssignmentProcessing(message, false);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          get(TableStorageService.getTableStorageState(tableIdentifiers));
          break;
        }
        case WS_EVENTS.ASSIGNMENT_START: {
          setCohortAssignmentProcessing(message, true);

          break;
        }
      }
    },
    [get, setIsProcessingById, tableIdentifiers]
  );

  useSocket(handleSocketMessage);

  const goToCohort = useCallback<TableAction<Cohort>['handler']>(
    ({ row: { original } }) => push(`${COHORTS_ROUTES.root}/${original.id}?affiliate=${affiliate}&env=${env}`),
    [affiliate, env, push]
  );

  const actions = useMemo<TableAction<Cohort>[]>(() => {
    return [
      {
        id: 'edit',
        icon: 'edit',
        handler: goToCohort,
        tooltip: 'Edit Cohort'
      },
      {
        id: 'refresh',
        icon: 'refresh',
        handler: handleRefresh,
        loading: ({ row }) => row.original?.isProcessing as boolean,
        tooltip: 'Update Cohort'
      },
      {
        id: 'stop',
        icon: 'cross',
        confirm: { origin: 'name' },
        disabled: ({ row }) => !row.original?.isProcessing as boolean,
        handler: stopRefresh,
        message: 'Are you sure you want to stop assignment of',
        tooltip: 'Stop updating cohort'
      },
      {
        id: 'delete',
        icon: 'bin',
        confirm: { origin: 'name' },
        handler: remove,
        tooltip: 'Delete'
      }
    ];
  }, [goToCohort, handleRefresh, remove, stopRefresh]);

  const publishColumn = useMemo<TableAction<Cohort>>(() => {
    return {
      id: 'isEnabled',
      title: 'Publish',
      handler: publish,
      with: 120
    };
  }, [publish]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  const { CoachPopover: WelcomePopover, ...welcomeProps } = useCoachPopover({
    disabled: isMainMenuOpened || !isEmpty || TableStorageService.isEmptyFilters({ affiliate, env, gist })
  });

  const pageCount = useMemo(() => calculatePageCount(count), [count]);

  const {
    Popover: ExportPopover,
    onOpenExport,
    onSubmitExport,
    ...exportProps
  } = useTableExport<Cohort>({
    gist,
    message: 'Related Push Notifications are included in this export.'
  });
  const { Popover: ImportPopover, onOpenImport, onImportSubmit, ...importProps } = useTableImport<Cohort>({ gist });

  const {
    onOpenNewCoachContent,
    onCloseCoachContent,
    CoachPopover: CoachImportPopover,
    ...coachImportProps
  } = useSlotController({ unsubscribePrevious: welcomeProps.unsubscribe, unsubscribeCurrent: importProps.toggle });

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Cohorts' />
        </Breadcrumb>
      </Slot>
      <Table<Cohort>
        key={`${affiliate}_${env}`}
        gist={gist}
        columns={columns}
        data={items}
        actions={actions}
        publishColumn={publishColumn}
        onEditCell={editCell}
        onPublish={publish}
        onUpdate={get}
        manualSortBy
        defaultSortBy={defaultSortBy}
        manualFilters
        manualPagination
        actionsColumnWidth={134}
        pageCount={pageCount}
        preventGet={isMainMenuOpened}
        count={count}
        {...autoResetProps}
      >
        {instance => {
          const refetch = async () => await instance.refetchTable();
          const openExportOptions = buildExportParamsFromTable(instance);
          const tooltipText = openExportOptions.isExportAll ? 'Export all' : 'Export selected';

          return (
            <>
              <Slot name='page-actions'>
                <WrapAction gist={gist} onChange={instance.setWrappingMode} />
                <ImportAction toggleImportPopover={onOpenImport} />
                <ImportPopover onSubmit={onImportSubmit} afterSubmit={refetch} {...importProps} />
                <ExportAction toggleExportPopover={onOpenExport(openExportOptions)} tooltipText={tooltipText} />
                <ExportPopover onSubmit={onSubmitExport} {...exportProps} />
                <DeleteAction items={instance.selectedFlatRows} onConfirm={removeSelected(instance)} gist={gist} />
                <LinkButton to='/cohorts/new' icon='plus' fluid>
                  New Cohort
                </LinkButton>
              </Slot>
              <CoachImportPopover {...coachImportProps}>
                <CoachPopoverComponent
                  {...importProps}
                  {...coachImportProps}
                  toggle={onCloseCoachContent}
                  onSubmit={onImportSubmit}
                  afterSubmit={refetch}
                />
              </CoachImportPopover>
            </>
          );
        }}
      </Table>
      <WelcomePopover message={WELCOME_MESSAGE} {...welcomeProps}>
        <LinkButton to='/cohorts/new' icon='plus' fluid>
          Add new Cohort
        </LinkButton>
        <Button onClick={onOpenNewCoachContent} is='minor' icon='import' fluid>
          Import Cohorts
        </Button>
      </WelcomePopover>
    </>
  );
};
