import React, { useCallback, useMemo } from 'react';
import { CellProps, TableDataLike } from 'react-table';

import classnames from 'classnames';

import styles from './styles.module.scss';
import { Select, SelectItem } from '~/components/_inputs/select';
import { useTheme } from '~/components/theme';
import { Heading } from '~/components/_layout/typography/heading';

type Props = {
  selectItems: Array<SelectItem<string>>;
  headingLabel?: string;
};

export const SelectCell = <Data extends TableDataLike>({
  cell,
  row,
  column,
  selectItems,
  headingLabel
}: CellProps<Data> & Props): React.JSX.Element => {
  const value = row.values[column.id];
  const { editCell } = column;

  const { theme } = useTheme();

  const onEdit = useCallback(
    () =>
      ({ value: newValue }) =>
        editCell(cell, newValue),
    [cell, editCell]
  );

  const selectValue = useMemo(
    () => value && selectItems.find(selectItem => selectItem.value === value),
    [selectItems, value]
  );

  return (
    <div className={classnames(styles.container, styles[theme])}>
      <Select
        name={`select-cell-${row.id}`}
        items={selectItems}
        value={selectValue}
        containerClassName={styles.select}
        className={classnames(styles.value, styles[theme])}
        onChange={onEdit}
        headerElement={
          headingLabel ? (
            <Heading size='xs' className={styles.heading}>
              {headingLabel}
            </Heading>
          ) : undefined
        }
      />
    </div>
  );
};
