import { useCallback, useMemo, useState } from 'react';
import { api } from '~/api';
import { CoachMessage } from '~/components/_layout/coach/coach-popover';
import {
  DEFAULT_POPPER_OPTIONS,
  ERROR_RESPONSE_MESSAGE,
  INITIAL_RESPONSE_MESSAGE,
  OPERATION_TYPES
} from '~/components/_layout/popovers/import-export/constants';
import { PageActionsPopoverComponent as Popover } from '~/components/_layout/popovers/import-export/page-actions-popover';
import { OperationOptions } from '~/components/_layout/popovers/import-export/types';
import { mapFilterBy } from '~/components/_table/helpers/build-table-request';
import { gistToIdentifier } from '~/components/_table/helpers/gist-to-identifier';
import { DEFAULT_EXPORT_RESULT, Extend } from '~/components/_table/hooks/use-table-fetch';
import { useAsyncButton } from '~/components/button';
import { usePopover } from '~/components/popover';
import { copyToClipboard } from '~/helpers/formatters';
import {
  OnOpenExportParams,
  UseTableExportParams,
  UseTableExportReturnType
} from '~/components/_table/hooks/use-table-export/types';

const defaultOperationOptions: OperationOptions = {
  editable: false,
  title: 'Export',
  submitText: 'Copy to clipboard & close popover',
  successTitle: 'Successful Export!',
  operationType: OPERATION_TYPES.EXPORT
};

export const useTableExport = <T extends Extend>({
  message,
  gist
}: UseTableExportParams): UseTableExportReturnType<T> => {
  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, DEFAULT_POPPER_OPTIONS);
  const { loading, ready, setLoading, setReady } = useAsyncButton();
  const [hasError, setHasError] = useState<boolean>(false);

  const [defaultCode, setDefaultCode] = useState<string>('');
  const operationOptions: OperationOptions = useMemo(
    () => ({ ...defaultOperationOptions, defaultCode, message }),
    [defaultCode, message]
  );

  const buildExportFilters = useCallback(
    ({ filtersBy, selectedFlatRows, isExportAll }: OnOpenExportParams<T>) => {
      const selectedIds = selectedFlatRows.map(it => it.original.id);

      // it needs to make a copy to do not mutate table instance state of filters
      const filters = [...filtersBy];

      if (!isExportAll) {
        filters.push({ id: 'id', value: selectedIds });
      }

      return mapFilterBy(filters, gistToIdentifier[gist]);
    },
    [gist]
  );

  const onOpenExport = useCallback(
    (params: OnOpenExportParams<T>) => async (): Promise<CoachMessage> => {
      // TODO: Fix this hack. 🤯🤯🤯
      setTimeout(() => {
        setLoading();
        toggle(true);
      }, 0);

      const filters = buildExportFilters(params);

      try {
        const response = await api[gist].exportItems<T>({ filters });
        const code = JSON.stringify(response) || DEFAULT_EXPORT_RESULT;
        setDefaultCode(code);
        setHasError(false);
        return INITIAL_RESPONSE_MESSAGE;
      } catch (e) {
        setHasError(true);
        return ERROR_RESPONSE_MESSAGE;
      } finally {
        await setReady();
      }
    },
    [buildExportFilters, gist, setLoading, setReady, toggle]
  );

  const onSubmitExport = useCallback(async (value: string) => {
    await copyToClipboard(value);
    setDefaultCode('');
    return 'Code copied to clipboard!';
  }, []);

  return {
    onOpenExport,
    onSubmitExport,
    operationOptions,
    show,
    onPopoverRef,
    onTriggerRef,
    toggle,
    loading,
    ready,
    setLoading,
    setReady,
    Popover,
    hasError,
    setDefaultCode
  };
};
