import { CustomError } from '~/helpers/common/custom-error';
import { withDefaultProps } from '~/hocs/with-default-props';
import { ConfirmBase, ConfirmProps } from '~/pages/onboarding/general/confirm';
import { onboarding } from '~/api/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';
import { MFA_CHALLENGE_TO_ROUTE } from '~/pages/onboarding/mfa/constants';

const returnBackPath = ONBOARDING_ROUTES.signUpEmail;

const sendNewCodeMethod = 'resendCode';

const onSubmit =
  history =>
  async ({ code }: { code: string }, { setFieldError }) => {
    try {
      const email = localStorage.getItem('email');

      if (email) {
        const response = await onboarding.confirmSignUp({ email, code });
        const nextChallenge = response.nextChallenge;

        if (nextChallenge) {
          const nextMfaRoute = MFA_CHALLENGE_TO_ROUTE[nextChallenge];

          history.push(nextMfaRoute);
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('password');

          history.push(ONBOARDING_ROUTES.signInEmail);
        }
      } else {
        history.push(returnBackPath);
      }
    } catch (unknownError) {
      const error = new CustomError(unknownError);
      setFieldError('code', error.message);
    }
  };

export const SignUpConfirm = withDefaultProps<ConfirmProps>({ onSubmit, returnBackPath, sendNewCodeMethod })(
  ConfirmBase
);
