import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';
import { OwnProps as TextProps, TextNode } from '../text';

import styles from './paragraph.module.scss';

type Sizes = 'l' | 'm' | 's';

export type Props = {
  size?: Sizes;
} & TextProps;

export const ParagraphComponent = (
  { size = 'm', children, className, ...props }: Props,
  ref?: Ref<HTMLParagraphElement>
): React.JSX.Element => {
  return (
    <TextNode as='p' ref={ref} className={classnames(styles.container, styles[`size-${size}`], className)} {...props}>
      {children}
    </TextNode>
  );
};

export const Paragraph = forwardRef<HTMLParagraphElement, Props>(ParagraphComponent);
