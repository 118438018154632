import React, { useMemo } from 'react';
import classnames from 'classnames';

import {
  BlockVariant,
  COMPONENT_PROPS_BY_TYPE,
  COMPONENT_TYPES,
  ComponentsMap,
  DefaultBlockVariants
} from '@life-moments/lifehub-components';

import styles from './styles.module.scss';

type Props = {
  variant: BlockVariant;
  type: COMPONENT_TYPES;
  content?: string;
  additionalProps?: Record<string, unknown>;
};

const Variant = ({ variant, type, content, additionalProps }: Props): React.JSX.Element => {
  const Block = ComponentsMap[type];
  const isDefault = DefaultBlockVariants[type].name === variant.name;

  const props = useMemo(
    () => ({ content, variant, additionalProps }),
    [variant, content, additionalProps]
  ) as COMPONENT_PROPS_BY_TYPE[typeof type];

  return (
    <div className={styles.variant}>
      <span className={styles.name}>{isDefault ? `${variant.displayName} - Default` : variant.displayName}</span>
      <div className={classnames(styles.block, { [styles['image']]: type === COMPONENT_TYPES.image })}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {Block && <Block {...props} />}
      </div>
    </div>
  );
};

export default Variant;
