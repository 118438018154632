import { Blocks, Containers, Structure } from '~/pages/pages/edit/context/store/types';
import { Page, PageMetaData, StructureFromAPI } from '~/types/gists/page';

export const mapFEBlocksToBEStructure = (
  structure: Structure,
  blocks: Blocks,
  containers: Containers
): StructureFromAPI =>
  (Object.keys(structure) as Array<keyof typeof structure>).reduce<StructureFromAPI>((result, containerKey) => {
    const blocksOrder = structure[containerKey];
    const container = containers[containerKey];

    return [
      ...result,
      {
        ...container,
        children: blocksOrder.map(blockId => blocks[blockId])
      }
    ];
  }, []);

export const mapPageFromAPI = (
  pageData: Page
): {
  structure: Structure;
  blocks: Blocks;
  containers: Containers;
  readTimeMinutes: PageMetaData['readTimeMinutes'];
} => {
  const { readTimeMinutes } = pageData.metadata;
  const { structure: structureFromAPI } = pageData.data;

  const containers: Containers = {};
  const blocks: Blocks = {};
  const structure: Structure = {};

  structureFromAPI.forEach(block => {
    const containerBlock = block;

    structure[containerBlock.id] = containerBlock.children.map(childBlock => {
      blocks[childBlock.id] = childBlock;

      return childBlock.id;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...containerWithoutChildren } = containerBlock;

    containers[block.id] = { ...containerWithoutChildren };
  });

  return {
    containers,
    structure,
    blocks,
    readTimeMinutes
  };
};
