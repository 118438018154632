import { Column } from 'react-table';
import { FileObject } from '~/types/gists/file';
import TagsCell from '~/components/_table/cells/tags';
import { TagsFilter } from '~/components/_table/filters/tags';
import { formatDate } from '~/helpers/formatters';
import { FOLDER_TYPES } from '~/routes/private/files/constants';
import FilePrimaryCell from '~/components/_table/cells/file-primary';

enum ColumnTypes {
  FILENAME = 'FILENAME',
  DESCRIPTION = 'DESCRIPTION',
  ALT_TEXT = 'ALT_TEXT',
  TAGS = 'TAGS',
  FILE_TYPE = 'FILE_TYPE',
  DIMENSIONS = 'DIMENSIONS',
  FILE_SIZE = 'FILE_SIZE',
  FULL_URL = 'FULL_URL',
  LOCAL_URL = 'LOCAL_URL',
  DATA_CREATED = 'DATA_CREATED',
  CREATED_BY = 'CREATED_BY',
  LAST_MODIFIED = 'LAST_UPDATED',
  MODIFIED_BY = 'UPDATED_BY'
}

const COLUMN_TYPES: Record<ColumnTypes, Column<FileObject>> = {
  FILENAME: {
    title: 'Filename',
    accessor: 'name',
    disableEdit: true,
    Cell: FilePrimaryCell,
    minWidth: 160
  },
  DESCRIPTION: {
    title: 'Description',
    id: 'description',
    accessor: 'description'
  },
  ALT_TEXT: {
    title: 'Alt text',
    id: 'altText',
    accessor: 'altText'
  },
  TAGS: {
    title: 'Tags',
    accessor: 'tags',
    Cell: TagsCell,
    disableSortBy: true,
    Filter: TagsFilter
  },
  FILE_TYPE: {
    title: 'File type',
    id: 'type',
    disableEdit: true,
    accessor: 'type'
  },
  DIMENSIONS: {
    title: 'Dimensions(px)',
    id: 'dimensions',
    disableEdit: true,
    accessor: 'dimensions',
    disableSortBy: true
  },
  FILE_SIZE: {
    title: 'File size',
    id: 'size',
    disableEdit: true,
    accessor: 'size',
    disableFilters: true
  },
  FULL_URL: {
    title: 'Full url',
    id: 'fullUrl',
    disableEdit: true,
    accessor: 'fullUrl',
    disableFilters: true,
    disableSortBy: true
  },
  LOCAL_URL: {
    title: 'Local url',
    id: 'key',
    disableEdit: true,
    accessor: 'key'
  },
  DATA_CREATED: { title: 'Data created', accessor: 'createdAt', format: formatDate, disableEdit: true },
  CREATED_BY: {
    title: 'Created by',
    accessor: 'createdBy',
    disableEdit: true
  },
  LAST_UPDATED: {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableEdit: true
  },
  UPDATED_BY: {
    title: 'Updated by',
    accessor: 'updatedBy',
    disableEdit: true
  }
};

export const IMAGES_COLUMNS: Column<FileObject>[] = [
  COLUMN_TYPES.FILENAME,
  COLUMN_TYPES.DESCRIPTION,
  COLUMN_TYPES.ALT_TEXT,
  COLUMN_TYPES.TAGS,
  COLUMN_TYPES.FILE_TYPE,
  COLUMN_TYPES.DIMENSIONS,
  COLUMN_TYPES.FILE_SIZE,
  COLUMN_TYPES.FULL_URL,
  COLUMN_TYPES.LOCAL_URL,
  COLUMN_TYPES.DATA_CREATED,
  COLUMN_TYPES.CREATED_BY,
  COLUMN_TYPES.LAST_UPDATED,
  COLUMN_TYPES.UPDATED_BY
];

export const ICONS_COLUMNS: Column<FileObject>[] = [
  COLUMN_TYPES.FILENAME,
  COLUMN_TYPES.DESCRIPTION,
  COLUMN_TYPES.ALT_TEXT,
  COLUMN_TYPES.TAGS,
  COLUMN_TYPES.FILE_TYPE,
  COLUMN_TYPES.DIMENSIONS,
  COLUMN_TYPES.FILE_SIZE,
  COLUMN_TYPES.FULL_URL,
  COLUMN_TYPES.LOCAL_URL,
  COLUMN_TYPES.DATA_CREATED,
  COLUMN_TYPES.CREATED_BY,
  COLUMN_TYPES.LAST_UPDATED,
  COLUMN_TYPES.UPDATED_BY
];

export const FONTS_COLUMNS: Column<FileObject>[] = [
  COLUMN_TYPES.FILENAME,
  COLUMN_TYPES.DESCRIPTION,
  COLUMN_TYPES.TAGS,
  COLUMN_TYPES.FILE_TYPE,
  COLUMN_TYPES.FILE_SIZE,
  COLUMN_TYPES.FULL_URL,
  COLUMN_TYPES.LOCAL_URL,
  COLUMN_TYPES.DATA_CREATED,
  COLUMN_TYPES.CREATED_BY,
  COLUMN_TYPES.LAST_UPDATED,
  COLUMN_TYPES.UPDATED_BY
];

export const COLUMNS_BY_FOLDER_TYPE: Record<FOLDER_TYPES, Column<FileObject>[]> = {
  [FOLDER_TYPES.IMAGES]: IMAGES_COLUMNS,
  [FOLDER_TYPES.ICONS]: ICONS_COLUMNS,
  [FOLDER_TYPES.FONTS]: FONTS_COLUMNS
};
