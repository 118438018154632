import React, { useCallback, useEffect, useMemo } from 'react';
import { BlockVariant, DefaultBlockVariants } from '@life-moments/lifehub-components';

import { Select, SelectCallback } from '~/components/_inputs/select';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getBlockById, getSelectedLayerId } from '~/pages/pages/edit/context/store/selectors';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { setBlockVariantById } from '~/pages/pages/edit/context/store/actions';
import { getBlockVariantsOptions } from '~/pages/pages/edit/components/right-sidebar/modes/block/modes/container/components/block-variant/constants';
import { FormLabel } from '~/components/_form/components/label';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

import styles from './styles.module.scss';

const BlockVariantSelect = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const selectedLayerId = usePageEditorSelector(getSelectedLayerId);

  const getBlockByIdSelector = useCallback(
    state => {
      const { blockId } = parseLayerId(selectedLayerId);
      return getBlockById(state, { id: blockId });
    },
    [selectedLayerId]
  );
  const block = usePageEditorSelector(getBlockByIdSelector);

  const blockVariantsOptions = useMemo(() => getBlockVariantsOptions(block.data.variants), [block.data.variants]);

  const selected = useMemo(
    () => blockVariantsOptions.find(({ id }) => id === block.data.variant?.name),
    [block.data.variant?.name, blockVariantsOptions]
  );

  const onVariantChange: SelectCallback<BlockVariant> = useCallback(
    () => item => dispatch(setBlockVariantById(item.value, block.id)),
    [block.id, dispatch]
  );

  useEffect(() => {
    if (!block.data.variant) {
      dispatch(setBlockVariantById(DefaultBlockVariants[block.type], block.id));
    }
  }, [block.data.variant, block.id, block.type, dispatch]);

  return (
    <div className={styles.container}>
      <FormLabel label='Variant' placement='top'>
        <Select<BlockVariant>
          name='block-variant'
          items={blockVariantsOptions}
          value={selected}
          onChange={onVariantChange}
        />
      </FormLabel>
    </div>
  );
};

export default BlockVariantSelect;
