import React, { ElementType, forwardRef, PropsWithRef } from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { mapClassName } from './helpers/class-names';

type OwnProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  className?: string;
};

export type GridCSSProperties = {
  '--app-custom-grid-cols'?: number | string;
  '--app-custom-grid-rows'?: number | string;
  '--app-custom-grid-gap-row'?: number | string;
  '--app-custom-grid-gap-column'?: number | string;
  '--app-custom-grid-width'?: number | string;
};

type OwnGridProps = PropsWithRef<{
  fill?: boolean;
  noGutter?: boolean;
  style?: GridCSSProperties;
}>;

type GridProps = PolymorphicComponentProps<ElementType, OwnGridProps & OwnProps>;

const defaultElement = 'div';

export const GridComponent = (
  { children, className, fill, noGutter, ...props }: GridProps,
  ref?: typeof props.ref
): React.JSX.Element => {
  return (
    <Box
      as={defaultElement}
      ref={ref}
      className={mapClassName('provider', className, { fill, 'no-gutter': noGutter })}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Grid = forwardRef(GridComponent);

type OwnColumnProps = PropsWithRef<{
  auto?: boolean;
}>;

type ColumnProps = PolymorphicComponentProps<ElementType, OwnColumnProps & OwnProps>;

export const ColumnComponent = (
  { children, className, auto, ...props }: ColumnProps,
  ref?: typeof props.ref
): React.JSX.Element => {
  return (
    <Box as={defaultElement} ref={ref} className={mapClassName('column', className, { auto })} {...props}>
      {children}
    </Box>
  );
};

export const Column = forwardRef(ColumnComponent);
