import { COMPONENT_TYPES } from '@life-moments/lifehub-components';
import { EditorState } from 'draft-js';
import { validationErrors } from '~/pages/pages/edit/components/right-sidebar/modes/page/components/publishing-panel/components/publishing-form/constants';

import { BREAKPOINT_LABELS } from '~/pages/pages/edit/context/store/enums';
import { mapFEBlocksToBEStructure } from '~/pages/pages/edit/context/store/mappers';
import {
  AvailableBlocks,
  AvailableImages,
  BESavePagePayload,
  State,
  Structure,
  ValidationError
} from '~/pages/pages/edit/context/store/types';
import { FileObject } from '~/types/gists/file';
import { EntityMetaData } from '~/types/gists/misc';
import {
  BlockLayer,
  BlockType,
  FacebookSEO,
  GoogleSEO,
  OverrodeAt,
  Page,
  PageMetaData,
  PublicBlock,
  ServiceBlock,
  TwitterSEO
} from '~/types/gists/page';
import { OriginalTag } from '~/types/gists/tag';
import { parseLayerId } from '~/pages/pages/edit/context/store/layers';

export const getIsNewPage = (state: State): boolean => state.isNew;
export const getPageName = (state: State): string => state.name;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getError = (state: State): string | null => state.error;
export const getFirstInvalidFieldName = (state: State): ValidationError => {
  const errors = state.validationErrors;
  const firstError: ValidationError = Object.keys(errors).filter(k => !!errors[k])[0] || null;
  if (!firstError) return firstError;

  return validationErrors[firstError];
};
export const getIsReady = (state: State): boolean => state.isReady;
export const getIsPagePublished = (state: State): boolean => state.isEnabled;
export const getPageMetaData = ({
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  readTimeMinutes
}: State): EntityMetaData & Pick<PageMetaData, 'readTimeMinutes'> => ({
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  readTimeMinutes
});
export const getIsTouched = (state: State): boolean => state.isTouched;
export const getPageId = (state: State): number => state.id;
export const getPageSlug = (state: State): string => state.slug;
export const getPageOverrodeAt = (state: State): OverrodeAt => state?.overrodeAt;
export const getGoogleSEO = (state: State): GoogleSEO => state.seo.google;
export const getFacebookSEO = (state: State): FacebookSEO => state.seo.facebook;
export const getTwitterSEO = (state: State): TwitterSEO => state.seo.twitter;
export const getPreviewBreakpoint = (state: State): BREAKPOINT_LABELS => state.preview.breakpoint;
export const getPreviewWidth = (state: State): number => state.preview.width;
export const getPreviewZoom = (state: State): number => state.preview.zoom;
export const getStructure = (state: State): Structure => state.structure;
export const getContainerById = (
  state: State,
  {
    containerId
  }: {
    containerId: string;
  }
): ServiceBlock => state.containers[containerId];
export const getContainerBlocks = (
  state: State,
  {
    containerId
  }: {
    containerId: string;
  }
): string[] => state.structure[containerId];

export const getPayloadForPageSaving = (state: State): BESavePagePayload => {
  const {
    name,
    slug,
    cohortId,
    displayConditionRule,
    structure,
    isEnabled,
    tags,
    overrodeAt,
    blocks,
    id,
    containers,
    seo,
    title,
    excerpt,
    label,
    image
  } = state;

  return {
    id,
    name,
    slug,
    cohortId: displayConditionRule !== null ? cohortId : null,
    displayConditionRule: cohortId !== null ? displayConditionRule : null,
    isEnabled,
    tags,
    seo,
    title,
    excerpt,
    label,
    image,
    overrodeAt: overrodeAt || undefined,
    data: {
      blocks: [...new Set(Object.values(blocks).map(block => block.name))],
      structure: mapFEBlocksToBEStructure(structure, blocks, containers)
    }
  };
};

export const getBlockById = (state: State, { id }: { id: string }): PublicBlock => state.blocks[id];
export const getBlockByLayerId = (state: State, { layerId }: { layerId: string }): PublicBlock => {
  const { blockId } = parseLayerId(layerId);

  return state.blocks[blockId];
};
export const getFlatBlocksByOrder = (state: State): BlockType[] =>
  Object.values(state.structure)
    .flat()
    .map(blockId => state.blocks[blockId]);

export const getBlockContainerById = (state: State, { blockId = '' }: { blockId?: string }): ServiceBlock => {
  const containerId = Object.keys(state.structure).find(containerKey => {
    return state.structure[containerKey].includes(blockId);
  });

  if (containerId) {
    return state.containers[containerId];
  }

  return Object.values(state.containers)[0];
};

export const getAvailableBlocks = (state: State): AvailableBlocks => state.availableBlocks;
export const getAvailableBlockById = (
  state: State,
  {
    id
  }: {
    id: string;
  }
): PublicBlock => state.availableBlocks.get(id) as PublicBlock;

export const getAvailableBlockByType = (
  state: State,
  {
    type
  }: {
    type: COMPONENT_TYPES;
  }
): PublicBlock => {
  let blockTemplate: PublicBlock;

  for (const [, value] of getAvailableBlocks(state)) {
    if (value.type === type) {
      blockTemplate = value;
    }
  }

  // Barely possible case that blockTemplate will be undefined.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return blockTemplate;
};
export const getSelectedLayerId = (state: State): string => state.selectedLayerId;

export const getSelectedLayer = (state: State): BlockLayer | null => {
  if (state.selectedLayerId) {
    const { blockId, layerIndex } = parseLayerId(state.selectedLayerId);

    return state.blocks[blockId].data.layers[layerIndex] as BlockLayer;
  }

  return null;
};

export const getIsBlockFirst = (
  state: State,
  {
    id
  }: {
    id: string;
  }
): boolean => {
  const flatBlocksOrder = getFlatBlocksByOrder(state);

  return flatBlocksOrder[0]?.id === id;
};

export const getIsBlockLast = (
  state: State,
  {
    id
  }: {
    id: string;
  }
): boolean => {
  const flatBlocksOrder = getFlatBlocksByOrder(state);

  return flatBlocksOrder[flatBlocksOrder.length - 1]?.id === id;
};
export const getRichEditorState = (state: State): EditorState => state.richEditorState;
export const getTags = (state: State): OriginalTag[] => state.tags;
export const getIsSomethingSelectedInRichEditor = (state: State): boolean =>
  !state.richEditorState.getSelection().isCollapsed();

export const getSelectedBlock = (state: State): PublicBlock | null => {
  if (state.selectedLayerId) {
    const { blockId } = parseLayerId(state.selectedLayerId);
    return state.blocks[blockId];
  }

  return null;
};

export const getPageTitle = (state: State): string | null => state.title;

export const getPageExcerpt = (state: State): string | null => state.excerpt;

export const getPageLabel = (state: State): string | null => state.label;

export const getPageImage = (state: State): FileObject['key'] | null => state.image;

export const getCohortId = (state: State): Page['cohortId'] => state.cohortId;
export const getDisplayConditionRule = (state: State): Page['displayConditionRule'] => state.displayConditionRule;

export const getIsImageBlockCaptionEnabled = (state: State): boolean => {
  const block = getSelectedBlock(state);

  if (block) {
    return !!block.data.layers.find(layer => layer.name === 'Caption');
  }
  return false;
};

export const getAvailableImages = (state: State): AvailableImages => state.availableImages;
export const getAvailableImageByKey = (
  state: State,
  {
    key
  }: {
    key?: FileObject['key'];
  }
): FileObject | undefined => (key ? state.availableImages.get(key) : undefined);

export const getAdditionalPropsByBlockType = (state: State, blockType: COMPONENT_TYPES): Record<string, unknown> => {
  const { updatedAt, readTimeMinutes } = state;

  let data = {};

  if (blockType === COMPONENT_TYPES.metabox) {
    data = { readTimeMinutes, updatedAt };
  }

  return data;
};
