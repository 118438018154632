import { useCallback, useEffect, useState } from 'react';
import { CoachMessage as CoachMessageType } from '~/components/_layout/coach/coach-popover';
import { INITIAL_CODE_VALUE, INITIAL_RESPONSE_MESSAGE } from '~/components/_layout/popovers/import-export/constants';
import { useSelector } from '~/store/hooks';

type useImportExportPopoverHandlersProps = {
  defaultCode: string;
  toggle: (value: boolean) => void;
  setDefaultCode?: (value: string) => void;
  afterSubmit?: () => Promise<void>;
  onSubmit: (code: string) => Promise<CoachMessageType>;
};

type useImportExportPopoverHandlersReturnType = {
  handleChange: HandleChange;
  handleSubmit: () => Promise<void>;
  onClose: () => Promise<void>;
  closePopover: () => void;
  responseMessage: CoachMessageType;
  code: string;
};

export const useImportExportPopoverHandlers = (
  props: useImportExportPopoverHandlersProps
): useImportExportPopoverHandlersReturnType => {
  const { defaultCode, toggle, setDefaultCode, afterSubmit, onSubmit } = props;

  const [responseMessage, setResponseMessage] = useState<CoachMessageType>(INITIAL_RESPONSE_MESSAGE);
  const [code, setCode] = useState<string>(defaultCode);

  const handleChange = useCallback<HandleChange>((_name, value) => {
    setCode(value);
  }, []);

  const closePopover = useCallback(() => {
    setCode(INITIAL_CODE_VALUE);
    setResponseMessage(INITIAL_RESPONSE_MESSAGE);
    toggle(false);

    if (setDefaultCode) {
      setDefaultCode(INITIAL_CODE_VALUE);
    }
  }, [setDefaultCode, toggle]);

  const onClose = useCallback(async () => {
    closePopover();

    if (afterSubmit) {
      await afterSubmit();
    }
  }, [afterSubmit, closePopover]);

  useEffect(() => {
    setCode(defaultCode);
  }, [defaultCode]);

  const isMainMenuOpened = useSelector(state => state.mainMenu.isMainMenuOpened);

  useEffect(() => {
    if (isMainMenuOpened) {
      closePopover();
    }
  }, [isMainMenuOpened, closePopover]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const responseMessage = await onSubmit(code);
    setResponseMessage(responseMessage);
  }, [code, onSubmit]);

  return {
    handleChange,
    handleSubmit,
    onClose,
    closePopover,
    responseMessage,
    code
  };
};
