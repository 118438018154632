import { v4 as uuid } from 'uuid';
import { EditorState } from 'draft-js';
import { BlockVariant, COMPONENT_TYPES } from '@life-moments/lifehub-components';

import { BREAKPOINT_LABELS } from '~/pages/pages/edit/context/store/enums';
import {
  AvailableBlocks,
  AvailableImages,
  SetState,
  State,
  ValidationError
} from '~/pages/pages/edit/context/store/types';
import { BlockType, FacebookSEO, GoogleSEO, Page, TwitterSEO } from '~/types/gists/page';
import { EntityMetaData } from '~/types/gists/misc';
import { OriginalTag } from '~/types/gists/tag';
import { FileObject } from '~/types/gists/file';

export enum ActionTypes {
  SavePageName = 'SavePageName',
  SetLoading = 'SetLoading',
  SetError = 'SetError',
  SetValidationError = 'SetValidationError',
  SetIsReady = 'SetIsReady',
  SetInitialPageData = 'SetInitialPageData',
  SetIsNew = 'SetIsNew',
  SetIsEnabled = 'SetIsEnabled',
  SetBreakpoint = 'SetBreakpoint',
  SetWidth = 'SetWidth',
  SetZoom = 'SetZoom',
  SetSlug = 'SetSlug',
  SetOverrodeAt = 'SetOverrodeAt',
  SetGoogleSEO = 'SetGoogleSEO',
  SetFacebookSEO = 'SetFacebookSEO',
  SetTwitterSEO = 'SetTwitterSEO',
  SetAvailableBlocks = 'SetAvailableBlocks',
  AddBlockByIndex = 'AddBlockByIndex',
  SetSelectedLayerId = 'SetSelectedLayerId',
  MoveBlockUp = 'MoveBlockUp',
  MoveBlockDown = 'MoveBlockDown',
  DeleteBlock = 'DeleteBlock',
  DuplicateBlock = 'DuplicateBlock',
  SetUpdatedMetaFields = 'SetUpdatedMetaFields',
  UpdateBlockContent = 'UpdateBlockContent',
  InitContainers = 'InitContainers',
  SetRichEditorState = 'SetRichEditorState',
  SetTags = 'SetTags',
  SetBlockVariantById = 'SetBlockVariantById',
  SetIsTouched = 'SetIsTouched',
  SetBlockType = 'SetBlockType',
  SetBlockLayerContent = 'SetBlockLayerContent',
  SetPageImage = 'SetPageImage',
  SetPageTitle = 'SetPageTitle',
  SetPageExcerpt = 'SetPageExcerpt',
  SetPageLabel = 'SetPageLabel',
  SetCohortId = 'SetCohortId',
  SetDisplayConditionRule = 'SetDisplayConditionRule',
  ToggleImageBlockCaption = 'ToggleImageBlockCaption',
  UpdateBlockData = 'UpdateBlockData',
  SetAvailableImages = 'SetAvailableImages',
  UpdateAvailableImage = 'UpdateAvailableImage'
}

export const savePageName = (pageName: string): SetState => ({
  type: ActionTypes.SavePageName,
  payload: pageName
});

export const setLoading = (isLoading: boolean): SetState => ({
  type: ActionTypes.SetLoading,
  payload: isLoading
});

export const setError = (error: string | null): SetState => ({
  type: ActionTypes.SetError,
  payload: error
});

export const setValidationError = (fieldName: keyof State['validationErrors'], error: ValidationError): SetState => ({
  type: ActionTypes.SetValidationError,
  payload: { fieldName, error }
});

export const setIsReady = (isReady: boolean): SetState => ({
  type: ActionTypes.SetIsReady,
  payload: isReady
});

export const setInitialPageData = (pageData: Page): SetState => ({
  type: ActionTypes.SetInitialPageData,
  payload: pageData
});

export const setIsEnabled = (isEnabled: boolean): SetState => ({
  type: ActionTypes.SetIsEnabled,
  payload: isEnabled
});

export const setBreakpoint = (breakpoint: BREAKPOINT_LABELS): SetState => ({
  type: ActionTypes.SetBreakpoint,
  payload: breakpoint
});

export const setWidth = (width: number): SetState => ({
  type: ActionTypes.SetWidth,
  payload: width
});

export const setZoom = (zoom: number): SetState => {
  return {
    type: ActionTypes.SetZoom,
    payload: zoom
  };
};

export const setSlug = (slug: string): SetState => {
  return {
    type: ActionTypes.SetSlug,
    payload: slug
  };
};

export const setOverrodeAt = (overrodeAt?: Date): SetState => {
  return {
    type: ActionTypes.SetOverrodeAt,
    payload: overrodeAt
  };
};

export const setGoogleSEO = (payload: { name: keyof GoogleSEO; value: GoogleSEO[keyof GoogleSEO] }): SetState => {
  return {
    type: ActionTypes.SetGoogleSEO,
    payload
  };
};

export const setFacebookSEO = (payload: {
  name: keyof FacebookSEO;
  value: FacebookSEO[keyof FacebookSEO];
}): SetState => {
  return {
    type: ActionTypes.SetFacebookSEO,
    payload
  };
};

export const setTwitterSEO = (payload: { name: keyof TwitterSEO; value: TwitterSEO[keyof TwitterSEO] }): SetState => {
  return {
    type: ActionTypes.SetTwitterSEO,
    payload
  };
};

export const setAvailableBlocks = (blocks: AvailableBlocks): SetState => {
  return {
    type: ActionTypes.SetAvailableBlocks,
    payload: blocks
  };
};

export const addBlockByIndex = (block: BlockType, index: number, containerId: string): SetState => {
  return {
    type: ActionTypes.AddBlockByIndex,
    payload: { block, index, containerId }
  };
};
export const setSelectedLayerId = (selectedLayerId: string): SetState => {
  return {
    type: ActionTypes.SetSelectedLayerId,
    payload: selectedLayerId
  };
};

export const moveBlockUp = (blockId: string): SetState => {
  return {
    type: ActionTypes.MoveBlockUp,
    payload: blockId
  };
};

export const moveBlockDown = (blockId: string): SetState => {
  return {
    type: ActionTypes.MoveBlockDown,
    payload: blockId
  };
};

export const deleteBlock = (blockId: string): SetState => {
  return {
    type: ActionTypes.DeleteBlock,
    payload: blockId
  };
};

export const duplicateBlock = (blockId: string): SetState => {
  return {
    type: ActionTypes.DuplicateBlock,
    payload: { id: blockId, newId: uuid() }
  };
};

export const setUpdatedMetaFields = (metaFields: Pick<EntityMetaData, 'updatedAt' | 'updatedBy'>) => {
  return {
    type: ActionTypes.SetUpdatedMetaFields,
    payload: metaFields
  };
};

export const updateBlockContent = (id: string, blockContent: string): SetState => {
  return {
    type: ActionTypes.UpdateBlockContent,
    payload: { id, content: blockContent }
  };
};

export const initContainers = (): SetState => {
  return {
    type: ActionTypes.InitContainers
  };
};

export const setRichEditorState = (editorState: EditorState): SetState => {
  return {
    type: ActionTypes.SetRichEditorState,
    payload: editorState
  };
};

export const setTags = (tags: OriginalTag[]): SetState => {
  return {
    type: ActionTypes.SetTags,
    payload: tags
  };
};

export const setBlockVariantById = (variant: BlockVariant, id: string): SetState => ({
  type: ActionTypes.SetBlockVariantById,
  payload: { variant, id }
});

export const setIsTouched = (isTouched: boolean): SetState => ({
  type: ActionTypes.SetIsTouched,
  payload: isTouched
});

export const setBlockType = (id: string, newType: COMPONENT_TYPES): SetState => ({
  type: ActionTypes.SetBlockType,
  payload: { id, newType }
});

export const setBlockLayerContent = (layerId: string, newContent: string): SetState => ({
  type: ActionTypes.SetBlockLayerContent,
  payload: { layerId, newContent }
});

export const setPageTitle = (title: string | null): SetState => ({
  type: ActionTypes.SetPageTitle,
  payload: title
});

export const setPageExcerpt = (excerpt: string | null): SetState => ({
  type: ActionTypes.SetPageExcerpt,
  payload: excerpt
});

export const setPageLabel = (label: string | null): SetState => ({
  type: ActionTypes.SetPageLabel,
  payload: label
});

export const setPageImage = (image: string | null): SetState => ({
  type: ActionTypes.SetPageImage,
  payload: image
});

export const setCohortId = (cohortId: Page['cohortId']): SetState => ({
  type: ActionTypes.SetCohortId,
  payload: cohortId
});

export const setDisplayConditionRule = (rule: Page['displayConditionRule']): SetState => ({
  type: ActionTypes.SetDisplayConditionRule,
  payload: rule
});

export const toggleImageBlockCaption = (blockId: string): SetState => ({
  type: ActionTypes.ToggleImageBlockCaption,
  payload: blockId
});

export const updateBlockData = (blockId: string, data: Record<string, any>): SetState => ({
  type: ActionTypes.UpdateBlockData,
  payload: { id: blockId, data }
});

export const setAvailableImages = (images: AvailableImages): SetState => {
  return {
    type: ActionTypes.SetAvailableImages,
    payload: images
  };
};

export const updateAvailableImages = (image: FileObject): SetState => {
  return {
    type: ActionTypes.UpdateAvailableImage,
    payload: image
  };
};
