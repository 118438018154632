import { useClickOutside } from '~/hooks/use-click-outside';
import React, { useCallback, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useUniqId } from '~/hooks/use-uniq-id';
import { RootState } from '~/store';
import { useConnect } from '~/store/hooks';
import { pop, push } from '~/store/reducers/coach';
import { MotionPopover } from '~/components/popover';
import { Slot } from '~/components/slots';
import { CoachMessage } from '../coach-message';
import { CoachButtons } from '../coach-buttons';

import styles from './coach-popover.module.scss';

export const coachRoot = document.getElementById('coach') as HTMLElement;

export type CoachMessage = string | string[] | null;

type Props = Pick<Partial<UseCoachPopover>, 'show' | 'unsubscribe'> & {
  message?: CoachMessage;
  children?: React.ReactNode;
};

export const VARIANTS = {
  initial: { y: '-100%' },
  in: { y: 0 },
  out: { y: '-100%' }
};

export const CoachPopover = ({ show = false, message, unsubscribe, children }: Props): React.JSX.Element | null => {
  const [clickTrackerRef] = useClickOutside(() => {
    if (show && unsubscribe) {
      unsubscribe();
    }
  }, true);

  return show ? (
    <Slot name='coach'>
      <AnimatePresence>
        <MotionPopover
          ref={clickTrackerRef}
          className={styles.container}
          initial='initial'
          animate='in'
          exit='out'
          variants={VARIANTS}
        >
          <CoachMessage>{message}</CoachMessage>
          <CoachButtons>{children}</CoachButtons>
        </MotionPopover>
      </AnimatePresence>
    </Slot>
  ) : null;
};

type UseCoachPopoverProps = {
  timeout?: number;
  loading?: boolean;
  disabled?: boolean;
};

export type UseCoachPopover = {
  id: string;
  show: boolean;
  unsubscribe: () => void;
  CoachPopover: typeof CoachPopover;
};

// The selector function does not receive an ownProps argument.
// However, props can be used through closure (see the examples below) or by using a curried selector.
const mapStateToProps = ({ id }: { id: string }) => {
  return (state: RootState) => state.coach.queue[0] === id;
};

const mapDispatchToProps = { push, pop };

export const useCoachPopover = ({
  timeout,
  loading = false,
  disabled = false
}: UseCoachPopoverProps = {}): UseCoachPopover => {
  const id = useUniqId();

  const [show, { pop, push }] = useConnect(mapStateToProps({ id }), mapDispatchToProps);

  const timer = useRef<{ ready: boolean; cleaned: boolean; id: NodeJS.Timeout | null }>({
    ready: !loading,
    cleaned: false,
    id: null
  });

  const subscribe = useCallback(() => {
    push(id);
    timer.current.cleaned = false;
  }, [id, push]);

  const unsubscribe = useCallback(() => {
    if (!timer.current.cleaned) {
      pop(id);

      timer.current.cleaned = true;
    }
  }, [id, pop]);

  useEffect(() => {
    if ((loading && !disabled) || !disabled) {
      subscribe();

      return () => {
        unsubscribe();
      };
    }

    return void 0;
  }, [disabled, subscribe, unsubscribe, loading]);

  useEffect(() => {
    timer.current.ready = !loading;
  }, [loading]);

  useEffect(() => {
    if (show && timeout) {
      const id = setTimeout(() => {
        if (timer.current.ready) {
          unsubscribe();
        } else {
          timer.current.id = null;
        }
      }, timeout);

      timer.current.id = id;

      return () => {
        clearTimeout(id);
      };
    }

    return void 0;
  }, [show, disabled, timeout, unsubscribe]);

  useEffect(() => {
    if (!loading && show && timeout && !timer.current.id) {
      unsubscribe();
    }
  }, [loading, show, timeout, unsubscribe]);

  return {
    id,
    show,
    CoachPopover,
    unsubscribe
  };
};
