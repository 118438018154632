import React, { useCallback, useEffect, useState } from 'react';
import Interaction, {
  InteractionState
} from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getBlockByLayerId, getRichEditorState, getSelectedLayer } from '~/pages/pages/edit/context/store/selectors';
import { EditorState, EntityInstance, RichUtils } from 'draft-js';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { setBlockLayerContent, setRichEditorState } from '~/pages/pages/edit/context/store/actions';
import { RenderConfig, stateToHTML } from 'draft-js-export-html';
import DOMPurify from 'dompurify';
import {
  DEFAULT_BLOCK_TAG,
  replaceWhitespacesBetweenTags
} from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor/utils';
import { makeValidUrl } from '~/pages/pages/edit/components/right-sidebar/modes/block/components/interaction/utils';
import { BlockLayer } from '~/types/gists/page';

const ContentInteraction = (): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const richEditorState = usePageEditorSelector(getRichEditorState);

  const [interactionState, setInteractionState] = useState<InteractionState>({
    url: '',
    ariaLabel: '',
    noFollow: false,
    target: '_self'
  });

  const selectedLayer = usePageEditorSelector(getSelectedLayer) as BlockLayer;

  const getBlockByLayerIdSelected = useCallback(
    state => getBlockByLayerId(state, { layerId: selectedLayer.id }),
    [selectedLayer.id]
  );
  const block = usePageEditorSelector(getBlockByLayerIdSelected);

  useEffect(() => {
    const selectionState = richEditorState.getSelection();

    const contentState = richEditorState.getCurrentContent();

    const linkKey = contentState
      .getBlockForKey(selectionState.getStartKey())
      .getEntityAt(selectionState.getStartOffset());

    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      if (linkInstance.getType() === 'LINK') {
        const { url, ariaLabel, noFollow, target } = linkInstance.getData();
        setInteractionState({
          url,
          ariaLabel,
          noFollow,
          target
        });
      }
    }
  }, [richEditorState]);

  const updateContent = useCallback(
    (newEditorState: EditorState) => {
      const html = stateToHTML(newEditorState.getCurrentContent(), {
        defaultBlockTag: DEFAULT_BLOCK_TAG[block.type] ?? DEFAULT_BLOCK_TAG.default,
        entityStyleFn: (entity: EntityInstance): RenderConfig | undefined => {
          if (entity.getType() === 'LINK') {
            const { url, target, ariaLabel, noFollow } = entity.getData();

            return {
              element: 'a',
              attributes: {
                href: url,
                target,
                'aria-label': ariaLabel ? ariaLabel : undefined,
                rel: noFollow ? 'nofollow' : undefined
              }
            };
          }
          return undefined;
        }
      });

      const sanitizedContent = DOMPurify.sanitize(replaceWhitespacesBetweenTags(html), { ADD_ATTR: ['target'] });
      if (sanitizedContent !== selectedLayer.content) {
        dispatch(setBlockLayerContent(selectedLayer.id, sanitizedContent));
      }
    },
    [block.type, dispatch, selectedLayer.content, selectedLayer.id]
  );

  const onInteractionChange = useCallback(
    (newInteractionState: InteractionState) => {
      const { url, ariaLabel, noFollow, target } = newInteractionState;

      const contentStateWithLink = url
        ? richEditorState.getCurrentContent().createEntity('LINK', 'MUTABLE', {
            url: makeValidUrl(url),
            ariaLabel,
            noFollow,
            target
          })
        : null;

      const selection = richEditorState.getSelection();

      const editorStateWithLink = contentStateWithLink
        ? EditorState.set(richEditorState, {
            currentContent: contentStateWithLink
          })
        : RichUtils.toggleLink(richEditorState, selection, null);

      const newState = RichUtils.toggleLink(
        editorStateWithLink,
        editorStateWithLink.getSelection(),
        contentStateWithLink ? contentStateWithLink.getLastCreatedEntityKey() : null
      );

      dispatch(setRichEditorState(newState));
      setInteractionState(newInteractionState);
      updateContent(newState);
    },
    [dispatch, richEditorState, updateContent]
  );

  return <Interaction interactionState={interactionState} onInteractionChange={onInteractionChange} />;
};

export default ContentInteraction;
