const isMorningNow = (hour: number): boolean => hour >= 6 && hour < 12;
const isAfternoonNow = (hour: number): boolean => hour >= 12 && hour < 18;
const isEveningNow = (hour: number): boolean => hour >= 18 && hour < 24;

type Periods = 'morning' | 'afternoon' | 'evening' | 'night';

const MESSAGES: Record<Periods, string> = {
  morning: 'Good morning!',
  afternoon: 'Good afternoon!',
  evening: 'Good evening!',
  night: 'Good night!'
};

const getMessageBasedOnADayPeriod = (): string => {
  const hour = parseInt(new Date().toTimeString());

  if (isMorningNow(hour)) {
    return MESSAGES.morning;
  }

  if (isAfternoonNow(hour)) {
    return MESSAGES.afternoon;
  }

  if (isEveningNow(hour)) {
    return MESSAGES.evening;
  }

  return MESSAGES.night ?? '';
};

export { isMorningNow, isAfternoonNow, isEveningNow, getMessageBasedOnADayPeriod };
