import React, { memo, useCallback } from 'react';
import { CellProps, TableDataLike } from 'react-table';
import classnames from 'classnames';

import styles from './related-item-cell.module.scss';
import { TextCell } from '~/components/_table/cells/text';
import { TooltipOnCondition } from '~/components/_layout/popovers/tooltip/on-hover';
import { Button } from '~/components/button';
import { useTheme } from '~/components/theme';

const RelatedItemCell = <Data extends TableDataLike>(props: CellProps<Data>): React.JSX.Element => {
  const {
    column: { to, actionTooltip },
    row: { original }
  } = props.cell;

  const { theme, env, affiliate } = useTheme();

  const onClick = useCallback(
    () => window.open((to as (value: Data) => string)(original) + `?affiliate=${affiliate}&env=${env}`),
    [to, original, affiliate, env]
  );

  return (
    <div className={classnames(styles.container, styles[theme])} onClick={onClick}>
      <TextCell {...props} />
      <div className={styles.button}>
        <TooltipOnCondition title={actionTooltip} condition={!!actionTooltip} delay={100}>
          <Button icon='blank' />
        </TooltipOnCondition>
      </div>
    </div>
  );
};

export default memo(RelatedItemCell);
