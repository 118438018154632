import { Reducer, useCallback, useReducer } from 'react';
import {
  CellValue,
  CloneSingleCallback,
  EditCallback,
  PublishCallback,
  RemoveCallback,
  RemoveSelectedCallback,
  TableAutoResetProps,
  TableDataLike,
  UpdateCallback
} from 'react-table';
import { api } from '~/api';
import { buildDeleteRequest, buildTableRequest } from '~/components/_table/helpers/build-table-request';
import { gistToIdentifier } from '~/components/_table/helpers/gist-to-identifier';
import { GetTableItemsResponse, TableApi, TableReducerState } from '~/components/_table/types';
import { useEditableData } from '../use-editable-data';
import { Action, reducer } from './reducer';
import { ACTIONS_COLUMN } from '~/components/_table/plugins/actions';
import { replaceEmptyStringWithNull } from '~/helpers/formatters';
import { CustomError } from '~/helpers/common/custom-error';

export const DEFAULT_DEBOUNCE_DELAY = 150;
export const DEFAULT_EXPORT_RESULT = '[]';

const initialState = {
  count: 0,
  items: [],
  normalizedItems: {},
  exportedData: '',
  isEmpty: null,
  loading: true,
  error: undefined
};

type Props<T> = {
  gist: TableApi;
  id?: string;
  callback?: {
    afterGet?: (data: GetTableItemsResponse<T>) => void;
  };
};

export type SetIsProcessingByIdPayload = { id: number; isProcessing: boolean };
export type RemovePayload = { ids: number[] };

export type Extend = HasId & { isEnabled?: boolean; isActive?: boolean } & TableDataLike;

export const useTableFetch = <T extends Extend>({ gist, id, callback }: Props<T>): UseTableFetch<T> => {
  const [state, dispatch] = useReducer<Reducer<TableReducerState<T>, Action<T>>>(reducer, initialState);

  const method = api[gist];

  const identifier = gistToIdentifier[gist];

  const setLoading = useCallback((isLoading: boolean) => {
    dispatch({ type: 'setLoading', payload: isLoading });
  }, []);

  const setError = (e: Error) => {
    dispatch({ type: 'failure', error: e });
  };

  const clear = useCallback(() => {
    dispatch({ type: 'clear' });
  }, []);

  const get = useCallback<UpdateCallback<T>>(
    async state => {
      dispatch({ type: 'request' });
      clear();

      const response = await method.get<T>(buildTableRequest(identifier, state));

      if (callback?.afterGet) {
        callback.afterGet(response);
      }

      dispatch({ type: 'get', payload: response });
    },
    [clear, callback, identifier, method]
  );

  const getHistory = useCallback<UpdateCallback<T>>(
    async state => {
      if (!id) {
        return;
      }

      dispatch({ type: 'request' });
      clear();

      try {
        const response = await method.getHistory<T>({
          ...buildTableRequest(identifier, state),
          id
        });

        dispatch({ type: 'get', payload: response });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
      }
    },
    [clear, id, identifier, method]
  );

  const edit = useCallback<EditCallback<T>>(
    async (value, { row }, { toggleTableLoading, setTableError }) => {
      setTableError(null);
      toggleTableLoading(true);

      dispatch({ type: 'request' });
      clear();

      try {
        const item = {
          inline: true,
          ...row.original,
          ...value
        };
        replaceEmptyStringWithNull(item);
        const response = await method.edit<T>(item);

        dispatch({ type: 'edit', payload: response });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
        setTableError(error.message);
      } finally {
        toggleTableLoading(false);
      }
    },
    [clear, method]
  );

  const editCell = useCallback<EditCallback<T>>(
    async (value, cell, instance) => {
      const { column } = cell;
      await edit(column.id === ACTIONS_COLUMN ? value : ({ [column.id]: value } as CellValue<T>), cell, instance);
    },
    [edit]
  );

  const publish = useCallback<PublishCallback<T>>(
    async ({ row, setTableError, toggleTableLoading }, setLoading, setReady) => {
      setTableError(null);
      toggleTableLoading(true);

      setLoading();

      dispatch({ type: 'request' });
      clear();

      try {
        const response = await method.edit<T>({
          inline: true,
          ...row.original,
          isEnabled: !row.original.isEnabled,
          isActive: !row.original.isActive
        });

        dispatch({ type: 'edit', payload: response });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
        setTableError(error.message);
      } finally {
        await setReady();
        toggleTableLoading(false);
      }
    },
    [clear, method]
  );

  const remove = useCallback<RemoveCallback<T>>(
    async ({ row, setTableError, toggleTableLoading }, setLoading, setReady) => {
      setTableError(null);
      toggleTableLoading(true);
      setLoading();

      dispatch({ type: 'request' });
      clear();

      try {
        await method.removeSingle({ id: row.original.id });

        dispatch({ type: 'remove', payload: { ids: [row.original.id] } });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
        setTableError(error.message);
      } finally {
        await setReady();
        toggleTableLoading(false);
      }
    },
    [clear, method]
  );

  const cloneSingle = useCallback<CloneSingleCallback<T>>(
    async ({ row, setTableError, toggleTableLoading }) => {
      setTableError(null);
      toggleTableLoading(true);

      dispatch({ type: 'request' });
      clear();

      try {
        const clonedItem = await method.cloneSingle({ id: row.original.id });
        dispatch({ type: 'clone', payload: clonedItem });
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        dispatch({ type: 'failure', error });
        setTableError(error.message);
      } finally {
        toggleTableLoading(false);
      }
    },
    [clear, method]
  );

  const removeSelected = useCallback<RemoveSelectedCallback<T>>(
    ({ state, rows, setTableError, toggleTableLoading }) =>
      async (setLoading, setReady) => {
        setTableError(null);
        toggleTableLoading(true);
        setLoading();

        dispatch({ type: 'request' });
        clear();

        try {
          const idsToDelete = rows.filter(({ id }) => state.selectedRowIds[id]).map(({ original }) => original.id);

          await method.remove(buildDeleteRequest(idsToDelete));

          dispatch({ type: 'remove', payload: { ids: idsToDelete } });
        } catch (unknownError) {
          const error = new CustomError(unknownError);
          dispatch({ type: 'failure', error });
          setTableError(error.message);
        } finally {
          toggleTableLoading(false);
          await setReady();
        }
      },
    [clear, method]
  );

  const setIsProcessingById = ({ id, isProcessing }: SetIsProcessingByIdPayload): void => {
    dispatch({ type: 'setIsProcessingById', payload: { id, isProcessing } });
  };

  const [handleEditCell, handlePublish, autoResetProps] = useEditableData<T>(state.items, editCell, publish);

  return {
    get,
    getHistory,
    editCell: handleEditCell,
    edit,
    publish: handlePublish,
    autoResetProps,
    remove,
    removeSelected,
    setError,
    clear,
    setIsProcessingById,
    cloneSingle,
    setLoading,
    ...state
  };
};

export type UseTableFetch<T extends Extend> = {
  get: UpdateCallback<T>;
  getHistory: UpdateCallback<T>;
  edit: EditCallback<T>;
  editCell: EditCallback<T>;
  publish: PublishCallback<T>;
  remove: RemoveCallback<T>;
  removeSelected: RemoveSelectedCallback<T>;
  count: TableReducerState<T>['count'];
  items: TableReducerState<T>['items'];
  normalizedItems: TableReducerState<T>['normalizedItems'];
  isEmpty: TableReducerState<T>['isEmpty'];
  loading: TableReducerState<T>['loading'];
  setLoading: (isLoading: boolean) => void;
  error?: TableReducerState<T>['error'];
  setError: (e: Error) => void;
  autoResetProps: TableAutoResetProps;
  clear: () => void;
  setIsProcessingById: (payload: SetIsProcessingByIdPayload) => void;
  cloneSingle: CloneSingleCallback<T>;
};
