import React from 'react';
import { PageStore } from '~/pages/pages/edit/context';

export function withPageEditorContext(Component: React.FC): React.FC {
  const displayName = Component.displayName || Component.name || 'Component';

  const EnhancedComponent = () => (
    <PageStore>
      <Component />
    </PageStore>
  );

  EnhancedComponent.displayName = `withPageEditorContext(${displayName})`;

  return EnhancedComponent;
}
