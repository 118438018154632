import React, { createContext, PropsWithChildren, useMemo, useReducer } from 'react';
import { CellProps } from 'react-table';

import { FileObject } from '~/types/gists/file';
import { State } from '~/components/_table/cells/file-primary/context/types';
import FilePrimaryCellReducer from '~/components/_table/cells/file-primary/context/reducer';

export type ContextReturnType = State & {
  cellProps: CellProps<FileObject>;
  dispatch: React.Dispatch<any>;
};

const initialState = {
  isFileDetailsOpened: false,
  altText: '',
  description: ''
};

const FilePrimaryCellContext = createContext<ContextReturnType>({
  ...initialState,
  cellProps: {} as CellProps<FileObject>,
  dispatch: () => void 0
});

const FilePrimaryCellProvider = ({ children, ...cellProps }: PropsWithChildren<CellProps<FileObject>>) => {
  const [state, dispatch] = useReducer(FilePrimaryCellReducer, initialState);

  const shared = useMemo(
    () => ({
      isFileDetailsOpened: state.isFileDetailsOpened,
      altText: state.altText,
      description: state.description,
      cellProps,
      dispatch
    }),
    [cellProps, dispatch, state.altText, state.description, state.isFileDetailsOpened]
  );

  return <FilePrimaryCellContext.Provider value={shared}>{children}</FilePrimaryCellContext.Provider>;
};

export default FilePrimaryCellProvider;

export { FilePrimaryCellContext };
