import React from 'react';

import { COMPONENT_TYPES, HeadingVariants } from '@life-moments/lifehub-components';

import Variant from '../../components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: HeadingVariants[];
  content: string;
};

const HeadingBlockVariants = ({ variants, content }: Props): React.JSX.Element => (
  <div className={styles.list}>
    {variants.map(variant => (
      <Variant key={variant.name} variant={variant} type={COMPONENT_TYPES.heading} content={content} />
    ))}
  </div>
);

export default HeadingBlockVariants;
