import React, { useMemo } from 'react';
import { Row, TableDataLike } from 'react-table';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Confirm, ConfirmCallback } from '~/components/_layout/popovers/confirm';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { TableApi } from '~/components/_table/types';
import { mapGistToPluralEntity } from '~/components/_table/helpers/common';

export type Props<Data extends TableDataLike> = {
  gist: TableApi;
  items?: Row<Data>[];
  onConfirm?: ConfirmCallback;
};

export const DeleteAction = <Data extends TableDataLike>({
  items = [],
  onConfirm,
  gist
}: Props<Data>): React.JSX.Element | null => {
  const names = useMemo(() => `"${items.map(({ original }) => original.name).join(',')}"`, [items]);

  const isMultipleDelete = items.length > 1;

  return items.length ? (
    <TopBarTool>
      <Confirm
        message={[
          <span key='permanently-delete'>
            Are you sure you want to delete <strong>{isMultipleDelete ? items.length : names}</strong>
            {isMultipleDelete ? ` ${mapGistToPluralEntity[gist]}` : ''}
          </span>,
          'This cannot be undone...'
        ]}
        onConfirm={onConfirm}
        cancelText={isMultipleDelete ? 'Keep them' : 'Keep it'}
        confirmText={isMultipleDelete ? 'Delete them' : 'Delete it'}
      >
        {toggle => (
          <TooltipOnHover title='Delete' delay={100}>
            <Button is='minor' icon='bin' onClick={() => toggle(true)} />
          </TooltipOnHover>
        )}
      </Confirm>
    </TopBarTool>
  ) : null;
};
