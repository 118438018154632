import React, { FC } from 'react';
import { Image } from '~/components/image';
import { useTheme } from '~/components/theme';

export const AffiliateLogo: FC<{ className?: string }> = ({ className }): React.JSX.Element | null => {
  const { affiliateDetails } = useTheme();

  return affiliateDetails?.iconUrl ? (
    <Image src={affiliateDetails.iconUrl} alt='product-env-logo' className={className} />
  ) : null;
};
