import { PageEditorThunk } from '~/pages/pages/edit/context/store/types';
import { getSelectedBlock } from '~/pages/pages/edit/context/store/selectors';
import { setSelectedLayerId, updateBlockContent } from '~/pages/pages/edit/context/store/actions';
import { collectContentFromLayers } from '~/pages/pages/edit/context/store/helpers';

export const onCloseRichEditor = (): PageEditorThunk => (dispatch, getState) => {
  const block = getSelectedBlock(getState());
  if (block) {
    const blockContent = collectContentFromLayers(block.data.layers);

    dispatch(updateBlockContent(block.id, blockContent));
    dispatch(setSelectedLayerId(''));
  }
};
