import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { publicRoutes as routes } from '~/routes/public';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { Background } from './components/background';
import styles from './onboarding.module.scss';
import useBackgroundColorByRoute, {
  NO_MATCH_ROUTE
} from '~/pages/onboarding/components/background/useBackgroundColorByRoute';

const FLOW_VARIANTS = {
  initial: { y: -50, opacity: 0 },
  in: { y: 0, opacity: 1 },
  out: { y: 50, opacity: 0 }
};

const MAIN_VARIANTS = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 }
};

export const Onboarding = (): React.JSX.Element => {
  const location = useLocation();

  const { show, CoachPopover } = useCoachPopover();

  const bgColor = useBackgroundColorByRoute();

  return (
    <>
      <motion.div
        className={styles.background}
        initial='initial'
        animate='in'
        exit='out'
        variants={MAIN_VARIANTS}
        style={{ background: bgColor }}
      >
        {bgColor === NO_MATCH_ROUTE && <Background />}
      </motion.div>
      <CoachPopover show={show}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {routes.map(({ to, component: Component, ...props }) => (
              <Route
                key={to.toString()}
                path={to}
                render={() => (
                  <motion.div initial='initial' animate='in' exit='out' variants={FLOW_VARIANTS}>
                    <Component />
                  </motion.div>
                )}
                {...props}
              />
            ))}
          </Switch>
        </AnimatePresence>
      </CoachPopover>
    </>
  );
};
