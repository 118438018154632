import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import useQuery from '~/hooks/use-query';

const wsUrls = {
  dev: process.env.REACT_APP_ADMIN_WS_ENDPOINT_DEV,
  test: process.env.REACT_APP_ADMIN_WS_ENDPOINT_TEST,
  stage: process.env.REACT_APP_ADMIN_WS_ENDPOINT_STAGE,
  prod: process.env.REACT_APP_ADMIN_WS_ENDPOINT_PROD
};

const useSocket = (onMessage: (event: MessageEvent) => void): void => {
  const ws: MutableRefObject<WebSocket | undefined> = useRef();

  const { env } = useQuery();

  const wsUrl = useMemo(() => (env ? wsUrls[env as Environment] : wsUrls.dev), [env]);

  useEffect(() => {
    ws.current = new WebSocket(`${wsUrl}?Authorizer=${localStorage.getItem('accessToken')}`);
    ws.current.onmessage = onMessage;
    ws.current.onopen = () => {
      setInterval(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send('ping');
        }
      }, 30000);
    };

    return () => {
      ws.current?.close();
    };
  }, [onMessage, wsUrl]);
};

export default useSocket;
