import React from 'react';

import {
  NUMBER_LIST_DEFAULT_VARIANT,
  NumberListVariants,
  BULLET_LIST_DEFAULT_VARIANT,
  BulletListVariants
} from '@life-moments/lifehub-components';

import { getStructureItemLayers, StructureItemLayer } from '~/pages/blocks/edit/components/structure-sidebar/helpers';

import { Block } from '~/types/gists/Block';

import Variant from '~/pages/blocks/edit/components/structure-sidebar/components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: NumberListVariants[] | BulletListVariants[];
  blockName: Block['name'];
};

const content: StructureItemLayer[] = [{ name: 'Items' }];

const ListBlockVariants = ({ variants, blockName }: Props): React.JSX.Element => (
  <div className={styles.container}>
    {variants.map(variant => (
      <Variant
        key={variant.name}
        name={variant.displayName}
        isDefault={
          variant.name === NUMBER_LIST_DEFAULT_VARIANT.name || variant.name === BULLET_LIST_DEFAULT_VARIANT.name
        }
        content={getStructureItemLayers(blockName, content)}
      />
    ))}
  </div>
);

export default ListBlockVariants;
