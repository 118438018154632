import React from 'react';

import { COMPONENT_TYPES, SpacerVariants } from '@life-moments/lifehub-components';

import Variant from '../../components/variant';

import styles from './styles.module.scss';

type Props = {
  variants: SpacerVariants[];
};

const SpacerBlockVariants = ({ variants }: Props): React.JSX.Element => (
  <div className={styles.list}>
    {variants.map(variant => (
      <Variant key={variant.name} variant={variant} type={COMPONENT_TYPES.spacer} />
    ))}
  </div>
);

export default SpacerBlockVariants;
