import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { setSelectedLayerId } from '~/pages/pages/edit/context/store/actions';
import { getAdditionalPropsByBlockType, getBlockById } from '~/pages/pages/edit/context/store/selectors';
import { getFirstLayerToSelect } from '~/pages/pages/edit/context/store/thunks';
import { State } from '~/pages/pages/edit/context/store/types';
import Layers from '~/pages/pages/edit/components/preview-zone/components/block/components/layers';

type Props = {
  id: string;
  index: number;
};
const Block = ({ id, index }: Props): React.JSX.Element | null => {
  const getBlockByIdSelector = useCallback((state: State) => getBlockById(state, { id }), [id]);
  const block = usePageEditorSelector(getBlockByIdSelector);
  const dispatch = usePageEditorDispatch();

  // Selecting the very first layer.
  const onClick = useCallback(() => {
    dispatch(setSelectedLayerId(block.data.layers[0]?.id));
  }, [block.data.layers, dispatch]);

  // Will work only if block has at least one content layer.
  const onDoubleClick = useCallback(() => {
    const firstLayerToSelect = getFirstLayerToSelect(block);

    if (firstLayerToSelect) {
      dispatch(setSelectedLayerId(firstLayerToSelect.id));
    }
  }, [block, dispatch]);

  // Additional data required by Block Modules beyond their internal state.
  const getAdditionalPropsByBlockTypeSelector = useCallback(
    (state: State) => getAdditionalPropsByBlockType(state, block.type),
    [block.type]
  );
  const additionalProps = usePageEditorSelector(getAdditionalPropsByBlockTypeSelector);

  return (
    <Draggable draggableId={block.id.toString()} index={index} isDragDisabled>
      {provided => {
        return (
          <div
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Layers block={block} additionalProps={additionalProps} />
          </div>
        );
      }}
    </Draggable>
  );
};

export default Block;
