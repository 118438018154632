import React from 'react';
import TagsModuleProvider from '~/pages/tags/context';

export function withTagsModuleContext<T>(Component: React.ComponentType<T>): React.ComponentType<T> {
  const displayName = Component.displayName || Component.name || 'Component';

  const EnhancedComponent = (props: React.PropsWithChildren<T>) => (
    <TagsModuleProvider>
      <Component {...props} />
    </TagsModuleProvider>
  );

  EnhancedComponent.displayName = `withTagsModuleContext(${displayName})`;

  return EnhancedComponent;
}
