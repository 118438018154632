import type { Column } from 'react-table';
import { PublishCell } from '~/components/_table/cells/publish';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import type { Page } from '~/types/gists/page';

import { PrimaryCell } from '~/components/_table/cells/primary';
import { formatDate } from '~/helpers/formatters';
import TagsCell from '~/components/_table/cells/tags';
import { TagsFilter } from '~/components/_table/filters/tags';

export const columns: Column<Page>[] = [
  {
    title: 'Page name',
    Cell: PrimaryCell,
    accessor: 'name',
    to: PAGES_ROUTES.root,
    actionTooltip: 'Edit Page',
    primary: true
  },
  {
    title: 'URL',
    accessor: 'url',
    disableFilters: true,
    disableEdit: true,
    width: 160
  },
  {
    title: 'Tags',
    accessor: 'tags',
    Cell: TagsCell,
    disableSortBy: true,
    Filter: TagsFilter
  },
  {
    id: 'isEnabled',
    accessor: 'isEnabled',
    title: 'Publish',
    Cell: PublishCell,
    width: 120
  },
  {
    title: 'Date created',
    accessor: 'createdAt',
    format: formatDate,
    disableFilters: true,
    disableEdit: true,
    width: 160
  },
  {
    title: 'Last updated',
    accessor: 'updatedAt',
    format: formatDate,
    disableFilters: true,
    disableEdit: true,
    width: 160
  }
];
