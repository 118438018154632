import React, { ChangeEvent, ChangeEventHandler, memo, useCallback } from 'react';
import useMountEffect from '~/hooks/use-mount-effect';

import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import { ContentEditableAsCode } from '~/components/contenteditable';

import styles from './styles.module.scss';

const CodeView = (): React.JSX.Element => {
  const { conditionQuery, convertVisualViewToCodeView, setConditionQuery, ruleSets, rules } =
    useConditionBuilderContext();

  useMountEffect(() => {
    if (Object.keys(ruleSets).length !== 0 && Object.keys(rules).length !== 0) {
      convertVisualViewToCodeView();
    }
  });

  const onChangeCodeContent: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setConditionQuery(event.target.value);
    },
    [setConditionQuery]
  );

  return (
    <ContentEditableAsCode
      name='conditionQuery'
      className={styles.container}
      value={conditionQuery}
      onChange={onChangeCodeContent}
    />
  );
};

export default memo(CodeView);
