import { useCallback, useState } from 'react';
import { UseCoachPopover, useCoachPopover } from '~/components/_layout/coach/coach-popover';

type UseCoachControllerProps = {
  unsubscribePrevious(): void;
  unsubscribeCurrent(value: boolean): void;
};

type UseCoachControllerReturnType = UseCoachPopover & {
  onOpenNewCoachContent(): void;
  onCloseCoachContent(): void;
};

export const useSlotController = (props: UseCoachControllerProps): UseCoachControllerReturnType => {
  const { unsubscribePrevious, unsubscribeCurrent } = props;

  const [showCoachPopover, setShowCoachPopover] = useState<boolean>(false);

  const { CoachPopover, ...coachProps } = useCoachPopover({
    disabled: !showCoachPopover
  });

  const onOpenNewCoachContent = useCallback(() => {
    unsubscribePrevious();
    setShowCoachPopover(true);
  }, [unsubscribePrevious]);

  const onCloseCoachContent = useCallback(() => {
    coachProps.unsubscribe();
    unsubscribeCurrent(false);
    setShowCoachPopover(false);
  }, [coachProps, unsubscribeCurrent]);

  return {
    ...coachProps,
    CoachPopover,
    onOpenNewCoachContent,
    onCloseCoachContent
  };
};
