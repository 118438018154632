import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { useLocation } from 'react-router-dom';

const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

export default usePageTracking;
