import { UTIL_COMPONENT_TYPES } from '@life-moments/lifehub-components';

export enum BREAKPOINT_LABELS {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

export enum WIDTH_LIMITS {
  min = 375,
  max = 5000
}

export enum ZOOM_VALUES {
  half = 50,
  threeQuarters = 75,
  full = 100,
  oneAndQuarter = 125,
  oneAndHalf = 150,
  double = 200
}

export const CONTAINERS = [UTIL_COMPONENT_TYPES.body];

export enum DISPLAY_CONDITION_RULES {
  SHOW = 'show',
  HIDE = 'hide'
}
