import React, { useCallback } from 'react';
import { Select, SelectItem } from '~/components/_inputs/select';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';

import styles from './styles.module.scss';
import { FormLabel } from '~/components/_form/components/label';

const VALUES_TO_LABELS: Record<string, string> = {
  int: 'Integer',
  text: 'Text',
  json: 'JSON',
  date: 'Date',
  bool: 'Boolean',
  float: 'Float'
};

const SELECT_ITEMS: Array<SelectItem<string>> = [
  {
    value: 'int',
    label: VALUES_TO_LABELS['int'],
    id: 'int'
  },
  {
    value: 'text',
    label: VALUES_TO_LABELS['text'],
    id: 'text'
  },
  {
    value: 'json',
    label: VALUES_TO_LABELS['json'],
    id: 'json'
  },
  {
    value: 'date',
    label: VALUES_TO_LABELS['date'],
    id: 'date'
  },
  {
    value: 'bool',
    label: VALUES_TO_LABELS['bool'],
    id: 'bool'
  },
  {
    value: 'float',
    label: VALUES_TO_LABELS['float'],
    id: 'float'
  }
];

const valueToSelectItem = <T extends string>(value: T): SelectItem<T> => ({
  value,
  id: value,
  label: VALUES_TO_LABELS[value]
});

type Props = {
  value: string;
  onChange: HandleChange;
};

const DataType = ({ value, onChange }: Props): React.JSX.Element => {
  const { theme } = useTheme();

  const onEdit = useCallback(
    () =>
      ({ value: newValue }) =>
        onChange('type', newValue),
    [onChange]
  );

  return (
    <div className={styles.container}>
      <FormLabel label='Data type' placement='top'>
        <Select
          name='data-type'
          items={SELECT_ITEMS}
          value={value ? valueToSelectItem(value) : undefined}
          containerClassName={styles.select}
          className={classnames(styles.value, styles[theme])}
          onChange={onEdit}
          placeholder='Choose a type'
        />
      </FormLabel>
    </div>
  );
};

export default DataType;
