import { ColumnInstance, TableDataLike } from 'react-table';
import { isNil } from '~/helpers/common';

type Offset = {
  left: number[];
  right: number[];
};

export const calculateOffset = <Data extends TableDataLike>(columns: ColumnInstance<Data>[]): Offset => {
  const getOffset = (items: ColumnInstance<Data>[]) =>
    items.reduce((acc: number[], { width }, index): number[] => {
      const previousItem = acc[index - 1];

      if (!isNil(previousItem)) {
        acc.push(previousItem + Number(width));
      } else {
        acc.push(Number(width));
      }
      return acc;
    }, []);

  return {
    left: [0, ...getOffset(columns)],
    right: [0, ...getOffset(columns.reverse())].reverse()
  };
};
