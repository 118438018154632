import React, { KeyboardEvent, useCallback } from 'react';
import { ContentBlock, EditorState, EntityInstance, SelectionState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import DOMPurify from 'dompurify';
import { RenderConfig, stateToHTML } from 'draft-js-export-html';
import { convertFromHTML } from 'draft-convert';

import { setRichEditorState } from '~/pages/pages/edit/context/store/actions';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { getRichEditorState } from '~/pages/pages/edit/context/store/selectors';
import useMountEffect from '~/hooks/use-mount-effect';
import {
  DEFAULT_BLOCK_TAG,
  findLinkEntitiesStrategy,
  REPLACE_NEW_LINE_VALUE_MAP,
  replaceNewLineSymbols,
  replaceWhitespacesBetweenTags
} from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor/utils';
import Link from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor/components/link';

import styles from './styles.module.scss';
import Figcaption from '~/pages/pages/edit/components/preview-zone/components/block/components/rich-editor/components/figcaption';

const CUSTOM_DECORATORS = [
  {
    strategy: findLinkEntitiesStrategy,
    component: Link
  }
];
const customBlockRenderFunc = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();

  if (type === 'FIGCAPTION') {
    return {
      component: Figcaption,
      editable: true,
      props: {}
    };
  }
  return null;
};

type Props = {
  content: string;
  defaultBlockTag: keyof typeof DEFAULT_BLOCK_TAG;
  onContentChange: (content: string) => void;
  handleEnterKeyPress?: () => void;
};

const RichEditor = ({ onContentChange, content, defaultBlockTag, handleEnterKeyPress }: Props): React.JSX.Element => {
  const dispatch = usePageEditorDispatch();

  const richEditorState = usePageEditorSelector(getRichEditorState);

  useMountEffect(() => {
    if (content) {
      const contentState = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity('LINK', 'MUTABLE', {
              url: node.href,
              noFollow: node.rel === 'nofollow',
              target: node.target ?? '_self',
              ariaLabel: node.ariaLabel
            });
          }
          if (nodeName === 'figcaption') {
            return createEntity('FIGCAPTION', 'MUTABLE', {});
          }
          return undefined;
        },
        htmlToBlock: nodeName => {
          if (nodeName === 'figcaption') {
            return { type: 'FIGCAPTION', data: {} };
          }

          return undefined;
        }
      })(content);
      const initialEditorState = EditorState.createWithContent(contentState);

      const initialSelection = new SelectionState({
        anchorKey: contentState.getLastBlock().getKey(),
        anchorOffset: contentState.getLastBlock().getLength(),
        focusKey: contentState.getLastBlock().getKey(),
        focusOffset: contentState.getLastBlock().getText().length
      });

      const initialEditorStateWithSelection = EditorState.forceSelection(initialEditorState, initialSelection);
      dispatch(setRichEditorState(initialEditorStateWithSelection));
    }
  });

  const onEditorStateChange = useCallback(
    (newEditorState: EditorState) => dispatch(setRichEditorState(newEditorState)),
    [dispatch]
  );

  const onContentStateChange = useCallback(() => {
    const html = stateToHTML(richEditorState.getCurrentContent(), {
      defaultBlockTag,
      entityStyleFn: (entity: EntityInstance): RenderConfig | undefined => {
        if (entity.getType() === 'LINK') {
          const { url, target, ariaLabel, noFollow } = entity.getData();

          return {
            element: 'a',
            attributes: {
              href: url,
              target,
              'aria-label': ariaLabel ? ariaLabel : undefined,
              rel: noFollow ? 'nofollow' : undefined
            }
          };
        }
        return undefined;
      }
    });

    const modifiedHTML = replaceWhitespacesBetweenTags(
      replaceNewLineSymbols(html, REPLACE_NEW_LINE_VALUE_MAP[defaultBlockTag])
    );

    const sanitizedContent = DOMPurify.sanitize(modifiedHTML, {
      ADD_ATTR: ['target']
    });

    if (sanitizedContent !== content) {
      onContentChange(sanitizedContent);
    }
  }, [richEditorState, content, onContentChange, defaultBlockTag]);

  const handleReturn = useCallback(
    (keyboardEvent: KeyboardEvent) => {
      const isSoftNewLine = keyboardEvent.key === 'Enter' && keyboardEvent.shiftKey;

      if (!isSoftNewLine && handleEnterKeyPress) {
        handleEnterKeyPress();
        return true;
      }

      return false;
    },
    [handleEnterKeyPress]
  );

  return (
    <Editor
      editorState={richEditorState}
      onEditorStateChange={onEditorStateChange}
      onChange={onContentStateChange}
      toolbarHidden={true}
      editorClassName={styles.container}
      customDecorators={CUSTOM_DECORATORS}
      stripPastedStyles={true}
      handleReturn={handleReturn}
      wrapperClassName={styles.layer}
      customBlockRenderFunc={customBlockRenderFunc}
    />
  );
};

export default RichEditor;
