import React, { memo, useMemo } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided
} from 'react-beautiful-dnd';

import { TagsTreeItem, TagsTreeLayout } from '~/pages/tags/components/TagsTree';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';

const WithDroppable = ({
  shouldWrap,
  children,
  droppableId
}: React.PropsWithChildren<{ shouldWrap: boolean; droppableId: string }>): React.JSX.Element => {
  if (!shouldWrap) {
    return <>{children}</>;
  }

  return (
    <Droppable droppableId={droppableId} type={droppableId}>
      {(providedDroppable: DroppableProvided) => (
        <div ref={providedDroppable.innerRef}>
          {children}
          {providedDroppable.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const WithDraggable = ({
  shouldWrap,
  children,
  draggableId,
  index,
  isOnlyVirtualTag
}: {
  shouldWrap: boolean;
  draggableId: number;
  index: number;
  isOnlyVirtualTag: boolean;
  children: (draggableSnapshot?: DraggableStateSnapshot) => React.ReactNode;
}): React.JSX.Element => {
  if (!shouldWrap) {
    return <>{children()}</>;
  }

  return (
    <Draggable draggableId={draggableId.toString()} index={index} key={draggableId} isDragDisabled={isOnlyVirtualTag}>
      {(providedDraggable: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
        >
          {children(draggableSnapshot)}
        </div>
      )}
    </Draggable>
  );
};

type Props = {
  onSelect: (ids: number[]) => void;
  onExpand: (ids: number) => void;
  parentId: number;
  isSelected: (id: number) => boolean;
  isExpanded: (id: number) => boolean;
  multiSelect?: boolean;
  enableDND?: boolean;
  selectedTagId?: number;
};

const TagsTreeDescendants = (props: Props): React.JSX.Element => {
  const { onExpand, onSelect, isSelected, isExpanded, parentId, multiSelect, enableDND = false, selectedTagId } = props;
  const { state } = useTagsModuleContext();

  const descendantsTags = useMemo(
    () => state.virtualTags.filter(tag => tag.parentId === parentId && tag.id !== selectedTagId),
    [parentId, state.virtualTags, selectedTagId]
  );

  return (
    <TagsTreeLayout.DescendantsWrapper>
      <WithDroppable shouldWrap={enableDND} droppableId={`droppable-${parentId}`}>
        {descendantsTags.map((descendantsTag, index) => (
          <WithDraggable
            shouldWrap={enableDND}
            draggableId={descendantsTag.id}
            index={index}
            key={descendantsTag.id}
            isOnlyVirtualTag={!state.originalTags.some(originalTag => originalTag.id === descendantsTag.id)}
          >
            {draggableSnapshot => (
              <TagsTreeLayout.DescendantItem>
                <TagsTreeItem
                  tagId={descendantsTag.id}
                  onSelect={onSelect}
                  isSelected={isSelected(descendantsTag.id)}
                  isExpanded={isExpanded(descendantsTag.id)}
                  onExpand={onExpand}
                  multiSelect={multiSelect}
                  shouldRenderDNDIcon={
                    enableDND && state.originalTags.some(originalTag => originalTag.id === descendantsTag.id)
                  }
                  selectedTagId={selectedTagId}
                  isDragging={draggableSnapshot?.isDragging}
                >
                  <TagsTreeDescendants {...props} parentId={descendantsTag.id} />
                </TagsTreeItem>
              </TagsTreeLayout.DescendantItem>
            )}
          </WithDraggable>
        ))}
      </WithDroppable>
    </TagsTreeLayout.DescendantsWrapper>
  );
};

export default memo(TagsTreeDescendants);
