import { BUILDER_TYPES } from '~/pages/cohorts/edit/ConditionBuilder/constants';
import { RULE, RULE_SET } from '~/pages/cohorts/edit/ConditionBuilder/context/reducer';

const wrapInSingleQuotesByCondition = (condition, field) => {
  if (condition) {
    return `'${field}'`;
  }

  return field;
};

const parseVisualModeStructureToConditionQuery = (
  ruleSetsLookup: Record<string, RULE_SET>,
  rulesLookup: { [key: string]: RULE }
): string =>
  Object.keys(ruleSetsLookup).reduce((result, ruleSetId) => {
    const { condition: rulesetCondition, ruleIds } = ruleSetsLookup[ruleSetId];

    const rules = ruleIds.map(ruleId => rulesLookup[ruleId]);

    const conditionGroup = rules.reduce((rulesAcc, rule) => {
      const { condition: ruleCondition, object, operator, value, type, computable } = rule;

      if (!value) {
        return rulesAcc;
      }

      return (
        rulesAcc +
        `${ruleCondition && ' ' + ruleCondition + ' '}` +
        wrapInSingleQuotesByCondition(!computable && type == BUILDER_TYPES.TEXT, `%${object}%`) +
        ' ' +
        operator +
        ' ' +
        wrapInSingleQuotesByCondition(type === BUILDER_TYPES.TEXT, value)
      );
    }, '');

    return (
      result +
      `${conditionGroup && rulesetCondition && ' ' + rulesetCondition + ' '}` +
      `${conditionGroup && `(${conditionGroup})`}`
    );
  }, '');

export default parseVisualModeStructureToConditionQuery;
