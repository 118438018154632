import { Column, ColumnInstance, Hooks, TableDataLike } from 'react-table';

const useAllColumns = <Data extends TableDataLike>(allColumns: ColumnInstance<Data>[]): Column<Data>[] => {
  const firstStickyRightColumnIndex = allColumns.findIndex(column => column.sticky === 'right');

  return firstStickyRightColumnIndex === -1
    ? allColumns
    : [
        ...allColumns.slice(0, firstStickyRightColumnIndex),
        {
          id: 'empty',
          disableResizing: true,
          disableGroupBy: true,
          disableSortBy: true,
          disableEdit: true,
          disableFilters: true,
          disableGlobalFilter: true,
          title: '',
          empty: true
        },
        ...allColumns.slice(firstStickyRightColumnIndex)
      ];
};

export const useEmptyColumn = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.allColumns.push(useAllColumns);
};

useEmptyColumn.pluginName = 'useEmptyColumn';
