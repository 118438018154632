import { affiliates } from '~/api/affiliates';
import { onboarding } from './onboarding';
import { cohorts } from './cohorts';
import { conditions } from './conditions';
import { pushNotifications } from './push-notifications';
import { tags } from './tags';
import { analytics } from '~/api/analytics';
import { attributes } from './attributes';
import { fonts, icons, images } from '~/api/files';
import { user } from './user';
import { blocks } from '~/api/blocks';
import { pages } from './pages';

export const api = {
  onboarding,
  cohorts,
  conditions,
  pushNotifications,
  affiliates,
  tags,
  analytics,
  attributes,
  images,
  icons,
  fonts,
  user,
  blocks,
  pages
};
