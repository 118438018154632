import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import type { History } from 'history';
import { FormikHelpers } from 'formik/dist/types';
import { CustomError } from '~/helpers/common/custom-error';

import useDocumentTitle from '~/hooks/use-document-title';

import { TextField } from '~/components/_form/fields/text';
import { ButtonField } from '~/components/_form/fields/button';
import { OnboardingTemplate } from '~/templates/onboarding';
import { onboarding } from '~/api/onboarding';
import { ONBOARDING_ROUTES } from '~/routes/constants';

const initialValues = {
  code: ''
};

const MESSAGE = ['I’ve emailed you a security code.', 'Enter it here to validate your new password.'];

type Values = { code: string };

export type ConfirmProps = {
  onSubmit: (history: History) => (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
  returnBackPath: string;
  sendNewCodeMethod: string;
};

export const ConfirmBase = ({ onSubmit, returnBackPath, sendNewCodeMethod }: ConfirmProps): React.JSX.Element => {
  const history = useHistory();

  useDocumentTitle('Welcome to LifeHub', '%s');

  const onNewCode =
    (
      setFieldError: (field: string, message: string) => void,
      setFieldTouched: (field: string, isTouched: boolean) => void
    ) =>
    async () => {
      setFieldTouched('code', true);

      try {
        const email = localStorage.getItem('email');

        if (email) {
          await onboarding[sendNewCodeMethod]({ email });

          history.push(ONBOARDING_ROUTES.newCodeSent);
        } else {
          history.push(returnBackPath);
        }
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        setFieldError('code', error.message);
      }
    };

  return (
    <OnboardingTemplate message={MESSAGE} backButton>
      <Formik initialValues={initialValues} onSubmit={onSubmit(history)}>
        {({ isValid, dirty, isSubmitting, setFieldError, setFieldTouched }) => (
          <Form>
            <TextField name='code' label='Security code' />
            <ButtonField disabled={!(isValid && dirty) || isSubmitting} type='submit'>
              Continue
            </ButtonField>
            <ButtonField onClick={onNewCode(setFieldError, setFieldTouched)} is='minor'>
              Send me another code
            </ButtonField>
          </Form>
        )}
      </Formik>
    </OnboardingTemplate>
  );
};
