import React from 'react';
import { connect, Field, FormikProps } from 'formik';
import { FormField } from '~/components/_form/components/field';
import { ExtraProps as LabelProps, FormLabel } from '~/components/_form/components/label';
import { FormError } from '~/components/_form/components/error';
import { Input, Props as InputProps } from '~/components/_inputs/input';

import styles from './text-field.module.scss';

type Props = {
  name: string;
  label: string;
  error?: string;
  labelProps?: LabelProps;
  inputProps?: InputProps;
  formik?: FormikProps<any>;
};

export const Component = ({ name, label, labelProps, inputProps, formik }: Props): React.JSX.Element => (
  <FormField className={styles.container}>
    <FormLabel {...labelProps} label={label}>
      <Field as={Input} {...inputProps} onChange={formik?.setFieldValue} type='text' name={name} />
    </FormLabel>
    <FormError name={name} />
  </FormField>
);

export const TextField = connect(Component);
