import React, { useCallback, useMemo } from 'react';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Button } from '~/components/button';

import { api } from '~/api';
import { CustomError } from '~/helpers/common/custom-error';
import useTagsModuleContext from '~/pages/tags/hooks/useTagsModuleContext';
import { saveTags, setErrorMessage, setSelectedTagsById } from '~/pages/tags/context/store/actions';
import { useConfirm } from '~/components/_layout/popovers/confirm';
import { PromiseShim } from '~/helpers/shims/promise-shim';
import { FlatTag } from '~/types/gists/tag';

const getConfirmMessage = (tags: FlatTag[]) => {
  if (tags.length > 1) {
    return `Are you sure you want to delete the tags named ${tags
      .map(tag => `"${tag.name}"`)
      .join(', ')}? All descendant tags will also be deleted.`;
  }

  const getDescendantsPlural = descendants =>
    `${descendants.length > 1 ? `${descendants.length} descendants` : '1 descendant'} `;

  const descendantsPart = tags[0].descendants.length ? ` and its ${getDescendantsPlural(tags[0].descendants)}` : '';

  return `Are you sure you want to delete the tag named ${tags[0].name} ${descendantsPart}?`;
};

const DeleteTags = (): React.JSX.Element | null => {
  const { state, dispatch } = useTagsModuleContext();

  const { Confirm, ...confirmProps } = useConfirm();

  const handleConfirm = useCallback(
    async (setLoading, setReady) => {
      setLoading();

      try {
        await api.tags.deleteTagsByIds({ ids: state.selectedTagsIds.join(',') });
        const tags = await api.tags.getTagsFlatTree();

        await PromiseShim.delay(1000);

        dispatch(saveTags(tags));
        dispatch(setSelectedTagsById(tags.length ? [tags[0].id] : []));
      } catch (unknownError) {
        const error = new CustomError(unknownError);
        console.error(error);
        dispatch(setErrorMessage([error.message]));
      } finally {
        await setReady();
      }
    },
    [dispatch, state.selectedTagsIds]
  );

  const selectedTags = useMemo(
    () => state.originalTags.filter(tag => state.selectedTagsIds.includes(tag.id)),
    [state.selectedTagsIds, state.originalTags]
  );

  if (!selectedTags?.length) {
    return null;
  }

  return (
    <TopBarTool>
      <TooltipOnHover title={`Delete ${selectedTags.length > 1 ? 'tags' : 'tag'}`}>
        <Confirm
          message={[
            <span key='permanently-delete'>{getConfirmMessage(selectedTags)}</span>,
            'This cannot be undone...'
          ]}
          onConfirm={handleConfirm}
          cancelText={'Keep it'}
          confirmText={'Delete it'}
          {...confirmProps}
        >
          {toggle => <Button icon='bin' is='minor' onClick={() => toggle(true)} />}
        </Confirm>
      </TooltipOnHover>
    </TopBarTool>
  );
};

export default DeleteTags;
