import React from 'react';
import useDocumentTitle from '~/hooks/use-document-title';
import { Slot } from '~/components/slots';
import { Breadcrumb } from '~/components/_layout/breadcrumb';
import BlocksTable from '~/pages/blocks/components/blocks-table';

const Blocks = (): React.JSX.Element => {
  useDocumentTitle('Blocks');

  return (
    <>
      <Slot name='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item label='Blocks' />
        </Breadcrumb>
      </Slot>
      <BlocksTable />
    </>
  );
};

export default Blocks;
