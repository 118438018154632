import { TableApi, TableIdentifier } from '~/components/_table/types';

type GistToIdentifier = {
  [key in TableApi]: TableIdentifier;
};

//map names of FE apis to names that BE expects
export const gistToIdentifier: GistToIdentifier = {
  pushNotifications: 'timedMessage',
  cohorts: 'cohort',
  attributes: 'userAttributeSettings',
  images: 'file',
  icons: 'file',
  fonts: 'file',
  blocks: 'blocks',
  datasets: 'datasets',
  pages: 'page'
};
