import React from 'react';
import BlocksList from '~/pages/pages/edit/components/left-sidebar/components/blocks-panel/components/blocks-list';
import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getAvailableBlocks } from '~/pages/pages/edit/context/store/selectors';

const BlocksContent = (): React.JSX.Element => {
  const availableBlocks = usePageEditorSelector(getAvailableBlocks);

  return <BlocksList blocks={availableBlocks} />;
};

export default BlocksContent;
