import classnames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AffiliateLogo } from '~/components/_layout/affiliate-logo';
import { Coach } from '~/components/_layout/coach';
import { HomeButton } from '~/components/_layout/home-button';
import MainMenu from '~/components/_layout/main-menu';
import { Column, Grid } from '~/components/grid';
import { usePopover } from '~/components/popover';
import { useSubscribe } from '~/components/slots';
import { useTheme } from '~/components/theme';
import { UserService } from '~/services/user-service';
import { setIsMainMenuOpened } from '~/store/reducers/mainMenu';

import { TopBarTool } from './tool';
import styles from './top-bar.module.scss';

import { EnvironmentIndicator } from '../environment-indicator';
import useOnboardingRoute from '~/pages/onboarding/hooks/useOnboardingRoute';
import useOpenProductInNewTab from '~/hooks/use-open-product-in-new-tab';

const TopBar = (): React.JSX.Element => {
  const [header, setHeaderRef] = useState<HTMLElement | null>(null);

  const { theme } = useTheme();

  const onHeaderRef = useCallback(node => {
    setHeaderRef(node);
  }, []);

  const subscribeActions = useSubscribe('page-actions');
  const subscribeBreadcrumb = useSubscribe('breadcrumb');
  const subscribeTabs = useSubscribe('tabs');

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isHomeRoute = pathname === '/';
  const isOnboardingRoute = useOnboardingRoute();

  const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, {
    strategy: 'fixed',
    placement: 'bottom-start'
  });

  const onToggleMainMenu = useCallback(
    value => {
      toggle(value);
      dispatch(setIsMainMenuOpened(value));
    },
    [dispatch, toggle]
  );

  const openProductInNewTab = useOpenProductInNewTab();

  return (
    <div ref={onHeaderRef} className={classnames(styles.container, styles[theme])}>
      <Grid>
        <Column span='2' flex justify='start'>
          {UserService.isAuthenticated() && (
            <>
              <TopBarTool>
                <MainMenu
                  anchor={header}
                  show={show}
                  onPopoverRef={onPopoverRef}
                  onTriggerRef={onTriggerRef}
                  toggle={onToggleMainMenu}
                />
              </TopBarTool>
              {!show && (
                <>
                  {!isHomeRoute && !isOnboardingRoute && (
                    <>
                      <TopBarTool>
                        <HomeButton />
                      </TopBarTool>
                      <TopBarTool>
                        <div onClick={openProductInNewTab} className={styles['product-link']}>
                          <AffiliateLogo className={styles.logo} />
                        </div>
                      </TopBarTool>
                      <TopBarTool>
                        <EnvironmentIndicator />
                      </TopBarTool>
                    </>
                  )}
                  <TopBarTool ref={subscribeBreadcrumb} />
                  <TopBarTool ref={subscribeTabs} />
                </>
              )}
            </>
          )}
        </Column>
        <Column span='3+1' flex justify='center'>
          <TopBarTool>
            <Coach />
          </TopBarTool>
        </Column>
        <Column span='4+2' flex justify='end'>
          {!show && <TopBarTool ref={subscribeActions} className={styles.actions} />}
        </Column>
      </Grid>
    </div>
  );
};

export default memo(TopBar);
