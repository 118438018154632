export const replaceAttributes = (inputString: string, newSrcValue: string, newAltValue: string): string => {
  const imgRegex = /<img[^>]*>/;

  const imgMatch = inputString.match(imgRegex);

  if (imgMatch) {
    const imgTag = imgMatch[0];

    // Check if 'src' attribute is present
    if (!imgTag.includes('src')) {
      // If 'src' attribute is not present, add it
      inputString = inputString.replace('<img', `<img src="${newSrcValue}"`);
    } else {
      // If 'src' attribute is present, replace its value
      inputString = inputString.replace(/(src\s*=\s*['"])([^'"]*)['"]/i, `$1${newSrcValue}"`);
    }

    // Check if 'alt' attribute is present
    if (!imgTag.includes('alt')) {
      // If 'alt' attribute is not present, add it
      inputString = inputString.replace('<img', `<img alt="${newAltValue}"`);
    } else {
      // If 'alt' attribute is present, replace its value
      inputString = inputString.replace(/(alt\s*=\s*['"])([^'"]*)['"]/i, `$1${newAltValue}"`);
    }
  }

  return inputString;
};
