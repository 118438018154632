import { AllResponse } from '~/api/affiliates';
import { Affiliates } from '~/types/gists/affiliates';
import { baseApiUrls } from '~/api/utils';

export function groupAffiliatesByEnvironment(responses: AllResponse[]): Affiliates {
  const groupedData: Affiliates = {};

  responses.forEach((response, index) => {
    const environment = Object.keys(baseApiUrls)[index] as Environment;
    const items = response.items;

    items.forEach(item => {
      const key = item.key;

      if (!groupedData[key]) {
        groupedData[key] = {
          affiliateInfo: item,
          environments: []
        };
      }

      groupedData[key].environments.push(environment);
    });
  });

  return groupedData;
}
