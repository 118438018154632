import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useTheme } from '~/components/theme';
import { Icon } from './icon';

import styles from './radio.module.scss';

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const Radio = ({ id, checked = false, ...props }: Props): React.JSX.Element => {
  const { theme } = useTheme();

  const viewBox = theme === 'dark' && checked ? '0.5 1 19.5 19' : '0 0 20 21';

  return (
    <div className={classnames(styles.container, { [styles.checked]: checked }, styles[theme])}>
      <input id={id} type='radio' className={styles.input} checked={checked} {...props} />
      <label htmlFor={id} className={styles.label}>
        <Icon className={styles.icon} viewBox={viewBox} />
      </label>
    </div>
  );
};
