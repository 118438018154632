import { post } from '~/api/utils';
import { createTableApi } from '~/api/utils/create-table-api';

import type { Cohort } from '~/types/gists/cohort';

const refreshCohort = async (id: number): Promise<Cohort> => post<Cohort, undefined>(`/cohorts/${id}/reassign-users`);

export const cohorts = {
  refreshCohort,
  ...createTableApi('/cohorts')
};
