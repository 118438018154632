import React, { useMemo } from 'react';
import { StringShim } from '~/helpers/shims/string-shim';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { useSegmentedButton } from '~/components/segmented-button';

import styles from './mode-action.module.scss';

export type Mode = 'edit' | 'stats';

const MODES: Record<'create' | 'edit', Mode[]> = {
  create: ['edit'],
  edit: ['edit', 'stats']
};

type Props = {
  onChange?: (value: Mode) => void;
  defaultValue: Mode;
  isNew: boolean;
};

export const ModeAction = ({ onChange, isNew }: Props): React.JSX.Element => {
  const { active, setActive, Container, Segment } = useSegmentedButton<Mode>('edit');

  const handleClick = mode => () => {
    setActive(mode);

    if (onChange) {
      onChange(mode);
    }
  };

  const modes = useMemo(() => (isNew ? MODES.create : MODES.edit), [isNew]);

  return (
    <TopBarTool>
      <Container className={styles.container}>
        {modes.map((mode, index) => (
          <Segment
            key={mode}
            active={mode === active}
            onClick={handleClick(mode)}
            first={index === 0}
            last={index === modes.length - 1}
          >
            {StringShim.capitalize(mode)}
          </Segment>
        ))}
      </Container>
    </TopBarTool>
  );
};
