import { useCallback } from 'react';
import { ActionType, Hooks, TableDataLike, TableInstance, TableState } from 'react-table';
// React Table public utils is a JSX file
// By the time when this comment was written the current tsconfig
// is not allowing to parse JSX files.
// import { actions } from 'react-table/~/publicUtils';

export type UseLoadingInstanceProps = {
  toggleTableLoading: (state: boolean) => void;
};

export type UseLoadingColumnProps = {
  toggleTableLoading: (state: boolean) => void;
};

export type UseLoadingState = {
  loading: boolean;
};

export type UseLoadingActions = {
  toggleTableLoading: { type: 'setLoading'; isLoading: boolean };
};

const ACTIONS = {
  // TODO: Find a way to export utils actions to preserve consistence
  // init: actions.init,
  init: 'init',
  setLoading: 'setLoading'
};

const useInstance = <Data extends TableDataLike>(instance: TableInstance<Data>) => {
  const { columns, dispatch } = instance;

  const toggleTableLoading = useCallback(
    (state: boolean) => {
      setTimeout(() => {
        dispatch({ type: ACTIONS.setLoading, isLoading: state });
      }, 0);
    },
    [dispatch]
  );

  columns.forEach(column => {
    Object.assign(column, {
      toggleTableLoading
    });
  });

  Object.assign(instance, {
    toggleTableLoading
  });
};

const useReducer = <Data extends TableDataLike>(state: TableState<Data>, action: ActionType): TableState<Data> => {
  if (action.type === ACTIONS.init) {
    return {
      ...state,
      loading: false
    };
  }

  if (action.type === ACTIONS.setLoading) {
    const { isLoading } = action as UseLoadingActions['toggleTableLoading'];

    return {
      ...state,
      loading: isLoading
    };
  }

  return state;
};

export const useLoading = <Data extends TableDataLike>(hooks: Hooks<Data>): void => {
  hooks.stateReducers.push(useReducer);
  hooks.useInstance.push(useInstance);
};

useLoading.pluginName = 'useLoading';
