export const ONBOARDING_ROUTES = {
  entry: '/onboarding',
  greeting: '/onboarding/greeting',
  signInEmail: '/onboarding/sign-in',
  signInPassword: '/onboarding/sign-in/password',
  signInLoggingIn: '/onboarding/sign-in/logging-in',
  signUpEmail: '/onboarding/sign-up',
  signUpPassword: '/onboarding/sign-up/password',
  signUpConfirm: '/onboarding/sign-up/confirm',
  forgotPasswordUpEmail: '/onboarding/forgot-password',
  forgotPasswordUpPassword: '/onboarding/forgot-password/password',
  forgotPasswordUpConfirm: '/onboarding/forgot-password/confirm',
  newCodeSent: '/onboarding/new-code-sent',
  setupPermissions: '/onboarding/setup-permissions',
  smsMfa: '/onboarding/sms-mfa',
  contactTeam: '/onboarding/contact-team',
  error: '/onboarding/error',
  phoneSetup: '/onboarding/phone-setup',
  phoneVerify: '/onboarding/phone-verify',
  mfaSetupComplete: '/onboarding/mfa-setup-complete'
};

// if some flow must persist switch state between nesting routing.
// put it to matches array
export const FLOWS = [ONBOARDING_ROUTES.entry];

export const ONBOARDING = [...Object.values(ONBOARDING_ROUTES)];
