import { Route } from '~/routes/types';
import { COHORTS_ROUTES } from '~/routes/private/cohorts/constants';
import { Cohorts } from '~/pages/cohorts';
import { EditCohort } from '~/pages/cohorts/edit';

export const cohorts: Route[] = [
  {
    to: COHORTS_ROUTES.root,
    exact: true,
    component: Cohorts
  },
  {
    to: COHORTS_ROUTES.edit,
    exact: true,
    component: EditCohort
  }
];
