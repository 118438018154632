import React from 'react';
import { CellProps, TableDataLike } from 'react-table';
import { Checkbox } from '~/components/_inputs/checkbox';

import styles from './checkbox-cell.module.scss';

export const CheckboxCell = <Data extends TableDataLike>({ row }: CellProps<Data>): React.JSX.Element => {
  return (
    <div className={styles.container}>
      <Checkbox id={`select-item-${row.id}`} {...row.getToggleRowSelectedProps()} />
    </div>
  );
};
