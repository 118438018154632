import React, { useCallback } from 'react';

import usePageEditorSelector from '~/pages/pages/edit/context/hooks/use-page-editor-selector';
import { getContainerBlocks } from '~/pages/pages/edit/context/store/selectors';
import { State } from '~/pages/pages/edit/context/store/types';
import Block from '~/pages/pages/edit/components/preview-zone/components/block';

type Props = {
  containerId: string;
};

const Container = ({ containerId }: Props): React.JSX.Element => {
  const getContainerBlocksSelector = useCallback(
    (state: State) => getContainerBlocks(state, { containerId }),
    [containerId]
  );

  const containerBlocks = usePageEditorSelector(getContainerBlocksSelector);

  return (
    <>
      {containerBlocks.map((blockId, index) => (
        <Block id={blockId} key={blockId} index={index} />
      ))}
    </>
  );
};

export default Container;
