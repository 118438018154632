export type SlotNames = 'coach' | 'page-actions' | 'left-sidebar' | 'right-sidebar' | 'breadcrumb' | 'tabs';

export enum ActionType {
  AddSlot
}

interface AddSlot {
  type: ActionType.AddSlot;
  payload: { name: SlotNames; ref: HTMLElement | null };
}

export type State = Record<SlotNames, HTMLElement | null>;
export type Actions = AddSlot;

export const initialState: State = {
  coach: null,
  'left-sidebar': null,
  'right-sidebar': null,
  'page-actions': null,
  breadcrumb: null,
  tabs: null
};

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ActionType.AddSlot: {
      const { name, ref } = action.payload;

      return {
        ...state,
        [name]: ref
      };
    }
    default:
      return state;
  }
};
