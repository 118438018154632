import React, { ComponentType } from 'react';
import { TableApi } from '~/components/_table/types';
import { Callback, EditTemplateState, useEditTemplate } from '~/templates/edit/use-editable-template';
import { useCoachPopover } from '~/components/_layout/coach/coach-popover';

type Props<T> = {
  gist: TableApi;
  defaultItem: Partial<T>;
  loadingMessage: string;
  callback?: Callback<T>;
};

export const withEditTemplate =
  <T extends HasId>({ gist, defaultItem, loadingMessage, callback }: Props<T>) =>
  (Component: ComponentType<EditTemplateState<T>>) =>
  (): React.JSX.Element => {
    const props = useEditTemplate<T>({
      defaultItem,
      gist,
      callback
    });

    const {
      item: { id },
      isNew,
      loading
    } = props;

    const disabled = isNew || id !== void 0;

    const { CoachPopover: Loading, ...loadingProps } = useCoachPopover({
      timeout: 1000,
      loading,
      disabled
    });

    if (loadingProps.show || loading) {
      return <Loading message={loadingMessage} {...loadingProps} />;
    }

    return <Component {...props} />;
  };
