import { Options } from '@popperjs/core';
import React, { memo, useCallback } from 'react';
import { encodeQueryParams } from '~/api/utils';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { TopBarTool } from '~/components/_layout/top-bar/tool';
import { Button } from '~/components/button';
import { TooltipOnHover } from '~/components/_layout/popovers/tooltip/on-hover';
import { Popover, usePopover } from '~/components/popover';
import { Portal } from '~/components/portal';
import { useTheme } from '~/components/theme';
import styles from '~/components/_layout/popovers/tooltip/tooltip-popover.module.scss';

const POPPER: Partial<Options> = {
  placement: 'bottom-start',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start']
      }
    }
  ]
};

type Props<T> = {
  rootRoute: string;
  isUnsavedChanges: boolean;
  onClone(): Promise<void | T>;
};

export const DuplicateCurrentAction = memo(
  <T extends HasId>({ isUnsavedChanges, rootRoute, onClone }: Props<T>): React.JSX.Element | null => {
    const { affiliate, env } = useTheme();

    const { show, onPopoverRef, onTriggerRef, toggle } = usePopover(false, POPPER);

    const redirectToNewItem = useCallback(
      (id: number) => window.open(encodeQueryParams(`${rootRoute}/${id}`, { affiliate, env })),
      [affiliate, env, rootRoute]
    );

    const closePopover = () => toggle(false);

    const openPopover = useCallback(() => toggle(true), [toggle]);

    const handleClone = useCallback(async () => {
      const response = await onClone();

      if (response) {
        redirectToNewItem(response.id);
      }
    }, [redirectToNewItem, onClone]);

    const handleClick = isUnsavedChanges ? openPopover : handleClone;

    return (
      <TopBarTool>
        <TooltipOnHover title='Duplicate' delay={100}>
          <div className={styles.container} ref={onTriggerRef}>
            <Button is='minor' icon='duplicate' onClick={handleClick} />
          </div>
        </TooltipOnHover>
        <Portal id='popover'>
          <div ref={onPopoverRef} style={{ zIndex: 1500 }}>
            {show && (
              <Popover className={styles.popover} onClickOutside={closePopover}>
                <CoachMessage>
                  You have some unsaved changes which won’t be included in your duplicate item. Save your item first if
                  you want these changes to be duplicated.
                </CoachMessage>
                <Button className={styles.button} is='minor' onClick={closePopover} fluid>
                  Ok
                </Button>
              </Popover>
            )}
          </div>
        </Portal>
      </TopBarTool>
    );
  }
);
