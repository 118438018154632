import React, { useEffect, useMemo } from 'react';

import useConditionBuilderContext from '~/pages/cohorts/edit/ConditionBuilder/hooks/useConditionBuilderContext';
import RuleSet from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/Ruleset';
import AddRuleset from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/AddRuleset';
import ErrorState from '~/pages/cohorts/edit/ConditionBuilder/components/VisualView/components/ErrorState';

const VisualView = (): React.JSX.Element => {
  const {
    ruleSets,
    convertCodeViewToVisualView,
    isError,
    resetVisualView,
    conditionQuery,
    setupInitialVisualViewData
  } = useConditionBuilderContext();

  useEffect(() => {
    if (conditionQuery) {
      convertCodeViewToVisualView();
    } else {
      setupInitialVisualViewData();
    }

    return () => {
      resetVisualView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ruleSetsIds = useMemo(() => Object.keys(ruleSets), [ruleSets]);

  if (isError) {
    return <ErrorState />;
  }

  return (
    <>
      {ruleSetsIds.map(ruleSetId => (
        <RuleSet key={ruleSetId} id={ruleSetId} />
      ))}
      <AddRuleset />
    </>
  );
};

export default VisualView;
