import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useCoachPopover } from '~/components/_layout/coach/coach-popover';
import { CoachMessage } from '~/components/_layout/coach/coach-message';
import { FormLabel } from '~/components/_form/components/label';
import { Input } from '~/components/_inputs/input';
import { Button } from '~/components/button';
import { pageNameToSlug } from '~/pages/pages/edit/components/page-name-popover/helpers';
import { PAGES_ROUTES } from '~/routes/private/pages/constants';
import usePageEditorDispatch from '~/pages/pages/edit/context/hooks/use-page-editor-dispatch';
import { savePageName, setSlug } from '~/pages/pages/edit/context/store/actions';

import styles from './styles.module.scss';

const PageNamePopover = (): React.JSX.Element => {
  const { CoachPopover, show, unsubscribe } = useCoachPopover();
  const history = useHistory();
  const { search } = useLocation();

  const dispatch = usePageEditorDispatch();

  const [pageName, setPageName] = useState('');
  const [forceError, setForceError] = useState(false);

  const returnToPagesList = useCallback(() => {
    if (show) {
      unsubscribe();
      history.replace(`${PAGES_ROUTES.root}${search}`);
    }
  }, [history, search, show, unsubscribe]);

  const onSavePageName = useCallback(() => {
    if (pageName) {
      dispatch(savePageName(pageName));
      dispatch(setSlug(pageNameToSlug(pageName)));
      unsubscribe();

      return;
    }
    setForceError(true);
  }, [dispatch, pageName, unsubscribe]);

  return (
    <CoachPopover show={show} unsubscribe={returnToPagesList}>
      <CoachMessage>Name your page to get started...</CoachMessage>
      <FormLabel label={'Page name'} placement='top'>
        <Input
          onBlur={() => setForceError(true)}
          value={pageName}
          onChange={(_, value) => setPageName(value)}
          error={forceError && !pageName.length ? 'Page name is required' : undefined}
        />
      </FormLabel>
      <div className={styles.buttons}>
        <Button onClick={returnToPagesList} is='minor'>
          Cancel
        </Button>
        <Button onClick={onSavePageName} is='major'>
          Ok
        </Button>
      </div>
    </CoachPopover>
  );
};

export default PageNamePopover;
